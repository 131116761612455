import { defineMessages } from 'react-intl';

export default defineMessages({
	emailPreviewWindowTitle: {
		id: 'getSetup.announce.modal.previewWindow.title',
		defaultMessage: 'New Message',
		description: '...',
	},
	subject: {
		id: 'getSetup.announce.modal.previewWindow.subjectLine',
		defaultMessage: 'Subject:',
		description: '...',
	},
	subjectLine: {
		id: 'getSetup.announce.modal.previewWindow.subjectLine',
		defaultMessage: 'New employee benefit, Calm!',
		description: '...',
	},
});
