import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Card, Button, Modal } from '@calm-web/design-system';

import CellSubtitle from '@/components/ui/CellSubtitle';
import CellTitle from '@/components/ui/CellTitle';
import { useApi } from '@/hooks/api';
import { setBannerMessage } from '@/store/actions';
import { isCalmError } from '@/utils/apiRequest/errors';
import { calmLogger } from '@/utils/calmLogger';

import { ConfirmationText, LeftButtonWrapper, SecondaryText } from './styles';

// Since this is edit, we know they will be defined.
// TODO: BM 11/16/2020 - it may be worth it to split out edit and create
// so that we can be more precise with our types
interface Props {
	partnerName?: string;
	partnerId?: string;
}

const DeletePartner: FC<Props> = ({ partnerName, partnerId }) => {
	const dispatch = useDispatch();
	const apiRequest = useApi();
	const history = useHistory();
	const { search } = useLocation();
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);

	function onRemoveClick(): void {
		setShowConfirmModal(true);
	}

	function closeModal(): void {
		setShowConfirmModal(false);
	}

	function handleDeleteError(err: unknown): void {
		const code = isCalmError(err) ? err?.data?.error?.code : undefined;
		switch (code) {
			case 'b2b_partner_not_found':
				dispatch(
					setBannerMessage({
						message: 'Error: Partner not found',
						isError: true,
						flash: true,
					}),
				);
				break;
			case 'b2b_partner_has_existing_subscriptions':
				dispatch(
					setBannerMessage({
						message: 'Error: Cannot remove partner when there are existing subscriptions',
						isError: true,
						flash: true,
					}),
				);
				break;
			case 'b2b_partner_has_existing_eligibilities':
				dispatch(
					setBannerMessage({
						message: 'Error: Cannot remove partner with an existing eligibility list',
						isError: true,
						flash: true,
					}),
				);
				break;
			default:
				// Most likely a network error, so prompt to try again
				dispatch(
					setBannerMessage({
						message: 'Unable to remove partner. Please try again',
						isError: true,
						flash: true,
					}),
				);
				break;
		}
	}

	async function deletePartner(): Promise<void> {
		if (!partnerId) {
			// Shouldn't ever happen, but throw an error just in case
			dispatch(
				setBannerMessage({
					message: 'Error: Partner ID is empty',
					isError: true,
					flash: true,
				}),
			);
			return;
		}

		setIsDeleting(true);
		try {
			await apiRequest({
				endpoint: `b2b/partners/${partnerId}`,
				method: 'DELETE',
			});
			dispatch(
				setBannerMessage({
					message: `${partnerName} successfully removed`,
					isError: false,
					flash: true,
				}),
			);
			history.push({
				pathname: '/groups',
				search,
			});
		} catch (err) {
			calmLogger.error('Error in DeletePartner deletePartner', {}, err);
			// Since this is internal only, we can show a generic error and have a developer debug
			handleDeleteError(err);
		} finally {
			setIsDeleting(false);
		}
	}

	const confirmationModal = (
		<Modal
			isOpen
			closeModal={closeModal}
			title="Are you sure?"
			footer={
				<>
					<LeftButtonWrapper>
						<Button textColor="blue3" backgroundColor="white" onPress={closeModal}>
							cancel
						</Button>
					</LeftButtonWrapper>
					<Button
						backgroundColor="heartRed"
						isLoading={isDeleting}
						onPress={deletePartner}
						type="button"
						data-testid="confirm-remove-group-button"
					>
						Remove this group
					</Button>
				</>
			}
		>
			<ConfirmationText>
				Removing this partner from Calm Partner Portal will remove the group and all of its configuration
				settings.
			</ConfirmationText>
			<br />
			<SecondaryText>This action cannot be undone. Are you sure you want to remove this group?</SecondaryText>
		</Modal>
	);

	return (
		<>
			<Card>
				<CellTitle showAdminFlag>Remove Group</CellTitle>
				<CellSubtitle>Remove this group from Calm Partner Portal. This action cannot be undone.</CellSubtitle>
				<Button
					backgroundColor="heartRed"
					onPress={onRemoveClick}
					type="button"
					data-testid="remove-group-button"
				>
					Remove
				</Button>
			</Card>
			{showConfirmModal ? confirmationModal : null}
		</>
	);
};

export default DeletePartner;
