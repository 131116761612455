import { defineMessages } from 'react-intl';

export default defineMessages({
	cancelButton: {
		id: 'pathways.cancelButton',
		defaultMessage: 'Cancel',
		description: 'Cancel button text',
	},
	backButton: {
		id: 'pathways.backButton',
		defaultMessage: 'Back',
		description: 'Back button text',
	},
	deleteButton: {
		id: 'pathways.deleteButton',
		defaultMessage: 'Delete Pathway',
		description: 'Delete button text',
	},
	nextButton: {
		id: 'pathways.nextButton',
		defaultMessage: 'Next: {nextpathway}',
		description: 'Next button text',
	},
	launchPathway: {
		id: 'pathways.launchButton',
		defaultMessage: 'Launch Pathway',
		description: 'Submit button text',
	},
	savePathway: {
		id: 'pathways.launchButton.edit',
		defaultMessage: 'Save Pathway',
		description: 'Submit button text for edit',
	},
	abandonedModalTitle: {
		id: 'pathways.abandonedModal.title',
		defaultMessage: 'Leave without launching?',
		description: 'Title for abandoned modal',
	},
	abandonedModalDescription: {
		id: 'pathways.abandonedModal.description',
		defaultMessage: 'If you leave now, you will lose all your progress and will have to start over.',
		description: 'Description for abandoned modal',
	},
	abandonedModalLeaveButton: {
		id: 'pathways.abandonedModal.leaveButton',
		defaultMessage: 'Leave',
		description: 'Back button text for abandoned modal',
	},
	abandonedModalStayButton: {
		id: 'pathways.abandonedModal.stayButton',
		defaultMessage: 'Stay',
		description: 'Launch button text for abandoned modal',
	},
	confirmationModalCreateTitle: {
		id: 'pathways.confirmationModal.create.description',
		defaultMessage: 'Ready to launch?',
		description: 'Description for confirmation modal on create',
	},
	confirmationModalNoEdit: {
		id: 'pathways.confirmationModal.noEdit.description',
		defaultMessage:
			'Please make sure everything is correct as you will not be able to edit the Pathway after launching.',
		description: 'Description for confirmation modal when edit flag is turned off',
	},
	confirmationModalEditTitle: {
		id: 'pathways.confirmationModal.edit.description',
		defaultMessage: 'Save your Pathway',
		description: 'Description for confirmation modal on edit',
	},
	confirmationModalPathwayWarning: {
		id: 'pathways.confirmationModal.pathwayWarning',
		defaultMessage: 'You can make changes up until the Announcement is sent.',
		description: 'Pathway warning for confirmation modal',
	},
	confirmationModalPathwayWarningEmailsOff: {
		id: 'pathways.confirmationModal.pathwayWarning.emailsOff',
		defaultMessage: 'You can make changes up until the Pathway begins.',
		description: 'New pathway warning for confirmation modal when emails are off',
	},
	confirmationModalPathwayHeader: {
		id: 'pathways.confirmationModal.pathwayHeader',
		defaultMessage: 'Pathway',
		description: 'Pathway header for confirmation modal',
	},
	confirmationModalAudienceHeader: {
		id: 'pathways.confirmationModal.audienceHeader',
		defaultMessage: 'Audience',
		description: 'Audience header for confirmation modal',
	},
	confirmationModalScheduleHeader: {
		id: 'pathways.confirmationModal.scheduleHeader',
		defaultMessage: 'Scheduling',
		description: 'Schedule header for confirmation modal',
	},
	confirmationModalAudience: {
		id: 'pathways.confirmationModal.audience',
		defaultMessage: '{userCount} users',
		description: 'Total audience count in confirmation modal',
	},
	confirmationModalScheduleDuration: {
		id: 'pathways.confirmationModal.duration',
		defaultMessage: 'Duration: {durationDates}',
		description: 'Duration timeframe in confirmation modal',
	},
	confirmationModalEmailSettings: {
		id: 'pathways.confirmationModal.emailSettings',
		defaultMessage: 'Emails: {emailSettings}',
		description: 'Email settings in confirmation modal',
	},
	confirmationModalEmailSettingsOff: {
		id: 'pathways.confirmationModal.emailSettings.off',
		defaultMessage: 'No emails from Calm',
		description: 'Email settings in confirmation modal when settings are off',
	},
	confirmationModalEmailSettingsOn: {
		id: 'pathways.confirmationModal.emailSettings.on',
		defaultMessage: 'Send emails from Calm',
		description: 'Email settings in confirmation modal when settings are on',
	},
	confirmationModalScheduleAnnouncement: {
		id: 'pathways.confirmationModal.announcement',
		defaultMessage: 'Announcement: {announcementDate}',
		description: 'Announcement date in confirmation modal',
	},
	confirmationModalScheduleReminder: {
		id: 'pathways.confirmationModal.reminder',
		defaultMessage: 'Reminder: {reminderDate}',
		description: 'Reminder date in confirmation modal',
	},
	confirmationModalBackButton: {
		id: 'pathways.confirmationModal.backButton',
		defaultMessage: 'Go back',
		description: 'Back button text for confirmation modal',
	},
	successModalTitle: {
		id: 'pathways.successModal.title',
		defaultMessage: 'Congratulations! Your Pathway has launched!',
		description: 'Modal title for successful pathway save',
	},
	successModalDescription: {
		id: 'pathways.successModal.description',
		defaultMessage: "You're one step further on the journey of improving mental health for your employees.",
		description: 'Modal description for successful pathway save',
	},
	successModalButton: {
		id: 'pathways.successModal.button',
		defaultMessage: 'Return to Pathways home',
		description: 'Modal button text for successful pathway save',
	},
	deleteConfirmationModalTitle: {
		id: 'pathways.deleteConfirmationModal.title',
		defaultMessage: 'Delete Pathway?',
		description: 'Modal title text for confirming pathway deletion',
	},
	deleteConfirmationModalCancelButton: {
		id: 'pathways.deleteConfirmationModalCancel.button',
		defaultMessage: 'Cancel',
		description: 'Canceling Pathway deletion',
	},
	deleteConfirmationModalButton: {
		id: 'pathways.deleteConfirmationModal.button',
		defaultMessage: 'Yes, Delete',
		description: 'Confirming Pathway deletion',
	},
	deleteConfirmationModalDescription1: {
		id: 'pathways.deleteConfirmationModal.Description1',
		defaultMessage: 'Deleting your Pathway is permanent and irreversible.',
		description: 'Confirming Pathway deletion description text line 1',
	},
	deleteConfirmationModalDescription2: {
		id: 'pathways.deleteConfirmationModal.Description2',
		defaultMessage:
			'Please note that once a Pathway has started and the Sign-Up Announcement has been delivered, it cannot be deleted.',
		description: 'Confirming Pathway deletion description text line 2',
	},
	errorMessage: {
		id: 'pathways.readOnly.errorMessage',
		defaultMessage: 'You are not able to edit this Pathway',
		description: 'Error message for uneditable Pathways',
	},
});
