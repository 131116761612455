import { defineMessages } from 'react-intl';

export default defineMessages({
	title: {
		id: 'createAdmin.adminDetails.title',
		defaultMessage: '{numAdmins} Admins',
		description: 'A title above a section where the user can add admin details',
	},
	admin: {
		id: 'createAdmin.adminDetails.roles.admin',
		defaultMessage: 'Admin',
		description: 'Placeholder text to denote Admin role.',
	},
	salesTrialAdmin: {
		id: 'createAdmin.adminDetails.roles.salesTrialAdmin',
		defaultMessage: 'Trial Admin',
		description: 'Placeholder text to denote Sales Trial Admin role.',
	},

	inputPlaceholderEmail: {
		id: 'createAdmin.adminDetails.placeholder.email',
		defaultMessage: "Admin's email address",
		description: 'placeholder for new admin email input field',
	},
	inputPlaceholderFirstName: {
		id: 'createAdmin.adminDetails.placeholder.firstName',
		defaultMessage: "Admin's first name",
		description: 'placeholder for new admin first name input field',
	},
	inputPlaceholderLastName: {
		id: 'createAdmin.adminDetails.placeholder.lastName',
		defaultMessage: "Admin's last name",
		description: 'placeholder for new admin last name input field',
	},
	createAdminPrompt: {
		id: 'createAdmin.adminDetails.prompt',
		defaultMessage: 'Enter the information for the new Admin',
		description: 'Prompt for new admin information',
	},

	newAdminButton: {
		id: 'createAdmin.adminDetails.buttons.newAdmin',
		defaultMessage: 'Add new admin',
		description: 'Text for the Add New Admin Button',
	},
	optionsButton: {
		id: 'createAdmin.adminDetails.buttons.options',
		defaultMessage: 'Options',
		description: 'Text for the Options Button',
	},
	cancelButton: {
		id: 'createAdmin.adminDetails.buttons.cancel',
		defaultMessage: 'Cancel',
		description: 'Text for the Cancel Button',
	},
	addButton: {
		id: 'createAdmin.adminDetails.buttons.add',
		defaultMessage: 'Add',
		description: 'Text for the Add Button',
	},

	resendInvite: {
		id: 'createAdmin.adminDetails.options.resendInvite',
		defaultMessage: 'Resend admin invite',
		description: 'Text for Resending Admin Invite',
	},
	passwordReset: {
		id: 'createAdmin.adminDetails.options.passwordReset',
		defaultMessage: 'Send password reset email',
		description: 'Text for Resetting Admin Password',
	},
	removeAdmin: {
		id: 'createAdmin.adminDetails.options.removeAdmin',
		defaultMessage: 'Remove admin',
		description: 'Text for Removing the Admin',
	},
});
