import styled, { createGlobalStyle } from 'styled-components';
import { palette, theme } from 'styled-tools';

import {
	Banner as DesignSystemBanner,
	Checkbox,
	FontSizes,
	TextButton,
	Loader,
	PrimaryButton as DesignSystemPrimaryButton,
	fontSize,
	fontWeight,
	FontWeights,
	units,
} from '@calm-web/design-system';

import WarningIcon from 'icons/warning-filled.svg';

export const ButtonContainer = styled.div`
	position: relative;
`;

export const TitleStyled = styled.div`
	${fontSize(FontSizes.lg)}
`;

export const SegmentSelectionStyled = styled.div`
	flex-grow: 0;
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: ${units(1, 0)};
`;

const scrollIndicatorHeight = 3;
export const SegmentSelectionListScrollIndicator = styled.div`
	position: absolute;
	bottom: 0;
	width: 100%;
	height: ${units(scrollIndicatorHeight)};
	background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
`;

export const SegmentSelectionListContainer = styled.div`
	margin-bottom: ${units(0.5)};
	position: relative;
	height: 100%;
`;

export const SegmentSelectionList = styled.div`
	flex-grow: 1;
	overflow-y: auto;
	max-height: min(max(calc(100vh - ${units(56)}), ${units(24)}), ${units(72)});
	padding: ${units(1, 2)};
	padding-bottom: ${units(scrollIndicatorHeight)};
`;

export const SegmentNameStyled = styled.div`
	${fontSize(FontSizes.sm)};
	${fontWeight(FontWeights.Medium)};
	padding: ${units(0, 2)};
`;

export const SegmentNameCheckbox = styled(Checkbox)`
	${fontSize(FontSizes.sm)};
	${fontWeight(FontWeights.Medium)};
	padding: ${units(0, 0, 1, 0)};
	margin: 0 ${units(2)};
	border-bottom: 1px solid #00000033;
`;

export const SegmentCheckbox = styled(Checkbox)`
	word-break: break-word;
	&:not(:last-child) {
		margin-bottom: ${units(1)};
	}
`;

export const SegmentListContainer = styled.div`
	display: flex;
	border: 1px solid ${palette('gray1')};
	border-radius: 4px;
	margin-bottom: ${units(2)};

	${SegmentSelectionStyled} + ${SegmentSelectionStyled} {
		border-left: 1px solid ${palette('gray1')};
	}
`;

export const PrimaryButton = styled(DesignSystemPrimaryButton)``;

export const ButtonRow = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

export const ResultsStyled = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-bottom: ${units(2)};
`;

export const InlineLoader = styled(Loader)`
	width: auto;
	height: 27px;
	margin: 0;
`;

export const LoadingText = styled.div`
	color: ${palette('gray5')};
	${fontSize(FontSizes.sm)}
`;

export const LoadingTextContainer = styled.div`
	display: flex;
	align-items: center;
`;

export const WarningIconStyled = styled(WarningIcon)``;

export const ResultsCountError = styled.div`
	display: flex;
	align-items: center;
	${fontSize(FontSizes.sm)};

	> div:first-child {
		margin-right: ${units(0.5)};
	}

	${WarningIconStyled} {
		margin-left: ${units(1)};
	}
`;

export const SegmentDetailsError = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	margin: ${units(4)};

	${fontSize(FontSizes.sm)};

	${WarningIconStyled} {
		margin-bottom: ${units(1)};
	}
`;

export const SelectButtonRow = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: ${units(0, 2)};
`;

export const SelectionButton = styled(TextButton).attrs({ hideUnderline: true, size: FontSizes.sm })``;

// TODO I tried this approach which would be cleaner, but unfortunately it applies the styles below the div that has a higher z index
/* export const TooltipStyled = styled(Tooltip)`
 *   z-index: 0;
 * `; */

export const UnscrolledTooltipZIndex = createGlobalStyle`
  div[data-tippy-root] {
    z-index:
      ${theme('layout.navIndex')} !important;
  }
`;

export const ScrolledTooltipZIndex = createGlobalStyle`
  div[data-tippy-root] {
    z-index:
      ${theme('layout.belowNavIndex')} !important;
  }
`;

export const TooltipText = styled.div`
	margin-top: ${units(1)};
`;

export const Banner = styled(DesignSystemBanner)`
	margin: ${units(2, 0)};
`;
