import styled from 'styled-components';
import { palette } from 'styled-tools';

import { fontSize, FontSizes, fontWeight, FontWeights, units } from '@calm-web/design-system';

export const Title = styled.h4`
	margin-bottom: ${units(1)};
	${fontSize(FontSizes.xl)}
	${fontWeight(FontWeights.Regular)};
	color: ${palette('gray5')};
	text-align: left;
	align: left;
`;

export const ErrorMessage = styled.div`
	text-align: center;
	color: ${palette('errorRed')};
	${fontWeight(FontWeights.Medium)};
	margin-bottom: ${units(2)};
`;

export const PlanRow = styled.div`
	flex: 1 0 50%;
	margin-right: ${units(1)};
	display: flex;
	justify-content: space-between;
`;

export const PlanDetail = styled.div`
	${fontSize(FontSizes.base)}
	${fontWeight(FontWeights.Regular)};
	margin-bottom: ${units(1)};
	margin-left: ${units(1)};
`;

export const SectionContainer = styled.div`
	background: ${palette('white')};
	border: 1px solid ${palette('gray1')};
	box-sizing: border-box;
	border-radius: ${units(2)};
	padding: ${units(3)};
	width: 100%;
	color: ${palette('gray7')};
	margin-bottom: ${units(3)};
`;

export const Total = styled.div``;

export const TotalRow = styled.div`
	${fontSize(FontSizes.lg)}
	${fontWeight(FontWeights.Demi)};
	margin-top: ${units(2)};
	margin-left: ${units(1)};
	padding-top: ${units(1)};
	box-sizing: border-box;
	border-top: 1px solid ${palette('gray2')};
	border-radius: 4px;
	display: flex;
	justify-content: space-between;
`;

export const Container = styled.div`
	margin-bottom: ${units(3)};
`;
