import { FC } from 'react';

import { BottomActionBar, PhoneOutline, Wrapper } from '../styles';
import {
	ViewPort,
	Background,
	ContinueButton,
	Title,
	Content,
	WarningIcon,
	Body,
	ErrorButton,
	ErrorButtonWrapper,
	WarningIconWrapper,
} from './styles';

interface Props {
	errorBody?: string;
	errorButtonText?: string;
}

const DEFAULT_ERROR_BODY =
	'Please check your account details to make sure you have access to Calm. If you need help, contact your HR Dept.';
const DEFAULT_ERROR_BUTTON_TEXT = 'Contact your HR Dept';

const getDisplayText = (text: string | undefined, defaultText: string): string => text || defaultText;

const ErrorCustomizationPreview: FC<Props> = ({ errorBody, errorButtonText }) => {
	const displayErrorBody = getDisplayText(errorBody, DEFAULT_ERROR_BODY);
	const displayErrorButtonText = getDisplayText(errorButtonText, DEFAULT_ERROR_BUTTON_TEXT);

	return (
		<Wrapper data-testid="error-customization-preview">
			<Background />
			<PhoneOutline />
			<ViewPort>
				<Content>
					<WarningIconWrapper>
						<WarningIcon />
					</WarningIconWrapper>
					<Title>Your eligibility has not been confirmed</Title>
					<Body>{displayErrorBody}</Body>
					<ErrorButtonWrapper>
						<ErrorButton>{displayErrorButtonText}</ErrorButton>
					</ErrorButtonWrapper>
				</Content>
			</ViewPort>
			<BottomActionBar>
				<ContinueButton>Continue</ContinueButton>
			</BottomActionBar>
		</Wrapper>
	);
};

export default ErrorCustomizationPreview;
