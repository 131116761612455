function friendlyPercentage(val: number | undefined, supportNegatives?: boolean): string {
	if (val === 0) {
		return '0';
	}
	if (supportNegatives && val) {
		const isNegative = val < 0;
		const absValue = Math.abs(val);
		return `${isNegative ? '-' : '+'}${friendlyPercentage(absValue, false)}`;
	}
	if (val && val < 0.01) {
		return '<1';
	}
	if (val && val > 9.99) {
		return '>999';
	}
	// otherwise, show at most one decimal
	const asString = val ? (val * 100).toFixed(1) : '';
	return asString.replace(/\.0$/, '');
}

export function friendlyActual(val: number | undefined, supportNegatives?: boolean): string {
	if (val === 0) {
		return '0';
	}
	if (supportNegatives && val) {
		const isNegative = val < 0;
		const absValue = Math.abs(val);
		return `${isNegative ? '-' : '+'}${friendlyActual(absValue, false)}`;
	}
	if (val && val > 999) {
		return '>999';
	}
	const asString = val ? val.toFixed() : '';
	return asString.replace(/\.0$/, '');
}

export default friendlyPercentage;
