/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

export default defineMessages({
	coveredLivesHeader: {
		id: 'users.header.coveredLives',
		defaultMessage: 'Covered Lives',
		description: 'shows the number of people covered in the current plan of a partner',
	},
	usersUploadedHeader: {
		id: 'users.header.usersUploaded',
		defaultMessage: 'Users Uploaded',
		description: 'shows the number of people within the Eligibility File Upload',
	},
	signUpsHeader: {
		id: 'users.header.signUps',
		defaultMessage: 'Sign ups',
		description: 'shows the percent of users signed up for Calm in the current plan of a partner',
	},
	signUpRemindersHeader: {
		id: 'users.header.signUpReminders',
		defaultMessage: 'Sign Up Reminders',
		description: 'shows the sign up reminder email stats',
	},
	signUpsExplanation: {
		id: 'users.header.signUpReminders.toolTip.explanation',
		defaultMessage: `Calculated by taking the total number of signed up users divided by your plan's total covered lives`,
		description: 'shows the sign up reminder email stats',
	},
	signUpsPrivacyNotice: {
		id: 'users.header.signUpReminders.toolTip.privacyNotice',
		defaultMessage: 'Data cannot be displayed for groups of less than 10 users to protect users’ privacy.',
		description: 'shows the reas',
	},
	unableToSendEmails: {
		id: 'users.header.signUpReminders.unableToSend',
		defaultMessage:
			'Automated invite emails are currently disabled. To enable them, update the <i>Benefit Invite & Reminders</i> setting under <link>User Email Preferences</link>.',
		description: 'Sign up email reminder tooltip heading',
	},
	frequencyHeader: {
		id: 'users.header.signUpReminders.toolTip.header',
		defaultMessage: 'Automated reminder email frequency:',
		description: 'Sign up email reminder tooltip heading',
	},
	frequencyOneWeekAfterEmail: {
		id: 'users.header.signUpReminders.toolTip.header',
		defaultMessage: '1 Week after being added',
		description: 'Sign up email reminder first automatic send out.',
	},
	frequencyOneDayAfterEmail: {
		id: 'users.header.signUpReminders.toolTip.frequencyOneDayAfterEmail',
		defaultMessage: '1 Day after being added',
		description: 'Sign up email reminder second automatic send out for 24 hour delay.',
	},
	frequencyOneMonthLaterEmail: {
		id: 'users.header.signUpReminders.toolTip.frequencyOneMonthLaterEmail',
		defaultMessage: '1 Month after last email',
		description: 'Sign up email reminder third automatic send out.',
	},
	frequencyTwoMonthsLaterEmail: {
		id: 'users.header.signUpReminders.toolTip.frequencyTwoMonthsLaterEmail',
		defaultMessage: '2 Months after last email',
		description: 'Sign up email reminder ongoing automatic send out.',
	},
	lastEmailSent: {
		id: 'users.eligibilityFile.uploadSuccessModal.lastEmailSent',
		defaultMessage: 'Last email reminder sent:',
		description: 'Text that will describe the previously sent sign up reminder date displayed.',
	},
	nextEmailSend: {
		id: 'users.eligibilityFile.uploadSuccessModal.nextEmailSend',
		defaultMessage: 'Next scheduled reminder:',
		description: 'Text that will describe the next sign up reminder date displayed.',
	},
	noPreviouslySentEmail: {
		id: 'users.eligibilityFile.uploadSuccessModal.noPreviousEmail',
		defaultMessage: 'No previously sent emails.',
		description: 'Text that will indicate no previous sign up reminders have been sent.',
	},
	noFutureEmails: {
		id: 'users.eligibilityFile.uploadSuccessModal.noFutureEmail',
		defaultMessage: 'No future emails are scheduled.',
		description: 'Text that will indicate no future sign up reminders are scheduled.',
	},
	manualReminderCopy: {
		id: 'users.eligibilityFile.uploadSuccessModal.addedSegments',
		defaultMessage:
			"To send an immediate sign up reminder to those who haven't redeemed, <sendEmailLink>click here</sendEmailLink>.",
		description: 'Segments added copy.',
	},
	remindersRequireEmails: {
		id: 'users.eligibilityFile.uploadSuccessModal.addedSegments',
		defaultMessage:
			"To have Calm automatically send sign up reminders to those who haven't redeemed, the <italic>Unique Identifier</italic> column must be an email address.",
		description: 'Segments added copy.',
	},
	downloadButtonText: {
		id: 'users.download',
		defaultMessage: 'Download list',
		description: 'Text of the button for downloading all current eligibility file.',
	},
	downloadButtonTextError: {
		id: 'users.downloadButtonTextError',
		defaultMessage: 'Error when downloading file',
		description: 'Text to display when there is an unexpected error while downloading eligibility csv file.',
	},
	addButtonText: {
		id: 'users.upload',
		defaultMessage: 'Add',
		description: 'Text of the button for adding a new user to the eligibility file.',
	},
	searchPlaceholder: {
		id: 'users.search.placeholder',
		defaultMessage: 'Search',
		description: 'Placeholder for access codes search input field',
	},
	eligibilityBlockedErrorMessage: {
		id: 'users.eligibilityBlockedErrorMessage',
		defaultMessage: 'Please talk to your account manager to access this data',
		description:
			'Text to display when we are manually blocking a partner from accessing the users/access codes page. Only shows up if user manually puts in /users or /access_codes in their url',
	},
	eligibleUsersErrorMessage: {
		id: 'users.eligibleUsersErrorMessage',
		defaultMessage: 'Failed to retrieve eligible users. Please refresh your browser to try again.',
		description:
			'Text to display when unable to fetch eligible users before rendering a partners eligibility list.',
	},

	permissionError: {
		id: 'users.permissionError',
		defaultMessage: 'You do not have permission to view Users.',
		description: 'Text to display when permission is not granted to User data.',
	},
	additionalCoveragePrompt: {
		id: 'users.add.additionalCoveragePrompt',
		defaultMessage: 'Would you like to purchase additional coverage?',
		description: 'Message prompting users to purchase additional coverage',
	},
});
