import _ from 'lodash';

/**
- permission: { action: string, target: string, fields: []string }:  an allowed action on an object and a set of fields
- accessPolicy: { permissions: []permission } : a list of permissions
- role: { accessPolicy }: simply maps the accessPolicy to the role name (edited)
 */

export const partnerActions = {
	CREATE: 'create',
	READ: 'read',
	UPDATE: 'update',
	DELETE: 'delete',
} as const;

export const partnerRoles = {
	accountManager: 'partner_portal_role_account_manager',
	accountManagerNonUS: 'partner_portal_role_account_manager_non_us',
	accountManagerAE: 'partner_portal_role_account_manager_ae',
	accountManagerPHIAuthourized: 'partner_portal_role_phi_authorized_account_manager',
	partnerAdmin: 'partner_portal_role_partner_admin',
	partnerAdminPHIAuthourized: 'partner_portal_role_phi_authorized_partner_admin',
	salesTrialAdmin: 'partner_portal_role_sales_trial_admin',
	selfServeAdmin: 'partner_portal_role_self_serve_admin',
};

export const ILLEGAL_USER_CONFIGURATION = 'illegal_user_configuration';
export const UNKNOWN_USER_ROLE = 'unknown_user_role';

interface AccessToken {
	allowed_user_role:
		| typeof partnerRoles.accountManager
		| typeof partnerRoles.accountManagerNonUS
		| typeof partnerRoles.accountManagerPHIAuthourized
		| typeof partnerRoles.partnerAdmin
		| typeof partnerRoles.partnerAdminPHIAuthourized
		| typeof partnerRoles.selfServeAdmin;
	allowed_partners: Array<string>;
	can_approve_partner_logos?: boolean;
}

export type Actions = Array<
	| typeof partnerActions.CREATE
	| typeof partnerActions.READ
	| typeof partnerActions.UPDATE
	| typeof partnerActions.DELETE
>;

interface Permission {
	actions: Actions;
}

export interface AccessPolicy {
	permissions: {
		[key: string]: Permission;
	};
	allowedPartners: Array<string>;
	isAdmin?: boolean;
	allowed_user_role?:
		| typeof partnerRoles.accountManager
		| typeof partnerRoles.accountManagerNonUS
		| typeof partnerRoles.accountManagerPHIAuthourized
		| typeof partnerRoles.partnerAdmin
		| typeof partnerRoles.partnerAdminPHIAuthourized
		| typeof partnerRoles.salesTrialAdmin
		| typeof partnerRoles.selfServeAdmin;
}

const { CREATE, READ, UPDATE, DELETE } = partnerActions;

export const accountManagerPolicy = {
	id: { actions: [CREATE, READ, DELETE] },
	email: { actions: [CREATE, UPDATE] },
	name: { actions: [CREATE, READ, UPDATE] },
	slug: { actions: [CREATE, READ, UPDATE] },
	logo_url: { actions: [CREATE, READ, UPDATE] },
	dtc_logo_url: { actions: [CREATE, READ, UPDATE, DELETE] },
	supports_eligibility_list: { actions: [CREATE, READ, UPDATE] },
	user_id_descriptor: { actions: [CREATE, READ, UPDATE] },
	client_id: { actions: [CREATE, READ] },
	vouched_plan_sku: { actions: [CREATE, READ, UPDATE] },
	contract_starts_at: { actions: [CREATE, READ, UPDATE] },
	contract_expires_at: { actions: [CREATE, READ, UPDATE] },
	contract_covered_lives: { actions: [CREATE, READ, UPDATE] },
	max_dependents_per_user: { actions: [CREATE, READ, UPDATE] },
	is_sales_trial: { actions: [CREATE, READ, UPDATE] },
	is_hipaa_compliant: { actions: [CREATE, READ, UPDATE] },
	is_ldu: { actions: [CREATE, READ, UPDATE] },
	email_config_is_branded: { actions: [CREATE, READ, UPDATE] },
	email_config_can_send_upsell: { actions: [CREATE, READ, UPDATE] },
	email_config_can_send_download_app: { actions: [CREATE, READ, UPDATE] },
	email_config_can_send_promo: { actions: [CREATE, READ, UPDATE] },
	email_config_can_send_benefit_reminders: { actions: [CREATE, READ, UPDATE] },
	email_config_can_send_survey: { actions: [CREATE, READ, UPDATE] },
	web_renew_url: { actions: [CREATE, READ, UPDATE] },
	ios_renew_url: { actions: [CREATE, READ, UPDATE] },
	android_renew_url: { actions: [CREATE, READ, UPDATE] },
	sync_eligibility_file: { actions: [UPDATE] },
	partner_user_feedback: { actions: [READ, UPDATE] },
	integration_type: { actions: [CREATE, READ, UPDATE] },
	sso_config: { actions: [CREATE, READ, UPDATE] },
	category: { actions: [CREATE, READ, UPDATE] },
	product_sku: { actions: [CREATE, READ, UPDATE] },
	add_users_to_eligibility: { actions: [UPDATE] },
	update_plan_details: { actions: [READ] },
	downgrade_selfserve_plan: { actions: [UPDATE] },
	terminate_partner: { actions: [CREATE] },
	admin_users: { actions: [READ, CREATE, DELETE] },
	admin_phi: { actions: [READ] },
	reset_mfa: { actions: [DELETE] },
	trial_duration: { actions: [READ, CREATE, DELETE, UPDATE] },
	max_redemptions: { actions: [READ, CREATE, DELETE, UPDATE] },
	promotion_active: { actions: [READ, CREATE, DELETE, UPDATE] },
	duration_type: { actions: [READ, CREATE, DELETE, UPDATE] },
	duration_in_months: { actions: [READ, CREATE, DELETE, UPDATE] },
	terms: { actions: [READ, CREATE, DELETE, UPDATE] },
	faq_link: { actions: [READ, CREATE, DELETE, UPDATE] },
	percent_off: { actions: [READ, CREATE, DELETE, UPDATE] },
	has_churned: { actions: [READ, UPDATE] },
	b2b_account_id: { actions: [READ, CREATE, DELETE, UPDATE] },
	group_code: { actions: [READ, CREATE, UPDATE, DELETE] },
	msft_teams_tenant_id: { actions: [READ, CREATE, UPDATE, DELETE] },
	// calm heath partner health config fields
	additional_languages: { actions: [READ, UPDATE] },
	landing_header: { actions: [READ, UPDATE] },
	landing_body: { actions: [READ, UPDATE] },
	baa_data_retention: { actions: [READ, UPDATE] },
	enable_data_feed_override: { actions: [READ, UPDATE] },
	hide_all_referrals: { actions: [READ, UPDATE] },
	client_support_phone: { actions: [READ, UPDATE] },
	client_support_email: { actions: [READ, UPDATE] },
	light_logo_url: { actions: [READ, UPDATE] },
	dark_logo_url: { actions: [READ, UPDATE] },
	square_logo_url: { actions: [READ, UPDATE] },
	health_client_id: { actions: [READ, CREATE, DELETE, UPDATE] },
	eligibility_file_format: { actions: [READ, CREATE, UPDATE] },
	error_configs: { actions: [READ, CREATE, DELETE, UPDATE] },
	eligibility_validator_validation_name: { actions: [READ, CREATE, DELETE, UPDATE] },
	eligibility_validator_validation_type: { actions: [READ, CREATE, DELETE, UPDATE] },
	eligibility_validator_validation_display_name: { actions: [READ, CREATE, DELETE, UPDATE] },
	eligibility_validator_is_required_field_at_redemption: { actions: [READ, CREATE, DELETE, UPDATE] },
	health_sponsorship_display_name: { actions: [READ, UPDATE, CREATE] },
	health_sponsorship_description: { actions: [READ, UPDATE, CREATE] },
	health_sponsorship_starts_at: { actions: [READ, UPDATE, CREATE] },
	health_sponsorship_ends_at: { actions: [READ, UPDATE, CREATE] },
	health_sponsorship_auto_expire_duration: { actions: [READ, UPDATE, CREATE] },
	health_sponsorship_is_demo: { actions: [READ, UPDATE, CREATE] },
	health_sponsorship_assignment_rules: { actions: [READ, UPDATE, CREATE] },
	health_sponsorship_referrals: { actions: [READ, UPDATE, CREATE] },
	parent: { actions: [READ, CREATE, UPDATE, DELETE] },
	reporting_delivery_method: { actions: [READ, UPDATE] },
	reporting_technical_poc_email: { actions: [READ, UPDATE] },
	reporting_data_feeds: { actions: [READ, UPDATE] },
	mental_health_screening_show_self_harm_question: { actions: [READ, UPDATE] },
	mental_health_screening_show_screening_results: { actions: [READ, UPDATE] },
	mental_health_screening_all_questions_required: { actions: [READ, UPDATE] },
	mental_health_screening_show_referrals: { actions: [READ, UPDATE] },
};

const accountManagerAEPolicy = _.omit(accountManagerPolicy, ['contract_covered_lives']);

export const accountManagerPolicyWithPHIAuthorization = {
	...accountManagerPolicy,
	admin_phi: { actions: [READ, CREATE, DELETE, UPDATE] },
	ch_reporting: { actions: [READ] },
};

// Keeping the CREATE functionality so that this accounts for self-serve
export const partnerAdminPolicy = {
	id: { actions: [READ] },
	name: { actions: [READ, UPDATE] },
	slug: { actions: [READ] },
	logo_url: { actions: [CREATE, READ, UPDATE] },
	dtc_logo_url: { actions: [CREATE, READ, UPDATE, DELETE] },
	// request_logo_access: { actions: [CREATE] },
	supports_eligibility_list: { actions: [READ] },
	user_id_descriptor: { actions: [READ, UPDATE] },
	integration_type: { actions: [READ] },
	client_id: { actions: [CREATE] },
	vouched_plan_sku: { actions: [READ] },
	email_config_can_send_benefit_reminders: { actions: [READ, UPDATE] },
	email_config_can_send_survey: { actions: [READ, UPDATE] },
	sync_eligibility_file: { actions: [UPDATE] },
	partner_user_feedback: { actions: [READ] },
	admin_users: { actions: [READ, CREATE, DELETE] },
	admin_phi: { actions: [READ] },
	add_users_to_eligibility: { actions: [UPDATE] },
	group_code: { actions: [READ] },
	msft_teams_tenant_id: { actions: [READ, CREATE, UPDATE, DELETE] },
	// calm heath partner health config fields
	landing_header: { actions: [READ] },
	landing_body: { actions: [READ] },
	baa_data_retention: { actions: [READ] },
	hide_all_referrals: { actions: [READ, UPDATE] },
	client_support_phone: { actions: [READ] },
	client_support_email: { actions: [READ] },
	light_logo_url: { actions: [READ, UPDATE] },
	dark_logo_url: { actions: [READ, UPDATE] },
	square_logo_url: { actions: [READ, UPDATE] },
	eligibility_file_format: { actions: [READ] },
	error_configs: { actions: [READ, UPDATE] },
	eligibility_validator_validation_name: { actions: [READ] },
	eligibility_validator_validation_type: { actions: [READ] },
	eligibility_validator_validation_display_name: { actions: [READ] },
	eligibility_validator_is_required_field_at_redemption: { actions: [READ] },
	health_sponsorship_display_name: { actions: [READ] },
	health_sponsorship_description: { actions: [READ] },
	health_sponsorship_starts_at: { actions: [READ] },
	health_sponsorship_ends_at: { actions: [READ] },
	health_sponsorship_auto_expire_duration: { actions: [READ] },
	health_sponsorship_is_demo: { actions: [READ] },
	health_sponsorship_assignment_rules: { actions: [READ] },
	health_sponsorship_referrals: { actions: [READ] },
	reporting_delivery_method: { actions: [READ] },
	reporting_technical_poc_email: { actions: [READ] },
	reporting_data_feeds: { actions: [READ] },
	mental_health_screening_show_self_harm_question: { actions: [READ] },
	mental_health_screening_show_screening_results: { actions: [READ] },
	mental_health_screening_all_questions_required: { actions: [READ] },
	mental_health_screening_show_referrals: { actions: [READ] },
};

export const partnerAdminPolicyWithPHIAuthorization = {
	...partnerAdminPolicy,
	admin_phi: { actions: [READ, CREATE, DELETE, UPDATE] },
	ch_reporting: { actions: [READ] },
};

export const selfServeAdminPolicy = {
	id: { actions: [CREATE, READ] },
	name: { actions: [CREATE, READ, UPDATE] },
	slug: { actions: [CREATE, READ] },
	logo_url: { actions: [CREATE, READ, UPDATE] },
	dtc_logo_url: { actions: [CREATE, READ, UPDATE, DELETE] },
	supports_eligibility_list: { actions: [READ] }, // Always true
	user_id_descriptor: { actions: [CREATE, READ] },
	client_id: { actions: [CREATE] },
	vouched_plan_sku: { actions: [CREATE, READ] },
	contract_starts_at: { actions: [READ] },
	contract_expires_at: { actions: [READ] },
	contract_covered_lives: { actions: [CREATE, READ] },
	update_plan_details: { actions: [READ, UPDATE] },
	email_config_can_send_benefit_reminders: { actions: [READ, UPDATE] },
	email_config_can_send_survey: { actions: [READ, UPDATE] },
	partner_user_feedback: { actions: [READ] },
	add_users_to_eligibility: { actions: [UPDATE] },
	admin_users: { actions: [READ, CREATE, DELETE] },
	admin_phi: { actions: [READ] },
	msft_teams_tenant_id: { actions: [READ, CREATE, UPDATE, DELETE] },
};

export function getUserPolicy(decodedAccessToken: AccessToken): AccessPolicy {
	// If no role or partner preference, throw an error in the parent function
	if (!decodedAccessToken?.allowed_partners || !decodedAccessToken?.allowed_user_role) {
		throw new Error(ILLEGAL_USER_CONFIGURATION);
	}

	const { allowed_partners, allowed_user_role } = decodedAccessToken;

	if (
		allowed_user_role === partnerRoles.accountManager ||
		allowed_user_role === partnerRoles.accountManagerNonUS
	) {
		// Calm account manager - superadmin - usually coincides with '*' partner access rights
		return {
			permissions: accountManagerPolicy,
			allowedPartners: allowed_partners,
			isAdmin: true,
			allowed_user_role,
		};
	}
	if (allowed_user_role === partnerRoles.accountManagerPHIAuthourized) {
		return {
			permissions: accountManagerPolicyWithPHIAuthorization,
			allowedPartners: allowed_partners,
			isAdmin: true,
			allowed_user_role,
		};
	}
	if (allowed_user_role === partnerRoles.partnerAdmin) {
		return {
			permissions: partnerAdminPolicy,
			allowedPartners: allowed_partners,
			allowed_user_role,
		};
	}
	if (allowed_user_role === partnerRoles.partnerAdminPHIAuthourized) {
		return {
			permissions: partnerAdminPolicyWithPHIAuthorization,
			allowedPartners: allowed_partners,
			allowed_user_role,
		};
	}
	if (allowed_user_role === partnerRoles.salesTrialAdmin) {
		return {
			permissions: partnerAdminPolicy,
			allowedPartners: allowed_partners,
			allowed_user_role,
		};
	}
	if (allowed_user_role === partnerRoles.selfServeAdmin) {
		return {
			permissions: selfServeAdminPolicy,
			allowedPartners: allowed_partners,
			allowed_user_role,
		};
	}
	if (allowed_user_role === partnerRoles.accountManagerAE) {
		return {
			permissions: accountManagerAEPolicy,
			allowedPartners: allowed_partners,
			isAdmin: true,
			allowed_user_role,
		};
	}
	throw new Error(UNKNOWN_USER_ROLE);
}

export function isPartnerAdmin(userRole: string | undefined): boolean {
	return userRole === partnerRoles.partnerAdmin || userRole === partnerRoles.partnerAdminPHIAuthourized;
}

export function isAccountManager(userRole: string | undefined): boolean {
	return (
		userRole === partnerRoles.accountManager ||
		userRole === partnerRoles.accountManagerNonUS ||
		userRole === partnerRoles.accountManagerPHIAuthourized ||
		userRole === partnerRoles.accountManagerAE
	);
}

export function isPHIAuthorized(userRole: string | undefined): boolean {
	return (
		userRole === partnerRoles.accountManagerPHIAuthourized ||
		userRole === partnerRoles.partnerAdminPHIAuthourized
	);
}
