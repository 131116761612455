import { ReactElement, ReactNode, useLayoutEffect, useState } from 'react';
import { useSpring } from 'react-spring';

import { Overlay, Wrapper } from './styles';

interface Props {
	children: ReactNode;
}

export default function FullscreenOverlay({ children }: Props): ReactElement {
	const [isReady, setIsReady] = useState(false);

	useLayoutEffect(() => {
		setIsReady(true);
	}, []);

	const springPropsIn = useSpring({
		to: {
			opacity: isReady ? 0.9 : 0,
			transform: isReady ? 'scale(1.0)' : 'scale(0.95)',
		},
		config: { mass: 5, tension: 400, friction: 75 },
	});

	return (
		<Wrapper>
			<Overlay style={springPropsIn}>{children}</Overlay>
		</Wrapper>
	);
}
