import { PressEvent } from '@react-types/shared';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';

import { adoptOrphans, ButtonSizes, Checkbox, SecondaryButton, Button, Modal } from '@calm-web/design-system';
import { booleanFromCheckbox } from '@calm-web/use-form';

import { useDefinedPartner } from '@/hooks/api/usePartner';
import { useSaml } from '@/hooks/api/useSaml';
import { ACCESS_CODE_DESCRIPTOR, type EditPartnerFormProps } from '@/hooks/forms/usePartnerForm';
import { useSubmitExistingPartnerForm } from '@/hooks/forms/useSubmitExistingPartnerForm';
import { IntegrationType, Partner, PartnerCategoryType } from '@/types/store/reducers';
import { Duration, SHOULD_RENEW } from '@/utils/SkuEnums';
import { isCalmLegacyCoachingPlan } from '@/utils/SkuUtils';

import { SsoToEfTransition } from '../IntegrationChanger';
import { StyledHr } from '../styles';
import messages from './messages';
import {
	ButtonWithBottomMargin,
	ModalText,
	SsoHelpLink,
	SsoSectionBody,
	SsoSectionTitle,
	WarningIcon,
	WarningText,
} from './styles';

const durationOptions: {
	value: Duration;
	label: MessageDescriptor;
}[] = [
	{
		value: Duration.OneMonth,
		label: messages.duration1Month,
	},
	{
		value: Duration.ThreeMonth,
		label: messages.duration3Months,
	},
	{ value: Duration.SixMonth, label: messages.duration6Months },
	{ value: Duration.OneYear, label: messages.duration1Year },
	{ value: Duration.Lifetime, label: messages.durationLifetime },
];

// We don't track whether or not they were on access codes vs eligibility file before their transition to SSO,
// so this is our hacky way to infer that
export const integrationWasAccessCodes = (formProps: EditPartnerFormProps): boolean =>
	formProps.model.userIdDescriptor === ACCESS_CODE_DESCRIPTOR;

export function DurationSelect({
	formProps,
	disabled = false,
}: {
	formProps: EditPartnerFormProps;
	disabled?: boolean;
}): ReactElement {
	const { formatMessage } = useIntl();

	const subscriptionCanRenew = booleanFromCheckbox(formProps.model.shouldRenew, SHOULD_RENEW);
	const calmLegacyCoachingSupportedDurationOptions = new Set([
		subscriptionCanRenew ? Duration.SixMonth : null,
		Duration.OneYear,
	]);
	const isDTCLeadGenPartnership = formProps?.model?.category === PartnerCategoryType.D2C_LEAD_GEN_PARTNERSHIP;
	const dtcLeadGenPartnershipDurationOptions = new Set([Duration.OneMonth, Duration.OneYear]);
	const selectableDurationOptions = isCalmLegacyCoachingPlan(formProps.model.productSKUType)
		? durationOptions.filter(durationOption =>
				calmLegacyCoachingSupportedDurationOptions.has(durationOption.value),
		  )
		: isDTCLeadGenPartnership
		? durationOptions.filter(durationOption => dtcLeadGenPartnershipDurationOptions.has(durationOption.value))
		: durationOptions;

	const shouldFallbackToFirstSelectOption =
		selectableDurationOptions.filter(durationOption => durationOption.value === formProps.model.duration)
			.length === 0;

	useEffect(() => {
		if (shouldFallbackToFirstSelectOption) {
			formProps.setProperty('duration', selectableDurationOptions[0].value);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [shouldFallbackToFirstSelectOption]);

	return (
		<div>
			<select
				{...formProps.bindInput('duration', 'select')}
				id="duration"
				placeholder="Duration"
				aria-labelledby="subscription-duration-label"
				disabled={disabled}
			>
				{selectableDurationOptions.map(({ value, label }) => {
					return (
						<option key={value} value={value}>
							{formatMessage(label)}
						</option>
					);
				})}
			</select>
		</div>
	);
}

export function SsoConversionStarter({
	currentIntegrationType,
	formProps,
}: {
	currentIntegrationType: IntegrationType | undefined;
	formProps: EditPartnerFormProps;
}): ReactElement {
	const { formatMessage } = useIntl();
	const partner = useDefinedPartner();
	const [submitExistingPartnerForm, { loading }] = useSubmitExistingPartnerForm(formProps);
	const handleStartChange = async (): Promise<void> => {
		await submitExistingPartnerForm(
			{
				integration_type: IntegrationType.SSO,
			},
			partner,
		);
	};

	return (
		<div>
			<SsoSectionBody>
				{formatMessage(messages.changeToSsoBody, {
					currentIntegrationType:
						currentIntegrationType === IntegrationType.ACCESS_CODES ? 'access codes' : 'eligibility file',
				})}
			</SsoSectionBody>
			<Button
				backgroundColor="blue3"
				onPress={handleStartChange}
				type="button"
				data-testid="start-sso-change"
				isLoading={loading}
			>
				Start change to SSO
			</Button>
		</div>
	);
}

function SsoConfigIncompleteWarning(): ReactElement {
	return (
		<WarningText>
			<WarningIcon />
			Configuration form is not complete
		</WarningText>
	);
}

export function SsoSetupDescription(): ReactElement {
	const { formatMessage } = useIntl();
	return (
		<SsoSectionBody>
			{formatMessage(messages.setUpSsoBody, {
				p: (...chunks: ReactNode[]) => <p>{chunks}</p>,
				b: (...chunks: ReactNode[]) => <b>{chunks}</b>,
				ul: (...chunks: ReactNode[]) => <ul>{chunks}</ul>,
				li: (...chunks: ReactNode[]) => <li>{chunks}</li>,
			})}
		</SsoSectionBody>
	);
}

function SsoSetupWalkthrough({
	partnerId,
	onClickOpenConfigurationForm,
	showSubtitle,
	showSsoConfigIncompleteWarning,
}: {
	partnerId: string;
	onClickOpenConfigurationForm: (e: PressEvent) => void;
	showSubtitle: boolean;
	showSsoConfigIncompleteWarning: boolean;
}): ReactElement {
	const { formatMessage } = useIntl();
	return (
		<>
			{showSubtitle ? <SsoSectionTitle>{formatMessage(messages.setUpSsoTitle)}</SsoSectionTitle> : null}
			<SsoSetupDescription />
			<Button
				backgroundColor="blue3"
				onPress={onClickOpenConfigurationForm}
				type="button"
				data-testid="configure-sso"
			>
				{formatMessage(messages.setUpSsoButton)}
			</Button>
			{showSsoConfigIncompleteWarning ? <SsoConfigIncompleteWarning /> : null}
			<SsoSectionBody>
				{formatMessage(messages.testingSsoBody, {
					title: (...chunks: ReactNode[]) => <h6>{chunks}</h6>,
					p: (...chunks: ReactNode[]) => <p>{chunks}</p>,
					b: (...chunks: ReactNode[]) => <b>{chunks}</b>,
					ul: (...chunks: ReactNode[]) => <ul>{chunks}</ul>,
					li: (...chunks: ReactNode[]) => <li>{chunks}</li>,
					reportingpagelink: (...chunks: ReactNode[]) => <Link to={`/${partnerId}/reporting`}>{chunks}</Link>,
				})}
			</SsoSectionBody>
		</>
	);
}

export function SsoConversionFinisher({
	onClickFinishSsoTransition,
	onClickCancelSsoTransition,
	wasAccessCodes,
	isLoading,
}: {
	onClickFinishSsoTransition: () => void;
	onClickCancelSsoTransition: () => void;
	wasAccessCodes: boolean;
	isLoading: boolean;
}): ReactElement {
	const { formatMessage } = useIntl();
	const [checked, setChecked] = useState(false);
	return (
		<>
			<SsoSectionTitle>{formatMessage(messages.finishSsoTitle)}</SsoSectionTitle>
			<SsoSectionBody>
				<Checkbox
					data-testid="finish-sso-checkbox"
					checked={checked}
					name="finish-sso-checkbox"
					onChange={() => setChecked(!checked)}
				>
					{adoptOrphans(formatMessage(messages.finishSsoCheckbox), 3)}
				</Checkbox>
				<SsoHelpLink
					data-testid="sso-help-link"
					href="https://docs.google.com/document/d/1zE5_iE1gC3BfVXrwLwZSNKvxEI3mBuU1lGfJZ1ZJ78o/edit#bookmark=id.9iru8f157n0e"
				>
					How do I test the configuration?
				</SsoHelpLink>
			</SsoSectionBody>
			<ButtonWithBottomMargin
				backgroundColor="blue3"
				onPress={onClickFinishSsoTransition}
				type="button"
				isDisabled={!checked}
				isLoading={isLoading}
				data-testid="transition-to-sso"
			>
				Transition this account to SSO
			</ButtonWithBottomMargin>
			<div>
				<SecondaryButton
					onPress={onClickCancelSsoTransition}
					size={ButtonSizes.Sm}
					data-testid="cancel-sso-change"
				>
					{formatMessage(messages.finishSsoCancelButton, {
						currentIntegrationType: wasAccessCodes ? 'Access Codes' : 'Eligibility File',
					})}
				</SecondaryButton>
			</div>
		</>
	);
}

export function SsoIntegrationManagement({
	isFullySSO,
	formProps,
	partner,
	integrationType,
	onClickFinishSsoTransition,
	onClickCancelSsoTransition,
	isLoading,
}: {
	isFullySSO: boolean;
	formProps: EditPartnerFormProps;
	partner: Partner;
	integrationType: IntegrationType;
	onClickFinishSsoTransition: () => void;
	onClickCancelSsoTransition: () => void;
	isLoading: boolean;
}): ReactElement {
	const { formatMessage } = useIntl();
	const history = useHistory();
	const onClickOpenConfigurationForm = (): void => {
		history.push({
			pathname: `/${partner.id}/account/sso`,
		});
	};
	const { data: samlConfig, loading: samlLoading } = useSaml(partner.id);
	const [showModal, setShowModal] = useState(false);
	const hasCompletedSamlForm = Boolean(samlConfig?.has_completed_saml_form);
	const showSsoConfigIncompleteWarning = !samlLoading && !hasCompletedSamlForm;
	const handleSsoTransition = (): void => {
		if (!hasCompletedSamlForm) {
			setShowModal(true);
			return;
		}
		onClickFinishSsoTransition();
	};

	if (!isFullySSO) {
		return (
			<div>
				<SsoSetupWalkthrough
					partnerId={partner.id}
					showSsoConfigIncompleteWarning={showSsoConfigIncompleteWarning}
					showSubtitle
					onClickOpenConfigurationForm={onClickOpenConfigurationForm}
				/>
				<StyledHr />
				<Modal
					isOpen={showModal}
					closeModal={() => setShowModal(false)}
					aria-labelledby="modal-sso-conversion-label"
				>
					<ModalText id="modal-sso-conversion-label">
						Please complete the SSO configuration form before finalizing the transition to SSO
					</ModalText>
					<Button backgroundColor="blue3" onPress={onClickOpenConfigurationForm}>
						{formatMessage(messages.setUpSsoButton)}
					</Button>
				</Modal>
				<SsoConversionFinisher
					onClickFinishSsoTransition={handleSsoTransition}
					onClickCancelSsoTransition={onClickCancelSsoTransition}
					wasAccessCodes={integrationWasAccessCodes(formProps)}
					isLoading={isLoading}
				/>
			</div>
		);
	}
	return (
		<div>
			<SsoSetupWalkthrough
				partnerId={partner.id}
				showSsoConfigIncompleteWarning={showSsoConfigIncompleteWarning}
				showSubtitle={false}
				onClickOpenConfigurationForm={onClickOpenConfigurationForm}
			/>
			<StyledHr marginTop={0} marginBottom={2} />
			<SsoSectionBody>
				<h6>Transition to EF</h6>
				<SsoToEfTransition partner={partner} integrationType={integrationType} />
			</SsoSectionBody>
		</div>
	);
}
