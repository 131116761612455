import { useEffect, useState } from 'react';

import { Admin } from '@/types/admin';
import { isPHIAuthorized } from '@/utils/RBAC';

const MAX_CALM_HEALTH_ADMINS = 2;

export const useCanAddPhiAdmin = (admins: Admin[], isCalmHealth: boolean): boolean => {
	const [canGrantPHI, setCanGrantPHI] = useState(true);

	useEffect(() => {
		if (isCalmHealth) {
			const phiAdmins = admins.filter(admin => isPHIAuthorized(admin.allowedUserRole));
			setCanGrantPHI(phiAdmins.length < MAX_CALM_HEALTH_ADMINS);
		}
	}, [admins, isCalmHealth]);

	return canGrantPHI;
};
