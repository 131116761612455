import styled from 'styled-components';
import { palette } from 'styled-tools';

import { FontSizes, fontSize, units } from '@calm-web/design-system';

export const TextBlock = styled.div``;

export const ButtonRow = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	:first-child {
		margin-right: ${units(2)};
	}
`;

export const Label = styled.label`
	${fontSize(FontSizes.sm)}
	color: ${palette('gray5')}
`;

export const SegmentsInfoStyled = styled.div``;

export const Container = styled.div`
	${TextBlock} {
		margin-bottom: ${units(1)};
	}

	${SegmentsInfoStyled} {
		margin-top: ${units(3)};
	}

	${ButtonRow} {
		margin-top: ${units(1)};
	}
`;

export const AdditionalCoverageMessage = styled.div``;

export const Prompt = styled.div``;

export const AdditionalCoverage = styled.div`
	text-align: center;

	${AdditionalCoverageMessage} {
		margin-bottom: ${units(1)};
	}

	${Prompt} {
		margin-bottom: ${units(1)};
	}
`;

export const UploadWrapper = styled.div`
	margin-bottom: ${units(4)};
`;

export const UploadButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: ${units(2)};
`;
