import { AxiosResponse } from 'axios';
import { DateTime } from 'luxon';
import useSWR from 'swr';

import { useApi } from '@/hooks/api';
import { SegmentSelectState, segmentsToJSON } from '@/utils/segments';

import { ApiResponse, RefetchOption } from '../types';

export function useMostRecentEndDate({
	partnerId,
	segmentSelection,
	startDate = DateTime.local().minus({ weeks: 12 }).toJSDate(),
	endDate = DateTime.local().minus({ days: 1 }).toJSDate(),
}: {
	partnerId: string;
	startDate?: Date;
	endDate?: Date;
	segmentSelection?: SegmentSelectState;
}): ApiResponse<string> & RefetchOption {
	const apiRequest = useApi();

	const [segmentFilter, swrString] = segmentsToJSON(segmentSelection, startDate, endDate);

	const endpoint = `b2b/partners/${partnerId}/reporting/end-date`;

	const { data, error, mutate } = useSWR<AxiosResponse | undefined>(
		`${endpoint}${swrString}`,
		async () => {
			const response = await apiRequest({ endpoint, method: 'POST', body: segmentFilter });
			return response;
		},
		{ errorRetryCount: 0 },
	);
	const apiReport = data?.data.end_date as string | undefined;
	const refetch = (): Promise<AxiosResponse | undefined> => mutate(undefined, true);
	return {
		data: apiReport,
		error,
		loading: !data && !error,
		refetch,
	};
}
