import styled from 'styled-components';
import { palette } from 'styled-tools';

import { Card, units } from '@calm-web/design-system';

export const SegmentsInfoStyled = styled.div``;

export const ExampleEmailContainer = styled(Card)`
	margin-top: ${units(1)};
	padding: ${units(2)};
	color: ${palette('gray6')};
	text-align: left;
`;

export const ButtonGroup = styled.div`
	display: flex;
	justify-content: end;
	gap: ${units(2)};
	margin-top: ${units(1)};
`;

export const Container = styled.div`
	margin-top: ${units(1)};
`;

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	margin: ${units(1, 0)};
`;
export const Centered = styled.div`
	text-align: center;
`;
