import styled from 'styled-components';
import { palette, theme } from 'styled-tools';

import {
	fontSize,
	FontSizes,
	fontWeight,
	FontWeights,
	Text,
	TextButton,
	units,
} from '@calm-web/design-system';

import { SuccessButton } from '../SyncButton/styles';

export const Container = styled.div`
	width: 100%;
	max-width: ${theme('layout.mainMaxWidth')};
	background-color: white;
	border: 2px solid ${palette('blue3')};
	border-radius: 4px;
	padding: ${units(3)};
	text-align: center;
	${fontSize(FontSizes.base)};
	${fontWeight(FontWeights.Regular)}

	${SuccessButton} {
		margin-left: ${units(1)};
	}
`;

export const Inner = styled.div`
	margin: auto;
	max-width: ${units(84)};
`;

export const BodyWrapper = styled.div`
	margin: ${units(3, 0)};
`;

export const ButtonGroup = styled.div`
	display: flex;
	justify-content: center;
`;

export const SmallTextButton = styled(TextButton).attrs({
	size: FontSizes.sm,
})``;

export const BoldText = styled(Text)`
	${fontWeight(FontWeights.Demi)}
`;
