import { defineMessages } from 'react-intl';

export default defineMessages({
	engagementEyebrow: {
		id: 'reporting.signupsAndEngagement.engagementEyebrow',
		defaultMessage: 'Engagement',
		description: 'Data label for how many signed-up users have used the app',
	},
	engagementBody: {
		id: 'reporting.signupsAndEngagement.engagementBody',
		defaultMessage: '{engagedCount} of your {signupCount} sign-ups have used Calm.',
		description:
			'Data description show below a number like "50%", for how many signed-up users have used the app',
	},
	engagementGraphTooltipLabel: {
		id: 'reporting.signupsAndEngagement.engagementGraphTooltipLabel',
		defaultMessage: 'Average sessions',
		description: 'Label for the tooltip on the sessions per aggregation level graph',
	},
	engagementResourceDescription: {
		id: 'reporting.signupsAndEngagement.engagementResourceDescription',
		defaultMessage: 'Here are some recommended resources to help you drive your engagement rate.',
		description: 'Description for resource links',
	},
});
