import styled from 'styled-components';
import { palette } from 'styled-tools';

import { FontSizes, minWidth, fontSize, fontWeight, FontWeights, units } from '@calm-web/design-system';

import CheckFilledSvg from 'icons/check.svg';
import WarningSvg from 'icons/warning-outline.svg';

export const ConfirmationText = styled.div`
	text-align: center;
	width: 100%;
	color: ${palette('errorRed')};
	${fontWeight(FontWeights.Medium)};
`;

export const LeftButtonWrapper = styled.div`
	margin-right: ${units(3)};
`;

export const ErrorMessage = styled.div`
	color: ${palette('errorRed')};
	${fontWeight(FontWeights.Medium)};
`;

export const WarningIcon = styled(WarningSvg)`
	height: ${units(5)};
	path {
		fill: ${palette('errorRed')};
	}
`;

export const LoadingText = styled.div`
	text-align: center;
	color: ${palette('gray8')};
	${fontSize(FontSizes.sm)}
	${minWidth('tablet')} {
		min-width: ${units(52)};
	}
`;

export const LoaderContainer = styled.div`
	height: ${units(4)};
`;

export const SuccessIcon = styled(CheckFilledSvg)`
	height: ${units(5)};
	path {
		fill: ${palette('errorRed')};
	}
`;
