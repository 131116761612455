import { capitalize } from 'lodash';
import { ReactElement, ReactNode, RefObject, useMemo } from 'react';
import { LineChart, XAxis, YAxis, Tooltip, ResponsiveContainer, TooltipProps, Line, Legend } from 'recharts';

import { PaletteColor, TooltipBody } from '@calm-web/design-system';

import { ReportContent } from '@/hooks/api/reporting/useContentReport';
import { aggregationLevelToTimeUnit, ReportingAggregationLevel } from '@/hooks/api/reporting/utils';

import GraphLegend from '../../Legend';
import { localTimezoneDate } from '../../SignupsAndEngagement/Graph';
import { TooltipLabel, TooltipValue, TooltipContainer } from '../../SignupsAndEngagement/Graph/styles';
import { LineChartContainer, TooltipBadge } from './styles';

interface Props {
	data: ReportContent[] | undefined;
	aggregationLevel?: ReportingAggregationLevel;
	forwardedRef?: RefObject<HTMLDivElement>;
}

const LEGEND_INFO = [
	{ key: 'stress', color: '#555BE4', paletteColor: 'blue3' as PaletteColor },
	{ key: 'relationship', color: '#75B2F0', paletteColor: 'graphBlue' as PaletteColor },
	{ key: 'focus', color: '#F39871', paletteColor: 'graphOrange' as PaletteColor },
	{ key: 'gratitude', color: '#F075C7', paletteColor: 'graphPink' as PaletteColor },
	{ key: 'self_esteem', color: '#9AF075', paletteColor: 'graphLimeGreen' as PaletteColor },
	{ key: 'anxiety', color: '#75F0F0', paletteColor: 'graphTeal' as PaletteColor },
];

function ContentGraph({ data, aggregationLevel, forwardedRef }: Props): ReactElement {
	const ChartTooltip = (props: TooltipProps<number, string | number>): ReactNode => {
		const { active, payload } = props;
		if (!active || !payload || !payload[0]) {
			return null;
		}

		const formattedDate = localTimezoneDate(payload?.[0].payload.starting_ts).toLocaleDateString();
		const aggregationLevelTimeUnit = aggregationLevelToTimeUnit[aggregationLevel ?? 'daily'];
		return (
			<TooltipBody>
				{capitalize(aggregationLevelTimeUnit)} of {formattedDate}
				{payload.map(({ dataKey: emotion, value }) => {
					return (
						<TooltipContainer key={`${emotion} tooltip value`}>
							<TooltipBadge $color={LEGEND_INFO.find(row => row.key === emotion)?.color} />
							<TooltipLabel>{emotion}</TooltipLabel>
							<TooltipValue>{value?.toLocaleString()}</TooltipValue>
						</TooltipContainer>
					);
				})}
			</TooltipBody>
		);
	};

	const lineType = 'monotone';
	const hasDot = false;
	const strokeWidth = 2;

	const stats = useMemo(() => {
		const sortedData = data?.sort((a, b) => {
			return localTimezoneDate(a.starting_ts) < localTimezoneDate(b.starting_ts) ? -1 : 1;
		});
		return sortedData?.map(content => {
			content.shortDate = localTimezoneDate(content.starting_ts).toLocaleDateString(undefined, {
				month: 'numeric',
				day: 'numeric',
			});
			return content;
		});
	}, [data]);
	const maxValue = useMemo(
		() =>
			data?.reduce((acc, content) => {
				const maxValueInContent = (Object.keys(content) as (keyof typeof content)[]).reduce((acc, key) => {
					if ((key as string) === 'other') {
						return acc;
					}
					const val = content[key];
					return typeof val === 'number' ? Math.max(acc, val) : acc;
				}, 0);
				return Math.max(acc, maxValueInContent);
			}, 0) ?? 0,
		[data],
	);
	const orderOfMagnitude = useMemo(() => Math.ceil(Math.log10(maxValue)), [maxValue]);

	return (
		<LineChartContainer data-testid="content-graph">
			<ResponsiveContainer height={240}>
				<LineChart data={stats} margin={{ top: 24, right: 12, left: 0, bottom: 0 }}>
					<Legend
						wrapperStyle={{ top: 0, left: -12 }}
						verticalAlign="top"
						content={<GraphLegend legendInfo={LEGEND_INFO} />}
					/>
					<XAxis
						dataKey="shortDate"
						padding={{ left: 12, right: 12 }}
						tickMargin={8}
						height={32}
						axisLine={{ stroke: '#E2E2E2' }}
						interval={0}
					/>
					<YAxis
						padding={{ top: 12 }}
						axisLine={{ stroke: '#E2E2E2' }}
						tickLine={false}
						tickFormatter={(v: string): string => parseInt(v, 10).toLocaleString()}
						width={16 + orderOfMagnitude * 8}
					/>
					<Tooltip isAnimationActive={false} cursor={false} content={ChartTooltip} />
					{LEGEND_INFO.map(row => {
						return (
							<Line
								key={`${row.key} line`}
								dataKey={row.key}
								stroke={row.color}
								type={lineType}
								dot={hasDot}
								strokeWidth={strokeWidth}
							/>
						);
					})}
				</LineChart>
			</ResponsiveContainer>
		</LineChartContainer>
	);
}

export default ContentGraph;
