import { EditHealthReferralFormProps } from '@/hooks/forms/useHealthReferralForm';
import { HealthReferralDefinitions, REFERRAL_DEFN_ID_ARRAY } from '@/types/health';
import getUrl from '@/utils/getUrl';

const createSVGLogo = async (
	form: EditHealthReferralFormProps,
	name: string,
	index: number,
): Promise<void> => {
	const response = await fetch(`${getUrl('partner-portal')}/_n/images/${name}.svg`);
	const blob = await response.blob();
	const file = new File([blob], `${name}=${index}.svg`, { type: 'image/svg+xml' });
	form.setProperty('uploaded_logo', [file]);
};

export const ReferralFormDescriptions = {
	[HealthReferralDefinitions.PHQ_0]:
		'We recommend that you continue with Calm Health’s programs, which will introduce you to some concepts and techniques that you may find helpful.',
	[HealthReferralDefinitions.PHQ_1_4]:
		'We recommend that you continue with Calm Health’s programs, which will introduce you to some concepts and techniques that you may find helpful.',
	[HealthReferralDefinitions.PHQ_5_9]:
		'We recommend that you continue with Calm Health’s programs, which will introduce you to some concepts and techniques that you may find helpful.  Based on your screening results, you may want to consider reaching out to a mental health provider for care.',
	[HealthReferralDefinitions.PHQ_10_14]:
		'Based on your screening results, you should consider reaching out to a mental health provider for care.  In addition, we recommend that you continue with Calm Health’s programs, which will introduce you to some concepts and techniques that you may find helpful.',
	[HealthReferralDefinitions.PHQ_15]:
		'Based on your screening results, you should consider reaching out to a mental health provider for care.  In addition, we recommend that you continue with Calm Health’s programs, which will introduce you to some concepts and techniques that you may find helpful.',
	[HealthReferralDefinitions.CRISIS]:
		'The Lifeline provides 24/7, free and confidential support for people in distress, prevention and crisis resources for you or your loved ones.',
} as const;

export const fillAllForms = async (forms: EditHealthReferralFormProps[]): Promise<void> => {
	forms.forEach(async (form, index) => {
		const referralDefinition = REFERRAL_DEFN_ID_ARRAY[index];
		const description = (): string => {
			return ReferralFormDescriptions[referralDefinition];
		};

		if (referralDefinition === HealthReferralDefinitions.CRISIS) {
			form.setProperty('title', 'In need of immediate assistance?');
			form.setProperty('banner_title', 'Call or text 988');
			form.setProperty(
				'banner_subtitle',
				'If you or someone you know are thinking about harming yourself or attempting suicide, tell someone who can help you right away.',
			);
			await createSVGLogo(form, '988', index);
			form.setProperty('description', description());
			form.setProperty('link_out_1_text', 'Call 988');
			form.setProperty('link_out_1_type', 'phone');
			form.setProperty('link_out_1_data', '988');
			form.setProperty('show_link_out_2', 'true');
			form.setProperty('link_out_2_text', 'Text 988');
			form.setProperty('link_out_2_type', 'sms');
			form.setProperty('link_out_2_data', '988');
			form.setProperty('show_link_out_3', 'false');
		} else {
			form.setProperty('banner_title', 'Find Mental Health Resources');
			form.setProperty('banner_subtitle', 'Find Help and Support');
			await createSVGLogo(form, 'adaa', index);
			form.setProperty('description', description());
			form.setProperty('link_out_1_text', 'Find Help and Support');
			form.setProperty('link_out_1_type', 'url');
			form.setProperty('link_out_1_data', 'https://findyourtherapist.adaa.org/');
			form.setProperty('show_link_out_2', 'false');
			form.setProperty('show_link_out_3', 'false');
		}
	});
};
