import { defineMessages } from 'react-intl';

export default defineMessages({
	LogoText: {
		id: 'pathways.announcementTemplate.logoText',
		defaultMessage: '{pathwayTitle} Pathway',
		description: 'Logo Text',
	},
	emailHeader: {
		id: 'pathways.announcementTemplate.header',
		defaultMessage: '{partnerName} has invited you to join {pathwayTitle} Pathway',
		description: 'Email template header',
	},
	emailDescription: {
		id: 'pathways.announcementTemplate.description',
		defaultMessage:
			'Pathways are tailored programs that’ll help you develop skills to tackle specific mental health challenges and improve your overall well-being.',
		description: 'Email template description',
	},
	descriptionBoxHeader: {
		id: 'pathways.announcementTemplate.keyInfoBox.header',
		defaultMessage: 'Key Information about the {pathwayTitle} Pathway:',
		description: 'Key info box header',
	},
	keyItem2: {
		id: 'pathways.announcementTemplate.keyInfoBox.item2',
		defaultMessage: 'This Pathway runs from <bold>{startDate}</bold> to <bold>{endDate}</bold>',
		description: 'Key info bullet text',
	},
	keyItem3: {
		id: 'pathways.announcementTemplate.keyInfoBox.item3.revised',
		defaultMessage:
			'Within the <bold>{pathwayDuration}</bold>, you’ll learn new skills through Calm’s in-app content pushed to you every <bold>{pathwaySchedule}.</bold>',
		description: 'Key info bullet text',
	},
	keyItem4: {
		id: 'pathways.announcementTemplate.keyInfoBox.item4',
		defaultMessage:
			'If you haven’t already, download the free Calm app today via <applestore>Apple Store</applestore> or <googleplay>Google Play Store.</googleplay>',
		description: 'Key info bullet text',
	},
	notificationSupportText: {
		id: 'pathways.announcementTemplate.notificationSupport',
		defaultMessage:
			'For further assistance on the opt-in and opt-out process for push notifications, please refer to <supportlink>this support article.</supportlink>',
		description: 'Text for push notification support',
	},
	optInButton: {
		id: 'pathways.reminderTemplate.optInButton',
		defaultMessage: 'Opt-in to Push Notifications',
		description: 'Opt in button text',
	},
});
