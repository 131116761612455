import { ReactElement, ReactNode, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Column } from 'react-table';

import { Hr, Loader } from '@calm-web/design-system';
import { HrWeight } from '@calm-web/design-system/lib/components/Hr/types';

import Table from '@/components/ui/Table';
import { useEligibilityUploadsDetails } from '@/hooks/api/useEligibility';
import { useIsMobile } from '@/hooks/layout/useIsMobile';
import { EligibilityUploadError } from '@/types/eligibility';
import { Partner } from '@/types/store/reducers';
import { getErrorMessage } from '@/utils/errorMessages';
import { addCommasToNumber } from '@/utils/helpers';

import { MobileDataColumn, MobileLabel, MobileRow, MobileWrapper } from '../../styles';
import eligibilityMessages from '../messages';
import { DividerContainer, TableColumn } from '../styles';
import messages from './messages';
import { Title, Header, HeaderTable, LogTableText, TableWrapper, TimeText } from './styles';

interface LogDetailsProps {
	rows_total: number | null;
	total_changes: number | null;
	number_of_errors: number | null;
}

interface Props {
	partner: Partner;
	id: string;
	isCalmHealth: boolean;
}

function DefaultCell({ value }: { value: number | number[] | null }): ReactElement {
	if (value === null || value === 0) {
		return (
			<DividerContainer>
				<Hr weight={HrWeight.Heavy} />
			</DividerContainer>
		);
	}
	if (typeof value === 'number') {
		return <LogTableText>{addCommasToNumber(value)}</LogTableText>;
	}
	return <LogTableText>{addCommasToNumber(value.length)}</LogTableText>;
}

function DefaultErrorCell({ value }: { value: number[] | undefined }): ReactElement {
	return <TableColumn>{(value && value.length) ?? 0}</TableColumn>;
}

export default function LogDetails({ partner, id, isCalmHealth }: Props): ReactElement {
	const { formatMessage } = useIntl();
	const [isMobile] = useIsMobile();
	const partnerId = partner.id;

	const { loading, data: logDetailsData } = useEligibilityUploadsDetails({
		partnerId,
		id,
	});

	if (loading) {
		return <Loader data-testid="loader" color="gray2" />;
	}

	if (!logDetailsData) {
		return <></>;
	}

	const {
		processing_start_at,
		processing_end_at,
		segments_added,
		segments_deleted,
		segments_updated,
		users_added,
		users_deleted,
		rows_total,
		users_upserted,
		name,
		error,
	} = logDetailsData.eligibility_upload;

	const formattedUploadedOnDate = new Date(processing_start_at).toLocaleString().toLowerCase();
	const formattedProcessedOnDate = new Date(processing_end_at).toLocaleString().toLowerCase();
	const number_of_errors = error?.length ?? 0;
	const total_changes = isCalmHealth
		? users_upserted ?? 0
		: (segments_added ?? 0) +
		  (segments_deleted ?? 0) +
		  (segments_updated ?? 0) +
		  (users_added ?? 0) +
		  (users_deleted ?? 0);

	function ErrorTableCell({ row }: { row: { original: EligibilityUploadError } }): ReactElement {
		const { formatMessage } = useIntl();
		const { code, subcode, message } = row.original;

		const errorContent = useMemo(() => {
			const errorName = subcode ?? code;
			const errorMessage = getErrorMessage(formatMessage, code, subcode, message);

			return formatMessage(eligibilityMessages.errorDescription, {
				errorName,
				errorMessage,
				bold: (...chunks: ReactNode[]) => <b>{chunks}</b>,
			});
		}, [code, subcode, message, formatMessage]);

		return <TableColumn>{errorContent}</TableColumn>;
	}

	const columns: Column<LogDetailsProps>[] = [
		{
			Header: formatMessage(messages.rowsHeader),
			accessor: 'rows_total',
			Cell: DefaultCell,
		},
		{
			Header: formatMessage(messages.changesHeader),
			accessor: 'total_changes',
			Cell: DefaultCell,
		},
		{
			Header: formatMessage(messages.errorsHeader),
			accessor: 'number_of_errors',
			Cell: DefaultCell,
		},
	];

	const errorTableColumns: Column<EligibilityUploadError>[] = [
		{
			Header: formatMessage(messages.numberOfErrorRowsHeader),
			accessor: 'rows',
			Cell: DefaultErrorCell,
			minWidth: 110,
			width: '5%',
		},
		{
			Header: formatMessage(messages.descriptionHeader),
			accessor: 'code',
			Cell: ErrorTableCell,
		},
	];

	return (
		<>
			<Header>
				<Title>{name.split('/').pop() ?? ''}</Title>
				<TimeText>
					{formatMessage(messages.uploadedOnText, {
						date: formattedUploadedOnDate,
					})}
				</TimeText>
				<TimeText>
					{formatMessage(messages.processedOnText, {
						date: formattedProcessedOnDate,
					})}
				</TimeText>
			</Header>
			<TableWrapper>
				<HeaderTable>
					<Table
						dataTestId="eligibility-upload-log-detail-table"
						columns={columns}
						data={[{ rows_total: rows_total ?? null, total_changes, number_of_errors }]}
						showRowLines={false}
						loading={loading}
						pageCount={1}
						alignHeadingsToText
						cellNoPadding
					/>
				</HeaderTable>
				{error &&
					(isMobile ? (
						<MobileWrapper data-testid="mobile-eligibility-upload-log-detail-error-table">
							{error?.map((err, index) => (
								<MobileRow key={index}>
									<MobileDataColumn>
										<MobileLabel>{formatMessage(messages.numberOfErrorRowsHeader)}</MobileLabel>
										<DefaultCell value={err.rows?.length ?? 0} />
									</MobileDataColumn>
									<MobileDataColumn>
										<MobileLabel>{formatMessage(messages.descriptionHeader)}</MobileLabel>
										<ErrorTableCell row={{ original: err }} />
									</MobileDataColumn>
								</MobileRow>
							))}
						</MobileWrapper>
					) : (
						<Table
							dataTestId="eligibility-upload-log-detail-error-table"
							columns={errorTableColumns}
							data={error ?? []}
							loading={loading}
							pageCount={1}
							alignHeadingsToText
							cellVerticalAlign="middle"
						/>
					))}
			</TableWrapper>
		</>
	);
}
