import { defineMessages } from 'react-intl';

export default defineMessages({
	updateTitle: {
		id: 'plan.cardModal.updateTitle',
		defaultMessage: 'Update your payment details',
		description: 'Title for the update payment details modal',
	},
	cancel: {
		id: 'plan.cardModal.cancel',
		defaultMessage: 'cancel',
		description: 'The word cancel',
	},
	updateDetails: {
		id: 'plan.cardModal.updateDetails',
		defaultMessage: 'Update payment details',
		description: 'Update button text for the payment details modal',
	},
});
