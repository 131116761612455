import { FC, useState } from 'react';
import { useIntl } from 'react-intl';

import { Checkbox } from '@calm-web/design-system';
import { stringFromModelValue } from '@calm-web/use-form';

import { usePermissions } from '@/hooks/auth';
import type { EditPartnerFormProps } from '@/hooks/forms/usePartnerForm';
import { useShouldShowBenefitEmailUpdates } from '@/hooks/useShouldShowBenefitEmailUpdates';
import { PartnerCategoryType } from '@/types/store/reducers';

import EmailTemplateLink from './EmailTemplateLink';
import BenefitsEmailTemplate from './EmailTemplates/BenefitsEmailTemplate';
import SurveyEmailTemplate from './EmailTemplates/SurveyEmailTemplate';
import ManualSurveyButton from './ManualSurveyButton';
import messages from './messages';
import {
	CheckboxDetails,
	Description,
	DetailsList,
	EmailSection,
	Title,
	ToggleContentButton,
	EmailTypeDetails,
	EmailTypeTitle,
} from './styles';

interface Props {
	formProps: EditPartnerFormProps;
	isEdit?: boolean;
	shouldShowManualScheduling: boolean;
	partnerName?: string;
	isShowingPartnerSave?: boolean;
}

export default function EmailPreferences({
	formProps,
	isEdit,
	shouldShowManualScheduling,
	partnerName,
	isShowingPartnerSave = false,
}: Props): ReturnType<FC> {
	const [hasValidPermissions, actions] = usePermissions();
	const [showUserEmailPreferenceDetails, setShowUserEmailPreferenceDetails] = useState(false);
	const { formatMessage } = useIntl();
	const requiredActions = isEdit ? [actions.UPDATE] : [actions.CREATE];
	const isSalesTrialPartner =
		stringFromModelValue(formProps.model.category) === PartnerCategoryType.SALES_TRIAL;

	const shouldShowBenefitInviteAndReminders = hasValidPermissions(
		'email_config_can_send_benefit_reminders',
		requiredActions,
	);
	const shouldShowSurvey = hasValidPermissions('email_config_can_send_survey', requiredActions);

	const shouldShowDescriptionMessage = shouldShowBenefitInviteAndReminders || shouldShowSurvey;

	const shouldShowBenefitEmailUpdates = useShouldShowBenefitEmailUpdates();

	const benefitEmailCheckbox = (
		<>
			<Checkbox
				{...formProps.bindWithErrorProps(
					'emailConfig',
					'checkbox',
					'email_config_can_send_benefit_reminders',
				)}
				id="benefit-reminders"
			>
				{formatMessage(messages.benefitReminders)}
			</Checkbox>
			<CheckboxDetails>
				{formatMessage(messages.benefitRemindersDescription)}
				{shouldShowBenefitEmailUpdates && (
					<EmailTemplateLink>
						<BenefitsEmailTemplate partnerName={partnerName} />
					</EmailTemplateLink>
				)}
			</CheckboxDetails>
		</>
	);

	const salesPartnerBenefitEmail = (
		<>
			<EmailTypeTitle>{formatMessage(messages.benefitReminders)}</EmailTypeTitle>
			<EmailTypeDetails>{formatMessage(messages.benefitRemindersDescription)}</EmailTypeDetails>
		</>
	);

	const surveyEmailCheckbox = (
		<>
			<Checkbox
				{...formProps.bindWithErrorProps('emailConfig', 'checkbox', 'email_config_can_send_survey')}
				id="survey"
			>
				{formatMessage(messages.survey)}
			</Checkbox>
			<CheckboxDetails>
				{formatMessage(messages.surveyDescription)}
				<EmailTemplateLink>
					<SurveyEmailTemplate />
				</EmailTemplateLink>
			</CheckboxDetails>
		</>
	);

	const salesPartnerSurveyEmail = (
		<>
			<EmailTypeTitle>{formatMessage(messages.survey)}</EmailTypeTitle>
			<EmailTypeDetails>
				{formatMessage(messages.surveyDescription)}
				<EmailTemplateLink>
					<SurveyEmailTemplate />
				</EmailTemplateLink>
			</EmailTypeDetails>
		</>
	);

	/*
	 ** Sales trial partners are only activated for 14 days so they can only view but not edit/select automated email preferences
	 ** With the exception being manually scheduled emails that they can send within the 14 days
	 */
	return (
		<EmailSection>
			<Title>{formatMessage(messages.title)}</Title>
			{shouldShowDescriptionMessage && <Description>{formatMessage(messages.description)}</Description>}
			{shouldShowBenefitInviteAndReminders &&
				(isSalesTrialPartner ? salesPartnerBenefitEmail : benefitEmailCheckbox)}
			{shouldShowBenefitInviteAndReminders &&
				showUserEmailPreferenceDetails &&
				shouldShowBenefitEmailUpdates && (
					<DetailsList>
						<li>{formatMessage(messages.benefitDetail1)}</li>
					</DetailsList>
				)}
			{shouldShowSurvey && (isSalesTrialPartner ? salesPartnerSurveyEmail : surveyEmailCheckbox)}
			{shouldShowSurvey && showUserEmailPreferenceDetails && (
				<DetailsList>
					<li>{formatMessage(messages.surveyDetail1)}</li>
					{!isSalesTrialPartner && <li>{formatMessage(messages.surveyDetail2)}</li>}
					<li>{formatMessage(messages.surveyDetail3)}</li>
					<li>{formatMessage(messages.surveyDetail4)}</li>
				</DetailsList>
			)}
			{shouldShowSurvey && (
				<ToggleContentButton
					type="button"
					onClick={() => setShowUserEmailPreferenceDetails(!showUserEmailPreferenceDetails)}
				>
					{formatMessage(showUserEmailPreferenceDetails ? messages.showLess : messages.showMore)}
				</ToggleContentButton>
			)}
			{/* Evaluate on manual scheduling FF, permissions allowed, and feedback email checkbox value */}
			{shouldShowManualScheduling && shouldShowSurvey && (
				<ManualSurveyButton isShowingPartnerSave={isShowingPartnerSave} />
			)}
		</EmailSection>
	);
}
