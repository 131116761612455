import { defineMessages } from 'react-intl';

export default defineMessages({
	LogoText: {
		id: 'pathways.reminderTemplate.logoText',
		defaultMessage: '{pathwayTitle} Pathway',
		description: 'Logo Text',
	},
	emailHeader: {
		id: 'pathways.reminderTemplate.header',
		defaultMessage: 'Hi {user},',
		description: 'Email template header',
	},
	emailDescription: {
		id: 'pathways.reminderTemplate.description',
		defaultMessage:
			'We hope you’re excited to start developing new skills in the {pathwayTitle} Pathway which is starting on {startdate}!',
		description: 'Email template description',
	},
	descriptionBoxHeader: {
		id: 'pathways.reminderTemplate.keyInfoBox.header',
		defaultMessage: 'Friendly reminders to help you get ready:',
		description: 'Key info box header',
	},
	keyItem2: {
		id: 'pathways.reminderTemplate.keyInfoBox.item2',
		defaultMessage:
			'<bold>Redeem Calm Subscription:</bold> <redemptionlink>Click here</redemptionlink> to redeem your free Calm Premium subscription if you haven’t done so already.',
		description: 'Key info bullet text',
	},
	keyItem3: {
		id: 'pathways.reminderTemplate.keyInfoBox.item3',
		defaultMessage:
			'<bold>Download the Calm App:</bold> If you haven’t already, download the free Calm app today via <applestore>Apple Store</applestore> or <googleplay>Google Play Store.</googleplay>',
		description: 'Key info bullet text',
	},
	keyItem4: {
		id: 'pathways.reminderTemplate.keyInfoBox.item4',
		defaultMessage:
			'<bold>Opt-in to Push Notifications:</bold> <enablepush>Click here</enablepush> to enable push notifications on your mobile device. You’ll receive guided content for the pathway every {cadence}.',
		description: 'Key info bullet text',
	},
	notificationSupportText: {
		id: 'pathways.reminderTemplate.notificationSupport',
		defaultMessage:
			'For any questions related to the opt-in and opt-out process for the push notifications on your mobile device, you can <supportlink>get more information here.</supportlink>',
		description: 'Text for push notification support',
	},
	supportNote: {
		id: 'pathways.reminderTemplate.notificationSupport.note',
		defaultMessage: 'Note that you will only be able to opt into push notifications on the Calm app.',
		descriptions: 'Note about push notifications',
	},
	optInButton: {
		id: 'pathways.reminderTemplate.optInButton',
		defaultMessage: 'Opt-in to Push Notifications',
		description: 'Opt in button text',
	},
});
