import { EnvConfig } from '@/env_config';
import { ProgramOrGuideItem } from '@/types/content';

import ContentCard from '../ContentCard';
import { SampleContentCardsContainer } from './styles';

interface SampleContentCardsProps {
	items: ProgramOrGuideItem[];
}

const SampleContentCards: React.FC<SampleContentCardsProps> = ({ items }) => {
	const contentCards = items.map(item => {
		const guideUrl = `${EnvConfig.partnerWebUrl}/app/player/${item.guide_id}`;
		// For Dailies, prefer the episode title to the series title
		const title = item.promotional_text ?? item.description ?? item.title;
		return (
			<ContentCard
				key={item.guide_id}
				category={item.search_category}
				title={title}
				narrator={item.narrator_name}
				image={item.image}
				contentDuration={item.duration}
				assetUrl={guideUrl}
			/>
		);
	});
	return <SampleContentCardsContainer>{contentCards}</SampleContentCardsContainer>;
};

export default SampleContentCards;
