import { FC, MutableRefObject, useState } from 'react';

import {
	Button,
	CloseButton,
	FontSizes,
	FontWeights,
	IconButton,
	OnChangePseudoEvent,
	Text,
	TextArea,
	TextButton,
} from '@calm-web/design-system';

import {
	CustomMessageCard,
	MessageButtonGroup,
	PencilIcon,
	CustomMessageHeadline,
	EditWrapper,
} from './styles';

interface Props {
	customMessageRef: MutableRefObject<string>;
	setShowMessageSection: (show: boolean) => void;
}

const CustomMessage: FC<Props> = ({ customMessageRef, setShowMessageSection }) => {
	const [customMessage, setCustomMessage] = useState('');
	const [isEditing, setisEditing] = useState(false);

	const handleMessageReset = (): void => {
		setisEditing(false);
		customMessageRef.current = '';
		setCustomMessage('');
	};

	const handleMessageSave = (): void => {
		setisEditing(false);
	};

	const onCustomMessageChange = (e: OnChangePseudoEvent): void => {
		const regex = /<|>/g;
		const parsedMessage = e.target.value.replace(regex, '');
		setCustomMessage(parsedMessage);
		customMessageRef.current = parsedMessage;
	};

	return (
		<CustomMessageCard>
			{customMessage || isEditing ? (
				<>
					<CustomMessageHeadline el="span" weight={FontWeights.Medium} size={FontSizes.sm}>
						A message from your HR team
					</CustomMessageHeadline>
					<MessageButtonGroup>
						{customMessage && !isEditing && (
							<IconButton
								color="blue3"
								aria-label="Edit custom message"
								Icon={PencilIcon}
								onPress={() => setisEditing(true)}
							/>
						)}
						<CloseButton aria-label="Remove custom message" color="blue3" onPress={handleMessageReset} />
					</MessageButtonGroup>
					{isEditing ? (
						<EditWrapper>
							<TextArea
								maxLength={1000}
								value={customMessage}
								aria-label="Share your excitement about having Calm as a benefit"
								placeholder="Share your excitement about having Calm as a benefit"
								name="customMessage"
								noValidation
								onChange={onCustomMessageChange}
							/>
							<TextButton onClick={handleMessageSave} hideUnderline size={FontSizes.sm} textColor="blue3">
								Save
							</TextButton>
						</EditWrapper>
					) : (
						<Text el="p">
							<i>&quot;{customMessage}&quot;</i>
						</Text>
					)}
				</>
			) : (
				<>
					<Button
						backgroundColor={'transparent'}
						textColor="blue3"
						hideShadow
						size="md"
						onPress={() => setisEditing(true)}
					>
						Add a custom message to your employees (optional)
					</Button>
					<MessageButtonGroup>
						<CloseButton
							color="blue3"
							aria-label="Remove message section"
							onPress={() => setShowMessageSection(false)}
						/>
					</MessageButtonGroup>
				</>
			)}
		</CustomMessageCard>
	);
};

export default CustomMessage;
