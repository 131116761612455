import styled, { css } from 'styled-components';
import { ifProp, palette } from 'styled-tools';

import { FontSizes, fontSize, units } from '@calm-web/design-system';

export const LineChartContainer = styled.div<{
	paddingSide: 'left' | 'right';
}>`
	${fontSize(FontSizes.sm)};
	color: ${palette('gray6')};
	width: 100%;
	box-sizing: border-box;
	${ifProp(
		{ paddingSide: 'right' },
		css`
			padding-right: ${units(2)};
		`,
	)}
	min-width: 200px;
	margin-bottom: ${units(1)};
`;

export const TooltipContainer = styled.div``;

export const TooltipLabel = styled.span`
	margin-right: ${units(2)};
	margin-top: ${units(1)};
`;

export const TooltipValue = styled.span`
	float: right;
`;
