import styled from 'styled-components';
import { palette } from 'styled-tools';

import { Card, units } from '@calm-web/design-system';

export const StyledCard = styled(Card)`
	background-color: ${palette('gray0')};
	padding: ${units(5)};
`;

export const CardContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;
