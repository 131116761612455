import { defineMessages } from 'react-intl';

export default defineMessages({
	buyMoreCoverage: {
		id: 'plan.buyMoreCoverage',
		defaultMessage: 'Buy more coverage',
		description: 'Button text for upgrading the number of covered lives',
	},
	updateCoverage: {
		id: 'plan.updateCoverage',
		defaultMessage: 'Update coverage',
		description: 'Button text for upgrading or downgrading the number of covered lives',
	},
});
