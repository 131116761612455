import omit from 'lodash/omit';
import { FC, ReactElement, useState } from 'react';
import { useIntl } from 'react-intl';

import { RadioGroup, Button, Modal } from '@calm-web/design-system';
import useForm from '@calm-web/use-form';

import { Subtitle } from '@/components/ui/CellTitle';
import { usePermissions } from '@/hooks/auth';
import type { EditPartnerFormProps } from '@/hooks/forms/usePartnerForm';
import { IntegrationType } from '@/types/store/reducers';

import AccessCodesDescription from '../AccessCodesDescription';
import GroupCode from '../GroupCode';
import { SsoSetupDescription } from '../SsoConversion';
import UserIdentifier from '../UserIdentifier';
import messages from '../messages';
import {
	IntegrationTypeDescriptionButton,
	IntegrationTypeOption,
	IntegrationTypeSelectionDescription,
	Label,
	OptionBody,
	StyledHr,
} from '../styles';
import {
	Container,
	ModalFooter,
	RadioButtonLabelDescription,
	RadioButtonLabelMain,
	RadioButtonLabelWrapper,
	RadioButtonWrapper,
} from './styles';

interface IntegrationDropdownTypeOption {
	id: IntegrationType;
	value: IntegrationType;
	label: string;
}
interface IntegrationRadioTypeOption {
	value: IntegrationType;
	id: string;
	label: ReactElement;
}

function willShowFurtherConfiguration({ formProps }: { formProps: EditPartnerFormProps }): boolean {
	return [
		IntegrationType.ELIGIBILITY_FILE,
		IntegrationType.ACCESS_CODES,
		IntegrationType.GROUP_CODE,
		IntegrationType.SSO,
	].includes(formProps.model.integrationType as IntegrationType);
}

function FurtherConfiguration({ formProps }: { formProps: EditPartnerFormProps }): ReturnType<FC> {
	const { formatMessage } = useIntl();

	const isUniqueIdDisabled = formProps.model.integrationType !== IntegrationType.ELIGIBILITY_FILE;
	if (formProps.model.integrationType === IntegrationType.ELIGIBILITY_FILE) {
		return (
			<>
				<Label>{formatMessage(messages.eligibilityFileLabel)}</Label>
				<Subtitle>{formatMessage(messages.eligibilityFileSubtitle)}</Subtitle>
				<OptionBody disabled={isUniqueIdDisabled}>
					<UserIdentifier isEdit={false} formProps={formProps} disabled={isUniqueIdDisabled} />
				</OptionBody>
			</>
		);
	}
	if (formProps.model.integrationType === IntegrationType.ACCESS_CODES) {
		return (
			<>
				<Label>{formatMessage(messages.accessCodesLabel)}</Label>
				<AccessCodesDescription />
			</>
		);
	}
	if (formProps.model.integrationType === IntegrationType.GROUP_CODE) {
		return <GroupCode formProps={formProps} isEdit={false} showExplanation />;
	}
	if (formProps.model.integrationType === IntegrationType.SSO) {
		return (
			<>
				<Label>{formatMessage(messages.ssoLabel)}</Label>
				<SsoSetupDescription />
			</>
		);
	}
	return null;
}

function CreatePartnerIntegrationSelector({
	formProps,
	currentIntegrationType,
	isDTCLeadGenPartner = false,
}: {
	formProps: EditPartnerFormProps;
	currentIntegrationType: IntegrationType | undefined;
	isDTCLeadGenPartner?: boolean;
}): ReactElement {
	const [hasValidPermissions, actions] = usePermissions();
	const newFormProps = useForm('newIntegrationType', {
		initialModel: {
			newIntegrationType: formProps.model.integrationType ?? IntegrationType.ELIGIBILITY_FILE,
		},
	});
	const [showModal, setShowModal] = useState(false);

	const canChangeIntegrationType = hasValidPermissions('integration_type', [actions.CREATE]);
	const includeSsoInDropdown = currentIntegrationType === IntegrationType.SSO || canChangeIntegrationType;
	const includeApiInDropdown =
		currentIntegrationType === IntegrationType.API || formProps.model.integrationType === IntegrationType.API;

	const options: IntegrationDropdownTypeOption[] = [
		{
			id: IntegrationType.ELIGIBILITY_FILE,
			value: IntegrationType.ELIGIBILITY_FILE,
			label: 'Eligibility file: .csv or .xlss',
		},
		{
			id: IntegrationType.ACCESS_CODES,
			value: IntegrationType.ACCESS_CODES,
			label: 'Calm-created access codes',
		},
		{
			id: IntegrationType.GROUP_CODE,
			value: IntegrationType.GROUP_CODE,
			label: 'Group code: one code for all covered lives',
		},
	];
	if (includeSsoInDropdown) {
		options.push({
			id: IntegrationType.SSO,
			value: IntegrationType.SSO,
			label: 'Sync with an SSO Provider',
		});
	}
	if (includeApiInDropdown) {
		options.push({
			id: IntegrationType.API,
			value: IntegrationType.API,
			label: 'API Integration',
		});
	}

	const radioOptions: IntegrationRadioTypeOption[] = [
		{
			value: IntegrationType.ELIGIBILITY_FILE,
			id: 'eligibility',
			label: (
				<RadioButtonLabelWrapper>
					<RadioButtonLabelMain>Eligibility file: .csv or .xlss</RadioButtonLabelMain>
					<RadioButtonLabelDescription
						selected={newFormProps.model.newIntegrationType === IntegrationType.ELIGIBILITY_FILE}
					>
						You or your partner will upload a file of active employees to tell Calm who the benefit is
						available to.
					</RadioButtonLabelDescription>
				</RadioButtonLabelWrapper>
			),
		},
		{
			value: IntegrationType.ACCESS_CODES,
			id: 'accessCodes',
			label: (
				<RadioButtonLabelWrapper>
					<RadioButtonLabelMain>Calm-created access codes</RadioButtonLabelMain>
					<RadioButtonLabelDescription
						selected={newFormProps.model.newIntegrationType === IntegrationType.ACCESS_CODES}
					>
						Access codes will be generated for every contracted covered life
					</RadioButtonLabelDescription>
				</RadioButtonLabelWrapper>
			),
		},
		{
			value: IntegrationType.GROUP_CODE,
			id: 'groupCodes',
			label: (
				<RadioButtonLabelWrapper>
					<RadioButtonLabelMain>Group code: one code for all covered lives</RadioButtonLabelMain>
					<RadioButtonLabelDescription
						selected={newFormProps.model.newIntegrationType === IntegrationType.GROUP_CODE}
					>
						Create one unique redemption code for your covered lives to redeem
					</RadioButtonLabelDescription>
				</RadioButtonLabelWrapper>
			),
		},
	];
	if (includeSsoInDropdown) {
		radioOptions.push({
			value: IntegrationType.SSO,
			id: 'syncWithSSO',
			label: (
				<RadioButtonLabelWrapper>
					<RadioButtonLabelMain>Sync with an SSO Provider</RadioButtonLabelMain>
					<RadioButtonLabelDescription
						selected={newFormProps.model.newIntegrationType === IntegrationType.SSO}
					>
						Admin will connect their portal to an SSO provider (e.g. Okta, Azure, G Suite). Employees will
						access their premium subscription via Okta Single Sign On.
					</RadioButtonLabelDescription>
				</RadioButtonLabelWrapper>
			),
		});
	}
	if (includeApiInDropdown) {
		radioOptions.push({
			value: IntegrationType.API,
			id: 'apiIntegration',
			label: (
				<RadioButtonLabelWrapper>
					<RadioButtonLabelMain>API Integration</RadioButtonLabelMain>
					<RadioButtonLabelDescription
						selected={newFormProps.model.newIntegrationType === IntegrationType.API}
					>
						Partner has a contract to integrate with Calm via API. This option should only be selected once
						the engineering team has confirmed the work.
					</RadioButtonLabelDescription>
				</RadioButtonLabelWrapper>
			),
		});
	}

	const dtcLeadGenOptionsDropdown = [
		{
			id: IntegrationType.ACCESS_CODES,
			value: IntegrationType.ACCESS_CODES,
			label: 'Calm-created access codes',
		},
		{
			id: IntegrationType.DTC_CODELESS,
			value: IntegrationType.DTC_CODELESS,
			label: 'Codeless DTC Lead Gen Partnership',
		},
		{
			id: IntegrationType.GROUP_CODE,
			value: IntegrationType.GROUP_CODE,
			label: 'Group code: one code for all covered lives',
		},
	];
	const dtcLeadGenOptionsRadio = [
		{
			value: IntegrationType.ACCESS_CODES,
			id: 'accessCodes',
			label: (
				<RadioButtonLabelWrapper>
					<RadioButtonLabelMain>Calm-created access codes</RadioButtonLabelMain>
					<RadioButtonLabelDescription
						selected={newFormProps.model.newIntegrationType === IntegrationType.ACCESS_CODES}
					>
						Access codes will be generated for every contracted covered life
					</RadioButtonLabelDescription>
				</RadioButtonLabelWrapper>
			),
		},
		{
			value: IntegrationType.DTC_CODELESS,
			id: 'codelessDTC',
			label: (
				<RadioButtonLabelWrapper>
					<RadioButtonLabelMain>Codeless DTC Lead Gen Partnership</RadioButtonLabelMain>
					<RadioButtonLabelDescription
						selected={newFormProps.model.newIntegrationType === IntegrationType.DTC_CODELESS}
					>
						A lead gen partnership flow without access codes - redemption methods set on the stripe coupon to
						avoid abuse
					</RadioButtonLabelDescription>
				</RadioButtonLabelWrapper>
			),
		},
		{
			value: IntegrationType.GROUP_CODE,
			id: 'groupCodes',
			label: (
				<RadioButtonLabelWrapper>
					<RadioButtonLabelMain>Group code: one code for all covered lives</RadioButtonLabelMain>
					<RadioButtonLabelDescription
						selected={newFormProps.model.newIntegrationType === IntegrationType.GROUP_CODE}
					>
						Create one unique redemption code for your covered lives to redeem
					</RadioButtonLabelDescription>
				</RadioButtonLabelWrapper>
			),
		},
	];

	function getDropdownOptions(): IntegrationDropdownTypeOption[] {
		if (isDTCLeadGenPartner) {
			return dtcLeadGenOptionsDropdown;
		}
		return options;
	}
	function getRadioOptions(): IntegrationRadioTypeOption[] {
		if (isDTCLeadGenPartner) {
			return dtcLeadGenOptionsRadio;
		}
		return radioOptions;
	}
	const bindWithErrorProps = formProps.bindWithErrorProps('integrationType', 'select');
	return (
		<Container>
			<IntegrationTypeSelectionDescription>
				Choose how the partner wants to maintain their eligibility list.
			</IntegrationTypeSelectionDescription>
			<select
				{...omit(bindWithErrorProps, ['isValid', 'showValidation'])}
				placeholder="Pick an integration type"
				aria-label="Pick an integration type"
				disabled={!canChangeIntegrationType}
				data-testid="create-partner-integration-select"
			>
				{getDropdownOptions().map(({ value, label }) => {
					return (
						<option key={value} value={value}>
							{label}
						</option>
					);
				})}
			</select>
			<IntegrationTypeDescriptionButton
				onPress={(): void => {
					if (formProps.model.integrationType) {
						newFormProps.setProperty('newIntegrationType', formProps.model.integrationType);
					}
					setShowModal(true);
				}}
			>
				Compare available integration methods
			</IntegrationTypeDescriptionButton>
			{willShowFurtherConfiguration({ formProps }) ? (
				<>
					<StyledHr />
					<IntegrationTypeOption>
						<FurtherConfiguration formProps={formProps} />
					</IntegrationTypeOption>
				</>
			) : null}
			<Modal
				isOpen={showModal}
				closeModal={() => setShowModal(false)}
				title="Compare integration methods"
				footer={
					<ModalFooter>
						<Button textColor="blue3" backgroundColor="white" onPress={() => setShowModal(false)}>
							cancel
						</Button>
						<Button
							backgroundColor="blue3"
							isDisabled={!canChangeIntegrationType}
							onPress={() => {
								if (newFormProps.model.newIntegrationType) {
									formProps.setProperty('integrationType', newFormProps.model.newIntegrationType);
								}
								setShowModal(false);
							}}
						>
							Pick this integration model
						</Button>
					</ModalFooter>
				}
			>
				<RadioButtonWrapper>
					<RadioGroup
						{...newFormProps.bindWithErrorProps('newIntegrationType', 'radio')}
						aria-label="Select the new integration type"
						options={getRadioOptions()}
						asCards
						fullWidth
					/>
				</RadioButtonWrapper>
			</Modal>
		</Container>
	);
}
export default CreatePartnerIntegrationSelector;
