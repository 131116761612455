import { defineMessages } from 'react-intl';

export default defineMessages({
	title: {
		id: 'userFeedback.title',
		defaultMessage: "We're so glad you love receiving Calm Premium as a benefit from {companyname}!",
		description: 'Titling a section where users can enter feedback on their usage of Calm, per company',
	},
	description: {
		id: 'userFeedback.description',
		defaultMessage: 'Please highlight your anonymous feedback to your benefits administrator.',
		description:
			'Gives a reason why a Calm user should provide positive feedback to their company about their usage of the Calm app',
	},
	placeholder: {
		id: 'userFeedback.textPlaceholder',
		defaultMessage: 'What does having Calm as a benefit mean to you?',
		description:
			'Placeholder text asking the Calm customer to input feedback on the benefits of their app usage',
	},
	sendFeedback: {
		id: 'userFeedback.sendFeedbackButton',
		defaultMessage: 'Send feedback',
		description: 'Button text asking the user to submit their feedback',
	},
	contactSupport: {
		id: 'userFeedback.contactSupport',
		defaultMessage: 'Having issues with Calm? Contact customer support.',
		description: 'Support link that the user can press to get help with the Calm app',
	},
	error: {
		id: 'userFeedback.tokenExpired',
		defaultMessage: "We're glad you're enjoying your Calm Premium benefit. This survey has closed.",
		description:
			'The user can only enter survey results within a certain amount of time, and the token is expired.',
	},
	thankYou: {
		id: 'userFeedback.thankYou',
		defaultMessage: 'Thank you',
		description: 'Just a thank you message, everyone likes getting one of those.',
	},
	success: {
		id: 'userFeedback.success',
		defaultMessage: 'Your anonymous feedback has been sent to your benefits administrator.',
		description: 'The user successfully submitted survey results',
	},
});
