import { omit } from 'lodash';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import useSWR, { useSWRConfig } from 'swr';

import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import { setBannerMessage } from '@/store/actions';
import { HealthReferral, REFERRAL_DEFN_ID_ARRAY } from '@/types/health';
import { JSONValue } from '@/types/json';
import { isCalmError } from '@/utils/apiRequest/errors';
import { calmLogger } from '@/utils/calmLogger';

import { ApiResponse } from './types';
import { useApi } from './useApi';

export function useHealthReferrals(partnerId: string, sponsorshipId: string): ApiResponse<HealthReferral[]> {
	const apiRequest = useApi();
	const dispatch = useDispatch();
	const endpoint = `b2b/partners/${partnerId}/health-sponsorships/${sponsorshipId}/referrals`;
	const { data, isLoading, error } = useSWR<HealthReferral[]>(endpoint, async endpoint => {
		try {
			const response = await apiRequest({ endpoint });
			const referralArray = response.data.health_referrals as HealthReferral[];
			return referralArray.sort(
				(a, b) =>
					REFERRAL_DEFN_ID_ARRAY.indexOf(a.referral_defn_id) -
					REFERRAL_DEFN_ID_ARRAY.indexOf(b.referral_defn_id),
			);
		} catch (err) {
			calmLogger.error('Error when fetching health referrals', {}, err);
			dispatch(
				setBannerMessage({
					message: `Error: ${
						(isCalmError(err) && err?.data?.error?.message) ||
						'There was a problem fetching the health recommendations'
					}`,
					flash: true,
					isError: true,
				}),
			);
			throw err;
		}
	});

	return { data, loading: isLoading, error };
}

export const useSubmitHealthReferral = (): [
	submitHealthReferral: (
		healthReferralSubmitData: Partial<HealthReferral>[],
		partnerId: string,
		sponsorship_id: string,
	) => Promise<void>,
	state: ApiResponse<HealthReferral>,
] => {
	const apiRequest = useApi();
	const { logEvent } = useAnalytics();
	const dispatch = useDispatch();
	const { mutate } = useSWRConfig();
	const [isSubmitting, setIsSubmitting] = useState(false);

	const submitHealthReferral = useCallback(
		async (
			healthReferralData: Partial<HealthReferral>,
			partnerId: string,
			sponsorship_id: string,
		): Promise<void> => {
			const isEdit = !!healthReferralData.id;
			const listEndpoint = `b2b/partners/${partnerId}/health-sponsorships/${sponsorship_id}/referrals`;
			const endpoint = isEdit ? `${listEndpoint}/${healthReferralData.id}` : listEndpoint;
			const method = isEdit ? 'PATCH' : 'POST';

			const data = new FormData();
			const json = JSON.stringify(omit(healthReferralData, 'uploaded_logo', 'id'));
			data.append('json', json);
			if (healthReferralData.uploaded_logo) {
				data.append('logo', healthReferralData.uploaded_logo);
			}

			const res = await apiRequest({
				endpoint,
				method,
				body: data,
			});
			const responseData = res?.data?.health_Referral ?? res?.data;
			if (responseData) {
				const updatedHealthReferralFields: { [field: string]: JSONValue } = {};
				Object.keys(responseData).forEach((field: string) => {
					if (
						healthReferralData &&
						healthReferralData[field as keyof HealthReferral] !== responseData[field]
					) {
						updatedHealthReferralFields[field] = responseData[field];
					}
				});
				if (Object.keys(updatedHealthReferralFields).length) {
					logEvent('Health Referral : Updated', { ...updatedHealthReferralFields });
				}
			}
			await mutate(listEndpoint);
		},
		[apiRequest, logEvent, mutate],
	);

	const submitHealthReferrals = useCallback(
		async (
			healthReferralSubmitData: Partial<HealthReferral>[],
			partnerId: string,
			sponsorship_id: string,
		): Promise<void> => {
			setIsSubmitting(true);

			const referralPromises = await Promise.allSettled(
				healthReferralSubmitData.map(data => {
					return submitHealthReferral(data, partnerId, sponsorship_id);
				}),
			);
			if (referralPromises.some(result => result.status === 'rejected')) {
				dispatch(
					setBannerMessage({
						message: `There was an error updating at least one of the Health Recommendations`,
						flash: true,
						isError: true,
					}),
				);
			} else {
				dispatch(
					setBannerMessage({
						message: `Successfully updated Health Sponsorship & all Health Recommendations`,
						flash: true,
						isError: false,
					}),
				);
			}
			setIsSubmitting(false);
		},

		[submitHealthReferral, dispatch],
	);

	return [submitHealthReferrals, { data: undefined, loading: isSubmitting, error: undefined }];
};
