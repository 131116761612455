import { ReactElement } from 'react';
import { useIntl } from 'react-intl';

import { Card, FormInput, RadioGroup } from '@calm-web/design-system';

import CellTitle from '@/components/ui/CellTitle';
import { usePermissions } from '@/hooks/auth/usePermissions';
import { EditHealthConfigFormProps } from '@/hooks/forms/useHealthConfigForm';

import messages from '../messages';

interface Props {
	formProps: EditHealthConfigFormProps;
	onFocus?: () => void;
}

export default function InAppClientSupport({ formProps, onFocus }: Props): ReactElement | null {
	const [hasValidPermissions, actions] = usePermissions();
	const { formatMessage } = useIntl();

	const shouldShow =
		hasValidPermissions('client_support_phone', [actions.READ]) &&
		hasValidPermissions('client_support_email', [actions.READ]);
	if (!shouldShow) {
		return null;
	}

	const options = [
		{
			value: 'phone',
			id: 'phone',
			label: 'Phone',
		},
		{
			value: 'email',
			id: 'email',
			label: 'Email',
		},
		{
			value: 'both',
			id: 'both',
			label: 'Both',
		},
	];

	const clientSupportPhoneInputProps = formProps.bindWithErrorProps('clientSupportPhone', 'text');
	const clientSupportEmailInputProps = formProps.bindWithErrorProps('clientSupportEmail', 'text');
	const clientSupportChannelInputProps = formProps.bindWithErrorProps('clientSupportChannel', 'text');

	return (
		<Card onFocus={onFocus} onClick={onFocus}>
			<CellTitle>In-App Client Support</CellTitle>
			<RadioGroup
				{...clientSupportChannelInputProps}
				label={formatMessage(messages.inAppSupportSubtitle)}
				options={options}
				asCards
				aria-label={formatMessage(messages.inAppSupportSubtitle)}
				display="flex"
				disabled={
					!hasValidPermissions('client_support_phone', [actions.UPDATE]) &&
					!hasValidPermissions('client_support_phone', [actions.UPDATE])
				}
			/>
			{['phone', 'both'].includes(clientSupportChannelInputProps.value) ? (
				<FormInput
					{...clientSupportPhoneInputProps}
					disabled={!hasValidPermissions('client_support_phone', [actions.UPDATE])}
					label="In-App Client Support Phone"
					noMargin={clientSupportChannelInputProps.value === 'phone'} // Only add margin if email input is also shown
				/>
			) : null}
			{['email', 'both'].includes(clientSupportChannelInputProps.value) ? (
				<FormInput
					{...clientSupportEmailInputProps}
					disabled={!hasValidPermissions('client_support_email', [actions.UPDATE])}
					label="In-App Client Support Email"
					noMargin
				/>
			) : null}
		</Card>
	);
}
