import styled from 'styled-components';

import { fontSize, FontSizes, fontWeight, FontWeights, units } from '@calm-web/design-system';

export const ConfirmationText = styled.div`
	text-align: center;
	width: 100%;
	${fontSize(FontSizes.sm)};
`;

export const SecondaryText = styled(ConfirmationText)`
	${fontWeight(FontWeights.Medium)};
	margin-top: ${units(1.5)};
`;

export const LeftButtonWrapper = styled.div`
	margin-right: ${units(3)};
`;
