import styled from 'styled-components';

import { fontWeight, FontWeights, units } from '@calm-web/design-system';

export const ModalMainText = styled.div`
	${fontWeight(FontWeights.Medium)};
	width: 100%;
	text-align: center;
	margin-bottom: ${units(2)};
`;

export const ModalSecondaryText = styled.div`
	width: 100%;
	text-align: center;
`;

export const StyledSelect = styled.select`
	max-width: 100%;
`;
