import _ from 'lodash';
import { FC, SetStateAction, useState, Dispatch, ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { Text, FontSizes, FontWeights } from '@calm-web/design-system';

import { GroupedItemState } from '@/types/actionPlan';

import ActionItem from '../ActionItem';
import EmptyStateBlock from '../EmptyStateBlock';
import messages from './messages';
import {
	Row,
	ProgressBar,
	ProgressBarContainer,
	PercentText,
	ActionItemsGroup,
	LockIcon,
	LockIconInline,
	TabButton,
} from './styles';

interface Props {
	getSetupStatuses?: GroupedItemState;
}
interface ActionTabProps {
	percentComplete: string;
	getSetupActive: boolean;
	setGetSetupActive: Dispatch<SetStateAction<boolean>>;
}

function getPercentComplete(getSetupStatuses?: GroupedItemState): string {
	if (_.isEmpty(getSetupStatuses)) {
		return '0';
	}

	const { completed, incomplete } = getSetupStatuses;

	const completedCount = completed.length;
	const incompleteCount = incomplete.length;
	const total = completedCount + incompleteCount;
	const percentComplete = total ? (completedCount / total) * 100 : 0;
	return `${Math.round(percentComplete * 10) / 10}`;
}

const ActionTab: FC<ActionTabProps> = ({ percentComplete, getSetupActive, setGetSetupActive }) => {
	const { formatMessage } = useIntl();

	return (
		<>
			<Row>
				<TabButton onPress={() => setGetSetupActive(true)} $isActive={getSetupActive}>
					<Text el="h2" noMargin size={FontSizes.lg} weight={FontWeights.Medium}>
						{formatMessage(messages.heading)}
					</Text>
					<ProgressBarContainer>
						<PercentText el="span" color="white" weight={FontWeights.Medium}>
							{`${percentComplete}%`}
						</PercentText>
						<ProgressBar
							role="progressbar"
							aria-label="Get setup completion percent"
							aria-valuenow={Number(percentComplete)}
							$percentComplete={percentComplete}
						></ProgressBar>
					</ProgressBarContainer>
				</TabButton>

				<TabButton onPress={() => setGetSetupActive(false)} $isActive={!getSetupActive}>
					<Text el="h2" noMargin size={FontSizes.lg} weight={FontWeights.Medium}>
						{formatMessage(messages.actionsHeading)}
					</Text>{' '}
					<LockIconInline $isActive={!getSetupActive} />
				</TabButton>
			</Row>
		</>
	);
};

const GetSetupSection: FC<Props> = ({ getSetupStatuses }) => {
	const { formatMessage } = useIntl();
	const [getSetupActive, setGetSetupActive] = useState(true);
	const percentComplete = getPercentComplete(getSetupStatuses);

	return (
		<>
			<ActionTab
				percentComplete={percentComplete}
				getSetupActive={getSetupActive}
				setGetSetupActive={setGetSetupActive}
			/>
			{getSetupActive ? (
				<ActionItemsGroup>
					{getSetupStatuses?.incomplete.map(item => (
						<ActionItem item={item} key={item.description} />
					))}
				</ActionItemsGroup>
			) : (
				<EmptyStateBlock
					Icon={LockIcon}
					primaryText={formatMessage(messages.notReadyYetHeading)}
					secondaryText={formatMessage(messages.notReadyYetCopy, {
						i: (...chunks: ReactNode[]) => <i>{chunks}</i>,
					})}
				/>
			)}
		</>
	);
};

export default GetSetupSection;
