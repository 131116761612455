import styled from 'styled-components';
import { palette } from 'styled-tools';

import { FontSizes, TextButton, Button, fontSize, units } from '@calm-web/design-system';

export const SaveButton = styled(Button)`
	vertical-align: top;
	margin-left: ${units(2)};
	margin-top: ${units(0.5)};
`;

export const TableHeaderContainer = styled.div`
	display: flex;
`;

export const ClearButton = styled(TextButton).attrs({ size: FontSizes.sm, hideUnderline: true })`
	&& {
		margin-left: ${units(1)};
	}
`;

export const Instructions = styled.div`
	${fontSize(FontSizes.sm)};
	color: ${palette('gray5')};
`;
