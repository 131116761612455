import copy from 'copy-to-clipboard';
import { FC, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Button, Modal, ModalWidth } from '@calm-web/design-system';

import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import { useIntegrationType } from '@/hooks/api/useIntegrationType';
import { PartnerMilestoneType, useRecordMilestone } from '@/hooks/api/useMilestones';
import { useDefinedPartner } from '@/hooks/api/usePartner';
import { useSaml } from '@/hooks/api/useSaml';
import useQrCode from '@/hooks/useQrCode';
import { setBannerMessage } from '@/store/actions';
import { IntegrationType, Partner } from '@/types/store/reducers';
import { SkuToConfig } from '@/utils/SkuToConfig';
import { hasIncompleteSsoSetup } from '@/utils/SsoUtils';
import { createB2BPartnerSubscribeUrlPreview } from '@/utils/url';

import LaunchEmailModal from './LaunchEmailModal';
import messages from './messages';
import { ButtonWrapper, ContentWrapper, PrimaryButtonStyled } from './styles';

const EmailModal: FC<{
	isEmailOpen: boolean;
	closeEmailModal: () => void;
	partner: Partner;
}> = ({ isEmailOpen, closeEmailModal, partner }) => {
	const { data: samlConfig } = useSaml(partner.id);
	const { data: integrationType } = useIntegrationType(partner.id);
	const isSSO = integrationType === IntegrationType.SSO;
	const uniqueIdIsEmail = partner?.user_id_descriptor === 'Email' || partner?.user_id_descriptor === 'email';
	const canSendEmails = !isSSO && partner?.email_config_can_send_benefit_reminders && uniqueIdIsEmail;
	const announceCookieName = isSSO ? 'announce-complete-sso' : 'announce-complete';
	const incompleteSsoSetup = isSSO ? hasIncompleteSsoSetup(partner, samlConfig) : false;

	const subscribeUrl =
		partner.redemption_url ??
		createB2BPartnerSubscribeUrlPreview({
			slug: partner.slug,
			skuType: SkuToConfig[partner.vouched_plan_sku].productSKUType,
		});
	const signupUrl = isSSO ? partner?.web_renew_url : subscribeUrl;

	return (
		<>
			{isEmailOpen && (
				<LaunchEmailModal
					isDisabled={incompleteSsoSetup}
					hideSendOption={!canSendEmails}
					signupLink={signupUrl}
					partnerId={partner.id}
					announceCookieName={announceCookieName}
					handleCloseOverride={closeEmailModal}
					renderModalOverride={isEmailOpen}
				/>
			)}
		</>
	);
};

const ModalContent: FC<{
	openEmailModal: () => void;
	partner: Partner;
}> = ({ openEmailModal, partner }) => {
	const { logEvent } = useAnalytics();
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();
	const { data: samlConfig } = useSaml(partner.id);
	const { data: integrationType } = useIntegrationType(partner.id);
	const [recordMilestone] = useRecordMilestone();
	const qrDataImage = useQrCode(partner);

	const isSSO = integrationType === IntegrationType.SSO;
	const incompleteSsoSetup = isSSO ? hasIncompleteSsoSetup(partner, samlConfig) : false;
	const integrationTypeString = isSSO ? 'SSO' : 'EF or Access Codes';

	const onQrCodeDownload = async (): Promise<void> => {
		logEvent('Get Set Up : Download QR Code', {
			type: integrationTypeString,
			triggeredFrom: 'Home Page : Launch Announcement Modal',
		});
		await recordMilestone({
			eventName: PartnerMilestoneType.LAUNCH_ANNOUNCEMENT_SENT,
			partnerId: partner.id,
		});
	};

	const subscribeUrl =
		partner.redemption_url ??
		createB2BPartnerSubscribeUrlPreview({
			slug: partner.slug,
			skuType: SkuToConfig[partner.vouched_plan_sku].productSKUType,
		});
	const signupUrl = isSSO ? partner?.web_renew_url : subscribeUrl;
	const copySignupUrl = async (): Promise<void> => {
		logEvent('Get Set Up : Copy Sign Up Link', {
			type: integrationTypeString,
			triggeredFrom: 'Home Page : Launch Announcement Modal',
		});
		if (signupUrl) {
			copy(signupUrl, {
				format: 'text/plain',
				onCopy: async () => {
					dispatch(
						setBannerMessage({
							message: 'Signup Link copied to clipboard',
							isError: false,
							flash: true,
						}),
					);
					await recordMilestone({
						eventName: PartnerMilestoneType.LAUNCH_ANNOUNCEMENT_SENT,
						partnerId: partner.id,
					});
				},
			});
		}
	};

	return (
		<>
			<ContentWrapper>{formatMessage(messages.modalBody)}</ContentWrapper>
			<ButtonWrapper>
				<Button
					backgroundColor="transparent"
					hideShadow
					textColor="blue3"
					onPress={copySignupUrl}
					isDisabled={incompleteSsoSetup}
				>
					Copy Sign-Up Link
				</Button>
				<Button
					backgroundColor="transparent"
					hideShadow
					textColor="blue3"
					href={qrDataImage}
					download
					onPress={onQrCodeDownload}
					isDisabled={incompleteSsoSetup}
					data-testid="qr-download-button"
				>
					Download QR Code
				</Button>
				<PrimaryButtonStyled size="sm" onPress={openEmailModal} data-testid="launch-email-template-modal">
					{formatMessage(messages.announceCta)}
				</PrimaryButtonStyled>
			</ButtonWrapper>
		</>
	);
};

export const LaunchAnnouncementModal: FC = () => {
	const partner = useDefinedPartner();
	const { hash } = useLocation();
	const history = useHistory();
	const [isAnnouncementOpen, setIsAnnouncementOpen] = useState(false);
	const [isEmailOpen, setIsEmailOpen] = useState(false);

	useEffect(() => {
		const initialModalState = hash === '#launch-announcement';
		setIsAnnouncementOpen(initialModalState);
	}, [hash, setIsAnnouncementOpen]);

	const handleClose = (): void => {
		history.push({
			pathname: 'home',
		});
		setIsAnnouncementOpen(false);
	};

	const openEmailModal = (): void => {
		setIsEmailOpen(true);
		handleClose();
	};
	const closeEmailModal = (): void => {
		setIsEmailOpen(false);
	};

	return (
		<>
			<Modal
				isOpen={isAnnouncementOpen}
				closeModal={handleClose}
				canClose
				title={'Launch Announcement'}
				width={ModalWidth.Normal}
				noPadding
			>
				<ModalContent openEmailModal={openEmailModal} partner={partner} />
			</Modal>
			<EmailModal isEmailOpen={isEmailOpen} partner={partner} closeEmailModal={closeEmailModal} />
		</>
	);
};
