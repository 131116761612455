import { defineMessages } from 'react-intl';

export default defineMessages({
	nameAndIdCardTitle: {
		id: 'createPartner.nameAndIdCard.title',
		defaultMessage: 'Name & ID',
		description: 'A title above a section where the user can add partner name & ID',
	},
	instructions: {
		id: 'createPartner.nameAndIdCard.instructions',
		defaultMessage: 'Enter the partner’s name. A suggested partner ID will be automatically generated.',
		description: 'A description of how to add partner name & ID',
	},
});
