import Image from 'next/image';
import styled, { css } from 'styled-components';

import { Button, units, Text, FontSizes, FontWeights, TextElements, Card } from '@calm-web/design-system';
import { CalmHealthLightLogo, InfoOutline } from '@calm-web/icons';

import { HealthReferralDefinitions } from '@/types/health';

const scale = 0.75;
export const ViewPort = styled.div`
	z-index: -1;
	position: relative;
	overflow-y: hidden;
	overflow-x: hidden;
	transform: scale(${scale});
	height: calc((100% - 18px) / ${scale});
	width: calc((100% - 18px) / ${scale});
	margin: calc(9px / ${scale});
	left: -${(100 / scale - 100) / 2}%;
	top: -${(100 / scale - 100) / 2}%;
	padding: ${units(2.5)};
	background: linear-gradient(rgb(238, 238, 255), rgb(247, 247, 255));
`;

export const NavBar = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: ${units(1.5, 0)};
	margin-top: ${units(3)};
	margin-bottom: ${units(3.5)};
`;

const logoRatio = 128 / 314;
const logoWidth = 124;
export const CalmHealthLogo = styled(CalmHealthLightLogo)`
	display: inline-block;
	width: ${logoWidth}px;
	height: ${logoWidth * logoRatio}px;
`;

export const TestOutcome = styled(Text).attrs({
	el: TextElements.Div,
	size: FontSizes.lg,
	weight: FontWeights.Medium,
})`
	color: #0d006a;
	margin-bottom: ${units(2)};
`;

export const ReferralCard = styled(Card)`
	margin-bottom: ${units(4.5)};
`;

export const ReferralCardContents = styled.div`
	display: flex;
`;

export const ReferralLogo = styled(Image).attrs(props => ({
	width: 80,
	height: 80,
	src: props.src ?? '/_n/images/employerco-square.png',
	alt: 'Your recommendation logo',
}))`
	border-radius: ${units(1.5)};
	flex: 0 0 auto;
	margin-right: ${units(1.5)};
`;

export const ReferralInfo = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export const ReferralTitle = styled(Text).attrs({ el: TextElements.Div, weight: FontWeights.Demi })`
	line-height: 150%;
	margin-bottom: ${units(0.5)};
`;

export const ReferralSubtitle = styled(Text).attrs({ el: TextElements.Div, size: FontSizes.sm })``;

export const Disclaimer = styled(Text).attrs({
	el: TextElements.Div,
	size: FontSizes.sm,
	weight: FontWeights.Medium,
})`
	color: #0d006a;
	margin-bottom: ${units(1)};
`;

export const ResultsCard = styled(Card)``;

export const UpdateButton = styled(Button).attrs({
	borderColor: 'blue3',
	backgroundColor: 'transparent',
	textColor: 'blue3',
	fullWidth: true,
})`
	border-radius: ${units(1.5)};
`;

export const ResultsReadoutContainer = styled.div`
	&:not(:last-child) {
		padding-bottom: ${units(2)};
		margin-bottom: ${units(2)};
		border-bottom: 1px solid #e5e5e5;
	}
`;

export const ResultsReadoutHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: ${units(0.5)};
`;

export const ResultsReadoutHeaderText = styled(Text).attrs({ weight: FontWeights.Medium })``;

export const InfoIcon = styled(InfoOutline)`
	width: ${units(2.5)};
	height: ${units(2.5)};
`;

export const ResultsReadoutBar = styled.div`
	position: relative;
`;

const healthReferralDefinitionXPos = {
	[HealthReferralDefinitions.PHQ_0]: 'calc(0% + 8px)',
	[HealthReferralDefinitions.PHQ_1_4]: '10%',
	[HealthReferralDefinitions.PHQ_5_9]: '33%',
	[HealthReferralDefinitions.PHQ_10_14]: '52%',
	[HealthReferralDefinitions.PHQ_15]: '72%',
	[HealthReferralDefinitions.CRISIS]: 'calc(100% - 8px)',
};
export const ResultsReadoutTooltip = styled.div<{ referralDefinition: HealthReferralDefinitions }>`
	display: inline-block;
	position: relative;
	left: ${p => healthReferralDefinitionXPos[p.referralDefinition]};
	${p => {
		if (p.referralDefinition === HealthReferralDefinitions.CRISIS) {
			return `transform: translate(-100%);`;
		}
	}}
	background-color: #e6f2ff;
	padding: ${units(1, 2, 0.5)};
	margin-bottom: ${units(1)};
	border-radius: 6px;

	&:after {
		content: '';
		position: absolute;
		width: 0;
		height: 0;
		top: auto;
		bottom: -5px;
		border-bottom: 10px solid;
		border-color: transparent;
		${p => {
			if (p.referralDefinition === HealthReferralDefinitions.CRISIS) {
				return css`
					left: auto;
					right: 0px;
					border-right: 20px solid #e6f2ff;
				`;
			}
			return css`
				left: 0px;
				right: auto;
				border-left: 20px solid #e6f2ff;
			`;
		}};
	}
`;

export const ResultsReadoutTooltipDescription = styled(Text).attrs({
	el: TextElements.Div,
	size: FontSizes.sm,
	align: 'center',
})`
	color: #006496;
`;

export const ResultsReadoutTooltipScore = styled(Text).attrs({
	el: TextElements.Div,
	weight: FontWeights.Medium,
	align: 'center',
})`
	color: #006496;
`;

export const ResultsReadoutBarBody = styled.div`
	width: 100%;
	height: ${units(4)};
	border-radius: ${units(1.5)};
	background: linear-gradient(
		90deg,
		rgb(117, 180, 239),
		rgb(118, 240, 148),
		rgb(237, 218, 118),
		rgb(241, 173, 118),
		rgb(240, 117, 137)
	);
`;

export const ResultsReadoutBarScores = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const ResultsReadoutBarScore = styled(Text).attrs({ size: FontSizes.sm })`
	position: relative;
	margin-top: ${units(1.5)};
	color: #6a6a75;

	&:before {
		content: '';
		width: 1px;
		height: ${units(1)};
		background: #cccccc;
		position: absolute;
		bottom: 100%;
		left: 50%;
	}
`;
