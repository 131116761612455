import { defineMessages } from 'react-intl';

export default defineMessages({
	heading: {
		id: 'home.actionPlan.heading',
		defaultMessage: 'Your action plan today',
		description: 'Heading for the action plans section',
	},
	allDone: {
		id: 'home.actionPlan.allDone',
		defaultMessage: "You're all done!",
		description: 'Copy shown once all action plan items have been completed.',
	},
	checkBackLater: {
		id: 'home.actionPlan.checkBackLater',
		defaultMessage: 'Check back again later for a new action plan.',
		description: 'Copy shown once all action plan items have been completed.',
	},
});
