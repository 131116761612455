import styled from 'styled-components';

import { Button, fontWeight, FontWeights } from '@calm-web/design-system';

export const CtaButton = styled(Button).attrs({ fullWidth: true, backgroundColor: 'gradientPink' })`
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	${fontWeight(FontWeights.Demi)};
	text-transform: uppercase;
`;
