import { FC, Dispatch, SetStateAction, useState, Ref, forwardRef } from 'react';

import { useSegmentDetails } from '@/hooks/api/reporting/useSegmentDetails';
import { SegmentSelectState, UNCATEGORIZED_VALUE_NAME } from '@/utils/segments';

import { BlueTextButton, SegmentText, Title } from './styles';

function SegmentsSection(
	{
		partnerId,
		segmentsSelected,
		setShowModal,
	}: {
		partnerId: string;
		segmentsSelected: SegmentSelectState;
		setShowModal: Dispatch<SetStateAction<boolean>>;
	},
	ref?: Ref<HTMLDivElement>,
): ReturnType<FC> {
	const [showSegments, setShowSegments] = useState(true);

	const { data: segmentDetails } = useSegmentDetails(partnerId);
	const { segment_names } = segmentDetails || {};
	const { segment_1_display_name, segment_2_display_name, segment_3_display_name } = segment_names || {};
	const displaySegment1 = segment_1_display_name && (segmentsSelected?.segment1?.size ?? 0) > 0;
	const displaySegment2 = segment_2_display_name && (segmentsSelected?.segment2?.size ?? 0) > 0;
	const displaySegment3 = segment_3_display_name && (segmentsSelected?.segment3?.size ?? 0) > 0;
	if (!displaySegment1 && !displaySegment2 && !displaySegment3) return null;

	const segmentsToString = (selectedSet: Set<string> | undefined): string => {
		if (!selectedSet) return '';
		let segmentStr = '';
		selectedSet.forEach(val => {
			segmentStr += `${val ? val : UNCATEGORIZED_VALUE_NAME}, `;
		});
		return segmentStr.slice(0, -2);
	};

	return (
		<div ref={ref}>
			<div>
				<Title>Segments </Title>
				<BlueTextButton
					data-html2canvas-ignore="true"
					onClick={() => {
						setShowSegments(!showSegments);
					}}
				>
					{showSegments ? 'hide' : 'show'}
				</BlueTextButton>
			</div>
			{showSegments && (
				<div>
					{displaySegment1 && (
						<SegmentText>
							{segment_1_display_name}: {segmentsToString(segmentsSelected.segment1)}
						</SegmentText>
					)}
					{displaySegment2 && (
						<SegmentText>
							{segment_2_display_name}: {segmentsToString(segmentsSelected.segment2)}
						</SegmentText>
					)}
					{displaySegment3 && (
						<SegmentText>
							{segment_3_display_name}: {segmentsToString(segmentsSelected.segment3)}
						</SegmentText>
					)}
					<BlueTextButton
						data-html2canvas-ignore="true"
						onClick={() => {
							setShowModal(true);
						}}
					>
						Edit Filters
					</BlueTextButton>
				</div>
			)}
			<br />
		</div>
	);
}

export default forwardRef(SegmentsSection);
