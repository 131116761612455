import styled from 'styled-components';

import { units } from '@calm-web/design-system';

import ClockSvg from 'icons/clock.svg';

export const ClockIcon = styled(ClockSvg)`
	height: ${units(6)};
	width: ${units(6)};
	margin-bottom: ${units(2)};
`;

export const Row = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	gap: ${units(2)};
	margin-bottom: ${units(4)};
`;
