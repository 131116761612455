import styled from 'styled-components';
import { palette } from 'styled-tools';

import { units, Text } from '@calm-web/design-system';

export const ParentDetailContainer = styled.div`
	display: flex;
	padding: ${units(2)};
	align-items: center;
	gap: ${units(1.25)};
	border-radius: 8px;
	border: 1px solid ${palette('gray3')};

	button {
		margin: ${units(-2)};
	}
`;

export const ParentDetailText = styled(Text)`
	flex-grow: 1;
	line-height: ${units(2.75)};
`;
