import styled from 'styled-components';
import { palette } from 'styled-tools';

import { units } from '@calm-web/design-system';

export const LogoBox = styled.div`
	height: ${units(5)};
	width: ${units(5)};
	border-radius: 8px;
	background: ${palette('gradientCalmBrand')};
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2px;
	box-sizing: border-box;
	margin-right: ${units(2)};
	cursor: pointer;
`;

export const BreadcrumbsWrapper = styled.div`
	display: flex;
	flex-wrap: nowrap;

	a {
		text-decoration: none;
	}
`;

export const BreadcrumbLink = styled.span`
	color: ${palette('blue3')};
	cursor: pointer;
`;

export const Divider = styled.span`
	margin: ${units(0, 0.5)};
`;
