import { FC } from 'react';
import { useIntl } from 'react-intl';

import Callout from '@/components/ui/Callout';
import { CalloutContent, CalloutCopy, CalloutTitle } from '@/components/ui/CalloutContent';
import CalloutCta from '@/components/ui/CalloutCta';
import { useFeatureFlags } from '@/hooks/api/useFeatureFlags';

import messages from './messages';
import { GiftIconGradient } from './styles';

interface Props {
	partnerId: string;
}

const B2B100HrReferralAmount = 'b2b-100-hr-referral-amount';
const ReferralBanner: FC<Props> = ({ partnerId }) => {
	const { formatMessage } = useIntl();
	const { data: flagValues } = useFeatureFlags(B2B100HrReferralAmount);
	const canSee100Referral = flagValues && flagValues[B2B100HrReferralAmount] === true;
	const AMAZON_GIFT_VALUE = canSee100Referral ? '$100' : '$500';

	return (
		<Callout>
			<CalloutContent>
				<GiftIconGradient />
				<CalloutTitle>
					{formatMessage(messages.bannerTitle, {
						giftValue: AMAZON_GIFT_VALUE,
					})}
				</CalloutTitle>
				<CalloutCopy>{formatMessage(messages.bannerBody)}</CalloutCopy>
			</CalloutContent>
			<CalloutCta to={`/${partnerId}/referral`}>{formatMessage(messages.bannerCta)}</CalloutCta>
		</Callout>
	);
};

export default ReferralBanner;
