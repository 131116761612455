import { defineMessages } from 'react-intl';

export default defineMessages({
	reviewBannerTitle: {
		id: 'pathways.review.reviewbanner.title',
		defaultMessage: 'Review the following details below and make any changes before launching',
		description: 'Review banner title text',
	},
	reviewPathwayText: {
		id: 'pathways.review.pathway.text',
		defaultMessage: 'Pathway',
		description: 'Review pathway text',
	},
	reviewAudienceText: {
		id: 'pathways.review.audience.text',
		defaultMessage: 'Audience',
		description: 'Review audience text',
	},
	reviewAnnouncementText: {
		id: 'pathways.review.announcement.text',
		defaultMessage: 'Announcement',
		description: 'Review announcement text',
	},
	reviewDurationText: {
		id: 'pathways.review.duration.text',
		defaultMessage: 'Duration',
		description: 'Review duration text',
	},
	viewText: {
		id: 'pathways.review.edviewit',
		defaultMessage: 'View',
		description: 'View button text',
	},
	editText: {
		id: 'pathways.review.edit',
		defaultMessage: 'Edit',
		description: 'Edit button text',
	},
	previewTitle: {
		id: 'pathways.review.preview.title',
		defaultMessage: 'Preview your Pathway',
		description: 'Preview banner title text',
	},
	signupAnnouncement: {
		id: 'pathways.review.emailPreview.signupAnnouncment',
		defaultMessage: 'Announcement email',
		description: 'Email preview link text',
	},
	signupReminder: {
		id: 'pathways.review.emailPreview.signupReminder',
		defaultMessage: 'Reminder email',
		description: 'Email preview link text',
	},
	pushNotification: {
		id: 'pathways.review.emailPreview.pushNotification',
		defaultMessage: 'Push notification',
		description: 'Notification preview link text',
	},
	resourcesTitle: {
		id: 'pathways.review.resources.title',
		defaultMessage: 'Resources for your pathway’s successful launch',
		description: 'Resources banner title text',
	},
	announcementDateText: {
		id: 'pathways.review.preview.announcementDateText',
		defaultMessage: 'Announcement',
		description: 'Text for Announcement Date',
	},
	reminderDateText: {
		id: 'pathways.review.modal.reminderDateText',
		defaultMessage: 'Reminder',
		description: 'Text for Reminder Date',
	},
	dayText: {
		id: 'pathways.review.preview.dayText',
		defaultMessage: 'Day',
		description: 'Preview text for day',
	},
	closeText: {
		id: 'pathways.review.modal.closeText',
		defaultMessage: 'Close',
		description: 'Modal close text',
	},
	announcementPreviewTitle: {
		id: 'pathways.review.announcementModal.title',
		defaultMessage: 'Announcement Email',
		description: 'Modal title',
	},
	reminderPreviewTitle: {
		id: 'pathways.review.reminderModal.title',
		defaultMessage: 'Reminder Email',
		description: 'Modal title',
	},
	notificationPreviewTitle: {
		id: 'pathways.review.notificationModal.title',
		defaultMessage: 'Push Notification',
		description: 'Modal title',
	},
	pathwayStartedText: {
		id: 'pathways.review.alreadyStarted.banner.text',
		defaultMessage:
			'The Pathway has already been announced or will be announced in the next two hours, so you can no longer make any changes.',
		description: 'Edit banner text when pathway has already started',
	},
	pathwayCompletedText: {
		id: 'pathways.review.alreadyStarted.banner.text',
		defaultMessage: 'The Pathway was completed on {endDate}.',
		description: 'Edit banner text when pathway was already completed',
	},
	canEditMessaging: {
		id: 'pathways.review.canStillEdit.header',
		defaultMessage:
			'This Pathway will be announced in {dayDelta}, and you can still make changes until then.',
		description:
			'Edit page heading to let the partner know they can still edit for X days before announce date',
	},
	canEditMessagingNoEmails: {
		id: 'pathways.review.canStillEdit.noEmails.header',
		defaultMessage: 'This Pathway will begin in {dayDelta}, and you can still make changes until then.',
		description: 'Edit page heading to let the partner know they can still edit for X days before start date',
	},
});
