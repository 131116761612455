import { ReactElement } from 'react';

import { useDefinedPartner } from '@/hooks/api/usePartner';

import CHReporting from '../CHReporting';

export default function CHReportingPreview(): ReactElement {
	const partner = useDefinedPartner();

	return <CHReporting partner={partner} preview={true} />;
}
