import styled from 'styled-components';

import { Button } from '@calm-web/design-system';

export const Wrapper = styled.div`
	flex: 1;
	display: flex;
	justify-content: end;
`;
export const AdditionalCoverage = styled.div`
	text-align: center;
`;

export const IncreaseButton = styled(Button)`
	align-self: end;
`;
