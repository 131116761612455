import omit from 'lodash/omit';
import { ReactElement } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

import { Card, Text } from '@calm-web/design-system';

import CellTitle, { Subtitle } from '@/components/ui/CellTitle';
import type { EditPartnerFormProps } from '@/hooks/forms/usePartnerForm';
import { ProductSKUType } from '@/utils/SkuEnums';

import messages from './messages';

const productSKUOptions: {
	value: ProductSKUType;
	label: MessageDescriptor;
}[] = [
	{
		value: ProductSKUType.C4B_DIRECT,
		label: messages.productSKUTypeC4BDirectSelection,
	},
	{
		value: ProductSKUType.C4B_PAYOR_BASE,
		label: messages.productSKUTypeC4BPayorBaseSelection,
	},
	{
		value: ProductSKUType.C4B_PAYOR_BUYUP,
		label: messages.productSKUTypeC4BPayorBuyupSelection,
	},
	{
		value: ProductSKUType.CH_DIRECT,
		label: messages.productSKUTypeCHDirectSelection,
	},
	{
		value: ProductSKUType.CH_PAYOR_BASE,
		label: messages.productSKUTypeCHPayorBaseSelection,
	},
	{
		value: ProductSKUType.CH_PAYOR_BUYUP,
		label: messages.productSKUTypeCHPayorBuyupSelection,
	},
];

function getProductSkuMessage(product_sku: ProductSKUType): MessageDescriptor {
	switch (product_sku) {
		case ProductSKUType.C4B_DIRECT:
			return messages.productSKUTypeC4BDirectSelection;
		case ProductSKUType.C4B_PAYOR_BASE:
			return messages.productSKUTypeC4BPayorBaseSelection;
		case ProductSKUType.C4B_PAYOR_BUYUP:
			return messages.productSKUTypeC4BPayorBuyupSelection;
		case ProductSKUType.CH_DIRECT:
			return messages.productSKUTypeCHDirectSelection;
		case ProductSKUType.CH_PAYOR_BASE:
			return messages.productSKUTypeCHPayorBaseSelection;
		case ProductSKUType.CH_PAYOR_BUYUP:
			return messages.productSKUTypeCHPayorBuyupSelection;
		case ProductSKUType.LEGACY_COACHING:
			return messages.productSKUTypeCalmLegacyCoachingSelection;
	}
}

function ProductSKUTypesSelector({
	formProps,
	disabled = false,
}: {
	formProps: EditPartnerFormProps;
	disabled: boolean;
}): ReactElement {
	const { formatMessage } = useIntl();
	const bindWithErrorProps = formProps.bindWithErrorProps('productSKUType', 'select');

	return (
		<>
			<Subtitle>{formatMessage(messages.productSKUTypesSubtitle)}</Subtitle>
			<div>
				<select
					{...omit(bindWithErrorProps, ['isValid', 'showValidation'])}
					id="productSKUType"
					placeholder="Select a product SKU"
					aria-labelledby="product-sku-label"
					disabled={disabled}
				>
					{productSKUOptions.map(({ value, label }) => {
						return (
							<option key={value} value={value}>
								{formatMessage(label)}
							</option>
						);
					})}
				</select>
			</div>
		</>
	);
}

function ProductSKUTypes({
	formProps,
	disabled = false,
	isEdit = false,
}: {
	formProps: EditPartnerFormProps;
	disabled: boolean;
	isEdit: boolean;
}): ReactElement {
	const { formatMessage } = useIntl();

	return (
		<Card>
			<div>
				<CellTitle showAdminFlag id="product-sku-label">
					{formatMessage(messages.productSKUTypesTitle)}
				</CellTitle>
				{isEdit ? (
					<Text>{formatMessage(getProductSkuMessage(formProps.model.productSKUType as ProductSKUType))}</Text>
				) : (
					<ProductSKUTypesSelector formProps={formProps} disabled={disabled} />
				)}
			</div>
		</Card>
	);
}

export default ProductSKUTypes;
