import { FC } from 'react';

import { FontWeights, Text } from '@calm-web/design-system';

// OG Launch Email template to preserve original "get set up" experience until we are good with the new flow.
const OgLaunchTemplate: FC<{ signupLink: string }> = ({ signupLink }) => {
	return (
		<>
			<Text el="span">We are excited to introduce the Calm app as our newest benefit!</Text>
			<br />
			<br />
			<Text el="span">
				Simply follow the instructions below to gain full access to Calm—the #1 app for mental fitness.
			</Text>
			<br />
			<br />
			<Text el="span">
				Whether you have 30 seconds or 30 minutes, Calm&apos;s diverse content library offers resources to
				suit your schedule and needs. Explore guided meditations and specialized music playlists to help with
				stress and focus, mindful movement video and audio, relaxing Sleep Stories, tailored content for
				children, wisdom-filled masterclasses led by experts, and much more.
			</Text>
			<br />
			<br />
			<Text el="p" weight={FontWeights.Medium}>
				To get started:
			</Text>
			<ol>
				<li>
					<Text el="span">
						Visit this link: <a href={signupLink}>{signupLink}</a>.
					</Text>
				</li>
				<li>
					<Text el="span">Sign up with your personal email address (or log in to an existing account)</Text>
				</li>
				<li>
					<Text el="span">Validate your eligible email address or unique ID</Text>
				</li>
			</ol>
			<br />
			<Text el="span">Once complete, you can download the Calm app and log into your new account.</Text>
			<br />
			<br />
			<Text el="span">
				Your Calm subscription gives you unlimited access to the full library of content at calm.com and in
				the Calm app. Sign up for a live{' '}
				<a href="https://business.calm.com/calm-webinars">Calm App Webinar</a> to learn more.
			</Text>
			<br />
			<br />
			<Text el="span">
				For information on Calm&apos;s privacy practices, see their privacy policy at{' '}
				<a href="https://calm.com/privacy">calm.com/privacy</a>.
			</Text>
		</>
	);
};

export default OgLaunchTemplate;
