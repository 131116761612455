import React, { useEffect, useRef, useState } from 'react';

import { PrimaryButton, Modal } from '@calm-web/design-system';

import { useActivateAchPaymentMethodLifeCycleManagement } from '@/hooks/api/useActivateAchPaymentMethodLifeCycleManagement';

import Confetti from '../Confetti';
import { TitleText, Paragraph, FooterContainer } from './styles';

export const AchModalKey = {
	PROCESSING: 'did-show-ach-payment-method-processing-modal',
	SUCCEEDED: 'did-show-ach-payment-method-succeeded-modal',
} as const;

type AchModalKey = (typeof AchModalKey)[keyof typeof AchModalKey];

const AchPendingModal: React.FC = () => {
	const confettiRef = useRef<HTMLDivElement>(null);
	const {
		isLoading: isLoadingAchStatus,
		ui: { modal: modalInfo },
		achModalKey,
	} = useActivateAchPaymentMethodLifeCycleManagement();
	const [showAchModal, setShowAchModal] = useState(false);
	const handleModalClose = (): void => {
		setShowAchModal(false);
	};
	const handleDisplayAchModal = showAchModal && achModalKey !== null;

	useEffect(() => {
		if (!isLoadingAchStatus && achModalKey) {
			const storageKey = achModalKey === 'SUCCEEDED' ? AchModalKey.SUCCEEDED : AchModalKey.PROCESSING;

			if (!localStorage.getItem(storageKey)) {
				localStorage.setItem(storageKey, 'true');
				setShowAchModal(true);
			}
		}
	}, [isLoadingAchStatus, achModalKey]);

	if (!(handleDisplayAchModal && modalInfo.title && modalInfo.bodyText)) {
		return null;
	}

	return (
		<Modal
			isOpen={true}
			closeModal={handleModalClose}
			canClose={false}
			titleElement={<TitleText>{modalInfo.title}</TitleText>}
			aria-labelledby="ach-pending-modal"
			width="Extra"
			additionalOverlay={modalInfo.showConfetti ? <Confetti bgSize="90" ref={confettiRef} /> : undefined}
		>
			<Paragraph>{modalInfo.bodyText}</Paragraph>
			<FooterContainer>
				<PrimaryButton data-testid="ach-pending-modal-button" onPress={handleModalClose}>
					{modalInfo.buttonText}
				</PrimaryButton>
			</FooterContainer>
		</Modal>
	);
};

export default AchPendingModal;
