import { ReactElement, useMemo, useState, useContext } from 'react';
import { useIntl } from 'react-intl';
import { Column } from 'react-table';

import { Modal, ModalWidth } from '@calm-web/design-system';

import { formatResizedUrl } from '@/components/pages/Pathways/ContentCard/utils';
import { ReportingContext, timeframeValues } from '@/components/providers/ReportingContextProvider';
import Table from '@/components/ui/Table';
import { ReportSession } from '@/hooks/api/reporting/useSessionsReport';
import { ReportTopContent, ReportTopContentForCategory } from '@/hooks/api/reporting/useTopContent';
import { useIsMobile } from '@/hooks/layout/useIsMobile';

import messages from './messages';
import {
	CellImage,
	MobileDataColumn,
	MobileGridWrapper,
	MobileLabel,
	MobileTopContentWrapper,
	ModalSubtitle,
	ModalTitle,
	TableNote,
	ViewButton,
} from './styles';

interface TableData {
	index: number;
	image_url: string;
	display_title: string;
	narrator_name: string;
	play_count: string | number;
}

function getTitleForContent(content: ReportTopContentForCategory | undefined): string | undefined {
	if (!content?.subtitle || content.subtitle === content?.narrator_name) {
		return content?.title;
	}
	return `${content?.title}: ${content.subtitle}`;
}

function ContentImageTableCell({ value }: { value: string }): ReactElement {
	return (
		<CellImage
			src={formatResizedUrl(value, 256)}
			width={5}
			noMargin
			verticalAlign="bottom"
			style={{ marginRight: 10 }}
		/>
	);
}

interface TopContentTableData {
	index: number;
	image_url: string;
	display_title: string;
	narrator_name: string;
	play_count: number;
}

function TopContentForCategory({
	category,
	data,
}: {
	category: keyof ReportTopContent;
	data: ReportTopContentForCategory[];
}): ReactElement {
	const [isMobile] = useIsMobile();
	const tableData: TopContentTableData[] = useMemo(
		() =>
			data
				.sort((a, b) => b.play_count - a.play_count)
				.map((datum, index) => ({
					index: index + 1,
					image_url: datum.image.url,
					display_title: getTitleForContent(datum) ?? '',
					narrator_name: datum.narrator_name,
					play_count: datum.play_count,
				})),
		[data],
	);

	const narratorHeader = useMemo(() => {
		return (
			{
				meditation: 'Narrator',
				body: 'Narrator',
				breathe: '',
				masterclass: 'Instructor',
				music: 'Artist',
				sleep: 'Narrator',
				spark: 'Guest',
			}[category] ?? 'Narrator'
		);
	}, [category]);
	const columns: Column<TopContentTableData>[] = [
		{ accessor: 'index', width: 72, minWidth: 72 },
		{ accessor: 'image_url', width: '40px', Cell: ContentImageTableCell },
		{ Header: 'Content', accessor: 'display_title', width: '40%' },
		{ Header: narratorHeader, accessor: 'narrator_name', width: '40%' },
		{ Header: 'Sessions', accessor: 'play_count', width: 100, minWidth: 100 },
	];

	return (
		<>
			{isMobile ? (
				<MobileTopContentWrapper>
					{tableData.map((data: TableData) => {
						const { index, image_url, display_title, narrator_name, play_count } = data;
						return (
							<MobileGridWrapper key={image_url}>
								<MobileDataColumn>
									<span>{index}</span>
								</MobileDataColumn>
								<MobileDataColumn>
									<ContentImageTableCell value={image_url} />
								</MobileDataColumn>
								<MobileDataColumn>
									<MobileLabel>Content</MobileLabel>
									<span>{display_title}</span>
								</MobileDataColumn>
								<MobileDataColumn>
									<MobileLabel>Narrator</MobileLabel>
									<span>{narrator_name}</span>
								</MobileDataColumn>
								<MobileDataColumn>
									<MobileLabel>Sessions</MobileLabel>
									<span>{play_count}</span>
								</MobileDataColumn>
							</MobileGridWrapper>
						);
					})}
				</MobileTopContentWrapper>
			) : (
				<Table
					columns={columns}
					data={tableData}
					loading={false}
					pageCount={1}
					showRowLines={false}
					cellVerticalAlign="inherit"
					alignHeadingsToText
					zebra
				/>
			)}
		</>
	);
}

function TopContentByCategory({
	data,
}: {
	data: {
		name: keyof ReportSession;
		value: number;
		topContent: ReportTopContentForCategory[];
	};
}): ReactElement {
	const { formatMessage } = useIntl();
	const { selectedLongerTimeframe } = useContext(ReportingContext);
	const [modalCategory, setModalCategory] = useState<null | keyof ReportTopContent>(null);
	const modalTitle = useMemo(
		() =>
			modalCategory ? modalCategory.slice(0, 1).toLocaleUpperCase() + modalCategory.slice(1) : 'category',
		[modalCategory],
	);

	return (
		<>
			<ViewButton onClick={() => setModalCategory(data.name)}>View Top 10</ViewButton>
			<Modal
				isOpen={Boolean(modalCategory)}
				closeModal={() => setModalCategory(null)}
				aria-label={`Top 10 in ${modalTitle}`}
				titleElement={
					<>
						<ModalTitle>{`Top 10 in ${modalTitle}`}</ModalTitle>
						<ModalSubtitle>
							{formatMessage(messages.modalSubtitleDynamic, {
								timeframe:
									selectedLongerTimeframe?.value === timeframeValues.CUSTOM
										? `selected ${selectedLongerTimeframe?.value}`
										: `last ${selectedLongerTimeframe?.value}`,
							})}
						</ModalSubtitle>
					</>
				}
				width={ModalWidth.Ultra}
			>
				{modalCategory ? (
					<>
						<TopContentForCategory category={modalCategory} data={data.topContent} />
						{data.topContent.length < 10 ? (
							<TableNote>{formatMessage(messages.tableNote, { category: modalTitle })}</TableNote>
						) : null}
					</>
				) : null}
			</Modal>
		</>
	);
}

export default TopContentByCategory;
