import { defineMessages } from 'react-intl';

export default defineMessages({
	title: {
		id: 'users.delete.title',
		defaultMessage: 'Delete User from Eligibility File',
		description: 'Title for the user delete modal',
	},
	header: {
		id: 'users.delete.header',
		defaultMessage: 'Remove user?',
		description: 'Header for the user delete modal',
	},
	confirm: {
		id: 'users.delete.confirm',
		defaultMessage: 'Are you sure that you want to remove <b>{partnerUserId}</b> from the eligibility file?',
		description: 'Confirm message for user delete modal',
	},
	removeUserButton: {
		id: 'users.delete.button',
		defaultMessage: 'Remove user',
		description: 'Button message for remove user',
	},
	cancelRemoveUserButton: {
		id: 'users.delete.cancel',
		defaultMessage: 'Cancel',
		description: 'Button message for canceling remove user modal',
	},
	removeError: {
		id: 'users.remove.error',
		defaultMessage: 'Oh no! Something went wrong and we were not able to remove this user.',
		description: 'Error message when removing user fails',
	},
});
