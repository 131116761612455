import { ReactElement, useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Button } from '@calm-web/design-system';

import InitialPageAnalytics from '@/components/analytics/InitialPageAnalytics';
import PartnersTable from '@/components/ui/PartnersTable';
import { useFeatureFlags, FeatureFlagNames } from '@/hooks/api/useFeatureFlags';
import { usePermissions } from '@/hooks/auth';
import { useUser } from '@/hooks/store';
import { calmLogger } from '@/utils/calmLogger';

import { CardStyled } from './styles';

export default function Groups(): ReactElement {
	const history = useHistory();
	const { search } = useLocation();
	const { user } = useUser();
	const isAdmin = user?.accessPolicy?.isAdmin;
	const allowedPartners = user?.accessPolicy?.allowedPartners;
	const [hasValidPermissions, actions] = usePermissions();

	const { data: flagValues } = useFeatureFlags(FeatureFlagNames.B2B_PARENT_PARTNER);
	const showParentColumn = flagValues && flagValues[FeatureFlagNames.B2B_PARENT_PARTNER] === true;
	const showSlugColumn = true;

	const onCreateNewPartner = (): void => {
		history.push({ pathname: '/create', search });
	};

	const selectPartner = useCallback(
		async (partnerId: string): Promise<void> => {
			if (partnerId) {
				await history.push({ pathname: `/${partnerId}/home`, search });
			} else {
				// TODO: handle global error of incorrect partner info from api
			}
		},
		[history, search],
	);

	// Redirect to partner page if there is only one available
	useEffect(() => {
		const hasOnlyOnePartner = allowedPartners?.length === 1;
		if (!isAdmin && hasOnlyOnePartner) {
			const [partnerId] = allowedPartners;
			selectPartner(partnerId).catch(err => calmLogger.error('Error in Groups selectPartner', {}, err));
		}
	}, [allowedPartners, isAdmin, selectPartner]);

	return (
		<CardStyled noPadding>
			<InitialPageAnalytics />
			<PartnersTable
				HeaderButton={
					hasValidPermissions('id', [actions.CREATE])
						? () => (
								<div>
									<Button
										backgroundColor="blue3"
										onPress={onCreateNewPartner}
										data-testid="create-partner-link"
									>
										Create New Partner
									</Button>
								</div>
						  )
						: undefined
				}
				onSelectPartner={selectPartner}
				showParentColumn={showParentColumn}
				showSlugColumn={showSlugColumn}
			/>
		</CardStyled>
	);
}
