import styled from 'styled-components';
import { palette } from 'styled-tools';

import { FontSizes, fontSize, units } from '@calm-web/design-system';

const StepIcon = styled.div`
	display: flex;
	padding: 0px;
	${fontSize(FontSizes.sm)};
	align-items: center;
	justify-content: center;
	color: ${palette('gray4')};
	border: 1px solid ${palette('gray4')};
	border-radius: ${units(4)};
	min-width: ${units(4)};
	max-width: ${units(4)};
	height: ${units(4)};
`;

export default StepIcon;
