import React, { PropsWithChildren } from 'react';

import { Button } from '@calm-web/design-system';

import { SubmitWrapper } from './styles';

interface StickySaveButtonProps {
	isFormDirty: boolean;
	hasTouchedForm: boolean;
	isLoading?: boolean;
	accountForSecondButton?: boolean;
	isDisabled?: boolean;
	dataTestId?: string;
	onPress?: () => Promise<void> | void;
}

const StickSaveButton = ({
	isFormDirty,
	isDisabled = false,
	isLoading = false,
	hasTouchedForm = false,
	accountForSecondButton = false,
	dataTestId = '',
	children,
	onPress,
}: PropsWithChildren<StickySaveButtonProps>): JSX.Element => {
	return (
		<SubmitWrapper
			isFormDirty={isFormDirty}
			hasTouchedForm={hasTouchedForm}
			accountForSecondButton={accountForSecondButton}
		>
			<Button
				backgroundColor="blue3"
				isLoading={isLoading}
				isDisabled={isLoading || isDisabled}
				type={onPress ? 'button' : 'submit'}
				data-testid={dataTestId}
				onPress={onPress}
			>
				{children}
			</Button>
		</SubmitWrapper>
	);
};

export default StickSaveButton;
