import styled from 'styled-components';

import { FontSizes, FontWeights, TextButton } from '@calm-web/design-system';

export const BannerLink = styled.a.attrs({
	target: '_blank',
	rel: 'noopener noreferrer',
})`
	text-decoration: underline;
`;

export const BannerTextButton = styled(TextButton).attrs({
	size: FontSizes.sm,
	weight: FontWeights.Light,
	textColor: 'white',
})``;
