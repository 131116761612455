import { rgba } from 'polished';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ifProp, palette, theme } from 'styled-tools';

import { fontSize, FontSizes, fontWeight, FontWeights, units } from '@calm-web/design-system';

export interface NavItemProps {
	active?: boolean;
}

export const Link = styled(NavLink)<NavItemProps>`
	padding: ${units(1, 2)};
	display: flex;
	align-items: center;
	justify-content: left;
	background: transparent;
	color: ${palette('blue3')};
	${fontSize(FontSizes.base)};
	${fontWeight(FontWeights.Medium)};
	text-decoration: none;
	border-radius: ${theme('layout.borderRadius')};
	transition: background 0.3s ease, color 0.3s ease;
	height: ${units(5)};
	line-height: ${units(5)};
	path {
		transition: fill 0.3s ease, stroke 0.3s ease;
	}

	&:hover {
		background: ${(props): string => rgba(palette('blue3')(props), 0.16)};
	}

	&.selected {
		background: ${palette('blue3')};
		color: white;
		path {
			fill: ${palette('white')};
			stroke: ${palette('white')};
		}
	}

	${ifProp(
		'initiallySelected',
		css`
			background: ${palette('blue3')};
			color: white;
		`,
	)}
`;
