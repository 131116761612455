import styled from 'styled-components';
import { ifProp, palette } from 'styled-tools';

import { fontSize, FontSizes, fontWeight, FontWeights, units } from '@calm-web/design-system';

export const Container = styled.div`
	width: 100%;
`;

export const ModalFooter = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	margin: ${units(0, 2)};
	gap: ${units(1)};
`;

export const RadioButtonWrapper = styled.div`
	display: block;
	width: 100%;
`;

export const RadioButtonLabelWrapper = styled.div`
	width: 100%;
`;

export const RadioButtonLabelMain = styled.div`
	${fontSize(FontSizes.base)};
	color: ${palette('gray8')};
	line-height: 1;
	margin-bottom: ${units(1)};
	${fontWeight(FontWeights.Medium)};
`;

export const RadioButtonLabelDescription = styled.div<{ selected: boolean }>`
	${fontSize(FontSizes.sm)};
	color: ${ifProp('selected', palette('gray8'), palette('gray5'))};
	line-height: 1.5;
`;
