import styled from 'styled-components';
import { palette } from 'styled-tools';

import { units, Loader, maxWidth } from '@calm-web/design-system';

export const Row = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 2fr;
	align-items: center;
	gap: ${units(2)};

	${maxWidth('desktop')} {
		grid-template-columns: 1fr;
	}
`;

export const StyledLoader = styled(Loader)`
	position: absolute;
	left: 0;
	right: 0;
	z-index: -1;
`;

export const ImageWrapper = styled.div`
	position: relative;
	width: 240px;
	height: 160px;
	background: ${palette('gray1')};
	border-radius: ${units(2)};
	img {
		border-radius: ${units(2)};
	}
`;

export const FieldContainer = styled.div`
	display: block;
	width: 100%;
`;
