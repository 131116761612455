import { useDispatch } from 'react-redux';

import { useApi } from '@/hooks/api';
import { setBannerMessage } from '@/store/actions';

import usePartners, { mutatePartnersResult } from './usePartners';

const buildNumChildrenQuery = (
	parentId?: string,
): {
	page: number;
	pageSize: number;
	query: string;
	filters?: { ['parent.id']: string };
} => {
	return {
		page: 0,
		pageSize: 1,
		query: '',
		filters: parentId ? { ['parent.id']: parentId } : undefined,
	};
};

export const mutatePartnerRelations = async (parentId: string): Promise<void> => {
	const numChildrenQuery = buildNumChildrenQuery(parentId);
	await mutatePartnersResult(numChildrenQuery);
};

export const useNumChildren = (
	parentId?: string,
): { numChildren: number; loading: false } | { numChildren?: undefined; loading: true } => {
	const numChildrenQuery = buildNumChildrenQuery(parentId);
	const { data: childrenResponse } = usePartners(numChildrenQuery);
	if (!parentId) {
		return { loading: true };
	}
	if (!childrenResponse) {
		return { loading: true };
	}
	return { numChildren: childrenResponse.meta.pagination.total, loading: false };
};

export const useRemoveParent = (): {
	removeParent: (childId: string, clearCache?: () => void) => Promise<void>;
} => {
	const apiRequest = useApi();
	const dispatch = useDispatch();

	async function removeParent(childId: string, clearCache?: () => void): Promise<void> {
		try {
			const response = await apiRequest({
				endpoint: `b2b/partners/${childId}`,
				method: 'PATCH',
				body: { parent: { id: null } },
			});
			if (response.status === 200) {
				if (clearCache) {
					clearCache();
				}
			}
		} catch (err) {
			dispatch(
				setBannerMessage({
					message: `Unable to remove child partner id ${childId}`,
					isError: true,
					flash: true,
				}),
			);
		}
	}

	return { removeParent };
};
