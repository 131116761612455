import { Auth0ContextInterface, User as Auth0User } from '@auth0/auth0-react';
import jwt from 'jsonwebtoken';

import { User } from '@/types/store/reducers';
import { getUserPolicy } from '@/utils/RBAC';
import getUrl from '@/utils/getUrl';

export default class Auth0AuthClient {
	private _auth0Client: Auth0ContextInterface<Auth0User>;

	public constructor(auth0Client: Auth0ContextInterface<Auth0User>) {
		this._auth0Client = auth0Client;
	}

	public async signOut(): Promise<void> {
		const returnTo = `${getUrl('partner-portal')}/login`;
		await this._auth0Client.logout({ logoutParams: { returnTo } });
	}

	public async getCalmUserFromState(): Promise<User | undefined> {
		try {
			const tokens = await this._auth0Client.getAccessTokenSilently({ detailedResponse: true });

			if (!tokens.id_token || !tokens.access_token) {
				return undefined;
			}
			const decodedAccessToken = jwt.decode(tokens.access_token);
			const userPolicy = getUserPolicy(decodedAccessToken);
			const result: User = {
				...this._auth0Client.user,
				accessToken: tokens.access_token,
				idToken: tokens.id_token,
				accessPolicy: userPolicy,
				expiresIn: tokens.expires_in,
			};
			return result;
		} catch (err) {
			if (err.error === 'missing_refresh_token') {
				// If the refresh token is missing, the user is logged out
				return undefined;
			}
			throw err;
		}
	}
}
