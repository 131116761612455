import styled, { css } from 'styled-components';
import { ifProp, palette, theme } from 'styled-tools';

import { maxWidth, FontSizes, fontSize, units } from '@calm-web/design-system';

import { Title } from '@/components/ui/CellTitle/styles';
import { boxShadow } from '@/utils/ui/boxShadow';

import Check from 'icons/check.svg';
import CircleX from 'icons/circle-x.svg';

export const AddTrialAdmin = styled.div`
	.react-share__ShareButton {
		margin-right: ${units(3)};
		margin-top: 0;
	}
`;

export const AddPartnerAdmin = styled.div`
	max-width: 100%;
`;

export const AddPrompt = styled.div`
	font-size: ${units(2)};
	margin-bottom: 1rem;
`;

export const CancelButton = styled.button`
	margin-right: ${units(4)};
	background: none;
	border: none;
	cursor: pointer;
	color: ${palette('blue3')};
`;
export const MobileLabel = styled.span`
	color: ${palette('gray5')};
	text-transform: uppercase;
	${fontSize(FontSizes.sm)};
`;
export const MobileDataColumn = styled.div`
	display: flex;
	flex-direction: column;
	align-items: start;
`;

export const MobileRow = styled.div`
	display: grid;
	grid-template-columns: 2fr 1fr 1fr;
	width: 100%;
	padding: ${units(2, 3)};

	& > ${MobileDataColumn}:last-of-type {
		align-items: end;
	}

	${maxWidth('tablet')} {
		grid-template-columns: 1fr;
		gap: ${units(3)};
	}
`;

export const MobileRowWrapper = styled.div`
	${MobileRow}:not(:last-child) {
		border-bottom: 1px solid ${palette('gray2')};
	}
`;

export const SectionHeader = styled.div`
	padding: ${units(3)};
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: space-between;
	margin-bottom: ${units(2)};
`;

export const MobileDataWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${units(1)};
`;

export const RowElement = styled.div<{ centerAlign?: boolean; rightAlign?: boolean }>`
	display: flex;
	font-size: ${units(1.5)};
	position: relative;
	${ifProp(
		'rightAlign',
		css`
			justify-content: flex-start;
		`,
	)}
	${ifProp(
		'centerAlign',
		css`
			justify-content: center;
		`,
	)}
`;

export const TableTitle = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;

	${Title} {
		width: auto;
		flex-grow: 1;
	}
`;

export const TableHeaderWrapper = styled.div`
	text-align: center;
`;

export const OptionTextButton = styled.button`
	cursor: pointer;
	color: ${palette('blue3')};
	background: none;
	border: none;
	outline: none;
	font-size: ${units(1.5)};
	padding-left: 0;
`;

export const OptionsContainer = styled.div`
	position: absolute;
	display: none;
	background-color: ${palette('white')};
	z-index: 2;
	overflow: hidden;
	flex-direction: column;
	right: 0;
	top: 0;
	background-color: ${palette('white')};
	border-radius: ${theme('layout.borderRadius')};
	border: 1px solid ${palette('gray2')};
	${boxShadow}
	min-width: ${units(24)};

	&.show-container {
		display: flex;
	}
`;

export const OptionRow = styled.div`
	cursor: pointer;
	&:hover {
		background-color: ${palette('gray1')};
	}
	padding: ${units(1)} ${units(2)};
`;

export const Overlay = styled.div`
	position: fixed;
	width: 100%;
	height: 100% !important;
	left: 0;
	top: 0;
	z-index: -1;
`;

export const CircleCheck = styled(Check)`
	width: ${units(2.5)};
	height: ${units(2.5)};
	path {
		fill: ${palette('buttonGreen')};
	}
`;

export const CircleXIcon = styled(CircleX)`
	width: ${units(2.5)};
	height: ${units(2.5)};
	path {
		fill: ${palette('errorRed')};
	}
`;

export const MarginBottomDiv = styled.div`
	margin-bottom: ${units(3)};
`;
