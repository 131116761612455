import { defineMessages } from 'react-intl';

export default defineMessages({
	bannerMessage: {
		id: 'selfServeNewContentBanner.bannerMessage',
		defaultMessage:
			'Welcome to the Calm Business Enterprise Plan! Explore your product features <underline>here.</underline>',
		description: 'Message shown for all self serve partners to inform them of new content',
	},
});
