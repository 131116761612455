import styled from 'styled-components';
import { palette } from 'styled-tools';

import { minWidth, fontSize, FontSizes, units } from '@calm-web/design-system';

export const LoaderContainer = styled.div`
	height: ${units(4)};
`;

export const LoadingText = styled.div`
	text-align: center;
	color: ${palette('gray8')};
	${fontSize(FontSizes.sm)}
	${minWidth('tablet')} {
		min-width: ${units(52)};
	}
`;
