import { Dispatch, FC, ReactElement, SetStateAction, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { CellProps } from 'react-table';

import Table from '@/components/ui/Table';
import { Feedback } from '@/hooks/api/useFeedback';
import { useIsMobile } from '@/hooks/layout/useIsMobile';

import messages from '../messages';
import { ExpandResponseButton, PinnedCheckbox, TimestampCell } from '../styles';

const MAX_TEXT_LENGTH = 190;

type RowData = Omit<Feedback, 'pinned'> & { pinned: string };

function CreatedCell({ value }: CellProps<RowData, string>): ReactElement {
	const formattedDate = new Date(value).toLocaleDateString();
	return <TimestampCell>{formattedDate}</TimestampCell>;
}

function ResponseCell(
	props: CellProps<RowData, string> & {
		expandedFeedback: Record<string, boolean | undefined>;
		setExpandedFeedback: Dispatch<SetStateAction<Record<string, boolean | undefined>>>;
	},
): ReactElement {
	const {
		value,
		row: {
			original: { id: responseId },
		},
		expandedFeedback,
		setExpandedFeedback,
	} = props;
	const willTruncate = value.length >= MAX_TEXT_LENGTH && !expandedFeedback[responseId];
	const textToShow = willTruncate ? `${value.slice(0, MAX_TEXT_LENGTH)}…` : value;
	return (
		<>
			&ldquo;{textToShow}&rdquo;
			{willTruncate ? (
				<ExpandResponseButton
					onClick={() => setExpandedFeedback({ ...expandedFeedback, [responseId]: true })}
				>
					expand
				</ExpandResponseButton>
			) : null}
		</>
	);
}

function PinnedCell(
	props: CellProps<RowData, string> & {
		onChangeFeedbackPin: (args: { id: string; pinned: boolean }) => void;
		isMobile: boolean;
	},
): ReactElement {
	const {
		value,
		row: {
			original: { id: responseId },
		},
		onChangeFeedbackPin,
		isMobile,
	} = props;
	return (
		<PinnedCheckbox
			onChange={e =>
				onChangeFeedbackPin({
					id: responseId,
					pinned: e.target.checked,
				})
			}
			isMobile={isMobile}
			name="pinned"
			checked={value === String(true)}
			aria-label="Pinned"
		/>
	);
}

export default function FeedbackTable({
	feedback,
	totalCount,
	loading,
	error,
	pageSize,
	pageIndex,
	setPageIndex,
	canPinFeedback,
	onChangeFeedbackPin,
}: {
	feedback?: Feedback[];
	totalCount?: number;
	loading: boolean;
	error: boolean;
	pageSize: number;
	pageIndex: number;
	setPageIndex: (value: SetStateAction<number>) => void;
	canPinFeedback: boolean;
	onChangeFeedbackPin: (props: { id: string; pinned: boolean }) => void;
}): ReturnType<FC> {
	const [isMobile] = useIsMobile();
	const { formatMessage } = useIntl();
	const [expandedFeedback, setExpandedFeedback] = useState<Record<string, boolean | undefined>>({});
	const columns = useMemo(() => {
		const commonColumns = [
			{
				accessor: 'response_text',
				Cell: (props: CellProps<RowData, string>) => (
					<ResponseCell
						{...props}
						expandedFeedback={expandedFeedback}
						setExpandedFeedback={setExpandedFeedback}
					/>
				),
				width: isMobile ? '75%' : '85%',
			},
			{
				accessor: 'created_at',
				Cell: CreatedCell,
				width: '15%',
			},
		] as {
			accessor: keyof RowData;
			Cell: (props: CellProps<RowData, string>) => ReactElement;
			width?: string;
		}[];
		if (canPinFeedback) {
			commonColumns.unshift({
				accessor: 'pinned',
				width: isMobile ? '8%' : '',
				Cell: (props: CellProps<RowData, string>) => (
					<PinnedCell {...props} isMobile={isMobile} onChangeFeedbackPin={onChangeFeedbackPin} />
				),
			});
		}
		return commonColumns;
	}, [canPinFeedback, expandedFeedback, onChangeFeedbackPin, isMobile]);
	const tableData = useMemo(
		() =>
			feedback?.map(f => ({
				...f,
				response_text: f.response_text.replace(/[\s]+/g, ' '),
				pinned: String(f.pinned),
			})) ?? [],
		[feedback],
	);
	const pageCount = Math.max(1, Math.ceil((totalCount ?? 0) / pageSize));
	return (
		<Table
			columns={columns}
			data={tableData}
			fetchData={({ pageIndex: newPageIndex }: { pageIndex: number }): void => setPageIndex(newPageIndex)}
			loading={loading}
			pageCount={pageCount}
			error={error ? formatMessage(messages.errorMessage) : undefined}
			pageSize={pageSize}
			forcePageIndex={pageIndex}
			showHeader={false}
			showRowLines={false}
			horizontalInset={false}
			paginationBackgroundColor="transparent"
			cellNoPadding={isMobile}
		/>
	);
}
