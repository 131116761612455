import { MouseEventHandler } from 'react';
import styled from 'styled-components';
import { ifProp, prop } from 'styled-tools';

import { FontSizes, fontSize, units } from '@calm-web/design-system';

import { buttonCssReset } from '@/utils/ui/buttonCssReset';

export const Container = styled.div`
	width: 100%;
`;

export const StyledFeedbackItem = styled.button<{
	width: number;
	onClick?: MouseEventHandler<HTMLButtonElement>;
}>`
	${buttonCssReset};

	background: linear-gradient(135deg, rgba(57, 207, 255, 0.16) 0%, rgba(52, 137, 255, 0.16) 100%);
	box-shadow: inset 0px -2px 8px rgba(85, 91, 228, 0.08);
	border-radius: ${units(1)};
	padding: ${units(1, 2)};
	margin-bottom: ${units(2)};
	${fontSize(FontSizes.sm)};
	width: ${prop('width')}px;
	max-width: ${units(40)};
	${ifProp('onClick', 'cursor: pointer')};
`;

export const StyledFeedbackList = styled.div<{ maxColumns: number }>`
	margin: auto;
	margin-bottom: ${units(-2)};
`;
