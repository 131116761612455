import useSWR from 'swr';

import { useApi } from '@/hooks/api';
import { useFeatureFlags } from '@/hooks/api/useFeatureFlags';
import CalmCookie from '@/utils/CalmCookie';

import { ApiResponse } from './types';

function useHrReferralV1(): ApiResponse<boolean> {
	const apiRequest = useApi();

	const { data, error } = useSWR('b2b/hr-referral/supported', async endpoint => {
		const response = await apiRequest({ endpoint });
		if (!response.data) {
			throw new Error('Unable to fetch whether HR referral is supported');
		}
		return response;
	});

	return { data: data?.data?.is_supported, error, loading: !data && !error };
}

const HR_REFERRAL_FF_V2 = 'b2b-calm-referral';
const HR_REFERRAL_FF_V2_MODAL = 'b2b-calm-referral-modal';
const HR_REFERRAL_FF_AMOUNT_100 = 'b2b-100-hr-referral-amount';

function useHrReferralV2(): ApiResponse<boolean> {
	const { data: flagValues, error: flagError, loading: flagLoading } = useFeatureFlags(HR_REFERRAL_FF_V2);

	if (flagLoading || !flagValues) {
		return { data: undefined, error: undefined, loading: true };
	}

	if (flagError) {
		return { data: undefined, error: flagError, loading: false };
	}

	const enabled = flagValues[HR_REFERRAL_FF_V2] === true;
	return { data: enabled, error: flagError, loading: false };
}

function useShouldShowReferralPopupV2(): ApiResponse<boolean> {
	const { data: v1Data, error: v1Error, loading: v1Loading } = useHrReferralV1();
	const {
		data: flagValues,
		error: flagError,
		loading: flagLoading,
	} = useFeatureFlags(HR_REFERRAL_FF_V2_MODAL);

	const error = v1Error || flagError;
	const loading = v1Loading || flagLoading;

	if (!flagValues || loading) {
		return { data: undefined, error: undefined, loading: true };
	}

	if (error || loading) {
		return { data: undefined, error, loading: false };
	}

	const hasCookie = CalmCookie.get('has-seen-referral-popup-v2') === 'true';

	const canSeeReferralV2Modal = flagValues[HR_REFERRAL_FF_V2_MODAL] === true;

	return {
		data: v1Data && canSeeReferralV2Modal && !hasCookie,
		error: undefined,
		loading: false,
	};
}

function useCanSeeReferralAmount100(): ApiResponse<boolean> {
	const { data: flagValues, error, loading } = useFeatureFlags(HR_REFERRAL_FF_AMOUNT_100);

	if (error || loading || !flagValues) {
		return {
			data: undefined,
			error,
			loading,
		};
	}
	const canSee100Referral = flagValues && flagValues[HR_REFERRAL_FF_AMOUNT_100] === true;

	return {
		data: canSee100Referral,
		error: undefined,
		loading: false,
	};
}

export default function useCanSeeReferral(): ApiResponse<{
	canSeeReferralV1: boolean;
	canSeeReferralV2: boolean;
	canSeeReferralV2Popup: boolean;
	canSeeReferral100: boolean;
}> {
	const { data: v1Data, error: v1Error, loading: v1Loading } = useHrReferralV1();
	const { data: v2Data, error: v2Error, loading: v2Loading } = useHrReferralV2();
	const { data: popupData, error: popupError, loading: popupLoading } = useShouldShowReferralPopupV2();
	const {
		data: referralAmount100Data,
		error: referralAmount100Error,
		loading: referralAmount100Loading,
	} = useCanSeeReferralAmount100();

	if (v1Loading || v2Loading || popupLoading || referralAmount100Loading) {
		return { data: undefined, error: undefined, loading: true };
	}

	const error = v1Error || v2Error || popupError || referralAmount100Error;
	if (error) {
		return { data: undefined, error, loading: false };
	}

	return {
		data: {
			canSeeReferralV1: v1Data === true && !v2Data === true,
			canSeeReferralV2: v1Data === true && v2Data === true,
			canSeeReferralV2Popup: v1Data === true && popupData === true,
			canSeeReferral100: referralAmount100Data === true,
		},
		error: undefined,
		loading: false,
	};
}
