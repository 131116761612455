import { FC, FormEvent, useCallback, ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';

import { FontSizes, FontWeights, Loader, Text } from '@calm-web/design-system';
import { stringFromModelValue } from '@calm-web/use-form';

import useHealthConfig, { useSubmitExistingHealthConfigForm } from '@/hooks/api/useHealthConfig';
import { useHealthConfigForm, useHealthConfigSubmitData } from '@/hooks/forms/useHealthConfigForm';
import { useShouldShowErrorEligibilityConfig } from '@/hooks/useShouldShowErrorEligibilityConfig';
import { HealthConfig as HealthConfigType } from '@/types/health';

import StickySaveButton from '../../../StickySaveButton';
import EligibilityValidators from './EligibilityValidators';
import ErrorEligibilityConfig from './ErrorEligibilityConfig';
import HealthSponsorships from './HealthSponsorships';
import InAppClientSupport from './InAppClientSupport';
import LandingPageCustomization from './LandingPageCustomization';
import EligibilityValidatorPreview from './Previews/EligibilityValidatorPreview';
import ErrorCustomizationPreview from './Previews/ErrorCustomizationPreview';
import HealthSponsorshipPreview from './Previews/HealthSponsorshipPreview';
import InAppClientSupportPreview from './Previews/InAppClientSupportPreview';
import LandingPagePreview from './Previews/LandingPagePreview';
import messages from './messages';
import { ConfigWrapper, PreviewWrapper, Wrapper } from './styles';

interface HealthConfigProps {
	partnerId: string;
	healthConfig: HealthConfigType;
}

type PreviewFocus = 'support' | 'landing' | 'eligibility' | 'sponsorship' | 'errorCustomization';

const HealthConfigInner: FC<HealthConfigProps> = ({ partnerId, healthConfig }) => {
	const { formatMessage } = useIntl();
	const healthConfigForm = useHealthConfigForm(healthConfig);
	const { getHealthConfigSubmitData, showValidationErrors } = useHealthConfigSubmitData(
		healthConfigForm.baseFormProps,
		healthConfigForm.eligibilityValidatorFormProps,
		healthConfigForm.errorCustomizationFormProps,
	);
	const shouldShowErrorEligibilityConfig = useShouldShowErrorEligibilityConfig();
	const [submitExistingHealthConfigForm, { loading }] = useSubmitExistingHealthConfigForm(
		healthConfigForm.resetAllDirtyStates,
	);
	const [focus, setFocus] = useState<PreviewFocus>('support');

	const landingOnFocus = useCallback((): void => {
		setFocus('landing');
	}, [setFocus]);
	const clientSupportOnFocus = useCallback((): void => {
		setFocus('support');
	}, [setFocus]);
	const eligibilityOnFocus = useCallback((): void => {
		setFocus('eligibility');
	}, [setFocus]);
	const sponsorshipOnFocus = useCallback((): void => {
		setFocus('sponsorship');
	}, [setFocus]);
	const eligibilityErrorCustomizationOnFocus = useCallback((): void => {
		setFocus('errorCustomization');
	}, [setFocus]);

	const onSubmit = async (e: FormEvent): Promise<void> => {
		e.preventDefault();
		if (showValidationErrors()) return;
		const healthConfigSubmitData = getHealthConfigSubmitData();

		try {
			await submitExistingHealthConfigForm(healthConfigSubmitData, partnerId);
		} catch (error) {
			//...
		}
	};

	const previews: Map<PreviewFocus, ReactNode> = new Map([
		[
			'landing',
			<LandingPagePreview
				key="landing" // linter requires this for who knows what reason
				title={stringFromModelValue(healthConfigForm.baseFormProps.model.landingHeader) ?? ''}
				body={stringFromModelValue(healthConfigForm.baseFormProps.model.landingBody) ?? ''}
			/>,
		],
		[
			'support',
			<InAppClientSupportPreview
				key="support" // linter requires this for who knows what reason
				clientSupportChannel={
					stringFromModelValue(healthConfigForm.baseFormProps.model.clientSupportChannel) ?? 'phone'
				}
				healthConfig={healthConfig}
			/>,
		],
		[
			'eligibility',
			<EligibilityValidatorPreview
				key="eligiblity" // linter requires this for who knows what reason
				formProps={healthConfigForm.eligibilityValidatorFormProps}
				healthConfig={healthConfig}
			/>,
		],
		[
			'sponsorship',
			<HealthSponsorshipPreview
				key="eligiblity" // linter requires this for who knows what reason
				partnerId={partnerId}
				name="Sponsor Group 1"
			/>,
		],
		[
			'errorCustomization',
			<ErrorCustomizationPreview
				key="errorCustomization"
				errorBody={stringFromModelValue(healthConfigForm.errorCustomizationFormProps.model.error_body)}
				errorButtonText={stringFromModelValue(
					healthConfigForm.errorCustomizationFormProps.model.error_button_text,
				)}
			/>,
		],
	]);
	const focusPreview = previews.get(focus);

	return (
		<>
			<form onSubmit={onSubmit} style={{ marginBottom: '4rem' }}>
				<Wrapper>
					<ConfigWrapper>
						<Text el="h2" size={FontSizes.xl} weight={FontWeights.Regular} color="gray7">
							{formatMessage(messages.healthConfigTitle)}
						</Text>
						<LandingPageCustomization formProps={healthConfigForm.baseFormProps} onFocus={landingOnFocus} />
						<InAppClientSupport formProps={healthConfigForm.baseFormProps} onFocus={clientSupportOnFocus} />
						<EligibilityValidators
							formProps={healthConfigForm.eligibilityValidatorFormProps}
							addEligibilityValidator={healthConfigForm.addEligibilityValidator}
							removeEligibilityValidator={healthConfigForm.removeEligibilityValidator}
							onFocus={eligibilityOnFocus}
						/>
						{shouldShowErrorEligibilityConfig && (
							<ErrorEligibilityConfig
								formProps={healthConfigForm.errorCustomizationFormProps}
								onFocus={eligibilityErrorCustomizationOnFocus}
							/>
						)}
						<HealthSponsorships partnerId={partnerId} onFocus={sponsorshipOnFocus} />
					</ConfigWrapper>
					<PreviewWrapper>{focusPreview}</PreviewWrapper>
				</Wrapper>
				<StickySaveButton
					isFormDirty={healthConfigForm.hasChangedAny}
					hasTouchedForm={healthConfigForm.hasTouchedAny}
					isLoading={loading}
					dataTestId="create-edit-health-config-save-btn"
				>
					Save Configuration
				</StickySaveButton>
			</form>
		</>
	);
};

const HealthConfig: FC<{ partnerId: string }> = ({ partnerId }) => {
	const { data: healthConfig, loading } = useHealthConfig({ partnerId });

	if (loading) {
		return <Loader />;
	}

	return healthConfig ? <HealthConfigInner partnerId={partnerId} healthConfig={healthConfig} /> : null;
};

export default HealthConfig;
