import { CalmError } from '@/utils/apiRequest/errors';

import { useFeatureFlags, FeatureFlagNames } from './api/useFeatureFlags';

interface FlagLoadingState {
	loading: boolean;
	error: Error | CalmError | undefined;
}

type Response = [boolean, FlagLoadingState];

export const useShouldShowSelfServeTaxesOnUpgrade = (): Response => {
	const {
		data: flagValues,
		error: flagError,
		loading: flagLoading,
	} = useFeatureFlags(FeatureFlagNames.B2B_SELF_SERVE_TAXES_ON_UPGRADES);
	const flagsLoaded = !flagLoading && !flagError && flagValues;
	const selfServeTaxesOnUpgradeFlag =
		flagValues && (flagValues[FeatureFlagNames.B2B_SELF_SERVE_TAXES_ON_UPGRADES] as boolean | undefined);
	const shouldShowSelfServeTaxesOnUpgrade = flagsLoaded && selfServeTaxesOnUpgradeFlag;

	return [Boolean(shouldShowSelfServeTaxesOnUpgrade), { loading: flagLoading, error: flagError }];
};
