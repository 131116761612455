import { ReactElement, useContext } from 'react';
import { useIntl } from 'react-intl';

import { PathwaysContext } from '@/components/providers/PathwaysContextProvider';
import ResourceLink from '@/components/ui/ResourceLink';

import DocumentIcon from 'icons/document-filled.svg';

import messages from './messages';
import { Wrapper, Header } from './styles';

function Resources({ header }: { header: string }): ReactElement | null {
	const { formatMessage } = useIntl();
	const { selectedPathway } = useContext(PathwaysContext);

	// TODO: BE will eventually return these resources dynamically for each pack, but for initial launch we will keep them hardcoded on the FE
	const placeholderLinks: { [pack_class: string]: { template: string; flyer: string } } = {
		'b2b-pathway-setting-boundaries': {
			template:
				'https://info.calm.com/rs/541-LYF-023/images/Calm-Business-Pathway-Setting-Boundaries-Announcement-Templates.docx',
			flyer:
				'https://docs.google.com/presentation/d/1Vp6PfGHiH79ATA-KXXDTLW-x45z3Qf7dltVbszspAKo/edit#slide=id.g22ab3ff57fa_0_44',
		},
		'b2b-pathways-manage-stress-and-overwhelm': {
			template:
				'https://info.calm.com/rs/541-LYF-023/images/Calm-Business-Pathway-Managing-Stress-Overwhelm-Announcement-Templates.docx',
			flyer:
				'https://docs.google.com/presentation/d/15l1kSURoQj8vN0wY4kzz5oRLHsiXAsBOKHJr2HB3B0k/edit#slide=id.g22ab3ff57fa_0_63',
		},
		'b2b-pathways-build-a-self-care-routine': {
			template:
				'https://info.calm.com/rs/541-LYF-023/images/Calm-Business-Pathway-Building-a-Self-Care-Routine-Announcement-Templates.docx',
			flyer:
				'https://docs.google.com/presentation/d/1AMAUsqLzT9U-DITAVwuDqApE1dqiSZawamNtk4qYwmM/edit#slide=id.g22ab3ff57fa_0_81',
		},
		'b2b-pathways-recenter-yourself-through-gratitude': {
			template:
				'https://info.calm.com/rs/541-LYF-023/images/Calm-Business-Pathway-Recenter-Yourself-Through-Gratitude-Announcement-Templates.docx',
			flyer:
				'https://docs.google.com/presentation/d/1VFVUtrbtd88EIhDA6hY-HoJSJb5pZcy9z3EGNFxqAu8/edit#slide=id.g22ab3ff57fa_0_81',
		},
		'b2b-pathways-ground-yourself-during-stressful-times': {
			template:
				'https://info.calm.com/rs/541-LYF-023/images/Calm_Business_Pathway_Ground_Yourself_During_Stressful_Times_Announcement_Templates.docx',
			flyer:
				'https://docs.google.com/presentation/d/190Yhlb1iUUMA036r-TQ_zVbMYkwg21JxQM_hmgxR19s/edit#slide=id.g22ab3ff57fa_0_81',
		},
		'b2b-pathways-getting-started-with-calm': {
			template:
				'https://info.calm.com/rs/541-LYF-023/images/Calm_Business_Pathway_Getting_Started_with%20Calm_Announcement_Templates.docx?version=0',
			flyer:
				'https://docs.google.com/presentation/d/1oqEM-2Uz_6ujZG8chb76_vSkU79LI0kpZRv_19YStsg/edit#slide=id.g22ab3ff57fa_0_81',
		},
		'b2b-pathways-building-healthy-habits': {
			template:
				'https://info.calm.com/rs/541-LYF-023/images/Calm_Business_Pathway_Building_Healthy_Habits_Announcement_Templates.docx?version=1',
			flyer:
				'https://docs.google.com/presentation/d/1V9dWTWP0bUTvdfHKSCUxp6kgsI-B9MzBWsSOs3BRpFw/edit#slide=id.g266d71e6eea_0_0',
		},
	};

	const shouldShowTemplate = selectedPathway && placeholderLinks[selectedPathway.pack_class]?.template;
	const shouldShowFlyer = selectedPathway && placeholderLinks[selectedPathway.pack_class]?.flyer;

	const shouldShowResources = shouldShowTemplate || shouldShowFlyer;
	if (!shouldShowResources) {
		return null;
	}

	return (
		<>
			<Header customMargin={2}>{header}</Header>
			<Wrapper>
				{shouldShowTemplate && (
					<ResourceLink
						href={placeholderLinks[selectedPathway.pack_class].template}
						Icon={DocumentIcon}
						openInNewTab
						shouldHideBorder
						iconColor="blue3"
						textColor="blue3"
					>
						{formatMessage(messages.sampleResource1)}
					</ResourceLink>
				)}
				{shouldShowFlyer && (
					<ResourceLink
						href={placeholderLinks[selectedPathway.pack_class].flyer}
						Icon={DocumentIcon}
						openInNewTab
						shouldHideBorder
						iconColor="blue3"
						textColor="blue3"
					>
						{formatMessage(messages.sampleResource2)}
					</ResourceLink>
				)}
			</Wrapper>
		</>
	);
}

export default Resources;
