import styled from 'styled-components';

import { FontSizes, FontWeights, Text, TextElements, units, maxWidth } from '@calm-web/design-system';

export const CellSubtitle = styled(Text).attrs({
	color: 'gray8',
	el: TextElements.H4,
	size: FontSizes.sm,
	weight: FontWeights.Medium,
})`
	margin-top: 0;
	margin-bottom: ${units(1.5)};
	width: 100%;
`;

export const FlexDiv = styled.div`
	display: flex;
	align-items: center;
`;

export const LinkOutTitle = styled(Text).attrs({
	color: 'gray8',
	el: TextElements.H4,
	size: FontSizes.base,
	weight: FontWeights.Medium,
})`
	margin: ${units(1.5)} 0;
	width: 100%;
`;

export const Wrapper = styled.section`
	display: flex;
	gap: ${units(2)};

	${maxWidth('desktopLarge')} {
		flex-wrap: wrap;
	}
`;

export const ConfigWrapper = styled.div`
	max-width: ${units(85)};
`;
