import { defineMessages } from 'react-intl';

export default defineMessages({
	loadingMessage: {
		id: 'reporting.loadingMessage',
		defaultMessage: 'Generating your report can take up to 10 seconds.',
		description: 'The loading message presented when the CH report is loading',
	},
	breatheMessage: {
		id: 'reporting.breatheMessage',
		defaultMessage: 'Take a deep breath while you wait.',
		description: 'The message presented below the loading message.',
	},
});
