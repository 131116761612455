import { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { FontSizes, TextButton } from '@calm-web/design-system';

import AdminFlag from '@/components/ui/AdminFlag';
import Subtitle from '@/components/ui/CellSubtitle';
import { setBannerMessage } from '@/store/actions';
import { Partner } from '@/types/store/reducers';

import messages from './messages';
import { AccountManagerToolsContainer, ClientIdInput, CopyWrapper } from './styles';

export default function AccountManagerTools({ partner }: { partner: Partner }): ReactElement {
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();

	async function copyClientId(): Promise<void> {
		if (!navigator.clipboard) {
			const clientIdElement = document.createElement('textarea');
			clientIdElement.value = partner.client_id;
			// Avoid scrolling to bottom
			clientIdElement.style.top = '0';
			clientIdElement.style.left = '0';
			clientIdElement.style.position = 'fixed';
			document.body.appendChild(clientIdElement);
			clientIdElement.focus();
			clientIdElement.select();
			document.execCommand('copy');
			document.body.removeChild(clientIdElement);
		} else {
			await navigator.clipboard.writeText(partner.client_id);
		}
		dispatch(
			setBannerMessage({
				message: 'Partner UID copied to clipboard',
				isError: false,
				flash: true,
			}),
		);
	}

	return (
		<AccountManagerToolsContainer>
			<div>
				<Subtitle>
					{formatMessage(messages.clientIdLabel)}
					<AdminFlag />
				</Subtitle>
				<CopyWrapper>
					<ClientIdInput
						name="clientId"
						data-testid="clientId"
						label={formatMessage(messages.clientIdPlaceholder)}
						value={partner.client_id}
						readOnly
						noValidation
					/>
					<TextButton onClick={copyClientId} size={FontSizes.sm} hideUnderline>
						{formatMessage(messages.copyButtonText)}
					</TextButton>
				</CopyWrapper>
			</div>
		</AccountManagerToolsContainer>
	);
}
