import omit from 'lodash/omit';
import { FC, ReactNode, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Loader, Text, FontWeights } from '@calm-web/design-system';

import InitialPageAnalytics from '@/components/analytics/InitialPageAnalytics';
import { useFeatureFlags } from '@/hooks/api/useFeatureFlags';
import { Feedback, useFeedback } from '@/hooks/api/useFeedback';
import { usePermissions } from '@/hooks/auth';

import reportingMessages from '../messages';
import { ErrorMessage, LoaderContainer } from '../styles';
import FeedbackTable from './FeedbackTable';
import PinnedFeedbackTable from './PinnedFeedbackTable';
import { Container } from './styles';

const PAGE_SIZE = 10;

export default function AllFeedbackSubpage(): ReturnType<FC> {
	const { partnerId } = useParams();
	const {
		data: flagValues,
		error: flagError,
		loading: flagLoading,
	} = useFeatureFlags('b2b-reporting-v2-feedback');
	const { formatMessage } = useIntl();
	const [pageIndex, setPageIndex] = useState(0);
	const {
		data: feedback,
		loading,
		error,
		clearCache: clearFeedbackCache,
	} = useFeedback(partnerId, undefined, PAGE_SIZE, pageIndex * PAGE_SIZE);
	const [modifiedPinnedStatus, setModifiedPinnedStatus] = useState<
		Record<string, Feedback | false | undefined>
	>({});
	const [hasValidPermissions, actions] = usePermissions();
	const canPinFeedback = hasValidPermissions('partner_user_feedback', [actions.UPDATE]);
	const tableData = useMemo(
		() =>
			feedback?.feedback.map(f => ({
				...f,
				pinned:
					typeof modifiedPinnedStatus[f.id] === 'undefined' ? f.pinned : Boolean(modifiedPinnedStatus[f.id]),
			})) ?? [],
		[feedback?.feedback, modifiedPinnedStatus],
	);
	const onChangeFeedbackPin = useMemo(
		() =>
			({ id, pinned }: { id: string; pinned: boolean }) => {
				if (typeof modifiedPinnedStatus[id] === 'undefined') {
					if (pinned) {
						const feedbackRecord = feedback?.feedback.find(f => f.id === id);
						if (feedbackRecord) {
							setModifiedPinnedStatus({
								...modifiedPinnedStatus,
								[id]: feedbackRecord,
							});
						}
					} else {
						setModifiedPinnedStatus({
							...modifiedPinnedStatus,
							[id]: pinned,
						});
					}
				} else {
					setModifiedPinnedStatus(omit(modifiedPinnedStatus, id));
				}
			},
		[feedback, modifiedPinnedStatus],
	);

	if ((!loading && error) || (!flagLoading && flagError)) {
		return (
			<Container>
				<ErrorMessage>
					{formatMessage(reportingMessages.pageLoadErrorMessage, {
						supportlink: (...chunks: ReactNode[]) => (
							<a href="https://support.calm.com/hc/en-us/articles/1260801520609">{chunks}</a>
						),
					})}
				</ErrorMessage>
			</Container>
		);
	}
	const showAsLoading = loading || !feedback || flagLoading || !flagValues;
	const canSeeFeedback = flagValues && flagValues['b2b-reporting-v2-feedback'] === true;
	if (!showAsLoading && !canSeeFeedback) {
		if (typeof window !== 'undefined') {
			window.location.href = `/${partnerId}/home`;
		}
		return (
			<Container>
				<LoaderContainer>
					<Loader color="gray5" />
				</LoaderContainer>
			</Container>
		);
	}

	return (
		<Container>
			<InitialPageAnalytics />
			<Text el="h1" styleAs="h3" weight={FontWeights.Medium}>
				All feedback
			</Text>
			{canPinFeedback ? (
				<>
					<PinnedFeedbackTable
						partnerId={partnerId}
						onChangeFeedbackPin={onChangeFeedbackPin}
						modifiedPinnedStatus={modifiedPinnedStatus}
						onSavePins={() => {
							clearFeedbackCache();
							setModifiedPinnedStatus({});
						}}
					/>
					<Text el="h3" styleAs="h5" weight={FontWeights.Medium}>
						All
					</Text>
				</>
			) : null}
			<FeedbackTable
				feedback={tableData}
				totalCount={feedback?.totalCount}
				loading={loading || !feedback}
				error={Boolean(error)}
				pageSize={PAGE_SIZE}
				pageIndex={pageIndex}
				setPageIndex={setPageIndex}
				onChangeFeedbackPin={onChangeFeedbackPin}
				canPinFeedback={canPinFeedback}
			/>
		</Container>
	);
}
