import { defineMessages } from 'react-intl';

export default defineMessages({
	pathwayBannerText1: {
		id: 'pathways.bannerText1',
		defaultMessage: 'What are Pathways?',
		description: 'Banner message text about what pathways are',
	},
	pathwayBannerText2: {
		id: 'pathways.bannerText2',
		defaultMessage:
			'Pathways guide and educate your people through a curated collection of in-app Calm content designed to support specific mental health challenges within your organization.',
		description: 'Banner message text about what pathways are',
	},
	pathwayBannerText3: {
		id: 'pathways.bannerText3',
		defaultMessage:
			'Pathway participants will learn practical tools and techniques to apply to their daily lives. On average, Pathways will be about 2 weeks long with new guidance typically sent every other business day.',
		description: 'Banner message text about what pathways are',
	},
	pathwayHeading: {
		id: 'pathways.heading',
		defaultMessage: 'Your Pathways',
		description: 'Pathways page heading',
	},
	createButton: {
		id: 'pathways.createButton',
		defaultMessage: 'Create a Pathway',
		description: 'Pathway create button text',
	},
});
