import { defineMessages } from 'react-intl';

export default defineMessages({
	title: {
		id: 'users.add.title',
		defaultMessage: 'Add a new user',
		description: 'Title for the file add modal',
	},
	segmentsError: {
		id: 'users.add.segments.error',
		defaultMessage: 'Oh no! Something went wrong.',
		description: 'Error message when loading segments for the add user modal fails',
	},
	addError: {
		id: 'users.add.error',
		defaultMessage: 'Oh no! Something went wrong and we were not able to add this user.',
		description: 'Error message when adding user fails',
	},
	addUserButton: {
		id: 'users.add.button',
		defaultMessage: 'Add a new user',
		description: 'Button message for adding a new user',
	},
	uploadFileHeading: {
		id: 'users.upload.heading',
		defaultMessage: 'Upload a file',
		description: 'Heading for uploading a new eligibility file.',
	},
	uploadFileButton: {
		id: 'users.upload.button',
		defaultMessage: 'Pick a new file to upload',
		description: 'Button message for uploading a new eligibility file.',
	},
	orDivider: {
		id: 'users.divider.or',
		defaultMessage: 'or',
		description: 'Text shown in divider between upload or add options.',
	},
	segmentInfoText: {
		id: 'users.divider.segmentInfoLabel',
		defaultMessage:
			'<bold>Include user in segmented data (optional)</bold><exampleText>For example, their department or location</exampleText>',
		description: 'Text shown in divider between upload or add options.',
	},
	uniqueIdText: {
		id: 'users.divider.uniqueIdLabel',
		defaultMessage:
			"<bold>Enter the user's unique ID (required)</bold><exampleText>For example, their work email address</exampleText>",
		description: 'Text shown in divider between upload or add options.',
	},
	cancelAddUserButton: {
		id: 'users.add.cancel',
		defaultMessage: 'Cancel',
		description: 'Button message for canceling add user modal',
	},
	uniqueIdPlaceholder: {
		id: 'users.add.uniqueId.placeholder',
		defaultMessage: 'e.g. work email address',
		description: 'Placeholder for unique id input',
	},
	segmentInputPlaceholder: {
		id: 'users.add.segmentValue.input',
		defaultMessage: 'Enter value',
		description: 'Placeholder for segment value input',
	},
	additionalCoveragePrompt: {
		id: 'users.add.additionalCoveragePrompt',
		defaultMessage: 'Would you like to purchase additional coverage?',
		description: 'Message prompting users to purchase additional coverage',
	},
});
