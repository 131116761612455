import { rgba } from 'polished';
import styled, { css } from 'styled-components';
import { ifProp, palette, theme } from 'styled-tools';

interface ImageElementProps {
	src: string;
	'data-src'?: string;
	'data-bgset'?: string;
	isFullScreen?: boolean;
}

export const Wrapper = styled.div`
	position: relative;
	width: auto;
	height: 100%;
	display: flex;
`;

export const ImageContainer = styled.div`
	position: relative;
	height: 100%;
	width: auto;
`;

export const ImageElement = styled.img<ImageElementProps>`
	height: 100%;
	width: 100%;

	${ifProp(
		'isFullScreen',
		css`
			object-fit: cover;
		`,
	)}
`;

export const ImageMask = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	width: auto;
	height: 100%;
	background: ${(props): string => rgba(palette('white')(props), 0.1)};
	backdrop-filter: blur(50px);
	opacity: 1;

	&.isRendered {
		opacity: 0;
		transition: ${theme('animation.transition')};
	}
`;
