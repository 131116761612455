import { useDispatch } from 'react-redux';
import useSWR, { mutate } from 'swr';

import { useApi } from '@/hooks/api';
import { useUser } from '@/hooks/store';
import { setBannerMessage } from '@/store/actions';

import { ApiResponse } from './types';

export interface Stats {
	numCoveredLives: number;
}

interface RawStats {
	num_covered_lives: number;
}

function parseStats(data: RawStats): Stats {
	return { numCoveredLives: data.num_covered_lives };
}

export enum PartnerStatsErrors {
	NotAuthorizedToFetchPartner = 'not_authorized_to_fetch_partner',
	UnableToFetchStats = 'unable_to_fetch_partner_stats',
}

export function usePartnerStats(partnerId: string, searchValue?: string): ApiResponse<Stats> {
	const apiRequest = useApi();
	const dispatch = useDispatch();
	const { user } = useUser();
	const isAdmin = user?.accessPolicy?.isAdmin;
	const allowedPartners = user?.accessPolicy?.allowedPartners;
	const isAllowedPartner = allowedPartners?.includes(partnerId);
	const isAbleToFetchPartner = isAdmin || isAllowedPartner;

	let baseEndpoint = `b2b/partners/${partnerId}/stats`;
	if (searchValue) {
		const trimmedSearchValue = searchValue.trim();
		baseEndpoint += `?filter[partner_user_id][like]=${trimmedSearchValue}`;
	}

	const { data, error } = useSWR(
		baseEndpoint,
		async endpoint => {
			try {
				if (!isAbleToFetchPartner) {
					throw new Error('Not able to fetch stats');
				}

				const response = await apiRequest({ endpoint });
				if (!response.data) {
					throw new Error('Not able to fetch stats');
				}
				return response.data;
			} catch (responseError) {
				dispatch(
					setBannerMessage({
						message: `Failed to retrieve stats for: ${partnerId}`,
						isError: true,
						flash: true,
					}),
				);
				throw responseError;
			}
		},
		{ errorRetryCount: 0 },
	);

	const stats = data && parseStats(data);

	return { data: stats, error, loading: !data && !error };
}

export function updateStats(partnerId: string): Promise<undefined> {
	return mutate(`b2b/partners/${partnerId}/stats`, undefined, true);
}
