import { useFeatureFlags } from './api/useFeatureFlags';

export const ELIGIBILITY_FILE_FORMAT_FLAG = 'b2b-eligibility-file-format-configuration';

export const useShouldShowEFFileFormatTemplate = (): boolean => {
	const {
		data: flagValues,
		error: flagError,
		loading: flagLoading,
	} = useFeatureFlags(ELIGIBILITY_FILE_FORMAT_FLAG);
	const flagsLoaded = !flagLoading && Boolean(!flagError) && Boolean(flagValues);
	const shouldShowEFFileFormatTemplate = flagsLoaded && flagValues?.[ELIGIBILITY_FILE_FORMAT_FLAG] === true;
	return shouldShowEFFileFormatTemplate;
};
