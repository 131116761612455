import { ReactElement } from 'react';
import { useIntl } from 'react-intl';

import { Modal, Button } from '@calm-web/design-system';

import redirect from '@/utils/redirect';

import messages from './messages';
import { ModalCancelButton, Paragraph, List } from './styles';

interface Props {
	partnerId: string;
	onClose: () => void;
	isOpen: boolean;
}

const PathwaysSegmentChangeModal = ({ partnerId, onClose, isOpen }: Props): ReactElement => {
	const { formatMessage } = useIntl();

	const viewPathways = (partnerId: string): void => {
		onClose();
		redirect(`/${partnerId}/pathways`);
	};
	return (
		<Modal
			isOpen={isOpen}
			canClose
			closeModal={onClose}
			title={formatMessage(messages.title)}
			footer={
				<>
					<ModalCancelButton onPress={onClose}>
						{formatMessage(messages.cancelPathwaysButton)}
					</ModalCancelButton>
					<Button
						data-testid="modal-submit-pathway"
						backgroundColor="blue3"
						onPress={() => viewPathways(partnerId)}
						type="button"
					>
						{formatMessage(messages.viewPathwaysButton)}
					</Button>
				</>
			}
		>
			<Paragraph>{formatMessage(messages.subtitle)}</Paragraph>
			<br></br>
			<Paragraph>{formatMessage(messages.subtitle2)}</Paragraph>

			<List>{formatMessage(messages.bulletedList1)}</List>
			<List>{formatMessage(messages.bulletedList2)}</List>
		</Modal>
	);
};

export default PathwaysSegmentChangeModal;
