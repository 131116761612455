import styled from 'styled-components';
import { palette } from 'styled-tools';

import { Card, FontSizes, fontSize, fontWeight, FontWeights, units } from '@calm-web/design-system';

import CellTitle from '@/components/ui/CellTitle';

export const Description = styled.div`
	${fontSize(FontSizes.sm)};
`;

export const Title = styled(CellTitle)`
	margin-bottom: 0;
`;

export const EmailTypeTitle = styled.div`
	${fontSize(FontSizes.sm)};
`;

export const DetailsList = styled.ul`
	${fontSize(FontSizes.sm)};
	color: ${palette('gray5')};
	margin-bottom: ${units(1)};
`;

export const CheckboxDetails = styled.div`
	${fontSize(FontSizes.sm)};
	margin-left: ${units(3)};
	margin-bottom: ${units(1)};
	color: ${palette('gray5')};
	display: flex;
	flex-direction: column;
`;

export const EmailTypeDetails = styled.div`
	${fontSize(FontSizes.sm)};
	margin-bottom: ${units(1)};
	color: ${palette('gray5')};
`;

export const ToggleContentButton = styled.button`
	margin-top: ${units(2)};
	color: ${palette('blue3')};
	background-color: transparent;
	border: none;
	${fontWeight(FontWeights.Medium)};
	line-height: 23px;

	&:hover {
		text-decoration: underline;
		cursor: pointer;
	}
`;

export const EmailSection = styled(Card)`
	${fontWeight(FontWeights.Medium)};
	line-height: 18px;

	${DetailsList} {
		padding-left: ${units(6)};
	}

	${Description} {
		margin-bottom: ${units(1)};
	}
`;
