import { ReactElement, useContext } from 'react';
import { useIntl } from 'react-intl';

import { OnChange } from '@calm-web/use-form';

import { PathwaysContext } from '@/components/providers/PathwaysContextProvider';
import { UNCATEGORIZED_VALUE_NAME, SegmentSelectState } from '@/utils/segments';

import type { SelectionObject } from '../';
import {
	SegmentNameStyled,
	SegmentSelectionList,
	SegmentSelectionStyled,
	SelectButtonRow,
	SelectionButton,
	SegmentCheckbox,
	SegmentSelectionListScrollIndicator,
	SegmentSelectionListContainer,
} from '../../../../Reporting/SegmentationFilter/styles';
import messages from '../messages';

function SingleSegmentSelection({
	name,
	values,
	segment,
	selected,
	selection,
	onChange,
}: {
	name: string;
	values: string[];
	segment: keyof SegmentSelectState;
	selected: Set<string> | undefined;
	selection: SelectionObject;
	onChange: (a: SelectionObject) => void;
}): ReactElement {
	const { formatMessage } = useIntl();
	const { isEdit, isReadOnly, setSegment1Cohorts, setSegment2Cohorts, setSegment3Cohorts } =
		useContext(PathwaysContext);

	const handleChange: OnChange = e => {
		const { value } = e.target;

		const selectionDup = selection[segment] ?? new Set(values);

		if (selectionDup.has(value)) {
			selectionDup.delete(value);
		} else {
			selectionDup.add(value);
		}

		// Change back from set to string array for db
		const newArray = Array.from(selectionDup);

		switch (segment) {
			case 'segment1':
				setSegment1Cohorts(newArray);
				break;
			case 'segment2':
				setSegment2Cohorts(newArray);
				break;
			case 'segment3':
				setSegment3Cohorts(newArray);
				break;
			default:
				break;
		}

		onChange({ ...selection, [segment]: selectionDup });
	};

	const handleSelectAll = (): void => {
		onChange({ ...selection, [segment]: undefined });
		if (segment === 'segment1') setSegment1Cohorts(null);
		else if (segment === 'segment2') setSegment2Cohorts(null);
		else if (segment === 'segment3') setSegment3Cohorts(null);
	};

	const handleSelectNone = (): void => {
		onChange({ ...selection, [segment]: new Set() });
		if (segment === 'segment1') setSegment1Cohorts(null);
		else if (segment === 'segment2') setSegment2Cohorts(null);
		else if (segment === 'segment3') setSegment3Cohorts(null);
	};

	const isChecked = (value: string): boolean => {
		if (typeof selected === 'undefined') {
			return true;
		}
		return selected.has(value);
	};

	const getDisplayName = (value: string): string => {
		return value === '' ? UNCATEGORIZED_VALUE_NAME : value;
	};

	return (
		<SegmentSelectionStyled>
			<SegmentNameStyled>{name}</SegmentNameStyled>
			<SegmentSelectionListContainer>
				<SegmentSelectionList>
					{values.map(value => (
						<SegmentCheckbox
							key={`${segment}-${getDisplayName(value)}}`}
							value={value}
							name={segment}
							onChange={handleChange}
							checked={isChecked(value)}
							data-testid={`segments-selection-list-value-${getDisplayName(value)}`}
							isDisabled={isEdit && isReadOnly}
						>
							{getDisplayName(value)}
						</SegmentCheckbox>
					))}
				</SegmentSelectionList>
				<SegmentSelectionListScrollIndicator />
			</SegmentSelectionListContainer>
			{!isReadOnly && (
				<SelectButtonRow>
					<SelectionButton onClick={handleSelectAll}>{formatMessage(messages.selectAll)}</SelectionButton>
					<SelectionButton onClick={handleSelectNone}>{formatMessage(messages.selectNone)}</SelectionButton>
				</SelectButtonRow>
			)}
		</SegmentSelectionStyled>
	);
}

export default SingleSegmentSelection;
