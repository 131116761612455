import { defineMessages } from 'react-intl';

export default defineMessages({
	announceCta: {
		id: 'launchAnnouncementModal.announce.cta',
		defaultMessage: 'Get Started',
		description: 'Button copy that opens the email announcement modal',
	},
	modalBody: {
		id: 'launchAnnouncementModal.announce.body',
		defaultMessage:
			"It's easy to send a launch announcement! You can copy your unique sign-up link, distribute a QR code to direct your eligible users to your subscribe link, or get started with our copy-and-paste email template.",
		description: 'subtitle for the Launch Announcement Modal Body',
	},
});
