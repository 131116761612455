/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

export default defineMessages({
	subtitle: {
		id: 'getStarted.sso.subtitle',
		defaultMessage: 'Use this onboarding checklist to get your team set up.',
		description: 'subtitle for the Get Started section',
	},
	announceTitle: {
		id: 'getStarted.announce.title',
		defaultMessage: 'Announce the Calm benefit to your team.',
		description: 'title for the Get Started Announce section',
	},
	announceLink: {
		id: 'getStarted.announce.link',
		defaultMessage: 'The link your team will use to sign up: {partnerlink}',
		description: 'link text for the Get Started Announce section',
	},
	announceButton: {
		id: 'getStarted.announce.button',
		defaultMessage: 'Get email template',
		description: 'button text for the Get Started Announce section',
	},
	announceCalm: {
		id: 'getStarted.announce.calm',
		defaultMessage: 'Announce the Calm Benefit',
		description: 'title text for the Get Started Announce modal',
	},
	announceCalmEmail: {
		id: 'getStarted.announce.calmEmail',
		defaultMessage: `We are excited to introduce the Calm app as our newest employee benefit!
    Simply follow the instructions below to gain full access to Calm—the #1 app for mental fitness.
    Whether you have 30 seconds or 30 minutes, Calm’s diverse content library offers resources to suit your schedule and needs. Explore guided meditations and specialized music playlists to help with stress and focus, mindful movement video and audio, relaxing Sleep Stories, tailored content for children, wisdom-filled masterclasses led by experts, and much more.
    To get started:
    • Visit this link [INSERT UNIQUE CALM URL LINK]
    • Sign up with your personal email address (or log in to an existing account)
    • Validate your work email address or Employee ID
    Once complete, you can download the Calm app and log into your new account.
    Your Calm subscription gives you unlimited access to the full library of content at calm.com
    and in the Calm app. Check out this 90 second demo to learn more.
    Calm only shares aggregated user data. For more information about our privacy, see our privacy policy at calm.com/privacy.`,
		description: 'email text for the Get Started Announce modal',
	},
	learnTitle: {
		id: 'getStarted.learn.title',
		defaultMessage: 'Learn how to communicate and engage with your team about Calm.',
		description: 'title for the Get Started Learn section',
	},
	learnSubtitle: {
		id: 'getStarted.learn.subtitle',
		defaultMessage:
			'Visit our resources page to view engagement guides and download helpful communication resources.',
		description: 'title for the Get Started Learn section',
	},
	learnButton: {
		id: 'getStarted.learn.button',
		defaultMessage: 'Learn more',
		description: 'button text for the Get Started Learn section',
	},
});
