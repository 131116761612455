import { FC, ReactNode } from 'react';

import { useHasScrolled } from '@/hooks/layout/useHasScrolled';

import { NavTopBar } from '../index';
import { MainContent, NavWrapper } from '../styles';

interface Props {
	children: ReactNode;
}

const SimpleNav: FC<Props> = ({ children }) => {
	const hasScrolled = useHasScrolled();
	return (
		<NavWrapper>
			<NavTopBar hasScrolled={hasScrolled} hideBreadcrumbs />
			<MainContent fullWidth navTopOffsetUnits={8}>
				{children}
			</MainContent>
		</NavWrapper>
	);
};

export default SimpleNav;
