import { useState } from 'react';
import { useDispatch } from 'react-redux';
import useSWR, { useSWRConfig } from 'swr';

import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import { setBannerMessage } from '@/store/actions';
import { HealthAssignmentRule, HealthSponsorship } from '@/types/health';
import { JSONValue } from '@/types/json';
import { isCalmError } from '@/utils/apiRequest/errors';
import { calmLogger } from '@/utils/calmLogger';

import { ApiResponse } from './types';
import { useApi } from './useApi';

export type HealthSponsorshipPayload = Omit<HealthSponsorship, 'id' | 'partner_id' | 'assignment_rules'> & {
	assignment_rules?: Omit<HealthAssignmentRule, 'id' | 'sponsorship_id'>[];
};

export function useHealthSponsorship(
	partnerId: string,
	sponsorshipId: string,
): ApiResponse<HealthSponsorship> {
	const apiRequest = useApi();
	const dispatch = useDispatch();
	const endpoint = `b2b/partners/${partnerId}/health-sponsorships/${sponsorshipId}`;
	const { data, isLoading, error } = useSWR<HealthSponsorship>(endpoint, async endpoint => {
		try {
			const response = await apiRequest({ endpoint });
			return response.data.health_sponsorship as HealthSponsorship;
		} catch (err) {
			calmLogger.error('Error when fetching health sponsorship', {}, err);
			dispatch(
				setBannerMessage({
					message: `Error: ${
						(isCalmError(err) && err?.data?.error?.message) ||
						'There was a problem fetching the health sponsorship'
					}`,
					flash: true,
					isError: true,
				}),
			);
			throw err;
		}
	});

	return { data, loading: isLoading, error };
}

export function useHealthSponsorships(partnerId: string): ApiResponse<HealthSponsorship[]> {
	const apiRequest = useApi();
	const dispatch = useDispatch();
	const endpoint = `b2b/partners/${partnerId}/health-sponsorships`;
	const { data, isLoading, error } = useSWR<HealthSponsorship[]>(endpoint, async endpoint => {
		try {
			const response = await apiRequest({ endpoint });
			return response.data.health_sponsorships as HealthSponsorship[];
		} catch (err) {
			calmLogger.error('Error when fetching health sponsorships', {}, err);
			dispatch(
				setBannerMessage({
					message: `Error: ${
						(isCalmError(err) && err?.data?.error?.message) ||
						'There was a problem fetching the health sponsorships'
					}`,
					flash: true,
					isError: true,
				}),
			);
			throw err;
		}
	});

	return { data, loading: isLoading, error };
}

export const useSubmitHealthSponsorship = (): [
	submitHealthSponsorship: (
		healthSponsorshipData: Partial<HealthSponsorshipPayload>,
		partnerId: string,
		sponsorshipId?: string,
	) => Promise<string | undefined>,
	state: ApiResponse<HealthSponsorship>,
] => {
	const apiRequest = useApi();
	const { logEvent } = useAnalytics();
	const dispatch = useDispatch();
	const { mutate } = useSWRConfig();
	const [isSubmitting, setIsSubmitting] = useState(false);

	async function submitHealthSponsorship(
		healthSponsorshipData: Partial<HealthSponsorshipPayload>,
		partnerId: string,
		sponsorshipId?: string,
	): Promise<string | undefined> {
		const isEdit = !!sponsorshipId;
		try {
			setIsSubmitting(true);
			const listEndpoint = `b2b/partners/${partnerId}/health-sponsorships`;
			const endpoint = isEdit
				? `b2b/partners/${partnerId}/health-sponsorships/${sponsorshipId}`
				: listEndpoint;
			const method = isEdit ? 'PATCH' : 'POST';
			const res = await apiRequest({
				endpoint,
				method,
				body: healthSponsorshipData,
			});
			const responseData = res?.data?.health_sponsorship ?? res?.data;
			if (isEdit && responseData) {
				const updatedHealthSponsorshipFields: { [field: string]: JSONValue } = {};
				Object.keys(responseData).forEach((field: string) => {
					if (
						healthSponsorshipData &&
						healthSponsorshipData[field as keyof HealthSponsorshipPayload] !== responseData[field]
					) {
						updatedHealthSponsorshipFields[field] = responseData[field];
					}
				});
				if (Object.keys(updatedHealthSponsorshipFields).length) {
					logEvent('Health Sponsorship : Updated', { ...updatedHealthSponsorshipFields });
				}
			}
			await mutate(endpoint);
			if (isEdit) {
				await mutate(listEndpoint);
			} else {
				dispatch(
					setBannerMessage({
						message: `Successfully created partner health sponsorship`,
						flash: true,
						isError: false,
					}),
				);
			}
			return responseData.id;
		} catch (error) {
			dispatch(
				setBannerMessage({
					message: `An error has occurred ${isEdit ? 'updating' : 'creating'} partner health sponsorship!`,
					flash: true,
					isError: true,
				}),
			);
		} finally {
			setIsSubmitting(false);
		}
	}

	return [submitHealthSponsorship, { data: undefined, loading: isSubmitting, error: undefined }];
};

export const useDeleteHealthSponsorship = (): ((
	partnerId: string,
	sponsorshipId: string,
) => Promise<boolean>) => {
	const apiRequest = useApi();
	const { logEvent } = useAnalytics();
	const dispatch = useDispatch();
	const { mutate } = useSWRConfig();

	async function deleteHealthSponsorship(partnerId: string, sponsorshipId: string): Promise<boolean> {
		try {
			const endpoint = `b2b/partners/${partnerId}/health-sponsorships/${sponsorshipId}`;
			await apiRequest({ endpoint, method: 'DELETE' });
			logEvent('Health Sponsorship : Deleted', { partnerId, sponsorshipId });
			dispatch(
				setBannerMessage({
					message: `Successfully deleted partner health sponsorship`,
					flash: true,
					isError: false,
				}),
			);
			await mutate(endpoint);
			const listEndpoint = `b2b/partners/${partnerId}/health-sponsorships`;
			await mutate(listEndpoint);
			return true;
		} catch (error) {
			logEvent('Health Sponsorship : Delete Failed', { partnerId, sponsorshipId });
			dispatch(
				setBannerMessage({
					message: `An error has occurred deleting partner health sponsorship!`,
					flash: true,
					isError: true,
				}),
			);
			return false;
		}
	}

	return deleteHealthSponsorship;
};
