import styled from 'styled-components';

import { units } from '@calm-web/design-system';

import CellTitle from '@/components/ui/CellTitle';

export const CellTitleWithPadding = styled(CellTitle)`
	padding: ${units(3, 3, 0)};
	margin-bottom: 0;
`;
