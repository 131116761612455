import styled from 'styled-components';

import { fontWeight, FontWeights, units } from '@calm-web/design-system';

export const BannerWrapper = styled.div`
	margin-bottom: ${units(1)};
	${fontWeight(FontWeights.Demi)};

	a {
		margin-left: ${units(0.5)};
		text-decoration: underline;
	}
`;
