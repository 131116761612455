import styled, { css } from 'styled-components';
import { ifNotProp, ifProp, palette, prop } from 'styled-tools';

import { FontSizes, fontSize, fontWeight, FontWeights, units } from '@calm-web/design-system';

export const Table = styled.table`
	width: 100%;
	border-collapse: collapse;
	margin-top: ${units(2)};
	td {
		padding: ${units(1)} ${units(2)};
	}
`;

export const TableRow = styled.tr`
	border-top: solid 2px ${palette('gray1')};
`;

export const HeaderText = styled.td<{ $alignRight?: boolean }>`
	${fontSize(FontSizes.sm)};
	${fontWeight(FontWeights.Medium)}
	line-height: 20px;
	letter-spacing: 0px;
	text-align: ${props => (props.$alignRight ? 'right' : 'left')};
	opacity: 60%;
`;

export const ContentText = styled.span`
	${fontSize(FontSizes.sm)};
	${fontWeight(FontWeights.Medium)}
	line-height: 20px;
	letter-spacing: 0px;
	opacity: 80%;
`;

export const PurpleRectangle = styled.div<{ $width: number; $opacity: number }>`
	width: ${props => props.$width * 100}%;
	height: 20px;
	background-color: ${palette('blue3')};
	margin-right: ${units(1)};
	opacity: ${props => props.$opacity}%;
`;

export const CellImage = styled.img<{
	width: number;
	noMargin?: boolean;
	verticalAlign?: string;
}>`
	object-fit: cover;
	width: ${props => units(props.width)};
	height: ${props => units(props.width)};
	border-radius: 4px;
	${ifNotProp(
		'noMargin',
		css`
			margin-right: ${units(1)};
		`,
	)};
	${ifProp(
		'verticalAlign',
		css`
			vertical-align: ${prop('verticalAlign')};
		`,
	)};
`;

export const RightAlign = styled.td`
	text-align: right;
`;

export const FlexCenterAlign = styled.div`
	display: flex;
	align-items: center;
`;

export const RowDivider = styled.div`
	width: 100%;
	background: ${palette('gray1')};
	height: 1px;
`;
