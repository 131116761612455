import omit from 'lodash/omit';
import { DateTime } from 'luxon';
import { ChangeEvent, ReactElement } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

import { Card } from '@calm-web/design-system';
import { OnChange, stringFromModelValue } from '@calm-web/use-form';

import CellTitle, { Subtitle } from '@/components/ui/CellTitle';
import type { EditPartnerFormProps } from '@/hooks/forms/usePartnerForm';
import { IntegrationType, PartnerCategoryType } from '@/types/store/reducers';
import { Duration, ProductSKUType } from '@/utils/SkuEnums';

import messages from './messages';

const DEFAULT_SALES_TRIAL_CONFIGS = {
	COVERED_LIVES: '10',
	TOTAL_DAYS: 30,
};
const partnerCategoryOptions: {
	value: PartnerCategoryType;
	label: MessageDescriptor;
}[] = [
	{
		value: PartnerCategoryType.CALM_FOR_BUSINESS,
		label: messages.partnerCategoryCalmForBusinessSelection,
	},
	{
		value: PartnerCategoryType.SALES_TRIAL,
		label: messages.partnerCategorySalesTrial,
	},
	{
		value: PartnerCategoryType.TELCO_PARTNERSHIP,
		label: messages.partnerCategoryTelcoPartnershipSelection,
	},
	{
		value: PartnerCategoryType.BRAND_PARTNERSHIP,
		label: messages.partnerCategoryBrandPartnershipSelection,
	},
	{
		value: PartnerCategoryType.HEALTH_CARE_PROVIDER,
		label: messages.partnerCategoryHealthCareProviderSelection,
	},
	{
		value: PartnerCategoryType.D2C_LEAD_GEN_PARTNERSHIP,
		label: messages.partnerCategoryD2CLeadGenPartnershipSelection,
	},
	{
		value: PartnerCategoryType.CALM_HEALTH,
		label: messages.partnerCategoryCalmHealthSelection,
	},
];

function PartnerCategory({ formProps }: { formProps: EditPartnerFormProps }): ReactElement {
	const { formatMessage } = useIntl();
	const bindWithErrorProps = formProps.bindWithErrorProps('category', 'select');
	const updateOtherProps: OnChange<HTMLSelectElement> = e => {
		const {
			target: { value },
		} = e;
		bindWithErrorProps.onChange(e);
		if (value === PartnerCategoryType.D2C_LEAD_GEN_PARTNERSHIP) {
			formProps.setProperty('productSKUType', ProductSKUType.C4B_DIRECT);
			formProps.setProperty('subscriptionAccessModel', 'fixed');
			formProps.setProperty('duration', Duration.OneYear);
			formProps.setProperty('integrationType', IntegrationType.ACCESS_CODES);
		} else if (value === PartnerCategoryType.SALES_TRIAL) {
			const contractStartDate = stringFromModelValue(formProps.model.contractStartDate);
			const trialEndDate = contractStartDate
				? DateTime.fromISO(contractStartDate)
						.plus({
							days: DEFAULT_SALES_TRIAL_CONFIGS.TOTAL_DAYS,
						})
						.toISODate()
				: '';
			formProps.setProperty('numCoveredLives', DEFAULT_SALES_TRIAL_CONFIGS.COVERED_LIVES);
			formProps.setProperty('contractEndDate', trialEndDate);
		}
	};

	return (
		<Card>
			<div>
				<CellTitle showAdminFlag id="partner-category-label">
					{formatMessage(messages.partnerCategoryTitle)}
				</CellTitle>
				<Subtitle>{formatMessage(messages.partnerCategorySubtitle)}</Subtitle>
				<div>
					<select
						{...omit(bindWithErrorProps, ['isValid', 'showValidation'])}
						data-testid="select-partner-category"
						id="category"
						placeholder="Select a partner category"
						aria-labelledby="partner-category-label"
						onChange={(e: ChangeEvent<HTMLSelectElement>) => updateOtherProps(e)}
					>
						{partnerCategoryOptions.map(({ value, label }) => {
							return (
								<option key={value} value={value}>
									{formatMessage(label)}
								</option>
							);
						})}
					</select>
				</div>
			</div>
		</Card>
	);
}

export default PartnerCategory;
