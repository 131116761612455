import pick from 'lodash/pick';
import { useEffect, useMemo, useState } from 'react';

import { Model } from '@calm-web/use-form';

import { useOptionalPartner } from '@/hooks/api/usePartner';
import { EditPartnerFormProps, FieldNames, getInitialModelForPartner } from '@/hooks/forms/usePartnerForm';

const keysToCopy = [
	'eligibilityType',
	'userIdDescriptor',
	'integrationType',
	'contractStartDate',
	'contractEndDate',
	'dependentsEnabled',
	'webRenewUrl',
	'iosRenewUrl',
	'androidRenewUrl',
	'androidRenewUrl',
	'isHipaaCompliant',
	'isLdu',
	'isSalesTrial',
	'duration',
	'subscriptionAccessModel',
	'shouldRenew',
	'renewalType',
	'category',
	'percentOff',
	'trialDuration',
	'duration_type',
	'duration_in_months',
	'promotion_active',
	'productSKUType',
	'emailConfig',
] as const;

export const useSetPartnerFormFromParent = (formProps: EditPartnerFormProps, search: string): void => {
	const parentIdQuery = new URLSearchParams(search).get('parentId') ?? undefined;
	const parentPartnerResponse = useOptionalPartner(parentIdQuery);
	const parentPartner = useMemo(() => parentPartnerResponse?.data, [parentPartnerResponse]);
	const [hasSetUpForParentId, setHasSetUpForParentId] = useState<string | undefined>(undefined);
	useEffect(() => {
		if (!parentPartner || hasSetUpForParentId === parentPartner.id) {
			return;
		}
		setHasSetUpForParentId(parentPartner.id);
		const parentInitialModel = getInitialModelForPartner(parentPartner);
		formProps.setModel({
			...(formProps.model as Model<FieldNames>),
			...pick(parentInitialModel, keysToCopy),
			parentId: parentPartner.id,
		});
	}, [formProps, parentPartner, hasSetUpForParentId]);
};
