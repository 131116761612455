import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { SecondaryButton, FontWeights, Text } from '@calm-web/design-system';

import { EnvConfig } from '@/env_config';
import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import { PartnerMilestoneType, useRecordMilestone } from '@/hooks/api/useMilestones';
import { useDefinedPartner } from '@/hooks/api/usePartner';
import { ActionPlanItem } from '@/types/actionPlan';

import messages from './messages';
import { Wrapper, ButtonGroup, CtaButton } from './styles';

interface Props {
	item: ActionPlanItem;
}

const clickToFinishActions = [
	PartnerMilestoneType.REPORTING_VIEWED,
	PartnerMilestoneType.MONTHLY_ENGAGEMENT,
	PartnerMilestoneType.ZOOM_APP_INSTALLED,
	PartnerMilestoneType.SIGNUPS_ABOVE_BENCHMARK,
	PartnerMilestoneType.SIGNUPS_BELOW_BENCHMARK_EMAIL,
	PartnerMilestoneType.SIGNUPS_BELOW_BENCHMARK_NON_EMAIL,
	PartnerMilestoneType.ENGAGEMENT_ABOVE_BENCHMARK,
	PartnerMilestoneType.ENGAGEMENT_BELOW_BENCHMARK,
	PartnerMilestoneType.SUBSCRIPTION_LINK_COPIED,
];

const { partnerPortalUrl } = EnvConfig;

const ActionItem: FC<Props> = ({ item }) => {
	const { formatMessage } = useIntl();
	const { logEvent } = useAnalytics();
	const canDismiss = item.category !== 'GET_SET_UP';
	const hasCta = item?.cta_link && item?.cta_text;
	const partner = useDefinedPartner();
	const [recordMilestone, { loading: isRecordLoading }] = useRecordMilestone();
	const [dismissMilestone, { loading: isDismissLoading }] = useRecordMilestone();
	const history = useHistory();
	const handleClick = async (): Promise<void> => {
		logEvent('Home : Action CTA clicked', { item: item.event_name });
		if (clickToFinishActions.includes(item.event_name)) {
			await recordMilestone({ eventName: item.event_name, partnerId: partner.id });
		}
		const url = item.cta_link ?? '';
		const isExternal = !url.match(partnerPortalUrl);
		if (isExternal) {
			window.open(url, '_blank')?.focus();
			return;
		}

		const route = url.replace(partnerPortalUrl, '');
		history.push(route);
	};

	const handleDismiss = async (): Promise<void> => {
		logEvent('Home : Action Dismissed', { item: item.event_name });
		await dismissMilestone({ eventName: item.event_name, partnerId: partner.id, isDismiss: true });
	};

	const descriptionId = `description-${item.event_name}`;
	return (
		<Wrapper>
			<Text el="span" weight={FontWeights.Medium} id={descriptionId}>
				{item.description}
			</Text>
			<ButtonGroup>
				{canDismiss && (
					<SecondaryButton
						borderColor="blue3"
						onPress={handleDismiss}
						isLoading={isDismissLoading}
						isDisabled={isRecordLoading}
					>
						{formatMessage(messages.dismissCta)}
					</SecondaryButton>
				)}
				{hasCta && (
					<CtaButton
						role="link"
						onPress={handleClick}
						isLoading={isRecordLoading}
						isDisabled={isDismissLoading}
						aria-describedby={descriptionId}
					>
						{item.cta_text}
					</CtaButton>
				)}
			</ButtonGroup>
		</Wrapper>
	);
};

export default ActionItem;
