import styled from 'styled-components';
import { palette } from 'styled-tools';

import { fontSize, FontSizes, fontWeight, FontWeights, units } from '@calm-web/design-system';

export const AccountId = styled.a`
	font-family: monospace;
	color: ${palette('blue3')};
	text-decoration: underline;
`;

export const ButtonWrapper = styled.div`
	display: flex;
	gap: ${units(1)};
	margin-top: ${units(1)};
`;

export const NoAccountText = styled.div`
	${fontSize(FontSizes.sm)};
	${fontWeight(FontWeights.Medium)};
`;

export const Wrapper = styled.div`
	display: block;

	${NoAccountText} {
		margin-bottom: ${units(1)};
	}
`;
