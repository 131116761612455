import { defineMessages } from 'react-intl';

export default defineMessages({
	moreToComeHeader: {
		id: 'pathways.selectProgram.moreToCome.header',
		defaultMessage: 'More Pathways are in the works',
		description: 'More to come header',
	},
	moreToComeBody: {
		id: 'pathways.selectProgram.moreToCome.body',
		defaultMessage: 'Got an idea for a Pathway? <suggestionmodal>Let us know!</suggestionmodal>',
		description: 'More to come body text',
	},
	suggestIdeaModalTitle: {
		id: 'pathways.selectProgram.suggestIdeaModal.title',
		defaultMessage: 'Suggest a Pathway',
		description: 'Suggest an idea modal title',
	},
	suggestIdeaModalDescription: {
		id: 'pathways.selectProgram.suggestIdeaModal.description',
		defaultMessage:
			'Got a great idea for a Pathway or looking to solve something we currently don’t cover? Let us know below.',
		description: 'Suggest an idea modal description',
	},
	suggestIdeaModalPlaceholder: {
		id: 'pathways.selectProgram.suggestIdeaModal.placeholder',
		defaultMessage: "What's on your mind?",
		description: 'Suggest an idea modal textArea placeholder',
	},
	suggestIdeaModalThanksText1: {
		id: 'pathways.selectProgram.suggestIdeaModal.thankYouMessage1',
		defaultMessage: 'Thanks!',
		description: 'Suggest an idea modal thank you message',
	},
	suggestIdeaModalThanksText2: {
		id: 'pathways.selectProgram.suggestIdeaModal.thankYouMessage2',
		defaultMessage: 'We appreciate your input.',
		description: 'Suggest an idea modal thank you message',
	},
	suggestIdeaModalSubmit: {
		id: 'pathways.selectProgram.suggestIdeaModal.submitButton',
		defaultMessage: 'Submit',
		description: 'Suggest an idea modal submit button text',
	},
	suggestIdeaModalClose: {
		id: 'pathways.selectProgram.suggestIdeaModal.closeButton',
		defaultMessage: 'Close',
		description: 'Suggest an idea modal close button text',
	},
	placeholderTitle: {
		id: 'pathways.selectProgram.placeholder.title',
		defaultMessage: 'Choose a pathway to learn more',
		description: 'Placeholder title text',
	},
	placeholderContent: {
		id: 'pathways.selectProgram.placeholder.text',
		defaultMessage: 'Pathways are designed to tackle specific challenges your members are facing.',
		description: 'Placeholder content text',
	},
	sampleContentHeader: {
		id: 'pathways.selectProgram.sampleContent.header',
		defaultMessage: 'Sample Content',
		description: 'Header for sample content section',
	},
	sampleResourcesHeader: {
		id: 'pathways.selectProgram.sampleResources.header',
		defaultMessage: 'Sample Resources',
		description: 'Header for sample resources section',
	},
});
