import { ReactElement, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Modal } from '@calm-web/design-system';

import { useTerminateSubscription } from '@/hooks/api/useSubscription';

import { TerminatePlanModalContent } from './TerminatePlanModalContent';
import { TerminatePlanModalFooter } from './TerminatePlanModalFooter';
import messages from './messages';

interface Props {
	onModalDismiss: () => void;
}
export default function TerminatePlanModal({ onModalDismiss }: Props): ReactElement {
	const { formatMessage } = useIntl();
	const { partnerId } = useParams();
	const [terminateSubscription, { loading: isTerminating, error: terminateError, data: terminateResult }] =
		useTerminateSubscription(partnerId);

	const [isTerminateDisabled, setTerminateDisabled] = useState(true);

	const title = terminateResult
		? formatMessage(messages.hasTerminatedTitle)
		: formatMessage(messages.areYouSure);

	return (
		<Modal
			data-testid="terminate-plan-modal"
			isOpen
			closeModal={onModalDismiss}
			title={title}
			footer={
				<TerminatePlanModalFooter
					hasTerminated={!!terminateResult}
					isTerminateDisabled={isTerminateDisabled}
					isTerminating={isTerminating}
					onModalDismiss={onModalDismiss}
					onTerminatePlan={() => {
						// No need to do anything with the catch here,
						// the hook puts the error in the hook return value
						terminateSubscription().catch(() => {});
					}}
				/>
			}
		>
			<TerminatePlanModalContent
				isTerminating={isTerminating}
				partnerId={partnerId}
				terminateError={terminateError}
				terminateResult={terminateResult}
				setTerminateDisabled={setTerminateDisabled}
			/>
		</Modal>
	);
}
