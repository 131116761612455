import { useContext, useMemo } from 'react';

import { Tooltip } from '@calm-web/design-system';

import {
	ReportingButtonContainerContext,
	ReportingContext,
	timeframeValues,
} from '@/components/providers/ReportingContextProvider';
import { formatShortDate } from '@/utils/helpers';

import DownloadButton from '../DownloadButton';
import SegmentationFilter from '../SegmentationFilter';
import TimeFrameFilter from '../TimeFrameFilter';
import CustomDateRange from '../TimeFrameFilter/CustomDateRange';
import { Container, DateRange, InfoIcon } from './styles';
import { ReportingButtonContainerProps } from './types';

const ReportingButtonContainer: React.FC<ReportingButtonContainerProps> = ({
	isDownloadLoading,
	isSelfServe,
	exportWholePageToPDF,
	partnerId,
	updateSegmentFilter,
	segmentsSelected,
	showModal,
	setShowModal,
	showDownloadButton,
}) => {
	const { openDateRangePicker } = useContext(ReportingButtonContainerContext);
	const { signupsAndEngagementReport, selectedLongerTimeframe, isReportMocked } =
		useContext(ReportingContext);
	const { data: report } = signupsAndEngagementReport || {};

	const { reportStartDateString, reportEndDateString, reportUpdatedAtString } = useMemo(() => {
		if (!(report?.start_date && report?.end_date && report?.updated_at)) {
			return {};
		}
		const baseOptions: Intl.DateTimeFormatOptions = {
			year: 'numeric',
			month: 'numeric',
			day: 'numeric',
		} as const;
		const optionsWithTime: Intl.DateTimeFormatOptions = {
			...baseOptions,
			hour: 'numeric',
			minute: '2-digit',
			timeZoneName: 'short',
		};
		const optionsUTC: Intl.DateTimeFormatOptions = {
			...baseOptions,
			timeZone: 'UTC',
		};
		return {
			reportStartDateString: new Date(report.start_date).toLocaleDateString(undefined, optionsUTC),
			reportEndDateString: new Date(report.end_date).toLocaleDateString(undefined, optionsUTC),
			reportUpdatedAtString: new Date(report.updated_at).toLocaleString(undefined, optionsWithTime),
		};
	}, [report?.start_date, report?.end_date, report?.updated_at]);

	const tooltipContent = (
		<>
			<span>
				Displaying data from {reportStartDateString} to {reportEndDateString}
			</span>
			<br />
			<span>Last updated on {reportUpdatedAtString}</span>
		</>
	);

	return (
		<Container>
			{showDownloadButton && (
				<DownloadButton isDownloadLoading={isDownloadLoading} exportWholePageToPDF={exportWholePageToPDF} />
			)}
			<SegmentationFilter
				partnerId={partnerId}
				onSubmit={updateSegmentFilter}
				segmentsSelected={segmentsSelected}
				showModal={showModal}
				setShowModal={setShowModal}
			/>
			<TimeFrameFilter />
			{selectedLongerTimeframe?.value === timeframeValues.CUSTOM && (
				<DateRange
					onClick={() => {
						if (openDateRangePicker) openDateRangePicker(prev => !prev);
					}}
				>
					{formatShortDate(selectedLongerTimeframe.startDate)} –{' '}
					{formatShortDate(selectedLongerTimeframe.endDate)}
				</DateRange>
			)}
			<CustomDateRange />
			{!isReportMocked && (
				<Tooltip placement="top-end" content={tooltipContent}>
					<div style={{ display: 'inline-block' }}>
						<InfoIcon data-html2canvas-ignore="true" />
					</div>
				</Tooltip>
			)}
		</Container>
	);
};

export default ReportingButtonContainer;
