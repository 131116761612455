import { useUser } from '@/hooks/store';
import { Actions, partnerActions } from '@/utils/RBAC';

export type PermissionFunction = (field: string, requiredPermissions: Actions) => boolean;

export function usePermissions(): [PermissionFunction, typeof partnerActions] {
	const { user } = useUser();
	if (!user) {
		return [() => false, partnerActions];
	}
	const { accessPolicy } = user;

	function hasValidPermissions(field: string, requiredPermissions: Actions): boolean {
		if (!accessPolicy?.permissions?.[field]) {
			return false;
		}
		const userFieldPermissions = accessPolicy.permissions[field].actions;
		// eslint-disable-next-line consistent-return, no-plusplus
		for (let i = 0; i < requiredPermissions.length; i++) {
			if (!userFieldPermissions.includes(requiredPermissions[i])) {
				return false;
			}
		}
		return true;
	}

	return [hasValidPermissions, partnerActions];
}
