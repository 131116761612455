import { FC, ReactNode, SyntheticEvent, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';

import { Modal as DesignModal } from '@calm-web/design-system';

import SocialShareButtons from '@/components/ui/SocialShareButtons';
import useCanSeeReferral from '@/hooks/api/useCanSeeReferral';
import { useUser } from '@/hooks/store';
import CalmCookie from '@/utils/CalmCookie';

import messages from './messages';
import {
	Background,
	Body,
	ButtonContainer,
	ButtonGroup,
	CircleArrowIcon,
	CtaButton,
	DismissButton,
	GiftFilledIcon,
	GiftIcon,
	Header,
	LinkSection,
	ModalContent,
	MoreButton,
	MoreContainer,
	Popup,
	StyledCloseButton,
	TextSection,
	Title,
} from './styles';

export default function ReferralPopup({ onDismiss }: { onDismiss: () => void }): ReturnType<FC> {
	const history = useHistory();
	const { search, pathname } = useLocation();
	const { partnerId } = useParams();
	const { formatMessage } = useIntl();
	const { user } = useUser();

	const referralUrl = `https://info.calm.com/refer.html?referrer=${user?.sub}`;

	function onRedirect(): void {
		history.push({
			pathname: `/${partnerId}/referral`,
			search,
		});
		onDismiss();
	}

	function onPopupClick(e: SyntheticEvent): void {
		e.preventDefault();
		e.stopPropagation();
	}

	return (
		<Background onClick={onDismiss}>
			<Popup onClick={onPopupClick}>
				<TextSection>
					<Header>
						<GiftIcon />
						<StyledCloseButton onPress={onDismiss} />
					</Header>
					<Title>{formatMessage(messages.popupTitle)}</Title>
					<div>{formatMessage(messages.popupBody)}</div>
					{pathname !== `/${partnerId}/referral` && (
						<MoreContainer>
							<MoreButton onPress={onRedirect}>
								{formatMessage(messages.popupMore)}
								<CircleArrowIcon />
							</MoreButton>
						</MoreContainer>
					)}
				</TextSection>
				<LinkSection onClick={onDismiss}>
					<SocialShareButtons event={'Referral'} url={referralUrl} />
				</LinkSection>
			</Popup>
		</Background>
	);
}

export function ReferralPopupV2({ partnerId }: { partnerId: string }): ReturnType<FC> {
	const [isOpen, setIsOpen] = useState(true);
	const { formatMessage } = useIntl();
	const { data: { canSeeReferral100 } = {}, loading, error } = useCanSeeReferral();

	const giftValue = canSeeReferral100 ? '$100' : '$500';

	const closeModal = (): void => {
		setIsOpen(false);
		CalmCookie.set('has-seen-referral-popup-v2', 'true');
	};

	if (loading || error) {
		return null;
	}

	return (
		<DesignModal
			canClose={false}
			isOpen={isOpen}
			closeModal={closeModal}
			borderColor="gradientPink"
			aria-label={formatMessage(messages.popupTitle)}
		>
			<ModalContent>
				<GiftFilledIcon />
				<Title>{formatMessage(messages.popupV2Title, { giftValue })}</Title>
				<Body>
					{formatMessage(messages.popupV2Body, {
						giftValue,
						linebreak: <br />,
						b: (...chunks: ReactNode[]) => <b>{chunks}</b>,
					})}
				</Body>
				<ButtonGroup>
					<ButtonContainer>
						<Link to={`/${partnerId}/referral`} onClick={closeModal}>
							<CtaButton>{formatMessage(messages.popupV2Cta)}</CtaButton>
						</Link>
					</ButtonContainer>
					<ButtonContainer>
						<DismissButton onPress={closeModal}>{formatMessage(messages.popupV2Dismiss)}</DismissButton>
					</ButtonContainer>
				</ButtonGroup>
			</ModalContent>
		</DesignModal>
	);
}
