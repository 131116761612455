import { FC, useCallback } from 'react';

import { PaletteColor } from '@calm-web/design-system';

import { useAnalytics } from '@/hooks/analytics/useAnalytics';

import { LinkWrapper, ComingSoonBadge } from './styles';

type Props = {
	children: string;
	Icon: FC;
	download?: boolean;
	shouldHideBorder?: boolean;
	openInNewTab?: boolean;
	iconColor?: PaletteColor;
	textColor?: PaletteColor;
} & (
	| {
			href: string;
			comingSoon?: never;
	  }
	| {
			href?: never;
			comingSoon: true;
	  }
);

const ResourceLink: FC<Props> = ({
	children,
	Icon,
	href,
	comingSoon,
	download,
	openInNewTab,
	shouldHideBorder = false,
	iconColor = 'buttonBlue',
	textColor = 'text',
}) => {
	const { logEvent } = useAnalytics();

	const logLinkClick = useCallback(() => {
		logEvent('Partner Portal : Resources : Clicked', {
			resource: children,
		});
	}, [logEvent, children]);

	const props = comingSoon
		? {}
		: {
				openInNewTab,
				download,
				href,
				onClick: logLinkClick,
		  };

	return (
		<LinkWrapper
			iconColor={iconColor}
			textColor={textColor}
			Icon={Icon}
			{...props}
			shouldHideBorder={shouldHideBorder}
		>
			{children}
			{comingSoon ? <ComingSoonBadge /> : null}
		</LinkWrapper>
	);
};

export default ResourceLink;
