import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { palette } from 'styled-tools';

import { FontSizes, FontWeights, units } from '@calm-web/design-system';

import ChartPreview from 'icons/chart-preview.svg';
import Chart from 'icons/chart.svg';
import Gear from 'icons/gear-outline.svg';
import HomeFilled from 'icons/home-filled.svg';
import Pathways from 'icons/pathways.svg';
import Profile from 'icons/profile.svg';

export const Wrapper = styled.div`
	padding: ${units(1, 0, 4, 0)};
	background-color: ${palette('blue3')};
	z-index: 1;
	display: flex;
	justify-content: center;
`;
export const StyledMobileNav = styled.nav`
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	flex: 1;
`;

interface NavItemProps {
	active?: boolean;
}

export const Link = styled(NavLink)<NavItemProps>`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: ${units(1)};
	background: transparent;
	color: ${palette('gray0')};
	font-size: ${FontSizes.sm};
	font-weight: ${FontWeights.Medium};
	text-decoration: none;

	&.selected {
		color: ${palette('white')};
		font-weight: ${FontWeights.Demi};

		svg {
			path {
				fill: ${palette('white')};
			}
		}
	}
`;

const navIconCss = css`
	width: ${units(3)};
	height: ${units(3)};
	path {
		fill: ${palette('white')};
	}
`;

export const HomeIcon = styled(HomeFilled)`
	${navIconCss};
`;

export const UsersIcon = styled(Profile)`
	${navIconCss};
`;

export const AccountIcon = styled(Gear)`
	${navIconCss};
`;

export const ReportingIcon = styled(Chart)`
	${navIconCss};
`;

export const ReportinPreviewIcon = styled(ChartPreview)`
	${navIconCss};
`;

export const PathwaysIcon = styled(Pathways)`
	${navIconCss};
`;
