import { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import messages from '@/components/pages/Pathways/Create/Review/TestEmailSend/messages';
import { PathwaysContext } from '@/components/providers/PathwaysContextProvider';
import { setBannerMessage } from '@/store/actions';
import { CalmError, getCalmErrorOrError } from '@/utils/apiRequest/errors';
import type { DayAbbreviation } from '@/utils/helpers';

import { useApi } from './useApi';

interface TriggerIterableSendDatum {
	email: string;
	template: string;
	locals: Record<string, string | string[] | boolean>;
}
interface TriggerIterableSendErrorInfo {
	reason: string;
	sendDatum?: TriggerIterableSendDatum;
	invalidFields?: {
		email?: string;
		template?: string;
	};
}

interface TriggerIterableSendResults {
	errors: TriggerIterableSendErrorInfo[];
	successCount: number;
}

export function useTriggerIterableSend(): [
	(sendData: TriggerIterableSendDatum[], partnerId: string) => Promise<void>,
	{
		loading: boolean;
		error: CalmError | Error | undefined;
		apiResErrors: TriggerIterableSendErrorInfo[] | undefined;
		didSucceed: boolean | undefined;
	},
] {
	const apiRequest = useApi();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<CalmError | Error | undefined>();
	const [apiResErrors, setApiResErrors] = useState<TriggerIterableSendErrorInfo[]>();
	const [didSucceed, setDidSucceed] = useState<boolean | undefined>();
	const { formatMessage } = useIntl();

	async function triggerIterableSend(sendData: TriggerIterableSendDatum[], partnerId: string): Promise<void> {
		try {
			setDidSucceed(undefined);
			setApiResErrors(undefined);
			setError(undefined);
			setLoading(true);
			const {
				data: { errors },
			} = await apiRequest<TriggerIterableSendResults>({
				endpoint: `b2b/partners/${partnerId}/iterable/trigger-send`,
				body: {
					send_data: sendData,
				},
				method: 'POST',
			});
			if (errors.length > 0) {
				setApiResErrors(errors);
				throw new Error('An error occurred while triggering Iterable send');
			}
			setDidSucceed(true);
			dispatch(
				setBannerMessage({
					message: formatMessage(messages.triggerTestEmailSuccess),
					isError: false,
					flash: true,
				}),
			);
		} catch (err) {
			const error = getCalmErrorOrError(err);
			setError(error);
		} finally {
			setLoading(false);
		}
	}

	return [triggerIterableSend, { loading, error, apiResErrors, didSucceed }];
}

const B2B_PATHWAYS_ANNOUNCEMENT = 'b2b_pathways_announcement_campaign';

export function useTriggerPathwayAnnouncementEmail({ isTest }: { isTest?: boolean }): [
	(email: string, errorMessage: string) => Promise<void>,
	{
		loading: boolean;
		error: CalmError | Error | undefined;
		apiResErrors: TriggerIterableSendErrorInfo[] | undefined;
		didSucceed: boolean | undefined;
	},
] {
	const { selectedPathway, startDate, endDate, partnerName, partnerId } = useContext(PathwaysContext);
	const dispatch = useDispatch();
	const [triggerIterableSend, { loading, error, apiResErrors, didSucceed }] = useTriggerIterableSend();

	async function triggerPathwayAnnouncementEmailSend(email: string, errorMessage: string): Promise<void> {
		if (
			startDate &&
			endDate &&
			selectedPathway?.title &&
			selectedPathway?.subtitle &&
			selectedPathway?.item_schedule?.length &&
			partnerName &&
			partnerId
		) {
			const availableLocals = {
				pathway_title: selectedPathway.title,
				pathway_description: selectedPathway.subtitle,
				start_date: startDate.toISOString(),
				end_date: endDate.toISOString(),
				item_schedule: selectedPathway.item_schedule as DayAbbreviation[],
				partner_name: partnerName.charAt(0).toUpperCase() + partnerName.slice(1),
				partner_id: partnerId,
				...(isTest ? { is_test: true } : {}),
			};
			await triggerIterableSend(
				[
					{
						email,
						template: B2B_PATHWAYS_ANNOUNCEMENT,
						locals: availableLocals,
					},
				],
				partnerId,
			);
		} else {
			// TODO: Log info to DD to help ID missing fields
			dispatch(
				setBannerMessage({
					message: errorMessage,
					isError: true,
					flash: true,
				}),
			);
		}
	}

	return [triggerPathwayAnnouncementEmailSend, { loading, error, apiResErrors, didSucceed }];
}
