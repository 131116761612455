import { defineMessages } from 'react-intl';

export default defineMessages({
	clientIdLabel: {
		id: 'accountManagerTools.clientIdLabel',
		defaultMessage: 'Calm Partner Client ID',
		description: 'Label for client id input',
	},
	clientIdPlaceholder: {
		id: 'accountManagerTools.clientIdPlaceholder',
		defaultMessage: 'Partner UID',
		description: 'Placeholder for client id input',
	},
	copyButtonText: {
		id: 'accountManagerTools.copyButtonText',
		defaultMessage: 'Copy',
		description: 'Text for copy button',
	},
});
