import Image from 'next/legacy/image';
import { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import bannerImg from '@/../public/_n/images/pathway-banner.png';
import InitialPageAnalytics from '@/components/analytics/InitialPageAnalytics';
import { PathwaysContext } from '@/components/providers/PathwaysContextProvider';
import PathwaysInfoPages from '@/components/ui/PathwaysInfoPages';
import PathwaysTable from '@/components/ui/PathwaysTable';
import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import { useFeatureFlags } from '@/hooks/api/useFeatureFlags';
import { useDefinedPartner } from '@/hooks/api/usePartner';
import { usePartnerPathways } from '@/hooks/api/usePathways';
// Import the useFeatureFlags hook
import CalmCookie from '@/utils/CalmCookie';
import { srcLoader } from '@/utils/ui/imageLoader';

import messages from './messages';
import {
	BannerContent,
	BannerHeader,
	BannerImage,
	BannerText,
	BannerWrapper,
	CreateButton,
	Heading,
	PathwaysBanner,
	Text,
} from './styles';

const Pathways: React.FC = () => {
	const { clearStoredPathway } = useContext(PathwaysContext);
	const { formatMessage } = useIntl();
	const history = useHistory();
	const { logEvent } = useAnalytics();
	const partner = useDefinedPartner();
	const [showPathwayBanner, setShowPathwayBanner] = useState<boolean>(
		() => CalmCookie.get('has-dismissed-pathways-banner') !== 'true',
	);

	const { data: pathways, loading } = usePartnerPathways(partner.id); // Retrieve pathways data
	const { data: pathwaysV2FlagValues } = useFeatureFlags('b2b-pathways-v2');
	const shouldUsePathwaysV2 = pathwaysV2FlagValues && pathwaysV2FlagValues['b2b-pathways-v2'] === true;
	const [showInfoPages, setShowInfoPages] = useState<boolean>(true);

	const onClickOpenCreateForm = (): void => {
		clearStoredPathway();
		logEvent('Partner Portal : Pathways : Create : Clicked');
		history.push({
			pathname: `/${partner.id}/pathways/create`,
		});
	};

	if (loading) {
		return <p>Loading...</p>;
	}

	if (!pathways || pathways.length === 0) {
		if (shouldUsePathwaysV2) {
			return showInfoPages ? (
				<PathwaysInfoPages onFinish={() => setShowInfoPages(false)} />
			) : (
				<>
					<InitialPageAnalytics />
					<BannerWrapper $isVisible={showPathwayBanner}>
						<PathwaysBanner
							data-testid="pathways-banner"
							onDismiss={() => {
								CalmCookie.set('has-dismissed-pathways-banner', 'true');
								setShowPathwayBanner(false);
							}}
						>
							<BannerContent>
								<BannerImage>
									<Image src={bannerImg} alt="" loader={srcLoader} />
								</BannerImage>
								<BannerText>
									<BannerHeader>{formatMessage(messages.pathwayBannerText1)}</BannerHeader>
									<Text>{formatMessage(messages.pathwayBannerText2)}</Text>
									<Text>{formatMessage(messages.pathwayBannerText3)}</Text>
								</BannerText>
							</BannerContent>
						</PathwaysBanner>
					</BannerWrapper>
					<Heading hasDismissedBanner={!showPathwayBanner}>
						{formatMessage(messages.pathwayHeading)}
						<CreateButton backgroundColor="blue3" onPress={onClickOpenCreateForm}>
							{formatMessage(messages.createButton)}
						</CreateButton>
					</Heading>
					<PathwaysTable />
				</>
			);
		} else {
			return (
				<>
					<InitialPageAnalytics />
					<BannerWrapper $isVisible={showPathwayBanner}>
						<PathwaysBanner
							data-testid="pathways-banner"
							onDismiss={() => {
								CalmCookie.set('has-dismissed-pathways-banner', 'true');
								setShowPathwayBanner(false);
							}}
						>
							<BannerContent>
								<BannerImage>
									<Image src={bannerImg} alt="" loader={srcLoader} />
								</BannerImage>
								<BannerText>
									<BannerHeader>{formatMessage(messages.pathwayBannerText1)}</BannerHeader>
									<Text>{formatMessage(messages.pathwayBannerText2)}</Text>
									<Text>{formatMessage(messages.pathwayBannerText3)}</Text>
								</BannerText>
							</BannerContent>
						</PathwaysBanner>
					</BannerWrapper>
					<Heading hasDismissedBanner={!showPathwayBanner}>
						{formatMessage(messages.pathwayHeading)}
						<CreateButton backgroundColor="blue3" onPress={onClickOpenCreateForm}>
							{formatMessage(messages.createButton)}
						</CreateButton>
					</Heading>
					<PathwaysTable />
				</>
			);
		}
	}

	return (
		<>
			<InitialPageAnalytics />
			<BannerWrapper $isVisible={showPathwayBanner}>
				<PathwaysBanner
					data-testid="pathways-banner"
					onDismiss={() => {
						CalmCookie.set('has-dismissed-pathways-banner', 'true');
						setShowPathwayBanner(false);
					}}
				>
					<BannerContent>
						<BannerImage>
							<Image src={bannerImg} alt="" loader={srcLoader} />
						</BannerImage>
						<BannerText>
							<BannerHeader>{formatMessage(messages.pathwayBannerText1)}</BannerHeader>
							<Text>{formatMessage(messages.pathwayBannerText2)}</Text>
							<Text>{formatMessage(messages.pathwayBannerText3)}</Text>
						</BannerText>
					</BannerContent>
				</PathwaysBanner>
			</BannerWrapper>
			<Heading hasDismissedBanner={!showPathwayBanner}>
				{formatMessage(messages.pathwayHeading)}
				<CreateButton backgroundColor="blue3" onPress={onClickOpenCreateForm}>
					{formatMessage(messages.createButton)}
				</CreateButton>
			</Heading>
			<PathwaysTable />
		</>
	);
};

export default Pathways;
