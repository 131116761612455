import Image from 'next/legacy/image';

import {
	ContentCardContainer,
	ContentImageContainer,
	ContentDurationContainer,
	ContentDurationText,
	ContentTitleContainer,
	ContentTitleText,
	ContentDescriptionContainer,
	ContentDescriptionText,
	ContentTextContainer,
	PlayButtonSvg,
} from './styles';
import { ContentCardProps } from './types';
import { formatResizedUrl, loader } from './utils';

const ContentCard: React.FC<ContentCardProps> = ({
	image,
	contentDuration,
	title,
	category,
	narrator,
	assetUrl,
}) => {
	const contentDurationString = `${Math.floor((contentDuration ?? 0) / 60)} min`;

	const cardContainerProps = {
		...(assetUrl ? { href: assetUrl, target: '_blank', rel: 'noopener noreferrer' } : {}),
	};

	return (
		<ContentCardContainer $isPlayable={Boolean(assetUrl)} {...cardContainerProps}>
			<ContentImageContainer>
				<Image
					placeholder="blur"
					blurDataURL={formatResizedUrl(image.url, 4, 4)}
					layout="fill"
					objectFit="cover"
					src={formatResizedUrl(image.url, 160, 160)}
					alt=""
					loader={loader}
				/>
				<ContentDurationContainer>
					<PlayButtonSvg />
					<ContentDurationText>{contentDurationString}</ContentDurationText>
				</ContentDurationContainer>
			</ContentImageContainer>
			<ContentTextContainer>
				<ContentTitleContainer>
					<ContentTitleText>{title}</ContentTitleText>
				</ContentTitleContainer>
				<ContentDescriptionContainer>
					<ContentDescriptionText>
						{[category, narrator].filter(item => item).join(' • ')}
					</ContentDescriptionText>
				</ContentDescriptionContainer>
			</ContentTextContainer>
		</ContentCardContainer>
	);
};

export default ContentCard;
