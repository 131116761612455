import { defineMessages } from 'react-intl';

export default defineMessages({
	maxTitle: {
		id: 'teams.coveredLives.maxTitle',
		defaultMessage: 'Buying for a larger business?',
		description: 'Covered Lives maximum title',
	},
	maxBody: {
		id: 'teams.coveredLives.maxBody',
		defaultMessage:
			'If you would like to purchase coverage for more than {maxNum} eligible team members, please contact sales to book a demo.',
		description: 'Covered Lives maximum body',
	},
	maxButtonText: {
		id: 'teams.coveredLives.maxButtonText',
		defaultMessage: 'Contact sales',
		description: 'Covered Lives maximum button text',
	},
	minTitle: {
		id: 'teams.coveredLives.minTitle',
		defaultMessage: 'Buying for less than 5 covered lives?',
		description: 'Covered Lives maximum title',
	},
	minBody: {
		id: 'teams.coveredLives.minBody',
		defaultMessage: 'If you would like to purchase less than 5 covered lives then please go to Calm.com',
		description: 'Covered Lives maximum body',
	},
	minButtonText: {
		id: 'teams.coveredLives.minButtonText',
		defaultMessage: 'Go to Calm.com',
		description: 'Covered Lives maximum button text',
	},
});
