import styled from 'styled-components';

import { FontSizes, FontWeights, FormSelect, Text, TextElements, units } from '@calm-web/design-system';

export const SelectContainer = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: ${units(2)};
`;
export const LabelText = styled(Text).attrs({
	size: FontSizes.base,
	weight: FontWeights.Medium,
	color: 'gray7',
	el: TextElements.Span,
})`
	width: 50%;
	line-height: 24px;
	text-align: left;
`;

export const StyledFormSelect = styled(FormSelect)`
	width: 50%;
	margin-bottom: 0;
`;
