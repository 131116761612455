import { AxiosResponse } from 'axios';
import { DateTime } from 'luxon';
import useSWR from 'swr';

import { useApi } from '@/hooks/api';
import { SegmentSelectState, segmentsToJSON } from '@/utils/segments';

import { ApiResponse, RefetchOption } from '../types';
import { ReportingAggregationLevel } from './utils';

export interface ActivityReport {
	signups: {
		starting_ts: string;
		signup_count: number;
	}[];
	sessions_per_active_user: {
		starting_ts: string;
		active_user_average_session_count: number;
	}[];
}

export interface ReportSignupsAndEngagementModular {
	partner_id: string;
	covered_lives: number | null;
	sign_up_count: number;
	sign_up_rate: number;
	sign_up_count_at_start?: number;
	signups_benchmark?: number;
	engaged_count: number;
	engage_rate: number;
	engaged_count_at_start?: number;
	engagement_benchmark?: number;
	unique_users_in_segment?: number | null;
	start_date?: string;
	end_date?: string;
	aggregation_level: ReportingAggregationLevel;
	sign_up_start_to_end_change_percentage?: number;
	sign_up_start_to_end_change_actual?: number;
	engaged_start_to_end_change_percentage?: number;
	engaged_start_to_end_change_actual?: number;
	activity_report: ActivityReport;
	updated_at?: string;
	is_mocked?: boolean;
}

export function useSignupsAndEngagement({
	partnerId,
	segmentSelection,
	startDate = DateTime.local().minus({ weeks: 12 }).toJSDate(),
	endDate,
}: {
	partnerId: string;
	startDate?: Date;
	endDate?: Date;
	segmentSelection?: SegmentSelectState;
}): ApiResponse<ReportSignupsAndEngagementModular> & RefetchOption {
	const apiRequest = useApi();

	const [segmentFilter, swrString] = segmentsToJSON(segmentSelection, startDate, endDate);

	const endpoint = `b2b/partners/${partnerId}/reporting/signups-and-engagement`;

	const { data, error, mutate } = useSWR<AxiosResponse | undefined>(
		`${endpoint}${swrString}`,
		async () => {
			if (!endDate) {
				return undefined;
			}
			const response = await apiRequest({ endpoint, method: 'POST', body: segmentFilter });
			return response;
		},
		{ errorRetryCount: 0 },
	);
	if (!endDate) {
		return {
			data: undefined,
			error,
			loading: true,
			refetch: () => {},
		};
	}
	const sign_up_start_to_end_change_percentage =
		data?.data.report.sign_up_count_at_start && data?.data.report.sign_up_count
			? data.data.report.sign_up_count / data.data.report.sign_up_count_at_start - 1
			: undefined;

	const sign_up_start_to_end_change_actual =
		data?.data.report.sign_up_count_at_start && data?.data.report.sign_up_count
			? data.data.report.sign_up_count - data.data.report.sign_up_count_at_start
			: undefined;

	const engaged_start_to_end_change_percentage =
		data?.data.report.engaged_count_at_start && data?.data.report.engaged_count
			? data.data.report.engaged_count / data.data.report.engaged_count_at_start - 1
			: undefined;

	const engaged_start_to_end_change_actual =
		data?.data.report.engaged_count_at_start && data?.data.report.engaged_count
			? data.data.report.engaged_count - data.data.report.engaged_count_at_start
			: undefined;

	const apiReport =
		data?.data.report ?? data?.data
			? {
					...data?.data.report,
					sign_up_start_to_end_change_percentage,
					sign_up_start_to_end_change_actual,
					engaged_start_to_end_change_percentage,
					engaged_start_to_end_change_actual,
			  }
			: (undefined as ReportSignupsAndEngagementModular | undefined);
	const refetch = (): Promise<AxiosResponse | undefined> => mutate(undefined, true);
	return {
		data: apiReport,
		error,
		loading: !data && !error,
		refetch,
	};
}
