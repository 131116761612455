import { defineMessages } from 'react-intl';

export default defineMessages({
	title: {
		id: 'users.segments.pathways.update.title',
		defaultMessage: 'Update Pathways with new segments?',
		description: 'Title for when the partner has pathways with segments that need updating',
	},
	subtitle: {
		id: 'users.segments.pathways.update.subtitle',
		defaultMessage:
			'You will have to manually update your scheduled Pathways if you would like them to reflect your newly added segments.',
		description: 'Explanation for manually updating scheduled Pathways with new segments.',
	},
	subtitle2: {
		id: 'users.segments.pathways.update.subtitle2',
		defaultMessage: 'For launched Pathways past the Announcement date:',
		description: 'Explanation for manually updating scheduled Pathways with new segments.',
	},
	bulletedList1: {
		id: 'users.segments.pathways.update.bulletedList1',
		defaultMessage: 'Users within the previously existing segments will still receive the Pathway',
		description: 'Explanation for manually updating scheduled Pathways past announcement date.',
	},
	bulletedList2: {
		id: 'users.segments.pathways.update.bulletedList2',
		defaultMessage: 'Updating the segments will only add users to the audience',
		description: 'Explanation for manually updating scheduled Pathways past announcement date.',
	},
	cancelPathwaysButton: {
		id: 'users.segments.pathways.cancel.button',
		defaultMessage: 'Not now',
		description: 'Exit modal for updating pathways after adding new segments',
	},
	viewPathwaysButton: {
		id: 'users.segments.pathways.view.button',
		defaultMessage: 'View Pathways',
		description: 'Button message for viewing Pathways',
	},
});
