import { ReactElement, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router';

import { Button, FontSizes, FontWeights, Text } from '@calm-web/design-system';

import { useShouldShowBenefitEmailUpdates } from '@/hooks/useShouldShowBenefitEmailUpdates';
import { Partner } from '@/types/store/reducers';
import CalmCookie from '@/utils/CalmCookie';

import SyncButton from '../SyncButton';
import messages from './messages';
import { BodyWrapper, BoldText, ButtonGroup, Container, Inner, SmallTextButton } from './styles';

interface Props {
	partner: Partner;
	openModal: () => void;
}

export default function UploadFirstEligibilityFile({ partner, openModal }: Props): ReactElement {
	const { formatMessage } = useIntl();
	const history = useHistory();
	const { search } = useLocation();
	const shouldShowBenefitEmailUpdates = useShouldShowBenefitEmailUpdates();

	const onBenefitsClick = (): void => {
		CalmCookie.set('benefits-link-clicked', 'true');
		history.push({
			pathname: `/${partner.id}/account`,
			search,
		});
	};

	return (
		<Container>
			<Inner>
				<Text el="h2" styleAs="h5" weight={FontWeights.Medium}>
					{formatMessage(messages.designateTitle)}
				</Text>
				<BodyWrapper>
					<Text el="p" size={FontSizes.sm}>
						{formatMessage(messages.designateSubtitle, {
							userIdDescriptor: partner.user_id_descriptor,
						})}
						<br />
						<br />
						{shouldShowBenefitEmailUpdates
							? formatMessage(messages.designateNote, {
									boldtext: (...chunks: ReactNode[]) => <BoldText>{chunks}</BoldText>,
									br: () => <br />,
									benefitslink: (...chunks: ReactNode[]) => (
										<SmallTextButton onClick={onBenefitsClick}>{chunks}</SmallTextButton>
									),
							  })
							: formatMessage(messages.oldDesignateNote)}
					</Text>
				</BodyWrapper>
				<ButtonGroup>
					<Button backgroundColor="blue3" onPress={openModal} data-testid="open-ef-modal">
						{formatMessage(messages.designateButton)}
					</Button>
					<SyncButton partnerId={partner.id} />
				</ButtonGroup>
			</Inner>
		</Container>
	);
}
