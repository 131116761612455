import { defineMessages } from 'react-intl';

export default defineMessages({
	typeformButtonText: {
		id: 'nav.typeform.buttonText',
		defaultMessage: 'Give Your Feedback',
		description: 'Button text for the typeform survey',
	},
	workshopsBanner: {
		id: 'nav.workshopsBanner',
		defaultMessage:
			'You don’t have workshops as part of your plan. Sign up for <yellowTitle>Calm Workshops</yellowTitle> today!',
		description:
			'Workshops promotion to show to b2b partners in order for them to hopefully sign up for more workshops (a product we offer)',
	},
	learnMore: {
		id: 'nav.workshopsBanner.learnMore',
		defaultMessage: 'Learn more',
		description: 'A button indicating the user can learn more about a topic',
	},
	requestSent: {
		id: 'nav.workshopsBanner.requestSent',
		defaultMessage: 'Request sent!',
		description: 'A request to add workshops to the partner plan has been sent',
	},
});
