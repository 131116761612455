/* eslint-disable no-nested-ternary */
import { ReactElement, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Loader, Button, Modal } from '@calm-web/design-system';

import { useCancelSubscription } from '@/hooks/api/useSubscription';

import messages from './messages';
import {
	ConfirmationText,
	ErrorMessage,
	LoaderContainer,
	LoadingText,
	SuccessIcon,
	WarningIcon,
} from './styles';

interface Props {
	onModalDismiss: () => void;
	expirationDateString: string;
}

export default function CancelPlanModal({ onModalDismiss, expirationDateString }: Props): ReactElement {
	const { formatMessage } = useIntl();
	const { partnerId } = useParams();
	const [cancelSubscription, { loading: isCanceling, error: cancelationError }] =
		useCancelSubscription(partnerId);
	const [hasCanceled, setHasCanceled] = useState(false);

	const onCancelPlan = async (): Promise<void> => {
		if (isCanceling) return;
		try {
			await cancelSubscription();
			setHasCanceled(true);
		} catch {
			// Any errors are returned as cancelationError from the hook
		}
	};

	return (
		<Modal
			isOpen
			closeModal={onModalDismiss}
			data-testid="cancellation-modal"
			title={hasCanceled ? formatMessage(messages.areYouSure) : formatMessage(messages.areYouSure)}
			footer={
				isCanceling ? (
					<Button backgroundColor="blue3" isDisabled>
						{formatMessage(messages.done)}
					</Button>
				) : hasCanceled ? (
					<Button backgroundColor="blue3" onPress={onModalDismiss} data-testid="success">
						{formatMessage(messages.done)}
					</Button>
				) : (
					<Button
						backgroundColor="errorRed"
						isLoading={isCanceling}
						onPress={onCancelPlan}
						data-testid="do-cancel"
					>
						{formatMessage(messages.cancelPlan)}
					</Button>
				)
			}
		>
			{isCanceling ? (
				<>
					<div>
						<LoaderContainer>
							<Loader color="black" />
						</LoaderContainer>
						<LoadingText>{formatMessage(messages.loading)}</LoadingText>
					</div>
				</>
			) : hasCanceled ? (
				<>
					<SuccessIcon />
					<ConfirmationText>
						{formatMessage(messages.hasCanceledText, {
							expirationdate: expirationDateString,
						})}
					</ConfirmationText>
				</>
			) : (
				<>
					<WarningIcon />
					{cancelationError && !isCanceling ? (
						<ErrorMessage>{formatMessage(messages.planRemovalError)}</ErrorMessage>
					) : (
						<ConfirmationText>
							{formatMessage(messages.confirmText, {
								expirationdate: expirationDateString,
							})}
						</ConfirmationText>
					)}
				</>
			)}
		</Modal>
	);
}
