import styled, { keyframes } from 'styled-components';
import { palette } from 'styled-tools';

import { units } from '@calm-web/design-system';

const graphSpacer = styled.div`
	top: ${units(12)};
	height: 50%;
	width: 1%;
`;

export const LoadingContainer = styled.div`
	height: 300px;
	padding: ${units(2)};
`;

export const wave = keyframes`
	from {
		background-position: -468px 0;
	}
	to {
		background-position: 468px 0;
	}
`;

export const Loading = styled.div`
	animation-duration: 1s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-name: ${wave};
	animation-timing-function: linear;
	background: ${palette('gray1')};
	background-image: -webkit-gradient(
		linear,
		left center,
		right center,
		from(${palette('gray1')}),
		color-stop(0.2, ${palette('gray0')}),
		color-stop(0.4, #e5e5e5),
		to(${palette('gray1')})
	);
	background-image: -webkit-linear-gradient(
		left,
		${palette('gray1')} 0%,
		${palette('gray0')} 20%,
		#e5e5e5 40%,
		${palette('gray1')} 100%
	);
	background-image: -moz-gradient(
		linear,
		left center,
		right center,
		from(${palette('gray1')}),
		color-stop(0.2, ${palette('gray0')}),
		color-stop(0.4, #e5e5e5),
		to(${palette('gray1')})
	);
	background-image: -moz-linear-gradient(
		left,
		${palette('gray1')} 0%,
		${palette('gray0')} 20%,
		#e5e5e5 40%,
		${palette('gray1')} 100%
	);
	background-repeat: no-repeat;
	background-size: 800px 275px;
	height: 275px;
	position: relative;
	div {
		background: ${palette('white')};
		position: absolute;
	}
`;

/* Header and data */

export const Shape1 = styled.div`
	height: ${units(4)};
	left: ${units(17)};
	right: 0;
`;

export const Shape2 = styled.div`
	height: ${units(3)};
	width: 100%;
	top: ${units(3)};
`;

export const Shape3 = styled.div`
	height: ${units(7)};
	width: 30%;
	right: 0;
	top: ${units(6)};
`;

export const Shape4 = styled.div`
	top: ${units(9)};
	height: ${units(4)};
	width: 100%;
`;

/* Graph Section */

export const Shape5 = styled.div`
	top: ${units(12)};
	height: ${units(9)};
	width: 6%;
`;

export const Shape6 = styled(graphSpacer)`
	left: 6%;
`;

export const Shape7 = styled(graphSpacer)`
	left: 12%;
`;

export const Shape8 = styled.div`
	top: ${units(12)};
	height: ${units(4)};
	width: 6%;
	left: 12%;
`;

export const Shape9 = styled(graphSpacer)`
	left: 18%;
`;

export const Shape10 = styled.div`
	top: ${units(12)};
	height: ${units(6)};
	width: 6%;
	left: 18%;
`;

export const Shape11 = styled(graphSpacer)`
	left: 24%;
`;

export const Shape12 = styled.div`
	top: ${units(12)};
	height: ${units(9)};
	width: 6%;
	left: 24%;
`;

export const Shape13 = styled(graphSpacer)`
	left: 30%;
`;

export const Shape14 = styled.div`
	top: ${units(12)};
	height: ${units(4)};
	width: 6%;
	left: 30%;
`;

export const Shape15 = styled(graphSpacer)`
	left: 36%;
`;

export const Shape16 = styled.div`
	top: ${units(12)};
	height: ${units(6)};
	width: 6%;
	left: 36%;
`;

export const Shape17 = styled(graphSpacer)`
	left: 42%;
`;

export const Shape18 = styled(graphSpacer)`
	left: 48%;
`;

export const Shape19 = styled.div`
	top: ${units(12)};
	height: ${units(9)};
	width: 6%;
	left: 48%;
`;

export const Shape20 = styled(graphSpacer)`
	left: 54%;
`;

export const Shape21 = styled.div`
	top: ${units(12)};
	height: ${units(7)};
	width: 6%;
	left: 54%;
`;

export const Shape22 = styled(graphSpacer)`
	left: 60%;
`;

export const Shape23 = styled.div`
	top: ${units(12)};
	height: ${units(4)};
	width: 6%;
	left: 60%;
`;

export const Shape24 = styled(graphSpacer)`
	left: 66%;
`;

export const Shape25 = styled.div`
	top: ${units(12)};
	height: ${units(6)};
	width: 6%;
	left: 66%;
`;

export const Shape26 = styled(graphSpacer)`
	left: 72%;
`;

export const Shape27 = styled.div`
	top: ${units(12)};
	height: ${units(2)};
	width: 6%;
	left: 72%;
`;

export const Shape28 = styled(graphSpacer)`
	left: 78%;
`;

export const Shape29 = styled.div`
	top: ${units(12)};
	height: ${units(6)};
	width: 6%;
	left: 78%;
`;

export const Shape30 = styled(graphSpacer)`
	left: 84%;
`;

export const Shape31 = styled.div`
	top: ${units(12)};
	height: ${units(12)};
	width: 6%;
	left: 84%;
`;

export const Shape32 = styled(graphSpacer)`
	left: 90%;
`;

export const Shape33 = styled.div`
	top: ${units(12)};
	height: ${units(9)};
	width: 6%;
	left: 90%;
`;

export const Shape34 = styled(graphSpacer)`
	left: 96%;
	width: 4%;
`;

/* Resources shape */

export const Shape35 = styled.div`
	top: ${units(28)};
	height: ${units(4)};
	width: 100%;
`;

export const Shape36 = styled.div`
	top: ${units(29)};
	height: ${units(8)};
	width: 60%;
	right: 0;
`;
