// Third Party
import styled from 'styled-components';
import { palette } from 'styled-tools';

import { TextButton, fontWeight, FontWeights, units } from '@calm-web/design-system';

export const CancelButton = styled(TextButton)`
	margin-right: ${units(4)};
`;

export const WarningMessage = styled.div`
	color: ${palette('errorRed')};
	${fontWeight(FontWeights.Medium)};
`;

export const Note = styled.div`
	margin-top: ${units(4)};
	border-top: 1px solid ${palette('gray2')};
	${fontWeight(FontWeights.Light)};
`;
