import { IntlFormatters } from 'react-intl';

import messages from './messages';

export function getErrorMessage(
	formatMessage: IntlFormatters['formatMessage'],
	code?: string,
	subcode?: string,
	message?: string,
): string {
	if (code === 'b2b_partner_exceeded_eligibilities') {
		return formatMessage(messages.uploadError, {});
	}

	if (code === 'b2b_eligibility_list_password_protected') {
		return formatMessage(messages.passwordProtectedError);
	}

	if (code === 'b2b_eligibility_list_unable_to_parse') {
		return formatMessage(messages.unableToParseError);
	}

	if (message && (typeof message === 'string' || typeof message === 'number')) {
		return formatMessage(messages.serverDefinedUploadError, {
			servermessage: message,
		});
	}

	if (code === 'b2b_eligibility_list_invalid_data') {
		if (subcode === 'invalid_format') {
			return formatMessage(messages.invalidFormatError, {});
		}
		if (subcode === 'multiple_columns') {
			return formatMessage(messages.multipleColumnsError, {});
		}

		return formatMessage(messages.uploadError, {});
	}

	return formatMessage(messages.uploadError, {});
}
