import throttle from 'lodash/throttle';
import { RefObject, useCallback, useLayoutEffect, useRef, useState } from 'react';

const useMeasureOnResize = <ElementType extends HTMLDivElement>(
	defaultWidth: number,
	optionalRef?: RefObject<ElementType>,
): { measuredWidth: number; ref: RefObject<ElementType> } => {
	let ref = useRef<ElementType>(null);
	if (typeof optionalRef !== 'undefined') {
		ref = optionalRef;
	}
	const [measuredWidth, setMeasuredWidth] = useState(defaultWidth);
	// Track whether or not we've successfully measured, and set up retries if we haven't
	const hasMeasuredWidthRef = useRef(false);
	const pollTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);

	// The actual resize work
	const handleResize = useCallback((): void => {
		const layoutWidth = ref.current?.clientWidth;
		if (!layoutWidth) {
			return;
		}
		setMeasuredWidth(layoutWidth);
		hasMeasuredWidthRef.current = true;
	}, []);

	// Poll the handleResize function until it successfully measures
	const clearPollTimeout = useCallback(() => {
		if (pollTimeoutRef.current) {
			clearTimeout(pollTimeoutRef.current);
		}
	}, []);
	const retryUntilMeasured = useCallback(
		(retriesRemaining: number) => {
			if (hasMeasuredWidthRef.current || retriesRemaining <= 0) {
				return;
			}
			handleResize();
			clearPollTimeout();
			pollTimeoutRef.current = setTimeout(() => {
				retryUntilMeasured(retriesRemaining - 1);
			}, 100);
		},
		[clearPollTimeout, handleResize],
	);

	// Kick off the measuring attempts, both directly and bound to the window resize function
	useLayoutEffect(() => {
		window.addEventListener('resize', throttle(handleResize, 60));
		retryUntilMeasured(5);
		return (): void => {
			clearPollTimeout();
			return window.removeEventListener('resize', handleResize);
		};
	}, [clearPollTimeout, handleResize, retryUntilMeasured]);

	return { measuredWidth, ref };
};

export default useMeasureOnResize;
