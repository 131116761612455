import { useAuth0 } from '@auth0/auth0-react';
import querystring from 'query-string';
import { ReactElement, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { CalmLogo, FontSizes, FontWeights, Loader, Text } from '@calm-web/design-system';

import InitialPageAnalytics from '@/components/analytics/InitialPageAnalytics';
import { EnvConfig } from '@/env_config';
import { useUser } from '@/hooks/store';
import { srcLoader } from '@/utils/ui/imageLoader';

import {
	Divider,
	LoginContainer,
	LoginInner,
	LoginWrapper,
	LogoContainer,
	SubmitButton,
	Title,
} from './styles';

export default function Login(): ReactElement {
	const { search } = useLocation();
	const query = querystring.parse(search);
	const rawEmailFromSearch = query?.email;
	const emailFromSearch = Array.isArray(rawEmailFromSearch) ? rawEmailFromSearch[0] : rawEmailFromSearch;

	const { loginWithRedirect, logout, isLoading, isAuthenticated } = useAuth0();
	const { user } = useUser();
	const shouldRedirectToAuth0 = !user.accessToken && !isLoading;

	useEffect(() => {
		if (shouldRedirectToAuth0) {
			if (isAuthenticated) {
				logout({ logoutParams: { returnTo: `${EnvConfig.partnerPortalUrl}/login` } }).catch(() => {});
			} else {
				loginWithRedirect({
					authorizationParams: { login_hint: emailFromSearch ?? '' },
					appState: { query },
				}).catch(() => {});
			}
		}
	}, [loginWithRedirect, shouldRedirectToAuth0, emailFromSearch, query, isAuthenticated, logout]);

	if (isLoading || shouldRedirectToAuth0) {
		return <Loader />;
	}

	return (
		<div>
			<InitialPageAnalytics />
			<LoginWrapper>
				<LogoContainer>
					<header>
						<CalmLogo height={48} loader={srcLoader} aria-label="Calm Logo" />
						<Title>Partner Portal</Title>
					</header>
				</LogoContainer>
				<LoginContainer>
					<LoginInner>
						<Text el="h1" color="gray7" size={FontSizes.lg} weight={FontWeights.Medium} noMargin>
							Log in to Calm Partner Portal
						</Text>
						<Text el="p" color="gray8" noMargin>
							Use your Calm Partner login
						</Text>
						<Divider />
						{user.accessToken && (
							<SubmitButton
								type="submit"
								backgroundColor="blue3"
								data-testid="login-button"
								onPress={() => logout({ logoutParams: { returnTo: `${EnvConfig.partnerPortalUrl}/login` } })}
							>
								Log out
							</SubmitButton>
						)}
					</LoginInner>
				</LoginContainer>
			</LoginWrapper>
		</div>
	);
}
