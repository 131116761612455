import { FC, FormEvent, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import { Button, Modal } from '@calm-web/design-system';

import { useSubmitExistingHealthConfigForm } from '@/hooks/api/useHealthConfig';
import { usePermissions } from '@/hooks/auth';
import { useHealthConfigForm, useHealthConfigSubmitData } from '@/hooks/forms/useHealthConfigForm';
import { usePartnerForm, usePartnerSubmitData } from '@/hooks/forms/usePartnerForm';
import { useSetPartnerFormFromParent } from '@/hooks/forms/useSetPartnerFormFromParent';
import { updatePartnerDetails, useSubmitNewPartnerForm } from '@/hooks/forms/useSubmitExistingPartnerForm';
import { useShouldShowEFFileFormatTemplate } from '@/hooks/useShouldShowEFFileFormatTemplate';
import { IntegrationType } from '@/types/store/reducers';
import { ProductSKUType } from '@/utils/SkuEnums';
import { isCalmHealthProductSKUType } from '@/utils/SkuUtils';

import PartnerDetails from '../Account/NameAndId/PartnerDetails';
import AccountDetails from '../Account/TabRouter/sections/AccountDetails';
import CalmAdmin from '../Account/TabRouter/sections/CalmAdmin';
import EmailsAndSurveys from '../Account/TabRouter/sections/EmailAndSurvey';
import { SubmitWrapper } from './styles';

const CreatePartner: FC = () => {
	const [hasValidPermissions, actions] = usePermissions();
	const { formProps } = usePartnerForm();
	const {
		baseFormProps: healthConfigFormProps,
		eligibilityValidatorFormProps,
		errorCustomizationFormProps,
		resetAllDirtyStates,
	} = useHealthConfigForm();

	const history = useHistory();
	const { search } = useLocation();
	useSetPartnerFormFromParent(formProps, search);
	const { getPartnerSubmitData, showValidationErrors } = usePartnerSubmitData(formProps);
	const { getHealthConfigSubmitData, showAdminValidationErrors } = useHealthConfigSubmitData(
		healthConfigFormProps,
		eligibilityValidatorFormProps,
		errorCustomizationFormProps,
	);
	const [submitNewPartnerForm, { data: newPartner, loading }] = useSubmitNewPartnerForm(formProps);
	const [submitExistingHealthConfigForm] = useSubmitExistingHealthConfigForm(resetAllDirtyStates);
	const [showSuccessModal, setShowSuccessModal] = useState(false);

	const isAccountManager = hasValidPermissions('client_id', [actions.READ]);
	const isCalmHealth = isCalmHealthProductSKUType(formProps.model.productSKUType as ProductSKUType);
	const shouldShowEligibilityFileFormat = useShouldShowEFFileFormatTemplate();

	async function onModalDismiss(): Promise<void> {
		setShowSuccessModal(false);
		if (formProps.model.integrationType === IntegrationType.SSO) {
			history.push({
				pathname: `${newPartner?.id}/account/sso`,
				search,
			});
			await updatePartnerDetails(formProps, newPartner);
			return;
		}
		if (formProps.model.integrationType === IntegrationType.GROUP_CODE) {
			history.push({
				pathname: `${newPartner?.id}/account`,
				search,
			});
			await updatePartnerDetails(formProps, newPartner);
			return;
		}
		await updatePartnerDetails(formProps, newPartner);
		history.push({
			pathname: `${newPartner?.id}/users`,
			search,
		});
	}

	const onSubmit = async (e: FormEvent): Promise<void> => {
		e.preventDefault();
		if (showValidationErrors() || showAdminValidationErrors()) {
			return;
		}
		const partnerSubmitData = getPartnerSubmitData();
		const result = await submitNewPartnerForm(partnerSubmitData);
		if (result) {
			if (isCalmHealth) {
				const healthConfigSubmitData = getHealthConfigSubmitData();
				await submitExistingHealthConfigForm(
					shouldShowEligibilityFileFormat
						? {
								integration_type: healthConfigSubmitData.integration_type,
								client_id: healthConfigSubmitData.client_id,
								eligibility_file_format: healthConfigSubmitData.eligibility_file_format,
						  }
						: {
								integration_type: healthConfigSubmitData.integration_type,
								client_id: healthConfigSubmitData.client_id,
						  },
					result.id,
				);
			}
			setShowSuccessModal(true);
		}
	};

	return (
		<form onSubmit={onSubmit}>
			<AccountDetails isAccountManager={isAccountManager} formProps={formProps} />
			<CalmAdmin formProps={formProps} healthConfigFormProps={healthConfigFormProps} />
			{isCalmHealth ? null : <EmailsAndSurveys formProps={formProps} />}
			<SubmitWrapper>
				<Button
					backgroundColor="blue3"
					isLoading={loading}
					isDisabled={loading}
					type={'submit'}
					data-testid="create-partner-button"
				>
					Create Partner
				</Button>
			</SubmitWrapper>
			{showSuccessModal && (
				<Modal
					isOpen
					closeModal={onModalDismiss}
					title="Partner created!"
					footer={
						<Button
							backgroundColor="blue3"
							onPress={onModalDismiss}
							data-testid="create-partner-success-continue-btn"
						>
							Done
						</Button>
					}
				>
					<PartnerDetails formProps={formProps} isEdit={false} isReadOnly />
				</Modal>
			)}
		</form>
	);
};

export default CreatePartner;
