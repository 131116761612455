import capitalize from 'lodash/capitalize';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import { stringFromModelValue } from '@calm-web/use-form';

import { EventProps } from '@/hooks/analytics/types';
import { useAnalytics, AnalyticsReturnType } from '@/hooks/analytics/useAnalytics';
import { useApi } from '@/hooks/api';
import { PartnerMilestoneType, useRecordMilestone } from '@/hooks/api/useMilestones';
import { updatePartnerCache } from '@/hooks/api/usePartner';
import { GetDtcImageResponse, useDtcImage } from '@/hooks/api/useUnifiedBrandingUpload';
import { EditPartnerFormProps } from '@/hooks/forms/usePartnerForm';
import { setBannerMessage } from '@/store/actions/setBannerMessage';
import { JSONValue } from '@/types/json';
import { IntegrationType, Partner, PartnerPayload } from '@/types/store/reducers';
import { isCalmHealthProductSKU } from '@/utils/SkuUtils';
import { validationErrorParser } from '@/utils/apiRequest';
import { isApiError, isCalmError, ValidationError } from '@/utils/apiRequest/errors';
import { calmLogger } from '@/utils/calmLogger';

import { mutatePartnerRelations } from '../api/usePartnerRelations';
import { usePermissions } from '../auth';

interface HookResponseState {
	data: Partner | undefined;
	loading: boolean;
	error: boolean;
}

type UseSubmitExistingPartnerFormResponse = [
	submitExistingPartnerForm: (partnerData: PartnerPayload, partner: Partner) => Promise<void>,
	state: HookResponseState,
	getDtcImage: () => GetDtcImageResponse,
];

function toSpaceSeparatedValue(v = ''): string {
	return v.replace(/_/g, ' ');
}

export async function updatePartnerDetails(formProps: EditPartnerFormProps, data?: Partner): Promise<void> {
	if (data) {
		await updatePartnerCache(data);

		const parentId = stringFromModelValue(formProps.model.parentId);
		if (formProps.dirtyState.parentId?.hasChanged && parentId) {
			await mutatePartnerRelations(parentId);
		}

		formProps.resetAllDirtyStates();
	}
}

function getValidationErrorMessage({ id, code, params }: ValidationError): string {
	const formattedId = capitalize(toSpaceSeparatedValue(id));
	if (code === 'string.min' || code === 'minLength') {
		if (params?.limit) {
			return `${formattedId} must be a minimum of ${params.limit} characters`;
		}
		// This case shouldn't really happen, but it's here just in case
		return `${formattedId} does not meet the minimum length`;
	}

	if ((code.startsWith('string.pattern') || code === 'pattern') && id === 'id') {
		return 'Id must only contain letters, numbers, and dashes';
	}

	if (
		(code === 'number.min' && params?.limit) ||
		(code === 'minimum' && params?.limit && params?.comparison)
	) {
		// This will look something like `Contract Covered Lives must be >= 1`
		return `${formattedId} must be ${params?.comparison ?? '>='} ${params.limit}`;
	}

	// This should only happen if there's a developer mistake, not a user mistake
	if (code === 'additionalProperties' && params?.additionalProperty) {
		return `${params.additionalProperty} is not an accepted partner property`;
	}
	return 'An error has occured!';
}

function handleError(
	err: unknown,
	partnerData: PartnerPayload,
	formProps: EditPartnerFormProps,
	logEvent: (eventName: string, eventProps?: EventProps) => AnalyticsReturnType,
	dispatch: Dispatch,
): void {
	if (formProps.model.uploadedLogo?.[0] && !partnerData.logo_url) {
		logEvent('Partner : Logo Upload : Error', { logo_type: 'partner-portal' });
	}
	if (formProps.model.uploadedDtcLogo?.[0] && !partnerData.dtc_logo_url) {
		logEvent('Partner : Logo Upload : Error', { logo_type: 'dtc-app' });
	}
	const validationErrors = validationErrorParser(err);
	if (validationErrors.length) {
		// Blaine Muri - 10/26/2020 - for now just handle 1 single error until
		// we have designs for handling multiple errors at once
		const [firstError] = validationErrors;
		const errorMessage = getValidationErrorMessage(firstError);
		dispatch(
			setBannerMessage({
				message: errorMessage,
				flash: true,
				isError: true,
			}),
		);
	} else if (isApiError(err) && err?.status === 409) {
		dispatch(
			setBannerMessage({
				message: `Error: Partner ID or Name already exists`,
				flash: true,
				isError: true,
			}),
		);
	} else if (isCalmError(err) && err.status === 400 && err.data.error.code === 'b2b_account_required') {
		dispatch(
			setBannerMessage({
				message: `A B2B account is required for all Partners. Please specify a Salesforce Account ID to associate the Partner with`,
				flash: true,
				isError: true,
			}),
		);
	} else if (isCalmError(err) && err.status === 400 && err.data.error.code === 'sfdc_account_required') {
		dispatch(
			setBannerMessage({
				message: `A B2B account with an SFDC id is required for all Partners`,
				flash: true,
				isError: true,
			}),
		);
	} else if (isCalmError(err) && err.status === 400 && err.data.error.code === 'read_only_b2b_partner') {
		dispatch(
			setBannerMessage({
				message: `A read only Partner cannot be updated in Partner Portal`,
				flash: true,
				isError: true,
			}),
		);
	} else {
		calmLogger.error('Unhandled error in handleError', {}, err as Error);
		dispatch(
			setBannerMessage({
				message: `An error has occurred!`,
				flash: true,
				isError: true,
			}),
		);
	}
}

export const useSubmitExistingPartnerForm = (
	formProps: EditPartnerFormProps,
): UseSubmitExistingPartnerFormResponse => {
	const apiRequest = useApi();
	const { logEvent } = useAnalytics();
	const dispatch = useDispatch();
	const [recordMilestone] = useRecordMilestone();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const { getDtcImage, clearImage } = useDtcImage(formProps);
	const [hasValidPermissions, actions] = usePermissions();

	async function logUserEmailPreferencesFormFieldDeltas(
		partnerSubmitData: PartnerPayload,
		partner: Partner,
	): Promise<void> {
		const surveyCheckboxInitial = partner?.email_config_can_send_survey;
		const surveyCheckboxFinal = partnerSubmitData.email_config_can_send_survey;
		const benefitRemindersCheckboxInitial = partner?.email_config_can_send_benefit_reminders;
		const benefitRemindersCheckboxFinal = partnerSubmitData.email_config_can_send_benefit_reminders;

		const checkboxDeltas = [
			[surveyCheckboxInitial, surveyCheckboxFinal, 'Survey Checkbox'],
			[benefitRemindersCheckboxInitial, benefitRemindersCheckboxFinal, 'Benefit Reminders Checkbox'],
		];
		for (const [initial, final, name] of checkboxDeltas) {
			if (initial === final) {
				continue;
			}
			const checkboxOpString = final ? 'checked' : 'unchecked';
			await logEvent(`PP : User Email Preferences : ${name} : ${checkboxOpString}`);
		}
	}

	async function logFormFieldDeltas(partnerSubmitData: PartnerPayload, partner: Partner): Promise<void> {
		try {
			await logUserEmailPreferencesFormFieldDeltas(partnerSubmitData, partner);
		} catch (err) {
			calmLogger.error(
				'Error in useSubmitExistingPartnerForm logUserEmailPreferencesFormFieldDeltas',
				{},
				err,
			);
		}
	}

	async function submitExistingPartnerForm(partnerData: PartnerPayload, partner: Partner): Promise<void> {
		const isCalmHealth = isCalmHealthProductSKU(partner.product_sku);
		const willUpdateLogo =
			hasValidPermissions('logo_url', [actions.UPDATE]) && formProps.model.uploadedLogo?.[0];
		const willUpdateDtcLogo =
			hasValidPermissions('dtc_logo_url', [actions.UPDATE]) &&
			!isCalmHealth &&
			(formProps.model.uploadedDtcLogo?.[0] || getDtcImage().dtcImageFile);
		try {
			setIsSubmitting(true);
			if (willUpdateLogo || willUpdateDtcLogo) {
				const logoData = new FormData();
				if (willUpdateLogo) {
					logoData.append('logo', formProps.model.uploadedLogo?.[0] as File);
				}
				if (willUpdateDtcLogo) {
					if (formProps.model.uploadedDtcLogo?.[0]) {
						logoData.append('dtc_logo', formProps.model.uploadedDtcLogo?.[0] as File);
					} else {
						const { dtcImageFile } = getDtcImage();
						if (dtcImageFile) {
							logoData.append('dtc_logo', dtcImageFile);
						}
					}
				}
				// patch to just send logo file
				const logoRes = await apiRequest({
					endpoint: `b2b/partners/${partner.id}`,
					method: 'PATCH',
					body: logoData,
				});
				if (logoRes.status === 200) {
					const eventProps: EventProps = {};
					if (willUpdateLogo) {
						partnerData.logo_url = logoRes.data.partner.logo_url;
						formProps.setProperty('logoUrl', logoRes.data.partner.logo_url);
						eventProps.logo = logoRes.data.partner.logo_url;
					}
					if (willUpdateDtcLogo) {
						partnerData.dtc_logo_url = logoRes.data.partner.dtc_logo_url;
						formProps.setProperty('dtcLogoUrl', logoRes.data.partner.dtc_logo_url);
						eventProps.dtc_logo = logoRes.data.partner.dtc_logo_url;
					}
					logEvent('Partner : Logo Upload : Success', eventProps);
					if (formProps.model.uploadedLogo?.[0] && partnerData?.logo_url) {
						await recordMilestone({
							eventName: PartnerMilestoneType.EMPLOYER_LOGO_UPLOADED,
							partnerId: partner.id,
						}).catch(err => {
							// ignore errors
						});
					}
					if (formProps.model.uploadedLogo?.[0]) {
						formProps.setProperty('uploadedLogo', []);
					}
					if (formProps.model.uploadedDtcLogo?.[0]) {
						formProps.setProperty('uploadedDtcLogo', []);
					}
				}
			}
			if (!partnerData.logo_url) {
				partnerData.dtc_logo_url = '';
				clearImage();
			}
			const res = await apiRequest({
				endpoint: `b2b/partners/${partner.id}`,
				method: 'PATCH',
				body: partnerData,
			});
			const responseData = res?.data?.partner ?? res?.data;
			if (responseData) {
				const updatedPartnerFields: { [field: string]: JSONValue } = {};
				Object.keys(responseData).forEach((field: string) => {
					if (partner && partner[field as keyof Partner] !== responseData[field]) {
						updatedPartnerFields[field] = responseData[field];
					}
				});
				if (Object.keys(updatedPartnerFields).length) {
					logEvent('Partner : Updated', { ...updatedPartnerFields });
				}
			}
			await updatePartnerDetails(formProps, responseData);
			dispatch(
				setBannerMessage({
					message: 'Successfully updated partner info',
					flash: true,
					isError: false,
				}),
			);
			await logFormFieldDeltas(partnerData, partner);
		} catch (error) {
			handleError(error, partnerData, formProps, logEvent, dispatch);
		} finally {
			setIsSubmitting(false);
		}
	}

	return [submitExistingPartnerForm, { data: undefined, loading: isSubmitting, error: false }, getDtcImage];
};

type UseSubmitNewPartnerFormResponse = [
	submitNewPartnerForm: (partnerData: PartnerPayload) => Promise<Partner | undefined>,
	state: HookResponseState,
];
export const useSubmitNewPartnerForm = (formProps: EditPartnerFormProps): UseSubmitNewPartnerFormResponse => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [newPartner, setNewPartner] = useState();
	const apiRequest = useApi();
	const { logEvent } = useAnalytics();
	const dispatch = useDispatch();

	async function submitNewPartnerForm(partnerData: PartnerPayload): Promise<Partner | undefined> {
		try {
			setIsSubmitting(true);
			const res = await apiRequest({
				endpoint: 'b2b/partners',
				method: 'POST',
				body: partnerData,
				timeout: 1000 * 120,
			});
			const partnerResp = res?.data?.partner ?? res?.data;
			setNewPartner(partnerResp);
			if (partnerResp.integrationType === IntegrationType.GROUP_CODE) {
				logEvent('Partner : Group Code : Submit', {
					integration_type: partnerResp.integrationType,
					group_code: partnerResp.groupCode,
				});
			}
			// Note - The consumer will call 'updatePartnerDetails' manually to account for the "post creation" modal
			dispatch(
				setBannerMessage({
					message: `Successfully created partner!`,
					flash: true,
					isError: false,
				}),
			);
			return partnerResp;
		} catch (error) {
			handleError(error, partnerData, formProps, logEvent, dispatch);
		} finally {
			setIsSubmitting(false);
		}
	}

	return [
		submitNewPartnerForm,
		{ data: newPartner, loading: isSubmitting, error: !newPartner && !isSubmitting },
	];
};
