import { useEffect, useState } from 'react';

import { calmLogger } from '@/utils/calmLogger';

export function useDynamicImport<T>(
	importPromise:
		| Promise<{
				default: T;
				prototype: unknown;
		  }>
		| undefined,
): T | undefined {
	const [imported, setImported] = useState<T>();

	useEffect(() => {
		let cancelled = false;
		const awaitImport = async (): Promise<void> => {
			const result = await importPromise;
			if (!cancelled && result && typeof imported === 'undefined') {
				setImported(() => result.default);
			}
		};
		awaitImport().catch(err => calmLogger.error('Error in useDynamicImport', {}, err));
		return () => {
			cancelled = true;
		};
	}, [importPromise, imported]);

	return imported;
}
