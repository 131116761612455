import isEqual from 'lodash/isEqual';

const BOLDED_PHRASES = [
	['saved', ' ', 'my', ' ', 'life'],
	['lebron', ' ', 'james'],
	['life', ' ', 'saver'],
	['life', ' ', 'saving'],
	['thank', ' ', 'you'],
	['thank', '-', 'you'],
	['master', ' ', 'class'],
];
const BOLDED_WORDS = new Set([
	'sleep',
	'asleep',
	'sleeping',
	'slept',
	'love',
	'loving',
	'thankyou',
	'appreciate',
	'please',
	'keep',
	'calm',
	'meditate',
	'meditating',
	'meditation',
	'happy',
	'happier',
	'happiest',
	'stress',
	'music',
	'lifesaver',
	'lifesaving',
	'life',
	'hope',
	'focus',
	'focusing',
	'gratitude',
	'thankful',
	'strength',
	'kindness',
	'joy',
	'joyful',
	'grateful',
	'son',
	'daughter',
	'child',
	'kids',
	'inspire',
	'inspired',
	'inspiring',
	'inspirational',
	'valuable',
	'wellness',
	'health',
	'relax',
	'relaxing',
	'help',
	'helped',
	'everyday',
	'daily',
	'masterclass',
	'spark',
	'breathe',
	'breath',
	'body',
]);

interface PieceAndBold {
	piece: string;
	bold: boolean;
}

const getWordsSplitByBolded = (value: string): PieceAndBold[] => {
	let boldedPhraseEndIdx = -1;
	return (
		value
			.split(/\b/)
			.map((word, idx, all) => {
				// Skip the words we've already included in a bolded phrase
				if (idx < boldedPhraseEndIdx) {
					return { piece: '', bold: false };
				}
				// See if this word plus some of the following words matches a bolded phrase
				for (let i = 0; i < BOLDED_PHRASES.length; i++) {
					const phrasePieces = BOLDED_PHRASES[i];
					const candidateEndIdx = idx + phrasePieces.length;
					const candidatePhrase = all.slice(idx, candidateEndIdx);
					const candidatePhraseLower = candidatePhrase.map(phraseWord => phraseWord.toLowerCase());
					if (isEqual(phrasePieces, candidatePhraseLower)) {
						boldedPhraseEndIdx = candidateEndIdx;
						return {
							piece: candidatePhrase.join(''),
							bold: true,
						};
					}
				}
				// If it doesn't match a phrase, just bold it if it matches a bolded word
				return {
					piece: word,
					bold: BOLDED_WORDS.has(word.toLowerCase()),
				};
			})
			// Trim empty pieces.
			// Especially important if they're the end of the list so we can properly inject spaces when rendering
			.filter(({ piece }) => Boolean(piece))
	);
};
export default getWordsSplitByBolded;
