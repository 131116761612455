import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useApi } from '@/hooks/api';
import { setBannerMessage } from '@/store/actions';
import { CalmError, getCalmErrorOrError } from '@/utils/apiRequest/errors';

// eslint-disable-next-line @rushstack/no-new-null
type jwtType = string | (string | null)[] | null;

export function useUserFeedback(): [
	(responseText: string, calmjwt: jwtType) => Promise<void>,
	{ loading: boolean; error: CalmError | Error | undefined },
] {
	const apiRequest = useApi();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<CalmError | Error | undefined>(undefined);

	async function saveUserFeedback(responseText: string, calmjwt: jwtType): Promise<void> {
		if (loading) return;
		setLoading(true);
		setError(undefined);
		try {
			await apiRequest({
				endpoint: 'partnerportal/user-feedback',
				method: 'POST',
				body: { token: calmjwt, response_text: responseText },
			});
		} catch (err) {
			setError(getCalmErrorOrError(err));
			dispatch(
				setBannerMessage({
					message: 'Unable to submit feedback at this time',
					isError: true,
					flash: true,
				}),
			);
			throw err;
		} finally {
			setLoading(false);
		}
	}

	return [saveUserFeedback, { loading, error }];
}
