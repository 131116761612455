import { ReactElement } from 'react';

import { Card, RadioGroup } from '@calm-web/design-system';

import CellTitle from '@/components/ui/CellTitle';
import type { EditPartnerFormProps } from '@/hooks/forms/usePartnerForm';

interface Props {
	formProps: EditPartnerFormProps;
}

export default function Compliance({ formProps }: Props): ReactElement {
	const options = [
		{
			value: true.toString(),
			id: 'hippaCompliant',
			label: 'IS HIPAA compliant',
		},
		{
			value: false.toString(),
			id: 'notHippaCompliant',
			label: 'IS NOT HIPAA compliant',
		},
	];
	return (
		<Card>
			<CellTitle showAdminFlag>Compliance</CellTitle>
			<RadioGroup
				{...formProps.bindWithErrorProps('isHipaaCompliant', 'radio')}
				options={options}
				asCards
				aria-label="Does the partner require HIPAA compliance?"
			/>
		</Card>
	);
}
