import styled from 'styled-components';
import { palette } from 'styled-tools';

import { Button, PrimaryButton, Text, units } from '@calm-web/design-system';

import WarningSvg from 'icons/warning-outline.svg';

export const PrimaryButtonStyled = styled(PrimaryButton)`
	white-space: nowrap;
`;

export const WarningIcon = styled(WarningSvg)`
	height: ${units(3)};
	width: ${units(3)};
	path {
		fill: ${palette('gray7')};
	}
`;

export const StyledButton = styled(Button)`
	white-space: nowrap;
`;

export const ButtonGroup = styled.div`
	display: flex;
	gap: ${units(1)};
`;

export const WarningText = styled(Text)`
	max-width: ${units(52)};
`;
export const WarningWrapper = styled.div`
	display: flex;
	gap: ${units(1)};
`;

export const FooterRow = styled.div<{ $hideSendOption: boolean }>`
	width: 100%;
	display: flex;
	justify-content: ${p => (p.$hideSendOption ? 'end' : 'space-between')};
	flex-wrap: none;
	padding: ${units(0, 3)};
`;
