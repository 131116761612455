import { ReactElement, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import EmailReminderModal from '@/components/pages/Users/EmailReminderModal';
import { useDefinedPartner } from '@/hooks/api/usePartner';

function SignupReminderModal(): ReactElement | null {
	const partner = useDefinedPartner();
	const [showModal, setShowModal] = useState(false);
	const { hash } = useLocation();
	const history = useHistory();

	useEffect(() => {
		const initialState = hash === '#schedule-signup-email';
		setShowModal(initialState);
	}, [hash, setShowModal]);

	const onClose = (): void => {
		history.push({
			pathname: 'home',
		});
		setShowModal(false);
	};

	if (!partner) {
		return null;
	}

	return (
		<EmailReminderModal
			isOpen={showModal}
			onClose={onClose}
			partnerId={partner.id}
			partnerName={partner.name}
		></EmailReminderModal>
	);
}

export default SignupReminderModal;
