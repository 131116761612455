import styled from 'styled-components';

import { units } from '@calm-web/design-system';

export const DatePickerWrapper = styled.div`
	width: 100%;
	margin-bottom: ${units(2)};
`;

export const RadioGroupWrapper = styled.div`
	[role='radiogroup'] > div {
		flex-wrap: wrap;
	}
`;
