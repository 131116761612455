import { defineMessages } from 'react-intl';

export default defineMessages({
	HelperDocsCardtitle: {
		id: 'createPartner.HelperDocsCard.title',
		defaultMessage: 'Helper Docs',
		description: 'A title above a section where the user can add partner name & ID',
	},
	description: {
		id: 'createPartner.HelperDocsCard.description',
		defaultMessage:
			'For knowledge regarding B2B Subscriptions & a breakdown of this Accounts page, reference <helperdoclink> this document. </helperdoclink>',
		description: 'A description of how how to view helper docs.',
	},
});
