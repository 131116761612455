import { ReactElement, useState } from 'react';
import { useIntl } from 'react-intl';

import { Loader, FormInput } from '@calm-web/design-system';

import { TerminateResult, useTerminateSubscriptionPreview } from '@/hooks/api/useSubscription';
import { ApiError, CalmError, isCalmError } from '@/utils/apiRequest/errors';
import { currencyStringFromCents, formatDate } from '@/utils/helpers';

import messages from './messages';
import {
	ConfirmationText,
	ErrorMessage,
	LoaderContainer,
	LoadingText,
	SuccessIcon,
	SuccessText,
	TextWrapper,
	WarningIcon,
} from './styles';

interface TerminateTextVars {
	cycleStartDate: string;
	originalAmount: string;
	monthsIntoTheCycle: number;
	individualSubscriptionsQuantity: number;
	individualSubscriptionsExpirationDate: string;
}

interface MessageVars {
	[key: string]: string | number | undefined;
}

interface ErrorMessageType {
	id: string;
	defaultMessage: string;
	description: string;
}

const extractTerminateTextVars = (apiResult: TerminateResult): TerminateTextVars => {
	return {
		cycleStartDate: formatDate(new Date(apiResult.metadata.cycle_start_date)),
		originalAmount: currencyStringFromCents(apiResult.metadata.original_amount),
		monthsIntoTheCycle: apiResult.metadata.months_into_cycle,
		individualSubscriptionsQuantity: apiResult.metadata.individual_subscriptions_canceled,
		individualSubscriptionsExpirationDate: formatDate(
			new Date(apiResult.metadata.individual_subscriptions_expiration_date),
		),
	};
};

const getTerminatePreviewErrorMsg = (errorCode: string): ErrorMessageType => {
	const { errorAlreadyTerminated, terminatePreviewError } = messages;
	const terminated = errorCode === 'b2b_subscription_already_canceled';
	return terminated ? errorAlreadyTerminated : terminatePreviewError;
};

const getStripeErrMsg = (error: CalmError): string | undefined => {
	return error.data.error.info.stripe_message ? `${error.data.error.info.stripe_message}` : undefined;
};

interface TerminateModalContentProps {
	isTerminating: boolean;
	partnerId: string;
	terminateError?: Error | ApiError | CalmError;
	terminateResult?: TerminateResult;
	setTerminateDisabled: (state: boolean) => void;
}
export const TerminatePlanModalContent = ({
	isTerminating,
	partnerId,
	terminateError,
	terminateResult,
	setTerminateDisabled,
}: TerminateModalContentProps): ReactElement => {
	const { formatMessage } = useIntl();
	const [confirmString, setConfirmString] = useState('');

	const {
		loading: isLoadingTerminatePreview,
		error: terminatePreviewError,
		data: terminatePreviewResult,
	} = useTerminateSubscriptionPreview(partnerId);

	// loading terminate preview
	if (isLoadingTerminatePreview) {
		return (
			<div>
				<LoaderContainer>
					<Loader color="black" />
				</LoaderContainer>
				<LoadingText>{formatMessage(messages.loadingPreview)}</LoadingText>
			</div>
		);
	}

	// processing terminate request
	if (isTerminating) {
		return (
			<div>
				<LoaderContainer>
					<Loader color="black" />
				</LoaderContainer>
				<LoadingText>{formatMessage(messages.loading)}</LoadingText>
			</div>
		);
	}

	// terminate error
	if (terminateError) {
		const stripeErrMsg = isCalmError(terminateError) ? getStripeErrMsg(terminateError) : null;

		return (
			<>
				<WarningIcon />
				<ErrorMessage>
					{formatMessage(messages.terminateError)}
					{stripeErrMsg}
				</ErrorMessage>
			</>
		);
	}

	// preview terminate error
	if (terminatePreviewError) {
		const errorMessage = isCalmError(terminatePreviewError)
			? getTerminatePreviewErrorMsg(terminatePreviewError?.data?.error.code)
			: messages.terminatePreviewError;
		const stripeErrMsg = isCalmError(terminatePreviewError) ? getStripeErrMsg(terminatePreviewError) : null;
		return (
			<>
				<WarningIcon />
				<ErrorMessage>
					{formatMessage(errorMessage)}
					{stripeErrMsg}
				</ErrorMessage>
			</>
		);
	}

	// terminate has completed
	if (terminateResult) {
		return (
			<>
				<SuccessIcon />
				<SuccessText>
					{formatMessage(
						messages.hasTerminatedText,
						extractTerminateTextVars(terminateResult) as unknown as MessageVars,
					)}
				</SuccessText>
			</>
		);
	}

	// before pressing terminate button, preview of possible terminate
	return (
		<>
			<WarningIcon />
			{terminatePreviewResult && (
				<ConfirmationText>
					<TextWrapper>
						{formatMessage(
							messages.confirmText,
							extractTerminateTextVars(terminatePreviewResult) as unknown as MessageVars,
						)}
					</TextWrapper>
					<FormInput
						label="Type the word TERMINATE here"
						value={confirmString}
						onChange={e => {
							setConfirmString(e.target.value);
							setTerminateDisabled(e.target.value !== 'TERMINATE');
						}}
						name="terminate"
						data-testid="terminate-confirm-input"
						noValidation
					/>
				</ConfirmationText>
			)}
		</>
	);
};
