import styled from 'styled-components';

import { units } from '@calm-web/design-system';

export const Wrapper = styled.div`
	position: relative;
	width: 100%;
	margin-bottom: ${units(3)};

	img:not(:first-child) {
		margin-left: ${units(1)};
	}
`;

export const Icon = styled.img`
	height: ${units(3)};
`;
