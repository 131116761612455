import styled from 'styled-components';
import { palette } from 'styled-tools';

import { fontSize, FontSizes, fontWeight, FontWeights, PaletteColor } from '@calm-web/design-system';

export const BubbleContainer = styled.div`
	width: 500px;
	height: auto;
	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;
	align-self: center;
	max-width: 100vw;
`;

export const Bubble = styled.div<{
	isReady: boolean;
}>`
	width: 500px;
	height: auto;
	max-width: 100vw;

	svg path {
		fill: ${palette('blue2')};
	}
`;

export const BubbleCopy = styled.p<{ color?: PaletteColor }>`
	${fontWeight(FontWeights.Regular)};
	${fontSize(FontSizes.lg)};
	color: ${p => palette(p.color ? p.color : 'black')};
	position: absolute;
	align-self: center;
`;
