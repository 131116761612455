import { defineMessages } from 'react-intl';

export default defineMessages({
	healthConfigTitle: {
		id: 'account.healthConfigTitle',
		defaultMessage: 'Health Config',
		description: 'A title above a section where the Calm admin can edit the health config',
	},
	landingPageCustomizationSubtitle: {
		id: 'account.healthConfig.landingPageCustomizationSubtitle',
		defaultMessage:
			'A user acquisition landing page will be created for your population as a kicking off point for registration. Customizations are needed for this landing page.',
		description: 'A subtitle above the landing page customization section',
	},
	inAppSupportSubtitle: {
		id: 'account.healthConfig.inAppSupportSubtitle',
		defaultMessage:
			'The client (you) will be offering Tier 1 and Tier 2 support. Would you like to offer phone or email support or both?',
		description: 'A subtitle above the in app client support section',
	},
});
