import { defineMessages } from 'react-intl';

export default defineMessages({
	editCTA: {
		id: 'salesforceDetails.cta.edit',
		defaultMessage: 'edit',
		description: 'CTA Button copy to edit salesforce account of partner',
	},
	disconnectCTA: {
		id: 'salesforceDetails.cta.disconnect',
		defaultMessage: 'disconnect',
		description: 'CTA Button copy to disconnect salesforce account from partner',
	},
	modalConnectTitle: {
		id: 'salesforceDetails.modal.title.connect',
		defaultMessage: 'Add Salesforce Account ID',
		description: 'Modal title used when an account manager is connecting a Salesforce account.',
	},
	modalDisconnectTitle: {
		id: 'salesforceDetails.modal.title.disconnect',
		defaultMessage: 'Disconnect partner from Salesforce?',
		description:
			'Modal title used when an account manager is disconnecting the Salesforce account from the partner.',
	},
	notAssociated: {
		id: 'saleforceDetails.notAssociated',
		defaultMessage: 'This partner is not associated with a Salesforce account. Click below to get started.',
		description: 'Message when a partner has no salesforce account',
	},
	addSalesforceIdCTA: {
		id: 'saleforceDetails.addSalesforceIdCTA',
		defaultMessage: 'Add Salesforce ID',
		description: 'Button text prompting account managers to connect Salesforce ID',
	},
});
