import styled, { css } from 'styled-components';
import { palette, theme } from 'styled-tools';

import { Card, Text, FontWeights, units } from '@calm-web/design-system';

import InfoOutline from 'icons/info-outline.svg';
import PencilSvg from 'icons/pencil-outline.svg';

export const Wrapper = styled.div`
	background: ${palette('gradientCalmBusinessBrand')};
	padding: ${units(2, 4)};
`;

export const PencilIcon = styled(PencilSvg)``;
export const MessageButtonGroup = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1;
	display: flex;
	align-items: center;
`;

export const CustomMessageCard = styled(Card)`
	position: relative;
	margin-top: ${units(2)};

	${PencilIcon} {
		height: ${units(2)};
		width: ${units(2)};
	}
`;

export const EditWrapper = styled.div`
	margin-top: ${units(3)};
	display: flex;
	flex-direction: column;
	align-items: end;
`;

export const ButtonGroup = styled.div`
	display: flex;
	gap: ${units(1)};
`;

export const EmailTopBar = styled.div`
	background: ${palette('gradientCalmBrand')};
	padding: ${units(1, 2)};
	border-top-right-radius: ${units(2)};
	border-top-left-radius: ${units(2)};
`;

const ContentWrapperStyles = css`
	padding: ${units(2)};
	max-height: ${units(60)};
	overflow-y: auto;

	a {
		color: ${palette('blue2')};
		font-weight: ${FontWeights.Medium};
	}
`;
export const ContentWrapper = styled.div`
	${ContentWrapperStyles};
`;
export const CopyOnlyWrapper = styled.div`
	${ContentWrapperStyles};
	border-bottom: 1px solid ${palette('gray2')};
`;

export const EmailContainer = styled.div`
	background-color: ${palette('white')};
	border-radius: ${theme('modal.borderRadius')};
	border: 1px solid ${palette('gray1')};

	${ContentWrapper}:not(:last-child) {
		border-bottom: 1px solid ${palette('gray2')};
	}
`;

export const CustomMessageHeadline = styled(Text)`
	color: ${palette('gray5')};
	text-transform: uppercase;
	letter-spacing: 0.06em;
	line-height: 1.5;
`;

export const InfoIcon = styled(InfoOutline)`
	width: ${units(2.5)};
	height: ${units(2.5)};
	margin-left: ${units(0.5)};

	path {
		fill: ${palette('blue3')};
	}
`;
