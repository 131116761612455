import { IntegrationType } from '@/types/store/reducers';
import { isCalmHealthProductSKU } from '@/utils/SkuUtils';

import { ApiResponse } from './types';
import useHealthConfig from './useHealthConfig';
import { useOptionalPartner } from './usePartner';

export const useIntegrationType = (partnerId?: string): ApiResponse<IntegrationType> => {
	const optionalPartner = useOptionalPartner(partnerId);
	const partner = optionalPartner?.data;
	const isCalmHealth = partner && isCalmHealthProductSKU(partner.product_sku);
	const healthConfigResponse = useHealthConfig({
		partnerId: isCalmHealth ? partnerId : undefined,
	});
	const integrationType = isCalmHealth
		? healthConfigResponse.data?.integration_type
		: partner?.integration_type;
	return {
		data: integrationType,
		loading: optionalPartner?.loading || healthConfigResponse.loading,
		error: optionalPartner?.error ?? healthConfigResponse.error,
	};
};
