import { defineMessages } from 'react-intl';

export default defineMessages({
	progressBarSelection: {
		id: 'pathways.progressBar.selection',
		defaultMessage: '1. Choose a pathway',
		description: 'First step in progress bar',
	},
	progressBarAudience: {
		id: 'pathways.progressBar.audience',
		defaultMessage: '2. Choose Audience',
		description: 'Second step in progress bar',
	},
	progressBarSchedule: {
		id: 'pathways.progressBar.schedule',
		defaultMessage: '2. Choose the scheduling',
		description: 'Second step in progress bar',
	},
	progressBarScheduleCohorts: {
		id: 'pathways.progressBar.schedule',
		defaultMessage: '3. Choose the scheduling',
		description: 'Third step in progress bar',
	},
	progressBarReview: {
		id: 'pathways.progressBar.review',
		defaultMessage: '3. Review',
		description: 'Third step in progress bar',
	},
	progressBarReviewCohorts: {
		id: 'pathways.progressBar.review',
		defaultMessage: '4. Review',
		description: 'Fourth step in progress bar',
	},
	audienceSelected: {
		id: 'pathways.progressBar.audience.selected',
		defaultMessage: '{count} users',
		description: 'User count',
	},
});
