import { DateTime } from 'luxon';

import { dateFromIso8601DateForDatePicker } from '@/utils/helpers';
import { SegmentSelectState } from '@/utils/segments';

import { ApiResponse, RefetchOption } from '../types';
import { useMostRecentEndDate } from './useMostRecentEndDate';
import { ReportSignupsAndEngagementModular, useSignupsAndEngagement } from './useSignupsAndEngagement';

export function useBaseReport({
	partnerId,
	segmentSelection,
}: {
	partnerId: string;
	segmentSelection?: SegmentSelectState;
}): ApiResponse<ReportSignupsAndEngagementModular> & RefetchOption {
	const {
		data: endDateData,
		error: endDateError,
		loading: endDateLoading,
	} = useMostRecentEndDate({
		partnerId,
		segmentSelection,
	});
	const endDateToUse = endDateError
		? DateTime.local().minus({ days: 1 }).toJSDate()
		: endDateLoading || !endDateData
		? undefined
		: dateFromIso8601DateForDatePicker(endDateData);

	const startDate = endDateToUse
		? DateTime.fromJSDate(endDateToUse).minus({ weeks: 11, days: 6 }).toJSDate()
		: undefined;
	return useSignupsAndEngagement({
		partnerId,
		segmentSelection,
		startDate,
		endDate: endDateToUse,
	});
}
