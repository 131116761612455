import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import BackArrow from 'icons/back-arrow.svg';

import { StyledBackLink } from './styles';

export interface BackLinkDetails {
	url:
		| string
		| {
				pathname?: string | undefined;
				search?: string | undefined;
				hash?: string | undefined;
				key?: string | undefined;
		  };
	text: string;
}

export default function BackLink({ url, text }: BackLinkDetails): ReactElement {
	return (
		<Link data-testid="back-link" to={url}>
			<StyledBackLink
				backgroundColor="transparent"
				textColor="buttonSecondaryText"
				hideShadow
				Icon={BackArrow}
			>
				{text}
			</StyledBackLink>
		</Link>
	);
}
