import { defineMessages } from 'react-intl';

export default defineMessages({
	title: {
		id: 'salesforceDetails.title',
		defaultMessage: 'Salesforce',
		description: 'Title for Salesforce Details Section',
	},
	subtitle: {
		id: 'salesforceDetails.subtitle',
		defaultMessage: 'Current Salesforce Account ID',
		description: 'Subtitle for Salesforce Details Section',
	},
	inputInstructions: {
		id: 'salesforceDetails.account.input.instructions',
		defaultMessage:
			'Enter the Salesforce Account ID for this partner. It may be shared by more than one partner.',
		description: 'Instructional copy for Salesforce Account ID input field',
	},
	inputPlaceholder: {
		id: 'salesforceDetails.account.input.placeholder',
		defaultMessage: 'Salesforce Account ID',
		description: 'Placeholder copy for Salesforce Account ID input field',
	},
	requestFailureCopy: {
		id: 'salesforceDetails.account.error.copy',
		defaultMessage: 'Failed to return data from Salesforce. Please refresh or try again later.',
		description: 'Copy for error when Salesforce Account ID call fails for an unknown reason',
	},
	editCTA: {
		id: 'salesforceDetails.cta.edit',
		defaultMessage: 'edit',
		description: 'CTA Button copy to edit salesforce account of partner',
	},
	disconnectCTA: {
		id: 'salesforceDetails.cta.disconnect',
		defaultMessage: 'disconnect',
		description: 'CTA Button copy to disconnect salesforce account from partner',
	},
});
