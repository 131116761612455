import { ReactElement, ReactNode, useMemo } from 'react';
import { Link } from 'react-router-dom';

import {
	Card,
	FontSizes,
	Loader,
	PaletteColor,
	PrimaryButton,
	Text,
	TextElements,
} from '@calm-web/design-system';

import CellTitle from '@/components/ui/CellTitle';
import { useHealthSponsorships } from '@/hooks/api/useHealthSponsorships';
import { usePermissions } from '@/hooks/auth';
import { HealthSponsorship } from '@/types/health';
import { dateFromIso8601DateForDatePicker, utcToIso8601Date } from '@/utils/helpers';

import { SponsorshipDescription, EditLink, MetadataWrapper, Wrapper, DescriptionWrapper } from './styles';

function HealthSponsorshipMetadataTile({
	description,
	value,
	color = 'text',
	children,
}: {
	description: string;
	value: string;
	color?: PaletteColor;
	children?: ReactNode;
}): ReactElement {
	return (
		<div>
			<Text color="gray5" size={FontSizes.sm} el={TextElements.DescriptionTerm}>
				{description}
			</Text>
			<Text color={color} el={TextElements.DescriptionDetails}>
				{value}
			</Text>
			{children}
		</div>
	);
}

function HealthSponsorshipOverview({
	sponsorship,
	index,
}: {
	sponsorship: HealthSponsorship;
	index: number;
}): ReactElement {
	const isDefault = sponsorship.assignment_rules?.[0]?.operator === 'true';
	const [hasValidPermissions, actions] = usePermissions();
	const { isActive, startsAtFormatted, endsAtFormatted } = useMemo(() => {
		const startsAt = new Date(sponsorship.starts_at);
		const startsAtDateOnly = utcToIso8601Date(startsAt);
		const startsAtFormatted = dateFromIso8601DateForDatePicker(startsAtDateOnly).toLocaleDateString(
			undefined,
			{},
		);
		const endsAt = new Date(sponsorship.ends_at);
		const endsAtDateOnly = utcToIso8601Date(endsAt);
		const endsAtFormatted = dateFromIso8601DateForDatePicker(endsAtDateOnly).toLocaleDateString(
			undefined,
			{},
		);
		const now = new Date();
		const isActive = now > startsAt && now < endsAt;
		return { isActive, startsAtFormatted, endsAtFormatted };
	}, [sponsorship.starts_at, sponsorship.ends_at]);
	return (
		<Wrapper $isDefault={isDefault}>
			<MetadataWrapper>
				<HealthSponsorshipMetadataTile
					description={isDefault ? 'Default' : `Sponsor Group ${index + 1}`}
					value={sponsorship.display_name}
				>
					<DescriptionWrapper>
						{sponsorship.description ? (
							<SponsorshipDescription>{sponsorship.description}</SponsorshipDescription>
						) : null}
					</DescriptionWrapper>
				</HealthSponsorshipMetadataTile>
				<HealthSponsorshipMetadataTile description="Start Date" value={startsAtFormatted} />
				<HealthSponsorshipMetadataTile description="End Date" value={endsAtFormatted} />
				<HealthSponsorshipMetadataTile
					description="Status"
					value={isActive ? 'Active' : 'Inactive'}
					color={isActive ? 'success' : 'error'}
				>
					<Link
						to={`/${sponsorship.partner_id}/account/health-sponsorships/${sponsorship.id}`}
						component={EditLink}
					>
						{hasValidPermissions('health_sponsorship_display_name', [actions.UPDATE])
							? 'Edit Group Details'
							: 'View Group Details'}
					</Link>
				</HealthSponsorshipMetadataTile>
			</MetadataWrapper>
		</Wrapper>
	);
}

function HealthSponsorships({
	partnerId,
	onFocus,
}: {
	partnerId: string;
	onFocus: () => void;
}): ReactElement {
	const { data: healthSponsorships, loading } = useHealthSponsorships(partnerId);
	const [hasValidPermissions, actions] = usePermissions();
	return (
		<Card onFocus={onFocus} onClick={onFocus}>
			<CellTitle>Sponsor Group Configurations</CellTitle>
			{!healthSponsorships && loading ? (
				<Loader />
			) : (
				healthSponsorships?.map((sponsorship, index) => (
					<HealthSponsorshipOverview key={sponsorship.id} sponsorship={sponsorship} index={index} />
				))
			)}
			{hasValidPermissions('health_sponsorship_display_name', [actions.CREATE]) && (
				<Link to={`/${partnerId}/account/health-sponsorships`} component={PrimaryButton}>
					Create New Sponsor Group
				</Link>
			)}
		</Card>
	);
}

export default HealthSponsorships;
