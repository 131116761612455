import { rgba } from 'polished';
import styled from 'styled-components';
import { palette } from 'styled-tools';

import { fontWeight, FontWeights } from '@calm-web/design-system';
import { PlayFilled } from '@calm-web/icons';

export const ContentCardContainer = styled.a<{ $isPlayable: boolean }>`
	cursor: ${props => (props.$isPlayable ? 'pointer' : 'auto')};
	text-decoration: unset;
	width: 160px;
`;

export const ContentImageContainer = styled.div`
	height: 160px;
	width: 160px;
	overflow: hidden;
	position: relative;
	border-radius: 16px;
	margin-bottom: 8px;
`;

export const PlayButtonSvg = styled(PlayFilled)`
	height: 11px;
	width: 11px;
	margin-right: 4px;
`;

export const ContentDurationContainer = styled.div`
	position: absolute;
	bottom: 8px;
	left: 8px;
	background-color: ${(props): string => rgba(palette('black')(props), 0.6)};
	border-radius: 10px;
	padding: 4px 6px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`;

export const ContentDurationText = styled.p`
	color: white;
	${fontWeight(FontWeights.Demi)}
	font-size: 11px;
	line-height: 12px;
`;

export const ContentTitleContainer = styled.div`
	margin-bottom: 2px;
`;

export const ContentTitleText = styled.h2`
	color: ${(props): string => rgba(palette('black')(props), 0.8)};
	font-weight: 600;
	font-size: 14px;
	line-height: 19px;
`;

export const ContentDescriptionContainer = styled.div``;

export const ContentDescriptionText = styled.p`
	color: ${(props): string => rgba(palette('black')(props), 0.6)};
	${fontWeight(FontWeights.Medium)}
	font-size: 13px;
	line-height: 17px;
`;

export const ContentTextContainer = styled.div`
	margin-left: 4px;
`;
