import { ReactElement } from 'react';

export default function RoundedTopCornersPath(props: {
	x: number;
	y: number;
	width: number;
	height: number;
	fill: string;
}): ReactElement<SVGElement> {
	const { fill, x, y: inputY, width, height: inputHeight } = props;
	const minHeight = 1;
	const y = inputHeight > minHeight ? inputY : inputY - (minHeight - inputHeight);
	const height = Math.max(inputHeight, minHeight);
	const borderRadius = 3;
	const pathString = `M${x},${y + borderRadius}
  a${borderRadius},${borderRadius} 0 0 1 ${borderRadius},${-borderRadius}
  h${width - 2 * borderRadius}
  a${borderRadius},${borderRadius} 0 0 1 ${borderRadius},${borderRadius}
  v${height - borderRadius}
  h${-width} Z`;
	return <path d={pathString} fill={fill} />;
}
