import styled, { css } from 'styled-components';
import { palette, ifProp } from 'styled-tools';

import { minWidth, fontWeight, FontWeights, units } from '@calm-web/design-system';

import { SegmentSelectionStyled } from '../../../Reporting/SegmentationFilter/styles';

export const Wrapper = styled.div`
	${minWidth('desktop')} {
		margin-bottom: ${units(13)};
	}
`;

export const Tab = styled.div<{ isActive: boolean }>`
	background-color: ${palette('white')};
	border-radius: ${units(1)};
	padding: ${units(2)};
	margin-bottom: ${units(2)};
	text-align: center;

	${minWidth('mobileLarge')} {
		width: 13%;
	}

	${ifProp(
		'isActive',
		css`
			border: 2px solid ${palette('blue3')};
		`,
	)}
`;

export const TabHeader = styled.div`
	${fontWeight(FontWeights.Medium)};
`;

export const SegmentContainer = styled.div`
	background-color: ${palette('white')};
	border-radius: ${units(2)};
	padding: ${units(2)};
`;

export const SegmentDescription = styled.div`
	${fontWeight(FontWeights.Medium)};
	margin-bottom: ${units(2)};
`;

export const ResultsStyled = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-bottom: ${units(2)};
	${fontWeight(FontWeights.Medium)};
`;

export const SegmentListContainer = styled.div`
	border: 1px solid ${palette('gray1')};
	border-radius: 4px;
	margin-bottom: ${units(2)};

	${SegmentSelectionStyled} + ${SegmentSelectionStyled} {
		border-left: 1px solid ${palette('gray1')};
	}

	${minWidth('tablet')} {
		display: flex;
	}
`;
