import styled from 'styled-components';
import { palette, switchProp } from 'styled-tools';

import { units, FontWeights, maxWidth, FontSizes, fontSize, fontWeight } from '@calm-web/design-system';

import { SessionType } from '@/hooks/api/reporting/useSessionsReport';

import InfoOutline from 'icons/info-outline.svg';

export const TableHeader = styled.h3`
	${fontSize(FontSizes.lg)}
	width: 100%;
	text-align: center;
	${fontWeight(FontWeights.Regular)};
`;

export const TableExplainer = styled.div`
	${fontSize(FontSizes.sm)}
	width: 100%;
	text-align: center;
	color: ${palette('gray4')};
`;

export const InfoIconContainer = styled.div`
	display: inline;
	margin-left: ${units(1.5)};
`;

export const ExplainerInfo = styled(InfoOutline)`
	width: ${units(2.5)};
	height: ${units(2.5)};
	position: relative;
	top: 4px;

	path {
		fill: ${palette('blue3')};
	}
`;

export const BarChartContainer = styled.div`
	${fontSize(FontSizes.sm)};
	width: 100%;
	max-width: 2160px;
	margin: auto;

	${maxWidth('desktop')} {
		max-width: 100%;
	}
`;

export const TableTooltipTitle = styled.p`
	${fontSize(FontSizes.sm)};
	padding-bottom: ${units(1)};
`;

export const ContentTypeBadge = styled.div<{ type: SessionType }>`
	background: ${switchProp(
		'type',
		{
			sleep: palette('gradientSleepDark'),
			music: palette('gradientCalmBrand'),
			meditation: palette('gradientBrightRed'),
			masterclass: palette('gradientBrightOrange'),
			breathe: palette('gradientCalmPurple'),
			body: palette('gradientRainbow9'),
		},
		'transparent',
	)};
	width: 16px;
	height: 4px;
	display: inline-block;
	margin-right: ${units(1)};
	position: relative;
	top: -2px;
`;
