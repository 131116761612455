/* eslint-disable @next/next/no-img-element */
import { ReactElement } from 'react';

import {
	Content,
	ContentWrapper,
	FaqAnswer,
	FaqQuestion,
	Header,
	SectionBody,
	SectionHeader,
	Title,
	Updated,
} from '../styles';

export default function EmployeeFaq(): ReactElement {
	return (
		<div>
			<Header>
				<img
					src="https://res.cloudinary.com/calm-com/image/upload/v1623175960/assets/images/logos/calm_square_logo.png"
					alt="Calm Logo"
				/>
				<Title>Calm Business Employee FAQ</Title>
				<Updated>Last Updated: November 12, 2021</Updated>
			</Header>

			<ContentWrapper>
				<Content>
					<SectionHeader>Table of Contents</SectionHeader>
					<SectionBody>
						<ul>
							<li>
								<a href="#accessing">Accessing the Calm benefit</a>
							</li>
							<li>
								<a href="#security">Security and Privacy</a>
							</li>
							<li>
								<a href="#general">General Calm Questions</a>
							</li>
						</ul>
					</SectionBody>

					<SectionHeader id="accessing">Accessing the Calm benefit</SectionHeader>
					<SectionBody>
						<FaqQuestion>
							I signed up for Calm but am prompted to pay in the Calm app or the Premium content is locked?
						</FaqQuestion>
						<FaqAnswer>
							Confirm you visited your company’s unique Calm sign-up link on a web browser and activated the
							Calm benefit there, using your unique identifier (work email address, employee ID, etc.). You
							will also need to be logged in to the Calm app with the same Calm account credentials you used
							when activating the benefit through the unique Calm sign-up link.
						</FaqAnswer>

						<FaqQuestion>
							I received an “Oops! Something went wrong” error message while trying to redeem my Calm benefit?
						</FaqQuestion>
						<FaqAnswer>
							Please ensure that the unique identifier you are using to activate your Calm benefit matches
							with what is included on your company’s Eligibility File. We recommend checking for any typos or
							extra spaces included at the beginning or end of the email address or employee ID on file.
							<p />
							If you have double checked with your employer that the unique identifier used matches exactly
							with the uploaded Eligibility File, then please reach out directly for assistance. You can reach
							Calm’s Support team by going{' '}
							<a href="https://support.calm.com/hc/en-us?solvvyProvidedByEmployer" target="_new">
								here
							</a>{' '}
							and clicking on “Continue” in the pop-up box to access and fill out our contact form. If you run
							into any issues, please see{' '}
							<a href="https://support.calm.com/hc/en-us/articles/115002582888-Contact-Us" target="_new">
								this article
							</a>{' '}
							for more details on how to contact us. Please include your unique identifier and company’s
							unique Calm sign-up link, as well as a screenshot of your issue, when possible.
						</FaqAnswer>

						<FaqQuestion>
							I tried activating my Calm benefit, but am receiving the “Already redeemed” error message. Now
							what?
						</FaqQuestion>
						<FaqAnswer>
							Please log in to your existing Calm account to see if your subscription is active. If your
							Premium content is still locked, please reach out directly for assistance. You can reach Calm’s
							Support team by going{' '}
							<a href="https://support.calm.com/hc/en-us?solvvyProvidedByEmployer" target="_new">
								here
							</a>{' '}
							and clicking on “Continue” in the pop-up box to access and fill out our contact form. If you run
							into any issues, please see{' '}
							<a href="https://support.calm.com/hc/en-us/articles/115002582888-Contact-Us" target="_new">
								this article
							</a>{' '}
							for more details on how to contact us. Please include your unique identifier and company’s
							unique Calm sign-up link, as well as a screenshot of your issue, when possible.
						</FaqAnswer>

						<FaqQuestion>
							I already have a paid subscription on my Calm account, can I activate the Calm benefit through
							my employer?
						</FaqQuestion>
						<FaqAnswer>
							You can activate your Calm benefit on your existing Calm account as long as there is no renewing
							subscription currently active on your account. If you already have a renewing subscription, you
							can cancel auto-renewal on the subscription and then proceed with the activation of your Calm
							benefit. For step by step instructions, please view our Help Center article: How do I turn off
							auto-renewal or cancel my subscription? Please note that the benefit will apply on the Calm
							account as soon as it is activated, even if this overlaps with remaining time on a previous
							subscription that has been canceled.
						</FaqAnswer>

						<FaqQuestion>How do I download the Calm app?</FaqQuestion>
						<FaqAnswer>
							You can download the Calm app through one of the links below. For more information,{' '}
							<a
								href="https://support.calm.com/hc/en-us/articles/115002474147-On-what-type-of-device-can-I-use-Calm-"
								target="_new"
							>
								see here
							</a>
							.
							<li>
								<a href="https://play.google.com/store/apps/details?id=com.calm.android" target="_new">
									Android
								</a>
							</li>
							<li>
								<a
									href="https://itunes.apple.com/us/app/calm-meditate-relax-guided/id571800810?mt=8"
									target="_new"
								>
									iPhone
								</a>
							</li>
							<li>
								<a href="https://itunes.apple.com/us/app/calm.com/id571800810?ls=1&mt=8" target="_new">
									iPad
								</a>
							</li>
						</FaqAnswer>

						<FaqQuestion>Can I log into the Calm app on multiple devices?</FaqQuestion>
						<FaqAnswer>Yes, you can log into the Calm app on multiple devices.</FaqAnswer>

						<FaqQuestion>What happens to my Calm subscription if I leave my company?</FaqQuestion>
						<FaqAnswer>
							You can access your subscription information through the “Manage Subscription” page in your Calm
							app. To find this page in the app, tap “More” on the home screen. Next, choose the gear icon in
							the upper left corner and tap “Manage Subscription” to view details about your subscription
							status.
						</FaqAnswer>

						<FaqQuestion>Still need help?</FaqQuestion>
						<FaqAnswer>
							You can visit our Help Center at{' '}
							<a href="https://support.calm.com" target="_new">
								support.calm.com
							</a>{' '}
							to review our how-to articles. If you can’t find answers to your questions on the Help Center,
							please reach out for assistance with technical issues. You can reach Calm’s Support team by
							going{' '}
							<a href="https://support.calm.com/hc/en-us?solvvyProvidedByEmployer" target="_new">
								here
							</a>
							and clicking on “Continue” in the pop-up box to access and fill out our contact form. If you run
							into any issues, please see{' '}
							<a href="https://support.calm.com/hc/en-us/articles/115002582888-Contact-Us" target="_new">
								this article
							</a>{' '}
							for more details on how to contact us. Please include your unique identifier and company’s
							unique Calm sign-up link, as well as a screenshot of your issue.
						</FaqAnswer>
					</SectionBody>

					<SectionHeader id="security">Security & Privacy</SectionHeader>
					<SectionBody>
						<FaqQuestion>Does my employer know what Calm sessions I am listening to?</FaqQuestion>
						<FaqAnswer>No, individual information is not shared with your employer.</FaqAnswer>

						<FaqQuestion>Is partner employee data sent to 3rd parties?</FaqQuestion>
						<FaqAnswer>
							Please note, we do not sell user information to third parties, but rather we use third party
							services to analyze user data and optimize their experience.
							<p />
							For more information on Calm’s Privacy Policy, please see{' '}
							<a href="https://www.calm.com/en/privacy-policy" target="_new">
								here
							</a>
							.
						</FaqAnswer>
					</SectionBody>

					<SectionHeader id="general">General Calm Questions</SectionHeader>
					<SectionBody>
						<FaqQuestion>What should I start with in the Calm app?</FaqQuestion>
						<FaqAnswer>
							Here are a few of our Calm team’s favorite places to start in the Calm app:
							<li>
								Meditations: 7 Days of Calm Meditation, 3 min Body Scan Meditation, Daily Calm & Daily Trip
							</li>
							<li>Sleep Stories: The Nordland Night Train, Wonder with Matthew McConaughey</li>
							<li>
								Want to know more? Sign up for a weekly{' '}
								<a href="https://business.calm.com/calm-webinars" target="_new">
									Getting Started with Calm Workshop here
								</a>
							</li>
						</FaqAnswer>
						<FaqQuestion>Where is Calm available?</FaqQuestion>
						<FaqAnswer>
							<a href="https://www.calm.com" target="_new">
								The Calm website
							</a>{' '}
							is available everywhere you have a solid WiFi connection or data connection, and the Calm app is
							available for any device that can download apps from the Apple App Store or Google Play Store.
							<p />
							Please note that, as Google is currently not accessible in China, the Calm app is not available
							to download through the Google Play Store there. Calm is available for iOS devices through the
							Apple App Store and also through our website at www.calm.com for users in China.
						</FaqAnswer>

						<FaqQuestion>Which languages does Calm support?</FaqQuestion>
						<FaqAnswer>
							For an up-to-date list of countries Calm supports, see{' '}
							<a
								href="https://support.calm.com/hc/en-us/articles/360008697573-What-languages-are-available-on-Calm-"
								target="_new"
							>
								here
							</a>
							.
						</FaqAnswer>

						<FaqQuestion>
							What research/evidence can you share with us regarding the benefits of Calm for employees?
						</FaqQuestion>
						<FaqAnswer>
							For up-to-date information on Calm Science, see{' '}
							<a href="https://business.calm.com/resources/clinical-studies/" target="_new">
								here
							</a>
							.
						</FaqAnswer>
					</SectionBody>
				</Content>
			</ContentWrapper>
		</div>
	);
}
