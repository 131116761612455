import { useState } from 'react';
import { useDispatch } from 'react-redux';

import useForm, { FormProps, stringFromModelValue, validation } from '@calm-web/use-form';

import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import { useApi } from '@/hooks/api';
import { ApiResponse } from '@/hooks/api/types';
import { EapBenefit } from '@/hooks/api/useEap';
import { setBannerMessage } from '@/store/actions';
import { Partner } from '@/types/store/reducers';

export type EAPFormProps = FormProps<'theme' | 'url' | 'id'>;
export type ThemeOptions =
	| 'Fruits & Veggies'
	| 'Sunflowers'
	| 'Books'
	| 'Water & Hands'
	| 'Medical'
	| 'Beach';

interface UseEapFormResponse {
	formProps: EAPFormProps;
	isEapFormDirty: boolean;
	hasTouchedEapForm: boolean;
}

type UseSubmitEapFormResponse = [
	submitEapForm: (formProps: EAPFormProps, partner: Partner, benefits?: EapBenefit[]) => Promise<void>,
	state: ApiResponse<EapBenefit[]>,
];

type UseDeleteEapResponse = [deleteEap: (eapId?: string) => Promise<void>, state: ApiResponse<EapBenefit[]>];

const themeSelectOptions: ThemeOptions[] = [
	'Fruits & Veggies',
	'Sunflowers',
	'Books',
	'Water & Hands',
	'Medical',
	'Beach',
];

const themeImageUrls = {
	'Fruits & Veggies': '21d5d1d16af0cfc6acc5fae300e25ce5.png',
	Sunflowers: 'f4044bc259bf1153a36e13a8992905da.png',
	Books: '192b611805e83102e977dabdae38c02c.png',
	'Water & Hands': '477b358210ec61aaec1d7372086f9dbe.png',
	Medical: '60a26ee589a3c1c212d437bfde354ff2.png',
	Beach: '1817bbab76a27cba90800e7f1e099946.jpeg',
};

const getThemeUrl = (theme?: ThemeOptions): string => {
	const imageUrl = themeImageUrls[theme || 'Fruits & Veggies'];
	return `https://assets.calm.com/240/${imageUrl}`;
};

export const getThemeIdFromName = (theme: ThemeOptions): string => {
	if (!theme) return '';
	const imageUrl = themeImageUrls[theme || 'Fruits & Veggies'];
	return imageUrl.split('.')[0] || '';
};
const getThemeNameFromId = (id: string): ThemeOptions => {
	const themeName = Object.keys(themeImageUrls).find(key => themeImageUrls[key as ThemeOptions].includes(id));
	return (themeName as ThemeOptions) || 'Fruits & Veggies';
};

const useEapForm = (eapData?: EapBenefit[]): UseEapFormResponse => {
	const eapId = eapData?.[0]?.id ?? '';
	const eapUrl = eapData?.[0]?.url ?? '';
	const eapImageId = eapData?.[0]?.image_id ?? '';
	const themeName = getThemeNameFromId(eapImageId);

	const formProps = useForm('eapForm', {
		initialModel: {
			id: eapId,
			theme: themeName,
			url: eapUrl,
		},
		validation: {
			theme: validation.validateOrFail([
				{
					rules: [validation.required],
					errorResult: 'Please select a theme',
				},
			]),
			url: validation.validateOrFail([
				{
					rules: [validation.required, validation.validURLWithProtocolAndSlashes],
					errorResult:
						'URL is required for EAP promotion. Please provide a valid URL that begins with http:// or https://',
				},
			]),
		},
	});

	const isEapFormDirty = !!Object.values(formProps.dirtyState).some(value => value?.hasChanged);
	const hasTouchedEapForm = !!Object.values(formProps.dirtyState).some(value => value?.hasTouched);

	return { formProps, isEapFormDirty, hasTouchedEapForm };
};

const useSubmitEapForm = (): UseSubmitEapFormResponse => {
	const apiRequest = useApi();
	const { logEvent } = useAnalytics();
	const dispatch = useDispatch();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [newEapBenefit, setNewEapBenefit] = useState<EapBenefit[]>();

	const submitEapForm = async (
		formProps: EAPFormProps,
		partner: Partner,
		benefits?: EapBenefit[],
	): Promise<void> => {
		try {
			setIsSubmitting(true);
			const eapId = stringFromModelValue(formProps.model.id);
			const existingBenefit = benefits?.find(el => el.id === eapId);

			if (existingBenefit) {
				const res = await apiRequest({
					endpoint: `b2b/partners/${partner.id}/benefits`,
					method: 'PATCH',
					body: {
						benefits: [
							{
								id: existingBenefit.id,
								url: stringFromModelValue(formProps.model.url),
								image_id: getThemeIdFromName(stringFromModelValue(formProps.model.theme) as ThemeOptions),
								position: existingBenefit.position,
							},
						],
					},
				});
				const eapResp = res?.data?.benefits ?? res?.data;
				setNewEapBenefit(eapResp);
				logEvent('Partner : EAP Update : Success');
				formProps.resetAllDirtyStates();
			} else {
				const res = await apiRequest({
					endpoint: `b2b/partners/${partner.id}/benefits`,
					method: 'POST',
					body: {
						url: stringFromModelValue(formProps.model.url),
						image_id: getThemeIdFromName(stringFromModelValue(formProps.model.theme) as ThemeOptions),
					},
				});
				const eapResp = [res?.data];
				setNewEapBenefit(eapResp);
				logEvent('Partner : EAP Creation : Success');
				formProps.resetAllDirtyStates();
			}
			dispatch(
				setBannerMessage({
					message: 'Successfully updated EAP benefit',
					flash: true,
					isError: false,
				}),
			);
		} catch (error) {
			logEvent('Partner : EAP Update : Error');
			dispatch(
				setBannerMessage({
					message: 'Error saving EAP benefit',
					flash: true,
					isError: true,
				}),
			);
		} finally {
			setIsSubmitting(false);
		}
	};

	return [submitEapForm, { data: newEapBenefit, loading: isSubmitting }];
};

const useDeleteEap = (partner: Partner): UseDeleteEapResponse => {
	const apiRequest = useApi();
	const { logEvent } = useAnalytics();
	const dispatch = useDispatch();
	const [isSubmitting, setIsSubmitting] = useState(false);

	const deleteEap = async (benefitId?: string): Promise<void> => {
		if (!benefitId) return;
		try {
			setIsSubmitting(true);
			await apiRequest({
				endpoint: `b2b/partners/${partner.id}/benefits`,
				method: 'DELETE',
				body: {
					id: benefitId,
				},
			});

			logEvent('Partner : EAP Delete : Success');
			dispatch(
				setBannerMessage({
					message: 'Successfully deleted EAP benefit',
					flash: true,
					isError: false,
				}),
			);
		} catch (error) {
			logEvent('Partner : EAP Delete : Error');
			dispatch(
				setBannerMessage({
					message: 'Error deleting EAP benefit',
					flash: true,
					isError: true,
				}),
			);
		} finally {
			setIsSubmitting(false);
		}
	};

	return [deleteEap, { loading: isSubmitting }];
};

export { useEapForm, useSubmitEapForm, useDeleteEap, getThemeUrl, themeSelectOptions };
