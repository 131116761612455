/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

export default defineMessages({
	testSsoConfigTitle: {
		id: 'getStarted.sso.testSsoConfig.title',
		defaultMessage: 'Complete and test your SSO configuration.',
		description: 'title for the Get Started SSO section',
	},
	testSsoConfigSubtitle: {
		id: 'getStarted.sso.testSsoConfig.subtitle',
		defaultMessage:
			'Work with your IT admin and Calm Account Manager to complete and test your SSO configuration.',
		description: 'subtitle for the Get Started SSO section',
	},
	testSsoConfigButton: {
		id: 'getStarted.sso.testSsoConfig.button',
		defaultMessage: 'Configure SSO',
		description: 'button text for the Get Started SSO section',
	},
	announceSubtitle: {
		id: 'getStarted.sso.announce.subtitle',
		defaultMessage:
			'Share the exciting news with your employees. Copy your unique sign-up link, distribute a QR code to direct employees to your subscribe link, or get started with our copy-and-paste email template.',
		description: 'subtitle for the Get Started Announce section for SSO partners',
	},
	announceNoSsoUrl: {
		id: 'getStarted.sso.announce.noSsoUrl',
		defaultMessage: 'Complete your SSO configuration before announcing Calm to your team',
		description:
			'subtitle for the Get Started Announce section for SSO partners, but there is no URL ready yet',
	},
});
