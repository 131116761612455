import styled from 'styled-components';

import { maxWidth, units } from '@calm-web/design-system';

import PhoneOutlineSvg from 'icons/phone-outline.svg';

export const Wrapper = styled.div`
	position: relative;
	height: ${units(81.25)};
	width: ${units(37.5)};
	overflow: hidden;
	border-radius: ${units(5)};
	margin: 0 auto ${units(3)};

	${maxWidth('desktop')} {
		margin: 0 auto ${units(3)};
	}
`;

export const PhoneOutline = styled(PhoneOutlineSvg)`
	position: absolute;
	height: 100%;
`;

export const BottomActionBar = styled.div`
	position: absolute;
	bottom: 8px;
	padding: ${units(1)};
	background: white;
	width: calc(100% - 18.5px);
	display: flex;
	align-items: center;
	border-top: 2px solid #edecfb;
	left: 0;
	right: 0;
	margin: auto;
	border-bottom-right-radius: ${units(4)};
	border-bottom-left-radius: ${units(4)};
`;
