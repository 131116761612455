import { capitalize, head } from 'lodash';
import { ReactElement, useContext, useMemo } from 'react';

import { formatResizedUrl } from '@/components/pages/Pathways/ContentCard/utils';
import { ReportingContext } from '@/components/providers/ReportingContextProvider';
import { EnvConfig } from '@/env_config';
import { isSessionType, ReportSession, SessionType } from '@/hooks/api/reporting/useSessionsReport';
import { ReportTopContent } from '@/hooks/api/reporting/useTopContent';

import TopContentByCategory from '../../TopContentByCategory';
import {
	CellImage,
	ContentText,
	HeaderText,
	PurpleRectangle,
	RightAlign,
	FlexCenterAlign,
	TableRow,
	Table,
} from './styles';

function getProxyAsset(originalSrc: string): string {
	// Pull image at calm.com/assets with a proxy to assets.calm.com for html2canvas if feature flag is checked
	const replacement = originalSrc.replace('https://assets.calm.com', `${EnvConfig.partnerPortalUrl}/assets`);
	// If replacement fails, revert back to the original value
	if (!replacement.match(/\/assets\/\w+\.\w+/)) {
		return originalSrc;
	}
	return replacement;
}

function getOpacity(index: number): number {
	switch (index) {
		case 0:
			return 80;
		case 1:
			return 50;
		case 2:
			return 50;
		case 3:
			return 20;
		case 4:
			return 20;
		default:
			return 20;
	}
}

function TopContentTable({
	sessionData,
	contentData,
	isShowingSegmented,
}: {
	sessionData: ReportSession[];
	contentData: ReportTopContent;
	isShowingSegmented: boolean;
}): ReactElement {
	const chartData = useMemo(() => {
		try {
			const overallData = sessionData?.reduce(
				(acc: Record<SessionType, number>, datum) => {
					(Object.keys(datum) as (keyof typeof datum)[]).forEach(key => {
						acc[key] += datum[key];
					});
					return acc;
				},
				{
					body: 0,
					breathe: 0,
					masterclass: 0,
					meditation: 0,
					music: 0,
					sleep: 0,
				},
			);
			return (Object.keys(overallData) as (keyof typeof overallData)[])
				.map(dataKey => ({
					name: dataKey,
					value: overallData[dataKey],
					topContent: contentData[dataKey]?.sort((a, b) => b.play_count - a.play_count),
				}))
				.filter(dataKey => isSessionType(dataKey.name))
				.sort((a, b) => b.value - a.value);
		} catch (e) {
			return [];
		}
	}, [sessionData, contentData]);

	const { isReportDownloading } = useContext(ReportingContext);
	const maxValue = (head(chartData)?.value ?? 0) / 0.85;
	const breatheImageURL = 'https://assets.calm.com/db59b394f4d9b72da5d79f956aacbaf3.jpeg';

	return (
		<Table>
			<thead>
				<tr>
					<HeaderText $alignRight={true}>Category</HeaderText>
					<HeaderText>Total Sessions</HeaderText>
					{!isShowingSegmented && (
						<>
							<HeaderText>Most Played</HeaderText>
							<HeaderText>Narrator</HeaderText>
							<HeaderText>Top 10</HeaderText>
						</>
					)}
				</tr>
			</thead>
			<tbody>
				{chartData.map((data, index) => {
					const topContentElement = head(data.topContent);
					let imageUrl = topContentElement?.image.url ?? '';
					let title = topContentElement?.title ?? '';
					let narratorName = topContentElement?.narrator_name ?? '';
					if (data.name === 'breathe') {
						imageUrl = breatheImageURL;
						title = 'Breathe Bubble';
						narratorName = 'Calm';
					}
					return (
						<TableRow key={`${title} by ${narratorName} ${index}`}>
							<RightAlign>
								<ContentText>{capitalize(data.name)}</ContentText>
							</RightAlign>
							<td width={isShowingSegmented ? '95%' : '40%'}>
								<FlexCenterAlign>
									<PurpleRectangle $width={data.value / maxValue} $opacity={getOpacity(index)} />
									<ContentText>{data.value.toLocaleString()}</ContentText>
								</FlexCenterAlign>
							</td>
							{!isShowingSegmented && (
								<>
									<td width="25%">
										<FlexCenterAlign>
											<CellImage
												src={isReportDownloading ? getProxyAsset(imageUrl) : formatResizedUrl(imageUrl, 512)}
												width={4}
												alt={`Image of ${title}`}
											/>
											<ContentText>{title}</ContentText>
										</FlexCenterAlign>
									</td>
									<td width="15%">
										<ContentText>{narratorName}</ContentText>
									</td>
									<td width="15%">{data.name !== 'breathe' && <TopContentByCategory data={data} />}</td>
								</>
							)}
						</TableRow>
					);
				})}
			</tbody>
		</Table>
	);
}

export default TopContentTable;
