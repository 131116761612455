import { FC } from 'react';
import { useIntl } from 'react-intl';

import { Card } from '@calm-web/design-system';

import CellTitle, { Subtitle } from '@/components/ui/CellTitle';
import type { EditPartnerFormProps } from '@/hooks/forms/usePartnerForm';
import { IntegrationType, Partner } from '@/types/store/reducers';

import AccessCodesDescription from './AccessCodesDescription';
import CreatePartnerIntegrationSelector from './CreatePartnerIntegrationSelector';
import GroupCode from './GroupCode';
import IntegrationChanger from './IntegrationChanger';
import { integrationWasAccessCodes, SsoIntegrationManagement } from './SsoConversion';
import UserIdentifier from './UserIdentifier';
import messages from './messages';
import { StyledHr } from './styles';

export function willShowIntegrationDetails(props: {
	shouldShowIdentifier: boolean;
	isEdit: boolean;
	isAccountManager: boolean;
	formProps: EditPartnerFormProps;
	partner?: Partner;
	integrationType?: IntegrationType;
}): boolean {
	const { shouldShowIdentifier, isEdit, isAccountManager, formProps, partner } = props;
	const currentIntegrationType = props?.integrationType;
	const currentlySupportsEligibilityFile = partner?.supports_eligibility_list;
	const isSsoPartner = isEdit && currentIntegrationType === IntegrationType.SSO;
	const isFullySSO = isSsoPartner && !currentlySupportsEligibilityFile;
	return (
		isAccountManager ||
		(shouldShowIdentifier &&
			(formProps.model.integrationType === IntegrationType.ELIGIBILITY_FILE || (isSsoPartner && !isFullySSO)))
	);
}

function TransitionManagement({
	partner,
	integrationType,
	isFullySSO,
	formProps,
	onClickFinishSsoTransition,
	onClickCancelSsoTransition,
	isLoading,
	onClickChangeIntegrationType,
}: {
	formProps: EditPartnerFormProps;
	partner: Partner;
	integrationType: IntegrationType;
	onClickChangeIntegrationType: (newIntegrationType: IntegrationType) => Promise<void>;
	onClickFinishSsoTransition: () => void;
	onClickCancelSsoTransition: () => void;
	isFullySSO: boolean;
	isLoading: boolean;
}): ReturnType<FC> {
	if (integrationType === IntegrationType.SSO) {
		return (
			<SsoIntegrationManagement
				isFullySSO={isFullySSO}
				formProps={formProps}
				partner={partner}
				integrationType={integrationType}
				onClickFinishSsoTransition={onClickFinishSsoTransition}
				onClickCancelSsoTransition={onClickCancelSsoTransition}
				isLoading={isLoading}
			/>
		);
	}
	return (
		<IntegrationChanger
			partner={partner}
			integrationType={integrationType}
			onClickChangeIntegrationType={onClickChangeIntegrationType}
			isLoading={isLoading}
			formProps={formProps}
		/>
	);
}

export default function IntegrationDetails({
	shouldShowIdentifier,
	isEdit,
	isAccountManager,
	formProps,
	partner,
	integrationType,
	onClickChangeIntegrationType,
	onClickFinishSsoTransition,
	onClickCancelSsoTransition,
	isLoading,
	isDTCLeadGenPartner = false,
}: {
	shouldShowIdentifier: boolean;
	isEdit: boolean;
	isAccountManager: boolean;
	formProps: EditPartnerFormProps;
	partner?: Partner;
	integrationType?: IntegrationType;
	onClickChangeIntegrationType: (newIntegrationType: IntegrationType) => Promise<void>;
	onClickFinishSsoTransition: () => void;
	onClickCancelSsoTransition: () => void;
	isLoading: boolean;
	isDTCLeadGenPartner?: boolean;
}): ReturnType<FC> {
	const { formatMessage } = useIntl();

	const currentIntegrationType = integrationType;
	const currentlySupportsEligibilityFile = partner?.supports_eligibility_list;
	const isSsoPartner = isEdit && currentIntegrationType === IntegrationType.SSO;
	const isFullySSO = isSsoPartner && !currentlySupportsEligibilityFile;

	if (
		!willShowIntegrationDetails({
			shouldShowIdentifier,
			isEdit,
			isAccountManager,
			formProps,
			partner,
		})
	) {
		return null;
	}

	if (isAccountManager) {
		if (!partner) {
			return (
				<Card>
					<CellTitle showAdminFlag>{formatMessage(messages.integrationTypeTitle)}</CellTitle>
					<CreatePartnerIntegrationSelector
						formProps={formProps}
						currentIntegrationType={currentIntegrationType}
						isDTCLeadGenPartner={isDTCLeadGenPartner}
					/>
				</Card>
			);
		}

		const showAsEligibilityFile =
			integrationType === IntegrationType.ELIGIBILITY_FILE ||
			(integrationType === IntegrationType.SSO &&
				partner.supports_eligibility_list &&
				!integrationWasAccessCodes(formProps));
		if (showAsEligibilityFile) {
			return (
				<Card>
					<CellTitle showAdminFlag>{formatMessage(messages.eligibilityFileLabel)}</CellTitle>
					<UserIdentifier isEdit={isEdit} formProps={formProps} disabled={false} />
					<StyledHr marginTop={2} marginBottom={2} />
					<TransitionManagement
						partner={partner}
						integrationType={integrationType}
						isFullySSO={isFullySSO}
						formProps={formProps}
						onClickFinishSsoTransition={onClickFinishSsoTransition}
						onClickCancelSsoTransition={onClickCancelSsoTransition}
						isLoading={isLoading}
						onClickChangeIntegrationType={onClickChangeIntegrationType}
					/>
				</Card>
			);
		}

		const showAsAccessCodes =
			integrationType === IntegrationType.ACCESS_CODES ||
			(integrationType === IntegrationType.SSO &&
				partner.supports_eligibility_list &&
				integrationWasAccessCodes(formProps));
		if (showAsAccessCodes) {
			return (
				<Card>
					<CellTitle showAdminFlag>{formatMessage(messages.accessCodesLabel)}</CellTitle>
					<AccessCodesDescription />
					<StyledHr marginTop={2} marginBottom={2} />
					<TransitionManagement
						partner={partner}
						integrationType={integrationType}
						isFullySSO={isFullySSO}
						formProps={formProps}
						onClickFinishSsoTransition={onClickFinishSsoTransition}
						onClickCancelSsoTransition={onClickCancelSsoTransition}
						isLoading={isLoading}
						onClickChangeIntegrationType={onClickChangeIntegrationType}
					/>
				</Card>
			);
		}

		if (integrationType === IntegrationType.GROUP_CODE) {
			return (
				<Card>
					<CellTitle showAdminFlag>{formatMessage(messages.groupCodesLabel)}</CellTitle>
					<Subtitle>{formatMessage(messages.groupCodesSubtitle)}</Subtitle>
					<GroupCode formProps={formProps} isEdit={isEdit} showExplanation={false} />
					<StyledHr marginTop={2} marginBottom={2} />
					<TransitionManagement
						partner={partner}
						integrationType={integrationType}
						isFullySSO={isFullySSO}
						formProps={formProps}
						onClickFinishSsoTransition={onClickFinishSsoTransition}
						onClickCancelSsoTransition={onClickCancelSsoTransition}
						isLoading={isLoading}
						onClickChangeIntegrationType={onClickChangeIntegrationType}
					/>
				</Card>
			);
		}

		if (integrationType === IntegrationType.SSO) {
			return (
				<Card>
					<CellTitle showAdminFlag>
						{formatMessage(
							// eslint-disable-next-line no-nested-ternary
							isFullySSO
								? messages.ssoConfigurationTitle
								: integrationWasAccessCodes(formProps)
								? messages.accessCodesLabel
								: messages.eligibilityFileLabel,
						)}
					</CellTitle>
					{!isFullySSO ? (
						<>
							<UserIdentifier isEdit={isEdit} formProps={formProps} disabled={false} />
							<StyledHr marginTop={2} marginBottom={2} />
						</>
					) : null}
					<TransitionManagement
						partner={partner}
						integrationType={integrationType}
						isFullySSO={isFullySSO}
						formProps={formProps}
						onClickFinishSsoTransition={onClickFinishSsoTransition}
						onClickCancelSsoTransition={onClickCancelSsoTransition}
						isLoading={isLoading}
						onClickChangeIntegrationType={onClickChangeIntegrationType}
					/>
				</Card>
			);
		}
	}

	if (integrationType === IntegrationType.GROUP_CODE) {
		return (
			<Card>
				<CellTitle>{formatMessage(messages.groupCodesLabel)}</CellTitle>
				<Subtitle>{formatMessage(messages.groupCodesSubtitle)}</Subtitle>
				<GroupCode formProps={formProps} isEdit={isEdit} showExplanation={false} />
			</Card>
		);
	}

	return (
		<Card>
			<CellTitle>{formatMessage(messages.userIdTitle)}</CellTitle>
			<UserIdentifier isEdit={isEdit} formProps={formProps} />
		</Card>
	);
}
