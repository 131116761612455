import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { setBannerMessage } from '@/store/actions';
import { isSelfServePlan } from '@/utils/SkuUtils';

import messages from './messages';
import { BannerTextButton } from './styles';
import { useNavToPlanPageAndOpenUpdatePaymentDetailsModal } from './useNavToPlanPageAndOpenUpdatePaymentDetailsModal';
import { useDefinedPartner } from './usePartner';
import { usePaymentInfo } from './usePaymentInfo';

type AchModalKey = 'PROCESSING' | 'SUCCEEDED' | null;

interface UseActivateAchPaymentMethodLifeCycleManagement {
	isLoading: boolean;
	ui: {
		paymentDetails: {
			error: string | undefined;
		};
		banner: {
			error: ReturnType<ReturnType<typeof useIntl>['formatMessage']> | undefined;
			pendingCopy: ReturnType<ReturnType<typeof useIntl>['formatMessage']> | undefined;
		};
		modal: {
			title: ReturnType<ReturnType<typeof useIntl>['formatMessage']> | undefined;
			bodyText: ReturnType<ReturnType<typeof useIntl>['formatMessage']> | undefined;
			buttonText: ReturnType<ReturnType<typeof useIntl>['formatMessage']> | undefined;
			showConfetti: boolean;
		};
	};
	achModalKey: AchModalKey;
}

export const useActivateAchPaymentMethodLifeCycleManagement =
	(): UseActivateAchPaymentMethodLifeCycleManagement => {
		const { formatMessage } = useIntl();
		const { data: paymentInfo, isLoading: isLoadingPaymentInfo } = usePaymentInfo();
		const [bannerError, setAchPaymentMethodActivationBannerError] =
			useState<UseActivateAchPaymentMethodLifeCycleManagement['ui']['paymentDetails']['error']>();
		const [error, setAchPaymentMethodActivationError] =
			useState<UseActivateAchPaymentMethodLifeCycleManagement['ui']['paymentDetails']['error']>();
		const [pendingCopy, setPendingCopy] =
			useState<UseActivateAchPaymentMethodLifeCycleManagement['ui']['banner']['pendingCopy']>();
		const [isLoading, setIsLoading] = useState<boolean>(true);
		const [modalInfo, setModalInfo] = useState<UseActivateAchPaymentMethodLifeCycleManagement['ui']['modal']>(
			{
				title: undefined,
				bodyText: undefined,
				buttonText: formatMessage(messages.achButtonText),
				showConfetti: false,
			},
		);
		const [achModalKey, setAchModalKey] = useState<AchModalKey>(null);
		const dispatch = useDispatch();
		const partner = useDefinedPartner();
		const navToPlanPageAndOpenUpdatePaymentDetailsModal =
			useNavToPlanPageAndOpenUpdatePaymentDetailsModal(false);

		useEffect(() => {
			const getActivateAchPaymentMethodLifeCycleInfo = (): void => {
				try {
					if (!isSelfServePlan(partner.vouched_plan_sku) || !paymentInfo) {
						return;
					}
					setIsLoading(true);
					setAchPaymentMethodActivationError(undefined);
					setPendingCopy(undefined);
					setAchModalKey(null);

					if (paymentInfo?.type !== 'us_bank_account') {
						return;
					}

					const intentStatus = paymentInfo.intent_status;
					const errorReason = paymentInfo.last_intent_error;

					if (intentStatus === 'requires_payment_method' && errorReason) {
						if (errorReason === 'insufficient_funds') {
							setAchPaymentMethodActivationBannerError(
								formatMessage(messages.achPaymentMethodInsufficientFundsBannerError, {
									changedetails: (...chunks: React.ReactNode[]) => (
										<BannerTextButton onClick={navToPlanPageAndOpenUpdatePaymentDetailsModal}>
											{chunks}
										</BannerTextButton>
									),
								}) as string,
							);
							setAchPaymentMethodActivationError(
								formatMessage(messages.achPaymentMethodInsufficientFundsError),
							);
						} else {
							setAchPaymentMethodActivationBannerError(
								formatMessage(messages.achPaymentMethodProcessingFailedBannerError, {
									changedetails: (...chunks: React.ReactNode[]) => (
										<BannerTextButton onClick={navToPlanPageAndOpenUpdatePaymentDetailsModal}>
											{chunks}
										</BannerTextButton>
									),
								}) as string,
							);
							setAchPaymentMethodActivationError(
								formatMessage(messages.achPaymentMethodProcessingFailedError),
							);
						}
						setAchModalKey(null);
					} else if (intentStatus === 'processing') {
						setPendingCopy(formatMessage(messages.achPaymentMethodProcessing));
						setModalInfo({
							title: formatMessage(messages.achProcessingTitle),
							bodyText: formatMessage(messages.achProcessingBody, {
								newline: () => <br />,
								b: chunks => <strong>{chunks}</strong>,
							}),
							buttonText: formatMessage(messages.achButtonText),
							showConfetti: false,
						});
						setAchModalKey('PROCESSING');
					} else if (intentStatus === 'succeeded') {
						setModalInfo({
							title: formatMessage(messages.achSucceededTitle),
							bodyText: formatMessage(messages.achSucceededBody, {
								newline: () => <br />,
								b: chunks => <strong>{chunks}</strong>,
							}),
							buttonText: formatMessage(messages.achSucceededButtonText),
							showConfetti: true,
						});
						setAchModalKey('SUCCEEDED');
					}
				} catch (err) {
					dispatch(
						setBannerMessage({
							message: formatMessage(messages.getPaymentInfoError),
							isError: true,
							flash: true,
						}),
					);
					throw err;
				} finally {
					setIsLoading(false);
				}
			};

			if (!isLoadingPaymentInfo) {
				getActivateAchPaymentMethodLifeCycleInfo();
			}
		}, [
			paymentInfo,
			dispatch,
			formatMessage,
			partner,
			navToPlanPageAndOpenUpdatePaymentDetailsModal,
			isLoadingPaymentInfo,
		]);

		return {
			isLoading,
			ui: {
				paymentDetails: {
					error,
				},
				banner: {
					error: bannerError,
					pendingCopy,
				},
				modal: modalInfo,
			},
			achModalKey,
		};
	};
