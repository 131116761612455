import { rgba } from 'polished';
import styled, { css } from 'styled-components';
import { ifProp, palette, theme } from 'styled-tools';

import {
	FontSizes,
	IconButton,
	TextButton,
	Button,
	maxWidth,
	fontSize,
	fontWeight,
	FontWeights,
	units,
} from '@calm-web/design-system';

import ChartPreview from 'icons/chart-preview.svg';
import Chart from 'icons/chart.svg';
import CreditCard from 'icons/credit-card.svg';
import Gear from 'icons/gear-outline.svg';
import GiftFilled from 'icons/gift-icon-simple.svg';
import Gift from 'icons/gift-outline.svg';
import Help from 'icons/help.svg';
import HomeFilled from 'icons/home-filled.svg';
import Lightning from 'icons/lightning-outline.svg';
import Pathways from 'icons/pathways.svg';
import Profile from 'icons/profile.svg';
import Tos from 'icons/tos.svg';

export const NavWrapper = styled.div<{ $withLeftNav?: boolean }>`
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	${maxWidth('desktop')} {
		overflow: hidden;
	}
`;

export const Background = styled.div<{ $isHomePage?: boolean }>`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	background-color: ${palette('gray0')};
`;

export const LogoContainer = styled.div`
	display: flex;
	flex: 1;
	margin: ${units(0, 3)};
`;

export const NavLeft = styled.div`
	width: ${units(42)};
	background: ${palette('white')};
	color: ${palette('gray8')};
	border-right: 1px solid ${palette('blackAlpha5')};
	padding: ${units(3)};
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	nav {
		display: grid;
		grid-gap: ${units(3)};
	}
	overflow-y: auto;
	position: fixed;
	height: 100%;
`;

export const PageHeader = styled.h3``;

export const MainContent = styled.main<{
	fullWidth?: boolean;
	navTopOffsetUnits: number;
}>`
	display: flex;
	flex-direction: column;
	padding: ${units(0, 3, 3)};
	overflow-y: auto;
	${ifProp(
		'fullWidth',
		css`
			width: 100%;
			max-width: ${theme('layout.mainMaxWidth')};
			margin-left: auto;
			margin-right: auto;
		`,
	)};
`;

export const LogOutLink = styled(TextButton).attrs({ size: FontSizes.sm, hideUnderline: true })``;

export const NavElementWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	text-align: right;
`;

export const LoggedInEmail = styled.div`
	${fontWeight(FontWeights.Medium)};
	${fontSize(FontSizes.sm)};
	text-align: right;
`;

export const LoggedInWrapper = styled(NavElementWrapper)`
	display: block;

	${maxWidth('desktop')} {
		display: flex;
		flex-wrap: nowrap;
		gap: ${units(2)};
	}
`;

export const NavTop = styled.nav<{ withBorder: boolean }>`
	box-sizing: border-box;
	z-index: ${theme('layout.navIndex')};
	border-bottom: 1px solid ${ifProp('withBorder', palette('gray1'), 'transparent')};
	transition: border-color 0.3s ease;
	position: sticky;
	top: 0;
	background-color: ${palette('gray0')};

	${maxWidth('desktop')} {
		position: unset;
	}
`;

export const ContentWrapper = styled.div<{ $withLeftNav?: boolean }>`
	display: block;
	${props =>
		props.$withLeftNav
			? css`
					width: calc(100% - ${units(42)});
					position: absolute;
					left: ${units(42)};
			  `
			: css`
					width: 100vw;
					height: 100dvh;
					display: grid;
					grid-template-columns: 1fr;
					grid-template-rows: auto 1fr 100px;
			  `};
`;

export const NavTopContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: ${theme('navigation.padding')};

	${maxWidth('desktop')} {
		flex-wrap: wrap;
		gap: ${units(1)};
	}
`;

export const NavRightWrapper = styled.div`
	display: flex;
`;

export const SupportIcon = styled(Help)`
	path {
		fill: ${palette('blue3')};
	}
	width: ${units(3)};
	height: ${units(3)};
	margin-right: ${units(2)};
`;

export const SupportLink = styled.a`
	color: ${palette('blue3')};
	${fontWeight(FontWeights.Medium)};
	${fontSize(FontSizes.base)};
	text-decoration: none;
`;

export const NavLeftBottom = styled.div`
	align-items: center;
	margin: ${units(2, 0)};
`;

export const Support = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: ${units(2, 0)};
	margin: ${units(0, 2)};
	width: 100%;
`;

export const WorkshopsBannerContainer = styled.div`
	width: 100%;
	background: ${p => rgba(palette('black')(p), 0.9)};
	padding: ${units(3)};
	border-radius: ${units(1)};
	display: flex;
	flex-wrap: wrap;
	color: ${palette('white')};
	margin-bottom: ${units(2)};
`;

export const WorkshopsPromoImg = styled.img`
	width: 100%;
	height: auto;
	position: relative;
`;

export const WorkshopsText = styled.div`
	${fontWeight(500)};
	line-height: 1.5;
	margin: ${units(2)} 0;
	padding-right: ${units(2)};

	> span {
		color: ${palette('gold')};
	}
`;

export const WorkshopsButton = styled(Button).attrs({
	backgroundColor: 'gold',
	textColor: 'gray8',
})`
	width: 100%;
`;

const navIconCss = css`
	width: ${units(3)};
	height: ${units(3)};
	margin-right: ${units(2)};
	path {
		fill: ${palette('blue3')};
	}
`;

export const HomeIcon = styled(HomeFilled)`
	${navIconCss};
`;

export const GetSetUpIcon = styled(Lightning)`
	${navIconCss};
`;

export const UsersIcon = styled(Profile)`
	${navIconCss};
`;

export const AccountIcon = styled(Gear)`
	${navIconCss};
`;

export const PlanAndPaymentIcon = styled(CreditCard)`
	${navIconCss};
`;

export const ReportingIcon = styled(Chart)`
	${navIconCss};
`;

export const ReportingPreviewIcon = styled(ChartPreview)`
	${navIconCss};
`;

export const ReferralIcon = styled(Gift)`
	${navIconCss};
`;

export const ResourcesIcon = styled(Tos)`
	${navIconCss};
`;

export const PathwaysIcon = styled(Pathways)`
	${navIconCss};
`;

export const GiftButton = styled(IconButton).attrs({ color: 'buttonSecondaryText' })`
	padding: 0;
	margin-left: ${units(1)};
`;

export const GiftIcon = styled(Gift)`
	width: ${units(3)};
	height: ${units(3)};
	path {
		fill: ${palette('blue3')};
	}
`;

export const GiftIconGradient = styled(GiftFilled)`
	width: ${units(3)};
	height: ${units(3)};
`;

export const ImageContainer = styled.div`
	position: relative;
	height: 47px;
	width: 78.3px;
`;

export const PathwaysText = styled.span`
	margin-right: ${units(1)};
`;

export const MainContainer = styled.div`
	display: flex;
`;
