import styled, { keyframes, css } from 'styled-components';

import { units } from '@calm-web/design-system';

interface SubmitProps {
	isFormDirty: boolean;
	hasTouchedForm: boolean;
	accountForSecondButton: boolean;
}

const slideUpAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideDownAnimation = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
  }
`;

export const SubmitWrapper = styled.div<SubmitProps>`
	// Default the button off screen since can't transition to display:none
	transform: translateY(150px);
	display: flex;
	justify-content: center;
	opacity: ${props => (props.isFormDirty ? 1 : 0)};
	width: max-content;
	position: fixed;
	z-index: 2;
	bottom: ${props => (props.accountForSecondButton ? units(12) : units(3))};
	left: 0;
	right: 0;
	background: rgba(255, 255, 255, 0.8);
	box-shadow: 0px 8px 16px rgb(0 0 0 / 15%);
	border-radius: 8px;
	padding: 8px;
	margin: 0 auto ${units(3)};
	${props =>
		props.isFormDirty
			? css`
					animation: ${slideUpAnimation} 700ms ease-in-out forwards;
			  `
			: props.hasTouchedForm &&
			  css`
					animation: ${slideDownAnimation} 700ms ease-in-out forwards;
			  `};
`;
