import { ReactElement, useState } from 'react';
import { useIntl } from 'react-intl';

import { Button, Card, Checkbox, FontWeights, Modal, Text } from '@calm-web/design-system';
import { booleanFromCheckbox } from '@calm-web/use-form';

import CellSubtitle from '@/components/ui/CellSubtitle';
import CellTitle from '@/components/ui/CellTitle';
import type { EditPartnerFormProps } from '@/hooks/forms/usePartnerForm';

import messages from './messages';
import { ButtonWrapper, ModalContent, ModalText } from './styles';

interface Props {
	formProps: EditPartnerFormProps;
}

export default function PartnerChurn({ formProps }: Props): ReactElement {
	const { formatMessage } = useIntl();
	const [modalIsOpen, openModal] = useState(false);
	const hasChurned = booleanFromCheckbox(formProps.model.hasChurned, 'churned');
	return (
		<Card>
			<CellTitle showAdminFlag>{formatMessage(messages.title)}</CellTitle>
			<CellSubtitle>{formatMessage(messages.description)}</CellSubtitle>
			<Checkbox
				checked={hasChurned}
				value="churned"
				id="churned"
				name="churned"
				onChange={() => {
					openModal(true);
				}}
			>
				{formatMessage(messages.churned)}
			</Checkbox>
			{modalIsOpen && (
				<Modal
					isOpen={modalIsOpen}
					closeModal={() => openModal(false)}
					canClose={false}
					aria-label={formatMessage(messages.modalTitle)}
				>
					<ModalContent>
						<Text weight={FontWeights.Medium}>
							{hasChurned
								? formatMessage(messages.modalUnchurnedQuestion)
								: formatMessage(messages.modalChurnedQuestion)}
						</Text>
						<ModalText>
							{hasChurned
								? formatMessage(messages.modalUnchurnedDescription)
								: formatMessage(messages.modalChurnedDescription)}
						</ModalText>
						<ButtonWrapper>
							<Button backgroundColor="gray2" onPress={() => openModal(false)} type="button">
								{formatMessage(messages.modalCancelButton)}
							</Button>
							<Button
								backgroundColor="blue3"
								onPress={() => {
									formProps.setProperty('hasChurned', hasChurned ? [] : ['churned']);
									openModal(false);
								}}
								type="button"
							>
								{formatMessage(messages.modalYesButton)}
							</Button>
						</ButtonWrapper>
					</ModalContent>
				</Modal>
			)}
		</Card>
	);
}
