export type Service = 'partner-portal' | 'app-api' | 'www' | 'hermes-gateway';

function getUrl(service: Service): string | undefined {
	switch (service) {
		case 'app-api':
			return process.env.APP_API_URL;
		case 'www':
			return process.env.CALM_WWW_URL;
		case 'hermes-gateway':
			return process.env.HERMES_ENDPOINT;
		case 'partner-portal':
		default:
			return process.env.CALM_PARTNER_PORTAL_URL;
	}
}

export default getUrl;
