import styled from 'styled-components';

import { Text, FontSizes, TextElements, units, FontWeights } from '@calm-web/design-system';

export const TitleText = styled(Text).attrs({
	color: 'gray7',
	el: TextElements.H4,
	FontSize: FontSizes.lg,
	weight: FontWeights.Regular,
})`
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-shrink: 0;
	width: 100%;
	margin: 0 auto;
	padding: 0 ${units(2)};
`;

export const Paragraph = styled(Text).attrs({
	el: TextElements.P,
	weight: FontWeights.Medium,
})`
	margin-bottom: 1em;
	&:last-child {
		margin-bottom: 0;
	}
	line-height: 1.5;
	font-size: 14px;
	width: 100%;
	flex-direction: column;
	padding: ${units(2)} ${units(3)};
	margin: 0 auto;
`;

export const FooterContainer = styled.div`
	display: flex;
	justify-content: center;
	padding: 1rem 0;
	width: 100%;
	margin: 0 auto;
`;
