import { ReactElement } from 'react';

import { Card, RadioGroup } from '@calm-web/design-system';
import { BindWithErrorProps } from '@calm-web/use-form';

import CellTitle from '@/components/ui/CellTitle';
import { usePermissions } from '@/hooks/auth/usePermissions';

interface Props {
	lduProps: ReturnType<BindWithErrorProps<string>>;
	isEdit: boolean;
}

export default function LDU({ lduProps, isEdit }: Props): ReactElement | null {
	const [hasValidPermissions, actions] = usePermissions();
	const requiredPermissions = isEdit ? [actions.UPDATE] : [actions.CREATE];
	const shouldShowLdu = hasValidPermissions('is_ldu', requiredPermissions);
	if (!shouldShowLdu) {
		return null;
	}

	const options = [
		{
			value: true.toString(),
			id: 'LDU',
			label: 'Set Limited Data Usage',
		},
		{
			value: false.toString(),
			id: 'notLDU',
			label: 'Do not set Limited Data Usage',
		},
	];
	return (
		<Card>
			<CellTitle showAdminFlag>Limited Data Usage</CellTitle>
			<RadioGroup {...lduProps} options={options} asCards aria-label="Does the partner require LDU?" />
		</Card>
	);
}
