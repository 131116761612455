import { FC } from 'react';

import { useFeatureFlags } from '@/hooks/api/useFeatureFlags';
import { useIntegrationType } from '@/hooks/api/useIntegrationType';
import { usePartner } from '@/hooks/api/usePartner';
import { useCalmHealthSftpIntegration } from '@/hooks/api/useSftpConfig';
import { IntegrationType } from '@/types/store/reducers';

import MobileLink from './MobileLink';
import {
	AccountIcon,
	HomeIcon,
	PathwaysIcon,
	ReportingIcon,
	StyledMobileNav,
	UsersIcon,
	Wrapper,
} from './styles';

interface Props {
	partnerId: string;
	isSelfServe?: boolean;
	isCalmHealthPartner?: boolean;
}

interface UsersLinkProps {
	partnerId: string;
}

const UsersLink: FC<UsersLinkProps> = ({ partnerId }) => {
	const { data: partner } = usePartner(partnerId);
	const { data: integrationType } = useIntegrationType(partner?.id);

	const isChSftpOn = useCalmHealthSftpIntegration();

	if (!partner || !integrationType) {
		return null;
	}

	if (
		!partner.supports_eligibility_list &&
		(integrationType !== IntegrationType.ELIGIBILITY_FILE || !isChSftpOn)
	) {
		return null;
	}
	if (integrationType === IntegrationType.ACCESS_CODES) {
		return (
			<MobileLink
				to={`/${partner.id}/access-codes`}
				id="access-codes-mobile-link"
				data-testid="access-codes-link"
			>
				<UsersIcon />
				<small>Access Codes</small>
			</MobileLink>
		);
	}
	return (
		<MobileLink to={`/${partner.id}/users`} id="users-mobile-link" data-testid="users-link">
			<UsersIcon />
			<small>Users</small>
		</MobileLink>
	);
};

const MobileNav: FC<Props> = ({ partnerId, isCalmHealthPartner }) => {
	const {
		data: flagValues,
		error: flagError,
		loading: flagLoading,
	} = useFeatureFlags('b2b-block-eligibility-endpoint');
	const flagsLoaded = !flagLoading && !flagError && flagValues;

	const blockEligibilityEndpoint = flagsLoaded && flagValues['b2b-block-eligibility-endpoint'];

	return (
		<Wrapper>
			<StyledMobileNav>
				{!isCalmHealthPartner && (
					<MobileLink to={`/${partnerId}/home`} id="home-mobile-link">
						<HomeIcon />
						<small>Home</small>
					</MobileLink>
				)}
				{!isCalmHealthPartner && (
					<MobileLink to={`/${partnerId}/reporting`} id="reporting-mobile-link">
						<ReportingIcon />
						<small>Reporting</small>
					</MobileLink>
				)}
				{!isCalmHealthPartner && (
					<MobileLink to={`/${partnerId}/pathways`} id="pathways-mobile-link">
						<PathwaysIcon />
						<small>Pathways</small>
					</MobileLink>
				)}
				{!blockEligibilityEndpoint && <UsersLink partnerId={partnerId} />}
				<MobileLink to={`/${partnerId}/account`} id="account-mobile-link">
					<AccountIcon />
					<small>Account</small>
				</MobileLink>
			</StyledMobileNav>
		</Wrapper>
	);
};

export default MobileNav;
