// Third Party
import styled from 'styled-components';

import { TextButton, Text, FontSizes, units } from '@calm-web/design-system';

import WarningSVG from 'icons/warning-filled.svg';

export const ModalContent = styled.div`
	text-align: center;
`;

export const CancelButton = styled(TextButton)`
	padding-right: ${units(3)};
	text-decoration: none;
`;

export const ModalText = styled(Text).attrs({
	size: FontSizes.sm,
})`
	text-align: center;
	width: 100%;
`;

export const PrimaryModalText = styled(ModalText)`
	padding: ${units(3, 0)};
`;

export const IconWrapper = styled.div`
	text-align: center;
`;

export const WarningIcon = styled(WarningSVG)`
	height: ${units(6)};
	width: ${units(6)};
`;
