import { defineMessages } from 'react-intl';

export default defineMessages({
	designateTitle: {
		id: 'users.designate.title',
		defaultMessage: 'Designate who is eligible for the Calm benefit',
		description: 'title for the Users Designate section',
	},
	designateSubtitle: {
		id: 'users.designate.subtitle',
		defaultMessage:
			'Upload a .xls or .csv listing the {userIdDescriptor} of who you’d like to make eligible for the Calm benefit.',
		description: 'subtitle for the Get Started Designate section',
	},
	oldDesignateNote: {
		id: 'users.designate.oldNote',
		defaultMessage:
			'Once you have uploaded, each eligible person will have immediate access to Calm. Please note, this getsupload will not trigger an invitation email.',
		description: 'note for the Get Started Designate section',
	},
	designateNote: {
		id: 'users.designate.note',
		defaultMessage:
			'Once you have uploaded, each eligible person will have immediate access to Calm. <br></br><boldtext>Note:</boldtext> If <benefitslink>Benefit Invite & Reminders</benefitslink> is turned on, uploading the Eligibility File will trigger an email series to all employees in the file 1 day after the file is uploaded.',
		description: 'note for the Get Started Designate section',
	},
	designateButton: {
		id: 'users.designate.button',
		defaultMessage: 'Upload eligibility file',
		description: 'button text for the Users Designate section',
	},
});
