import { useContext, FC } from 'react';
import { useIntl } from 'react-intl';

import { Loader } from '@calm-web/design-system';

import { TryAgain } from '@/components/pages/Reporting/SegmentationFilter';
import { PathwaysContext } from '@/components/providers/PathwaysContextProvider';
import { hasSegments } from '@/utils/segments';

import type { SelectionObject } from '../';
import { SegmentDetailsError, WarningIconStyled } from '../../../../Reporting/SegmentationFilter/styles';
import SingleSegmentSelection from '../SingleSegmentSelection';
import messages from '../messages';

function SegmentList({
	selection,
	updateSelection,
}: {
	selection: SelectionObject;
	updateSelection: (s: SelectionObject) => void;
}): ReturnType<FC> {
	const { formatMessage } = useIntl();
	const { segmentDetails, areSegmentsLoading, hasSegmentsError, refetchSegments } =
		useContext(PathwaysContext);

	const { segment_names, segment_values } = segmentDetails || {};
	const { segment_1_display_name, segment_2_display_name, segment_3_display_name } = segment_names || {};
	const { segment_1_values, segment_2_values, segment_3_values } = segment_values || {};

	if (areSegmentsLoading) {
		return <Loader color="gray1" />;
	}

	if (hasSegmentsError) {
		const handleTryAgain = (): void => refetchSegments();
		return (
			<SegmentDetailsError>
				<WarningIconStyled />
				<div>{formatMessage(messages.segmentDetailsError)}</div>
				<TryAgain onClick={handleTryAgain} />
			</SegmentDetailsError>
		);
	}

	if (!hasSegments(segmentDetails)) {
		return null;
	}

	return (
		<>
			{segment_1_display_name && segment_1_values ? (
				<SingleSegmentSelection
					name={segment_1_display_name}
					values={segment_1_values}
					segment="segment1"
					selected={selection.segment1}
					selection={selection}
					onChange={updateSelection}
				/>
			) : null}
			{segment_2_display_name && segment_2_values ? (
				<SingleSegmentSelection
					name={segment_2_display_name}
					values={segment_2_values}
					segment="segment2"
					selected={selection.segment2}
					selection={selection}
					onChange={updateSelection}
				/>
			) : null}
			{segment_3_display_name && segment_3_values ? (
				<SingleSegmentSelection
					name={segment_3_display_name}
					values={segment_3_values}
					segment="segment3"
					selected={selection.segment3}
					selection={selection}
					onChange={updateSelection}
				/>
			) : null}
		</>
	);
}

export default SegmentList;
