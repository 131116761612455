import styled from 'styled-components';

import { Button, FontSizes, FontWeights, fontSize, fontWeight, units } from '@calm-web/design-system';

export const InfoPageWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px;
`;

export const InfoImage = styled.img`
	width: 50%;
	max-width: 600px;
	margin-right: 50px;
`;

export const InfoContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	height: 100%;
`;

export const InfoDescription = styled.p`
	${fontSize(FontSizes.xl)};
	${fontWeight(FontWeights.Medium)};
	margin-bottom: ${units(2)};
`;

export const NextButton = styled(Button)``;

export const FinishButton = styled(Button)``;
