/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

export default defineMessages({
	durationLabel: {
		id: 'integrationDetails.sso.durationLabel',
		defaultMessage: 'Subscription duration:',
		description: 'Label for subscription duration select',
	},
	durationSubtitle: {
		id: 'integrationDetails.sso.durationSubtitle',
		defaultMessage: 'The subscription length the partner wants to offer their covered lives.',
		description: 'Subtitle for subscription duration select',
	},
	duration1Month: {
		id: 'integrationDetails.duration.1month',
		defaultMessage: '1 month',
		description: 'Label for 1 month option in duration select',
	},
	duration3Months: {
		id: 'integrationDetails.duration.3months',
		defaultMessage: '3 months',
		description: 'Label for 3 month option in duration select',
	},
	duration6Months: {
		id: 'integrationDetails.duration.6months',
		defaultMessage: '6 months',
		description: 'Label for 6 month option in duration select',
	},
	duration1Year: {
		id: 'integrationDetails.duration.1year',
		defaultMessage: '1 year',
		description: 'Label for 1 year option in duration select',
	},
	durationLifetime: {
		id: 'integrationDetails.duration.lifetime',
		defaultMessage: 'Lifetime',
		description: 'Label for lifetime option in duration select',
	},
	changeToSsoTitle: {
		id: 'integrationDetails.changeToSso.title',
		defaultMessage: 'Change integration from {currentIntegrationType} to SSO',
		description: 'Header text for the section about changing an existing non-SSO account over to SSO',
	},
	changeToSsoBody: {
		id: 'integrationDetails.changeToSso.body',
		defaultMessage:
			'Your partner will be able to continue to use their {currentIntegrationType} until you confirm the SSO configuration is working and ready to transition',
		description: 'Description text for the section about changing an existing non-SSO account over to SSO',
	},
	setUpSsoTitle: {
		id: 'integrationDetails.setUpSso.title',
		defaultMessage: 'Change integration to SSO',
		description: 'Header text for the section about setting up an SSO integration',
	},
	setUpSsoBody: {
		id: 'integrationDetails.setUpSso.body',
		defaultMessage: `
    <p>Work with your partner’s IT administrator so they can correctly configure their IdP and SAML.</p>
    <b>Here’s what they’ll need:</b>
    <ul>
      <li>Their authentication URL</li>
      <li>The unique SAML Attribute Name to identify users</li>
      <li>(optional) any additional SAML Attribute Names the HR admin may want to use for segmented reporting (e.g. Location or Department)</li>
      <li>SAML metadata like their IdP Isssuer URI, IdP SOO Sign-on URL and Encoded Public Key Certificate</li>
    </ul>`,
		description: 'Description text for the section about setting up an SSO integration',
	},
	setUpSsoButton: {
		id: 'integrationDetails.setUpSso.button',
		defaultMessage: 'Configure SSO',
		description: 'Text on button linking to configure SSO',
	},
	finishSsoTitle: {
		id: 'integrationDetails.finishSso.title',
		defaultMessage:
			'Once you and your partner’s IT manager have verified the configuration is working, transition to account to SSO',
		description: 'Header text for the section about finalizing an SSO integration',
	},
	finishSsoCheckbox: {
		id: 'integrationDetails.finishSso.checkbox',
		defaultMessage:
			'I have tested the SSO configuration with my partner and we are ready to transition the account to SSO',
		description: 'Label text for confirming your setup is complete',
	},
	finishSsoCancelButton: {
		id: 'integrationDetails.finishSso.cancel',
		defaultMessage: 'Cancel SSO transition and keep using the {currentIntegrationType}',
		description: 'Button text for canceling the transition to SSO',
	},
	testingSsoBody: {
		id: 'integrationDetails.testingSso.body',
		defaultMessage:
			'<title>Testing the configuration</title>Your partner can test the configuration by attempting to log in using any existing credentials on their Identity Provider (IdP).<ul><li>You can check if your partner has successfully tested the configuration by checking if there are any sign ups reported on the <reportingpagelink>reporting page.</reportingpagelink></li></ul>',
		description: 'Body text for explainer of how to test SSO',
	},
});
