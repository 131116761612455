import { useFeatureFlags } from './api/useFeatureFlags';

const B2BUserSignupsPercentage = 'b2b-user-signups-percentage';

export const useShouldShowUserSignupsPercentage = (): boolean | string | undefined => {
	const {
		data: flagValues,
		error: flagError,
		loading: flagLoading,
	} = useFeatureFlags(B2BUserSignupsPercentage);
	const flagsLoaded = !flagLoading && !flagError && flagValues;
	const userSignupsPercentageFlag =
		flagValues && (flagValues[B2BUserSignupsPercentage] as boolean | undefined);
	const shouldShowUserSignupsPercentage = flagsLoaded && userSignupsPercentageFlag;
	return shouldShowUserSignupsPercentage;
};
