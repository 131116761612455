import { ReactElement, ReactNode, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { Link } from './styles';

interface Props extends RouteComponentProps {
	to: string;
	children: ReactNode;
	id: string;
	'data-testid'?: string;
}

function MobileNavLink({
	to,
	id,
	children,
	location: { pathname },
	history,
	match,
	staticContext,
	'data-testid': dataTestId,
	...props
}: Props): ReactElement {
	// this circumvents the page being built with a static router
	useEffect(() => {
		const link = document.getElementById(id);
		if (pathname.startsWith(to)) {
			link?.classList?.add('selected');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function getActiveClassName(): string {
		return pathname.startsWith(to) ? 'selected' : '';
	}

	return (
		<Link
			{...props}
			to={to}
			id={id}
			className={getActiveClassName()}
			activeClassName="selected"
			data-testid={dataTestId}
		>
			{children}
		</Link>
	);
}

export default withRouter(MobileNavLink);
