import { defineMessages } from 'react-intl';

export default defineMessages({
	signupsEyebrow: {
		id: 'reporting.signupsAndEngagement.signupsEyebrow',
		defaultMessage: 'Signups',
		description: 'Data label for how many invited users have signed up',
	},
	signupsBody: {
		id: 'reporting.signupsAndEngagement.signupsBody',
		defaultMessage: '{signupCount} of your {eligibleCount} eligible members have signed up.',
		description: 'Data description show below a number like "50%", for how many invited users have signed up',
	},
	signupGraphTooltipLabel: {
		id: 'reporting.signupsAndEngagement.signupGraphTooltipLabel',
		defaultMessage: 'Signups',
		description: 'Label for the tooltip on the signups per aggregation level graph',
	},
	signupsResourceDescription: {
		id: 'reporting.signupsAndEngagement.signupResourceDescription',
		defaultMessage: 'Here are some recommended resources to help you drive your sign-ups and adoption.',
		description: 'Description for resource links',
	},
});
