import { defineMessages } from 'react-intl';

export default defineMessages({
	tenantIdLabel: {
		id: 'msftTeamsTenantId.title',
		defaultMessage: 'Microsoft Teams Tenant ID',
		description: 'Title for microsoft teams tenant id config',
	},
	tenantIdDescription1: {
		id: 'msftTeamsTenantId.description1',
		defaultMessage:
			'To help simplify sign-up for your employees in Microsoft Teams, please provide your Microsoft 365 Tenant ID.',
		description: 'Description on tenant id config',
	},
	tenantIdDescription2: {
		id: 'msftTeamsTenantId.description2',
		defaultMessage: 'More details to find your Tenant ID can be found <tenantdetails>here</tenantdetails>.',
		description: 'Description on tenant id config',
	},
	tenantIdPlaceholder: {
		id: 'msftTeamsTenantId.placeholder',
		defaultMessage: 'Enter your Teams Tenant ID',
		description: 'Placeholder for tenant id config',
	},
});
