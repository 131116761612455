import { ReactElement, useEffect, forwardRef, Ref, useContext, useState, RefObject, useRef } from 'react';
import { useIntl } from 'react-intl';

import { ReportingContext } from '@/components/providers/ReportingContextProvider';
import { aggregationLevelToTimeUnit } from '@/hooks/api/reporting/utils';
import { useHoverTracking } from '@/hooks/useHoverTracking';
import friendlyPercentage from '@/utils/friendlyPercentage';

import WidgetCard from '../../WidgetCard';
import SignupsOrEngagementGraph from '../Graph';
import messages from './messages';
import { PrimaryText } from './styles';

interface Props {
	isPrivacyError: boolean;
	isNoDataError: boolean;
}

function omitLastIncompleteAggregationLevel<T extends { date: string }>(data: T[]): T[] {
	const sorted = data.sort((a, b) => a.date.localeCompare(b.date));
	return sorted;
}

function Engagement(
	{ isPrivacyError, isNoDataError }: Props,
	forwardedRef?: Ref<HTMLDivElement>,
): ReactElement {
	const { formatMessage } = useIntl();
	const { signupsAndEngagementReport, partner } = useContext(ReportingContext);
	const {
		engaged_count,
		engage_rate,
		sign_up_count,
		activity_report,
		engagement_benchmark,
		aggregation_level,
		engaged_start_to_end_change_percentage,
	} = signupsAndEngagementReport?.data || {};
	const { sessions_per_active_user } = activity_report || {};
	const [sessionsPerAggregationLevelData, setSessionsPerAggregationLevelData] =
		useState<{ date: string; value: number }[]>();
	const [startToEndChange, setStartToEndChange] = useState<number | undefined>();
	const [startToEndLabel, setStartToEndLabel] = useState<string | undefined>();
	const benchmarkRef = useRef<HTMLDivElement>(null);
	const graphRef = useRef<HTMLDivElement>(null);

	useHoverTracking({
		loggingPrefix: 'Reporting : Engagement',
		containerRef: forwardedRef as RefObject<HTMLDivElement>,
		targetRefs: [
			{ name: 'benchmark', ref: benchmarkRef },
			{ name: 'graph', ref: graphRef },
		],
	});

	useEffect(() => {
		if (sessions_per_active_user) {
			const sessionsPerAggregationLevel = omitLastIncompleteAggregationLevel(
				sessions_per_active_user.map(datum => ({
					date: datum.starting_ts,
					value: datum.active_user_average_session_count,
				})),
			);
			setSessionsPerAggregationLevelData(sessionsPerAggregationLevel);
			setStartToEndChange(engaged_start_to_end_change_percentage);
			if (sessions_per_active_user && aggregation_level) {
				setStartToEndLabel(
					`${sessions_per_active_user.length} ${aggregationLevelToTimeUnit[aggregation_level]} change`,
				);
			}
		}
	}, [sessions_per_active_user, aggregation_level, engaged_start_to_end_change_percentage]);

	const primaryText = (
		<PrimaryText>
			{formatMessage(messages.engagementBody, {
				engagedCount: engaged_count,
				signupCount: sign_up_count,
			})}
		</PrimaryText>
	);

	return (
		<WidgetCard
			isLoading={signupsAndEngagementReport?.loading}
			isPrivacyError={isPrivacyError}
			isNoDataError={isNoDataError}
			ref={forwardedRef}
			heading={formatMessage(messages.engagementEyebrow)}
			subHeading="Measure how much your organization is engaging with Calm."
			primaryPercentage={friendlyPercentage(engage_rate)}
			{...(typeof startToEndChange !== 'undefined'
				? {
						secondaryText: startToEndLabel,
						secondaryPercentage: friendlyPercentage(startToEndChange, true),
				  }
				: {})}
			primaryText={primaryText}
			benchmark={engagement_benchmark}
			benchmarkRef={benchmarkRef}
			content={
				signupsAndEngagementReport?.data && sessionsPerAggregationLevelData ? (
					<SignupsOrEngagementGraph
						forwardedRef={graphRef}
						data={sessionsPerAggregationLevelData}
						aggregationLevel={signupsAndEngagementReport.data.aggregation_level}
						tooltipLabel={formatMessage(messages.engagementGraphTooltipLabel)}
						paddingSide="left"
					/>
				) : undefined
			}
			resourceDescription={formatMessage(messages.engagementResourceDescription)}
			resourceData={[
				{
					title: '2024 Engagement Guide',
					link: 'https://business.calm.com/elevating-calm-communications-for-2024/',
					openInNewTab: true,
				},
				{
					title: 'Engagement Strategies',
					link: `/${partner?.id}/resources`,
				},
			]}
		/>
	);
}

export default forwardRef(Engagement);
