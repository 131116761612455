import { defineMessages } from 'react-intl';

export default defineMessages({
	calculateButtonText: {
		id: 'plan.calculateButtonText',
		defaultMessage: 'Calculate new price',
		description: 'Text for the calculate new price',
	},
	editButtonText: {
		id: 'plan.editButtonText',
		defaultMessage: 'edit',
		description: 'Text for button to edit the covered lives picker.',
	},
	cancelButtonText: {
		id: 'plan.cancelButtonText',
		defaultMessage: 'Cancel',
		description: 'Text for button to cancel covered lives upgrade.',
	},
	coveredLivesLabel: {
		id: 'plan.coveredLivesLabel',
		defaultMessage: 'Number of covered lives',
		description: 'Label for covered lives picker',
	},
	coveredLivesReactivate: {
		id: 'plan.coveredLivesReactivate',
		defaultMessage:
			'To increase coverage, please reactivate your plan on the <planAndPaymentLink>Plan & Payment</planAndPaymentLink> page first.',
		description: 'Need to reactivate plan to change covered lives',
	},
});
