import FileSaver from 'file-saver';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import JSZip from 'jszip';
import { DateTime } from 'luxon';
import { MutableRefObject } from 'react';
import { Dispatch } from 'redux';

import { ReportSessionsModular } from '@/hooks/api/reporting/useSessionsReport';
import { Feedback } from '@/hooks/api/useFeedback';
import { setBannerMessage } from '@/store/actions';
import { Partner } from '@/types/store/reducers';

export function createFiles(
	setIsDownloadLoading: (b: boolean) => void,
	setDownloadProgress: (p: number) => void,
	partner: Partner | undefined,
	summaryRef: MutableRefObject<HTMLDivElement | null>,
	feedbackRef: MutableRefObject<HTMLDivElement | null>,
	feedback: { feedback: Feedback[]; totalCount: number } | undefined,
	signupRef: MutableRefObject<HTMLDivElement | null>,
	engagementRef: MutableRefObject<HTMLDivElement | null>,
	moodRef: MutableRefObject<HTMLDivElement | null>,
	portraitsRef: MutableRefObject<HTMLDivElement | null>,
	contentRef: MutableRefObject<HTMLDivElement | null>,
	sessionsReport: ReportSessionsModular | undefined,
	topContentRef: MutableRefObject<HTMLDivElement | null>,
	hourlyUsageRef: MutableRefObject<HTMLDivElement | null>,
	zip: JSZip,
	segmentRef: MutableRefObject<HTMLDivElement | null>,
	dispatch: Dispatch,
): () => Promise<void> {
	// Set this as (the largest number in the file) + 1
	const totalDownloadSteps = 26;
	const setDownloadStep = (stepNumber: number): void => {
		setDownloadProgress((stepNumber / totalDownloadSteps) * 100);
	};

	return async (): Promise<void> => {
		setDownloadStep(1);
		// Create new PDF with partner name in header
		const filename = partner?.name.split(' ').join('_');
		const pdf = new jsPDF('p', 'px', 'a4');
		const date = DateTime.now().toISODate();
		pdf.setFontSize(14);
		pdf.setTextColor(0, 0, 0);
		pdf.text(`Calm - ${partner?.name} Reporting - ${date}`, 20, 20);
		pdf.setFontSize(10);
		pdf.setTextColor(141, 141, 141);
		pdf.text(`Data updated as of ${date}`, 20, 30);

		// Employee pulse will contain a lot more graphs, need to split into various pages instead of one screenshot

		let topMargin = 60;
		const WIDGET_WIDTH = 360;
		const MAX_PAGE_HEIGHT = 580;

		// If segments are selected, add to the top of the report
		if (segmentRef && segmentRef.current) {
			const height = (segmentRef.current.clientHeight / segmentRef.current.clientWidth) * WIDGET_WIDTH;
			const segmentCanvas = await html2canvas(segmentRef.current);
			const segmentImage = segmentCanvas.toDataURL('image/png', 1.0);
			pdf.addImage(segmentImage, 'JPEG', 40, topMargin, WIDGET_WIDTH, height);
			if (height + topMargin > MAX_PAGE_HEIGHT) {
				topMargin = 20;
				pdf.addPage();
			}
			topMargin = topMargin + height + 10;
		}
		setDownloadStep(2);

		if (summaryRef && summaryRef.current) {
			const height = (summaryRef.current.clientHeight / summaryRef.current.clientWidth) * WIDGET_WIDTH;
			const summaryCanvas = await html2canvas(summaryRef.current);
			const summaryImage = summaryCanvas.toDataURL('image/png', 1.0);
			if (height + topMargin > MAX_PAGE_HEIGHT) {
				topMargin = 20;
				pdf.addPage();
			}
			pdf.addImage(summaryImage, 'JPEG', 40, topMargin, WIDGET_WIDTH, height);
			topMargin = topMargin + height + 10;
		}
		setDownloadStep(3);

		if (feedbackRef && feedbackRef.current && feedback?.feedback.length) {
			// Dynamic height adjustment depending on how many feedback comments there are
			const height = (feedbackRef.current.clientHeight / feedbackRef.current.clientWidth) * WIDGET_WIDTH;
			const feedbackCanvas = await html2canvas(feedbackRef.current);
			const feedbackImage = feedbackCanvas.toDataURL('image/png', 1.0);
			if (height + topMargin > MAX_PAGE_HEIGHT) {
				topMargin = 20;
				pdf.addPage();
			}
			pdf.addImage(feedbackImage, 'JPEG', 40, topMargin, WIDGET_WIDTH, height);
			topMargin = topMargin + height + 10;
		}
		setDownloadStep(4);

		if (signupRef && signupRef.current) {
			const height = (signupRef.current.clientHeight / signupRef.current.clientWidth) * WIDGET_WIDTH;
			const signupCanvas = await html2canvas(signupRef.current);
			const signupImage = signupCanvas.toDataURL('image/png', 1.0);
			if (height + topMargin > MAX_PAGE_HEIGHT) {
				topMargin = 20;
				pdf.addPage();
			}
			pdf.addImage(signupImage, 'JPEG', 40, topMargin, WIDGET_WIDTH, height);
			topMargin = topMargin + height + 10;
		}
		setDownloadStep(5);

		if (engagementRef && engagementRef.current) {
			const height = (engagementRef.current.clientHeight / engagementRef.current.clientWidth) * WIDGET_WIDTH;
			const engagementCanvas = await html2canvas(engagementRef.current);
			const engagementImage = engagementCanvas.toDataURL('image/png', 1.0);
			if (height + topMargin > MAX_PAGE_HEIGHT) {
				topMargin = 20;
				pdf.addPage();
			}
			pdf.addImage(engagementImage, 'JPEG', 40, topMargin, WIDGET_WIDTH, height);
			topMargin = topMargin + height + 10;
		}
		setDownloadStep(6);

		if (moodRef && moodRef.current) {
			const height = (moodRef.current.clientHeight / moodRef.current.clientWidth) * WIDGET_WIDTH;
			const moodCanvas = await html2canvas(moodRef.current);
			const moodImage = moodCanvas.toDataURL('image/png', 1.0);
			if (height + topMargin > MAX_PAGE_HEIGHT) {
				topMargin = 20;
				pdf.addPage();
			}
			pdf.addImage(moodImage, 'JPEG', 40, topMargin, WIDGET_WIDTH, height);
			topMargin = topMargin + height + 10;
		}
		setDownloadStep(7);

		if (portraitsRef && portraitsRef.current) {
			const height = (portraitsRef.current.clientHeight / portraitsRef.current.clientWidth) * WIDGET_WIDTH;
			const portraitsCanvas = await html2canvas(portraitsRef.current);
			const portraitsImage = portraitsCanvas.toDataURL('image/png', 1.0);
			if (height + topMargin > MAX_PAGE_HEIGHT) {
				topMargin = 20;
				pdf.addPage();
			}
			pdf.addImage(portraitsImage, 'JPEG', 40, topMargin, WIDGET_WIDTH, height);
			topMargin = topMargin + height + 10;
		}
		setDownloadStep(8);

		if (contentRef && contentRef.current) {
			const height = (contentRef.current.clientHeight / contentRef.current.clientWidth) * WIDGET_WIDTH;
			const contentCanvas = await html2canvas(contentRef.current);
			const contentImage = contentCanvas.toDataURL('image/png', 1.0);
			if (height + topMargin > MAX_PAGE_HEIGHT) {
				topMargin = 20;
				pdf.addPage();
			}
			pdf.addImage(contentImage, 'JPEG', 40, topMargin, WIDGET_WIDTH, height);
			topMargin = topMargin + height + 10;
		}
		setDownloadStep(9);

		if (sessionsReport?.sessions && sessionsReport?.sessions?.length > 0) {
			if (topContentRef && topContentRef.current) {
				const height =
					(topContentRef.current.clientHeight / topContentRef.current.clientWidth) * WIDGET_WIDTH;
				const topContentCanvas = await html2canvas(topContentRef.current);
				const topContentImage = topContentCanvas.toDataURL('image/png', 1.0);
				if (height + topMargin > MAX_PAGE_HEIGHT) {
					topMargin = 20;
					pdf.addPage();
				}
				pdf.addImage(topContentImage, 'JPEG', 40, topMargin, WIDGET_WIDTH, height);
				topMargin = topMargin + height + 10;
			}
			setDownloadStep(10);
			if (hourlyUsageRef && hourlyUsageRef.current) {
				const height =
					(hourlyUsageRef.current.clientHeight / hourlyUsageRef.current.clientWidth) * WIDGET_WIDTH;
				const hourlyUsageCanvas = await html2canvas(hourlyUsageRef.current);
				const hourlyUsageImage = hourlyUsageCanvas.toDataURL('image/png', 1.0);
				if (height + topMargin > MAX_PAGE_HEIGHT) {
					topMargin = 20;
					pdf.addPage();
				}
				pdf.addImage(hourlyUsageImage, 'JPEG', 40, topMargin, WIDGET_WIDTH, height);
			}
			setDownloadStep(11);
		}

		zip.file(filename + '_Calm_Reporting_' + date + '.pdf', pdf.output('blob'));
		setDownloadStep(12);

		const img: JSZip | null = zip.folder('images');
		setDownloadStep(13);

		if (!img) {
			dispatch(
				setBannerMessage({
					message: `Unable to download the report. Please try again.`,
					isError: true,
					flash: true,
				}),
			);
			setIsDownloadLoading(false);
		} else {
			// Add each individual report on its own page
			if (summaryRef && summaryRef.current) {
				const summary = await html2canvas(summaryRef.current);
				const summaryImage = summary.toDataURL('image/png', 1.0);
				const blob = await (await fetch(summaryImage)).blob();
				img?.file('ReportSummary.png', blob);
			}
			setDownloadStep(14);
			if (feedbackRef && feedbackRef.current && feedback?.feedback.length) {
				const feedback = await html2canvas(feedbackRef.current);
				const feedbackImage = feedback.toDataURL('image/png', 1.0);
				const blob = await (await fetch(feedbackImage)).blob();
				img?.file('FeedbackReport.png', blob);
			}
			setDownloadStep(15);
			if (signupRef && signupRef.current) {
				const signups = await html2canvas(signupRef.current);
				const signupsImage = signups.toDataURL('image/png', 1.0);
				const blob = await (await fetch(signupsImage)).blob();
				img?.file('SignupReport.png', blob);
			}
			setDownloadStep(16);
			if (engagementRef && engagementRef.current) {
				const engagement = await html2canvas(engagementRef.current);
				const engagementImage = engagement.toDataURL('image/png', 1.0);
				const blob = await (await fetch(engagementImage)).blob();
				img?.file('EngagementReport.png', blob);
			}
			setDownloadStep(17);
			if (moodRef && moodRef.current) {
				const mood = await html2canvas(moodRef.current);
				const moodImage = mood.toDataURL('image/png', 1.0);
				const blob = await (await fetch(moodImage)).blob();
				img?.file('MoodCheckinReport.png', blob);
			}
			setDownloadStep(18);
			if (portraitsRef && portraitsRef.current) {
				const portraits = await html2canvas(portraitsRef.current);
				const portraitsImage = portraits.toDataURL('image/png', 1.0);
				const blob = await (await fetch(portraitsImage)).blob();
				img?.file('UserStatsReport.png', blob);
			}
			setDownloadStep(19);
			if (contentRef && contentRef.current) {
				const content = await html2canvas(contentRef.current);
				const contentImage = content.toDataURL('image/png', 1.0);
				const blob = await (await fetch(contentImage)).blob();
				img?.file('ContentTrendsReport.png', blob);
			}
			setDownloadStep(20);
			if (sessionsReport?.sessions && sessionsReport?.sessions?.length > 0) {
				if (topContentRef && topContentRef.current) {
					const topContent = await html2canvas(topContentRef.current);
					const topContentImage = topContent.toDataURL('image/png', 1.0);
					const blob = await (await fetch(topContentImage)).blob();
					img?.file('TopContentReport.png', blob);
				}
				setDownloadStep(21);
				if (hourlyUsageRef && hourlyUsageRef.current) {
					const hourlyUsage = await html2canvas(hourlyUsageRef.current);
					const hourlyUsageImage = hourlyUsage.toDataURL('image/png', 1.0);
					const blob = await (await fetch(hourlyUsageImage)).blob();
					img?.file('HourlyUsageReport.png', blob);
				}
				setDownloadStep(22);
			}
			zip
				.generateAsync({ type: 'blob' })
				.then(function (content: string | Blob) {
					setDownloadStep(24);
					FileSaver.saveAs(content, filename + '_Calm_Reporting_' + date + '.zip');
				})
				.catch((error: Error) => {
					dispatch(
						setBannerMessage({
							message: `Unable to download the report. Please try again.`,
							isError: true,
							flash: true,
						}),
					);
					throw error;
				})
				.finally(() => {
					setDownloadStep(25);
					setIsDownloadLoading(false);
				});
			setDownloadStep(23);
		}
	};
}
