import { FC, useMemo, useState } from 'react';
import { useBeforeunload } from 'react-beforeunload';
import { useIntl } from 'react-intl';
import { Prompt } from 'react-router';

import { FontWeights, Text } from '@calm-web/design-system';

import { Feedback, useFeedback, useSaveFeedbackPins } from '@/hooks/api/useFeedback';
import { calmLogger } from '@/utils/calmLogger';

import FeedbackTable from '../FeedbackTable';
import messages from './messages';
import { ClearButton, Instructions, SaveButton, TableHeaderContainer } from './styles';

// For now, we chose a huge page size so that we never actually paginate,
// as having two paginated tables that influence each other on the same page gets really confusing UX-wise
const PAGE_SIZE = 9999;

export default function PinnedFeedbackTable({
	partnerId,
	onChangeFeedbackPin,
	modifiedPinnedStatus,
	onSavePins,
}: {
	partnerId: string;
	modifiedPinnedStatus: Record<string, Feedback | false | undefined>;
	onChangeFeedbackPin: (props: { id: string; pinned: boolean }) => void;
	onSavePins: () => void;
}): ReturnType<FC> {
	const { formatMessage } = useIntl();
	const [pageIndex, setPageIndex] = useState(0);
	const {
		data: feedback,
		loading,
		error,
		clearCache: clearFeedbackCache,
	} = useFeedback(partnerId, true, PAGE_SIZE, pageIndex * PAGE_SIZE);
	const unsavedPinnedFeedback = useMemo(
		() => Object.values(modifiedPinnedStatus).filter(Boolean) as Feedback[],
		[modifiedPinnedStatus],
	);
	const tableData = useMemo(
		() =>
			(
				feedback?.feedback.filter(
					f => modifiedPinnedStatus[f.id] || typeof modifiedPinnedStatus[f.id] === 'undefined',
				) ?? []
			)
				.concat(unsavedPinnedFeedback)
				.map(f => ({ ...f, pinned: true }))
				.sort((a, b) => {
					const timeCompare = b.created_at.localeCompare(a.created_at);
					if (timeCompare !== 0) {
						return timeCompare;
					}
					return b.id.localeCompare(a.id);
				}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[feedback?.feedback, modifiedPinnedStatus],
	);
	const showSaveButton = Object.keys(modifiedPinnedStatus).length > 0;
	const [saveFeedbackPins, { loading: savePinsIsLoading }] = useSaveFeedbackPins(partnerId);
	const onClickSave = useMemo(
		() => async (clear: boolean) => {
			try {
				await saveFeedbackPins(
					clear ? Object.fromEntries(feedback?.feedback.map(f => [f.id, false]) ?? []) : modifiedPinnedStatus,
				);
				clearFeedbackCache();
				onSavePins();
			} catch (err) {
				calmLogger.error('Error in PinnedFeedbackTable onClickSave', {}, err);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[feedback?.feedback, modifiedPinnedStatus],
	);
	useBeforeunload(e => {
		if (showSaveButton) {
			e.preventDefault();
		}
	});

	return (
		<>
			<Prompt when={showSaveButton} message={formatMessage(messages.unsavedChanges)} />
			{showSaveButton ? (
				<SaveButton onPress={() => onClickSave(false)} backgroundColor="blue3" isLoading={savePinsIsLoading}>
					Save changes
				</SaveButton>
			) : null}
			<TableHeaderContainer>
				<Text el="h2" styleAs="h5" weight={FontWeights.Medium}>
					Pinned
				</Text>
				<ClearButton onClick={() => onClickSave(true)}>clear</ClearButton>
			</TableHeaderContainer>
			<Instructions>{formatMessage(messages.instructions)}</Instructions>
			<FeedbackTable
				feedback={tableData}
				totalCount={feedback?.totalCount}
				loading={loading || !feedback}
				error={Boolean(error)}
				pageSize={PAGE_SIZE}
				pageIndex={pageIndex}
				setPageIndex={setPageIndex}
				onChangeFeedbackPin={onChangeFeedbackPin}
				canPinFeedback
			/>
		</>
	);
}
