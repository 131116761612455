import { FC, useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { FontSizes, FontWeights, Text } from '@calm-web/design-system';

import EmailPreferences from '@/components/pages/Account/EmailPreferences';
import type { EditPartnerFormProps } from '@/hooks/forms/usePartnerForm';
import { Partner } from '@/types/store/reducers';
import CalmCookie from '@/utils/CalmCookie';

import messages from './messages';

interface EmailsAndSurveysProps {
	partner?: Partner;
	formProps: EditPartnerFormProps;
	isShowingPartnerSave?: boolean;
}

const EmailsAndSurveys: FC<EmailsAndSurveysProps> = ({
	partner,
	formProps,
	isShowingPartnerSave = false,
}) => {
	const { formatMessage } = useIntl();
	const partnerId = partner?.id;
	const userEmailPreferencesRef = useRef<null | HTMLDivElement>(null);
	const benefitsLinkClickedCookie = CalmCookie.get('benefits-link-clicked');

	useEffect(() => {
		if (benefitsLinkClickedCookie === 'true' && userEmailPreferencesRef) {
			userEmailPreferencesRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
			CalmCookie.set('benefits-link-clicked', 'false');
		}
	}, [benefitsLinkClickedCookie]);

	return (
		<section>
			<Text el="h2" size={FontSizes.xl} weight={FontWeights.Regular} color="gray7">
				{formatMessage(messages.emailsAndSurveysTitle)}
			</Text>
			<div ref={userEmailPreferencesRef}>
				<EmailPreferences
					formProps={formProps}
					isEdit={!!partnerId}
					shouldShowManualScheduling={!!partnerId}
					partnerName={partner?.name}
					isShowingPartnerSave={isShowingPartnerSave}
				/>
			</div>
		</section>
	);
};

export default EmailsAndSurveys;
