import { ReactElement, forwardRef, Ref, RefObject, useRef } from 'react';

import { ReportContentModular } from '@/hooks/api/reporting/useContentReport';
import { useHoverTracking } from '@/hooks/useHoverTracking';

import WidgetCard from '../WidgetCard';
import ContentGraph from './Graph';

interface Props {
	contentReport: ReportContentModular | undefined;
	isLoading: boolean;
	isPrivacyError: boolean;
	isNoDataError: boolean;
}

function Content(
	{ contentReport, isLoading, isPrivacyError, isNoDataError }: Props,
	forwardedRef?: Ref<HTMLDivElement>,
): ReactElement {
	const { content, aggregation_level } = contentReport || {};

	const graphRef = useRef<HTMLDivElement>(null);

	useHoverTracking({
		loggingPrefix: 'Reporting : Content',
		containerRef: forwardedRef as RefObject<HTMLDivElement>,
		targetRefs: [{ name: 'graph', ref: graphRef }],
	});

	return (
		<WidgetCard
			ref={forwardedRef}
			isLoading={isLoading}
			heading="Content Trends"
			subHeading="Analyze trends in your organization based on their content usage."
			content={<ContentGraph forwardedRef={graphRef} data={content} aggregationLevel={aggregation_level} />}
			removeDataContainer
			isPrivacyError={isPrivacyError}
			isNoDataError={isNoDataError}
		/>
	);
}

export default forwardRef(Content);
