import { PropsWithChildren } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { Button, Loader } from '@calm-web/design-system';

import FullPageWrapper from '@/components/ui/FullPageWrapper';
import FullscreenOverlay from '@/components/ui/FullscreenOverlay';
import { usePartner, PartnerContext } from '@/hooks/api/usePartner';

const PartnerSetup = ({ children }: PropsWithChildren<{}>): JSX.Element => {
	const history = useHistory();
	const { search } = useLocation();
	const { partnerId } = useParams();
	const { loading, error, data: partner } = usePartner(partnerId);

	const goToGroupsPage = (): void => {
		history.push({
			pathname: '/groups',
			search,
		});
	};

	if (loading) {
		return (
			<FullPageWrapper>
				<Loader color="gray1" />
			</FullPageWrapper>
		);
	}

	if (error || !partner) {
		return (
			<FullscreenOverlay>
				<Button backgroundColor="blue3" onPress={goToGroupsPage}>
					Select New Partner
				</Button>
			</FullscreenOverlay>
		);
	}

	return <PartnerContext.Provider value={partner}>{children}</PartnerContext.Provider>;
};

export default PartnerSetup;
