import { PropsWithChildren } from 'react';

import { ActiveStep, CompletedSteps } from '@/components/providers/PathwaysContextProvider';

import { StepItemButton } from './styles';

interface Props {
	name: ActiveStep;
	activeStep: ActiveStep;
	completedSteps: CompletedSteps;
	onClick: (name: ActiveStep) => void;
	disabled?: boolean;
}

const StepItem = ({
	activeStep,
	completedSteps,
	name,
	onClick,
	disabled = false,
	children,
}: PropsWithChildren<Props>): JSX.Element => {
	const hasCompleted = completedSteps[name];
	return (
		<StepItemButton
			active={activeStep === name}
			visited={hasCompleted}
			disabled={disabled}
			onClick={() => onClick(name)}
		>
			{children}
		</StepItemButton>
	);
};

export default StepItem;
