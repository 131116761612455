import IsEmail from 'isemail';

const minLength =
	(length: number): ((value: string) => boolean) =>
	(value: string): boolean =>
		value.length >= length;
const maxLength =
	(length: number): ((value: string) => boolean) =>
	(value: string): boolean =>
		value.length <= length;
const hasNoURL = (value: string): boolean => !value.includes('://');

type ValidationTypes = 'name' | 'email';

export function validate(type: ValidationTypes, value: string): boolean {
	if (typeof value !== 'string') {
		return false;
	}
	switch (type) {
		case 'name':
			return minLength(1)(value) && maxLength(2048)(value) && hasNoURL(value);
		case 'email':
			return IsEmail.validate(value);
		default:
			return false;
	}
}
