import styled from 'styled-components';

import {
	units,
	minWidth,
	maxWidth,
	fontWeight,
	FontWeights,
	Text,
	TextButton,
	FontSizes,
} from '@calm-web/design-system';

export const ButtonGroup = styled.div`
	margin: 0 auto;
	:first-child {
		margin-right: 1.33rem;
	}
`;
export const UpdateForm = styled.form`
	width: 100%;
	display: flex;
	text-align: left;
	flex-direction: column;
	font-size: 0.75rem;

	${minWidth('desktop')} {
		min-width: ${units(62)};

		button {
			margin-left: 1rem;
		}
	}

	label {
		margin-bottom: 0.22rem;
	}
`;

export const Row = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: ${units(2)};

	${maxWidth('desktop')} {
		flex-direction: column;
		gap: ${units(1)};
	}
`;

export const CoveredLivesReactivate = styled(Text).attrs({
	size: FontSizes.base,
	weight: FontWeights.Medium,
})``;

export const PlanAndPaymentLink = styled(TextButton)`
	margin-left: 0 !important;
`;

export const CoveredLivesTitle = styled.div`
	${fontWeight(FontWeights.Medium)};
`;
