import styled from 'styled-components';

import { fontWeight, FontWeights, units } from '@calm-web/design-system';

export const TextBlock = styled.div``;

export const Bold = styled.span`
	${fontWeight(FontWeights.Medium)};
`;

export const ButtonRow = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	:first-child {
		margin-right: ${units(2)};
	}
`;

export const Title = styled.h5``;

export const Container = styled.div`
	${Title} {
		margin-bottom: ${units(1)};
	}

	${TextBlock} {
		margin-bottom: ${units(1)};
	}
`;
