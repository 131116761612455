// TODO: check i'm tested

import { useState } from 'react';
import { useDispatch } from 'react-redux';
import useSWR, { useSWRConfig } from 'swr';

import { useApi } from '@/hooks/api';
import { setBannerMessage } from '@/store/actions';
import { CalmError, getCalmErrorOrError } from '@/utils/apiRequest/errors';

import { ApiResponse } from './types';

const BASE_ACCOUNTS_URL = 'b2b/accounts';

type ConnectFunction = (salesforceAccountId: string) => Promise<Account>;
interface Account {
	id: string;
	salesforce_account_id?: string;
}
export function useConnectSalesforceId(b2bAccountId?: string): [ConnectFunction, ApiResponse<Account>] {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<CalmError | Error | undefined>(undefined);
	const apiRequest = useApi();
	const dispatch = useDispatch();
	const { mutate } = useSWRConfig();

	const connectSalesforceAccount: ConnectFunction = async salesforceId => {
		try {
			setLoading(true);
			const method = b2bAccountId ? 'PATCH' : 'POST';
			const endpoint = b2bAccountId ? `${BASE_ACCOUNTS_URL}/${b2bAccountId}` : BASE_ACCOUNTS_URL;
			const response = await apiRequest({ endpoint, method, body: { salesforce_account_id: salesforceId } });
			dispatch(
				setBannerMessage({
					message: `Successfully connected to Salesforce`,
					isError: false,
					flash: true,
				}),
			);
			// Update the data being consumed
			await mutate(endpoint);
			return response.data?.account as Account;
		} catch (err) {
			setError(getCalmErrorOrError(err));
			dispatch(
				setBannerMessage({
					message: `Failed to connect to Salesforce`,
					isError: true,
					flash: true,
				}),
			);
			throw err;
		} finally {
			setLoading(false);
		}
	};

	return [connectSalesforceAccount, { error, loading }];
}

export function useSalesforceId(b2bAccountId?: string): ApiResponse<string> {
	const apiRequest = useApi();
	const dispatch = useDispatch();

	const { data, error } = useSWR(
		b2bAccountId ? `${BASE_ACCOUNTS_URL}/${b2bAccountId}` : undefined,
		async (endpoint: string) => {
			try {
				const response = await apiRequest({ endpoint });
				return response;
			} catch (err) {
				dispatch(
					setBannerMessage({
						message: `Failed to retrieve salesforce id`,
						isError: true,
						flash: true,
					}),
				);
				throw err;
			}
		},
		{ errorRetryCount: 0 },
	);

	if (!b2bAccountId) {
		return { loading: false, error: undefined };
	}

	if (error) {
		return { loading: false, error };
	}

	if (!data) {
		return { loading: true, error: undefined };
	}

	const salesforceId = data.data?.account?.salesforce_account_id as string;
	return {
		data: salesforceId,
		error: undefined,
		loading: false,
	};
}
