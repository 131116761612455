import styled from 'styled-components';

import { FontSizes, Text, TextElements, FontWeights, units } from '@calm-web/design-system';

export const GroupCodeLabel = styled(Text).attrs({
	el: TextElements.Label,
	size: FontSizes.base,
	weight: FontWeights.Medium,
})`
	margin-bottom: ${units(1)};
`;
