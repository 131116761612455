import styled, { css } from 'styled-components';
import { palette, theme } from 'styled-tools';

import { FontSizes, minWidth, fontSize, units } from '@calm-web/design-system';

import StepIcon from '@/components/ui/StepIcon';

import Gift from 'icons/gift-outline.svg';

export const LoaderContainer = styled.div`
	width: auto;
	height: 80px;
	margin: 0 auto;
`;

export const HeaderRow = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const HeaderText = styled.div`
	padding: ${units(3)};
`;

export const HeaderTitle = styled.div`
	${fontSize(FontSizes['2xl'])};
	color: ${palette('blue3')};
`;

export const HeaderSubtitle = styled.div`
	color: ${palette('gray8')};
	width: ${units(44)};
	padding-top: ${units(2)};
`;

export const HeaderLockup = styled.div`
	display: flex;
	align-items: center;
	margin: ${units(0, 6)};
`;

export const HeaderLockupImage = styled.img`
	width: ${units(16)};
	height: ${units(22)};
`;

export const HeaderLockupText = styled.div`
	padding: ${units(2)};
`;

export const HeaderLockupNumbers = styled.div`
	color: ${palette('blue3')};
	${fontSize(FontSizes.lg)}
`;

export const HeaderLockupQuote = styled.div`
	color: ${palette('gray5')};
	${fontSize(FontSizes.sm)};
	width: ${units(16)};
`;

export const Cell = styled.div`
	display: flex;
	${fontSize(FontSizes.sm)};

	${StepIcon} {
		margin-right: ${units(2)};
	}
`;

const cellStyles = css`
	background-color: ${palette('white')};
	border: 1px solid ${palette('gray1')};
	border-radius: 6px;
	margin-top: ${units(1)};
`;

export const CellRow = styled.div`
	${cellStyles}
	padding: ${units(3)};
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: ${units(1)};

	${minWidth('desktop')} {
		grid-template-columns: 1fr 1fr;
	}
`;

export const GiftRow = styled.div`
	${cellStyles}
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: ${units(4)};
	margin-top: ${units(7)};

	${minWidth('desktop')} {
		margin-top: ${units(0)};
	}
`;

export const GiftIcon = styled(Gift)`
	width: ${units(4)};
	height: ${units(4)};
	fill: ${palette('blue3')};
	margin-bottom: ${units(2)};
`;

export const GiftHeader = styled.div`
	margin-bottom: ${units(2)};
`;

export const GiftInput = styled.input`
	border: 2px solid ${palette('blue2')};
	border-radius: 6px;
	padding: ${units(2)};
	width: 100%;
	text-align: center;
`;

export const ShareRow = styled.div`
	display: flex;
	flex-wrap: wrap;

	.react-share__ShareButton {
		display: flex;
		align-items: center;
		margin-right: ${units(3)};
		margin-top: ${units(2)};

		:last-child {
			margin-right: 0;
		}
	}

	div:first-child {
		flex-direction: row;
		flex-wrap: wrap;
	}
`;

export const Container = styled.div`
	width: 100%;
	max-width: ${theme('layout.mainMaxWidth')};

	${CellRow} + ${CellRow} {
		margin-top: ${units(2)};
	}
`;

export const TermsAndConditions = styled.div`
	margin-top: ${units(2)};
	${fontSize(FontSizes.sm)};

	a {
		text-decoration: underline;
		color: ${palette('blue3')};
	}
`;
