import styled from 'styled-components';

import { fontSize, FontSizes, fontWeight, FontWeights } from '@calm-web/design-system';

import { CalloutBase } from '@/components/ui/Callout/styles';

export const CalloutContent = styled(CalloutBase)`
	text-align: center;
	border-bottom: none;
	border-radius: 4px 4px 0 0;
`;
export const CalloutTitle = styled.div`
	${fontSize(FontSizes.base)};
	${fontWeight(FontWeights.Demi)};
`;
export const CalloutCopy = styled.div`
	${fontSize(FontSizes.sm)};
	${fontWeight(FontWeights.Medium)};
`;
