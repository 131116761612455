import { defineMessages } from 'react-intl';

export default defineMessages({
	sectionHeading: {
		id: 'home.stats.section.heading',
		defaultMessage: 'At a glance',
		description: 'Heading for the stats section',
	},
	heading: {
		id: 'home.stats.noStats.heading',
		defaultMessage: "The latest data will show up here once it's available",
		description: 'Copy shown once all action plan items have been completed.',
	},
	subheading: {
		id: 'home.stats.noStats.subheading',
		defaultMessage: 'Get started on your action plan below.',
		description: 'Copy shown once all action plan items have been completed.',
	},
});
