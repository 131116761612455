import { defineMessages } from 'react-intl';

export default defineMessages({
	productSKUTypesTitle: {
		id: 'productSKUTypes.productSKUTypesTitle',
		defaultMessage: 'Product SKU Type',
		description: 'Cell title for product SKU type',
	},
	productSKUTypesSubtitle: {
		id: 'productSKUTypes.productSKUTypesLabel.subtitle',
		defaultMessage: 'Choose the appropriate product SKU type for this partner.',
		description: 'Subtitle for product SKU type',
	},
	productSKUTypeC4BDirectSelection: {
		id: 'productSKUTypes.productSKUC4BDirectSelection',
		defaultMessage: 'Calm for Business - Direct',
		description: 'Selection label for Calm for Business Direct product SKU',
	},
	productSKUTypeC4BPayorBaseSelection: {
		id: 'productSKUTypes.productSKUC4BPayorBaseSelection',
		defaultMessage: 'Calm for Business - Payor Base',
		description: 'Selection label for Calm for Business Payor Base product SKU',
	},
	productSKUTypeC4BPayorBuyupSelection: {
		id: 'productSKUTypes.productSKUC4BPayorBuyupSelection',
		defaultMessage: 'Calm for Business - Payor Buy-up',
		description: 'Selection label for Calm for Business Payor Buy-up product SKU',
	},
	productSKUTypeCHDirectSelection: {
		id: 'productSKUTypes.productSKUCHDirectSelection',
		defaultMessage: 'Calm Health - Direct',
		description: 'Selection label for Calm Health Direct product SKU',
	},
	productSKUTypeCHPayorBaseSelection: {
		id: 'productSKUTypes.productSKUCHPayorBaseSelection',
		defaultMessage: 'Calm Health - Payor Base',
		description: 'Selection label for Calm Health Payor Base product SKU',
	},
	productSKUTypeCHPayorBuyupSelection: {
		id: 'productSKUTypes.productSKUCHPayorBuyupSelection',
		defaultMessage: 'Calm Health - Payor Buy-up',
		description: 'Selection label for Calm Health Payor Buy-up product SKU',
	},
	productSKUTypeCalmLegacyCoachingSelection: {
		id: 'productSKUTypes.productSKUCalmLegacyCoachingSelection',
		defaultMessage: 'Calm Legacy Coaching',
		description: 'Selection label for Calm Legacy Coaching product SKU',
	},
});
