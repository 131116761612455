/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

export default defineMessages({
	tableNote: {
		id: 'reporting.topContentByCategory.tableNote',
		defaultMessage: 'Your employees haven’t listened to 10 different {category} items yet.',
		description: 'Note as to why there are not 10 "top 10" items',
	},
	topContentSubtitleDynamic: {
		id: 'reporting.topContentByCategory.subtitleDynamic',
		defaultMessage: 'Most played content over the {timeframe}. Click a category to see the top 10',
		description: 'Description of top content by category section',
	},
	modalSubtitleDynamic: {
		id: 'reporting.topContentByCategory.modalSubtitleDynamic',
		defaultMessage: 'Most played content in this category over the {timeframe}',
		description: 'Description of top content by category section in the modal',
	},
});
