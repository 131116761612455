import styled, { css } from 'styled-components';
import { ifProp, palette } from 'styled-tools';

import {
	Button,
	ButtonSizes,
	Card,
	FontSizes,
	maxWidth,
	fontSize,
	fontWeight,
	FontWeights,
	units,
} from '@calm-web/design-system';

import InfoOutline from 'icons/info-outline.svg';

export const StyledCard = styled(Card)`
	border: none;
	padding: ${units(3)};
	border-radius: 16px;
	margin-bottom: ${units(3)};
`;

export const Heading = styled.h3`
	display: block;
	${fontSize(FontSizes.lg)};
	${fontWeight(FontWeights.Regular)};
	margin-right: ${units(1)};
`;

export const SubHeading = styled.h4`
	display: block;
	${fontSize(FontSizes.sm)};
	${fontWeight(FontWeights.Regular)};
	color: ${palette('gray4')};
`;

export const FlexWrapper = styled.div`
	display: flex;
	align-items: baseline;
	${maxWidth('tablet')} {
		flex-wrap: wrap;
	}
`;

export const Wrapper = styled.div``;

export const DataContainer = styled.div`
	display: flex;
	align-items: baseline;
	justify-content: space-between;
	margin: ${units(2, 0, 2, 0)};
`;

export const DataLeft = styled.div``;

export const PrimaryContainer = styled.div`
	display: flex;
	align-items: baseline;
	${maxWidth('tablet')} {
		display: block;
	}
`;

export const PrimaryPercentage = styled.span`
	${fontSize(FontSizes['2xl'])};
	${fontWeight(FontWeights.Medium)};
	margin-right: ${units(1)};
`;

export const PrimaryText = styled.span`
	${fontSize(FontSizes.sm)};
	margin-left: ${units(0.5)};
`;

export const SecondaryContainer = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: ${units(2)};
`;

export const SecondaryPercentage = styled.span<{
	$isNegative?: boolean;
}>`
	${fontSize(FontSizes.sm)};
	color: ${palette('buttonGreen')};
	margin-right: ${units(0.5)};
	${fontWeight(FontWeights.Medium)};
	${ifProp(
		'$isNegative',
		css`
			color: ${palette('errorRed')};
		`,
	)}
`;

export const SecondaryText = styled.span`
	color: ${palette('gray5')};
	${fontSize(FontSizes.sm)};
`;

export const BenchmarkContainer = styled.div`
	${fontSize(FontSizes.sm)};
	color: ${palette('gray5')};
	text-align: right;
`;

export const BenchmarkText = styled.div`
	display: flex;
	align-items: baseline;
`;

export const BenchmarkPercentage = styled.span`
	${fontSize(FontSizes['2xl'])};
	${fontWeight(FontWeights.Medium)};
`;

export const ResourceTitle = styled.span`
	${fontWeight(FontWeights.Medium)};
`;

export const ResourceDescription = styled.span`
	display: block;
	${fontSize(FontSizes.sm)};
`;

export const LinkReferences = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
`;

export const ArticleLink = styled(Button).attrs({
	iconColor: 'blue3',
	backgroundColor: 'white',
	textColor: 'blue3',
	size: ButtonSizes.Sm,
	contentAlignment: 'left',
	hideShadow: true,
})`
	${fontWeight(FontWeights.Medium)};
	&& {
		padding-left: 0;
	}
`;

export const InfoIcon = styled(InfoOutline)`
	path {
		fill: ${palette('blue3')};
	}
	width: ${units(2)};
	height: ${units(2)};
	position: relative;
	vertical-align: baseline;
	top: 2px;
	margin-left: ${units(0.5)};
`;
