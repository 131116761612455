import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useApi } from '@/hooks/api';
import { setBannerMessage } from '@/store/actions';
import { calmLogger } from '@/utils/calmLogger';

type SendRemindersFunction = (partnerId: string) => Promise<void>;
interface RemindersState {
	loading: boolean;
	error: unknown;
}
type EligibilityReminderResponse = [SendRemindersFunction, RemindersState];

const useEligibilityReminder = (): EligibilityReminderResponse => {
	const apiRequest = useApi();
	const dispatch = useDispatch();
	const [error, setError] = useState<unknown>(null);
	const [loading, setLoading] = useState<boolean>(false);

	const sendReminders: SendRemindersFunction = async (partnerId: string) => {
		const endpoint = `b2b/partners/${partnerId}/eligibility/send-reminders`;
		try {
			setLoading(true);
			await apiRequest({
				endpoint,
				method: 'POST',
			});
			dispatch(
				setBannerMessage({
					message: 'Successfully send sign up reminders.',
					isError: false,
					flash: true,
				}),
			);
		} catch (err) {
			calmLogger.error('Error when trying to send sign up reminders', {}, err);
			setError(err);
			dispatch(
				setBannerMessage({
					message: 'Unable to send the sign up reminder at this time.',
					isError: true,
					flash: true,
				}),
			);
			throw err;
		} finally {
			setLoading(false);
		}
	};

	return [sendReminders, { loading, error }];
};

export default useEligibilityReminder;
