import styled, { css } from 'styled-components';
import { palette } from 'styled-tools';

import { Button, fontSize, FontSizes, fontWeight, FontWeights, units } from '@calm-web/design-system';

import WarningSVG from 'icons/warning-filled.svg';

export const SsoSectionTitle = styled.h6`
	margin: ${units(1, 0)};
`;

const ssoSectionBodyText = css`
	${fontSize(FontSizes.sm)}
	${fontWeight(FontWeights.Regular)};
`;

export const SsoSectionBody = styled.div`
	${ssoSectionBodyText}
	:not(:last-child) {
		margin-bottom: ${units(2)};
	}
	max-width: ${units(80)};

	p,
	li,
	label {
		${ssoSectionBodyText};
		margin: ${units(0.5, 0)};
	}

	a {
		color: ${palette('blue3')};
	}

	p {
		margin-bottom: ${units(2)};
	}

	label {
		margin-top: ${units(2)};
	}

	b {
		${fontWeight(FontWeights.Medium)};
	}

	ul {
		padding-left: ${units(2.25)};
	}

	h6 {
		margin: ${units(2, 0, 0.5, 0)};
	}
`;

export const ButtonWithBottomMargin = styled(Button)`
	margin-bottom: ${units(2)};
`;

export const SsoHelpLink = styled.a`
	color: ${palette('blue3')};
`;

export const ModalText = styled.div`
	margin-bottom: ${units(2)};
`;

export const WarningText = styled.span`
	${fontSize(FontSizes.sm)}
`;

export const WarningIcon = styled(WarningSVG)`
	margin: ${units(0, 1, 0, 2)};
	width: ${units(2)};
	height: auto;
	vertical-align: middle;
	position: relative;
	top: -1px;
	path {
		fill: ${palette('gold')};
	}
`;
