import React, { FC, ReactNode, useEffect, useState } from 'react';

import { Loader } from '@calm-web/design-system';

import { ErrorWrapper, PaginationButton, PaginationButtonWrapper, PaginationRow } from '../Table/styles';
import { NextPrevEllipse } from './styles';

interface MobilePaginatorProps {
	fetchData?: (o: { pageIndex: number; pageSize?: number }) => void;
	loading: boolean;
	pageCount: number;
	error?: string;
	pageSize?: number;
	forcePageIndex?: number;
	paginationBackgroundColor?: string;
}

const NUM_PAGES_SHOWN_MOBILE = 2;
const PAGINATION_OFFSET_MOBILE = Math.ceil(NUM_PAGES_SHOWN_MOBILE / 2);

const MobilePaginator: FC<MobilePaginatorProps> = ({
	fetchData,
	loading,
	pageCount,
	error: errorFetchingData,
	pageSize,
	forcePageIndex = 0,
	paginationBackgroundColor = 'gray1',
}) => {
	const numPagesShown = NUM_PAGES_SHOWN_MOBILE;
	const paginationOffset = PAGINATION_OFFSET_MOBILE;
	const [pageIndex, setPageIndex] = useState(0);
	const currentPage = pageIndex + 1;
	const pageOptions = pageCount && pageCount > 0 ? [...new Array(pageCount)].fill(null).map((_, i) => i) : [];
	const lastPage = pageOptions.length ? pageOptions.length + 1 : 0;
	const canPreviousPage = pageIndex > 0;
	const canNextPage = (pageCount === -1 || pageIndex < pageCount - 1) && pageCount !== 0;

	function getPaginatedSubset(): Array<number> {
		if (currentPage < paginationOffset) {
			return pageOptions.slice(0, numPagesShown);
		}
		if (currentPage < lastPage - paginationOffset) {
			return pageOptions.slice(currentPage - paginationOffset, currentPage + paginationOffset - 1);
		}
		return pageOptions.slice(lastPage - numPagesShown - 1, lastPage - 1);
	}

	function renderPageNumbers(): Array<ReactNode> {
		const options = getPaginatedSubset();
		return options.map(option => {
			return (
				<PaginationButton
					onClick={(): void => setPageIndex(option)}
					selected={option === pageIndex}
					id={`pagination-${option}`}
					key={`pagination-${option}`}
				>
					{option + 1}
				</PaginationButton>
			);
		});
	}

	function shouldShowPreviousEllipses(): boolean {
		if (pageOptions.length > numPagesShown) {
			if (currentPage > paginationOffset) {
				return true;
			}
		}
		return false;
	}

	function shouldShowNextEllipses(): boolean {
		if (pageOptions.length > numPagesShown) {
			if (currentPage < lastPage - paginationOffset) {
				return true;
			}
		}
		return false;
	}

	// Listen for changes in pagination and use the state to fetch our new data
	useEffect(() => {
		if (fetchData) {
			fetchData({ pageIndex, pageSize });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageIndex, pageSize]);

	useEffect(() => {
		setPageIndex(forcePageIndex);
	}, [forcePageIndex]);

	if (loading) {
		return <Loader />;
	}

	return (
		<div>
			{errorFetchingData && <ErrorWrapper>{errorFetchingData}</ErrorWrapper>}
			{pageCount > 1 && (
				<PaginationRow backgroundColor={paginationBackgroundColor}>
					<PaginationButtonWrapper>
						<PaginationButton onClick={(): void => setPageIndex(0)} disabled={!canPreviousPage}>
							first
						</PaginationButton>
						<PaginationButton
							onClick={(): void => setPageIndex(index => index - 1)}
							disabled={!canPreviousPage}
						>
							prev
						</PaginationButton>
						{shouldShowPreviousEllipses() && <NextPrevEllipse>...</NextPrevEllipse>}

						{renderPageNumbers()}
						{shouldShowNextEllipses() && <NextPrevEllipse>...</NextPrevEllipse>}

						<PaginationButton onClick={(): void => setPageIndex(index => index + 1)} disabled={!canNextPage}>
							next
						</PaginationButton>
						<PaginationButton onClick={(): void => setPageIndex(pageCount - 1)} disabled={!canNextPage}>
							last
						</PaginationButton>
					</PaginationButtonWrapper>
				</PaginationRow>
			)}
		</div>
	);
};

export default MobilePaginator;
