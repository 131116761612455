import { defineMessages } from 'react-intl';

export const messages = defineMessages({
	breatheIn: {
		id: 'breatheIn',
		defaultMessage: 'Breathe in',
		description: 'Invite users to relax by breathing in',
	},
	breatheOut: {
		id: 'breatheOut',
		defaultMessage: 'Breathe out',
		description: 'Invite users to relax by breathing out',
	},
});
