import styled, { css } from 'styled-components';
import { palette } from 'styled-tools';

import { TextArea, FormInput, Card, fontSize, FontSizes, units } from '@calm-web/design-system';

import { Subtitle } from '@/components/ui/CellTitle';

export const FormInputStyled = styled(FormInput)``;

export const HelperLink = styled.a`
	color: ${palette('blue3')};
	text-decoration: underline;
`;

const rowStyles = css`
	display: flex;
	grid-gap: ${units(2)};
	align-items: center;

	& ${FormInputStyled} {
		margin-bottom: 0;
	}

	& div:first-child {
		flex-grow: 1;
	}
`;
export const RowForm = styled.form`
	${rowStyles}
`;

export const Row = styled.div`
	${rowStyles}
`;

export const SectionStyled = styled.section`
	max-width: ${units(60)};

	${Row} + ${Row} {
		margin-top: ${units(2)};
	}
`;

export const CardStyled = styled(Card)`
	${SectionStyled} + ${SectionStyled} {
		margin-top: ${units(2)};
		margin-bottom: ${units(2)};
	}

	${Subtitle} {
		margin-bottom: 0;
	}
`;

export const StyledTextArea = styled(TextArea)`
	resize: vertical;
	${fontSize(FontSizes.sm)};
	min-height: ${units(12)};
	height: ${units(6)};
`;

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: end;
	width: 100%;
	padding: ${units(0, 3)};
	gap: ${units(2)};
`;
