import styled from 'styled-components';
import { palette } from 'styled-tools';

import { FontSizes, FontWeights, Notice, Text, TextElements, units } from '@calm-web/design-system';

import { boxShadow } from '@/utils/ui/boxShadow';

export const PanelContainer = styled.div`
	position: absolute;
	top: 3rem;
	right: 0;
`;

export const DateRangePanel = styled.div`
	display: flex;
	flex-direction: column;
	width: ${units(50)};
	border: ${units(0.1)} solid ${palette('gray1')};
	border-radius: ${units(1)};
	padding: ${units(2)};
	background-color: white;
	${boxShadow}
`;

export const DateRangeTitle = styled(Text).attrs({
	el: TextElements.Label,
	size: FontSizes.base,
	weight: FontWeights.Medium,
})`
	margin-bottom: ${units(1)};
`;

export const DatePickerGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: ${units(1.2)};
	margin-bottom: ${units(1)};
`;

export const DatePickerTitle = styled(Text).attrs({
	el: TextElements.Label,
	size: FontSizes.sm,
	weight: FontWeights.Medium,
})`
	margin-bottom: ${units(0.5)};
`;

export const ButtonRow = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

export const ErrorMessage = styled(Notice)`
	margin-bottom: ${units(1)};
`;
