import styled from 'styled-components';

import { maxWidth, units } from '@calm-web/design-system';

export const Wrapper = styled.section`
	display: flex;
	gap: ${units(2)};

	${maxWidth('desktopLarge')} {
		flex-wrap: wrap;
	}
`;
export const ConfigWrapper = styled.div`
	max-width: ${units(85)};
`;

export const LogoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: ${units(2)};

	& > :last-child {
		margin-bottom: 0;
		margin-top: ${units(1)};
	}
`;
