import styled from 'styled-components';
import { palette } from 'styled-tools';

import { units } from '@calm-web/design-system';

import InfoOutline from 'icons/info-outline.svg';

export const InfoIcon = styled(InfoOutline)`
	width: ${units(2.5)};
	height: ${units(2.5)};
	margin-left: ${units(0.5)};

	path {
		fill: ${palette('blue3')};
	}
`;
