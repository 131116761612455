import { ReactElement, ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { Subtitle } from '@/components/ui/CellTitle';

import messages from '../messages';
import { Description } from '../styles';

function AccessCodesDescription(): ReactElement {
	const { formatMessage } = useIntl();
	return (
		<>
			<Subtitle>
				{formatMessage(messages.accessCodesSubtitle, {
					b: (...chunks: ReactNode[]) => <b>{chunks}</b>,
				})}
			</Subtitle>
			<Description topMargin={false}>{formatMessage(messages.accessCodesDescription)}</Description>
		</>
	);
}

export default AccessCodesDescription;
