import { ReactElement, ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';

import { PrimaryButton, SecondaryButton, Loader } from '@calm-web/design-system';

import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import { MIN_COVERED_LIVES, MAX_COVERED_LIVES_INCREASE } from '@/hooks/api/useDiscountedPricing';
import { useInvoice } from '@/hooks/api/useInvoice';
import { useSubscription, useUpdateSubscription } from '@/hooks/api/useSubscription';
import { useIsMobile } from '@/hooks/layout/useIsMobile';

import { PrimaryTextButton } from '../../styles';
import CoveredLivesMessage from '../CoveredLivesMessage';
import { PriceComparison } from '../PriceComparison';
import { CoveredLivesPicker } from './CoveredLivesPicker';
import { WarningMessage } from './WarningMessage';
import messages from './messages';
import {
	ButtonGroup,
	CoveredLivesTitle,
	Row,
	UpdateForm,
	CoveredLivesReactivate,
	PlanAndPaymentLink,
} from './styles';

type PriceStatus = 'calculated' | 'uncalculated' | undefined;

function canCalculateNewPrice(
	coveredLives: number,
	previousCoveredLives: number,
	isAccountManager: boolean,
): boolean {
	const overMaxLives = coveredLives > MAX_COVERED_LIVES_INCREASE;

	if (overMaxLives) {
		return false;
	}

	if (coveredLives === previousCoveredLives) {
		return false;
	}

	if (isAccountManager) {
		return true;
	}

	const decreasingLives = coveredLives < previousCoveredLives;
	if (decreasingLives) {
		return false;
	}
	return true;
}

function canUpdateCoverage(
	priceStatus: PriceStatus,
	loading: boolean,
	coveredLives: number,
	previousCoveredLives: number,
	isAccountManager: boolean,
): boolean {
	if (loading) {
		return false;
	}

	if (priceStatus !== 'calculated') {
		return false;
	}

	const isDisabledCalculateNewPrice = canCalculateNewPrice(
		coveredLives,
		previousCoveredLives,
		isAccountManager,
	);
	return isDisabledCalculateNewPrice;
}

interface Props {
	partnerId: string;
	previousCoveredLives: number;
	onCancel: () => void;
	onSubmit: () => void;
	isAccountManager?: boolean;
}

interface UpdatePricePrimaryButtonProps {
	onPress: () => void;
	fullWidth?: boolean;
	isDisabled: boolean;
	dataTestId: string;
	children: ReactNode;
}

export const UpdatePricePrimaryButton: React.FC<UpdatePricePrimaryButtonProps> = ({
	onPress,
	fullWidth,
	isDisabled,
	dataTestId,
	children,
}) => {
	return (
		<PrimaryButton
			onPress={onPress}
			type="button"
			fullWidth={fullWidth}
			isDisabled={isDisabled}
			data-testid={dataTestId}
		>
			{children}
		</PrimaryButton>
	);
};

export function UpdatePrice({
	partnerId,
	previousCoveredLives,
	onCancel,
	onSubmit,
	isAccountManager = false,
}: Props): ReactElement {
	const history = useHistory();
	const { search } = useLocation();
	const { logEvent } = useAnalytics();
	const { data: subscription, loading: subscriptionLoading } = useSubscription(partnerId);

	const isExpired = Boolean(subscription?.canceled_at);

	const [coveredLives, setCoveredLives] = useState(previousCoveredLives);
	const [nextCoveredLives, setNextCoveredLives] = useState(0);
	const [updateSubscription, { loading, error }] = useUpdateSubscription(partnerId);
	const { loading: proratedLoading, data: proratedInvoice } = useInvoice(partnerId, coveredLives);
	const [priceStatus, setPriceStatus] = useState<PriceStatus>();
	const [isMobile] = useIsMobile();

	const { formatMessage } = useIntl();

	async function handleUpdate(): Promise<void> {
		try {
			await updateSubscription(nextCoveredLives);
			onSubmit();
		} catch (err) {
			// Nothing to be done here, the hook will populate the error variable for us
		}
	}

	function handleClick(): void {
		logEvent('Partner Portal : Update coverage : Calculate price');

		setPriceStatus('calculated');
		setNextCoveredLives(coveredLives);
	}

	function handleCoveredLivesChange(amount: number): void {
		logEvent('Partner Portal : Update coverage : Covered lives change');
		setPriceStatus('uncalculated');
		setCoveredLives(amount);
	}

	function resetPriceStatus(): void {
		setPriceStatus(undefined);
	}

	const onPlanAndPaymentsClick = (): void => {
		history.push({
			pathname: `/${partnerId}/plan`,
			search,
		});
	};

	let updateButtonText;
	if (loading) {
		updateButtonText = 'Updating';
	} else if (error) {
		updateButtonText = 'Error';
	} else {
		updateButtonText = 'Update coverage';
	}

	const isDisabledCalculateNewPrice = !canCalculateNewPrice(
		coveredLives,
		previousCoveredLives,
		isAccountManager,
	);
	const isDisabledUpdateCoverage = !canUpdateCoverage(
		priceStatus,
		loading,
		coveredLives,
		previousCoveredLives,
		isAccountManager,
	);

	const isCalculated = priceStatus === 'calculated';
	const overMaxLives = coveredLives > MAX_COVERED_LIVES_INCREASE;

	return (
		<UpdateForm>
			{subscriptionLoading ? (
				<Loader color="gray7" />
			) : (
				<>
					{isExpired ? (
						<CoveredLivesReactivate>
							{formatMessage(messages.coveredLivesReactivate, {
								planAndPaymentLink: (...chunks: ReactNode[]) => (
									<PlanAndPaymentLink onClick={onPlanAndPaymentsClick}>{chunks}</PlanAndPaymentLink>
								),
							})}
						</CoveredLivesReactivate>
					) : (
						<>
							<CoveredLivesTitle>{formatMessage(messages.coveredLivesLabel)}</CoveredLivesTitle>
							<Row>
								<CoveredLivesPicker
									coveredLives={coveredLives}
									onChange={handleCoveredLivesChange}
									isCalculated={isCalculated}
								/>
								{isCalculated ? (
									<PrimaryTextButton onClick={resetPriceStatus}>
										{formatMessage(messages.editButtonText)}
									</PrimaryTextButton>
								) : (
									<UpdatePricePrimaryButton
										onPress={handleClick}
										fullWidth={isMobile}
										isDisabled={isDisabledCalculateNewPrice}
										dataTestId="calculate-new-price-button"
									>
										{formatMessage(messages.calculateButtonText)}
									</UpdatePricePrimaryButton>
								)}
							</Row>
							{overMaxLives ? (
								<CoveredLivesMessage
									coveredLives={coveredLives}
									min={MIN_COVERED_LIVES}
									max={MAX_COVERED_LIVES_INCREASE}
								/>
							) : (
								<>
									<PriceComparison
										coveredLives={nextCoveredLives}
										previousCoveredLives={previousCoveredLives}
										partnerId={partnerId}
									/>
									<WarningMessage
										isUncalculated={!isCalculated}
										isAccountManager={isAccountManager}
										coveredLives={coveredLives}
										previousCoveredLives={previousCoveredLives}
										proratedInvoice={proratedInvoice}
										proratedLoading={proratedLoading}
									/>
									<ButtonGroup>
										<SecondaryButton type="button" onPress={onCancel}>
											{formatMessage(messages.cancelButtonText)}
										</SecondaryButton>
										<UpdatePricePrimaryButton
											onPress={handleUpdate}
											isDisabled={isDisabledUpdateCoverage}
											dataTestId="update-coverage-button"
										>
											{updateButtonText}
										</UpdatePricePrimaryButton>
									</ButtonGroup>
								</>
							)}
						</>
					)}
				</>
			)}
		</UpdateForm>
	);
}
