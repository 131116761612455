import styled from 'styled-components';
import { palette } from 'styled-tools';

import { PaletteColor, Text, units } from '@calm-web/design-system';

import { boxShadow } from '@/utils/ui/boxShadow';

export function getDeltaColor(delta?: number): PaletteColor {
	if (!delta || delta === 0) return 'text';
	return delta < 0 ? 'errorRed' : 'buttonGreen';
}

export const StatsWrapper = styled.a`
	display: flex;
	flex-direction: column;
	background-color: ${palette('white')};
	border-radius: ${units(2)};
	padding: ${units(3)};
	flex: 1;
	height: 100%;
	transition: box-shadow 0.3s ease-in-out;
	text-decoration: none;

	&:focus,
	&:hover {
		${boxShadow};
		z-index: 1;
	}
`;

export const Title = styled(Text)`
	flex: 1;
`;

export const DeltaChange = styled(Text)`
	margin-right: ${units(0.5)};
`;
