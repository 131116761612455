import styled from 'styled-components';

import { FormInput, minWidth, fontWeight, FontWeights, units } from '@calm-web/design-system';

export const Label = styled.label`
	${fontWeight(FontWeights.Medium)};
`;

export const DarkInput = styled(FormInput)`
	min-width: ${units(20)};
	margin-bottom: 0;

	${minWidth('desktop')} {
		max-width: ${units(30)};
	}
`;
