import { defineMessages } from 'react-intl';

export default defineMessages({
	integrationTitle: {
		id: 'account.integrationTitle',
		defaultMessage: 'Integration',
		description: 'A title above a section where the user can edit Integration config',
	},
	groupCodesLabel: {
		id: 'account.integrationDetails.groupCodes.label',
		defaultMessage: 'Group code',
		description: 'Label for group codes option in integration type',
	},
	groupCodesSubtitle: {
		id: 'account.integrationDetails.groupCodes.subtitle',
		defaultMessage:
			'Create one unique redemption code for your covered lives to redeem via \u201cLink Subscription\u201d located in the Calm app\u2019s settings. When the covered lives limit is reached, no more users can be added.',
		description: 'Subtitle for group code option in integration type',
	},
	userIdTitle: {
		id: 'account.integrationDetails.userIdTitle',
		defaultMessage: 'User Identifier',
		description: 'Cell title for user identifier',
	},
	sftpTitle: {
		id: 'createPartner.sftpTitle',
		defaultMessage: 'SFTP Integration',
		description: 'A title above a section where the user can edit SFTP integration',
	},
	sftpSubtitle: {
		id: 'createPartner.sftpSubtitle',
		defaultMessage: `To get started with setting up your integration you'll need to enter the contact information of your IT Administrator, and they can reference the following <link>instructions</link> which we'll send via email.`,
		description: 'Subtitle for SFTP integration',
	},
});
