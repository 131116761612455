import styled from 'styled-components';
import { palette } from 'styled-tools';

import { Text, TextElements, units } from '@calm-web/design-system';

import InfoOutline from 'icons/info-outline.svg';

export const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 0;
	flex-wrap: wrap;
`;

export const Category = styled(Text).attrs({ el: TextElements.P })`
	font-size: 18px;
	line-height: 27px;
	@media only screen and (max-width: 1200px) {
		font-size: 13px;
		line-height: 20px;
	}
`;

export const Value = styled(Text).attrs({ el: TextElements.P })`
	font-weight: 700;
	font-size: 31.5px;
	line-height: 47px;
	margin: 0;
	@media only screen and (max-width: 1200px) {
		font-size: 22px;
		line-height: 34px;
	}
`;

export const StatWrapper = styled.div`
	padding: 0 ${units(1)};
`;

export const DefinitionWrapper = styled.div`
	margin-top: ${units(1)};
	text-align: center;
`;

export const InfoIconContainer = styled.div`
	display: inline;
	margin-left: ${units(1.5)};
`;

export const ExplainerInfo = styled(InfoOutline)`
	width: ${units(2.5)};
	height: ${units(2.5)};
	position: relative;
	top: 4px;

	path {
		fill: ${palette('blue3')};
	}
`;
