import styled from 'styled-components';

import { units, Text, FontSizes, FontWeights, TextAlign, TextElements, Card } from '@calm-web/design-system';

const scale = 0.75;
export const ViewPort = styled.div`
	z-index: -1;
	position: relative;
	overflow-y: hidden;
	overflow-x: hidden;
	transform: scale(${scale});
	height: calc((100% - 18px) / ${scale});
	width: calc((100% - 18px) / ${scale});
	margin: calc(9px / ${scale});
	left: -${(100 / scale - 100) / 2}%;
	top: -${(100 / scale - 100) / 2}%;
	background: linear-gradient(#e2f6ff, #fdf7f9);
`;

export const LogoBar = styled.div`
	position: absolute;
	z-index: 1;
	top: ${units(6.75)};
	height: ${units(4)};
	display: flex;
	align-items: center;
	width: 100%;
	padding: ${units(0, 2)};
`;

export const CalmHealthLogoWrapper = styled.div`
	flex-grow: 1;
	display: flex;
`;

export const SignIn = styled(Text).attrs({
	color: 'white',
	weight: FontWeights.Medium,
})`
	line-height: 150%;
`;

export const BannerTitle = styled(Text).attrs({
	size: FontSizes.lg,
	weight: FontWeights.Medium,
	el: TextElements.Div,
})`
	margin-bottom: ${units(2)};
`;

export const Hero = styled.div`
	height: ${units(40)};
	position: relative;
	background: #e3f5ff;
	display: flex;
	justify-content: bottom;
`;

export const HeroFade = styled.div`
	position: absolute;
	/* small bottom extension to make sure there's no gap transition smooth */
	bottom: -1px;
	width: 100%;
	height: ${units(10)};
	background: linear-gradient(transparent, #e3f5ff);
`;

export const Content = styled.div`
	padding: ${units(3, 2)};
	background: #e3f5ff;
	height: 100%;
	overflow-wrap: break-word;
`;

export const BannerBody = styled(Text).attrs({
	el: TextElements.Div,
})`
	margin-bottom: ${units(5)};
`;

export const DownloadCalm = styled(Card)`
	padding: ${units(3)};
`;

export const DownloadCalmCta = styled(Text).attrs({
	weight: FontWeights.Medium,
	align: TextAlign.Center,
	el: TextElements.Div,
})``;

export const CalmHealthAppIcon = styled.div`
	margin-bottom: ${units(1)};
`;
