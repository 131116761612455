import styled from 'styled-components';
import { palette } from 'styled-tools';

import { PrimaryButton, units } from '@calm-web/design-system';

export const ContentWrapper = styled.div`
	padding: ${units(3, 2, 3, 2)};
	max-height: ${units(60)};
	overflow-y: auto;
`;
export const ButtonWrapper = styled.div`
	display: flex;
	justify-content: right;
	padding: ${units(1)};
	max-height: ${units(60)};
	overflow-y: auto;
	border-top: 1px solid ${palette('gray2')};
`;

export const PrimaryButtonStyled = styled(PrimaryButton)`
	white-space: nowrap;
`;
