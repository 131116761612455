import { FC, MutableRefObject, ReactElement, Ref } from 'react';
import { useIntl } from 'react-intl';
import { ThemeProvider } from 'styled-components';

import { CalmTheme, FontWeights, Text, Tooltip } from '@calm-web/design-system';

import AdminLaunchEmail from '@/components/ui/LaunchTemplates/AdminLaunchEmail';
import NewAdminLaunchEmail from '@/components/ui/LaunchTemplates/NewAdminLaunchEmail';
import NonEfOrEmailTemplate from '@/components/ui/LaunchTemplates/NonEfOrEmailTemplate';
import OgLaunchTemplate from '@/components/ui/LaunchTemplates/OGLaunchTemplate';
import { useShouldShowLaunchEmailSignupMethods } from '@/hooks/useShouldShowLaunchEmailSignupMethods';
import { window } from '@/utils/window';

import CustomMessage from './CustomMessage';
import messages from './messages';
import { Wrapper, EmailContainer, EmailTopBar, ContentWrapper, CopyOnlyWrapper, InfoIcon } from './styles';

export async function executeCopyTemplate(emailTemplate: HTMLDivElement): Promise<void> {
	if (window.ClipboardItem) {
		// Prefer this implementation over copy-to-clipboard lib to preserve formatting.
		const clipboardItem = new window.ClipboardItem({
			'text/html': new Blob([emailTemplate.outerHTML], { type: 'text/html' }),
			'text/plain': new Blob([emailTemplate.innerText], { type: 'text/plain' }),
		});
		await window.navigator.clipboard.write([clipboardItem]);
	} else {
		// At the time of writing FF doesn't support ClipboardItem or clipboard.write()
		// Since we are limited to '.writeText()' we lose rich text support and have to reference the actual links in the email template
		const addedHelpCenterLink = emailTemplate.innerText.replace(
			'Help Center article',
			'Help Center article: https://support.calm.com/hc/en-us/articles/360055261934-How-to-redeem-your-subscription-provided-by-your-employer',
		);
		const addedPrivacyPolicyLink = addedHelpCenterLink.replace(
			'calm.com/privacy',
			'https://calm.com/privacy',
		);
		await window.navigator.clipboard.writeText(addedPrivacyPolicyLink);
	}
}

export function EmailTemplate({
	showOldTemplate,
	hideSendOption,
	emailRef,
	signupLink,
	showMessageSection,
	setShowMessageSection,
	customMessageRef,
}: {
	showOldTemplate: boolean;
	hideSendOption: boolean;
	emailRef: Ref<HTMLDivElement>;
	signupLink: string;
	showMessageSection: boolean;
	setShowMessageSection?: (b: boolean) => void;
	customMessageRef?: MutableRefObject<string>;
}): ReactElement | null {
	const shouldShowLaunchEmailSignUpMethods = useShouldShowLaunchEmailSignupMethods();
	const { formatMessage } = useIntl();
	const CustomMessageBlock: FC = () => {
		if (!showMessageSection || hideSendOption || !customMessageRef || !setShowMessageSection) {
			return null;
		}

		return (
			<CustomMessage customMessageRef={customMessageRef} setShowMessageSection={setShowMessageSection} />
		);
	};

	return (
		<>
			{showOldTemplate || hideSendOption ? (
				<>
					{shouldShowLaunchEmailSignUpMethods ? (
						<span data-testid="old-non-ef-email-template">
							<CopyOnlyWrapper ref={emailRef}>
								<NonEfOrEmailTemplate signupLink={signupLink} />
							</CopyOnlyWrapper>
						</span>
					) : (
						<span data-testid="og-email-template">
							<CopyOnlyWrapper ref={emailRef}>
								<OgLaunchTemplate signupLink={signupLink} />
							</CopyOnlyWrapper>
						</span>
					)}
				</>
			) : (
				<ThemeProvider theme={CalmTheme}>
					<Wrapper>
						<EmailContainer>
							<EmailTopBar>
								<Text el="span" color="white" weight={FontWeights.Medium}>
									{formatMessage(messages.emailPreviewWindowTitle)}
								</Text>
							</EmailTopBar>
							<ContentWrapper>
								<Text el="span" weight={FontWeights.Medium}>
									From:{' '}
								</Text>
								<Text el="span">hello@breathe.calm.com</Text>
								<Tooltip content={<Text el="span">Please ask your IT team to whitelist this domain.</Text>}>
									<InfoIcon />
								</Tooltip>
							</ContentWrapper>
							<ContentWrapper>
								<Text el="span" weight={FontWeights.Medium}>
									{formatMessage(messages.subject)}{' '}
								</Text>
								<Text el="span">{formatMessage(messages.subjectLine)}</Text>
							</ContentWrapper>
							<>
								{shouldShowLaunchEmailSignUpMethods ? (
									<span data-testid="new-admin-launch-email">
										<ContentWrapper ref={emailRef}>
											<NewAdminLaunchEmail signupLink={signupLink} CustomMessageBlock={CustomMessageBlock} />
										</ContentWrapper>
									</span>
								) : (
									<span data-testid="old-admin-launch-email">
										<ContentWrapper ref={emailRef}>
											<AdminLaunchEmail signupLink={signupLink} CustomMessageBlock={CustomMessageBlock} />
										</ContentWrapper>
									</span>
								)}
							</>
						</EmailContainer>
					</Wrapper>
				</ThemeProvider>
			)}
		</>
	);
}
