import { FC } from 'react';
import { useIntl } from 'react-intl';

import { FontSizes, FontWeights, Text } from '@calm-web/design-system';

import AdminUsers from '@/components/pages/Account/AdminUsers';
import MsftTeamsTenant from '@/components/pages/Account/MsftTeamsTenant';
import NameAndId from '@/components/pages/Account/NameAndId';
import PlanDetails from '@/components/pages/Account/PlanDetails';
import { usePermissions } from '@/hooks/auth';
import { usePartnerSubmitData, type EditPartnerFormProps } from '@/hooks/forms/usePartnerForm';
import { useUser } from '@/hooks/store';
import { Partner } from '@/types/store/reducers';
import { isPartnerAdmin } from '@/utils/RBAC';
import { isCalmHealthSKU } from '@/utils/SkuUtils';

import messages from './messages';

interface AccountDetailsProps {
	isAccountManager: boolean;
	partner?: Partner;
	formProps: EditPartnerFormProps;
}

const AccountDetails: FC<AccountDetailsProps> = ({ isAccountManager, partner, formProps }) => {
	const { formatMessage } = useIntl();
	const [hasValidPermissions, actions] = usePermissions();
	const { vouchedPlanSku } = usePartnerSubmitData(formProps, partner);
	const { user } = useUser();

	const partnerId = partner?.id;
	const shouldShowPlanDetails = hasValidPermissions('contract_expires_at', [actions.READ]);
	const isCalmHealth = isCalmHealthSKU(vouchedPlanSku);
	const userIsPartnerAdmin = isPartnerAdmin(user.accessPolicy?.allowed_user_role);
	const isViewOnly = userIsPartnerAdmin && isCalmHealth;

	return (
		<section>
			<Text el="h2" size={FontSizes.xl} weight={FontWeights.Regular} color="gray7">
				{formatMessage(messages.accountDetailsTitle)}
			</Text>
			<NameAndId
				formProps={formProps}
				isEdit={!!partnerId}
				partner={partner}
				isAccountManager={isAccountManager}
				isViewOnly={isViewOnly}
			/>
			{!isCalmHealth && <MsftTeamsTenant formProps={formProps} isEdit={!!partnerId} />}
			{shouldShowPlanDetails && <PlanDetails formProps={formProps} isEdit={!!partnerId} partner={partner} />}
			{partnerId && hasValidPermissions('admin_users', [actions.READ]) && (
				<AdminUsers category={partner?.category} isViewOnly={isViewOnly} />
			)}
		</section>
	);
};

export default AccountDetails;
