import { defineMessages } from 'react-intl';

export default defineMessages({
	hasReactivatedText: {
		id: 'plan.planModal.hasReactivatedText',
		defaultMessage: 'Your plan has been reactivated.',
		description: 'Text confirming their plan is canceled',
	},
	nextBillText: {
		id: 'plan.planModal.nextBillText',
		defaultMessage:
			'You should expect to be billed {billTotal} on {billDate} for {coveredLives} covered lives.',
		description: 'Text confirming their next future billing details',
	},
	done: {
		id: 'plan.planModal.closeModal',
		defaultMessage: 'Done',
		description: 'Indicates the reactivation request in progress',
	},
});
