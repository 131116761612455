import styled from 'styled-components';
import { palette } from 'styled-tools';

import { units } from '@calm-web/design-system';

export const Container = styled.div`
	position: relative;
`;

export const ResultsContainer = styled.div`
	position: absolute;
	z-index: 1;
	top: 100%;
	left: 0;
	right: 0;
	margin-top: ${units(1)};
	padding: ${units(1.5)};
	background-color: white;
	border: 1px solid ${palette('gray1')};
	border-radius: 3px;
	box-shadow: ${units(0, 1, 2, 0)} rgba(0, 0, 0, 0.15);
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 0.75rem;
`;

export const StyledPartnerRow = styled.div`
	width: 100%;
	cursor: pointer;
`;
