import { FC } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { Loader } from '@calm-web/design-system';

import InitialPageAnalytics from '@/components/analytics/InitialPageAnalytics';
import { useActionPlans } from '@/hooks/api/useActionPlan';
import { useDefinedPartner } from '@/hooks/api/usePartner';
import { isCalmHealthProductSKU } from '@/utils/SkuUtils';

import ActionPlanSection from './ActionPlan';
import GetSetupSection from './GetSetup';
import { LaunchAnnouncementModal } from './LaunchAnnouncementModal';
import SignupReminderModal from './SignupReminderModal';
import Stats from './Stats';
import { Wrapper } from './styles';

export default function Home(): ReturnType<FC> {
	const { partnerId } = useParams();
	const { data, loading } = useActionPlans(partnerId);
	const partnerData = useDefinedPartner();
	const history = useHistory();
	const { search } = useLocation();

	if (loading) {
		return <Loader color="gray1" />;
	}

	if (isCalmHealthProductSKU(partnerData?.product_sku)) {
		history.push({ pathname: `/${partnerId}/account`, search });
	}

	return (
		<Wrapper>
			<InitialPageAnalytics />
			<Stats />
			{data?.getSetupComplete ? (
				<ActionPlanSection actionPlanStatuses={data?.actionPlanStatuses} />
			) : (
				<GetSetupSection getSetupStatuses={data?.getSetupStatuses} />
			)}
			<LaunchAnnouncementModal />
			<SignupReminderModal />
		</Wrapper>
	);
}
