import styled from 'styled-components';

import { maxWidth } from '@calm-web/design-system';

import { PaginationButton } from '../Table/styles';

export const NextPrevEllipse = styled(PaginationButton)`
	display: inline-block;

	${maxWidth('mobileLarge')} {
		display: none;
	}
`;
