import styled, { css } from 'styled-components';
import { ifNotProp, ifProp, palette, prop } from 'styled-tools';

import {
	FontSizes,
	fontSize,
	Text,
	TextAlign,
	TextElements,
	maxWidth,
	TextButton,
	fontWeight,
	FontWeights,
	units,
} from '@calm-web/design-system';

export const Container = styled.div`
	padding: 0 ${units(2)};
`;

export const ViewButton = styled(TextButton).attrs({ hideUnderline: true, textColor: 'blue3' })`
	${fontSize(FontSizes.sm)};
	${fontWeight(FontWeights.Medium)};
	line-height: 20px;
	letter-spacing: 0px;
`;

export const MobileDataColumn = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${units(1)};
`;

export const MobileGridWrapper = styled.div`
	display: grid;
	grid-auto-flow: column;
	grid-template-columns: ${units(2)} ${units(8)} repeat(3, 1fr);
	gap: ${units(2)};
	padding: ${units(1, 0, 1, 4)};

	${maxWidth('mobileLarge')} {
		grid-template-columns: ${units(8)} 1fr;
		grid-template-rows: auto 1fr;
		padding: ${units(2, 0, 2, 2)};
	}
`;

export const MobileTopContentWrapper = styled.div`
	${MobileGridWrapper}:nth-child(even) {
		background-color: ${palette('gray0')};
	}
`;

export const MobileLabel = styled.span`
	color: ${palette('gray5')};
	text-transform: uppercase;
	white-space: nowrap;
	display: block;
	${fontSize(FontSizes.sm)};
`;

export const CellImage = styled.img<{
	width: number;
	noMargin?: boolean;
	verticalAlign?: string;
}>`
	object-fit: cover;
	width: ${props => units(props.width)};
	height: ${props => units(props.width)};
	border-radius: 4px;
	${ifNotProp(
		'noMargin',
		css`
			margin-right: ${units(1)};
		`,
	)};
	${ifProp(
		'verticalAlign',
		css`
			vertical-align: ${prop('verticalAlign')};
		`,
	)};
`;

export const TableNote = styled.div`
	${fontSize(FontSizes.sm)};
	margin-top: ${units(2)};
`;

export const ModalTitle = styled(Text).attrs({
	el: TextElements.H2,
	weight: FontWeights.Regular,
	color: 'gray7',
	noMargin: true,
	size: FontSizes.base,
	align: TextAlign.Left,
})``;

export const ModalSubtitle = styled(Text).attrs({
	el: TextElements.H3,
	weight: FontWeights.Regular,
	color: 'gray5',
	noMargin: true,
	size: FontSizes.sm,
	align: TextAlign.Left,
})``;
