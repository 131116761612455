import { defineMessages } from 'react-intl';

export default defineMessages({
	title: {
		id: 'getSetup.announce.modal.title',
		defaultMessage: 'Email Announcement',
		description: "Modal title showing the partner's Calm launch announcement",
	},
	announceCta: {
		id: 'getSetup.announce.cta',
		defaultMessage: 'Get Started',
		description: 'Button copy that opens the email announcement modal',
	},
});
