import { FC, ReactNode } from 'react';

import { CalloutContainer } from './styles';

interface Props {
	children: ReactNode;
}

const Callout: FC<Props> = ({ children }) => {
	return <CalloutContainer>{children}</CalloutContainer>;
};

export default Callout;
