import styled from 'styled-components';

export const FullPageWrapper = styled.div`
	width: 200px;
	height: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
`;
