import styled from 'styled-components';

import { FontSizes, fontWeight, FontWeights, TextButton, units } from '@calm-web/design-system';

export const PrimaryTextButton = styled(TextButton).attrs({
	textColor: 'blue3',
	hideUnderline: true,
	size: FontSizes.sm,
})`
	${fontWeight(FontWeights.Demi)};
`;

export const Container = styled.span`
	margin-left: ${units(0.5)};
`;
