import { rgba } from 'polished';
import styled, { css } from 'styled-components';
import { palette } from 'styled-tools';

import {
	ButtonSizes,
	CloseButton,
	SecondaryButton,
	Button,
	FontSizes,
	fontSize,
	fontWeight,
	FontWeights,
	units,
} from '@calm-web/design-system';

import { boxShadow } from '@/utils/ui/boxShadow';

import CircleArrow from 'icons/circle-arrow.svg';
import _GiftFilledIcon from 'icons/gift-icon-simple.svg';
import Gift from 'icons/gift-outline.svg';

export const Background = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	width: 100%;
	height: 100vh;
	background: ${(props): string => rgba(palette('black')(props), 0.08)};
`;

export const Body = styled.div``;

export const ButtonGroup = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Title = styled.div`
	${fontWeight(FontWeights.Demi)};
`;

export const Popup = styled.div`
	position: fixed;
	top: ${units(0.5)};
	right: ${units(1.5)};
	width: ${units(30)};
	${fontSize(FontSizes.sm)};
	color: ${palette('gray8')};
	${fontWeight(FontWeights.Medium)};
	border-radius: 6px;
	border: 1px solid ${palette('gray1')};
	line-height: ${units(2.5)};
	${boxShadow}
	/* Needed to get border-radius working when children have backgrounds */
  overflow: hidden;

	${Title} {
		margin-top: ${units(2)};
		margin-bottom: ${units(0.5)};
	}
`;

export const CtaButton = styled(Button).attrs({
	backgroundColor: 'gradientPink',
})`
	text-transform: uppercase;
`;

export const DismissButton = styled(SecondaryButton).attrs({ size: ButtonSizes.Sm })`
	margin-bottom: ${units(-2)};
`;

export const ButtonContainer = styled.div`
	${CtaButton} {
		width: 100%;
	}
`;

export const TextSection = styled.div`
	padding: ${units(2)};
	background: linear-gradient(180deg, ${palette('white')} 0%, ${palette('gray0')} 100%);
	border-bottom: 1px solid ${palette('gray1')};
`;

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	margin: ${units(-2)};
`;

export const GiftFilledIcon = styled(_GiftFilledIcon)``;

export const ModalContent = styled.div`
	text-align: center;
	padding: ${units(3)};

	${Title} {
		${fontSize(FontSizes.lg)};
		margin-top: ${units(3)};
	}

	${Body} {
		${fontSize(FontSizes.sm)};
		margin-top: ${units(1)};
	}

	${ButtonGroup} {
		margin-top: ${units(2)};
	}

	${ButtonContainer} + ${ButtonContainer} {
		margin-top: ${units(1)};
	}

	${DismissButton} {
		${fontSize(FontSizes.sm)}
	}

	${GiftFilledIcon} {
		width: ${units(5)};
		height: ${units(4)};
	}
`;

export const MoreContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin: ${units(-2)};
	margin-top: 0;
`;

export const MoreButton = styled(SecondaryButton).attrs({ size: ButtonSizes.Sm })``;

const iconsPadding = css`
	padding: ${units(2)};
`;

export const StyledCloseButton = styled(CloseButton).attrs({ color: 'blue3' })`
	${iconsPadding}
`;

export const GiftIcon = styled(Gift)`
	box-sizing: content-box;
	width: ${units(3)};
	height: ${units(3)};
	path {
		fill: ${palette('blue3')};
	}
	${iconsPadding}
`;

export const LinkSection = styled.div`
	padding: ${units(2)};
	padding-top: 0;
	background-color: ${palette('white')};
	button {
		display: inherit;
		margin-top: ${units(2)};
	}
`;

export const CircleArrowIcon = styled(CircleArrow)`
	width: 20px;
	path {
		fill: ${palette('blue3')};
	}
	margin-left: ${units(0.5)};
	position: relative;
	top: 1px;
`;
