import { defineMessages } from 'react-intl';

export default defineMessages({
	achPaymentMethodInsufficientFundsError: {
		id: 'achPaymentMethodInsufficientFundsError',
		defaultMessage:
			'Payment processing has failed and will automatically try again within the next few days. Please review your payment method and ensure there are enough funds.',
		description: 'Error message when the ACH payment method processing has failed due to insufficient funds',
	},
	achPaymentMethodProcessingFailedError: {
		id: 'achPaymentMethodProcessingFailedError',
		defaultMessage:
			'Payment processing has failed and the subscription has been cancelled. Please update your payment method when reactivating the subscription.',
		description: 'Error message when the ACH payment method processing has failed',
	},
	achPaymentMethodInsufficientFundsBannerError: {
		id: 'achPaymentMethodInsufficientFundsBannerError',
		defaultMessage:
			'Payment processing has failed and will automatically try again within the next few days. Please <changedetails>review your payment method</changedetails> and ensure there are enough funds.',
		description: 'Error message when the ACH payment method processing has failed due to insufficient funds',
	},
	achPaymentMethodProcessingFailedBannerError: {
		id: 'achPaymentMethodProcessingFailedBannerError',
		defaultMessage:
			'Payment processing has failed and the subscription has been cancelled. Please update your payment method when <changedetails>reactivating the subscription</changedetails>.',
		description: 'Error message when the ACH payment method processing has failed',
	},
	getPaymentInfoError: {
		id: 'getPaymentInfoError',
		defaultMessage: 'An error occurred while fetching payment information.',
		description: 'Error message when an error occurs while fetching payment information',
	},
	achPaymentMethodProcessing: {
		id: 'achPaymentMethodProcessing',
		defaultMessage: 'Your payment is now processing. This can take up to 5 days.',
		description: 'Message when the ACH payment method is processing',
	},
	achProcessingTitle: {
		id: 'achModal.processingTitle',
		defaultMessage: 'Payment is processing',
		description: 'Title for the ACH modal when processing payment',
	},
	achProcessingBody: {
		id: 'achModal.processingBody',
		defaultMessage:
			'Your ACH payment method is processing. This typically takes 5 days.<newline></newline><newline></newline>You can still get set up and start using Calm while you wait. Please note that if your payment method fails, you and your employees will lose access to Calm until your payment method is updated.',
		description: 'First paragraph of the ACH modal body when processing payment',
	},
	achSucceededTitle: {
		id: 'achModal.succeededTitle',
		defaultMessage: 'Your payment has processed',
		description: 'Title for the ACH modal when payment has succeeded',
	},
	achSucceededBody: {
		id: 'achModal.succeededBody',
		defaultMessage: 'Your payment method has been processed. Enjoy using Calm and the Partner Portal!',
		description: 'First paragraph of the ACH modal body when payment has succeeded',
	},
	achSucceededButtonText: {
		id: 'achModal.succeededButtonText',
		defaultMessage: 'Done',
		description: 'Text for button in ACH modal when payment has succeeded',
	},
	achButtonText: {
		id: 'achModal.button',
		defaultMessage: 'I understand',
		description: 'Text for the button in the ACH modal',
	},
});
