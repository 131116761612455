import { useDispatch } from 'react-redux';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';

import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import { useApi } from '@/hooks/api';
import { useDefinedPartner } from '@/hooks/api/usePartner';
import { setBannerMessage } from '@/store/actions';
import { CalmError, isCalmError, getCalmErrorOrError } from '@/utils/apiRequest/errors';
import { calmLogger } from '@/utils/calmLogger';

export interface RecordObject {
	id: string;
	partner_id: string;
	campaign_id: string;
	scheduled_send_date: string;
	sent_at?: string | undefined;
	deleted_at?: string | undefined;
}

export interface GetRecordsResponse {
	past_records: RecordObject[] | undefined;
	future_record: RecordObject | undefined;
}

export interface SetPartnerEmailRecordProps {
	campaign_id: string;
	new_date: Date;
}

interface ApiResponse<T> {
	data?: T;
	loading: boolean;
	error?: Error | CalmError;
}

export function usePartnerEmailRecords({
	campaignId,
}: {
	campaignId: string;
}): ApiResponse<GetRecordsResponse> {
	const apiRequest = useApi();
	const partner = useDefinedPartner();
	const dispatch = useDispatch();
	const isEdit = !!partner.id;

	const { data, isLoading, error } = useSWR<GetRecordsResponse | undefined>(
		`b2b/partners/${partner.id}/email-records?campaign_id=${campaignId}`,
		async endpoint => {
			try {
				if (isEdit) {
					const response = await apiRequest({ endpoint });
					return response.data as GetRecordsResponse;
				}
				return;
			} catch (err) {
				calmLogger.error('Error when fetching partner email records', {}, err);
				if (isEdit) {
					dispatch(
						setBannerMessage({
							message: 'Failed to get feedback survey date records',
							isError: true,
							flash: true,
						}),
					);
				}
				throw err;
			}
		},
	);
	return {
		loading: isLoading,
		data: {
			past_records: data?.past_records,
			future_record: data?.future_record,
		},
		error,
	};
}

type EmailRecordsFunction = (input: { new_date: Date }) => Promise<SetPartnerEmailRecordProps>;
export function useSavePartnerEmailRecord(
	campaignId: string,
): [EmailRecordsFunction, ApiResponse<SetPartnerEmailRecordProps>] {
	const apiRequest = useApi();
	const partner = useDefinedPartner();
	const dispatch = useDispatch();
	const { logEvent } = useAnalytics();

	const configEndpoint = `b2b/partners/${partner.id}/email-records`;
	const { trigger, isMutating, error, data } = useSWRMutation(
		`${configEndpoint}?campaign_id=${campaignId}`,

		async (_endpoint: string, { arg }: { arg: { new_date: Date } }) => {
			try {
				const { new_date } = arg;
				const body = { campaign_id: campaignId, date: new_date };
				const response = await apiRequest({
					endpoint: configEndpoint,
					method: 'POST',
					body,
				});
				// Send success analytics
				logEvent('Partner Portal : Account Page : Manual Survey Button : Success', {
					partner_id: partner.id,
					campaign_id: campaignId,
				});
				return response.data;
			} catch (error) {
				calmLogger.error('Error when setting partner email records', {}, error);
				if (isCalmError(error) && error.data?.error?.code === 'invalid_field') {
					dispatch(
						setBannerMessage({
							message: 'Invalid feedback survey date selection. Please try again.',
							isError: true,
							flash: true,
						}),
					);
				} else {
					dispatch(
						setBannerMessage({
							message: 'Failed to save feedback survey date',
							isError: true,
							flash: true,
						}),
					);
				}
				const parsed = getCalmErrorOrError(error);
				throw parsed;
			}
		},
	);
	return [trigger, { data, loading: isMutating, error }];
}
