import { useEffect, useState } from 'react';

import { CalmError, getCalmErrorOrError } from '@/utils/apiRequest/errors';

import { ApiResponse } from './types';
import { useApi } from './useApi';
import { updateBillingHistoryCache } from './useBillingHistory';

type RefundFunction = () => Promise<void>;
export function useRefundInvoice(
	partnerId: string,
	invoiceId: string | null,
): [RefundFunction, ApiResponse<boolean>] {
	const apiRequest = useApi();
	const [result, setResult] = useState<boolean>(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<CalmError | Error | undefined>();

	const refundInvoices = async (): Promise<void> => {
		setError(undefined);

		if (loading) return;
		try {
			setLoading(true);

			const url = `b2b/partners/${partnerId}/invoice/${invoiceId}/refund`;
			const response = await apiRequest({
				endpoint: url,
				method: 'POST',
			});
			const { data } = response;

			setResult(data);
			await updateBillingHistoryCache(partnerId);
			return data;
		} catch (err) {
			const parsed = getCalmErrorOrError(err);
			setError(parsed);
			throw err;
		} finally {
			setLoading(false);
		}
	};

	// Clear out any errors if a component is removed
	useEffect(() => {
		return () => {
			setError(undefined);
		};
	}, [setError]);

	return [refundInvoices, { data: result, loading, error }];
}
