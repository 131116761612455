import { defineMessages } from 'react-intl';

export default defineMessages({
	benchmarkTitle: {
		id: 'reporting.signupsAndEngagement.benchmark.title',
		defaultMessage: 'Industry Benchmark',
		description: 'Benchmark title',
	},
	benchmarkTooltip: {
		id: 'reporting.signupsAndEngagement.benchmark.tooltip',
		defaultMessage:
			'The industry benchmark represents an average across companies like yours. We generate your benchmark based on multiple factors, including line of business and company size.',
		description: 'Tooltip description for benchmark percentage',
	},
});
