import { PaymentIntent, SetupIntent } from '@stripe/stripe-js';

export interface PlanDetails {
	coveredLives: number;
	costPerLife: string;
	total: string;
	expires: string;
	canceledAt: string | undefined | null;
}

export interface BillingDetails {
	name: string;
	brand?: string | undefined;
}

export enum PAYMENT_TYPE {
	CARD = 'card',
	US_BANK_ACCOUNT = 'us_bank_account',
}

export interface PaymentInfo {
	brand?: string;
	last4?: string;
	exp_month?: string;
	exp_year?: string;
	type?: PAYMENT_TYPE;
	billing_details?: {
		address?: {
			city?: string;
			country?: string;
			line1?: string;
			line2?: string;
			postal_code?: string;
			state?: string;
		};
	};
	card?: {
		brand?: string;
		last4?: string;
		exp_month?: string;
		exp_year?: string;
	};
	us_bank_account?: {
		routing_number?: string;
		last4?: string;
	};
	intent_status?: PaymentIntent['status'] | SetupIntent['status'];
	last_intent_error?: string | null;
	next_action?: {
		verify_with_microdeposits?: {
			arrival_date: number;
			hosted_verification_url: string;
			microdeposit_type: 'amounts' | 'descriptor_code' | null;
		};
	};
}
