export enum ReportingAggregationLevel {
	DAILY = 'daily',
	WEEKLY = 'weekly',
	MONTHLY = 'monthly',
}

export const aggregationLevelToTimeUnit = {
	[ReportingAggregationLevel.DAILY]: 'day',
	[ReportingAggregationLevel.WEEKLY]: 'week',
	[ReportingAggregationLevel.MONTHLY]: 'month',
};
