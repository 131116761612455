import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSWRConfig } from 'swr';

import { Card, PrimaryButton } from '@calm-web/design-system';

import ConfirmModal from '@/components/ui/ConfirmModal';
import PartnersTable from '@/components/ui/PartnersTable';
import { useDefinedPartner } from '@/hooks/api/usePartner';
import { useRemoveParent } from '@/hooks/api/usePartnerRelations';
import { usePermissions } from '@/hooks/auth';
import { Partner } from '@/types/store/reducers';
import { calmLogger } from '@/utils/calmLogger';

import { CellTitleWithPadding } from './styles';

const ChildPartners = (): JSX.Element => {
	const history = useHistory();
	const [hasValidPermissions, actions] = usePermissions();
	const partner = useDefinedPartner();
	const { removeParent } = useRemoveParent();
	const [partnerToRemove, setPartnerToRemove] = useState<Partner | null>(null);
	const { mutate } = useSWRConfig();
	const showSlugColumn = false;

	const selectPartner = useCallback(
		async (partnerId: string): Promise<void> => {
			if (partnerId) {
				await history.push({ pathname: `/${partnerId}/home` });
			} else {
				// TODO: handle global error of incorrect partner info from api
			}
		},
		[history],
	);

	return (
		<Card noPadding>
			<CellTitleWithPadding showAdminFlag>Children</CellTitleWithPadding>
			<PartnersTable
				onSelectPartner={selectPartner}
				HeaderButton={
					hasValidPermissions('id', [actions.CREATE])
						? () => (
								<PrimaryButton href={`/create?parentId=${partner.id}`} openInNewTab>
									Create Child Partner
								</PrimaryButton>
						  )
						: undefined
				}
				additionalFilters={{
					['parent.id']: partner.id,
				}}
				hideCategorySelector
				actionButtons={[
					{
						label: 'Manage',
						onClick: (partner: Partner) => {
							selectPartner(partner.id).catch(err =>
								calmLogger.error(`Error selecting partner ${partner.id}`, {}, err),
							);
						},
						color: 'blue3',
						ariaLabel: `Manage child partner: ${partner.name}`,
					},
					{
						label: 'Remove',
						onClick: (partner: Partner) => {
							setPartnerToRemove(partner);
						},
						color: 'errorRed',
						ariaLabel: `Remove child partner: ${partner.name} from parent: ${partner.parent?.name}`,
					},
				]}
				showSlugColumn={showSlugColumn}
			/>
			<ConfirmModal
				title="Remove parent-child relationship?"
				confirmText="Remove Relationship"
				description={`Removing the parent-child relationship from ${partnerToRemove?.parent?.name} (parent) and ${partnerToRemove?.name} (child) will affect the parent's reporting.`}
				isOpen={!!partnerToRemove}
				onConfirm={async () => {
					if (partnerToRemove) await removeParent(partnerToRemove.id);
					await mutate(key => typeof key === 'string' && key.startsWith('b2b/partners?'));
					setPartnerToRemove(null);
				}}
				onCancel={() => setPartnerToRemove(null)}
			/>
		</Card>
	);
};

export default ChildPartners;
