import { ReactElement, ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { Card } from '@calm-web/design-system';

import CellTitle from '@/components/ui/CellTitle';

import messages from './messages';
import { HelperLink, Subtitle } from './styles';

// This is an external link to documentation that can be edited by Internal
// Calm Users as needed, without the need for Engineering intervention.
const HELPER_DOC_LINK =
	'https://docs.google.com/document/d/1VB1LTJ1MaunC6Dy4Rpc-sWfEElfd3DrdAT7IoVsZXnY/edit#';

function HelperDocs(): ReactElement {
	const { formatMessage } = useIntl();
	return (
		<Card>
			<CellTitle showAdminFlag>{formatMessage(messages.HelperDocsCardtitle)}</CellTitle>
			<Subtitle>
				{formatMessage(messages.description, {
					helperdoclink: (...chunks: ReactNode[]) => (
						<HelperLink href={HELPER_DOC_LINK} target="_blank" rel="noopener noreferrer">
							{chunks}
						</HelperLink>
					),
				})}
			</Subtitle>
		</Card>
	);
}

export default HelperDocs;
