import styled, { css } from 'styled-components';
import { ifNotProp } from 'styled-tools';

import {
	Text,
	FontSizes,
	units,
	TextElements,
	fontSize,
	fontWeight,
	FontWeights,
} from '@calm-web/design-system';

export const Title = styled(Text).attrs({
	color: 'gray7',
	el: TextElements.H3,
	size: FontSizes.lg,
})`
	margin-top: 0;
	margin-bottom: ${units(2)};
	display: flex;
	align-items: baseline;
`;

export const Subtitle = styled.div<{ noBottomMargin?: boolean }>`
	${fontSize(FontSizes.sm)};
	${ifNotProp(
		'noBottomMargin',
		css`
			margin-bottom: ${units(1)};
		`,
	)}

	b {
		${fontWeight(FontWeights.Medium)};
	}
`;
