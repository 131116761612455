/* eslint-disable @next/next/no-img-element */

/* eslint-disable no-nested-ternary */

import { ChangeEvent, FC, ReactElement, ReactNode, SyntheticEvent, useEffect, useState } from 'react';
import { IntlFormatters, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button, Card, Modal, ModalWidth, PrimaryButton, SecondaryButton } from '@calm-web/design-system';

import { FileUploadWithPlaceholder } from '@/components/ui/FileUpload';
import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import { ApiResponse } from '@/hooks/api/types';
import { MAX_FILE_SIZE_LIMIT, UploadResults, useUploadEligibilityFile } from '@/hooks/api/useEligibilityFile';
import { PartnerMilestoneType, useRecordMilestone } from '@/hooks/api/useMilestones';
import { clearSegmentCache } from '@/hooks/api/useSegments';
import { setBannerMessage } from '@/store/actions';
import { Partner } from '@/types/store/reducers';
import CalmCookie from '@/utils/CalmCookie';
import { isCalmHealthSKU, isSelfServePlan } from '@/utils/SkuUtils';
import { ApiError, GenericCalmError, isCalmError } from '@/utils/apiRequest/errors';
import { calmLogger } from '@/utils/calmLogger';
import { ELIGIBILITY_ERRORS } from '@/utils/errors';

import ExampleSingleColSvg from './eligibility-file-example-single-col.svg';
import messages from './messages';
import {
	AnnouncementBody,
	AnnouncementDescription,
	AnnouncementTitle,
	BodySegmentedVariation,
	Description,
	DescriptionContainer,
	DescriptionContainerInner,
	ExampleContainer,
	ExampleSegmentedSvgStyled,
	FileTooLargeError,
	FooterButtonsContainer,
	InfoOutlineStyled,
	HelperLink,
	OverwriteAdd,
	OverwriteCallout,
	OverwriteDetail,
	OverwriteRemove,
	OverwriteWarning,
	PurchaseMoreMessageContainer,
	SegmentedDescription,
	ShowExampleButton,
	UploadErrorStyled,
	WarningIcon,
	UploadErrorMessage,
	FormWrapper,
	Title,
	TitleWrapper,
	FormStack,
} from './styles';

type Props = {
	partner: Partner;
	isFirstUpload: boolean;
	loading: boolean;
	upload: ((file: File) => Promise<ApiResponse<UploadResults>>) | ((file: File) => Promise<void>);
	uploadError?: Error | ApiError;
	onUploadSuccess?: () => void;
	isModal: boolean;
	clearCache?: () => Promise<void>;
} & ({ isModal: true; onClickCancel: () => void } | { isModal?: false; onClickCancel?: never });

export function ShowPreviewResults({
	previewResults,
	noChanges,
	button,
	className,
}: {
	previewResults: UploadResults;
	noChanges?: boolean;
	button: ReactNode;
	className?: string;
}): ReactElement {
	const { formatMessage } = useIntl();
	return (
		<OverwriteCallout className={className}>
			<div>
				<OverwriteDetail>{formatMessage(messages.overwriteWarning)}</OverwriteDetail>
				{previewResults.deletedCount ? (
					<OverwriteDetail>
						<OverwriteRemove />
						{formatMessage(messages.overwriteRemovalWarning, {
							deletedcount: previewResults.deletedCount,
						})}
					</OverwriteDetail>
				) : null}
				{previewResults.addedCount ? (
					<OverwriteDetail>
						<OverwriteAdd />
						{formatMessage(messages.overwriteAddWarning, {
							addedcount: previewResults.addedCount,
						})}
					</OverwriteDetail>
				) : null}
				{previewResults.deletedSegmentCount ? (
					<OverwriteDetail>
						<OverwriteRemove />
						{formatMessage(messages.overwriteRemoveSegmentWarning, {
							deletedsegmentcount: previewResults.deletedSegmentCount,
						})}
					</OverwriteDetail>
				) : null}
				{previewResults.addedSegmentCount ? (
					<OverwriteDetail>
						<OverwriteAdd />
						{formatMessage(messages.overwriteAddSegmentWarning, {
							addedsegmentcount: previewResults.addedSegmentCount,
						})}
					</OverwriteDetail>
				) : null}
				{previewResults.changedSegmentValueCount ? (
					<OverwriteDetail>
						<OverwriteAdd />
						{formatMessage(messages.overwriteChangeSegmentValueWarning, {
							changedsegmentvaluecount: previewResults.changedSegmentValueCount,
						})}
					</OverwriteDetail>
				) : null}
				{noChanges ? (
					<OverwriteDetail>
						<OverwriteWarning />
						{formatMessage(messages.overwriteNoChangesWarning)}
					</OverwriteDetail>
				) : null}
			</div>
			{button}
		</OverwriteCallout>
	);
}

function getErrorMessage(formatMessage: IntlFormatters['formatMessage'], error: Error | ApiError): string {
	if (error.message === 'CLIENT_TIMEOUT') {
		// upgrading to react-intl 6 is causing some type issues since this actually returns a ReactNode
		// casting to a string so that I'm not modifying the actual implementation
		return formatMessage(messages.timeout, { linebreak: <br /> }) as string;
	}

	if (error.message === 'SIGNED_URL_NOT_FOUND') {
		return formatMessage(messages.signedUrlUploadError, {});
	}

	const {
		data: {
			error: {
				code,
				info: { detail: messageDetail, subcode },
			},
		},
	} = isCalmError(error) ? error : GenericCalmError;

	if (code === 'b2b_partner_exceeded_eligibilities') {
		return formatMessage(messages.uploadError, {});
	}

	if (code === 'b2b_eligibility_list_password_protected') {
		return formatMessage(messages.passwordProtectedError);
	}

	if (code === 'b2b_eligibility_list_unable_to_parse') {
		return formatMessage(messages.unableToParseError);
	}

	if (messageDetail && (typeof messageDetail === 'string' || typeof messageDetail === 'number')) {
		return formatMessage(messages.serverDefinedUploadError, {
			servermessage: messageDetail,
		});
	}

	if (code === 'b2b_eligibility_list_invalid_data') {
		if (subcode === 'invalid_format') {
			return formatMessage(messages.invalidFormatError, {});
		}
		if (subcode === 'multiple_columns') {
			return formatMessage(messages.multipleColumnsError, {});
		}

		return formatMessage(messages.uploadError, {});
	}

	return formatMessage(messages.uploadError, {});
}

function UploadError({ error }: { error: Error | ApiError }): ReactElement {
	const { formatMessage } = useIntl();
	const message = getErrorMessage(formatMessage, error);
	return <UploadErrorStyled>{message}</UploadErrorStyled>;
}

function ExampleModal({
	onClickCancel,
	setShowExample,
	descriptor,
}: {
	onClickCancel: () => void;
	setShowExample: (v: boolean) => void;
	descriptor?: string;
}): ReactElement {
	const { formatMessage } = useIntl();
	return (
		<Modal
			isOpen
			closeModal={onClickCancel}
			title={formatMessage(messages.exampleEligibilityFileTitle)}
			footer={
				<Button backgroundColor="blue3" onPress={(): void => setShowExample(false)}>
					{formatMessage(messages.exampleBack)}
				</Button>
			}
		>
			<ExampleContainer>
				<figure>
					<ExampleSegmentedSvgStyled />
				</figure>
				<SegmentedDescription>
					<div>
						{formatMessage(messages.segmentedExampleDescription, {
							descriptor,
							b: (...chunks: ReactNode[]) => <Description as="span">{chunks}</Description>,
							ul: (...chunks: ReactNode[]) => <ul>{chunks}</ul>,
							li: (...chunks: ReactNode[]) => <li>{chunks}</li>,
						})}
					</div>
				</SegmentedDescription>
			</ExampleContainer>
		</Modal>
	);
}

function PurchaseMoreMessage({
	error,
	partnerId,
}: {
	error?: Error | ApiError;
	partnerId: string;
}): ReturnType<FC> {
	const { formatMessage } = useIntl();
	const toMegabytes = (val: number): number => {
		return val / 1e6;
	};

	if (error?.message === ELIGIBILITY_ERRORS.FILE_TOO_LARGE) {
		return (
			<FileTooLargeError>
				<WarningIcon />
				{formatMessage(messages.fileTooLargeError, {
					fileSizeLimit: toMegabytes(MAX_FILE_SIZE_LIMIT),
				})}
			</FileTooLargeError>
		);
	}

	if (!error || !isCalmError(error)) {
		return null;
	}

	const {
		data: {
			error: { code },
		},
	} = error;

	if (code !== 'b2b_partner_exceeded_eligibilities') {
		return null;
	}

	const messageDetail = error.data.error.info.detail;

	return (
		<PurchaseMoreMessageContainer>
			<UploadErrorMessage>
				{messageDetail ? String(messageDetail) : formatMessage(messages.tooManyEntriesError)}
			</UploadErrorMessage>
			<Link data-testid="plan-link" to={`/${partnerId}/plan`}>
				<Button backgroundColor="buttonPurple">{formatMessage(messages.purchaseMoreButton)}</Button>
			</Link>
		</PurchaseMoreMessageContainer>
	);
}

function Announcement(): ReturnType<FC> {
	const { formatMessage } = useIntl();

	const hasSeenAnnouncement = CalmCookie.get('has-seen-segmented-reporting-announcement_v2') === 'true';
	if (hasSeenAnnouncement) {
		return null;
	}

	return (
		<AnnouncementBody>
			<AnnouncementTitle>{formatMessage(messages.segmentedAnnouncementTitle)}</AnnouncementTitle>
			<img
				width={'100%'}
				src="https://res.cloudinary.com/calm-com/image/upload/v1626804074/partner-portal/eligibility-file-example-segmented-announcement.png"
				alt="Example of a elegibility file with columns for segmented reporting"
			/>
			<AnnouncementDescription>
				{formatMessage(messages.segmentedAnnouncementDescription, {
					div: (...chunks: ReactNode[]) => <div>{chunks}</div>,
					b: (...chunks: ReactNode[]) => (
						<Description>
							<br />
							{chunks}
						</Description>
					),
				})}
			</AnnouncementDescription>
		</AnnouncementBody>
	);
}

function UploadInstructionsSegmentedVariation({
	descriptor,
	handleExampleClick,
	isFullRow,
}: {
	descriptor?: string;
	handleExampleClick?: () => void;
	isFullRow?: boolean;
}): ReactElement {
	const { formatMessage } = useIntl();

	return (
		<DescriptionContainer $fullRow={isFullRow}>
			<DescriptionContainerInner>
				<div>
					<ExampleSingleColSvg />
				</div>
				<div>
					<Description>
						{formatMessage(messages.segmentedDescription, {
							descriptor,
							ul: (...chunks: ReactNode[]) => <ul>{chunks}</ul>,
							li: (...chunks: ReactNode[]) => <li>{chunks}</li>,
							div: (...chunks: ReactNode[]) => <div>{chunks}</div>,
						})}
					</Description>
					<div>{formatMessage(messages.segmentedOptional)}</div>
				</div>
			</DescriptionContainerInner>
			{handleExampleClick && (
				<div>
					<ShowExampleButton onPress={handleExampleClick} Icon={InfoOutlineStyled}>
						{formatMessage(messages.exampleShow)}
					</ShowExampleButton>
				</div>
			)}
		</DescriptionContainer>
	);
}

function UploadInstructionsCH(): ReactElement {
	const { formatMessage } = useIntl();
	return (
		<DescriptionContainer $centerAlignItems>
			<div>
				{formatMessage(messages.helpDescription, {
					linebreak: <br />,
					setupGuideLink: (...chunks: ReactNode[]) => (
						<HelperLink
							href="https://info.calm.com/rs/541-LYF-023/images/Eligibility_File_Setup_Guide_CalmHealth.pdf?version=0"
							target="_blank"
						>
							{chunks}
						</HelperLink>
					),
					defaultTemplateLink: (...chunks: ReactNode[]) => (
						<HelperLink
							href="https://docs.google.com/spreadsheets/d/1yoZbNpwnDjV9ijSS82CAobeu4V9N10CA7gTy55joZgM/edit?gid=1352717429#gid=1352717429"
							target="_blank"
						>
							{chunks}
						</HelperLink>
					),
				})}
			</div>
		</DescriptionContainer>
	);
}

function UploadEligibilityFileModal({
	partner,
	isFirstUpload,
	onClickCancel,
	upload,
	error,
	loading,
	previewResults,
	handleFileChange,
	eligibilityFile,
}: {
	partner: Partner;
	isFirstUpload: boolean;
	onClickCancel: () => void;
	upload: (e: SyntheticEvent) => Promise<void>;
	loading: boolean;
	error?: Error | ApiError;
	previewResults?: UploadResults;
	handleFileChange: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
	eligibilityFile?: File;
}): ReactElement {
	const [showExample, setShowExample] = useState(false);
	const { formatMessage } = useIntl();
	const handleExampleClick = (): void => setShowExample(true);
	const isSelfServe = isSelfServePlan(partner.vouched_plan_sku);
	const descriptor = partner.user_id_descriptor;

	const noChanges =
		previewResults &&
		!previewResults.deletedCount &&
		!previewResults.deletedSegmentCount &&
		!previewResults.addedCount &&
		!previewResults.addedSegmentCount &&
		!previewResults.changedSegmentValueCount;

	const fileUploadWithPlaceholderProps = {
		id: 'eligibility-file',
		name: 'eligibility-file',
		onChange: handleFileChange,
		accept: ['.csv', '.xls', '.xlsx'],
	};

	const isDisabled = !eligibilityFile || noChanges || loading || !!error;

	function handleCancel(): void {
		CalmCookie.set('has-seen-segmented-reporting-announcement_v2', 'true');
		onClickCancel();
	}

	if (showExample) {
		return (
			<ExampleModal onClickCancel={handleCancel} setShowExample={setShowExample} descriptor={descriptor} />
		);
	}

	return (
		<Modal
			isOpen
			closeModal={handleCancel}
			title={formatMessage(isFirstUpload ? messages.firstUploadTitle : messages.title)}
			width={ModalWidth.Extra}
		>
			{!isSelfServe && <Announcement />}
			<form name="eligibilityForm" onSubmit={upload} data-testid="ef-segmented-upload-modal">
				<BodySegmentedVariation>
					{eligibilityFile ? (
						<FileUploadWithPlaceholder {...fileUploadWithPlaceholderProps} file={eligibilityFile} />
					) : (
						<FileUploadWithPlaceholder
							{...fileUploadWithPlaceholderProps}
							buttonText={isFirstUpload ? formatMessage(messages.pickFileFirstUpload) : undefined}
							loading={loading}
						/>
					)}
					{error ? <UploadError error={error} /> : null}
					<UploadInstructionsSegmentedVariation
						descriptor={descriptor}
						handleExampleClick={handleExampleClick}
						isFullRow
					/>
					{previewResults ? (
						<ShowPreviewResults
							previewResults={previewResults}
							noChanges={noChanges}
							button={
								<PrimaryButton
									type="submit"
									isDisabled={isDisabled}
									isLoading={loading}
									data-testid="ef-segmented-upload-confirm"
								>
									{formatMessage(messages.uploadButton)}
								</PrimaryButton>
							}
						/>
					) : (
						<FooterButtonsContainer>
							<SecondaryButton isLoading={loading} onPress={onClickCancel}>
								{formatMessage(messages.cancelButton)}
							</SecondaryButton>
							<PrimaryButton
								type="submit"
								isDisabled={isDisabled}
								isLoading={loading}
								data-testid="ef-segmented-upload-confirm"
							>
								{formatMessage(messages.uploadButtonFirstUpload)}
							</PrimaryButton>
						</FooterButtonsContainer>
					)}
				</BodySegmentedVariation>
				<PurchaseMoreMessage partnerId={partner.id} error={error} />
			</form>
		</Modal>
	);
}

function UploadEligibilityFileInner({
	partner,
	upload,
	loading,
	error,
	previewResults,
	handleFileChange,
	eligibilityFile,
}: {
	partner: Partner;
	upload: (e: SyntheticEvent) => Promise<void>;
	loading: boolean;
	error?: Error | ApiError;
	previewResults?: UploadResults;
	handleFileChange: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
	eligibilityFile?: File;
}): ReactElement {
	const { formatMessage } = useIntl();
	const isCalmHealth = isCalmHealthSKU(partner.vouched_plan_sku);

	const noChanges =
		previewResults &&
		!previewResults.deletedCount &&
		!previewResults.deletedSegmentCount &&
		!previewResults.addedCount &&
		!previewResults.addedSegmentCount &&
		!previewResults.changedSegmentValueCount;

	const fileUploadWithPlaceholderProps = {
		id: 'eligibility-file',
		name: 'eligibility-file',
		onChange: handleFileChange,
		accept: isCalmHealth ? ['.csv'] : ['.csv', '.xls', '.xlsx'],
	};

	const isDisabled = !eligibilityFile || noChanges || loading || !!error;

	return (
		<>
			<form name="eligibilityForm" onSubmit={upload}>
				<FormWrapper>
					{isCalmHealth ? (
						<UploadInstructionsCH />
					) : (
						<UploadInstructionsSegmentedVariation descriptor={partner.user_id_descriptor} />
					)}
					<FormStack>
						{eligibilityFile ? (
							<FileUploadWithPlaceholder
								{...fileUploadWithPlaceholderProps}
								file={eligibilityFile}
								includeSubmitButton={!previewResults && !error}
								loading={loading}
							/>
						) : (
							<FileUploadWithPlaceholder
								{...fileUploadWithPlaceholderProps}
								loading={loading}
								includeSubmitButton={!previewResults && !error}
							/>
						)}
						{error && <UploadError error={error} />}
						{previewResults && (
							<ShowPreviewResults
								previewResults={previewResults}
								noChanges={noChanges}
								button={
									<PrimaryButton
										type="submit"
										isDisabled={isDisabled}
										isLoading={loading}
										data-testid="ef-segmented-upload-confirm"
									>
										{formatMessage(messages.uploadButton)}
									</PrimaryButton>
								}
							/>
						)}
					</FormStack>
				</FormWrapper>
				<PurchaseMoreMessage partnerId={partner.id} error={error} />
			</form>
		</>
	);
}

export default function UploadEligibilityFile({
	partner,
	isFirstUpload,
	loading,
	upload,
	uploadError,
	isModal,
	onClickCancel,
	onUploadSuccess,
	clearCache,
}: Props): ReactElement {
	const dispatch = useDispatch();
	const isCalmHealth = isCalmHealthSKU(partner.vouched_plan_sku);
	const [eligibilityFile, setEligibilityFile] = useState<File>();
	const { formatMessage } = useIntl();
	const [recordMilestone] = useRecordMilestone();
	const { logEvent } = useAnalytics();
	const [localPreviewResults, setLocalPreviewResults] = useState<UploadResults | undefined>();
	const [previewUpload, { data: previewResults, error: previewError, loading: previewLoading }] =
		useUploadEligibilityFile(partner.id, true);

	// track preview results in a separate state variable so that we can clear them after a successful submission
	useEffect(() => {
		setLocalPreviewResults(previewResults);
	}, [previewResults]);

	function handleUploadSuccess(): void {
		logEvent('Partner Portal : Submit File : Completed', {
			is_first_upload: isFirstUpload,
			is_calm_health: isCalmHealth,
		});

		// reset local file state values
		setEligibilityFile(undefined);
		setLocalPreviewResults(undefined);

		clearSegmentCache(partner.id).catch(err =>
			calmLogger.error('Error in UploadEligibilityFile clearSegmentCache', {}, err),
		);
	}

	async function uploadForReal(e: SyntheticEvent): Promise<void> {
		logEvent('Partner Portal : Submit File : Clicked', {
			is_first_upload: isFirstUpload,
			is_calm_health: isCalmHealth,
		});
		if (loading) return;
		if (!eligibilityFile) return;
		e.preventDefault();
		try {
			await upload(eligibilityFile);
			await clearCache?.();
			handleUploadSuccess();
			recordMilestone({ eventName: PartnerMilestoneType.EF_UPLOADED, partnerId: partner.id }).catch(err => {
				calmLogger.error('Error when trying to record milestone', {}, err);
			});

			// call custom success handler if it exists. otherwise, display a success message.
			if (onUploadSuccess) {
				onUploadSuccess();
			} else {
				dispatch(
					setBannerMessage({
						message: 'Eligibility file is being processed. Check back later for the status of your upload.',
						isError: false,
						flash: true,
						backgroundColor: 'gold',
						textColor: 'black',
					}),
				);
			}
		} catch (error) {
			calmLogger.error('Error in UploadEligibilityFile upload', {}, error);

			const message = getErrorMessage(formatMessage, error);
			const parsedError = isCalmError(error) ? error : GenericCalmError;
			logEvent('Partner Portal : Submit File : Error', {
				is_first_upload: isFirstUpload,
				error_code: parsedError.data.error.code,
				error_message: message,
				is_calm_health: isCalmHealth,
			});
			dispatch(
				setBannerMessage({
					message,
					flash: true,
					isError: true,
				}),
			);
		}
	}

	async function handleFileChange(e: ChangeEvent<HTMLInputElement>): Promise<void> {
		e.preventDefault();
		if (loading) return;

		const file = e.target?.files?.[0];

		if (!file) {
			return;
		}
		if (!isCalmHealth) {
			try {
				await previewUpload(file);
			} catch (error) {
				calmLogger.error('Error in UploadEligibilityFileModal previewUpload', {}, error);
				const message = getErrorMessage(formatMessage, error);

				const parsedError = isCalmError(error) ? error : GenericCalmError;
				logEvent('Partner Portal : Submit File : Error', {
					is_first_upload: isFirstUpload,
					error_code: parsedError.data.error.code,
					error_message: message,
					is_calm_health: isCalmHealth,
				});
				dispatch(
					setBannerMessage({
						message,
						flash: true,
						isError: true,
					}),
				);
			}
		}
		setEligibilityFile(file);

		logEvent('Partner Portal : Pick File : Changed', {
			is_first_upload: isFirstUpload,
			is_calm_health: isCalmHealth,
		});
	}

	return (
		<>
			{isModal ? (
				<UploadEligibilityFileModal
					partner={partner}
					isFirstUpload={isFirstUpload}
					onClickCancel={onClickCancel}
					upload={uploadForReal}
					error={previewError ?? uploadError}
					loading={loading || previewLoading}
					handleFileChange={handleFileChange}
					previewResults={localPreviewResults}
					eligibilityFile={eligibilityFile}
				/>
			) : (
				<Card>
					<TitleWrapper data-testid="ef-manual-upload-header">
						<Title>Upload an eligibility file</Title>
					</TitleWrapper>
					<UploadEligibilityFileInner
						partner={partner}
						upload={uploadForReal}
						loading={loading}
						handleFileChange={handleFileChange}
						eligibilityFile={eligibilityFile}
						previewResults={localPreviewResults}
						error={previewError ?? uploadError}
					/>
				</Card>
			)}
		</>
	);
}
