import { isSelfServePlan } from '@/utils/SkuUtils';

import { useFeatureFlags } from './api/useFeatureFlags';

const EMPLOYEE_PULSE_PROGRAM = 'employee-pulse-program-json';

export const useShouldShowPulseBanner = (
	partnerId?: string,
	partnerSKU?: string,
): boolean | string | undefined => {
	const {
		data: flagValues,
		error: flagError,
		loading: flagLoading,
	} = useFeatureFlags(EMPLOYEE_PULSE_PROGRAM);
	const flagsLoaded = !flagLoading && !flagError && flagValues;
	const employeePulseProgramFlag =
		flagValues &&
		(flagValues[EMPLOYEE_PULSE_PROGRAM] as
			| {
					isEnabled: boolean;
					partnerIds: string[];
			  }
			| undefined);
	const isSelfServe = isSelfServePlan(partnerSKU);
	const shouldShowPulseBanner =
		flagsLoaded &&
		partnerId &&
		(employeePulseProgramFlag?.isEnabled || employeePulseProgramFlag?.partnerIds.includes(partnerId)) &&
		!isSelfServe;

	return shouldShowPulseBanner;
};
