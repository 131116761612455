/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

export const sessionTypeLabels = defineMessages({
	sleep: {
		id: 'reporting.sessionTypes.sleep',
		defaultMessage: 'Sleep',
		description: "Label for Calm's sleep content",
	},
	music: {
		id: 'reporting.sessionTypes.music',
		defaultMessage: 'Music',
		description: "Label for Calm's music content",
	},
	meditation: {
		id: 'reporting.sessionTypes.meditation',
		defaultMessage: 'Meditation',
		description: "Label for Calm's meditation content",
	},
	masterclass: {
		id: 'reporting.sessionTypes.masterclass',
		defaultMessage: 'Masterclass',
		description: "Label for Calm's masterclass content",
	},
	breathe: {
		id: 'reporting.sessionTypes.breathe',
		defaultMessage: 'Breathe',
		description: "Label for Calm's breathing exercises content",
	},
	body: {
		id: 'reporting.sessionTypes.body',
		defaultMessage: 'Body',
		description: "Label for Calm's workout content",
	},
});

export default defineMessages({
	reportingDelay: {
		id: 'reporting.signupsAndEngagement.reportingDelay',
		defaultMessage:
			'Data may not be up to date due to recent user session activity or eligibility file upload. Reporting data will be synced and accurate at {nextSyncTime}',
		description: 'Disclaimer regarding reporting data recency',
	},
	mockedReport: {
		id: 'reporting.mockedReport',
		defaultMessage:
			'Data shown here is only for demo purposes and does not reflect actual usage on your account.',
		description:
			'Message warning users that the reporting data they are seeing does not reflect their account',
	},
	pageLoadErrorMessage: {
		id: 'reporting.pageLoadErrorMessage',
		defaultMessage:
			'We are sorry, but we are unable to load that page right now. Please email your Calm Account Manager, or visit our <supportlink>Help Center</supportlink> for support.',
		description: 'Error message shown when the Reporting page cannot load',
	},
	noDataMessage: {
		id: 'reporting.noDataMessage',
		defaultMessage: "There's no data here yet!",
		description: 'Error message when no data available for partner',
	},
	noDataHelpMessage: {
		id: 'reporting.noDataHelpMessage',
		defaultMessage:
			'You’ll see more data here as your team uses Calm. Encourage your team to sign up for Calm Premium using our strategies outlined in the <resourceslink>Resources</resourceslink> page.',
		description: 'Help message when no data is available for partner',
	},
	moreContentTitle: {
		id: 'reporting.noData.moreContentTitle',
		defaultMessage: 'More data coming soon!',
		description: 'A title indication there will be more graphs and user data coming soon',
	},
	moreContentDescription: {
		id: 'reporting.noData.moreContentDescription',
		defaultMessage:
			'Once your employees start using Calm, we’ll share engagement and top content reports here. Visit the resources page to learn how to encourage your team to sign up and make the most of their Calm Premium benefit.',
		description:
			'Indicates when the user will get more reporting data on things like app usage, signups, etc.',
	},
	topContentTitle: {
		id: 'reporting.topContent.title',
		defaultMessage: 'Top content',
		description: 'Title for the top content section',
	},
	privacyLimitMessage: {
		id: 'reporting.error.privacyLimitMessage',
		defaultMessage: 'Oops! Not enough data',
		description: 'Message to tell users that we can not show their report because of privacy',
	},
	privacyLimitSubmessage: {
		id: 'reporting.error.privacyLimitSubmessage',
		defaultMessage: 'To protect user privacy, data cannot be displayed for groups of less than 10 users.',
		description: 'Further explanation for privacy limit',
	},
	tooFewUsersSelfServeMessage: {
		id: 'reporting.error.tooFewUsersSelfServeMessage',
		defaultMessage: 'It looks like you only have a plan for {coveredLives} users.',
	},
	downloading: {
		id: 'reporting.downloading',
		defaultMessage: 'Creating report...',
		description: 'Explanation that the user is seeing a loading state because their report is downloading.',
	},
});
