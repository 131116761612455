import { defineMessages } from 'react-intl';

export default defineMessages({
	sampleResource1: {
		id: 'pathways.selectProgram.sampleResources.text1',
		defaultMessage: 'Announcement Template',
		description: 'Title for sample resource',
	},
	sampleResource2: {
		id: 'pathways.selectProgram.sampleResources.text2',
		defaultMessage: 'Flyer',
		description: 'Title for sample resource',
	},
});
