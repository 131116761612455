// Third Party

import styled from 'styled-components';
import { palette } from 'styled-tools';

import { FontSizes, fontSize, fontWeight, FontWeights, units } from '@calm-web/design-system';

export const GradientButton = styled.button`
	color: ${palette('white')};
	background: ${palette('gradientCalmBrand')};
	border-radius: ${units(9)};
	padding: ${units(3, 9)};
	border: none;
	:hover {
		cursor: pointer;
	}
`;

export const BoldText = styled.div`
	${fontWeight(FontWeights.Medium)};
`;

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
`;

export const ExampleEmailContainer = styled.div`
	${fontSize(FontSizes.base)}
`;

export const ImageContainer = styled.div`
	position: relative;
	margin: ${units(2)} 0;
`;
