import { EnvConfig } from '@/env_config';

import { ProductSKUType } from './SkuEnums';
import { isCalmHealthProductSKUType } from './SkuUtils';

export function createUrl(src?: Blob | MediaSource): string {
	return src ? URL.createObjectURL(src) : '';
}

/*
 * Creates a preview of the subscribe url for a partner before it exists on the partner
 * If the partner exists already in the DB, the partner.redemption_url should be used instead
 */
export function createB2BPartnerSubscribeUrlPreview({
	slug,
	skuType,
}: {
	slug?: string;
	skuType?: string;
}): string {
	const partnerIdentifier = !!slug && slug.length > 0 ? slug : 'your-partner-identifier';

	if (isCalmHealthProductSKUType(skuType as ProductSKUType)) {
		return `${EnvConfig.calmHealthWebUrl}/go/${partnerIdentifier}`;
	}

	return `${EnvConfig.partnerWebUrl}/b2b/${partnerIdentifier}/subscribe`;
}
