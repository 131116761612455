import { useFeatureFlags } from './api/useFeatureFlags';

const BENEFIT_EMAIL_UPDATES = 'b2b-benefit-invite-emails-24-hour';

export const useShouldShowBenefitEmailUpdates = (): boolean | string | undefined => {
	const { data: flagValues, error: flagError, loading: flagLoading } = useFeatureFlags(BENEFIT_EMAIL_UPDATES);
	const flagsLoaded = !flagLoading && !flagError && flagValues;
	const benefitEmail24HourFlag = flagValues && (flagValues[BENEFIT_EMAIL_UPDATES] as boolean | undefined);
	const shouldShowBenefitEmailUpdates = flagsLoaded && benefitEmail24HourFlag;
	return shouldShowBenefitEmailUpdates;
};
