import useSWR from 'swr';

import { useApi } from '@/hooks/api';
import { calmLogger } from '@/utils/calmLogger';

import { ApiResponse } from '../types';

export interface HealthDashboard {
	url: string;
	token: string;
}

export function useTableauReport({
	partnerId,
	preview = false,
}: {
	partnerId: string;
	preview?: boolean;
}): ApiResponse<HealthDashboard> {
	const apiRequest = useApi();

	const endpoint = `b2b/partners/${partnerId}/health-dashboard${preview ? '/preview' : ''}`;

	const { data, isLoading, error } = useSWR<HealthDashboard>(endpoint, async endpoint => {
		try {
			const response = await apiRequest({ endpoint });
			return response.data.health_dashboard as HealthDashboard;
		} catch (err) {
			calmLogger.error('Error when fetching Calm Health Tableau Report', {}, err);
			throw err;
		}
	});

	return { data, loading: isLoading, error };
}
