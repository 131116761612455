import styled from 'styled-components';

import { units, minWidth } from '@calm-web/design-system';
import { Breakpoints } from '@calm-web/design-system/lib/utils/mediaQueries';

export const Wrapper = styled.div`
	max-width: ${Breakpoints.desktop}px;
	// Account for the feedback button shown on the side
	${minWidth('desktop')} {
		margin-right: ${units(5)};
	}
`;
