import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import useSWR, { mutate } from 'swr';

import { useApi } from '@/hooks/api';
import { setBannerMessage } from '@/store/actions';
import { formatError } from '@/utils/analytics/dataFormatting';
import { CalmError, getCalmErrorOrError } from '@/utils/apiRequest/errors';
import { calmLogger } from '@/utils/calmLogger';

import { useAnalytics } from '../analytics/useAnalytics';
import { useStripeHelp } from '../useStripeHelp';
import { ApiResponse } from './types';
import { updateBillingHistoryCache } from './useBillingHistory';
import { updatePartnerCacheById } from './usePartner';
import { updateUpcomingInvoiceCache } from './useUpcomingInvoice';

export interface Subscription {
	amount: number;
	quantity: number;
	current_cycle_start_date: string;
	expires: string;
	canceled_at?: string | null;
}

export function useSubscription(partnerId: string): ApiResponse<Subscription> {
	const apiRequest = useApi();
	const dispatch = useDispatch();

	const { data, error } = useSWR(`b2b/partners/${partnerId}/subscription`, async endpoint => {
		try {
			const response = await apiRequest({ endpoint });
			const subscription = response.data?.subscription ?? response.data;
			if (!subscription) {
				throw new Error('Not able to fetch subscription details');
			}

			await updateBillingHistoryCache(partnerId);
			return subscription;
		} catch (responseError) {
			calmLogger.error('Teams : Failed to retrieve subscription details', { partnerId }, responseError);
			dispatch(
				setBannerMessage({
					message: `Failed to retrieve subscription details`,
					isError: true,
					flash: true,
				}),
			);
			throw responseError;
		}
	});

	if (error) {
		return { loading: false, error };
	}

	if (!data) {
		return { loading: true, error: undefined };
	}

	return {
		data,
		error: undefined,
		loading: false,
	};
}

const TEAMS_CANCEL_SUBSCRIPTION_LOG_PREFIX = 'Teams : Cancel Subscription';

export function useCancelSubscription(partnerId: string): [() => Promise<void>, ApiResponse<Subscription>] {
	const apiRequest = useApi();
	const [error, setError] = useState<CalmError | Error | undefined>(undefined);
	const [loading, setLoading] = useState<boolean>(false);
	const { logEvent } = useAnalytics();

	async function cancelSubscription(): Promise<void> {
		try {
			setLoading(true);
			setError(undefined);
			logEvent(`${TEAMS_CANCEL_SUBSCRIPTION_LOG_PREFIX} : Initiated`);
			await apiRequest({
				endpoint: `b2b/partners/${partnerId}/subscription`,
				method: 'DELETE',
			});
			logEvent(`${TEAMS_CANCEL_SUBSCRIPTION_LOG_PREFIX} : Success`);
			await mutate(`b2b/partners/${partnerId}/subscription`);
		} catch (err) {
			logEvent(`${TEAMS_CANCEL_SUBSCRIPTION_LOG_PREFIX} : Error`, {
				...formatError(err),
			});
			calmLogger.error('Teams : Error canceling subscription', { partnerId }, err);
			setError(getCalmErrorOrError(err));
			throw err;
		} finally {
			setLoading(false);
		}
	}
	return [cancelSubscription, { loading, error }];
}

interface ReactivateSubscriptionArgs {
	stripeConfirmationTokenId: string | undefined;
}

const TEAMS_REACTIVATE_PLAN_LOG_PREFIX = 'Teams : Reactivate Plan';

export function useReactivateSubscription(): [
	({ isSubExpired }: { isSubExpired: boolean }) => Promise<void>,
	ApiResponse<Subscription> & { hasReactivated: boolean },
] {
	const { partnerId } = useParams();
	const apiRequest = useApi();
	const [error, setError] = useState<CalmError | Error | undefined>(undefined);
	const [loading, setLoading] = useState<boolean>(false);
	const [hasReactivated, setHasReactivated] = useState(false);
	const { createConfirmationToken } = useStripeHelp();
	const { logEvent } = useAnalytics();

	async function reactivateSubscription({
		stripeConfirmationTokenId,
	}: ReactivateSubscriptionArgs): Promise<void> {
		try {
			setLoading(true);
			setError(undefined);
			const body = {
				stripe_confirmation_token_id: stripeConfirmationTokenId,
			};
			await apiRequest({
				endpoint: `b2b/partners/${partnerId}/reactivate`,
				method: 'PATCH',
				body,
			});
			await mutate(`b2b/partners/${partnerId}/subscription`);
		} catch (err) {
			calmLogger.error('Teams : Error in reactivateSubscription', { partnerId }, err);
			setError(getCalmErrorOrError(err));
			throw err;
		} finally {
			setLoading(false);
		}
	}

	const onReactivatePlan = async ({ isSubExpired }: { isSubExpired: boolean }): Promise<void> => {
		if (loading) {
			return;
		}
		let paymentMethodType: string | undefined;
		try {
			const stripeConfirmationToken = await createConfirmationToken();
			paymentMethodType = stripeConfirmationToken?.payment_method_preview.type;
			logEvent(`${TEAMS_REACTIVATE_PLAN_LOG_PREFIX} : Initiated`, {
				...(paymentMethodType ? { payment_method_type: paymentMethodType } : {}),
				is_sub_expired: isSubExpired,
			});
			await reactivateSubscription({ stripeConfirmationTokenId: stripeConfirmationToken?.id });
			logEvent(`${TEAMS_REACTIVATE_PLAN_LOG_PREFIX} : Success`, {
				...(paymentMethodType ? { payment_method_type: paymentMethodType } : {}),
				is_sub_expired: isSubExpired,
			});
			setHasReactivated(true);
		} catch (err) {
			logEvent(`${TEAMS_REACTIVATE_PLAN_LOG_PREFIX} : Error`, {
				...formatError(err),
				...(paymentMethodType ? { payment_method_type: paymentMethodType } : {}),
				is_sub_expired: isSubExpired,
			});
			calmLogger.error('Teams : Error in onReactivatePlan', { partnerId }, err);
			throw err;
		}
	};

	return [onReactivatePlan, { loading, error, hasReactivated }];
}

const TEAMS_UPDATE_COVERED_LIVES_LOG_PREFIX = 'Teams : Covered Lives : Update';

export function useUpdateSubscription(
	partnerId: string,
): [(coveredLives: number) => Promise<void>, { loading: boolean; error: CalmError | Error | undefined }] {
	const apiRequest = useApi();
	const dispatch = useDispatch();
	const [error, setError] = useState<CalmError | Error | undefined>(undefined);
	const [loading, setLoading] = useState<boolean>(false);
	const { logEvent } = useAnalytics();

	async function update(coveredLives: number): Promise<void> {
		try {
			setLoading(true);
			logEvent(`${TEAMS_UPDATE_COVERED_LIVES_LOG_PREFIX} : Initiated`);
			const body = { covered_lives: coveredLives };
			await apiRequest({
				endpoint: `b2b/partners/${partnerId}/subscription`,
				method: 'PATCH',
				body,
			});
			logEvent(`${TEAMS_UPDATE_COVERED_LIVES_LOG_PREFIX} : Success`);
			await Promise.all([
				mutate(`b2b/partners/${partnerId}/subscription`),
				updatePartnerCacheById(partnerId),
				updateUpcomingInvoiceCache(partnerId),
			]);
		} catch (err) {
			logEvent(`${TEAMS_UPDATE_COVERED_LIVES_LOG_PREFIX} : Error`, {
				...formatError(err),
			});
			calmLogger.error('Teams : Error updating subscription', { partnerId }, err);
			setError(getCalmErrorOrError(err));
			dispatch(
				setBannerMessage({
					message: `Failed to update subscription`,
					isError: true,
					flash: true,
				}),
			);
			throw err;
		} finally {
			setLoading(false);
		}
	}
	return [update, { loading, error }];
}

export interface TerminateResult {
	metadata: {
		cycle_start_date: string;
		individual_subscriptions_canceled: number;
		individual_subscriptions_expiration_date: string;
		months_into_cycle: number;
		original_amount: number;
	};
	preview_mode: boolean;
}

export function useTerminateSubscription(
	partnerId: string,
): [() => Promise<void>, ApiResponse<TerminateResult>] {
	const apiRequest = useApi();
	const dispatch = useDispatch();
	const [error, setError] = useState<CalmError | Error | undefined>(undefined);
	const [loading, setLoading] = useState<boolean>(false);
	const [data, setData] = useState<TerminateResult | undefined>(undefined);

	async function terminate(): Promise<void> {
		try {
			setLoading(true);
			const response = await apiRequest({
				endpoint: `b2b/partners/${partnerId}/terminate`,
				method: 'POST',
				params: {
					preview_mode: false,
				},
			});
			setData(response.data);
			await mutate(`b2b/partners/${partnerId}/subscription`);
		} catch (err) {
			calmLogger.error('Teams : Error terminating subscription', { partnerId }, err);
			setError(getCalmErrorOrError(err));
			dispatch(
				setBannerMessage({
					message: `Failed to terminate subscription`,
					isError: true,
					flash: true,
				}),
			);
			throw err;
		} finally {
			setLoading(false);
		}
	}
	return [terminate, { loading, error, data }];
}

export function useTerminateSubscriptionPreview(partnerId: string): ApiResponse<TerminateResult> {
	const apiRequest = useApi();
	const dispatch = useDispatch();

	const { data, error } = useSWR(`b2b/partners/${partnerId}/terminate`, async endpoint => {
		try {
			const response = await apiRequest({
				endpoint,
				method: 'POST',
				params: {
					preview_mode: true,
				},
			});
			if (!response.data) {
				throw new Error('Not able to fetch subscription terminate preview');
			}
			return response.data;
		} catch (responseError) {
			calmLogger.error('Teams : Error fetching subscription terminate preview', { partnerId }, responseError);
			dispatch(
				setBannerMessage({
					message: `Failed to retrieve subscription terminate preview`,
					isError: true,
					flash: true,
				}),
			);
			throw responseError;
		}
	});

	if (error) {
		return { loading: false, error };
	}

	if (!data) {
		return { loading: true, error: undefined };
	}

	return {
		data,
		error: undefined,
		loading: false,
	};
}
