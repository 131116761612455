import { defineMessages } from 'react-intl';

export default defineMessages({
	heading: {
		id: 'home.getSetup.heading',
		defaultMessage: 'Get set up',
		description: 'Heading for the get set up section',
	},
	actionsHeading: {
		id: 'home.recommendedActionsTeaser.heading',
		defaultMessage: 'Recommended actions',
		description: 'Heading for the get recommended actions teaser section',
	},
	notReadyYetHeading: {
		id: 'home.notReady.heading',
		defaultMessage: "Your Recommended actions aren't ready yet",
		description:
			'Heading shown when Recommended actions are not ready since they have not completed get started yet.',
	},
	notReadyYetCopy: {
		id: 'home.notReady.copy',
		defaultMessage: 'Complete <i>Get set up</i> to start receiving <i>Recommended actions</i>.',
		description: 'Recommended actions not ready copy.',
	},
});
