import { defineMessages } from 'react-intl';

export default defineMessages({
	priceLoadingText: {
		id: 'plan.priceLoadingText',
		defaultMessage: 'Calculating',
		description: 'Tell users that the prece for their plan is being calculated',
	},
	costBreakdownLabel: {
		id: 'plan.costBreakdownLabel',
		defaultMessage: 'Cost breakdown',
		description: 'Label for cost breakdown',
	},
	priceLoadingError: {
		id: 'plan.priceLoadingError',
		defaultMessage: 'Unable to load prices',
		description:
			'Message to show to users if the call to load the invoice price for their amount of covered lives fails.',
	},
	proratedBillLabel: {
		id: 'plan.proratedBillLabel',
		defaultMessage: 'Prorated bill (for the remainder of the current year)',
		description:
			'Label to show in table for the row that displays the prorated amount that the user will be charged for the current year.',
	},
	taxRowLabel: {
		id: 'plan.taxRowLabel',
		defaultMessage: 'Pro-rated taxes',
		description: 'Row label for the prorate tax',
	},
	subtotalBillRowLabel: {
		id: 'plan.subtotalBillRowLabel',
		defaultMessage: 'Pro-rated subtotal',
		description: 'Row label for the prorated subtotal',
	},
	billRowLabel: {
		id: 'plan.billRowLabel',
		defaultMessage: 'Annual Bill',
		description: 'Row label for the bill',
	},
	nextBillLabel: {
		id: 'plan.nextBillLabel',
		defaultMessage: 'Next Bill Amount',
		description: 'Row label for the next amount the user will owe',
	},
	costPerCoveredLifeRowLabel: {
		id: 'plan.costPerCoveredLifeRowLabel',
		defaultMessage: 'Cost per covered life',
		description: 'Row label for cost per covered life.',
	},
	numberOfCoveredLivesLabel: {
		id: 'plan.numberOfCoveredLivesLabel',
		defaultMessage: 'Number of covered lives',
		description: 'Row label for number of covered lives.',
	},
	wasLabel: {
		id: 'plan.wasLabel',
		defaultMessage: 'was',
		description: 'Column label for previous prices',
	},
	nowLabel: {
		id: 'plan.nowLabel',
		defaultMessage: 'now',
		description: 'Column label for current prices',
	},
});
