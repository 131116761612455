import { useMemo, ReactElement, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Column } from 'react-table';

import { Tooltip } from '@calm-web/design-system';

import { PathwaysContext } from '@/components/providers/PathwaysContextProvider';
import Table from '@/components/ui/Table';
import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import { usePartnerPathways } from '@/hooks/api/usePathways';
import { useIsMobile } from '@/hooks/layout/useIsMobile';
import { isEditable } from '@/utils/helpers';
import redirect from '@/utils/redirect';

import messages from './messages';
import {
	TableColumn,
	ActiveColumn,
	UpcomingIcon,
	PastIcon,
	ActiveIcon,
	TableWrapper,
	PathwaysPlaceholder,
	PlaceholderIcon,
	PlaceholderHeading,
	MobileWrapper,
	MobileDataColumn,
	MobileLabel,
	MobileRow,
	MobilePathwayWrapper,
	EditText,
	EditTextMobile,
	TooltipWrapper,
} from './styles';

interface TablePathway {
	id: string;
	status: string;
	title: string;
	audience: number;
	announceDate: string | null;
	duration: string;
	editStatus?: boolean;
}

function DefaultCell({ value }: { value: string | number | null }): ReactElement {
	return <TableColumn>{value}</TableColumn>;
}

// TODO: Move the icons over to buttons (links) when we're ready for new pages
function ActiveCell({ value }: { value: string }): ReactElement {
	switch (value) {
		default:
		case 'upcoming':
			return (
				<ActiveColumn>
					<UpcomingIcon width={16} height={16} data-testid="upcoming-pathway-icon" />
				</ActiveColumn>
			);
		case 'current':
			return (
				<ActiveColumn>
					<ActiveIcon width={16} height={16} data-testid="active-pathway-icon" />
				</ActiveColumn>
			);
		case 'past':
			return (
				<ActiveColumn>
					<PastIcon width={16} height={16} data-testid="past-pathway-icon" />
				</ActiveColumn>
			);
	}
}

// TODO: Move the empty state to this component to simplify things in the parent
const PathwaysTable: React.FC = () => {
	const { formatMessage } = useIntl();
	const { logEvent } = useAnalytics();
	const { partnerId } = useParams();
	const { data: pathways, loading } = usePartnerPathways(partnerId);
	const { setCompletedSteps, serverTimeZone, shouldUsePathwaysV2 } = useContext(PathwaysContext);
	const [isMobile] = useIsMobile();

	// The server has the pathway start and end date in GMT-14 so that all users can get pushes on the selected days
	const formattedPathways: TablePathway[] =
		useMemo(
			() =>
				pathways?.map(pathway => {
					const { status, partner_id, title, total_users, announce_date, start_date, end_date } = pathway;
					const announceDate =
						announce_date &&
						new Date(announce_date).toLocaleDateString(undefined, {
							year: '2-digit',
							month: '2-digit',
							day: 'numeric',
						});
					const duration = `${new Date(start_date).toLocaleDateString(undefined, {
						year: '2-digit',
						month: '2-digit',
						day: 'numeric',
						timeZone: serverTimeZone,
					})} - ${new Date(end_date).toLocaleDateString(undefined, {
						year: '2-digit',
						month: '2-digit',
						day: 'numeric',
						timeZone: serverTimeZone,
					})} `;
					const shouldShowEmails = !shouldUsePathwaysV2 || !!(shouldUsePathwaysV2 && announceDate);
					return {
						id: partner_id,
						status,
						title,
						audience: total_users,
						announceDate,
						duration,
						editStatus: isEditable(shouldShowEmails, start_date, announce_date || undefined),
					};
				}),
			[pathways, serverTimeZone, shouldUsePathwaysV2],
		) ?? [];

	function EditPathwayCell({ value }: { value: boolean | undefined }): ReactElement {
		const editOrViewPathwayText = value
			? formatMessage(messages.editPathwayAria)
			: formatMessage(messages.viewPathwayAria);

		const TextComponent = isMobile ? EditTextMobile : EditText;

		return (
			<TooltipWrapper>
				<Tooltip
					maxWidth={205}
					content={
						value ? formatMessage(messages.pathwayEditTooltip) : formatMessage(messages.pathwayViewTooltip)
					}
				>
					<TextComponent>{editOrViewPathwayText}</TextComponent>
				</Tooltip>
			</TooltipWrapper>
		);
	}

	const columns: Column<TablePathway>[] = [
		{ Header: ' ', accessor: 'status', Cell: ActiveCell, width: 32, maxWidth: 32 },
		{
			Header: formatMessage(messages.titleHeader),
			accessor: 'title',
			Cell: DefaultCell,
			minWidth: 200,
		},
		{
			Header: formatMessage(messages.audienceHeader),
			accessor: 'audience',
			Cell: DefaultCell,
			maxWidth: 100,
			width: 100,
		},
		{
			Header: formatMessage(messages.signupAnnouncementHeader),
			accessor: 'announceDate',
			Cell: DefaultCell,
		},
		{ Header: formatMessage(messages.durationHeader), accessor: 'duration', Cell: DefaultCell },
	];

	columns.push({ Header: ' ', accessor: 'editStatus', Cell: EditPathwayCell, minWidth: 100 });

	const redirectToEdit = (index: number): void => {
		if (pathways) {
			logEvent('Partner Portal : Pathways Table : Row : Clicked', {
				pathway_id: pathways[index].id,
				selected_pathway: pathways[index].title,
			});
			setCompletedSteps({ selection: true, audience: true, schedule: true, review: true });
			redirect(`/${partnerId}/pathways/edit/${pathways[index].id}#review`);
		}
	};

	if (!loading && !pathways?.length) {
		return (
			<PathwaysPlaceholder data-testid="pathways-placeholder">
				<PlaceholderIcon />
				<PlaceholderHeading>{formatMessage(messages.placeholderHeading)}</PlaceholderHeading>
				{formatMessage(messages.placeholderDescription)}
			</PathwaysPlaceholder>
		);
	}

	return (
		<>
			{isMobile ? (
				<MobilePathwayWrapper>
					{formattedPathways.map(({ status, title, audience, announceDate, duration, editStatus }, index) => (
						<MobileWrapper key={`${title}_${duration}`} onClick={() => redirectToEdit(index)}>
							<MobileDataColumn>
								<MobileRow>
									<ActiveCell value={status} />
									<span>{title}</span>
								</MobileRow>
							</MobileDataColumn>
							<MobileDataColumn>
								<div>
									<MobileLabel>{formatMessage(messages.audienceHeader)}</MobileLabel>
									<span>{audience}</span>
								</div>
							</MobileDataColumn>
							<MobileDataColumn>
								<div>
									<MobileLabel>{formatMessage(messages.signupAnnouncementHeader)}</MobileLabel>
									<span>{announceDate}</span>
								</div>
							</MobileDataColumn>
							<MobileDataColumn>
								<div>
									<MobileLabel>{formatMessage(messages.durationHeader)}</MobileLabel>
									<span>{duration}</span>
								</div>
							</MobileDataColumn>
							<MobileDataColumn>
								<MobileLabel>
									<EditPathwayCell value={editStatus} />
								</MobileLabel>
							</MobileDataColumn>
						</MobileWrapper>
					))}
				</MobilePathwayWrapper>
			) : (
				<TableWrapper>
					<Table
						columns={columns}
						data={formattedPathways}
						pageCount={1}
						error={undefined}
						loading={loading}
						alignHeadingsToText
						cellNoPadding
						cellVerticalAlign="middle"
						customHeaderAndRowHeight={60}
						onRowClick={redirectToEdit}
					/>
				</TableWrapper>
			)}
		</>
	);
};

export default PathwaysTable;
