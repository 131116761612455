import { defineMessages } from 'react-intl';

export default defineMessages({
	updateCopy: {
		id: 'salesforceDetails.actionModal.update.copy',
		defaultMessage: 'Are you sure you want to disconnect this partner from Salesforce?',
		description: 'Copy confirming account manager wants to disconnect Salesforce account.',
	},
	connectCTA: {
		id: 'salesforceDetails.actionModal.button.connect',
		defaultMessage: 'Save changes',
		description: 'Connect Salesforce account button copy',
	},
	disconnectCTA: {
		id: 'salesforceDetails.actionModal.button.disconnect',
		defaultMessage: 'Disconnect',
		description: 'Disconnect Salesforce account button copy',
	},
	saveChangesCTA: {
		id: 'salesforceDetails.actionModal.button.saveChanges',
		defaultMessage: 'Save Changes',
		description: 'Save changes button copy',
	},
	cancelCTA: {
		id: 'salesforceDetails.actionModal.button.cancel',
		defaultMessage: 'Cancel',
		description: 'Cancel button copy',
	},
	currentSalesforceId: {
		id: 'salesforceDetails.actionModal.currentSalesforceId',
		defaultMessage: 'Current Salesforce Account ID',
		description: 'Label above the current for Salesforce account ID',
	},
	addSalesforceIdDescription: {
		id: 'salesforceDetails.actionModal.addSalesforceIdDecription',
		defaultMessage:
			'Enter the [18 character] Salesforce Account ID for this partner. The ID may be shared by more than one partner (e.g. corporate buying for franchises).',
		description: 'Instructions for adding an SFDC id',
	},
	salesforceDevNTestAccountDescription: {
		id: 'salesforceDetails.actionModal.salesforceTestAccountIdDescription',
		defaultMessage: 'For internal testing, you may use this Account ID: {accountId}',
		description: 'Helper text that provides the SFDC Account ID for testing purposes',
	},
	salesforceProdTestAccountDescription: {
		id: 'salesforceDetails.actionModal.salesforceProdTestAccountDescription',
		defaultMessage:
			'For internal testing, you may use this Account ID: {accountId}. However, please notify Sales Ops <slackLink>on Slack here</slackLink> BEFORE creating this account.',
		description:
			'Helper text that provides the SFDC Account ID for testing purposes & warning for Production.',
	},
	salesforceIdPlaceholder: {
		id: 'salesforceDetails.actionModal.salesforceIdPlaceholder',
		defaultMessage: 'Enter new Salesforce Account ID',
		description: 'Placeholder for Salesforce id input',
	},
	salesforceIdMinLengthError: {
		id: 'salesforceDetails.actionModal.salesforceIdMinLengthError',
		defaultMessage: 'Salesforce Account ID must be exactly 18 characters',
		description: 'Error message when the salesforce id is not long enough',
	},
	noAccount: {
		id: 'salesforceDetails.actionModal.noAccountFound',
		defaultMessage: 'No account found',
		description: 'Message when looking up b2b account and none was found',
	},
	accountFound: {
		id: 'salesforceDetails.actionModal.accountFound',
		defaultMessage: 'Account Found',
		description: 'Message when looking up b2b account and account was found',
	},
	savePrompt: {
		id: 'salesforceDetails.actionModal.savePrompt',
		defaultMessage: 'Click below to save',
		description: 'Prompt for saving account name',
	},
});
