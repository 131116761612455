import { FC, ReactElement } from 'react';

import { Modal } from '@calm-web/design-system';

import { UploadResults, useSyncEligibility } from '@/hooks/api/useEligibilityFile';
import { usePermissions } from '@/hooks/auth';

import { StyledPreviewResults, SuccessButton } from './styles';

function SyncPreview({
	partnerId,
	syncResults,
	afterSync,
}: {
	partnerId: string;
	syncResults: UploadResults;
	afterSync: () => void;
}): ReactElement {
	const [syncEligibility, { loading: syncLoading, error: syncError }] = useSyncEligibility(partnerId);

	const noChanges = syncResults && !syncResults.deletedCount && !syncResults.addedCount;

	async function handleClick(): Promise<void> {
		await syncEligibility();
		afterSync();
	}
	return (
		<StyledPreviewResults
			previewResults={syncResults}
			noChanges={noChanges}
			button={
				<SuccessButton type="button" onPress={handleClick} isLoading={syncLoading}>
					{syncError ? 'Error syncing' : 'Continue Sync'}
				</SuccessButton>
			}
		/>
	);
}

export default function SyncButton({ partnerId }: { partnerId: string }): ReturnType<FC> {
	const [syncEligibility, { loading: syncLoading, error: syncError, data: syncResults, clearData }] =
		useSyncEligibility(partnerId, true);
	const [hasValidPermissions, actions] = usePermissions();

	if (!hasValidPermissions('sync_eligibility_file', [actions.UPDATE]) || partnerId !== 'calm') {
		return null;
	}

	function clearSync(): void {
		clearData();
	}

	return (
		<>
			<SuccessButton type="button" onPress={syncEligibility} isLoading={syncLoading}>
				{syncError ? 'Error syncing' : 'Sync with Zenefits'}
			</SuccessButton>
			{!syncError && !syncLoading && syncResults && (
				<Modal isOpen title="Sync preview" closeModal={clearSync}>
					<SyncPreview syncResults={syncResults} afterSync={clearSync} partnerId={partnerId} />
				</Modal>
			)}
		</>
	);
}
