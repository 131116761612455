import isEqual from 'lodash/isEqual';
import { useCallback, useMemo, useState } from 'react';

import useForm, { FormProps, validation } from '@calm-web/use-form';

import { EligibilityValidator, HealthConfig } from '@/types/health';

type FieldNames = 'validation_name' | 'validation_type' | 'validation_display_name';

export type EditHealthEligibilityValidatorFormProps = FormProps<FieldNames>;

const useHealthEligibilityValidatorForm = (
	index: number,
	eligibilityValidator?: EligibilityValidator,
): { formProps: EditHealthEligibilityValidatorFormProps; hasChangedAny: boolean; hasTouchedAny: boolean } => {
	const formProps: EditHealthEligibilityValidatorFormProps = useForm(
		`healthEligibilityValidatorForm-${index}`,
		{
			initialModel: {
				validation_name: eligibilityValidator?.validation_name ?? '',
				validation_type: eligibilityValidator?.validation_type ?? '',
				validation_display_name: eligibilityValidator?.validation_display_name ?? '',
			},
			validation: {
				validation_name: validation.validateOrFail([
					{
						rules: [validation.required],
						errorResult: 'Please select an eligibility file field',
					},
				]),
				validation_type: validation.validateOrFail([
					{
						rules: [validation.required],
						errorResult: 'Please select an eligibility file field type',
					},
				]),
				validation_display_name: validation.validateOrFail([
					{
						rules: [validation.required],
						errorResult: 'Please enter valid display text',
					},
				]),
			},
		},
	);

	const hasChangedAny = !!Object.values(formProps.dirtyState).some(value => value?.hasChanged);
	const hasTouchedAny = !!Object.values(formProps.dirtyState).some(value => value?.hasTouched);

	return { formProps, hasChangedAny, hasTouchedAny };
};

export const useHealthEligibilityValidatorForms = (
	healthConfig?: Pick<HealthConfig, 'eligibility_validators'>,
): {
	eligibilityValidatorFormProps: EditHealthEligibilityValidatorFormProps[];
	hasChangedAny: boolean;
	hasTouchedAny: boolean;
	resetAllDirtyStates: () => void;
	addEligibilityValidator: () => void;
	removeEligibilityValidator: (index: number) => void;
} => {
	// It is annoying that hooks can't be called conditionally, so we have to set up all possible forms every time
	const [numEligibilityValidators, setNumEligibilityValidators] = useState(
		healthConfig?.eligibility_validators.length ?? 0,
	);
	const eligibilityValidatorsForm0 = useHealthEligibilityValidatorForm(
		0,
		healthConfig?.eligibility_validators[0],
	);
	const eligibilityValidatorsForm1 = useHealthEligibilityValidatorForm(
		1,
		healthConfig?.eligibility_validators[1],
	);
	const eligibilityValidatorsForm2 = useHealthEligibilityValidatorForm(
		2,
		healthConfig?.eligibility_validators[2],
	);
	const eligibilityValidatorsForm3 = useHealthEligibilityValidatorForm(
		3,
		healthConfig?.eligibility_validators[3],
	);
	const eligibilityValidatorsForm4 = useHealthEligibilityValidatorForm(
		4,
		healthConfig?.eligibility_validators[4],
	);
	const [formOrder, setFormOrder] = useState([0, 1, 2, 3, 4]);
	const getFormNumber = useCallback(
		(index: number) =>
			[
				eligibilityValidatorsForm0,
				eligibilityValidatorsForm1,
				eligibilityValidatorsForm2,
				eligibilityValidatorsForm3,
				eligibilityValidatorsForm4,
			][index],
		[
			eligibilityValidatorsForm0,
			eligibilityValidatorsForm1,
			eligibilityValidatorsForm2,
			eligibilityValidatorsForm3,
			eligibilityValidatorsForm4,
		],
	);
	const eligibilityValidatorForms = useMemo(() => {
		return formOrder.map(getFormNumber).slice(0, numEligibilityValidators);
	}, [formOrder, getFormNumber, numEligibilityValidators]);

	const addEligibilityValidator = (): void => setNumEligibilityValidators(num => Math.min((num ?? 0) + 1, 5));
	const removeEligibilityValidator = useCallback(
		(index: number) => {
			// Move the index to the back of the array, decrement the numEligibilityValidators so that it gets excluded, and reset its state
			const eligibilityValidatorForm = getFormNumber(formOrder[index]);
			eligibilityValidatorForm.formProps.resetAllDirtyStates();
			eligibilityValidatorForm.formProps.setModel({
				validation_name: '',
				validation_type: '',
				validation_display_name: '',
			});
			setFormOrder(items => [...items.slice(0, index), ...items.slice(index + 1), items[index]]);
			setNumEligibilityValidators(num => num - 1);
		},
		[formOrder, getFormNumber],
	);
	const eligibilityValidatorFormProps = useMemo(
		() => eligibilityValidatorForms.map(form => form.formProps),
		[eligibilityValidatorForms],
	);

	const hasChangedFormOrder =
		!isEqual(
			formOrder.slice(0, numEligibilityValidators),
			[0, 1, 2, 3, 4].slice(0, healthConfig?.eligibility_validators.length ?? 0),
		) && healthConfig?.eligibility_validators.length !== undefined;

	const hasChangedAny = eligibilityValidatorForms.some(props => props.hasChangedAny) || hasChangedFormOrder;
	const hasTouchedAny = eligibilityValidatorForms.some(props => props.hasTouchedAny);

	const resetAllDirtyStates = useCallback(() => {
		eligibilityValidatorFormProps.forEach(form => form.resetAllDirtyStates());
	}, [eligibilityValidatorFormProps]);

	return {
		eligibilityValidatorFormProps,
		hasChangedAny,
		hasTouchedAny,
		resetAllDirtyStates,
		addEligibilityValidator,
		removeEligibilityValidator,
	};
};
