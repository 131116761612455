import { ReactElement } from 'react';
import { useIntl } from 'react-intl';

import { Button } from '@calm-web/design-system';

import messages from './messages';

interface TerminateModalFooterProps {
	hasTerminated: boolean;
	isTerminateDisabled: boolean;
	isTerminating: boolean;
	onModalDismiss: () => void;
	onTerminatePlan: () => void;
}
export const TerminatePlanModalFooter = ({
	hasTerminated,
	isTerminateDisabled,
	isTerminating,
	onModalDismiss,
	onTerminatePlan,
}: TerminateModalFooterProps): ReactElement => {
	const { formatMessage } = useIntl();
	// processing terminate
	if (isTerminating) {
		return (
			<Button isDisabled backgroundColor="blue3">
				{formatMessage(messages.done)}
			</Button>
		);
	}
	// terminate completed
	if (hasTerminated) {
		return (
			<Button backgroundColor="blue3" onPress={onModalDismiss} data-testid="terminate-plan-done-button">
				{formatMessage(messages.done)}
			</Button>
		);
	}

	// before pressing terminate button
	return (
		<Button
			isDisabled={isTerminateDisabled}
			backgroundColor="errorRed"
			isLoading={isTerminating}
			onPress={onTerminatePlan}
			data-testid="terminate-partner-confirm-button"
		>
			{formatMessage(messages.terminatePlan)}
		</Button>
	);
};
