import { FC, useState } from 'react';
import { useIntl } from 'react-intl';

import { Loader, TextButton, PrimaryButton, Modal, ModalWidth } from '@calm-web/design-system';

import { useSalesforceAccount } from '@/hooks/api/useSalesforceAccount';

import AccountActionModalContent, { ConnectSalesforceIdModal } from '../AccountActionModalContent';
import messages from './messages';
import { Wrapper, ButtonWrapper, AccountId, NoAccountText } from './styles';

interface Props {
	b2bAccountId?: string;
	onSuccess: (b2bAccountId: string) => void;
}

export const ConnectSalesforceAccount: FC<Props> = ({ onSuccess, b2bAccountId }) => {
	const [showModal, setShowModal] = useState<boolean>(false);

	const { formatMessage } = useIntl();
	const closeModal = (): void => {
		setShowModal(false);
	};

	const onConnectClick = (): void => {
		setShowModal(true);
	};

	return (
		<Wrapper>
			<NoAccountText>{formatMessage(messages.notAssociated)}</NoAccountText>
			<ButtonWrapper>
				<PrimaryButton
					backgroundColor="blue3"
					onPress={onConnectClick}
					type="button"
					size={'sm'}
					data-testid="add-salesforce-account-id"
				>
					{formatMessage(messages.addSalesforceIdCTA)}
				</PrimaryButton>
			</ButtonWrapper>
			<Modal
				canClose
				isOpen={showModal}
				closeModal={closeModal}
				aria-label={formatMessage(messages.modalConnectTitle)}
				width={ModalWidth.Extra}
			>
				<ConnectSalesforceIdModal b2bAccountId={b2bAccountId} closeModal={closeModal} onSuccess={onSuccess} />
			</Modal>
		</Wrapper>
	);
};

const AccountName: FC<{ salesforceAccountId: string }> = ({ salesforceAccountId }) => {
	const { data: salesforceAccountInfo, loading } = useSalesforceAccount(salesforceAccountId);

	if (loading) {
		return <Loader color="gray1" />;
	}

	return <div>{salesforceAccountInfo?.name}</div>;
};

const SalesforceAccountInfo: FC<
	Props & {
		salesforceAccountId: string;
		onDisconnect: () => void;
	}
> = ({ salesforceAccountId, b2bAccountId, onSuccess, onDisconnect }) => {
	const { formatMessage } = useIntl();
	const [showModal, setShowModal] = useState<boolean>(false);
	const salesforceBaseUrl =
		process.env.CALM_ENV === 'dev'
			? 'https://yourcalm--partial.sandbox.lightning.force.com/lightning/r' // Partial Sandbox = our Dev ENV for Salesforce
			: 'https://yourcalm.lightning.force.com/lightning/r';
	const salesforceAccountLink = `${salesforceBaseUrl}/Account/${salesforceAccountId}/view`;

	const closeModal = (): void => {
		setShowModal(false);
	};

	const onDisconnectClick = (): void => {
		setShowModal(true);
	};

	return (
		<Wrapper>
			<AccountId target="_blank" rel="noopener noreferrer" href={salesforceAccountLink}>
				{salesforceAccountId}
			</AccountId>
			{salesforceAccountId ? <AccountName salesforceAccountId={salesforceAccountId} /> : null}
			<ButtonWrapper>
				<TextButton textColor="heartRed" size={0.75} hideUnderline onClick={onDisconnectClick}>
					{formatMessage(messages.disconnectCTA)}
				</TextButton>
			</ButtonWrapper>
			<Modal
				canClose
				isOpen={showModal}
				closeModal={closeModal}
				aria-label={formatMessage(messages.modalDisconnectTitle)}
				width={ModalWidth.Extra}
			>
				<AccountActionModalContent
					salesforceAccountId={salesforceAccountId}
					b2bAccountId={b2bAccountId}
					closeModal={closeModal}
					onSuccess={onSuccess}
					onDisconnect={onDisconnect}
				/>
			</Modal>
		</Wrapper>
	);
};

export default SalesforceAccountInfo;
