import axios from 'axios';
import { useCallback, useState } from 'react';

import { useApi } from '@/hooks/api';
import { getCalmErrorOrError } from '@/utils/apiRequest/errors';

export const getLocalStoragePendingFiles = (
	partnerId: string,
): { fileName: string; uploadTime: string }[] => {
	const storedFiles = localStorage.getItem('sftpEFPendingFiles');
	const partnerDictionary = storedFiles ? JSON.parse(storedFiles) : {};
	return partnerDictionary[partnerId] || [];
};

export const addLocalStoragePendingFiles = (
	partnerId: string,
	file: { fileName: string; uploadTime: string },
): void => {
	const storedFiles = localStorage.getItem('sftpEFPendingFiles');
	const partnerDictionary = storedFiles ? JSON.parse(storedFiles) : {};
	if (!partnerDictionary[partnerId]) {
		partnerDictionary[partnerId] = [];
	}
	partnerDictionary[partnerId].push(file);
	localStorage.setItem('sftpEFPendingFiles', JSON.stringify(partnerDictionary));
};

export const removeLocalStoragePendingFiles = (
	partnerId: string,
	fileToRemove: { fileName: string; uploadTime: string },
): void => {
	const storedFiles = localStorage.getItem('sftpEFPendingFiles');
	const partnerDictionary = storedFiles ? JSON.parse(storedFiles) : {};
	partnerDictionary[partnerId] = partnerDictionary[partnerId].filter(
		(file: { fileName: string; uploadTime: string }) => {
			return file.fileName !== fileToRemove.fileName && file.uploadTime >= fileToRemove.uploadTime;
		},
	);
	localStorage.setItem('sftpEFPendingFiles', JSON.stringify(partnerDictionary));
};

export function useUploadSftpEligibilityFile(partnerId: string): {
	upload: (file: File) => Promise<void>;
	progress: number;
	isUploading: boolean;
} {
	const [progress, setProgress] = useState(0);
	const [isUploading, setIsUploading] = useState(false);
	const apiRequest = useApi();
	const upload = useCallback(
		async (file: File) => {
			try {
				setIsUploading(true);
				setProgress(0);

				// get signed url
				const response = await apiRequest({
					endpoint: `b2b/partners/${partnerId}/eligibility-upload/signed-url?file_name=${file.name}`,
				});
				const signedUrl = response?.data?.upload_url;

				if (!signedUrl) {
					throw new Error('SIGNED_URL_NOT_FOUND');
				}

				// upload the file
				const putResponse = await axios.put(signedUrl, file, {
					onUploadProgress: progressEvent => {
						const total = progressEvent.total ?? 1; // Default to 1 to avoid division by zero
						const progress = Math.round((progressEvent.loaded * 100) / total);
						setProgress(progress);
					},
				});
				if (putResponse.status >= 400) {
					throw new Error('File failed to upload');
				}

				const newFile = { fileName: file.name, uploadTime: new Date().toISOString() };
				addLocalStoragePendingFiles(partnerId, newFile);
			} catch (error) {
				throw getCalmErrorOrError(error);
			} finally {
				setIsUploading(false);
			}
		},
		[apiRequest, partnerId],
	);
	return { upload, progress, isUploading: isUploading };
}
