import { FC, ReactElement, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { usePartner } from '@/hooks/api/usePartner';
import { useUser } from '@/hooks/store';
import { useBreadcrumbPageTitle } from '@/hooks/usePageTitle';

import { BreadcrumbLink, BreadcrumbsWrapper, Divider } from './styles';

interface PartnerProps {
	partnerId: string;
}

function PartnerBreadcrumb({ partnerId }: PartnerProps): ReturnType<FC> {
	const { search } = useLocation();
	const { data: partner } = usePartner(partnerId);
	const { user } = useUser();

	const extraBreadcrumb = useMemo(() => {
		if (!partner?.parent) {
			return null;
		}
		if (
			user?.accessPolicy?.allowedPartners.includes('*') ||
			user?.accessPolicy?.allowedPartners.includes(partner.parent.id)
		) {
			return (
				<>
					<Divider>/</Divider>
					<Link to={{ pathname: `/${partner.parent?.id}/home`, search }}>
						<BreadcrumbLink>{partner.parent?.name}</BreadcrumbLink>
					</Link>
				</>
			);
		}
		return (
			<>
				<Divider>/</Divider>
				{partner.parent?.name}
			</>
		);
	}, [partner, search, user]);

	if (!partner) {
		return null;
	}
	return (
		<>
			{extraBreadcrumb}
			<Divider>/</Divider>
			{partner.name}
		</>
	);
}

function GroupsBreadcrumb(): ReactElement {
	const { search } = useLocation();
	const { user } = useUser();

	const groupsBreadcrumb =
		user?.accessPolicy?.isAdmin || (user?.accessPolicy?.allowedPartners.length ?? 0) > 1 ? (
			<Link to={{ pathname: '/groups', search }}>
				<BreadcrumbLink>Groups</BreadcrumbLink>
			</Link>
		) : (
			<span>Groups</span>
		);

	return groupsBreadcrumb;
}

interface BreadcrumbsProps {
	partnerId?: string;
}

export default function Breadcrumbs({ partnerId }: BreadcrumbsProps): ReactElement {
	const pageTitle = useBreadcrumbPageTitle();

	return (
		<BreadcrumbsWrapper>
			<GroupsBreadcrumb />
			{partnerId && <PartnerBreadcrumb partnerId={partnerId} />}
			{pageTitle && (
				<>
					<Divider>/</Divider>

					{pageTitle}
				</>
			)}
		</BreadcrumbsWrapper>
	);
}
