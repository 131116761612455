/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

export default defineMessages({
	increaseCoverageButtonText: {
		id: 'users.increaseCoverageButtonText',
		defaultMessage: 'Increase Coverage',
		description: 'Button text for increasing plan coverage',
	},
	increaseCoverageTitle: {
		id: 'users.increaseCoverageButtonTitle',
		defaultMessage: 'Increase Coverage',
		description: 'Modal title for increasing plan coverage',
	},
	additionalCoveragePrompt: {
		id: 'users.add.additionalCoveragePrompt',
		defaultMessage: 'Would you like to purchase additional coverage?',
		description: 'Message prompting users to purchase additional coverage',
	},
});
