import { defineMessages } from 'react-intl';

export default defineMessages({
	partnerCategoryTitle: {
		id: 'partnerCategory.partnerCategoryTitle',
		defaultMessage: 'Partner Category',
		description: 'Cell title for partner category',
	},
	partnerCategorySubtitle: {
		id: 'partnerCategory.partnerCategoryLabel.subtitle',
		defaultMessage: 'Choose the appropriate category for this partner.',
		description: 'Subtitle for partner category',
	},
	partnerCategoryCalmForBusinessSelection: {
		id: 'partnerCategory.partnerCategoryCalmForBusinessSelection',
		defaultMessage: 'Calm Business',
		description: 'Selection label for Calm Business partner category',
	},
	partnerCategorySalesTrial: {
		id: 'partnerCategory.partnerCategorySalesTrial',
		defaultMessage: 'Sales Trial',
		description: 'Selection label for Sales Trial partner category',
	},
	partnerCategoryTelcoPartnershipSelection: {
		id: 'partnerCategory.partnerCategoryTelcoPartnershipSelection',
		defaultMessage: 'Telco Partnership',
		description: 'Selection label for telco partnership partner category',
	},
	partnerCategoryBrandPartnershipSelection: {
		id: 'partnerCategory.partnerCategoryBrandPartnershipSelection',
		defaultMessage: 'Brand Partnership',
		description: 'Selection label for brand partnership partner category',
	},
	partnerCategoryHealthCareProviderSelection: {
		id: 'partnerCategory.partnerCategoryHealthCareProviderSelection',
		defaultMessage: 'Health Care Provider',
		description: 'Selection label for health care provider partner category',
	},
	partnerCategoryD2CLeadGenPartnershipSelection: {
		id: 'partnerCategory.partnerCategoryD2CLeadGenPartnershipSelection',
		defaultMessage: 'D2C Lead Gen Partnership',
		description: 'Selection label for d2c lead gen partner category',
	},
	partnerCategoryCalmHealthSelection: {
		id: 'partnerCategory.partnerCategoryCalmHealthSelection',
		defaultMessage: 'Calm Health',
		description: 'Selection label for Calm Health partner category',
	},
});
