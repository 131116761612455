import { defineMessages } from 'react-intl';

export default defineMessages({
	infoPageDescription1: {
		id: 'pathwaysInfoPages.description1',
		defaultMessage: 'You choose from a growing library of Pathways topics and when you want it to launch',
		description: 'Description for the first info page',
	},
	infoPageDescription2: {
		id: 'pathwaysInfoPages.description2',
		defaultMessage: 'Your selected audience sees announcements for Pathways in their app when they launch',
		description: 'Description for the second info page',
	},
	infoPageDescription3: {
		id: 'pathwaysInfoPages.description3',
		defaultMessage: 'They unlock new content related to the Pathway every other day',
		description: 'Description for the third info page',
	},
	nextButton: {
		id: 'pathwaysInfoPages.nextButton',
		defaultMessage: 'Next',
		description: 'Text for the Next button',
	},
	finishButton: {
		id: 'pathwaysInfoPages.finishButton',
		defaultMessage: 'View Pathways Dashboard',
		description: 'Text for the Finish button',
	},
});
