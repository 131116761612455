import { capitalize } from 'lodash';
import { ReactElement, CSSProperties } from 'react';

import { PaletteColor } from '@calm-web/design-system';

import { OuterLegend, InnerLegend, ColorLabel, TextLabel } from './styles';

interface Props {
	legendInfo: {
		key: string;
		color?: string;
		paletteColor?: PaletteColor;
	}[];
	style?: CSSProperties;
}

function GraphLegend({ legendInfo, style }: Props): ReactElement {
	return (
		<OuterLegend style={style}>
			{legendInfo.map(row => {
				return (
					<InnerLegend key={`${row.key} ${row.paletteColor} ${row.color}`}>
						<ColorLabel $color={row.color} $paletteColor={row.paletteColor} />
						<TextLabel>{capitalize(row.key.replaceAll('_', '-'))}</TextLabel>
					</InnerLegend>
				);
			})}
		</OuterLegend>
	);
}

export default GraphLegend;
