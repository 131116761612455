import { FC } from 'react';

import { FontWeights, FontSizes, Text } from '@calm-web/design-system';

interface Props {
	signupLink: string;
	CustomMessageBlock: FC;
}

// Launch Email template used after ~April 18th, 2023 for partners who use EF AND 'email' as their unique identifier.
const NewAdminLaunchEmail: FC<Props> = ({ signupLink, CustomMessageBlock }) => {
	return (
		<>
			<Text el="h2" size={FontSizes.xl} weight={FontWeights.Medium}>
				Hello,New
			</Text>
			<Text el="span">We are excited to introduce Calm as our newest mental health employee benefit!</Text>
			<br />
			<br />
			<Text el="span">
				Whether you have 30 seconds or 30 minutes, Calm can help you reduce stress and anxiety, get better
				sleep and live a happier, healthier life. Explore relaxing Sleep Stories, guided meditations, mindful
				movement, focus music, tailored content for children, masterclasses, and more.
			</Text>
			<br />
			<br />
			<CustomMessageBlock />
			<Text el="p" weight={FontWeights.Medium}>
				How to get started:
			</Text>
			<br />
			<br />
			<Text el="span" weight={FontWeights.Medium}>
				To activate through mobile:
			</Text>
			<ol>
				<li>
					<Text el="span">Download and open the Calm app</Text>
				</li>
				<li>
					<Text el="span">
						Create an account with a personal email address and go to Profile &gt; Settings (gear symbol) &gt;
						Link Organization Subscription
					</Text>
				</li>
				<li>
					<Text el="span">
						Click on <i>Redeem via Email</i> and enter your credentials to activate your free subscription
					</Text>
				</li>
			</ol>
			<br />
			<Text el="span">
				If you already have an existing Calm account, go to your Settings and follow step 3.
			</Text>
			<br />
			<br />
			<Text el="span" weight={FontWeights.Medium}>
				To activate through desktop:
			</Text>
			<ol>
				<li>
					<Text el="span">
						Visit this link: <a href={signupLink}>{signupLink}</a>.
					</Text>
				</li>
				<li>
					<Text el="span">
						Sign up with your personal email address or log in to an existing Calm account
					</Text>
				</li>
				<li>
					<Text el="span">Validate your work email address</Text>
				</li>
				<li>
					<Text el="span">Once complete, you can download the Calm app and log in to your new account</Text>
				</li>
			</ol>
			<br />
			<Text el="span">
				For further assistance, check out this{' '}
				<a href="https://support.calm.com/hc/en-us/articles/360055261934-How-to-redeem-your-subscription-provided-by-your-employer">
					Help Center article
				</a>
				.
			</Text>
			<br />
			<br />
			<Text el="span">
				For information on Calm&apos;s privacy practices, see their privacy policy at{' '}
				<a href="https://calm.com/privacy">calm.com/privacy</a>.
			</Text>
		</>
	);
};

export default NewAdminLaunchEmail;
