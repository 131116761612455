import { JSONValue } from '@segment/analytics-next';
import { DateTime } from 'luxon';
import { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import useSWR from 'swr';

import type { SelectedCohorts } from '@/components/providers/PathwaysContextProvider';
import { useApi } from '@/hooks/api';
import { setBannerMessage } from '@/store/actions';
import { PacksFeedResponse } from '@/types/content';
import { CalmError, getCalmErrorOrError } from '@/utils/apiRequest/errors';
import { calmLogger } from '@/utils/calmLogger';
import { iso8601Date } from '@/utils/helpers';
import redirect from '@/utils/redirect';
import { SegmentSelectState, segmentsToJSON } from '@/utils/segments';

import { ApiResponse, RefetchOption } from './types';

export interface Pathway {
	pack_class: string;
	image: string;
	title: string;
	subtitle: string;
	isActive?: boolean | undefined;
}

export interface PathwayDetails {
	image: string;
	title: string;
	subtitle: string;
}

export interface SavedPathway {
	pack_details: {};
}

interface SelectedPathway {
	id: string;
	partner_id: string;
	status: string;
	pack_class: string;
	announce_date: Date | null;
	remind_date: Date | null;
	start_date: Date;
	end_date: Date;
	total_users: number;
	title: string;
}

export interface SinglePathway {
	id: string;
	pack_class: string;
	start_date: string;
	partner_id: string;
	total_users: number;
	segments: SelectedCohorts;
	announce_date: string | null;
	remind_date: string | null;
	status: string;
	end_date: string;
	title: string;
}

interface notificationObject {
	title: string;
	description: string;
}

interface notificationProps {
	[pack_class: string]: notificationObject[];
}

/* Hardcoded descriptions for now. If the number of packs start growing, we will consider switching to make this an admin portal field under pack item */
export const notificationPreviewContent: notificationProps = {
	'b2b-pathway-setting-boundaries': [
		{
			title: 'Pathway: Setting Boundaries - Day 1',
			description:
				'This week focuses on work-life balance. You’ll relax your body, let go of tension, and reduce burnout with a short guided meditation.',
		},
		{
			title: 'Day 2 of Setting Boundaries Pathway!',
			description:
				"Use today's session to re-adjust your identity with work and sense of self so you can withstand the daily grind.",
		},
		{
			title: 'Day 3 of Setting Boundaries Pathway! ',
			description:
				'Mindfully manage your worries in this guided meditation to regain focus throughout the day.',
		},
		{
			title: 'Day 4 of Setting Boundaries Pathway! ',
			description:
				'In today’s session, you’ll release yourself from other people’s expectations and judgments to stay true to yourself.',
		},
		{
			title: 'Day 5 of Setting Boundaries Pathway!',
			description:
				"Today's body scan exercise will help you ground your awareness so you won’t be overwhelmed by others around you.",
		},
		{
			title: 'Last Day of Setting Boundaries Pathway! ',
			description:
				"Today's guided meditation will help you bring awareness to your own needs so you can practice setting better boundaries and not worry about disappointing others.",
		},
	],
	'b2b-pathways-manage-stress-and-overwhelm': [
		{
			title: 'Pathway: Managing Stress & Overwhelm - Day 1',
			description:
				'Feeling stressed? Try this short guided body relaxation exercise to help you reconnect your body and mind so you can start letting go of stress.',
		},
		{
			title: 'Day 2 of Managing Stress & Overwhelm Pathway!',
			description:
				'In moments of overwhelm, try this mindfulness exercise to bring awareness to your thoughts and surroundings so you can handle the day calmly. ',
		},
		{
			title: 'Day 3 of Managing Stress & Overwhelm Pathway!',
			description:
				'Let go of the tensions you’re carrying today by trying a progressive muscle relaxation exercise. Do it consistently to reduce symptoms of anxiety!',
		},
		{
			title: 'Day 4 of Managing Stress & Overwhelm Pathway!',
			description:
				'Reduce your daily overwhelm by focusing on one task for now. Also, try this breathwork exercise so you can reset and boost your focus.',
		},
		{
			title: 'Day 5 of Managing Stress & Overwhelm Pathway!',
			description:
				"Today's short guided meditation focuses on the noting technique which will help you let go of thoughts that don’t serve you.",
		},
		{
			title: 'Last Day of Managing Stress & Overwhelm Pathway!',
			description:
				'Today’s session will help you relieve stress and quiet the mind. You’ll also learn to engage with your prefrontal cortex to reduce overwhelm.',
		},
	],
	'b2b-pathways-build-a-self-care-routine': [
		{
			title: 'Pathway: Building a Self-Care Routine - Day 1',
			description:
				'Need to refocus your mind and relax your body? Try this short guided breathing exercise so you can unplug briefly and be more productive afterward.',
		},
		{
			title: 'Day 2 of Building a Self-Care Routine Pathway! ',
			description:
				"Use today's guided breathing exercise when you feel your internal pressure rising, and regain your calm so you can mindfully tackle what’s ahead.",
		},
		{
			title: 'Day 3 of Building a Self-Care Routine Pathway!',
			description:
				'In this grounding exercise, you’ll try a 5 senses activity which will calm your mind and body so you can anchor yourself in the present.',
		},
		{
			title: 'Day 4 of Building a Self-Care Routine Pathway!',
			description:
				'In today’s session, reflect on how you start your day and learn how to slowly wake up your brain in a more mindful manner.',
		},
		{
			title: 'Day 5 of Building a Self-Care Routine Pathway!',
			description:
				'Let’s try replacing your usual caffeinated drink in the morning with a more natural approach—stimulating breathing exercise! In a few minutes, you’ll feel more energized and ready to tackle the day.',
		},
		{
			title: 'Day 6 of Building a Self-Care Routine Pathway!',
			description:
				"Today's guided meditation will bring a sense of openness to your morning routine so you can set a positive tone for the day.",
		},
		{
			title: 'Day 7 of Building a Self-Care Routine Pathway!',
			description:
				'Try wrapping up your day with some reflections, a cleanup of physical and virtual spaces, enjoyable activities, and a conscious statement to fully transition to your personal time.',
		},
		{
			title: 'Day 8 of Building a Self-Care Routine Pathway! ',
			description:
				'Ready to play a game that you’ll never lose? In today’s session, learn how practicing daily gratitude can help you reduce stress and negativity.',
		},
		{
			title: 'Last Day of Building a Self-Care Routine Pathway!',
			description:
				'As you unwind for the day, try this short guided movement practice that’ll quiet your noisy mind and relax your tense body.',
		},
	],
	'b2b-pathways-recenter-yourself-through-gratitude': [
		{
			title: 'Time for your gratitude practice! 🙏',
			description:
				'Kick off the Recenter Yourself Through Gratitude Pathway by giving thanks to the small things ➡️',
		},
		{
			title: 'Gratitude Practice: Day 2 🙏',
			description:
				'Release negative thoughts and recall today’s positive moments with this guided exercise 💭',
		},
		{
			title: 'Gratitude Practice: Day 3 🙏',
			description: 'Relax your body and fall into a deep sleep tonight with a gentle gratitude practice 😴',
		},
		{
			title: 'Week 2 of Gratitude Practice 🙌',
			description: 'Time to learn how to have a healthy gratitude practice—without forcing it 🌟',
		},
		{
			title: 'Gratitude Practice: Day 5 🙏',
			description:
				'Try to notice the simple pleasures in your life to make your daily gratitude practice easier ☀️',
		},
		{
			title: 'Gratitude Practice: Last Day 🙏',
			description:
				'Relax into your slumber tonight with a gentle body scan while expressing your gratitude 💤',
		},
	],
	'b2b-pathways-ground-yourself-during-stressful-times': [
		{
			title: 'Ready to ground yourself? 🧘🏻‍♀️',
			description:
				'Let’s start the Ground Yourself During Stressful Times Pathway with a breathing exercise to regain your calm 😮‍💨',
		},
		{
			title: 'Ground Yourself: Day 2 🧘🏻‍♀️',
			description:
				'Use our visualization exercise to reach out to someone who needs a little extra support today 🤝',
		},
		{
			title: 'Ground Yourself: Day 3 🧘🏻‍♀️',
			description:
				'When your day feels hectic, try this grounding practice to activate your senses and recenter yourself 😌',
		},
		{
			title: 'Ground Yourself: Day 4 🧘🏻‍♀️',
			description:
				'When negative thoughts take over your mind, create some distance with our meditation exercise 🧘🏻‍♀️',
		},
		{
			title: 'Ground Yourself: Last Day 🙌',
			description: 'Try a gratitude reflection practice to reclaim your peace in moments of stress 🙏',
		},
	],
	'b2b-pathways-getting-started-with-calm': [
		{
			title: 'Welcome to Calm 😌',
			description: 'There’s no “right way” to meditate. Try a simple meditation practice with Jeff ➡️',
		},
		{
			title: 'Welcome to Calm: Day 2 😌',
			description: 'Deepen your focus throughout the day with our unique lo-fi music 🎶',
		},
		{
			title: 'Welcome to Calm: Day 3 😌',
			description: 'Enjoy Calm with your family tonight by listening to our Winnie the Pooh sleep story 😴',
		},
		{
			title: 'Welcome to Calm: Day 4 😌',
			description:
				'Having a rough day? Try our attitude adjustment exercise to reclaim your inner peace again 💭',
		},
		{
			title: 'Welcome to Calm: Day 5 😌',
			description:
				'Daily gentle stretch relaxes your body! Try this tech neck exercise for instant relief 💆‍♀️',
		},
		{
			title: 'Welcome to Calm: Last Day 😌',
			description: 'Stressed out or feeling anxious? Do a breathwork exercise to regain your calm 😮‍💨',
		},
		{
			title: 'Try something new on Calm 🤩',
			description: 'New mental health resources are added daily, so which one will you try today? 👀',
		},
	],
	'b2b-pathways-building-healthy-habits': [
		{
			title: 'Ready to build healthy habits? 🌱',
			description:
				'Let’s start with some reflection questions to help set the tone for your habit-building journey ➡️',
		},
		{
			title: 'Build Healthy Habits: Day 2 🌱',
			description:
				'Anyone can do meditation, even you! Let’s take 1-min together and meditate in silence with Jeff Warren 🧘🏻‍♀️',
		},
		{
			title: 'Build Healthy Habits: Day 3 🌱',
			description:
				'When you feel stressed, anxious, or even overwhelmed today, reset with the equal breathing exercise 😮‍💨',
		},
		{
			title: 'Build Healthy Habits: Day 4 🌱',
			description:
				'Having trouble making a habit stick? Try embodying a new identity and reinforce the habit 🌞',
		},
		{
			title: 'Build Healthy Habits: Day 5 🌱',
			description:
				'Quick, gentle stretches can help relieve any muscle tensions in your body during the day 🍃',
		},
		{
			title: 'Build Healthy Habits: Last Day 🎉',
			description:
				'A gentle reminder to be in the present and own your day to continue building daily habits 🙏🏻',
		},
	],
};

interface DateObject {
	startDate: string | undefined;
	announceDate: string | undefined;
	remindDate: string | undefined;
}

function parseDatesForDb(
	start_date: Date | null | undefined,
	announcement_date: Date | null | undefined,
	reminder_date: Date | null | undefined,
): DateObject {
	const startDate = start_date ? iso8601Date(start_date) : undefined;
	const announcementTimeString = announcement_date?.toTimeString().split(' ')[0];
	const reminderTimeString = reminder_date?.toTimeString().split(' ')[0];
	const announcementDateString = announcement_date ? iso8601Date(announcement_date) : undefined;
	const reminderDateString = reminder_date ? iso8601Date(reminder_date) : undefined;
	return {
		startDate: startDate,
		announceDate:
			announcementDateString && announcementTimeString
				? `${announcementDateString}T${announcementTimeString}`
				: undefined,
		remindDate:
			reminderDateString && reminderTimeString ? `${reminderDateString}T${reminderTimeString}` : undefined,
	};
}

const PATHWAYS_FEED_ID = 'b2b-partner-pathways';

export function usePathways(): ApiResponse<PacksFeedResponse> {
	const apiRequest = useApi();
	const dispatch = useDispatch();

	const { data, isLoading } = useSWR<PacksFeedResponse>(`packs/feeds/${PATHWAYS_FEED_ID}`, async endpoint => {
		try {
			const response = await apiRequest({
				endpoint,
				customHeaders: {
					// These custom headers are needed to ensure we can receive
					// content that's protected by our Content Policies.
					'x-device-capabilities': 'v1;signedCookie;streamOnly',
				},
			});
			if (!response.data) {
				throw new Error('Not able to fetch pack classes');
			}
			return response.data;
		} catch (responseError) {
			dispatch(
				setBannerMessage({
					message: `Failed to retrieve pack classes`,
					isError: true,
					flash: true,
				}),
			);
			throw responseError;
		}
	});

	return {
		data,
		loading: isLoading,
	};
}

export function usePathwayDetails({
	selectedPackClass,
}: {
	selectedPackClass: string;
}): ApiResponse<PathwayDetails> {
	const apiRequest = useApi();
	const dispatch = useDispatch();

	const { data, error } = useSWR(
		`/b2b/packs/pack-classes?pack_class=${selectedPackClass}`,
		async endpoint => {
			try {
				const response = await apiRequest({ endpoint });
				if (!response.data) {
					throw new Error('Not able to fetch pack class details');
				}
				return response.data;
			} catch (responseError) {
				dispatch(
					setBannerMessage({
						message: `Failed to retrieve pack class details`,
						isError: true,
						flash: true,
					}),
				);
				throw responseError;
			}
		},
	);

	return {
		data,
		error,
		loading: !data && !error,
	};
}

export function usePathwayTimeline(pack_class?: string, start_date?: Date | null): ApiResponse<Date[]> {
	const apiRequest = useApi();
	const dispatch = useDispatch();
	// Extract just the day portion -- API doesn't want to hear about an exact timestamp
	const startDate = useMemo(() => (start_date ? iso8601Date(start_date) : null), [start_date]);

	const { data, error } = useSWR(
		pack_class && start_date
			? `b2b/partners/pathways/schedule?pack_class=${pack_class}&start_date=${startDate}`
			: null,
		async endpoint => {
			try {
				const response = await apiRequest<{ item_release_dates: string[] }>({ endpoint });
				if (!response.data) {
					throw new Error('Not able to fetch pack class details');
				}
				return response.data;
			} catch (responseError) {
				dispatch(
					setBannerMessage({
						message: `Failed to retrieve pack class details`,
						isError: true,
						flash: true,
					}),
				);
				throw responseError;
			}
		},
	);

	const pathwayTimeline = useMemo(
		() => data?.item_release_dates.map(dateString => new Date(`${dateString}T00:00:00`)),
		[data],
	);

	return {
		data: pathwayTimeline,
		error,
		loading: !data && !error,
	};
}

type SaveFunction = () => Promise<void>;
export function useSaveSelectedPathway(
	partner_id: string,
	pack_class?: string,
	emailSettings?: string,
	announce_date?: Date | null,
	remind_date?: Date | null,
	start_date?: Date | null,
	selectedCohorts?: SelectedCohorts | null,
	shouldUsePathwaysV2?: boolean,
): [SaveFunction, ApiResponse<SavedPathway>] {
	const apiRequest = useApi();
	const dispatch = useDispatch();
	const [result, setResult] = useState<SavedPathway | undefined>();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<CalmError | Error | undefined>();

	const savePathway = async (): Promise<void> => {
		// Parse date back to US timezone format and create a date string without timezone to send back to the db
		const { startDate, announceDate, remindDate } = parseDatesForDb(start_date, announce_date, remind_date);
		const segments =
			selectedCohorts &&
			(selectedCohorts.segment_1_values ||
				selectedCohorts.segment_2_values ||
				selectedCohorts.segment_3_values)
				? selectedCohorts
				: null;

		setError(undefined);

		if (loading || !pack_class) return;
		try {
			setLoading(true);

			const response = await apiRequest({
				endpoint: `b2b/partners/${partner_id}/pathways`,
				method: 'POST',
				body: {
					pack_class,
					announce_date: shouldUsePathwaysV2 && emailSettings === 'off' ? null : announceDate,
					remind_date: shouldUsePathwaysV2 && emailSettings === 'off' ? null : remindDate,
					start_date: startDate,
					segments,
				},
			});

			const { data } = response;
			setResult(data);
			return data;
		} catch (err) {
			dispatch(
				setBannerMessage({
					message: `Unable to save pathway data`,
					isError: true,
					flash: true,
				}),
			);
			const parsed = getCalmErrorOrError(err);
			setError(parsed);
			throw err;
		} finally {
			setLoading(false);
		}
	};

	// Clear out any errors if a component is removed
	useEffect(() => {
		return () => {
			setError(undefined);
		};
	}, [setError]);

	return [savePathway, { data: result, loading, error }];
}

export function usePartnerPathways(partner_id: string): ApiResponse<SelectedPathway[]> {
	const apiRequest = useApi();
	const dispatch = useDispatch();

	const { data, error } = useSWR(`b2b/partners/${partner_id}/pathways`, async endpoint => {
		try {
			const response = await apiRequest({ endpoint });
			if (!response.data) {
				throw new Error('Not able to fetch pathway list');
			}
			return response.data;
		} catch (responseError) {
			dispatch(
				setBannerMessage({
					message: `Failed to retrieve pathway list`,
					isError: true,
					flash: true,
				}),
			);
			throw responseError;
		}
	});

	return { loading: !data && !error, data: data?.partner_packs, error };
}

export function usePartnerSinglePathway(
	pathway_id?: string,
	partner_id?: string,
): ApiResponse<SinglePathway> {
	const apiRequest = useApi();
	const dispatch = useDispatch();

	const { data, error } = useSWR(
		partner_id && pathway_id ? `b2b/partners/${partner_id}/pathways/${pathway_id}` : null,
		async endpoint => {
			try {
				const response = await apiRequest({ endpoint });
				if (!response.data) {
					throw new Error('Not able to fetch pathway list');
				}
				return response.data;
			} catch (responseError) {
				dispatch(
					setBannerMessage({
						message: `Failed to retrieve partner pathway`,
						isError: true,
						flash: true,
					}),
				);
				setTimeout(() => {
					redirect(`/${partner_id}/pathways`);
				}, 1000);
				throw responseError;
			}
		},
	);
	return { loading: !data && !error, data: data?.partner_pack, error };
}

export function useEditPartnerPathway(
	partner_id: string,
	id?: string,
	pack_class?: string,
	start_date?: Date | null,
	announcement_date?: Date | null,
	reminder_date?: Date | null,
	selectedCohorts?: SelectedCohorts | null,
): [SaveFunction, ApiResponse<SavedPathway>] {
	const apiRequest = useApi();
	const dispatch = useDispatch();
	const [result, setResult] = useState<SavedPathway | undefined>();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<CalmError | Error | undefined>();

	const editPathway = async (): Promise<void> => {
		// Parse date back to US timezone format and create a date string without timezone to send back to the db
		const { startDate, announceDate, remindDate } = parseDatesForDb(
			start_date,
			announcement_date,
			reminder_date,
		);

		const segments =
			selectedCohorts &&
			(selectedCohorts.segment_1_values ||
				selectedCohorts.segment_2_values ||
				selectedCohorts.segment_3_values)
				? selectedCohorts
				: null;

		setError(undefined);

		if (loading || !pack_class || !id) return;
		try {
			setLoading(true);

			const response = await apiRequest({
				endpoint: `b2b/partners/${partner_id}/pathways`,
				method: 'PATCH',
				body: {
					id,
					partner_id,
					pack_class,
					start_date: startDate,
					announce_date: announceDate,
					remind_date: remindDate,
					segments,
				},
			});

			const { data } = response;
			setResult(data);
			return data;
		} catch (err) {
			dispatch(
				setBannerMessage({
					message: `Unable to save pathway data`,
					isError: true,
					flash: true,
				}),
			);
			const parsed = getCalmErrorOrError(err);
			setError(parsed);
			throw err;
		} finally {
			setLoading(false);
		}
	};

	// Clear out any errors if a component is removed
	useEffect(() => {
		return () => {
			setError(undefined);
		};
	}, [setError]);

	return [editPathway, { data: result, loading, error }];
}

type DeleteFunction = () => Promise<void>;
export function useDeletePartnerPathway(
	partner_id: string,
	id?: string,
): [DeleteFunction, ApiResponse<object>] {
	const apiRequest = useApi();
	const dispatch = useDispatch();
	const [result, setResult] = useState<SavedPathway | undefined>();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<CalmError | Error | undefined>();

	const deletePathway = async (): Promise<void> => {
		setError(undefined);
		if (loading || !id) return;
		try {
			setLoading(true);

			const response = await apiRequest({
				endpoint: `b2b/partners/${partner_id}/pathways/${id}`,
				method: 'DELETE',
			});

			const { data } = response;
			setResult(data);
			return data;
		} catch (err) {
			dispatch(
				setBannerMessage({
					message: `Unable to delete pathway`,
					isError: true,
					flash: true,
				}),
			);
			const parsed = getCalmErrorOrError(err);
			setError(parsed);
			throw err;
		} finally {
			setLoading(false);
		}
	};

	// Clear out any errors if a component is removed
	useEffect(() => {
		return () => {
			setError(undefined);
		};
	}, [setError]);

	return [deletePathway, { data: result, loading, error }];
}

interface PathwaysCohortDetails {
	size: number;
}
export function usePathwaysCohortDetails({
	partnerId,
	segmentSelection,
}: {
	partnerId: string;
	segmentSelection?: SegmentSelectState;
}): ApiResponse<PathwaysCohortDetails> & RefetchOption {
	const apiRequest = useApi();
	const dispatch = useDispatch();
	const startDate = DateTime.local().minus({ weeks: 1 }).toJSDate();
	const endDate = DateTime.local().toJSDate();
	const [segmentFilter, swrString] = segmentsToJSON(segmentSelection, startDate, endDate);
	const endpoint = `b2b/partners/${partnerId}/pathways/cohort-details`;

	const { data, error, mutate } = useSWR<PathwaysCohortDetails>(
		`${endpoint}?${swrString}`,
		async endpoint => {
			try {
				const response = await apiRequest({ endpoint, method: 'POST', body: segmentFilter });
				if (!response.data) {
					throw new Error('Not able to fetch pathway cohort details');
				}
				return response.data;
			} catch (responseError) {
				calmLogger.error(
					'Failed to retrieve partner pathway cohort details',
					{ partnerId, segmentSelection: (segmentSelection ?? []) as JSONValue },
					responseError,
				);
				dispatch(
					setBannerMessage({
						message: `Error: Failed to retrieve partner pathway cohort details`,
						isError: true,
						flash: true,
					}),
				);
				throw responseError;
			}
		},
	);

	const refetch = (): Promise<PathwaysCohortDetails | undefined> => mutate(undefined, true);
	return { loading: !data && !error, data: data, error, refetch };
}
