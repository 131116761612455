import pluralize from 'pluralize';
import { ReactElement, forwardRef, Ref, useContext } from 'react';

import { Tooltip, Text, FontSizes } from '@calm-web/design-system';

import { ReportingContext } from '@/components/providers/ReportingContextProvider';

import LoadingAnimation from '../LoadingAnimation';
import ReportingPlaceholder from '../ReportingPlaceholder';
import WidgetCard from '../WidgetCard';
import {
	Wrapper,
	Category,
	Value,
	DefinitionWrapper,
	InfoIconContainer,
	ExplainerInfo,
	StatWrapper,
} from './styles';

interface Props {
	isPrivacyError: boolean;
	isNoDataError: boolean;
}

function Portraits({ isPrivacyError, isNoDataError }: Props, ref?: Ref<HTMLDivElement>): ReactElement {
	const { portraitsReport } = useContext(ReportingContext);
	const { session_stats, user_portraits } = portraitsReport?.data || {};
	const { power_users, casual_users } = user_portraits || {};
	const { total_sessions, mindful_days, mindful_minutes, average_session_length } = session_stats || {};
	const title = 'User Stats';

	if (portraitsReport?.loading) {
		return <LoadingAnimation />;
	}

	if (isPrivacyError || (!power_users && power_users !== 0) || (!casual_users && casual_users !== 0)) {
		return <ReportingPlaceholder title={title} graphType="privacyError" />;
	}
	if (isNoDataError) {
		return <ReportingPlaceholder title={title} graphType="getStarted" />;
	}

	const portraitsWrapper = (
		<>
			<Wrapper data-testid="session-stats-wrapper">
				<StatWrapper>
					<Category>Active users</Category>
					<Value>{(power_users + casual_users)?.toLocaleString()}</Value>
				</StatWrapper>
				<StatWrapper>
					<Category>Total sessions</Category>
					<Value>{total_sessions?.toLocaleString()}</Value>
				</StatWrapper>
				<StatWrapper>
					<Category>Average session</Category>
					<Value>
						{average_session_length?.toLocaleString(undefined, {
							minimumFractionDigits: 1,
							maximumFractionDigits: 1,
						})}{' '}
						{pluralize('min', Number(average_session_length?.toFixed(1)))}
					</Value>
				</StatWrapper>
				<StatWrapper>
					<Category>Mindful days</Category>
					<Value>
						{mindful_days?.toLocaleString()} {pluralize('day', mindful_days)}
					</Value>
				</StatWrapper>
				<StatWrapper>
					<Category>Mindful minutes</Category>
					<Value>
						{mindful_minutes?.toLocaleString()} {pluralize('min', mindful_days)}
					</Value>
				</StatWrapper>
			</Wrapper>
			<DefinitionWrapper>
				<Text size={FontSizes.sm} color="gray4">
					To ensure accurate categorization, users must have signed up for the product at least 30 days ago.
				</Text>
				<Tooltip
					placement="bottom"
					content="This timeframe allows for the formation of sustainable habits, as studies suggest it takes an average of 66 days to develop a habit."
					hideOnClick={false}
					maxWidth={210}
				>
					<InfoIconContainer data-html2canvas-ignore="true">
						<ExplainerInfo />
					</InfoIconContainer>
				</Tooltip>
			</DefinitionWrapper>
		</>
	);

	return (
		<WidgetCard
			ref={ref}
			isLoading={portraitsReport?.loading}
			heading={title}
			subHeading="Dive deeper into usage patterns and engagement levels of your organization."
			content={portraitsWrapper}
			removeDataContainer
			isPrivacyError={isPrivacyError}
			isNoDataError={isNoDataError}
		/>
	);
}

export default forwardRef(Portraits);
