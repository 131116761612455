import { useAuth0 } from '@auth0/auth0-react';
import querystring from 'query-string';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Loader } from '@calm-web/design-system';

import FullPageWrapper from '@/components/ui/FullPageWrapper';
import { AuthClientInterface, useAuth } from '@/hooks/auth';
import { useUser } from '@/hooks/store';
import { setBannerMessage, setLoading, setUser } from '@/store/actions';
import { User } from '@/types/store/reducers';
import { ILLEGAL_USER_CONFIGURATION, UNKNOWN_USER_ROLE } from '@/utils/RBAC';
import { ErrorWithMessage, isCalmError, isErrorWithMessage } from '@/utils/apiRequest/errors';
import { calmLogger } from '@/utils/calmLogger';

interface Props {
	children: ReactNode;
}

export default function AuthProvider({ children }: Props): ReturnType<FC> {
	const [hasInitializedApplicationData, setHasInitializedApplicationData] = useState(false);
	const history = useHistory();
	const { loading, user } = useUser();
	const { isLoading } = useAuth0();
	const [authClient] = useAuth();
	const dispatch = useDispatch();
	const { pathname } = useLocation();

	function handleInvalidUserErrors(err: ErrorWithMessage): void {
		if (err.message === ILLEGAL_USER_CONFIGURATION) {
			dispatch(
				setBannerMessage({
					message: 'Invalid user configuration. Please contact the system administrator to resolve.',
					isError: true,
					flash: false,
				}),
			);
		} else if (err.message === UNKNOWN_USER_ROLE) {
			dispatch(
				setBannerMessage({
					message:
						'User role not recognized for this application. Please contact the system administrator to resolve.',
					isError: true,
					flash: false,
				}),
			);
		}
	}

	async function getUser(_authClient: AuthClientInterface): Promise<User> {
		// eslint-disable-next-line no-async-promise-executor
		return new Promise(async (resolve, reject) => {
			try {
				const calmUser = await _authClient.getCalmUserFromState();
				if (!calmUser) {
					return resolve({});
				}
				resolve(calmUser);
			} catch (err) {
				try {
					await _authClient.signOut();
				} catch (e) {
					// Do nothing here
					calmLogger.error('Failed to sign out in AuthProvider getUser', {}, e);
				}
				if (isErrorWithMessage(err)) {
					handleInvalidUserErrors(err);
				}
				reject(new Error('failed to load user'));
			}
		});
	}

	async function initializeApplicationData(_authClient: AuthClientInterface): Promise<void> {
		try {
			const userDetails = await getUser(_authClient);
			await dispatch(setUser(userDetails));
			setHasInitializedApplicationData(true);
		} catch (err) {
			// TODO: Eventually handle this error gracefully
			calmLogger.error(
				(isErrorWithMessage(err) ? err.message : isCalmError(err) ? err.data.error.message : undefined) ??
					'Error in AuthProvider initializeApplicationData',
				{},
				err,
			);
		}
	}

	/**
	 * Initialize the user state
	 */
	useEffect(() => {
		if (loading && authClient && !isLoading) {
			initializeApplicationData(authClient).catch(err =>
				calmLogger.error('Error in AuthProvider initializeApplicationData catch', {}, err),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading, authClient, isLoading]);

	/**
	 * Check to see if we should redirect to /login after the inital load
	 */
	useEffect(() => {
		// Only check after we've async grabbed the access token from okta tokenManager
		if (loading && hasInitializedApplicationData) {
			// Check for lack of session token stored
			if (!user?.accessToken && !pathname.startsWith('/login')) {
				const redirectUrl = encodeURIComponent(pathname);
				const query = querystring.parse(window.location.search);
				query.returnTo = redirectUrl;
				history.push({
					pathname: '/login',
					search: `?${querystring.stringify(query)}`,
				});
			} else {
				dispatch(setLoading(false));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading, hasInitializedApplicationData, user]);

	return (
		<>
			{loading ? (
				<FullPageWrapper>
					<Loader color="gray1" />
				</FullPageWrapper>
			) : (
				children
			)}
		</>
	);
}
