import { ReactElement, SetStateAction } from 'react';
import { useIntl } from 'react-intl';

import { Loader, Tooltip } from '@calm-web/design-system';

import { CARD_IMAGES } from '@/components/ui/Stripe/CreditCardIcons';
import { useActivateAchPaymentMethodLifeCycleManagement } from '@/hooks/api/useActivateAchPaymentMethodLifeCycleManagement';
import { CalmError } from '@/utils/apiRequest/errors';

import { ButtonContainer, PlanDetail } from '../styles';
import { PaymentInfo, PAYMENT_TYPE } from '../types';
import messages from './messages';
import {
	PaymentDetailsContainer,
	DetailsContainer,
	Icon,
	BillingAddressContainer,
	ChangePaymentDetailsButton,
} from './styles';

interface Props {
	paymentInfo?: PaymentInfo;
	paymentError: CalmError | undefined;
	setShowChangeModal: (showChangeModal: SetStateAction<boolean>) => void;
	shouldHideChangePaymentDetailsButton?: boolean;
}

function PaymentMethodDetails({ paymentInfo }: { paymentInfo: PaymentInfo }): ReactElement | null {
	const { formatMessage } = useIntl();
	const address = paymentInfo.billing_details?.address;
	const billingDetails = address ? (
		<PaymentDetailsContainer>
			<BillingAddressContainer>
				<PlanDetail>{formatMessage(messages.billingAddress)}</PlanDetail>&nbsp;
			</BillingAddressContainer>
			<PlanDetail data-testid="billing-address-details" shouldHideMarginBottom>
				{formatMessage(messages.addressDetails, {
					linebreak: <br />,
					line1: address.line1,
					line2: address.line2,
					city: address.city,
					state: address.state,
					postal_code: address.postal_code,
				})}
			</PlanDetail>
		</PaymentDetailsContainer>
	) : null;

	if (paymentInfo.type === PAYMENT_TYPE.CARD) {
		const cardImageDetails = CARD_IMAGES[paymentInfo.brand as keyof typeof CARD_IMAGES];
		const brand = paymentInfo.card?.brand ?? paymentInfo.brand;
		const last4 = paymentInfo.card?.last4 ?? paymentInfo.last4;
		const expMonth = paymentInfo.card?.exp_month ?? paymentInfo.exp_month;
		const expYear = paymentInfo.card?.exp_year ?? paymentInfo.exp_year;
		return (
			<>
				<PlanDetail>
					{formatMessage(messages.cardName, {
						cardname: brand,
					})}
					{cardImageDetails && <Icon src={cardImageDetails.src} alt={cardImageDetails.alt} />}
				</PlanDetail>
				<PlanDetail>
					{formatMessage(messages.cardDetails, {
						cardnumber: last4,
					})}
				</PlanDetail>
				<PlanDetail>
					{formatMessage(messages.cardExp, {
						cardexp: `${expMonth}/${expYear}`,
					})}
				</PlanDetail>
				{billingDetails}
			</>
		);
	}

	if (paymentInfo.type === PAYMENT_TYPE.US_BANK_ACCOUNT) {
		return (
			<>
				<PlanDetail>
					{formatMessage(messages.bankAccount, {
						bankaccount: paymentInfo.us_bank_account?.last4,
					})}
				</PlanDetail>
				<PlanDetail>
					{formatMessage(messages.routingNumber, {
						routingnumber: paymentInfo.us_bank_account?.routing_number,
					})}
				</PlanDetail>
				{billingDetails}
			</>
		);
	}

	return null;
}

export default function PaymentDetails({
	paymentInfo,
	paymentError,
	setShowChangeModal,
	shouldHideChangePaymentDetailsButton,
}: Props): ReactElement {
	const { formatMessage } = useIntl();
	const {
		ui: {
			paymentDetails: { error },
		},
		isLoading,
		achModalKey,
	} = useActivateAchPaymentMethodLifeCycleManagement();

	if (isLoading) {
		return <Loader color="gray1" />;
	}

	return (
		<DetailsContainer data-testid="payment-details">
			{paymentError && <PlanDetail>Error: {paymentError.data.error.code}</PlanDetail>}
			{!paymentError && paymentInfo && <PaymentMethodDetails paymentInfo={paymentInfo} />}
			{error && <PlanDetail color="error">{error}</PlanDetail>}
			{shouldHideChangePaymentDetailsButton ? null : (
				<ButtonContainer>
					{achModalKey === 'PROCESSING' ? (
						<Tooltip placement="top" content={formatMessage(messages.achUpdatingPaymentDetailsTooltip)}>
							<ChangePaymentDetailsButton
								data-testid="change-payment-details-button"
								onPress={(): void => setShowChangeModal(true)}
								isDisabled
							>
								{formatMessage(messages.changeDetailsLink)}
							</ChangePaymentDetailsButton>
						</Tooltip>
					) : (
						<ChangePaymentDetailsButton
							data-testid="change-payment-details-button"
							onPress={(): void => setShowChangeModal(true)}
						>
							{formatMessage(messages.changeDetailsLink)}
						</ChangePaymentDetailsButton>
					)}
				</ButtonContainer>
			)}
		</DetailsContainer>
	);
}
