import { FC } from 'react';
import { useIntl } from 'react-intl';

import { FormInput } from '@calm-web/design-system';

import Subtitle from '@/components/ui/CellSubtitle';
import { usePermissions } from '@/hooks/auth';
import type { EditPartnerFormProps } from '@/hooks/forms/usePartnerForm';

import messages from '../messages';
import { GroupCodeLabel } from './styles';

interface Props {
	formProps: EditPartnerFormProps;
	isEdit: boolean;
	showExplanation: boolean;
}

const GroupCode: FC<Props> = ({ formProps, isEdit, showExplanation }) => {
	const { formatMessage } = useIntl();
	const [hasValidPermissions, actions] = usePermissions();
	const requiredPermissions = isEdit ? [actions.UPDATE] : [actions.CREATE];
	const canEdit = hasValidPermissions('group_code', requiredPermissions);
	const inputProps = formProps.bindWithErrorProps('groupCode', 'text');

	return (
		<>
			{showExplanation ? (
				<>
					<GroupCodeLabel id="group-codes-field-label">
						{formatMessage(messages.groupCodesLabel)}
					</GroupCodeLabel>
					<Subtitle>{formatMessage(messages.groupCodesSubtitle)}</Subtitle>
				</>
			) : null}
			<FormInput
				{...inputProps}
				{...(showExplanation
					? { 'aria-labelledby': 'group-codes-field-label' }
					: { 'aria-label': formatMessage(messages.groupCodesLabel) })}
				placeholder="Group code (20 characters max)"
				disabled={!canEdit}
				noMargin
			/>
		</>
	);
};

export default GroupCode;
