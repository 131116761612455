import { defineMessages } from 'react-intl';

export default defineMessages({
	areYouSure: {
		id: 'plan.terminateModal.areYouSure',
		defaultMessage: "Are you sure you want to terminate this partner's plan?",
		description: 'Title for the terminate plan modal',
	},
	confirmText: {
		id: 'plan.terminateModal.confirmText',
		defaultMessage:
			// eslint-disable-next-line max-len
			"This partner's current subscription cycle started on {cycleStartDate} for the amount of {originalAmount} and is {monthsIntoTheCycle} months into the cycle. If you choose to continue, {individualSubscriptionsQuantity} subscriptions will expire on {individualSubscriptionsExpirationDate}.",
		description: 'Confirmation text for the terminate plan modal',
	},
	errorAlreadyTerminated: {
		id: 'plan.terminateModal.errorAlreadyTerminated',
		defaultMessage: 'The latest charge has already been terminated.',
		description: 'Error message for when terminate plan fails since it was previously terminated.',
	},
	terminatePlan: {
		id: 'plan.terminateModal.terminatePlan',
		defaultMessage: 'OK, terminate partner plan now',
		description: 'Terminate plan confirm text for terminate plan modal button',
	},
	terminateError: {
		id: 'plan.terminateModal.terminateError',
		defaultMessage: "We're sorry, there was an error terminating the plan.",
		description: 'Error message for when terminate plan fails for unknown reasons',
	},
	terminatePreviewError: {
		id: 'plan.terminateModal.terminatePreviewError',
		defaultMessage: "We're sorry, there was an error loading the terminate preview for the plan",
		description: 'Error message for when terminate plan fails for unknown reasons',
	},
	hasTerminatedTitle: {
		id: 'plan.terminateModal.hasTerminatedTitle',
		defaultMessage: 'Terminate complete',
		description: 'Title confirming their plan is terminated',
	},
	hasTerminatedText: {
		id: 'plan.terminateModal.hasTerminatedText',
		defaultMessage:
			// eslint-disable-next-line max-len
			'The current subscription cycle started on {cycleStartDate} for the amount of {originalAmount} and is {monthsIntoTheCycle} months into the cycle. {individualSubscriptionsQuantity} active individual subscriptions on the team will expire on {individualSubscriptionsExpirationDate}.',
		description: 'Text confirming that their subscription has been terminated',
	},
	done: {
		id: 'plan.terminateModal.closeModal',
		defaultMessage: 'Done',
		description: 'Indicates the user is done, and the modal can be closed',
	},
	loading: {
		id: 'plan.terminateModal.loading',
		defaultMessage: 'Terminating plan…',
		description: 'Indicates the terminate request in progress',
	},
	loadingPreview: {
		id: 'plan.terminateModal.loadingPreview',
		defaultMessage: 'Loading terminate preview…',
		description: 'Indicates the terminate preview request is in progress',
	},
});
