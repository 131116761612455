import { defineMessages } from 'react-intl';

export default defineMessages({
	longNamePlaceholder: {
		id: 'createPartner.partnerDetails.longNamePlaceholder',
		defaultMessage: 'Partner Name',
		description: 'The name of the partner to be added',
	},
	logoUploadText: {
		id: 'createPartnerDetails.logoUpload',
		defaultMessage: "Drag 'n' drop partner logo here, or click to upload",
		description: 'A drag and drop container to upload an image',
	},
	logoDragText: {
		id: 'createPartnerDetails.logoDragUpload',
		defaultMessage: 'Drop partner logo here',
		description: 'Drag and drop a partner logo to a specific spot',
	},
	ssoSignupUrl: {
		id: 'createPartner.partnerDetails.ssoSignupUrl',
		defaultMessage: 'SSO Signup Link',
		description: "The link for the sso partner's signup",
	},
	subscribeUrl: {
		id: 'createPartner.partnerDetails.subscribeUrl',
		defaultMessage: 'Partner subscribe link',
		description: "The link for the partner's new subscriptions",
	},
	subscribeUrlPreview: {
		id: 'createPartner.partnerDetails.subscribeUrlPreview',
		defaultMessage: 'Partner subscribe link preview',
		description: "A preview for the link for the partner's new subscriptions",
	},
	subscribeUrlExplainer: {
		id: 'createPartner.partnerDetails.subscribeUrlExplainer',
		defaultMessage: 'This subscribe link will not be created until partner creation is complete.',
		description: "Describe the link for the partner's new subscriptions",
	},
});
