import { useMemo, useState } from 'react';
import SeededShuffle from 'seededshuffle';
import useSWR from 'swr';
import uuidv4 from 'uuid/v4';

import { useApi } from '@/hooks/api';
import { CalmError, getCalmErrorOrError } from '@/utils/apiRequest/errors';

import { ApiResponse } from './types';

export interface Feedback {
	id: string;
	partner_id: string;
	calm_user_id: string;
	response_text: string;
	pinned: boolean;
	created_at: string; // ISO 8601
	updated_at: string; // ISO 8601
}

export function useFeedback(
	partnerId: string,
	pinned: boolean | undefined,
	limit: number,
	offset = 0,
): ApiResponse<{ feedback: Feedback[]; totalCount: number }> & {
	clearCache: () => void;
} {
	const apiRequest = useApi();

	const pinnedFilter = typeof pinned === 'undefined' ? '' : `&filter[pinned]=${pinned}`;
	const { data, error, mutate } = useSWR(
		`b2b/partners/${partnerId}/feedback?limit=${limit}&offset=${offset}${pinnedFilter}`,
		async endpoint => {
			const response = await apiRequest({ endpoint });
			return response.data;
		},
		{ errorRetryCount: 2 },
	);

	return { data, error, loading: !data && !error, clearCache: mutate };
}

function useShuffledFeedback(
	raw: ApiResponse<{ feedback: Feedback[]; totalCount: number }>,
	randomizationSeed: string,
): ApiResponse<{ feedback: Feedback[]; totalCount: number }> {
	return useMemo(() => {
		if (!raw.data) {
			return raw;
		}
		return {
			...raw,
			data: {
				...raw.data,
				feedback: SeededShuffle.shuffle([...raw.data.feedback], randomizationSeed)
					.slice(0, 5)
					.sort((a, b) => b.updated_at?.localeCompare(a.updated_at) || b.id?.localeCompare(a.id)),
			},
		};
	}, [raw, randomizationSeed]);
}

export function useFeedbackHighlighted(
	partnerId: string,
): ApiResponse<{ feedback: Feedback[]; totalCount: number }> {
	const [randomizationSeed] = useState(uuidv4());
	const pinned = useShuffledFeedback(useFeedback(partnerId, true, 100), randomizationSeed);
	const unpinned = useShuffledFeedback(useFeedback(partnerId, false, 100), randomizationSeed);
	const combinedData = useMemo(() => {
		// The total count returned by the API is dependent on the pinned/unpinned filter,
		// so we merge the two counts here once both are loaded
		if (pinned.data && unpinned.data) {
			return {
				...pinned,
				data: {
					...pinned.data,
					totalCount: pinned.data.totalCount + unpinned.data.totalCount,
				},
			};
		}
		// If not both loaded, prefer the pinned data
		return pinned;
	}, [pinned, unpinned]);
	// Only use unpinned feedback if theyre both done loading, there's no pinned feedback, and there's unpinned data
	if (pinned.data?.feedback.length === 0 && unpinned.data) {
		return unpinned;
	}
	return combinedData;
}

export function useSaveFeedbackPins(
	partnerId: string,
): [
	(modifiedPinnedStatus: Record<string, Feedback | false | undefined>) => Promise<void>,
	ApiResponse<{ feedback: Feedback[] }>,
] {
	const apiRequest = useApi();
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState<{ feedback: Feedback[] }>();
	const [error, setError] = useState<CalmError | Error | undefined>();
	const saveFeedbackPins = async (
		modifiedPinnedStatus: Record<string, Feedback | false | undefined>,
	): Promise<void> => {
		setIsLoading(true);
		setError(undefined);
		try {
			const setPinned: string[] = [];
			const removePinned: string[] = [];
			Object.entries(modifiedPinnedStatus).forEach(([id, isPinned]) => {
				if (isPinned) {
					setPinned.push(id);
				} else if (isPinned === false) {
					removePinned.push(id);
				}
			});
			const response = await apiRequest({
				endpoint: `b2b/partners/${partnerId}/feedback`,
				method: 'PATCH',
				body: {
					set_pinned: setPinned,
					remove_pinned: removePinned,
				},
			});
			setData(response.data);
			return response.data;
		} catch (err) {
			setData(undefined);
			setError(getCalmErrorOrError(err));
			throw err;
		} finally {
			setIsLoading(false);
		}
	};
	return [saveFeedbackPins, { data, error, loading: isLoading }];
}
