import { defineMessages } from 'react-intl';

export default defineMessages({
	moodHeading: {
		id: 'reporting.moodCheckin.heading',
		defaultMessage: 'Mood Check-ins',
		description: 'Mood card heading',
	},
	placeholderTitle: {
		id: 'reporting.moodCheckin.noData.title',
		defaultMessage: 'You’ll see more as your members complete mood check-ins.',
		description:
			'Placeholder text indication the user does not have enough data to show a graph on user mood check-ins (a survey asking about mood)',
	},
	placeholderSubtitle: {
		id: 'reporting.moodCheckin.noData.subtitle',
		defaultMessage: 'You may also adjust the time period on this page to see more data.',
		description:
			'Placeholder text indicating the user does not have enough data to show a graph on user mood check-ins (a survey asking about mood)',
	},
});
