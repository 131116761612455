import styled from 'styled-components';
import { palette } from 'styled-tools';

import { units } from '@calm-web/design-system';

import SmileyFaceSvg from 'icons/smiley-face.svg';

export const Row = styled.div`
	display: flex;
	align-items: center;
	gap: ${units(1.5)};
`;

export const SmileyFaceIcon = styled(SmileyFaceSvg)`
	height: ${units(6)};
	width: ${units(6)};
	margin-bottom: ${units(1)};
`;

export const ActionItemsGroup = styled.div`
	border: 1px solid ${palette('blackAlpha5')};
	border-radius: ${units(1)};
	background-color: ${palette('white')};
`;
