import { rgba } from 'polished';
import styled from 'styled-components';
import { palette } from 'styled-tools';

import {
	fontWeight,
	FontWeights,
	fontSize,
	FontSizes,
	CalmTheme,
	lineHeight,
	Text,
	TextButton,
	TextArea,
	maxWidth,
	minWidth,
	units,
} from '@calm-web/design-system';

import CheckSvg from 'icons/check.svg';
import ClockSvg from 'icons/clock.svg';

import { SampleContentCardsContainer } from '../../SampleContentCards/styles';

const PROGRAM_LIST_CONTAINER_WIDTH = 40;
const SELECT_PROGRAM_COMPONENT_MAX_HEIGHT = '1000px';

export const CreateWrapper = styled.div`
	display: flex;
	align-items: flex-start;
`;

export const ProgramListContainer = styled.div`
	width: ${PROGRAM_LIST_CONTAINER_WIDTH}%;
	padding-right: ${units(3)};
	max-height: ${SELECT_PROGRAM_COMPONENT_MAX_HEIGHT};
	overflow-y: scroll;

	${minWidth('tablet') && maxWidth('desktop')} {
		width: 100%;
		padding-right: unset;
		max-height: unset;
		height: 50rem;
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
		gap: ${units(3)};
	}
`;

export const CenterContentContainer = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const Program = styled.div<{ active: boolean | undefined }>`
	display: grid;
	grid-template-columns: 100px 1fr;
	align-items: center;
	background-color: ${p => (p.active ? rgba(CalmTheme.palette.blue3, 0.1) : palette('white'))};
	padding: ${units(2)};
	border-radius: ${units(2)};
	margin-bottom: ${units(2)};

	&:hover {
		cursor: pointer;
	}

	${maxWidth('desktopLarge')} {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin-bottom: 0;
	}
`;

export const ProgramImage = styled.div`
	span {
		border-radius: ${units(2)};
	}
	margin-right: ${units(2)};
`;

export const ProgramContent = styled.div``;

export const ProgramTitle = styled.div`
	${fontWeight(FontWeights.Medium)};
	margin-bottom: ${units(1)};
	${fontSize(FontSizes.base)}
	${lineHeight(FontSizes.base)}
`;

export const ProgramDescription = styled.div`
	${fontSize(FontSizes.base)}
	${lineHeight(FontSizes.base)}
`;

export const MoreToCome = styled.div`
	display: grid;
	grid-template-columns: 100px 1fr;
	align-items: center;
	border: 1px solid ${palette('gray1')};
	border-radius: ${units(2)};
	padding: ${units(4, 2, 2, 3)};
`;

export const ClockIcon = styled(ClockSvg)`
	height: ${units(10)};
	width: ${units(10)};
	margin-bottom: ${units(2)};
`;

export const LinkToModal = styled(TextButton)``;

export const SuggestionTextBox = styled(TextArea)`
	min-height: ${units(30)};
	margin-top: ${units(2)};
`;

export const CheckIcon = styled(CheckSvg)`
	width: ${units(10)};
	height: ${units(10)};
	path {
		fill: ${palette('buttonGreen')};
	}
`;

export const ThanksContainer = styled.div`
	text-align: center;
`;

export const ThanksText1 = styled.div`
	${fontSize(FontSizes.lg)};
	${fontWeight(FontWeights.Medium)};
	margin: ${units(2, 0, 1, 0)};
`;

export const ThanksText2 = styled.div``;

export const PlaceholderContainer = styled.div`
	max-width: 333px;
	text-align: center;
`;

export const PlaceholderTitle = styled.div`
	${fontWeight(FontWeights.Medium)};
	${fontSize(FontSizes.base)}
	${lineHeight(FontSizes.base)}
`;

export const PlaceholderContent = styled.div`
	${fontSize(FontSizes.base)}
	${lineHeight(FontSizes.base)}
`;

export const ProgramDetailContainer = styled.div`
	width: ${100 - PROGRAM_LIST_CONTAINER_WIDTH}%;
	border: 1px solid ${palette('gray2')};
	border-radius: ${units(2)};
	padding: ${units(3)};
	max-height: ${SELECT_PROGRAM_COMPONENT_MAX_HEIGHT};
	overflow-y: scroll;

	${maxWidth('desktopLarge')} {
		width: 100%;
	}
`;

export const BannerImage = styled.div`
	max-width: 800px;
`;

export const SampleProgramTitle = styled(Text).attrs({
	el: 'div',
	size: FontSizes['2xl'],
	weight: FontWeights.Medium,
})`
	margin-bottom: ${units(2)};
`;

export const SampleProgramDescription = styled.div`
	margin-bottom: ${units(2)};
	${fontSize(FontSizes.base)}
	${lineHeight(FontSizes.base)}
`;

export const SampleContent = styled.div`
	margin: ${units(0, -3, 2)};
	max-width: calc(100% + ${units(6)});
	overflow-x: hidden;

	${SampleContentCardsContainer} {
		padding: ${units(0, 3, 1, 3)};
		overflow-x: scroll;
		overflow-y: hidden;
	}
`;

export const SampleContentHeader = styled(Text).attrs({
	el: 'div',
	size: FontSizes.base,
	weight: FontWeights.Medium,
})`
	padding-left: ${units(3)};
`;

export const SampleContentItemContainer = styled.div`
	display: grid;
	grid-template-columns: 160px 160px 160px 160px;
	grid-gap: ${units(2)};
`;

export const SampleContentItem = styled.div`
	margin-top: ${units(2)};
	border: 1px solid gray;
	width: 160px;
	height: 160px;
`;

export const SampleResources = styled(Text).attrs({
	el: 'div',
	size: FontSizes.base,
	weight: FontWeights.Medium,
})``;

export const ImagePlaceholder = styled.div`
	border: 1px solid black;
	border-radius: ${units(2)};
	height: 84px;
	width: 84px;
`;
