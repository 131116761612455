import { useOption, useListBox } from '@react-aria/listbox';
import { useRef } from 'react';

import { List, ListItem } from './styles';
import { ListBoxProps, OptionProps } from './types';

const Option: React.FC<OptionProps> = ({ item, state }) => {
	const ref = useRef(null);
	const { optionProps, isSelected, isFocused, isDisabled } = useOption({ key: item.key }, state, ref);

	return (
		<ListItem
			isDisabled={isDisabled}
			isSelected={isSelected}
			isFocused={isFocused}
			{...optionProps}
			ref={ref}
		>
			{item.rendered}
		</ListItem>
	);
};

const ListBox: React.FC<ListBoxProps> = props => {
	const ref = useRef(null);
	const { state } = props;
	const { listBoxProps } = useListBox(props, state, ref);

	return (
		<List {...listBoxProps} ref={ref}>
			{[...state.collection].map(item => (
				<Option key={item.key} item={item} state={state} />
			))}
		</List>
	);
};

export default ListBox;
