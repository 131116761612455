import styled from 'styled-components';
import { palette, theme } from 'styled-tools';

import { FormInput, maxWidth, units } from '@calm-web/design-system';

import CircleX from 'icons/circle-x.svg';

export const Heading = styled.h6`
	width: 100%;
	display: flex;
	color: ${palette('black')};
	margin: ${units(1, 0)};
`;

export const TwoColumnWrapper = styled.div`
	display: flex;
	width: 100%;
	flex-wrap: nowrap;
	align-items: center;

	${maxWidth('desktop')} {
		flex-wrap: wrap;
	}
`;

export const DateColumn = styled.div`
	display: flex;
	flex: 50%;
	flex-wrap: wrap;
	align-content: space-between;
	align-self: stretch;
	box-sizing: border-box;

	&:not(:last-child) {
		padding-right: ${units(2)};
	}
`;

// TODO: import this from design-system
export const InvalidIcon = styled(CircleX)`
	left: unset !important;
	right: 10px !important;
	path {
		fill: ${palette('errorRed')};
	}
`;

export const FormInputStyled = styled(FormInput)`
	max-width: ${theme('card.innerMaxWidth')};
	margin-right: ${units(1)};
	margin-bottom: 0;
`;
