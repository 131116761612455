import { FC } from 'react';
import { useIntl } from 'react-intl';
import { ThemeProvider } from 'styled-components';

import {
	Modal,
	Text,
	Button,
	PrimaryButton,
	FontWeights,
	FontSizes,
	CalmTheme,
	Collapsible,
} from '@calm-web/design-system';

import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import useEligibilityReminder from '@/hooks/api/useEligibilityReminder';
import { calmLogger } from '@/utils/calmLogger';

import messages from './messages';
import { Container, ExampleEmailContainer, ButtonContainer, Centered, ButtonGroup } from './styles';

interface Props {
	onClose: () => void;
	isOpen: boolean;
	partnerId: string;
	partnerName: string;
	nextEmailDate?: string;
	prevEmailDate?: string;
}

const ExampleSignupEmail: FC<{ partnerName: string }> = ({ partnerName }) => {
	const { formatMessage } = useIntl();

	return (
		<ExampleEmailContainer>
			<Text el="h5" noMargin weight={FontWeights.Medium} color="black">
				{formatMessage(messages.hello)}
			</Text>
			<br />
			{formatMessage(messages.intro, { partnerName })}
			<br />
			<br />
			{formatMessage(messages.description)}
			<br />
			<br />
			<ButtonContainer>
				<ThemeProvider theme={CalmTheme}>
					<Button size="lg" backgroundColor="gradientCalmBrand">
						{formatMessage(messages.getStarted)}
					</Button>
				</ThemeProvider>
			</ButtonContainer>
			<br />
			<Text el="h5" noMargin weight={FontWeights.Medium} align="center" color="black">
				{formatMessage(messages.startExploring)}
			</Text>
			<br />
			<Centered>{formatMessage(messages.kickstart)}</Centered>
			<br />
			...
		</ExampleEmailContainer>
	);
};

const EmailReminderModal: FC<Props> = ({ isOpen, onClose, partnerId, partnerName }) => {
	const { formatMessage } = useIntl();
	const { logEvent } = useAnalytics();
	const [sendReminders, { loading }] = useEligibilityReminder();

	const handleSendReminder = async (): Promise<void> => {
		logEvent('Partner Portal : Users : Manual reminder email sent');
		try {
			await sendReminders(partnerId);
		} catch (error) {
			calmLogger.error('Error in EmailReminderModal sendReminders', {}, error);
		} finally {
			onClose();
		}
	};

	return (
		<Modal isOpen={isOpen} closeModal={onClose} aria-label="Send sign up reminder" canClose>
			<Container>
				<Text el="p">{formatMessage(messages.confirmationCopy)}</Text>
				<Collapsible
					trigger={
						<Text el="p" size={FontSizes.sm} color="blue3" weight={FontWeights.Medium}>
							{formatMessage(messages.exampleOfEmail)}
						</Text>
					}
				>
					<ExampleSignupEmail partnerName={partnerName} />
				</Collapsible>
			</Container>
			<ButtonGroup>
				<Button textColor="blue3" backgroundColor="transparent" hideShadow onPress={onClose}>
					{formatMessage(messages.cancel)}
				</Button>
				<PrimaryButton isDisabled={loading} onPress={handleSendReminder}>
					{formatMessage(messages.sendReminderCta)}
				</PrimaryButton>
			</ButtonGroup>
		</Modal>
	);
};

export default EmailReminderModal;
