import { useCallback, useEffect, useState } from 'react';

import { fileFromModelValue } from '@calm-web/use-form';

import type { EditPartnerFormProps } from '@/hooks/forms/usePartnerForm';
import { createUrl } from '@/utils/url';

export interface GetDtcImageResponse {
	dtcImageFile?: File;
	newDtcImageUrl?: string;
	defaultImageUrl: string;
}
export interface UseDtcImageResponse {
	getDtcImage: () => GetDtcImageResponse;
	clearImage: () => void;
}

const blankHeroImgUrl = '/_n/images/for-work-hero-blank.png';
const calmHeroImageUrl = '/_n/images/for-work-hero-calm.png';

const calculateAspectRatioFit = (
	srcWidth: number,
	srcHeight: number,
	maxWidth: number,
	maxHeight: number,
): { width: number; height: number } => {
	const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
	return { width: srcWidth * ratio, height: srcHeight * ratio };
};

const drawCanvasImage = (
	setDtcFile: (file: File) => void,
	imageEl: HTMLImageElement,
	blankHeroImgEl?: HTMLImageElement,
): void => {
	if (!imageEl || !blankHeroImgEl) return;
	const canvas = document.createElement('canvas');
	canvas.width = 792;
	canvas.height = 792;
	const ctx = canvas.getContext('2d');

	if (ctx) {
		ctx.drawImage(blankHeroImgEl, 0, 0);
		const { width, height } = calculateAspectRatioFit(imageEl.width, imageEl.height, 275, 275);
		const xPos = (canvas.width - width) / 2;
		const yPos = (canvas.height - height) / 2 + 60;
		// Overlay the image on top
		ctx.drawImage(imageEl, xPos, yPos, width, height);

		canvas.toBlob(blob => {
			if (blob) {
				const d2cImgFile = new File([blob], `d2c_logo.jpeg`, { type: 'image/jpeg' });
				setDtcFile(d2cImgFile);
			}
		}, 'image/jpeg');
	}
};

export function useDtcImage(formProps: EditPartnerFormProps): UseDtcImageResponse {
	const [dtcFile, setDtcFile] = useState<File | undefined>();
	const logoImgFile = fileFromModelValue(formProps.model.uploadedLogo);

	const setDtcImage = useCallback((): void => {
		const uploadedLogoUrl = createUrl(logoImgFile);
		const backgroundImg = new window.Image(792, 792);
		backgroundImg.src = blankHeroImgUrl;
		const baseImage = new window.Image();
		baseImage.src = uploadedLogoUrl;
		backgroundImg.onload = () => drawCanvasImage(setDtcFile, baseImage, backgroundImg);
		baseImage.onload = () => drawCanvasImage(setDtcFile, baseImage, backgroundImg);
		if (process.env.NODE_ENV === 'test') {
			drawCanvasImage(setDtcFile, baseImage, backgroundImg);
		}
	}, [logoImgFile]);

	useEffect(() => {
		if (logoImgFile) {
			setDtcImage();
		}
	}, [logoImgFile, setDtcImage]);

	const getDtcImage = useCallback((): GetDtcImageResponse => {
		const dtcFileToUse = dtcFile;
		const dtcHeroUrl = dtcFileToUse ? createUrl(dtcFileToUse) : '';
		return {
			dtcImageFile: dtcFileToUse,
			newDtcImageUrl: dtcHeroUrl,
			defaultImageUrl: calmHeroImageUrl,
		};
	}, [dtcFile]);

	const clearImage = useCallback((): void => {
		setDtcFile(undefined);
	}, []);

	return { getDtcImage, clearImage };
}
