import { ReactElement, useState } from 'react';
import { useIntl } from 'react-intl';

import { Card, Checkbox, Button, Modal } from '@calm-web/design-system';
import { booleanFromCheckbox } from '@calm-web/use-form';

import CellTitle from '@/components/ui/CellTitle';
import type { EditPartnerFormProps } from '@/hooks/forms/usePartnerForm';
import { Partner } from '@/types/store/reducers';

import messages from './messages';
import { CancelButton, Note, WarningMessage } from './styles';

function DependentsDetails({
	formProps: {
		setProperty,
		model: { dependentsEnabled },
	},
	partner,
}: {
	formProps: EditPartnerFormProps;
	partner?: Partner;
}): ReactElement {
	const { formatMessage } = useIntl();
	const [showModal, setShowModal] = useState(false);

	// Form Checkboxes use arrays as the underlying data structure for storing the "value" associated
	// with the checkbox. As a result, omitting the "value" from the checkbox is how we denote that
	// the box is "unchecked" while including the "value" in the array means it's "checked"
	const originalFormValue = dependentsEnabled ?? [];
	const ENABLE_DEPENDENTS = ['dependentsEnabled'];
	const DISABLE_DEPENDENTS: string[] = [];

	const onModalDismiss = (): void => {
		setProperty('dependentsEnabled', originalFormValue);
		setShowModal(false);
	};

	const onModalConfirm = (): void => {
		setProperty('dependentsEnabled', DISABLE_DEPENDENTS);
		setShowModal(false);
	};

	const handleChange = (): void => {
		const wasEnabled = booleanFromCheckbox(dependentsEnabled, 'dependentsEnabled');
		// Denotes the "value" of the checkbox based on whether we're unchecking / checking the box
		const newVal = !wasEnabled ? ENABLE_DEPENDENTS : DISABLE_DEPENDENTS;

		const alreadyHasDependents = (partner?.max_dependents_per_user ?? 0) > 0;
		const isDisabling = wasEnabled && alreadyHasDependents;
		if (isDisabling) {
			setShowModal(true);
			return;
		}

		setProperty('dependentsEnabled', newVal);
	};
	// Eventually, we'll convert this into a general input field, but for
	// now, we're only allowing 0 (disabled) or 5 (enabled) dependents for all partners
	return (
		<Card>
			<CellTitle showAdminFlag>{formatMessage(messages.title)}</CellTitle>
			<Checkbox
				name="dependentsEnabled"
				value="dependentsEnabled"
				checked={booleanFromCheckbox(dependentsEnabled, 'dependentsEnabled')}
				id="dependentsEnabled"
				onChange={handleChange}
			>
				{formatMessage(messages.subtitle)}
			</Checkbox>
			<Modal
				isOpen={showModal}
				closeModal={onModalDismiss}
				title={formatMessage(messages.confirmUpdateTitle)}
				footer={
					<>
						<CancelButton hideUnderline type="button" onClick={onModalDismiss}>
							{formatMessage(messages.cancel)}
						</CancelButton>
						<Button backgroundColor="errorRed" onPress={onModalConfirm} type="button">
							{formatMessage(messages.confirm)}
						</Button>
					</>
				}
			>
				<>
					<WarningMessage>{formatMessage(messages.dependentDecreaseWarning)}</WarningMessage>
					<Note>{formatMessage(messages.noteAboutSaving)}</Note>
				</>
			</Modal>
		</Card>
	);
}

export default DependentsDetails;
