import styled from 'styled-components';

import { Card, units } from '@calm-web/design-system';

export const ContainerCard = styled(Card)`
	position: relative;
`;

export const Anchor = styled.div`
	position: absolute;
	top: -${units(12)};
	visibility: hidden;
`;
