interface QueryParams {
	[key: string]: string;
}

/* eslint-disable no-undef */
function constructMapping(baseUrl: string, queryParamMapping: QueryParams, params: Array<string>): void {
	params.forEach(param => {
		const paramKeyValue = param.split('=');
		if (paramKeyValue.length > 1 && typeof paramKeyValue[1] === 'string') {
			if (paramKeyValue[1].replace('%2F', '') !== baseUrl.replace('/', '')) {
				const [paramKey, paramValue] = paramKeyValue;
				// TODO: BM 7/28/20 Fix this - param reassign is gross
				// eslint-disable-next-line no-param-reassign
				queryParamMapping[paramKey] = paramValue;
			}
		}
	});
}

export const getRedirectUrl = (newUrl: string): string => {
	if (typeof window === 'undefined') {
		return newUrl;
	}

	const queryParams = ((): Array<string> => {
		if (!window.location.search) {
			return [];
		}
		const currentQueryParams = window.location.search.slice(1);
		return currentQueryParams.split('&').filter(param => {
			if (typeof param === 'string' && param.split('=').length <= 1) {
				return false;
			}
			return true;
		});
	})();

	const splitUrl = newUrl.split('?');

	const redirectUrl = ((): string => {
		return splitUrl[0];
	})();

	const newQueryParams = ((): Array<string> => {
		if (splitUrl.length <= 1) {
			return [];
		}

		return splitUrl[1].split('&');
	})();

	// Take only the latest duplicate query param from old/new params
	const queryParamMapping: QueryParams = {};
	if (queryParams) {
		constructMapping(redirectUrl, queryParamMapping, queryParams);
	}
	constructMapping(redirectUrl, queryParamMapping, newQueryParams);

	// Flatten out the object back into the array
	const flattenedQueryParams = Object.keys(queryParamMapping).map(param => {
		return `${param}=${queryParamMapping[param]}`;
	});

	if (queryParams.length + flattenedQueryParams.length < 1) {
		return redirectUrl;
	}

	const redirectUrlWithParams = ((): string => {
		const paramPart = flattenedQueryParams.join('&');
		return `${redirectUrl}?${paramPart}`;
	})();

	return redirectUrlWithParams;
};

const redirect = (newUrl: string): void => {
	const redirectUrl = getRedirectUrl(newUrl);
	if (window) {
		window.location.href = redirectUrl;
	}
};

export default redirect;
