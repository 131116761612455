import styled from 'styled-components';
import { palette } from 'styled-tools';

import { units } from '@calm-web/design-system';

export const List = styled.ul`
	background-color: ${palette('gray5')};
	border-radius: 6px;
	margin: 0;
	padding: 0;
	list-style: none;
	overflow: auto;
`;

export const ListItem = styled.li<{ isDisabled: boolean; isFocused: boolean; isSelected: boolean }>`
	color: ${p => (p.isDisabled ? palette('gray7') : 'white')};
	padding: ${units(3 / 4, 3 / 2)};
	font-weight: 500;
	font-size: ${units(2)};
	line-height: ${units(3)};
	outline: none;
	cursor: ${p => (p.isDisabled ? 'not-allowed' : 'pointer')};
	background-color: ${p => (p.isSelected ? palette('gray4') : '')};
	background-color: ${p => (p.isFocused ? palette('gray3') : '')};
`;
