import { AxiosResponse } from 'axios';
import useSWR from 'swr';

import { useApi } from '@/hooks/api';
import { iso8601Date } from '@/utils/helpers';

import { ApiResponse, RefetchOption } from '../types';
import { ReportingAggregationLevel } from './utils';

export interface SegmentInfo {
	segment_value: string;
	segment_name: string;
	percent_signups: number;
	total_signups: number;
	total_eligible_users: number;
}

export interface ReportSegmentRankings {
	top: SegmentInfo[];
	bottom: SegmentInfo[];
	total_segments: number;
}

export interface ReportSegmentRankingsModular {
	partner_id: string;
	start_date?: string;
	end_date?: string;
	aggregation_level: ReportingAggregationLevel;
	updated_at?: string;
	is_mocked?: boolean;
	segment_ranking?: ReportSegmentRankings;
}

export function useSegmentRankings({
	partnerId,
	segmentNames,
	endDate,
}: {
	partnerId: string;
	endDate?: Date;
	segmentNames?: string[];
}): ApiResponse<ReportSegmentRankingsModular> & RefetchOption {
	const apiRequest = useApi();
	const _endDate = endDate ? iso8601Date(endDate) : '';
	const segmentNamesFilter = segmentNames ? { segment_names: { in: segmentNames } } : null;
	const segmentFilter = { filter: { ...segmentNamesFilter, end_date: _endDate } };
	const swrString = segmentNames?.toString();

	const endpoint = `b2b/partners/${partnerId}/reporting/signup-segment-ranking`;

	const { data, error, mutate } = useSWR<AxiosResponse | undefined>(
		`${endpoint}${partnerId}${endDate}${swrString}`,
		async () => {
			if (!endDate) {
				return undefined;
			}
			const response = await apiRequest({ endpoint, method: 'POST', body: segmentFilter });
			return response;
		},
		{ errorRetryCount: 0 },
	);
	if (!endDate) {
		return {
			data: undefined,
			error,
			loading: true,
			refetch: () => {},
		};
	}
	const apiReport = data?.data.report as ReportSegmentRankingsModular | undefined;

	const refetch = (): Promise<AxiosResponse | undefined> => mutate(undefined, true);
	return {
		data: apiReport,
		error,
		loading: !data && !error,
		refetch,
	};
}
