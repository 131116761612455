import { defineMessages } from 'react-intl';

export default defineMessages({
	planDetails: {
		id: 'plan.planModal.planDetails',
		defaultMessage: 'Plan details',
		description: 'Label for Plan details section for the reactivation modal',
	},
	paymentDetails: {
		id: 'plan.planModal.paymentDetails',
		defaultMessage: 'Payment details',
		description: 'Label for Payment details section for the reactivation modal',
	},
	coveredLives: {
		id: 'plan.planModal.coveredLives',
		defaultMessage: 'Number of covered lives:',
		description: 'Denotes the total number of covered lives',
	},
	costPerLife: {
		id: 'plan.planModal.costPerLife',
		defaultMessage: 'Annual/covered life:',
		description: 'Denotes the total cost per covered life',
	},
	total: {
		id: 'plan.planModal.total',
		defaultMessage: 'Total: ',
		description: 'Denotes the total cost for reactivation',
	},
	reactivateButton: {
		id: 'plan.planModal.reactivateButton',
		defaultMessage: 'Reactivate',
		description: 'Button text to reactivate subscription.',
	},
	invalidPaymentError: {
		id: 'plan.planModal.stripeInvalidRequestError',
		defaultMessage:
			"We're sorry, there was an error processing your payment information. Please re-enter your Payment Information.",
		description: 'Error message for when Stripe Payment fails for unknown reasons',
	},
	planReactivationError: {
		id: 'plan.planModal.planReactivationError',
		defaultMessage: "We're sorry, there was an error reactivating your plan.",
		description: 'Error message for when plan reactivation fails for unknown reasons',
	},
});
