import styled from 'styled-components';

import { FormInput, Button, units } from '@calm-web/design-system';

export const Container = styled.div``;

export const TestEmailSendForm = styled.form`
	display: flex;
	margin-top: ${units(2)};

	div {
		margin: 0;
	}
`;

export const TestEmailSendFormButton = styled(Button)`
	white-space: nowrap;
	height: ${units(2)};
	min-width: unset;
	margin-left: ${units(2)};
	width: 128px;
	margin-top: 4px;
`;

export const TestEmailSendFormInput = styled(FormInput)`
	width: 320px;
`;
