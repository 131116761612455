import { ReactElement } from 'react';

import { OnChange } from '@calm-web/use-form';

import { DarkInput } from './styles';

const MIN_COVERED_LIVES = 5;

interface Props {
	coveredLives: number;
	onChange: (amount: number) => void;
	isCalculated: boolean;
	shouldShowPriceTierUpdates?: boolean;
}

export function CoveredLivesPicker({
	coveredLives,
	onChange,
	isCalculated,
	shouldShowPriceTierUpdates,
}: Props): ReactElement {
	const nameInForm = 'covered-lives';

	const handleChange: OnChange = e => {
		const target = e.target as HTMLInputElement;
		const numberOfLives = Number(target.value);
		onChange(numberOfLives);
	};

	return (
		<DarkInput
			data-testid="covered-lives-picker"
			name={nameInForm}
			label={`Covered lives (5 - ${shouldShowPriceTierUpdates ? '500' : '100'})`}
			value={coveredLives.toString()}
			onChange={handleChange}
			type="number"
			disabled={isCalculated}
			min={`${MIN_COVERED_LIVES}`}
			isValid={coveredLives >= MIN_COVERED_LIVES}
		/>
	);
}
