export function downloadPartnerDataViaATag(data: string, partnerId: string): void {
	const encoded = encodeURIComponent(data);
	const element = document.createElement('a');
	element.setAttribute('href', `data:text/plain;charset=utf-8,${encoded}`);
	element.setAttribute('download', `${partnerId}.csv`);
	element.style.display = 'none';
	document.body.appendChild(element);
	element.click();
	document.body.removeChild(element);
}
