import { defineMessages } from 'react-intl';

export default defineMessages({
	titleHeader: {
		id: 'pathways.table.titleHeader',
		defaultMessage: 'Program Title',
		description: 'The title column of a table',
	},
	audienceHeader: {
		id: 'pathways.table.usersHeader',
		defaultMessage: 'Audience',
		description: 'The users column of a table',
	},
	signupAnnouncementHeader: {
		id: 'pathways.table.signupAnnouncement',
		defaultMessage: 'Signup Announcement',
		description: 'The title column of a table',
	},
	durationHeader: {
		id: 'pathways.table.durationHeader',
		defaultMessage: 'Pathway Duration',
		description: 'The time period column of a table',
	},
	placeholderHeading: {
		id: 'pathways.placeholder.heading',
		defaultMessage: 'You dont have any active Pathways',
		description: 'Pathway placeholder heading text',
	},
	placeholderDescription: {
		id: 'pathways.placeholder.description',
		defaultMessage: "Once you create your first Pathway, you'll see it here.",
		description: 'Pathway placeholder description text',
	},
	editPathwayAria: {
		id: 'pathways.table.editIconLabel',
		defaultMessage: 'Edit Pathway',
		description:
			'A label for an icon to edit the current program that a user is viewing - program and pathway are synonymous',
	},
	reportingPathwayAria: {
		id: 'pathways.table.reportingPathwayLabel',
		defaultMessage: 'View pathway reporting data',
		description:
			'A label for an icon to view the current pathway reporting - program and pathway are synonymous',
	},
	viewPathwayAria: {
		id: 'pathways.table.viewIconLabel',
		defaultMessage: 'View Pathway',
		description:
			'A label for an icon to view the current Pathway when the Annoucne date has passed and the pathway cannot be updated',
	},
	pathwayEditTooltip: {
		id: 'pathways.table.tooltip.edit',
		defaultMessage: 'The Pathway has yet to launch and details are editable',
		description: 'Tooltip text for edit button',
	},
	pathwayViewTooltip: {
		id: 'pathways.table.tooltip.view',
		defaultMessage: 'The Pathway is launched and details are no longer editable',
		description: 'Tooltip text for view button',
	},
});
