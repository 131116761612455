/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

export default defineMessages({
	lastUploadHeader: {
		id: 'users.header.lastUpload',
		defaultMessage: 'Last upload {date}',
		description: 'shows the date of the last uploaded Eligibility File',
	},
	lastSyncHeader: {
		id: 'users.header.lastUpload',
		defaultMessage: 'Last sync {date}',
		description: 'shows the date of the last users sync event.',
	},
	noPreviousSyncHeader: {
		id: 'users.header.lastUpload',
		defaultMessage: 'No previous sync events have been recorded.',
		description: 'shows the date of the last users sync event.',
	},
	lastUploadUsersAdded: {
		id: 'users.header.lastUpload.usersAdded',
		defaultMessage: '<b>{usersAdded}</b> users added',
		description: 'shows the number of users added in the last uploaded Eligibility File',
	},
	lastUploadUsersDeleted: {
		id: 'users.header.lastUpload.usersDeleted',
		defaultMessage: '<b>{usersDeleted}</b> users deleted',
		description: 'shows the number of users deleted in the last uploaded Eligibility File',
	},
	lastUploadSegmentsAdded: {
		id: 'users.header.lastUpload.segmentsAdded',
		defaultMessage: '<b>{segmentsAdded}</b> segments added',
		description: 'shows the number of segments added in the last uploaded Eligibility File',
	},
	lastUploadSegmentsDeleted: {
		id: 'users.header.lastUpload.segmentsDeleted',
		defaultMessage: '<b>{segmentsDeleted}</b> segments deleted',
		description: 'shows the number of segments deleted in the last uploaded Eligibility File',
	},
	lastUploadSegmentsUpdated: {
		id: 'users.header.lastUpload.segmentsUpdated',
		defaultMessage: '<b>{segmentsUpdated}</b> segments updated',
		description: 'shows the number of segments updated in the last uploaded Eligibility File',
	},
});
