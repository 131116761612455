import { defineMessages } from 'react-intl';

export default defineMessages({
	uploadError: {
		id: 'users.upload.uploadError',
		defaultMessage: 'Failed to upload eligibility file',
		description: 'Message for the file upload error if there are no details',
	},
	serverDefinedUploadError: {
		id: 'users.upload.serverDefinedUploadError',
		defaultMessage: 'Failed to upload eligibility file. {servermessage}',
		description: 'Message for the file upload error if the server provides its own error message',
	},
	invalidFormatError: {
		id: 'users.upload.invalidFormatError',
		defaultMessage: 'Failed to upload eligibility file. File was in an incorrect format',
		description: 'Message for the file upload error if file was in wrong format (not CSV or Excel)',
	},
	multipleColumnsError: {
		id: 'users.upload.multipleColumnsError',
		defaultMessage: 'Failed to upload eligibility file. Data in multiple different columns',
		description: 'Message for the file upload error if file had data in more than one column',
	},
	passwordProtectedError: {
		id: 'users.upload.passwordProtected',
		defaultMessage: `File is password protected. Please remove this constraint and upload again.`,
		description:
			'Error message warning users that the file they gave us has a password protection and cannot be parsed',
	},
	unableToParseError: {
		id: 'users.upload.unableToParse',
		defaultMessage: `Parsing error. We are unable to handle this file. Please reach out to your Customer Success Manager for help`,
		description: 'Error message warning users that the file they uploaded is in a format we cannot handle.',
	},
});
