import styled from 'styled-components';

import { maxWidth, units } from '@calm-web/design-system';

export const Wrapper = styled.section`
	display: flex;
	gap: ${units(2)};

	${maxWidth('desktopLarge')} {
		flex-wrap: wrap;
	}
`;
export const ConfigWrapper = styled.div`
	max-width: ${units(85)};
`;

export const PreviewWrapper = styled.div`
	position: fixed;
	left: calc(1040px + ${units(4)});
	${maxWidth('desktop')} {
		display: none;
	}
`;
