import styled, { css } from 'styled-components';
import { palette } from 'styled-tools';

import { Text, units } from '@calm-web/design-system';

import CircleCheckIcon from 'icons/check.svg';
import WarningIcon from 'icons/warning-filled.svg';

const IconCss = css`
	margin-right: ${units(1)};
	width: ${units(2)};
	height: auto;
`;

export const Checkmark = styled(CircleCheckIcon)`
	${IconCss}

	path {
		fill: ${palette('buttonLime')};
	}
`;

export const Warning = styled(WarningIcon)`
	${IconCss}
`;

export const StatsWrapper = styled.div`
	margin: ${units(4, 0, 0, 2)};
`;
export const RemovedReminderText = styled(Text)`
	margin: ${units(1, 0, 2, 4)};
`;
