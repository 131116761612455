import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { Button, FormInput, FormSelect, Modal } from '@calm-web/design-system';
import { AddAdmin, Mail, Profile } from '@calm-web/icons';
import { OnChange } from '@calm-web/use-form';

import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import { PartnerMilestoneType, useRecordMilestone } from '@/hooks/api/useMilestones';
import { usePartnerAdmin } from '@/hooks/api/usePartnerAdmin';
import { usePermissions } from '@/hooks/auth';
import { useCanAddPhiAdmin } from '@/hooks/useCanAddPhiAdmin';
import { validate } from '@/utils/ui/validator';

import messages from '../messages';
import { AddPartnerAdmin, AddPrompt, CancelButton } from '../styles';

const AddAdminModal: FC<{
	partnerId: string;
	showAdminModal: boolean;
	setShowAdminModal: Dispatch<SetStateAction<boolean>>;
	isCalmHealth: boolean;
	isSftpAdmin?: boolean;
}> = ({ partnerId, showAdminModal, setShowAdminModal, isCalmHealth, isSftpAdmin }) => {
	const { formatMessage } = useIntl();
	const { logEvent } = useAnalytics();
	const [hasValidPermissions, actions] = usePermissions();
	const [recordMilestone] = useRecordMilestone();
	const { data: admins = [], sendInviteEmail } = usePartnerAdmin(partnerId);
	const [inviting, setInviting] = useState(false);
	const [adminEmail, setAdminEmail] = useState('');
	const [adminFirstName, setAdminFirstName] = useState('');
	const [adminLastName, setAdminLastName] = useState('');
	const adminPHIValues = ['Reporting Disabled', 'Reporting Enabled'];
	const [adminPHIValue, setAdminPHIValue] = useState(adminPHIValues[0]);
	const [disableAdminSubmit, setDisableAdminSubmit] = useState(true);
	const canGrantPHI = useCanAddPhiAdmin(admins, isCalmHealth);

	function resetAdminModal(): void {
		setAdminEmail('');
		setAdminFirstName('');
		setAdminLastName('');
	}

	const onModalDismiss = (): void => {
		resetAdminModal();
		setShowAdminModal(false);
	};

	const onChange: OnChange = e => {
		const { name, value } = e.target;
		if (name === 'email') {
			setAdminEmail(value);
		} else if (name === 'firstName') {
			setAdminFirstName(value);
		} else {
			setAdminLastName(value);
		}
	};

	const onChangePHI = (e: ChangeEvent<HTMLSelectElement>): void => {
		setAdminPHIValue(e.currentTarget.value);
	};

	const handleInviteClick = async (): Promise<void> => {
		try {
			logEvent('Partner Portal : Add Admin Button : Clicked', {
				email_added: adminEmail ?? '',
			});
			setInviting(true);
			await sendInviteEmail(
				adminEmail,
				adminFirstName,
				adminLastName,
				adminPHIValue === 'Reporting Enabled' ? true : false,
				isSftpAdmin,
			);
			await recordMilestone({ eventName: PartnerMilestoneType.ADMIN_INVITED, partnerId });
			setShowAdminModal(false);
			resetAdminModal();
		} catch {
			logEvent('Partner Portal : Add Admin Button : Clicked : Error', {
				email_added: adminEmail ?? '',
			});
		} finally {
			setInviting(false);
		}
	};
	useEffect(() => {
		if (adminEmail && adminFirstName && adminLastName) {
			const validEmail = validate('email', adminEmail);
			const validFirstName = validate('name', adminFirstName);
			const validLastName = validate('name', adminLastName);
			setDisableAdminSubmit(!(validEmail && validFirstName && validLastName));
		} else {
			setDisableAdminSubmit(true);
		}
	}, [adminEmail, adminFirstName, adminLastName]);

	return (
		<Modal
			isOpen={showAdminModal}
			closeModal={onModalDismiss}
			aria-label="Add Admin"
			titleElement={<AddAdmin />}
			footer={
				<>
					<CancelButton type="button" onClick={onModalDismiss}>
						{formatMessage(messages.cancelButton)}
					</CancelButton>

					<Button
						isDisabled={disableAdminSubmit}
						backgroundColor="blue3"
						onPress={handleInviteClick}
						isLoading={inviting}
						type="button"
					>
						{formatMessage(messages.addButton)}
					</Button>
				</>
			}
		>
			<AddPartnerAdmin>
				<AddPrompt>{formatMessage(messages.createAdminPrompt)}</AddPrompt>
				<FormInput
					label={formatMessage(messages.inputPlaceholderFirstName)}
					onChange={onChange}
					name="firstName"
					value={adminFirstName}
					Icon={Profile}
					isValid={validate('name', adminFirstName)}
				/>
				<FormInput
					label={formatMessage(messages.inputPlaceholderLastName)}
					onChange={onChange}
					name="lastName"
					value={adminLastName}
					Icon={Profile}
					isValid={validate('name', adminLastName)}
				/>
				<FormInput
					label={formatMessage(messages.inputPlaceholderEmail)}
					onChange={onChange}
					name="email"
					value={adminEmail}
					Icon={Mail}
					isValid={validate('email', adminEmail)}
				/>
				{hasValidPermissions('admin_phi', [actions.READ]) && isCalmHealth && (
					<FormSelect
						name="phiAuthorizationValue"
						value={adminPHIValue}
						aria-label="phiAuthorizationValue"
						options={adminPHIValues}
						disabled={!hasValidPermissions('admin_phi', [actions.CREATE]) || !canGrantPHI}
						onChange={onChangePHI}
					></FormSelect>
				)}
			</AddPartnerAdmin>
		</Modal>
	);
};

export default AddAdminModal;
