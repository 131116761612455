import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { palette, theme } from 'styled-tools';

import { fontSize, FontSizes, fontWeight, FontWeights, units } from '@calm-web/design-system';

export interface NavItemProps {
	active?: boolean;
}

export const StyledNav = styled.nav`
	display: flex;
	flex-wrap: wrap;
	gap: ${units(1)};
	margin-bottom: ${units(2)};
`;

export const Link = styled(NavLink)<NavItemProps>`
	padding: ${units(1.5)};
	color: ${palette('gray5')};
	background-color: ${palette('white')};
	border: 2px solid ${palette('blackAlpha5')};
	${fontSize(FontSizes.base)};
	${fontWeight(FontWeights.Medium)};
	white-space: nowrap;
	text-decoration: none;
	border-radius: ${theme('layout.borderRadius')};
	transition: border 0.3s ease, color 0.3s ease, color 0.3s ease, color 0.3s ease;
	path {
		transition: fill 0.3s ease, stroke 0.3s ease;
	}

	&:hover {
		color: ${palette('gray7')};
		border: 2px solid ${palette('blue3')};
	}

	&.selected {
		color: ${palette('gray7')};
		border: 2px solid ${palette('blue3')};
	}
`;
