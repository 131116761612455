import { AxiosResponse } from 'axios';
import useSWR from 'swr';

import { useApi } from '@/hooks/api';

import { ApiResponse, RefetchOption } from '../types';

export interface SegmentDetails {
	segment_names?: {
		segment_1_display_name?: string;
		segment_2_display_name?: string;
		segment_3_display_name?: string;
	};
	segment_values?: {
		segment_1_values?: string[];
		segment_2_values?: string[];
		segment_3_values?: string[];
	};
}

export function useSegmentDetails(
	partnerId: string,
	sourceFromEligibility = false,
): ApiResponse<SegmentDetails> & RefetchOption {
	const apiRequest = useApi();
	const { data, error, mutate } = useSWR<AxiosResponse | undefined>(
		`b2b/partners/${partnerId}/segment/details?source_from_eligibility_file=${sourceFromEligibility}`,
		async endpoint => {
			const response = await apiRequest({ endpoint });
			return response;
		},
		{ errorRetryCount: 0 },
	);

	const segments = data?.data.segments as SegmentDetails;
	const refetch = (): Promise<AxiosResponse | undefined> => mutate(undefined, true);
	return { data: segments, error, loading: !data && !error, refetch };
}
