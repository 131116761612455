import { defineMessages } from 'react-intl';

export default defineMessages({
	timeText: {
		id: 'pathways.notificationsTemplate.timeText',
		defaultMessage: 'now',
		description: 'Notification time text',
	},
	calmLogoText: {
		id: 'pathways.notificationsTemplate.logoText',
		defaultMessage: 'Calm',
		description: 'Text for calm logo',
	},
});
