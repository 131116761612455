import { defineMessages } from 'react-intl';

export default defineMessages({
	testEmailTitle: {
		id: 'pathways.review.testEmail.title',
		defaultMessage: 'Test email delivery',
		description: 'Test Email title text',
	},
	testEmailButton: {
		id: 'pathways.review.testEmail.button',
		defaultMessage: 'Send test email',
		description: 'Test Email button text',
	},
	triggerTestEmailError: {
		id: 'pathways.review.triggerTestEmail.error',
		defaultMessage: 'Unable to send test email',
		description: 'Error shown when attempt to send email fails',
	},
	triggerTestEmailInvalidEmailError: {
		id: 'pathways.review.triggerTestEmail.invalidEmail.error',
		defaultMessage: 'Please enter a valid email address.',
		description: 'Error shown when a send attempt occurs to an invalid email address',
	},
	triggerTestEmailSuccess: {
		id: 'pathways.review.triggerTestEmail.success',
		defaultMessage: 'Successfully sent email!',
		description: 'Message shown when the test email is delivered',
	},
	triggerTestEmailGeneralError: {
		id: 'pathways.review.triggerTestEmail.general.error',
		defaultMessage: 'An error occurred. Please try again later.',
		description: 'A message saying that an error happened when triggering the test email send',
	},
});
