import styled from 'styled-components';

import { units } from '@calm-web/design-system';

import GiftFilled from 'icons/gift-icon-simple.svg';

export const GiftIconGradient = styled(GiftFilled)`
	width: ${units(3)};
	height: ${units(3)};
`;
