import { defineMessages } from 'react-intl';

export default defineMessages({
	getStartedTitle: {
		id: 'reporting.signupsAndEngagement.graphPlaceholder.getStarted.title',
		defaultMessage: 'Start getting your covered lives to use Calm',
		description: 'Title for getting started',
	},
	noDataTitle: {
		id: 'reporting.signupsAndEngagement.graphPlaceholder.noData.title',
		defaultMessage: 'Oops! Not enough data',
		description: 'Title when theres not enough data to display',
	},
	getStartedDescription: {
		id: 'reporting.signupsAndEngagement.graphPlaceholder.getStarted.description',
		defaultMessage:
			'You’ll see more data here as your team uses Calm. Encourage your team to sign up for Calm Premium using our strategies outlined in the <resourcedoclink>Resources page</resourcedoclink>.',
		description: 'Description for getting started',
	},
	noDataDescription: {
		id: 'reporting.signupsAndEngagement.graphPlaceholder.noData.description',
		defaultMessage: 'Data cannot be displayed for groups of less than 10 users to protect users’ privacy.',
		description: 'Description when theres not enough data to display',
	},
});
