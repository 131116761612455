import { ReactElement, FormEvent, Fragment, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import {
	Modal,
	Button,
	FormInput,
	PrimaryButton,
	HeaderWithRule,
	Text,
	FontSizes,
	FontWeights,
	Loader,
	ModalWidth,
} from '@calm-web/design-system';
import useForm, { FormProps, stringFromModelValue, validation } from '@calm-web/use-form';

import { UpdatePrice } from '@/components/pages/Plan/PlanDetails/UpdateCoverage/UpdatePrice';
import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import { useAddSingleEligibility, isOverageError } from '@/hooks/api/useEligibility';
import { usePartner } from '@/hooks/api/usePartner';
import useSegments, { Segments } from '@/hooks/api/useSegments';
import { setBannerMessage } from '@/store/actions/setBannerMessage';
import { calmLogger } from '@/utils/calmLogger';

import messages from './messages';
import {
	AdditionalCoverage,
	AdditionalCoverageMessage,
	Prompt,
	Container,
	ButtonRow,
	Label,
	TextBlock,
	SegmentsInfoStyled,
	UploadButtonWrapper,
	UploadWrapper,
} from './styles';

export type FieldNames = 'partnerUserId' | 'segment1Value' | 'segment2Value' | 'segment3Value';

export type AddUserFormProps = FormProps<FieldNames>;

interface Props {
	partnerId: string;
	onClickCancel: () => void;
	onAddSuccess: () => void;
	openFileUpload?: (isFirstUpload: boolean, partnerId: string) => void;
}

export default function AddModal({
	partnerId,
	onClickCancel,
	onAddSuccess,
	openFileUpload,
}: Props): ReactElement {
	const { formatMessage } = useIntl();
	const { data: segments, loading, error } = useSegments(partnerId);

	function handleCancel(): void {
		onClickCancel();
	}

	function handleUploadClick(): void {
		if (openFileUpload) {
			openFileUpload(true, partnerId);
		}
		onClickCancel();
	}

	return (
		<Modal
			width={ModalWidth.Extra}
			isOpen
			closeModal={handleCancel}
			aria-label={formatMessage(messages.title)}
		>
			<Container>
				{openFileUpload && (
					<UploadWrapper>
						<Text el="h2" styleAs="h5" weight={FontWeights.Medium}>
							{formatMessage(messages.uploadFileHeading)}
						</Text>
						<UploadButtonWrapper>
							<Button
								size="lg"
								textColor="blue3"
								hideShadow
								backgroundColor="transparent"
								onPress={handleUploadClick}
								data-testid="open-ef-modal"
							>
								{formatMessage(messages.uploadFileButton)}
							</Button>
						</UploadButtonWrapper>

						<HeaderWithRule fontSize={FontSizes.sm} textColor="gray6" hrColor="gray2">
							{formatMessage(messages.orDivider)}
						</HeaderWithRule>
					</UploadWrapper>
				)}

				{loading ? <Loader color="gray1" /> : null}
				{error ? <div>{formatMessage(messages.segmentsError)}</div> : null}
				{!loading && !error ? (
					<AddUserForm
						partnerId={partnerId}
						onAddSuccess={onAddSuccess}
						handleCancel={handleCancel}
						segments={segments}
					/>
				) : null}
			</Container>
		</Modal>
	);
}

function SegmentsInfo(): ReactElement {
	const { formatMessage } = useIntl();

	return (
		<SegmentsInfoStyled>
			{formatMessage(messages.segmentInfoText, {
				bold: (...chunks: ReactNode[]) => <b>{chunks}</b>,
				exampleText: (...chunks: ReactNode[]) => <TextBlock>{chunks}</TextBlock>,
			})}
		</SegmentsInfoStyled>
	);
}

function SegmentValuesForm({
	segmentFormInfo,
	formProps,
}: {
	segmentFormInfo: { name: string; formName: FieldNames }[];
	formProps: AddUserFormProps;
}): ReactElement {
	const { formatMessage } = useIntl();
	return (
		<>
			{segmentFormInfo.map(({ name, formName }) => (
				<Fragment key={formName}>
					<FormInput
						{...formProps.bindWithErrorProps(formName, 'text')}
						placeholder={formatMessage(messages.segmentInputPlaceholder)}
						label={name}
						LabelComponent={Label}
						noValidation
					/>
				</Fragment>
			))}
		</>
	);
}

function AddUserForm({
	partnerId,
	onAddSuccess,
	handleCancel,
	segments,
}: {
	partnerId: string;
	onAddSuccess: () => void;
	handleCancel: () => void;
	segments?: Segments;
}): ReactElement {
	const { formatMessage } = useIntl();
	const { data: partner } = usePartner(partnerId);
	const coveredLives = partner?.contract_covered_lives ?? 0;

	const { logEvent } = useAnalytics();
	const dispatch = useDispatch();

	const [addUserToEligibility, { error, loading, clearError }] = useAddSingleEligibility(partnerId);

	const formProps: AddUserFormProps = useForm('addUserForm', {
		initialModel: {
			partnerUserId: '',
			segment1Value: '',
			segment2Value: '',
			segment3Value: '',
		},
		validation: {
			partnerUserId: validation.validateOrFail([
				{
					rules: [validation.required, validation.minLength(2)],
					errorResult: 'Please enter a partner slug at least 2 characters long',
				},
			]),
		},
	});

	async function onSubmit(e: FormEvent): Promise<void> {
		e.preventDefault();
		try {
			const partnerUserId = stringFromModelValue(formProps.model.partnerUserId);
			const segment1Value = stringFromModelValue(formProps.model.segment1Value);
			const segment2Value = stringFromModelValue(formProps.model.segment2Value);
			const segment3Value = stringFromModelValue(formProps.model.segment3Value);
			if (!formProps.validation.isValid || !partnerUserId) {
				return;
			}

			await addUserToEligibility(partnerUserId, segment1Value, segment2Value, segment3Value);
			onAddSuccess();
		} catch (err) {
			calmLogger.error('Error when trying to add user to eligibility', {}, err);
		}
	}

	if (isOverageError(error)) {
		const {
			data: {
				error: {
					info: { detail: messageDetail },
				},
			},
		} = error;

		const handleSubmit = (): void => {
			logEvent('Partner Portal : Update coverage : Upgrade complete');

			dispatch(
				setBannerMessage({
					message: 'Upgrade complete!',
					isError: false,
					flash: true,
				}),
			);

			clearError();
		};

		return (
			<AdditionalCoverage>
				<AdditionalCoverageMessage>{String(messageDetail)}</AdditionalCoverageMessage>
				<Prompt>{formatMessage(messages.additionalCoveragePrompt)}</Prompt>
				<UpdatePrice
					previousCoveredLives={coveredLives}
					onCancel={handleCancel}
					onSubmit={handleSubmit}
					partnerId={partnerId}
				/>
			</AdditionalCoverage>
		);
	}
	if (error) {
		return (
			<form onSubmit={onSubmit}>
				<Text el="h2" styleAs="h5" weight={FontWeights.Medium}>
					{formatMessage(messages.title)}
				</Text>
				<TextBlock>{formatMessage(messages.addError)}</TextBlock>
				<ButtonRow>
					<Button backgroundColor="transparent" textColor="blue3" hideShadow onPress={handleCancel}>
						{formatMessage(messages.cancelAddUserButton)}
					</Button>
					<PrimaryButton type="submit" isDisabled={true} isLoading={loading}>
						{formatMessage(messages.addUserButton)}
					</PrimaryButton>
				</ButtonRow>
			</form>
		);
	}
	const segment1Name = segments?.segment_1_name;
	const segment2Name = segments?.segment_2_name;
	const segment3Name = segments?.segment_3_name;
	const showSegmentInfo = !!segment1Name || !!segment2Name || !!segment3Name;

	const segmentFormInfo = [
		...(segment1Name ? [{ name: segment1Name, formName: 'segment1Value' as const }] : []),
		...(segment2Name ? [{ name: segment2Name, formName: 'segment2Value' as const }] : []),
		...(segment3Name ? [{ name: segment3Name, formName: 'segment3Value' as const }] : []),
	];

	return (
		<form onSubmit={onSubmit}>
			<Text el="h2" styleAs="h5" weight={FontWeights.Medium}>
				{formatMessage(messages.title)}
			</Text>
			<TextBlock>
				{formatMessage(messages.uniqueIdText, {
					bold: (...chunks: ReactNode[]) => <b>{chunks}</b>,
					exampleText: (...chunks: ReactNode[]) => <TextBlock>{chunks}</TextBlock>,
				})}
			</TextBlock>
			<FormInput
				{...formProps.bindWithErrorProps('partnerUserId', 'text')}
				placeholder={formatMessage(messages.uniqueIdPlaceholder)}
				label="Unique ID"
				LabelComponent={Label}
				noValidation
			/>
			{showSegmentInfo ? <SegmentsInfo /> : null}
			<SegmentValuesForm segmentFormInfo={segmentFormInfo} formProps={formProps} />
			<ButtonRow>
				<Button backgroundColor="transparent" textColor="blue3" hideShadow onPress={handleCancel}>
					{formatMessage(messages.cancelAddUserButton)}
				</Button>
				<PrimaryButton
					type="submit"
					isDisabled={!formProps.validation.isValid || loading}
					isLoading={loading}
				>
					{formatMessage(messages.addUserButton)}
				</PrimaryButton>
			</ButtonRow>
		</form>
	);
}
