import styled from 'styled-components';
import { palette } from 'styled-tools';

import { Button, FontSizes, FontWeights, TextButton, units } from '@calm-web/design-system';

import Download from 'icons/download.svg';

export const SetupSubtitle = styled.div`
	color: ${palette('gray7')};
	margin-bottom: 1rem;
`;

export const SetupButton = styled(Button)`
	white-space: nowrap;
`;

export const StepTask = styled.div`
	border-left: 2px solid ${palette('blue1')};
	padding-left: ${units(2)};
	margin: ${units(2)};
	color: ${palette('gray8')};
	font-size: ${FontSizes.sm};
`;

export const StepLink = styled.a`
	display: flex;
	align-items: flex-end;
	overflow-wrap: anywhere;
`;

export const CopyButton = styled(Button)`
	margin-bottom: ${units(3)};
`;

export const LinkWrapper = styled.div`
	display: flex;
	button {
		margin-left: ${units(1)};
	}
`;

export const DownloadLink = styled.a`
	font-size: ${FontSizes.sm};
	font-weight: ${FontWeights.Regular};
	color: ${palette('blue3')};
	background: none;
	border: none;
	cursor: pointer;
	display: flex;
	align-items: center;
`;

export const DownloadIcon = styled(Download)`
	height: 13px;
	margin-right: 10px;
`;

export const ButtonGroup = styled.div`
	display: flex;
	gap: ${units(1)};
`;

export const SmallTextButton = styled(TextButton).attrs({
	size: FontSizes.sm,
})``;

export const DesignateNote = styled.div`
	margin-right: ${units(2)};
`;
