import querystring from 'query-string';
import { Key } from 'react';

import { timeframeValues } from '@/components/providers/ReportingContextProvider';
import { SegmentDetails } from '@/hooks/api/reporting/useSegmentDetails';
import { csvToArray } from '@/utils/csv';

import { iso8601Date } from './helpers';

export interface DefinedSegmentDetails {
	segment_names: SegmentDetails['segment_names'];
	segment_values: SegmentDetails['segment_values'];
}

interface SegmentJSONFilter {
	filter: {
		start_date: string;
		end_date: string;
		segment_1_value?: {
			in: string[];
		};
		segment_2_value?: {
			in: string[];
		};
		segment_3_value?: {
			in: string[];
		};
	};
}

export const UNCATEGORIZED_VALUE_NAME = '(No Segment)';

function addQueryParams(params: string, [key, values]: [string, Set<string>]): string {
	if (!values || values.size === 0) {
		return params;
	}
	const mapped = [...values].map(v => `"${encodeURIComponent(v)}"`);
	const joined = mapped.join(',');
	if (params === '') {
		return `${key}=${joined}`;
	}
	return `${params}&${key}=${joined}`;
}

export function hasSegments(segmentDetails?: SegmentDetails): segmentDetails is DefinedSegmentDetails {
	if (!segmentDetails) {
		return false;
	}

	const { segment_names, segment_values } = segmentDetails;
	if (!segment_names || !segment_values) {
		return false;
	}

	const { segment_1_display_name, segment_2_display_name, segment_3_display_name } = segment_names;

	const { segment_1_values, segment_2_values, segment_3_values } = segment_values;

	return Boolean(
		(segment_1_display_name && segment_1_values?.length) ||
			(segment_2_display_name && segment_2_values?.length) ||
			(segment_3_display_name && segment_3_values?.length),
	);
}

export interface SegmentSelectState {
	segment1: Set<string> | undefined;
	segment2: Set<string> | undefined;
	segment3: Set<string> | undefined;
}

export function segmentsToParams(
	state: SegmentSelectState,
	timeframe?: { value: Key; startDate: Date; endDate: Date },
): string {
	const entries = Object.entries(state);
	const params = entries.reduce(addQueryParams, '');
	if (timeframe) {
		const timeframeParams: { startDate?: number; endDate?: number; timeframe?: string } = {};
		if (timeframe.value === timeframeValues.CUSTOM) {
			timeframeParams.startDate = timeframe.startDate.getTime();
			timeframeParams.endDate = timeframe.endDate.getTime();
		} else {
			timeframeParams.timeframe = timeframe.value as string;
		}
		const timeframeParamsString = querystring.stringify(timeframeParams);
		if (params === '') {
			return `?${timeframeParamsString}`;
		}
		return `?${params}&${timeframeParamsString}`;
	}
	if (params === '') {
		return params;
	}
	return `?${params}`;
}

export function paramsToSegments(search: string): SegmentSelectState {
	const query = querystring.parse(search);
	const joined = Object.entries(query).map(([key, _raw]) => {
		const raw = Array.isArray(_raw) ? _raw[0] : _raw;
		const parsed = csvToArray(raw);
		if (!parsed || !parsed.length) {
			return [key, new Set<string>()];
		}
		const [value] = parsed;
		const decodedValue = value.map(v => (v ? decodeURIComponent(v) : ''));

		if (Array.isArray(decodedValue)) {
			return [key, new Set<string>(decodedValue)];
		}

		return [key, new Set<string>([decodedValue])];
	});
	const params = Object.fromEntries(joined);
	return params;
}

export interface SegmentSelectAction {
	type: 'select all' | 'select none' | 'select one';
	data: {
		segment: keyof SegmentSelectState;
		value?: string;
		allChoices: string[];
	};
}

export function checkIsShowingSegmented(segments: SegmentSelectState): boolean {
	return (
		(typeof segments.segment1 !== 'undefined' && segments.segment1?.size > 0) ||
		(typeof segments.segment2 !== 'undefined' && segments.segment2?.size > 0) ||
		(typeof segments.segment3 !== 'undefined' && segments.segment3?.size > 0)
	);
}

export function segmentsToJSON(
	segmentSelection: SegmentSelectState | undefined,
	startDate: Date,
	endDate: Date | undefined,
): [SegmentJSONFilter, string] {
	const segment1Filter = segmentSelection?.segment1
		? { segment_1_value: { in: [...segmentSelection.segment1] } }
		: null;
	const segment2Filter = segmentSelection?.segment2
		? { segment_2_value: { in: [...segmentSelection.segment2] } }
		: null;
	const segment3Filter = segmentSelection?.segment3
		? { segment_3_value: { in: [...segmentSelection.segment3] } }
		: null;

	const _startDate = iso8601Date(startDate);
	const _endDate = endDate ? iso8601Date(endDate) : '';

	const segment1Printed = segmentSelection?.segment1 ? [...segmentSelection.segment1]?.join(' ') : '';
	const segment2Printed = segmentSelection?.segment2 ? [...segmentSelection.segment2]?.join(' ') : '';
	const segment3Printed = segmentSelection?.segment3 ? [...segmentSelection.segment3]?.join(' ') : '';

	const swrString = `s1Val=${segment1Printed}&s2Val=${segment2Printed}&s3Val=${segment3Printed}&startDate=${_startDate}&endDate=${_endDate}`;

	return [
		{
			filter: {
				...segment1Filter,
				...segment2Filter,
				...segment3Filter,
				start_date: _startDate,
				end_date: _endDate,
			},
		},
		swrString,
	];
}
