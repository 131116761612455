import { animated } from 'react-spring';
import styled from 'styled-components';
import { palette } from 'styled-tools';

export const Wrapper = styled.div`
	position: fixed;
	width: 100vw;
	height: 100vh;
	left: 0;
	top: 0;
`;

export const Overlay = styled(animated.div)`
	width: 100%;
	height: 100%;
	background: ${palette('gray7')};
	opacity: 0.7;
	display: flex;
	align-items: center;
	justify-content: center;
`;
