import styled from 'styled-components';
import { palette } from 'styled-tools';

import { fontWeight, FontWeights, units } from '@calm-web/design-system';

import CheckFilledSvg from 'icons/check.svg';

export const SuccessContainer = styled.div`
	text-align: center;
	margin: ${units(4)};
`;

export const SuccessIcon = styled(CheckFilledSvg)`
	height: ${units(5)};
	path {
		fill: ${palette('blue3')};
	}
`;

export const SuccessText = styled.div`
	text-align: center;
	width: 100%;
	color: ${palette('gray6')};
	${fontWeight(FontWeights.Medium)};
	margin-bottom: ${units(4)};
`;
