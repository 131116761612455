import styled from 'styled-components';
import { palette } from 'styled-tools';

import {
	units,
	Text,
	TextElements,
	FontSizes,
	FontWeights,
	TextAlign,
	TextButton,
	fontWeight,
} from '@calm-web/design-system';

import Filter from 'icons/filter.svg';
import RightArrow from 'icons/right-arrow.svg';

export const FlexBox = styled.div`
	display: flex;
	justify-content: space-around;
	gap: 16px;
	margin-bottom: 16px;
`;

export const StyledTable = styled.table`
	border-spacing: 0;
	flex: 1;
	th,
	td {
		border-bottom: 2px solid ${palette('gray0')};
	}
`;

export const Arrow = styled(RightArrow)<{ $isTop: boolean }>`
	height: ${units(2.5)};
	max-width: ${units(2.5)};
	transform: ${p => (p.$isTop ? 'rotate(-90deg)' : 'rotate(90deg)')};
	margin: 0 auto;
	display: block;
	path {
		fill: ${p => (p.$isTop ? palette('successGreen') : palette('errorRed'))};
	}
`;

export const FilterIcon = styled(Filter)`
	height: ${units(2.5)};
	max-width: ${units(2.5)};
	vertical-align: bottom;
	path {
		fill: ${palette('blue3')};
	}
`;

export const TableRow = styled.tr`
	height: 29px;
`;

export const TableHeaderRow = styled(TableRow)<{ $isTop: boolean }>`
	background-color: ${p => (p.$isTop ? '#28865e0d' : '#d12c2c0d')};
`;

export const TableHeader = styled.th`
	text-align: left;
	text-wrap: nowrap;
	padding-right: ${units(1)};
`;

const TableText = styled(Text).attrs({
	el: TextElements.Span,
	size: FontSizes.sm,
	weight: FontWeights.Medium,
	align: TextAlign.Left,
})`
	line-height: 20px;
`;

export const TableTextMargin = styled(TableText)`
	margin-right: 10px;
`;

export const TableTextGray80 = styled(TableText).attrs({
	opacity: 0.8,
})``;

export const TableTextGray60 = styled(TableText).attrs({
	opacity: 0.6,
})``;

export const RankText = styled(Text).attrs({
	el: TextElements.Span,
	size: FontSizes.sm,
	weight: FontWeights.Medium,
	align: TextAlign.Center,
	opacity: 0.6,
})`
	display: flex;
	justify-content: center;
`;

export const ViewButton = styled(TextButton).attrs({
	textColor: 'blue3',
	size: FontSizes.sm,
	hideUnderline: true,
})`
	${fontWeight(FontWeights.Medium)}
	text-align: center;
`;

export const FilterSegmentButton = styled(TextButton).attrs({
	size: FontSizes.sm,
	textColor: 'blue3',
	type: 'button',
	hideUnderline: true,
})``;
