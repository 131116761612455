import { ReactElement } from 'react';

import { ImageContainer, ImageElement, ImageMask, Wrapper } from './styles';

interface Props {
	src: string;
	lqip?: string;
	className?: string;
	isFullScreen?: boolean;
	enableResponsiveImage?: boolean;
	customFormat?: string;
	alt: string;
	width?: number;
}

function generateImageUrl(url: string, width: number, customFormat?: string): string {
	if (!width) return '';
	const baseImage = url?.split('/upload/');
	const settings = `f_auto,c_scale,w_${width},dpr_auto,q_auto`;
	if (customFormat) {
		const srcKey = baseImage[1].substr(0, baseImage[1].lastIndexOf('.'));
		return `${baseImage[0]}/upload/${settings}/${srcKey}.${customFormat}`;
	}
	return `${baseImage[0]}/upload/${settings}/${baseImage[1]}`;
}

function generateImgSrcSetAttribute(
	url: string,
	enableResponsiveImage: boolean,
	customFormat?: string,
	width?: number,
): string {
	if (!url?.includes('/upload/') || !enableResponsiveImage) {
		// Image isn't a cloudinary url
		return url;
	}

	if (width) {
		return generateImageUrl(url, width, customFormat);
	}
	return generateImageUrl(url, 2650, customFormat);

	/**
	 * Leaving for reverence on how to map images for multiple screen sizes
	 * Intersection observer handles the screen sizes automatically
	 */
	// These refer to the min screen width that an image can be loaded, in pixels
	// const BREAKPOINTS = [300, 400, 768, 1024, 1440, 1920, 2560, 3840];
	// return BREAKPOINTS.map(
	//   width => `${generateImageUrl(url, width, customFormat)} ${width}w`,
	// ).join(', ');
}

// Scale based off of the height
function Image({
	src,
	lqip,
	className,
	isFullScreen,
	enableResponsiveImage,
	customFormat,
	alt,
	width,
}: Props): ReactElement {
	const images = generateImgSrcSetAttribute(src, Boolean(enableResponsiveImage), customFormat, width);
	const srcSet = images.length > 1 ? images : null;
	if (lqip) {
		return (
			<Wrapper>
				<ImageContainer>
					<ImageElement
						className={`${className || ''} lozad`}
						src={lqip}
						data-src={src}
						isFullScreen={isFullScreen}
						data-srcset={srcSet}
						alt={alt}
					/>
					<ImageMask />
				</ImageContainer>
			</Wrapper>
		);
	}
	return <ImageElement src={src} isFullScreen={isFullScreen} />;
}

export default Image;
