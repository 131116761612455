import { FC } from 'react';

import { CalmHealthDarkLogo, CalmHealthLightLogo } from '@calm-web/icons';
import { fileFromModelValue } from '@calm-web/use-form';

import { useHealthSponsorships } from '@/hooks/api/useHealthSponsorships';
import { useDefinedPartner } from '@/hooks/api/usePartner';
import { HealthConfigLogosFormProps } from '@/hooks/forms/useHealthConfigLogosForm';
import { HealthConfig } from '@/types/health';
import { createUrl } from '@/utils/url';

import { PhoneOutline, Wrapper } from '../styles';
import {
	LeftChevron,
	ViewPort,
	Background,
	WhiteBox,
	Header,
	CHLogo,
	Heading,
	SubHeading,
	Image,
	ImageContainer,
	GrayLine,
	Label,
	Value,
	Title,
	EligibilityHeader,
	EligibilitySubHeader,
	PhoneIcon,
	RightChevron,
	SupportContainer,
	EmailIcon,
} from './styles';

interface Props {
	healthConfig: HealthConfig;
	isDarkMode?: boolean;
	clientSupportChannel?: string;
	logoFormProps?: HealthConfigLogosFormProps;
}

const InAppClientSupportPreview: FC<Props> = ({
	healthConfig,
	isDarkMode,
	clientSupportChannel = 'phone',
	logoFormProps,
}) => {
	const partner = useDefinedPartner();
	const sponsorships = useHealthSponsorships(partner.id);

	const updatedLightLogoUrl = createUrl(fileFromModelValue(logoFormProps?.model?.lightLogoFile));
	const lightLogoSrc = updatedLightLogoUrl || healthConfig.light_logo_url || '/_n/images/employer-co.png';
	const updatedDarkLogoUrl = createUrl(fileFromModelValue(logoFormProps?.model?.darkLogoFile));
	const darkLogoSrc = updatedDarkLogoUrl || healthConfig.dark_logo_url || '/_n/images/employer-co-white.png';

	return (
		<Wrapper data-testid="in-app-client-support-preview">
			<Background $isDark={isDarkMode} />
			<PhoneOutline />
			<Header>
				<LeftChevron $isDark={isDarkMode} />
				<CHLogo>
					{isDarkMode ? (
						<CalmHealthDarkLogo data-testid="calm-health-dark-logo" width={58.8} height={24} />
					) : (
						<CalmHealthLightLogo data-testid="calm-health-light-logo" width={58.8} height={24} />
					)}
				</CHLogo>
			</Header>
			<ViewPort>
				<Heading $isDark={isDarkMode}>Calm Health is provided to you by {partner.name}</Heading>
				<SubHeading $isDark={isDarkMode}>
					Access to Calm Health can be sponsored by employers, healthcare providers, or health insurance
					organizations.
				</SubHeading>
				<WhiteBox $isDark={isDarkMode} $boxIndex={0}>
					<ImageContainer>
						<Image src={isDarkMode ? darkLogoSrc : lightLogoSrc} alt="Your Logo" />
					</ImageContainer>
					<GrayLine />
					<Label $isDark={isDarkMode}>Sponsorship Access Code:</Label>
					<Value $isDark={isDarkMode}>12345670XYT</Value>
					<GrayLine />
					<Label $isDark={isDarkMode}>Sponsor Group:</Label>
					<Value $isDark={isDarkMode}>{sponsorships.data?.[0]?.display_name ?? 'Default Sponsor'}</Value>
				</WhiteBox>
				<Title $isDark={isDarkMode}>Eligibility Support</Title>
				<WhiteBox $isDark={isDarkMode}>
					{clientSupportChannel !== 'email' && (
						<>
							<SupportContainer>
								<PhoneIcon $isDark={isDarkMode} aria-label="phone icon" width={32} />
								<div>
									<EligibilityHeader $isDark={isDarkMode}>Call Support</EligibilityHeader>
									<EligibilitySubHeader $isDark={isDarkMode}>
										Call Default with any eligibility questions
									</EligibilitySubHeader>
								</div>
								<RightChevron $isDark={isDarkMode} />
							</SupportContainer>
						</>
					)}
					{clientSupportChannel === 'both' && <GrayLine />}
					{clientSupportChannel !== 'phone' && (
						<>
							<SupportContainer>
								<EmailIcon aria-label="email icon" width={32} />
								<div>
									<EligibilityHeader>Email Support</EligibilityHeader>
									<EligibilitySubHeader>Email Default with any eligibility questions</EligibilitySubHeader>
								</div>
								<RightChevron />
							</SupportContainer>
						</>
					)}
				</WhiteBox>
			</ViewPort>
		</Wrapper>
	);
};

export default InAppClientSupportPreview;
