import {
	Dispatch,
	FC,
	ReactElement,
	ReactNode,
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useReducer,
	useState,
} from 'react';
import { useIntl } from 'react-intl';

import { ButtonSizes, SecondaryButton, Tooltip, Loader, Modal, ModalWidth } from '@calm-web/design-system';
import { OnChange } from '@calm-web/use-form';

import FilterButton from '@/components/ui/FilterButton';
import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import { useBaseReport } from '@/hooks/api/reporting/useBaseReport';
import { useSegmentDetails } from '@/hooks/api/reporting/useSegmentDetails';
import { ReportSignupsAndEngagementModular } from '@/hooks/api/reporting/useSignupsAndEngagement';
import { ApiResponse, RefetchOption } from '@/hooks/api/types';
import { useFeatureFlags } from '@/hooks/api/useFeatureFlags';
import { useHasScrolled } from '@/hooks/layout/useHasScrolled';
import CalmCookie from '@/utils/CalmCookie';
import { isReportingPrivacyError, isReportNotFoundError } from '@/utils/apiRequest/errors';
import {
	hasSegments,
	SegmentSelectAction,
	SegmentSelectState,
	UNCATEGORIZED_VALUE_NAME,
} from '@/utils/segments';

import ListOutlineIcon from 'icons/filter.svg';

import { B2B_UNLIMITED_SEGMENTS } from '../B2BReporting';
import messages from './messages';
import {
	Banner,
	ButtonContainer,
	ButtonRow,
	InlineLoader,
	LoadingText,
	LoadingTextContainer,
	PrimaryButton,
	ResultsCountError,
	ResultsStyled,
	ScrolledTooltipZIndex,
	SegmentDetailsError,
	SegmentListContainer,
	SegmentNameStyled,
	SegmentSelectionList,
	SegmentSelectionStyled,
	SelectButtonRow,
	SelectionButton,
	TooltipText,
	UnscrolledTooltipZIndex,
	WarningIconStyled,
	SegmentCheckbox,
	SegmentSelectionListScrollIndicator,
	SegmentSelectionListContainer,
	SegmentNameCheckbox,
} from './styles';

const MIN_USER_LIMIT = 10;
// MAX_SEGMENTS_LIMIT is determined by the number of distinct
// segments we return from `findDistinctValues` of each B2B Reporting Model
// Specifically, the `limit` used when querying for distinct
// values determines the `MAX_SEGMENTS_LIMIT`
const MAX_SEGMENTS_LIMIT = 50;

export function SingleSegmentSelection({
	name,
	values,
	segment,
	onChange,
	selected,
	isAllOrNone,
}: {
	name: string;
	values: string[];
	segment: keyof SegmentSelectState;
	onChange: (a: SegmentSelectAction) => void;
	selected: Set<string> | undefined;
	isAllOrNone?: boolean;
}): ReactElement {
	const { formatMessage } = useIntl();
	const handleChange: OnChange = e => {
		const { value } = e.target;

		onChange({
			type: 'select one',
			data: { segment, value, allChoices: values },
		});
	};

	const handleSelectAll = (): void => {
		onChange({
			type: 'select all',
			data: { segment, allChoices: values },
		});
	};

	const handleSelectNone = (): void => {
		onChange({
			type: 'select none',
			data: { segment, allChoices: values },
		});
	};

	const isChecked = useCallback(
		(value: string): boolean => {
			if (typeof selected === 'undefined') {
				return true;
			}
			return selected.has(value);
		},
		[selected],
	);

	const getDisplayName = (value: string): string => {
		return value === '' ? UNCATEGORIZED_VALUE_NAME : value;
	};

	const isCheckedAll = useMemo(() => {
		return values.some(isChecked);
	}, [values, isChecked]);

	return (
		<SegmentSelectionStyled>
			{isAllOrNone ? (
				<SegmentNameCheckbox
					name={name}
					checked={isCheckedAll}
					onClick={isCheckedAll ? handleSelectNone : handleSelectAll}
				>
					{name}
				</SegmentNameCheckbox>
			) : (
				<SegmentNameStyled>{name}</SegmentNameStyled>
			)}
			<SegmentSelectionListContainer>
				<SegmentSelectionList>
					{values.map(value => (
						<SegmentCheckbox
							key={`${segment}-${getDisplayName(value)}}`}
							value={value}
							name={segment}
							onChange={handleChange}
							checked={isChecked(value)}
							data-testid={`segments-selection-list-value-${getDisplayName(value)}`}
							disabled={isAllOrNone}
						>
							{getDisplayName(value)}
						</SegmentCheckbox>
					))}
				</SegmentSelectionList>
				<SegmentSelectionListScrollIndicator />
			</SegmentSelectionListContainer>
			{!isAllOrNone && (
				<SelectButtonRow>
					<SelectionButton onClick={handleSelectAll}>{formatMessage(messages.selectAll)}</SelectionButton>
					<SelectionButton onClick={handleSelectNone}>{formatMessage(messages.selectNone)}</SelectionButton>
				</SelectButtonRow>
			)}
		</SegmentSelectionStyled>
	);
}

export function selectionReducer(state: SegmentSelectState, action: SegmentSelectAction): SegmentSelectState {
	const {
		type,
		data: { segment, value, allChoices },
	} = action;

	if (type === 'select all') {
		const newState = { ...state };
		newState[segment] = undefined;
		return newState;
	}

	if (type === 'select none') {
		const newState = { ...state };
		newState[segment] = new Set();
		return newState;
	}

	if (!value && value !== '') {
		return state;
	}

	const selections = state[segment] ?? new Set(allChoices);

	if (selections.has(value)) {
		selections.delete(value);
	} else {
		selections.add(value);
	}

	if (selections.size === allChoices.length) {
		const newState = { ...state };
		newState[segment] = undefined;
		return newState;
	}

	const newState = { ...state, [segment]: selections };
	return newState;
}

export function TryAgain({ onClick }: { onClick: () => void }): ReactElement {
	const { formatMessage } = useIntl();
	return (
		<SecondaryButton size={ButtonSizes.Sm} onPress={onClick}>
			{formatMessage(messages.tryAgain)}
		</SecondaryButton>
	);
}

export function SegmentSelection({
	partnerId,
	selection,
	updateSelection,
	isAllOrNone,
}: {
	partnerId: string;
	selection: SegmentSelectState;
	updateSelection: (s: SegmentSelectAction) => void;
	isAllOrNone?: boolean;
}): ReturnType<FC> {
	const { formatMessage } = useIntl();

	const { loading, data: segmentDetails, error, refetch } = useSegmentDetails(partnerId);

	if (loading) {
		return <Loader color="gray1" />;
	}

	if (error) {
		const handleTryAgain = (): void => refetch();
		return (
			<SegmentDetailsError>
				<WarningIconStyled />
				<div>{formatMessage(messages.segmentDetailsError)}</div>
				<TryAgain onClick={handleTryAgain} />
			</SegmentDetailsError>
		);
	}

	if (!hasSegments(segmentDetails)) {
		return null;
	}

	const { segment_names, segment_values } = segmentDetails;
	const { segment_1_display_name, segment_2_display_name, segment_3_display_name } = segment_names || {};
	const { segment_1_values, segment_2_values, segment_3_values } = segment_values || {};

	return (
		<>
			{segment_1_display_name && segment_1_values?.length ? (
				<SingleSegmentSelection
					name={segment_1_display_name}
					values={segment_1_values}
					segment="segment1"
					onChange={updateSelection}
					selected={selection.segment1}
					isAllOrNone={isAllOrNone}
				/>
			) : null}
			{segment_2_display_name && segment_2_values?.length ? (
				<SingleSegmentSelection
					name={segment_2_display_name}
					values={segment_2_values}
					segment="segment2"
					onChange={updateSelection}
					selected={selection.segment2}
					isAllOrNone={isAllOrNone}
				/>
			) : null}
			{segment_3_display_name && segment_3_values?.length ? (
				<SingleSegmentSelection
					name={segment_3_display_name}
					values={segment_3_values}
					segment="segment3"
					onChange={updateSelection}
					selected={selection.segment3}
					isAllOrNone={isAllOrNone}
				/>
			) : null}
		</>
	);
}

function SegmentSelectionWarning({ partnerId }: { partnerId: string }): ReturnType<FC> {
	const { formatMessage } = useIntl();

	const { loading, data: segmentDetails, error } = useSegmentDetails(partnerId);

	if (loading || error || !segmentDetails) {
		return null;
	}

	const allValues = segmentDetails.segment_values || {};
	const showWarning = Object.values(allValues).some(
		(values?: string[]) => !values || (values.length ?? 0) >= MAX_SEGMENTS_LIMIT,
	);

	if (!showWarning) {
		return null;
	}

	return (
		<Banner data-testid="segment-selection-warning-banner">
			{formatMessage(messages.tooManySegmentsWarning, {
				segmentLimit: MAX_SEGMENTS_LIMIT,
			})}
		</Banner>
	);
}

export function ResultsCount({
	baseReport,
	isDebouncing,
}: {
	baseReport: ApiResponse<Pick<ReportSignupsAndEngagementModular, 'unique_users_in_segment'>> & RefetchOption;
	isDebouncing: boolean;
}): ReactElement {
	const { formatMessage } = useIntl();
	const { data: report, error, loading, refetch } = baseReport;

	if (isDebouncing || loading) {
		return (
			<LoadingTextContainer>
				<LoadingText>{formatMessage(messages.loading)}</LoadingText>
				<InlineLoader color="gray1" />
			</LoadingTextContainer>
		);
	}

	if (isReportingPrivacyError(error)) {
		return <></>;
	}

	if (isReportNotFoundError(error)) {
		return <>{formatMessage(messages.noUsersInSegment)}</>;
	}

	if (error || !report) {
		const handleTryAgain = (): void => refetch();
		return (
			<ResultsCountError>
				<div>{formatMessage(messages.resultsCountError)}</div>
				<div>
					<TryAgain onClick={handleTryAgain} />
				</div>
				<WarningIconStyled />
			</ResultsCountError>
		);
	}

	return <>{formatMessage(messages.results, { n: report.unique_users_in_segment })}</>;
}

function SubmitButton({
	handleSubmit,
	baseReport,
	isDebouncing,
}: {
	handleSubmit: () => void;
	baseReport: ApiResponse<ReportSignupsAndEngagementModular> & RefetchOption;
	isDebouncing?: boolean;
}): ReturnType<FC> {
	const { formatMessage } = useIntl();
	const { data: report, error, loading } = baseReport;

	const isDisabled =
		!!error || !!loading || !report || isDebouncing || (report.unique_users_in_segment ?? 0) < MIN_USER_LIMIT;
	return (
		<PrimaryButton onPress={handleSubmit} isDisabled={isDisabled}>
			{formatMessage(messages.apply)}
		</PrimaryButton>
	);
}

function PrivacyWarning({
	baseReport,
}: {
	baseReport: ApiResponse<ReportSignupsAndEngagementModular> & RefetchOption;
}): ReturnType<FC> {
	const { formatMessage } = useIntl();
	const { error, loading } = baseReport;

	if (loading || !error || !isReportingPrivacyError(error)) {
		return null;
	}

	return (
		<Banner backgroundColor="error" textColor="white">
			{formatMessage(messages.privacyWarning)}
		</Banner>
	);
}

export function SegmentationModal({
	onDismiss,
	onSubmit,
	partnerId,
	segmentsSelected,
	isAllOrNone,
}: {
	onDismiss: () => void;
	onSubmit: (s: SegmentSelectState) => void;
	partnerId: string;
	segmentsSelected: SegmentSelectState;
	isAllOrNone?: boolean;
}): ReactElement {
	const { formatMessage } = useIntl();
	const [selection, updateSelection] = useReducer(selectionReducer, {
		segment1: segmentsSelected.segment1 ?? undefined,
		segment2: segmentsSelected.segment2 ?? undefined,
		segment3: segmentsSelected.segment3 ?? undefined,
	});
	const [isDebouncing, setIsDebouncing] = useState(false);
	const [debouncedSelection, setDebouncedSelection] = useState<SegmentSelectState>();
	const { data: flagValues } = useFeatureFlags(B2B_UNLIMITED_SEGMENTS);
	const hideSegmentationWarning = flagValues && flagValues[B2B_UNLIMITED_SEGMENTS] === true;

	useEffect(() => {
		setIsDebouncing(true);
		const debounce = setTimeout(() => {
			setDebouncedSelection({
				segment1: selection.segment1 ? new Set(selection.segment1) : undefined,
				segment2: selection.segment2 ? new Set(selection.segment2) : undefined,
				segment3: selection.segment3 ? new Set(selection.segment3) : undefined,
			});
			setIsDebouncing(false);
		}, 500);
		return () => clearTimeout(debounce);
	}, [selection]);

	const baseReport = useBaseReport({ partnerId, segmentSelection: debouncedSelection });

	function handleSubmit(): void {
		onSubmit(selection);
	}

	function onReset(): void {
		onSubmit({
			segment1: undefined,
			segment2: undefined,
			segment3: undefined,
		});
	}

	return (
		<Modal
			isOpen
			closeModal={onDismiss}
			title={formatMessage(messages.filterDirections)}
			canClose={true}
			data-testid="segmentation-modal"
			width={ModalWidth.Ultra}
		>
			<SegmentListContainer>
				<SegmentSelection
					partnerId={partnerId}
					selection={selection}
					updateSelection={updateSelection}
					isAllOrNone={isAllOrNone}
				/>
			</SegmentListContainer>
			{!hideSegmentationWarning && <SegmentSelectionWarning partnerId={partnerId} />}
			<PrivacyWarning baseReport={baseReport} />
			{!isAllOrNone && (
				<ResultsStyled>
					<ResultsCount baseReport={baseReport} isDebouncing={isDebouncing} />
				</ResultsStyled>
			)}
			<ButtonRow>
				<SecondaryButton size={ButtonSizes.Sm} onPress={onReset}>
					{formatMessage(messages.reset)}
				</SecondaryButton>
				<SubmitButton
					handleSubmit={handleSubmit}
					baseReport={baseReport}
					isDebouncing={isDebouncing && !isAllOrNone}
				/>
			</ButtonRow>
		</Modal>
	);
}

export default function SegmentationFilter({
	partnerId,
	onSubmit,
	segmentsSelected,
	showModal,
	setShowModal,
}: {
	partnerId: string;
	onSubmit: (s: SegmentSelectState) => void;
	segmentsSelected: SegmentSelectState;
	showModal: boolean;
	setShowModal: Dispatch<SetStateAction<boolean>>;
}): ReturnType<FC> {
	const { formatMessage } = useIntl();
	const { loading, data: segmentDetails, error } = useSegmentDetails(partnerId);
	const { logEvent } = useAnalytics();
	const hasScrolled = useHasScrolled();

	const handleClick = (): void => setShowModal(true);

	const handleDismiss = (): void => setShowModal(false);

	function handleSubmit(state: SegmentSelectState): void {
		CalmCookie.set('has-used-segmentation-filter', 'true');
		const segments_used = (state.segment1 ? 1 : 0) + (state.segment2 ? 1 : 0) + (state.segment3 ? 1 : 0);
		const segment1Info =
			state.segment1 && segmentDetails?.segment_values?.segment_1_values
				? {
						segment_1_segments_selected: state.segment1.size,
						segment_1_segments_deselected:
							segmentDetails.segment_values.segment_1_values.length - state.segment1.size,
						segment_1_total_segments: segmentDetails.segment_values.segment_1_values.length,
				  }
				: null;
		const segment2Info =
			state.segment2 && segmentDetails?.segment_values?.segment_2_values
				? {
						segment_2_segments_selected: state.segment2.size,
						segment_2_segments_deselected:
							segmentDetails.segment_values.segment_2_values.length - state.segment2.size,
						segment_2_total_segments: segmentDetails.segment_values.segment_2_values.length,
				  }
				: null;
		const segment3Info =
			state.segment3 && segmentDetails?.segment_values?.segment_3_values
				? {
						segment_3_segments_selected: state.segment3.size,
						segment_3_segments_deselected:
							segmentDetails.segment_values.segment_3_values.length - state.segment3.size,
						segment_3_total_segments: segmentDetails.segment_values.segment_3_values.length,
				  }
				: null;

		logEvent('Partner Portal : Segmented Reporting : Filter Applied', {
			segments_used, // 0, 1, 2, or 3
			...segment1Info,
			...segment2Info,
			...segment3Info,
		});
		onSubmit(state);
		setShowModal(false);
	}

	if (loading || error || !hasSegments(segmentDetails)) {
		return null;
	}

	const hasUsedSegmentationFilter = CalmCookie.get('has-used-segmentation-filter') === 'true';

	const showScrolledZIndex = hasScrolled || showModal;

	return (
		<>
			{showScrolledZIndex ? <ScrolledTooltipZIndex /> : <UnscrolledTooltipZIndex />}
			<Tooltip
				placement="bottom"
				visible={!hasUsedSegmentationFilter}
				content={formatMessage(messages.announcement, {
					linebreak: <br />,
					b: (...chunks: ReactNode[]) => <b>{chunks}</b>,
					text: (...chunks: ReactNode[]) => <TooltipText>{chunks}</TooltipText>,
				})}
				maxWidth={250}
				absolutePositionedChild
			>
				<ButtonContainer>
					<FilterButton Icon={ListOutlineIcon} onPress={handleClick} data-testid="filter-by-segment-button">
						{formatMessage(messages.buttonText)}
					</FilterButton>
				</ButtonContainer>
			</Tooltip>
			{showModal && (
				<SegmentationModal
					onDismiss={handleDismiss}
					partnerId={partnerId}
					onSubmit={handleSubmit}
					segmentsSelected={segmentsSelected}
				/>
			)}
		</>
	);
}
