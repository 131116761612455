import { FC } from 'react';

import { Icon, Wrapper } from './styles';

/**
 * From Stripe Doc's: https://stripe.com/docs/api/cards/object#card_object-brand
 * Card brand. Can be `American Express`, `Diners Club`, `Discover`, `JCB`, `MasterCard`, `UnionPay`, `Visa`, or `Unknown`.
 */
export const CARD_IMAGES = {
	Visa: {
		src: 'https://assets.calm.com/3ddc4a4d25c946e8ad7e6998f30fd4e3.png',
		alt: 'VISA credit card logo',
	},
	MasterCard: {
		src: 'https://assets.calm.com/6f6cdc29ee2e22e06b1ac029cb52ef71.png',
		alt: 'MasterCard credit card logo',
	},
	'American Express': {
		src: 'https://assets.calm.com/c49a4247984b3732a4af50a3390aa978.png',
		alt: 'American Express credit card logo',
	},
	Discover: {
		src: 'https://assets.calm.com/6c0a386a00307f87db7bea366cca35f5.png',
		alt: 'Discover credit card logo',
	},
} as const;

const CreditCardIcons: FC = () => {
	return (
		<Wrapper>
			{Object.entries(CARD_IMAGES).map(([brand, { src, alt }]) => (
				<Icon key={brand} src={src} alt={alt} />
			))}
		</Wrapper>
	);
};

export default CreditCardIcons;
