import styled from 'styled-components';
import { ifProp, palette } from 'styled-tools';

import { Button, units } from '@calm-web/design-system';
import { WarningSolid } from '@calm-web/icons';

export const StyledButton = styled(Button)<{ $isSelected: boolean }>`
	border-radius: 8px;
	box-shadow: none;
	border: 2px solid ${p => (!p.$isSelected ? palette('gray1') : palette('blue3'))};
	${ifProp('$isSelected', '&:before { box-shadow: none;}')};
`;

export const MarginDiv = styled.div`
	margin-bottom: ${units(1.5)};
	display: flex;
	flex-wrap: wrap;
	gap: ${units(1)};
`;

export const WarningIcon = styled(WarningSolid).attrs({
	fontSize: units(2),
})`
	margin-left: ${units(0.75)};
`;

export const TabContent = styled.div`
	display: flex;
	align-items: center;
`;

export const FillButton = styled(Button).attrs({
	backgroundColor: 'buttonPrimaryBg',
})`
	float: right;
`;
