import { ReactElement } from 'react';
import { useIntl } from 'react-intl';

import { Button } from '@calm-web/design-system';

import { formatDate } from '@/utils/helpers';

import { PlanDetails } from '../../types';
import messages from './messages';
import { SuccessContainer, SuccessIcon, SuccessText } from './styles';

interface Props {
	onModalDismiss: () => void;
	planDetails: PlanDetails;
}

export default function Success({ onModalDismiss, planDetails }: Props): ReactElement {
	const { formatMessage } = useIntl();

	return (
		<SuccessContainer>
			<SuccessIcon />
			<SuccessText>
				{formatMessage(messages.hasReactivatedText, {
					billTotal: planDetails.total,
					billDate: formatDate(new Date(planDetails.expires)),
					coveredLives: planDetails.coveredLives,
				})}
			</SuccessText>
			<SuccessText>
				{formatMessage(messages.nextBillText, {
					billTotal: planDetails.total,
					billDate: formatDate(new Date(planDetails.expires)),
					coveredLives: planDetails.coveredLives,
				})}
			</SuccessText>

			<Button backgroundColor="blue3" onPress={onModalDismiss}>
				{formatMessage(messages.done)}
			</Button>
		</SuccessContainer>
	);
}
