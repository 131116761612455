import { ReactElement, useState } from 'react';
import { useIntl } from 'react-intl';

import { Modal } from '@calm-web/design-system';

import messages from './messages';
import { Container, PrimaryTextButton } from './styles';

interface Props {
	children: JSX.Element;
}

const EmailTemplateLink = ({ children }: Props): ReactElement => {
	const [showModal, setShowModal] = useState(false);
	const { formatMessage } = useIntl();

	const handleClick = (): void => setShowModal(true);

	return (
		<Container>
			{showModal && (
				<Modal isOpen closeModal={(): void => setShowModal(false)} title={formatMessage(messages.title)}>
					{children}
				</Modal>
			)}
			<PrimaryTextButton onClick={handleClick} type="button">
				{formatMessage(messages.showSurveySample)}
			</PrimaryTextButton>
		</Container>
	);
};

export default EmailTemplateLink;
