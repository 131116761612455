import Image from 'next/legacy/image';
import { ReactElement } from 'react';

import fauxReporting from '@/../public/_n/images/faux-reporting.png';
import { useDefinedPartner } from '@/hooks/api/usePartner';
import { isCalmHealthProductSKU } from '@/utils/SkuUtils';
import { showFauxReportingPage } from '@/utils/partner';
import srcLoader from '@/utils/ui/imageLoader';

import B2BReporting from './B2BReporting';
import CHReporting from './CHReporting';

export default function Reporting(): ReactElement {
	const partner = useDefinedPartner();

	if (showFauxReportingPage(partner.id)) {
		return (
			<div data-testid="faux-reporting-img">
				<Image src={fauxReporting} alt="" loader={srcLoader} data-testid={'hello'} />
			</div>
		);
	}

	return isCalmHealthProductSKU(partner.product_sku) ? (
		<CHReporting partner={partner} />
	) : (
		<B2BReporting partner={partner} />
	);
}
