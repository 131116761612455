import lottie from 'lottie-web';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import type { PaletteColor } from '@calm-web/design-system';

import { useInterval } from '@/hooks/useInterval';

import { messages } from './messages';
import { Bubble, BubbleContainer, BubbleCopy } from './styles';

interface Props {
	textColor?: PaletteColor;
}

const BreatheBubble: React.FC<Props> = ({ textColor = 'black' }) => {
	const { formatMessage } = useIntl();
	const breatheIn = formatMessage(messages.breatheIn);
	const breatheOut = formatMessage(messages.breatheOut);

	const [isReady, setIsReady] = useState(false);
	const [breatheCopy, setBreatheCopy] = useState(breatheIn);
	const bubbleRef = useRef<HTMLDivElement>(null);

	const interval = 5.5; // the optimal breathing interval ;)
	useInterval(
		() => {
			setBreatheCopy(breatheCopy === breatheIn ? breatheOut : breatheIn);
		},
		isReady ? interval * 1000 : null,
	);

	useEffect(() => {
		if (document?.querySelector('.bubble-lottie')) {
			const animate = lottie.loadAnimation({
				container: document.querySelector('.bubble-lottie') as Element,
				renderer: 'svg',
				loop: true,
				autoplay: true,
				path: '/_n/lottie/bubble-center.json',
			});
			setIsReady(true);
			animate.setSpeed(1.0 / interval);
		}
	}, []);

	return (
		<BubbleContainer data-testid="breathe-bubble">
			<Bubble ref={bubbleRef} className="bubble-lottie" isReady={isReady} />
			<BubbleCopy color={textColor}>{breatheCopy}</BubbleCopy>
		</BubbleContainer>
	);
};

export default BreatheBubble;
