import { ReactElement, forwardRef, Ref } from 'react';
import { useIntl } from 'react-intl';

import { ReportSession } from '@/hooks/api/reporting/useSessionsReport';
import { ReportTopContent } from '@/hooks/api/reporting/useTopContent';

import Graph from 'icons/graph.svg';

import LoadingAnimation from '../LoadingAnimation';
import ReportingPlaceholder from '../ReportingPlaceholder';
import WidgetCard from '../WidgetCard';
import { StyledCard } from '../WidgetCard/styles';
import messages from '../messages';
import { TopContentWrapper, MoreContentContainer, MoreContentSubtitle, MoreContentTitle } from '../styles';
import TopContentTable from './TopContentTable';

function TopContent(
	{
		isLoading,
		isPrivacyError,
		isNoDataError,
		isShowingSegmented,
		sessionData,
		contentData,
	}: {
		isLoading: boolean;
		isPrivacyError: boolean;
		isNoDataError: boolean;
		isShowingSegmented: boolean;
		sessionData: ReportSession[] | undefined;
		contentData: ReportTopContent | undefined;
	},
	ref?: Ref<HTMLDivElement>,
): ReactElement {
	const { formatMessage } = useIntl();

	if (isLoading) {
		return <LoadingAnimation />;
	} else if (isPrivacyError) {
		return <ReportingPlaceholder title="Top Content" graphType="privacyError" />;
	} else if (isNoDataError || !sessionData || !contentData) {
		return (
			<div data-html2canvas-ignore="true">
				<StyledCard>
					<MoreContentContainer>
						<Graph />
						<MoreContentTitle>{formatMessage(messages.moreContentTitle)}</MoreContentTitle>
						<MoreContentSubtitle>{formatMessage(messages.moreContentDescription)}</MoreContentSubtitle>
					</MoreContentContainer>
				</StyledCard>
			</div>
		);
	}

	const wrapper = (
		<TopContentWrapper>
			<TopContentTable
				sessionData={sessionData}
				contentData={contentData}
				isShowingSegmented={isShowingSegmented}
			/>
		</TopContentWrapper>
	);

	return (
		<WidgetCard
			ref={ref}
			isLoading={isLoading}
			heading="Top Content"
			subHeading="See which categories of content your employees use Calm for."
			content={wrapper}
			removeDataContainer
			isPrivacyError={isPrivacyError}
			isNoDataError={isNoDataError || !sessionData || !contentData}
		/>
	);
}

export default forwardRef(TopContent);
