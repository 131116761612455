import { useDispatch } from 'react-redux';
import useSWR from 'swr';

import { setBannerMessage } from '@/store/actions';
import { Partner } from '@/types/store/reducers';
import { isCalmError } from '@/utils/apiRequest/errors';
import { calmLogger } from '@/utils/calmLogger';

import { ApiResponse } from './types';
import { useApi } from './useApi';

export interface EapBenefit {
	id: string;
	partner_id: string;
	benefit_type: string;
	heading: string;
	subtitle: string;
	image_id: string;
	url: string;
	position: number;
}

const useEap = (partner: Partner): [revalidateBenefits: () => void, data: ApiResponse<EapBenefit[]>] => {
	const apiRequest = useApi();
	const dispatch = useDispatch();

	const endpoint = `b2b/partners/${partner.id}/benefits`;
	const { data, isValidating, error, mutate } = useSWR(
		endpoint,
		async endpoint => {
			try {
				const response = await apiRequest({ endpoint });
				return response.data?.benefits ?? response.data;
			} catch (err) {
				calmLogger.error('Error when fetching EAP benefits', {}, err);
				dispatch(
					setBannerMessage({
						message: `Error: ${(isCalmError(err) && err?.data?.error?.message) || ''}`,
						flash: true,
						isError: true,
					}),
				);
				throw err;
			}
		},
		{ revalidateOnFocus: true },
	);

	return [mutate, { data, loading: isValidating, error }];
};

export default useEap;
