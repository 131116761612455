import { defineMessages } from 'react-intl';

export default defineMessages({
	promptForAddress: {
		id: 'nav.selfserve.billing.address.prompt',
		defaultMessage:
			'In review of our records, we have determined that we do not have a current billing address for you. <link>Add billing details</link>.',
		description: 'Informs Self Serve Admins that they need to add a billing address.',
	},
});
