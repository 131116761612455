import styled from 'styled-components';
import { palette } from 'styled-tools';

import { FontSizes, Text, TextElements, units, minWidth } from '@calm-web/design-system';

export const Subtitle = styled(Text).attrs({
	el: TextElements.P,
	size: FontSizes.sm,
})``;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin: ${units(2, 0)};
	& > input {
		margin: ${units(0.5, 0)};
	}
	border: 1px solid ${palette('gray3')};
	border-radius: 8px;
	padding: ${units(0, 2)};

	${minWidth('desktop')} {
		border: none;
		padding: 0;
		flex-direction: row;
		margin: ${units(2, -0.5)};
		& > * {
			margin: ${units(0, 0.5)};
		}
		& > button {
			margin: 0;
			height: ${units(7)};
		}
	}
`;

export const FieldInfoText = styled(Text)``;

export const FieldInfo = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	${minWidth('desktop')} {
		display: unset;
		width: unset;
		order: 1;

		${FieldInfoText} {
			display: none;
		}
	}
`;
