import { ReactElement, useMemo } from 'react';

import { ChatOutline, LinkOutline, Phone } from '@calm-web/icons';

import { HealthReferralDefinitions, LinkOutType } from '@/types/health';

import ReferralOverviewPreview from '../ReferralOverviewPreview';
import {
	PreviewModal,
	ModalContent,
	HeaderBar,
	ReferralLogo,
	ReferralTitle,
	CloseButton,
	Description,
	LinkOutButton,
} from './styles';

interface LinkOutProp {
	text: string;
	type: LinkOutType;
}

const icons: Record<LinkOutType, React.FC | undefined> = {
	phone: Phone,
	sms: ChatOutline,
	url: LinkOutline,
	text: undefined,
};

const LinkOut = ({ text, type }: LinkOutProp): ReactElement => {
	return <LinkOutButton Icon={icons[type]}>{text}</LinkOutButton>;
};

const ReferralLinkoutPreview = ({
	title,
	subtitle,
	referralDefinition,
	logoSrc,
	description,
	link_out_1_text,
	link_out_1_type,
	show_link_out_2,
	link_out_2_text,
	link_out_2_type,
	show_link_out_3,
	link_out_3_text,
	link_out_3_type,
}: {
	title: string;
	subtitle: string;
	referralDefinition: HealthReferralDefinitions;
	logoSrc?: string;
	description: string;
	link_out_1_text: string;
	link_out_1_type: string;
	show_link_out_2: boolean;
	link_out_2_text: string;
	link_out_2_type: string | null;
	show_link_out_3: boolean;
	link_out_3_text: string;
	link_out_3_type: string | null;
}): ReactElement => {
	const linkOuts: LinkOutProp[] = useMemo(
		() =>
			[
				{
					text: link_out_1_text,
					type: link_out_1_type,
				},
				show_link_out_2
					? {
							text: link_out_2_text,
							type: link_out_2_type,
					  }
					: null,
				show_link_out_3
					? {
							text: link_out_3_text,
							type: link_out_3_type,
					  }
					: null,
			].filter(Boolean) as LinkOutProp[],
		[
			link_out_1_text,
			link_out_1_type,
			show_link_out_2,
			link_out_2_text,
			link_out_2_type,
			show_link_out_3,
			link_out_3_text,
			link_out_3_type,
		],
	);

	return (
		<ReferralOverviewPreview
			title={title}
			subtitle={subtitle}
			logoSrc={logoSrc}
			referralDefinition={referralDefinition}
			data-testid="referral-linkout-preview"
		>
			<PreviewModal>
				<ModalContent>
					<HeaderBar>
						{logoSrc ? <ReferralLogo src={logoSrc} data-testid="referral-linkout-logo" /> : null}
						<ReferralTitle data-testid="referral-linkout-title">{title}</ReferralTitle>
						<CloseButton />
					</HeaderBar>
					<Description data-testid="referral-linkout-description">{description}</Description>
					{linkOuts.map((linkOut, index) => (
						<LinkOut key={index} {...linkOut} />
					))}
				</ModalContent>
			</PreviewModal>
		</ReferralOverviewPreview>
	);
};

export default ReferralLinkoutPreview;
