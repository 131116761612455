import { DateTime } from 'luxon';
import querystring from 'query-string';
import { createContext, Dispatch, SetStateAction, useState, Key, PropsWithChildren } from 'react';
import { useHistory, useLocation } from 'react-router';

import { ReportMoodModular } from '@/hooks/api/reporting/useMoodReport';
import { ReportPortraitsModular } from '@/hooks/api/reporting/usePortraits';
import { ReportSignupsAndEngagementModular } from '@/hooks/api/reporting/useSignupsAndEngagement';
import { ApiResponse, RefetchOption } from '@/hooks/api/types';
import { Partner } from '@/types/store/reducers';
import { iso8601Date } from '@/utils/helpers';
import { SegmentSelectState } from '@/utils/segments';

export const timeframeValues = {
	SEVEN_DAYS: '7 days',
	TWENTY_EIGHT_DAYS: '28 days',
	TWELVE_WEEKS: '12 weeks',
	YEAR: 'year',
	CUSTOM: 'custom date range',
};

export interface ReportingButtonContainerContextState {
	dateRangePickerIsOpen: boolean;
	openDateRangePicker: Dispatch<SetStateAction<boolean>>;
}

export const ReportingButtonContainerContext = createContext<ReportingButtonContainerContextState>({
	dateRangePickerIsOpen: false,
	openDateRangePicker: () => {},
});

const twelveWeeksAgo = DateTime.local().minus({ weeks: 12 }).toJSDate() as Date;

export interface ReportingContextState {
	startDate: Date | undefined;
	endDate: Date | undefined;
	endDateToUse: Date | undefined;
	setStartDate: Dispatch<SetStateAction<Date | undefined>>;
	setEndDate: Dispatch<SetStateAction<Date | undefined>>;
	isReportDownloading: boolean;
	selectedLongerTimeframe: { value: Key; startDate: Date; endDate: Date };
	onReportingTimeframeSelection: (selected: { value: Key; startDate: Date; endDate: Date }) => void;
	isReportMocked: boolean | undefined;
	partner: Partner | undefined;
	signupsAndEngagementReport?: ApiResponse<ReportSignupsAndEngagementModular> & RefetchOption;
	moodReport?: ApiResponse<ReportMoodModular> & RefetchOption;
	portraitsReport?: ApiResponse<ReportPortraitsModular> & RefetchOption;
	scrollToWidgetViaAnchor: () => void;
	maxEndDate: string | undefined;
	updateSegmentFilter?: (state: SegmentSelectState) => void;
}

export const ReportingContext = createContext<ReportingContextState>({
	startDate: undefined,
	endDate: undefined,
	endDateToUse: undefined,
	setStartDate: () => {},
	setEndDate: () => {},
	isReportDownloading: false,
	selectedLongerTimeframe: {
		value: timeframeValues.TWELVE_WEEKS,
		startDate: twelveWeeksAgo,
		endDate: new Date(),
	},
	onReportingTimeframeSelection: (selected: { value: Key; startDate: Date; endDate: Date }) => {},
	isReportMocked: undefined,
	partner: undefined,
	signupsAndEngagementReport: undefined,
	moodReport: undefined,
	portraitsReport: undefined,
	scrollToWidgetViaAnchor: () => undefined,
	maxEndDate: undefined,
	updateSegmentFilter: (state: SegmentSelectState) => {},
});

const ReportingContextProvider = ({
	isDownloading,
	selectedLongerTimeframe,
	setSelectedLongerTimeframe,
	startDate,
	setStartDate,
	endDate,
	endDateToUse,
	setEndDate,
	isReportMocked,
	partner,
	signupsAndEngagementReport,
	moodReport,
	portraitsReport,
	scrollToWidgetViaAnchor,
	maxEndDate,
	updateSegmentFilter,
	children,
}: PropsWithChildren<{
	isDownloading: boolean;
	selectedLongerTimeframe: { value: Key; startDate: Date; endDate: Date };
	setSelectedLongerTimeframe: Dispatch<
		SetStateAction<{
			value: Key;
			startDate: Date;
			endDate: Date;
		}>
	>;
	startDate: Date | undefined;
	setStartDate: Dispatch<SetStateAction<Date | undefined>>;
	endDate: Date | undefined;
	endDateToUse: Date | undefined;
	setEndDate: Dispatch<SetStateAction<Date | undefined>>;
	isReportMocked: boolean | undefined;
	partner: Partner | undefined;
	signupsAndEngagementReport?: ApiResponse<ReportSignupsAndEngagementModular> & RefetchOption;
	moodReport?: ApiResponse<ReportMoodModular> & RefetchOption;
	portraitsReport?: ApiResponse<ReportPortraitsModular> & RefetchOption;
	scrollToWidgetViaAnchor: () => void;
	maxEndDate: string | undefined;
	updateSegmentFilter?: (state: SegmentSelectState) => void;
}>): JSX.Element => {
	const [dateRangePickerIsOpen, openDateRangePicker] = useState<boolean>(false);
	const history = useHistory();
	const { pathname, search } = useLocation();

	const setTimeframe = (timeframe: { value: Key; startDate: Date; endDate: Date }): void => {
		setSelectedLongerTimeframe(timeframe);
		const paramsDict = querystring.parse(search);
		if (timeframe.value === timeframeValues.CUSTOM) {
			paramsDict.startDate = iso8601Date(timeframe.startDate);
			paramsDict.endDate = iso8601Date(timeframe.endDate);
			delete paramsDict.timeframe;
		} else {
			paramsDict.timeframe = `${timeframe.value}`;
			delete paramsDict.startDate;
			delete paramsDict.endDate;
		}
		history.push({ pathname, search: `?${querystring.stringify(paramsDict)}` });
	};

	const reportingContextValues = {
		startDate: startDate,
		setStartDate,
		endDate,
		setEndDate,
		endDateToUse,
		isReportDownloading: isDownloading,
		selectedLongerTimeframe,
		onReportingTimeframeSelection: setTimeframe,
		isReportMocked,
		partner,
		signupsAndEngagementReport,
		moodReport,
		portraitsReport,
		scrollToWidgetViaAnchor,
		maxEndDate,
		updateSegmentFilter,
	};

	const buttonContextValue = {
		dateRangePickerIsOpen,
		openDateRangePicker,
	};

	return (
		<ReportingContext.Provider value={reportingContextValues}>
			<ReportingButtonContainerContext.Provider value={buttonContextValue}>
				{children}
			</ReportingButtonContainerContext.Provider>
		</ReportingContext.Provider>
	);
};

export default ReportingContextProvider;
