import { ReactElement, ReactNode } from 'react';
import { RouteComponentProps, useLocation, withRouter } from 'react-router';

import { useAnalytics } from '@/hooks/analytics/useAnalytics';

import { Link } from './styles';

interface Props extends RouteComponentProps {
	to: string;
	children: ReactNode;
	id: string;
	defaultId?: string;
	'data-testid'?: string;
	isActive?: boolean;
}

function AccountNavLink({
	to,
	id,
	children,
	defaultId,
	'data-testid': dataTestId,
	staticContext, // this prop causes a dom error so it must be removed
	isActive,
	...props
}: Props): ReactElement {
	const { hash } = useLocation();
	const { logEvent } = useAnalytics();

	const noHash = hash === '';
	const fallbackId = defaultId || 'details';
	const logTabClick = (): void => {
		const splitPath = to.split('#');
		const hashBasedPath = splitPath.length > 1 ? splitPath[1] : to;
		logEvent('Account : Tab Clicked', {
			tab: hashBasedPath,
		});
	};

	return (
		<Link
			{...props}
			to={to}
			id={id}
			activeClassName="selected"
			isActive={() => isActive || (noHash ? id === fallbackId : window.location.href.match(to))}
			data-testid={dataTestId}
			onClick={logTabClick}
		>
			{children}
		</Link>
	);
}

export default withRouter(AccountNavLink);
