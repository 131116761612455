import { FC, ReactElement } from 'react';

import { Loader } from '@calm-web/design-system';

import InitialPageAnalytics from '@/components/analytics/InitialPageAnalytics';
import useHealthConfig from '@/hooks/api/useHealthConfig';
import { useDefinedPartner } from '@/hooks/api/usePartner';
import { HealthConfig } from '@/types/health';
import { Partner } from '@/types/store/reducers';
import { isCalmHealthProductSKU } from '@/utils/SkuUtils';

import CreatePartner from '../CreatePartner';
import TabRouter from './TabRouter';

interface WrapperProps {
	partner?: Partner;
	healthConfig?: HealthConfig;
}

const NavVersion: FC<WrapperProps> = ({ partner, healthConfig }) => {
	if (partner && (healthConfig || !isCalmHealthProductSKU(partner.product_sku))) {
		return <TabRouter partner={partner} healthConfig={healthConfig} />;
	}

	return <CreatePartner />;
};

const PartnerAccountInfoWrapper: FC<WrapperProps> = ({ partner, healthConfig }) => {
	return (
		<>
			<InitialPageAnalytics />
			<NavVersion partner={partner} healthConfig={healthConfig} />
		</>
	);
};

export default function Account(): ReactElement {
	const partner = useDefinedPartner();
	const isCalmHealth = isCalmHealthProductSKU(partner.product_sku);
	const { data: healthConfig, loading } = useHealthConfig({
		partnerId: isCalmHealth ? partner.id : undefined,
	});

	if (loading) {
		return <Loader></Loader>;
	}

	return <PartnerAccountInfoWrapper partner={partner} healthConfig={healthConfig} />;
}

export function CreateAccount(): ReactElement {
	return <PartnerAccountInfoWrapper />;
}
