import { ReactElement } from 'react';
import { useIntl } from 'react-intl';

import { useInvoice } from '@/hooks/api/useInvoice';

import { ComparisonBody } from './ComparisonBody';
import messages from './messages';
import { ComparisonTable, Label } from './styles';

interface Props {
	previousCoveredLives: number;
	coveredLives: number;
	partnerId: string;
}

export function PriceComparison({ previousCoveredLives, coveredLives, partnerId }: Props): ReactElement {
	const { formatMessage } = useIntl();
	const { data: invoice, loading, error } = useInvoice(partnerId, previousCoveredLives);
	const {
		loading: proratedLoading,
		error: proratedError,
		data: proratedInvoice,
	} = useInvoice(partnerId, coveredLives);

	const isLoading = loading || proratedLoading;
	const hasError = !!error || !!proratedError;

	return (
		<>
			<Label as="div">{formatMessage(messages.costBreakdownLabel)}</Label>
			<ComparisonTable>
				<ComparisonBody
					isLoading={isLoading}
					hasError={hasError}
					proratedInvoice={proratedInvoice}
					invoice={invoice}
					previousCoveredLives={previousCoveredLives}
					coveredLives={coveredLives}
				/>
			</ComparisonTable>
		</>
	);
}
