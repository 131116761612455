import jwt from 'jsonwebtoken';
import qs from 'query-string';
import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { Button } from '@calm-web/design-system';
import { OnChange } from '@calm-web/use-form';

import InitialPageAnalytics from '@/components/analytics/InitialPageAnalytics';
import SimpleNav from '@/components/ui/Nav/SimpleNav';
import { Background } from '@/components/ui/Nav/styles';
import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import { useUserFeedback } from '@/hooks/api/useUserFeedback';
import { formatError } from '@/utils/analytics/dataFormatting';
import { getErrorMessage } from '@/utils/apiRequest/errors';

import messages from './messages';
import {
	CharCount,
	Container,
	Divider,
	HelpLink,
	StyledTextArea,
	Subtitle,
	TextAreaWrapper,
	Title,
	Wrapper,
} from './styles';

const UserFeedback: FC = () => {
	const [saveUserFeedback, { loading }] = useUserFeedback();
	const [partner, setPartner] = useState<{ name?: string; id?: string }>({});
	const [error, setError] = useState('');
	const [responseText, setResponseText] = useState('');
	const [showThankYouPage, setShowThankYouPage] = useState(false);
	const { search } = useLocation();
	const { formatMessage } = useIntl();
	const { logEvent } = useAnalytics();
	const query = qs.parse(search);

	function logError(errorMessage?: string): void {
		logEvent('User Feedback : Error', {
			error: errorMessage || null,
		});
	}

	async function saveFeedback(e: SyntheticEvent): Promise<void> {
		e.stopPropagation();
		e.preventDefault();
		try {
			await saveUserFeedback(responseText, query.calmjwt);
			setShowThankYouPage(true);
			logEvent('User Feedback : Success', {
				partner_id: partner.id || null,
				partner_name: partner.name || null,
			});
		} catch (err) {
			logEvent('User Feedback : Error', formatError(err));
		}
	}

	useEffect(() => {
		try {
			const { calmjwt } = query;
			if (!calmjwt) {
				throw new Error('missing token');
			}
			const { exp, partner_name, partner_id } = jwt.decode(calmjwt);
			if (exp * 1000 < Date.now()) {
				throw new Error('token expired');
			}
			if (!partner_name) {
				throw new Error('invalid jwt');
			}
			setPartner({ name: partner_name, id: partner_id });
		} catch (err) {
			setError(formatMessage(messages.error));
			setShowThankYouPage(true);
			logError(getErrorMessage(err));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleTextChange: OnChange<HTMLTextAreaElement> = e => {
		setResponseText(e.target.value);
	};

	return (
		<SimpleNav>
			<InitialPageAnalytics />
			<Background />
			<Wrapper>
				<Container>
					{showThankYouPage ? (
						<>
							<Title>{formatMessage(messages.thankYou)}</Title>
							<br />
							<Subtitle>{error || formatMessage(messages.success)}</Subtitle>
						</>
					) : (
						<form onSubmit={saveFeedback}>
							<Title>{formatMessage(messages.title, { companyname: partner.name })}</Title>
							<Subtitle>{formatMessage(messages.description)}</Subtitle>
							<Divider />
							<TextAreaWrapper>
								<StyledTextArea
									name="feedback"
									isValid
									aria-label={formatMessage(messages.placeholder)}
									placeholder={formatMessage(messages.placeholder)}
									value={responseText}
									maxLength={1000}
									onChange={handleTextChange}
								/>
								<CharCount>{1000 - responseText.length}</CharCount>
							</TextAreaWrapper>
							<Button backgroundColor="blue3" isDisabled={!responseText} type="submit" isLoading={loading}>
								{formatMessage(messages.sendFeedback)}
							</Button>
						</form>
					)}
				</Container>
				<HelpLink href="https://www.calm.com/help">{formatMessage(messages.contactSupport)}</HelpLink>
			</Wrapper>
		</SimpleNav>
	);
};

export default UserFeedback;
