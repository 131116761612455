import styled from 'styled-components';
import { palette, theme } from 'styled-tools';

import { Checkbox, FontSizes, TextButton, units } from '@calm-web/design-system';

export const Container = styled.div`
	width: 100%;
	max-width: ${theme('layout.mainMaxWidth')};
`;

export const TimestampCell = styled.div`
	white-space: nowrap;
	color: ${palette('gray5')};
	margin-left: ${units(1)};
`;

export const ExpandResponseButton = styled(TextButton).attrs({ size: FontSizes.sm, hideUnderline: true })`
	&& {
		display: block;
	}
`;

export const PinnedCheckbox = styled(Checkbox)<{ isMobile: boolean }>`
	padding: ${p => (p.isMobile ? units(1, 0) : 0)};
	padding-left: ${p => (p.isMobile ? 0 : units(2))};

	div {
		transform: translateY(20%);
	}
`;
