import copy from 'copy-to-clipboard';
import QRCode from 'qrcode';
import { FC, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { Loader, Button, TextButton, FontSizes } from '@calm-web/design-system';

import InitialPageAnalytics from '@/components/analytics/InitialPageAnalytics';
import LaunchEmailModal from '@/components/pages/Home/LaunchAnnouncementModal/LaunchEmailModal';
import CellContainer from '@/components/ui/CellContainer';
import ChecklistItem from '@/components/ui/Checklist';
import { StyledChecklist } from '@/components/ui/Checklist/styles';
import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import { useBaseReport } from '@/hooks/api/reporting/useBaseReport';
import { usePartner } from '@/hooks/api/usePartner';
import { useSaml } from '@/hooks/api/useSaml';
import { useShouldShowCustomLaunchEmail } from '@/hooks/useShouldShowCustomLaunchEmail';
import { setBannerMessage } from '@/store/actions';
import CalmCookie from '@/utils/CalmCookie';
import { hasIncompleteSsoSetup } from '@/utils/SsoUtils';
import { getErrorMessage } from '@/utils/apiRequest/errors';

import genericMessages from '../messages';
import {
	ButtonGroup,
	SetupButton,
	SetupSubtitle,
	LinkWrapper,
	DownloadIcon,
	DownloadLink,
	StepLink,
	StepTask,
} from '../styles';
import messages from './messages';
import { FadeableText } from './styles';

export default function GetSetup(): ReturnType<FC> {
	const { partnerId } = useParams();
	const history = useHistory();
	const { search } = useLocation();
	const { data: partner } = usePartner(partnerId);
	const { formatMessage } = useIntl();
	const { data: signupsReport } = useBaseReport({ partnerId });
	const { data: samlConfig } = useSaml(partnerId);
	const dispatch = useDispatch();
	const { logEvent } = useAnalytics();
	const [qrDataImage, setQrDataImage] = useState('');
	const [shouldShowUpdatedAnnouncementSection, { loading }] = useShouldShowCustomLaunchEmail();

	const url = partner?.web_renew_url;
	useEffect(() => {
		if (!url) return;
		QRCode.toDataURL(url, (err: unknown, dataUrl: string) => {
			if (err) {
				logEvent('Partner QR Code Error', {
					message: getErrorMessage(err),
				});
			}
			setQrDataImage(dataUrl);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function copySignupUrl(): void {
		logEvent('Get Set Up : Copy Sign Up Link', { type: 'SSO' });
		if (url) {
			copy(url, {
				format: 'text/plain',
				onCopy: () => {
					dispatch(
						setBannerMessage({
							message: 'Signup Link copied to clipboard',
							isError: false,
							flash: true,
						}),
					);
				},
			});
		}
	}

	const onQrCodeDownload = (): void => {
		logEvent('Get Set Up : Copy Sign Up Link', { type: 'SSO' });
	};

	const onLearnClick = (): void => {
		logEvent('Get Set Up : Visit Resources Page Clicked', { type: 'SSO' });
		CalmCookie.set('learn-complete', 'true');
		history.push({
			pathname: `/${partnerId}/resources`,
			search,
		});
	};

	if (!partner) {
		return null;
	}

	if (loading) {
		return (
			<CellContainer>
				<Loader color="gray5" />
			</CellContainer>
		);
	}

	const incompleteSsoSetup = hasIncompleteSsoSetup(partner, samlConfig);

	return (
		<CellContainer>
			<InitialPageAnalytics />
			<SetupSubtitle>{formatMessage(genericMessages.subtitle)}</SetupSubtitle>
			<StyledChecklist>
				<ChecklistItem
					stepCount={1}
					isCompleted={!incompleteSsoSetup && (signupsReport?.sign_up_count ?? 0) > 0}
					title={formatMessage(messages.testSsoConfigTitle)}
				>
					{formatMessage(messages.testSsoConfigSubtitle)}
				</ChecklistItem>
				{shouldShowUpdatedAnnouncementSection ? (
					<ChecklistItem
						stepCount={2}
						isCompleted={CalmCookie.get('announce-complete-sso') === 'true'}
						title={
							<FadeableText faded={incompleteSsoSetup}>
								{formatMessage(genericMessages.announceTitle)}
							</FadeableText>
						}
						stackActions
						action={
							<ButtonGroup>
								<Button
									backgroundColor="transparent"
									hideShadow
									textColor="blue3"
									onPress={copySignupUrl}
									isDisabled={incompleteSsoSetup}
								>
									Copy Sign-Up Link
								</Button>
								<Button
									backgroundColor="transparent"
									hideShadow
									textColor="blue3"
									href={qrDataImage}
									download
									onPress={onQrCodeDownload}
									isDisabled={incompleteSsoSetup}
									data-testid="qr-download-button"
								>
									Download QR Code
								</Button>
								<LaunchEmailModal
									isDisabled={incompleteSsoSetup}
									hideSendOption
									signupLink={partner.web_renew_url}
									partnerId={partnerId}
									announceCookieName="announce-complete-sso"
								/>
							</ButtonGroup>
						}
					>
						<FadeableText faded={incompleteSsoSetup}>{formatMessage(messages.announceSubtitle)}</FadeableText>
						{incompleteSsoSetup && <b>{formatMessage(messages.announceNoSsoUrl)}</b>}
					</ChecklistItem>
				) : (
					<ChecklistItem
						stepCount={2}
						isCompleted={CalmCookie.get('announce-complete-sso') === 'true'}
						title={
							<FadeableText faded={incompleteSsoSetup}>
								{formatMessage(genericMessages.announceTitle)}
							</FadeableText>
						}
						action={
							<LaunchEmailModal
								hideSendOption
								signupLink={partner.web_renew_url}
								partnerId={partnerId}
								announceCookieName="announce-complete-sso"
							/>
						}
					>
						<FadeableText faded={incompleteSsoSetup}>{formatMessage(messages.announceSubtitle)}</FadeableText>
						{incompleteSsoSetup ? (
							<b>{formatMessage(messages.announceNoSsoUrl)}</b>
						) : (
							<StepTask>
								<b>
									{formatMessage(genericMessages.announceLink, {
										partnerlink: (
											<LinkWrapper>
												<StepLink id="partner-signup-link" href={partner.web_renew_url}>
													{partner.web_renew_url}
												</StepLink>
												<TextButton onClick={copySignupUrl} size={FontSizes.sm} hideUnderline>
													copy
												</TextButton>
											</LinkWrapper>
										),
									})}
								</b>
								<div>Alternatively, you can distribute a QR code to direct them to your subscribe link:</div>
								{qrDataImage && (
									<DownloadLink
										data-testid="qr-download-button"
										href={qrDataImage}
										download
										onClick={onQrCodeDownload}
									>
										<DownloadIcon />
										Download QR code (.png)
									</DownloadLink>
								)}
							</StepTask>
						)}
					</ChecklistItem>
				)}
				<ChecklistItem
					stepCount={3}
					isCompleted={CalmCookie.get('learn-complete') === 'true'}
					title={formatMessage(genericMessages.learnTitle)}
					action={
						<SetupButton onPress={onLearnClick} backgroundColor="blue3">
							{formatMessage(genericMessages.learnButton)}
						</SetupButton>
					}
				>
					{formatMessage(genericMessages.learnSubtitle)}
				</ChecklistItem>
			</StyledChecklist>
		</CellContainer>
	);
}
