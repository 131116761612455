import { ReactElement } from 'react';
import { useIntl } from 'react-intl';

import { Loader } from '@calm-web/design-system';

import messages from './messages';
import { LoaderContainer, LoadingText } from './styles';

export default function Loading(): ReactElement {
	const { formatMessage } = useIntl();

	return (
		<div>
			<LoaderContainer>
				<Loader color="black" />
			</LoaderContainer>
			<LoadingText>{formatMessage(messages.loading)}</LoadingText>
		</div>
	);
}
