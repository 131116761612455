import { ReactElement, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { Button } from '@calm-web/design-system';

import { useAnalytics } from '@/hooks/analytics/useAnalytics';

import messages from './messages';
import { CoveredLives, CoveredLivesBody, CoveredLivesTitle } from './styles';

interface CoveredLivesProps {
	coveredLives: number;
	min: number;
	max: number;
}

export default function CoveredLivesMessage({ coveredLives, min, max }: CoveredLivesProps): ReactElement {
	const { formatMessage } = useIntl();
	const { logEvent } = useAnalytics();
	const title = coveredLives > max ? messages.maxTitle : messages.minTitle;
	const body =
		coveredLives > max
			? formatMessage(messages.maxBody, {
					maxNum: max,
			  })
			: formatMessage(messages.minBody);
	const buttonText = coveredLives > max ? messages.maxButtonText : messages.minButtonText;
	const url = coveredLives > max ? 'https://business.calm.com/book-a-demo/' : 'https://www.calm.com';
	const buttonEvent = coveredLives > max ? 'Teams : Demo Request : Clicked' : 'Teams : Calm.com : Clicked';

	async function logSalesInquiryEvent(): Promise<boolean> {
		await logEvent(buttonEvent);
		return true;
	}

	useEffect(() => {
		if (coveredLives > max) {
			logEvent('Teams : Covered Lives : Exceeded');
		}
		if (coveredLives < min) {
			logEvent('Teams : Covered Lives : Minimum not met');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<CoveredLives>
			<CoveredLivesTitle>{formatMessage(title)}</CoveredLivesTitle>
			<CoveredLivesBody>{body}</CoveredLivesBody>
			<Button
				onPress={logSalesInquiryEvent}
				href={url}
				backgroundColor="blue3"
				type="button"
				data-testid="book-a-demo"
			>
				{formatMessage(buttonText)}
			</Button>
		</CoveredLives>
	);
}
