import styled, { css } from 'styled-components';
import { ifProp, palette } from 'styled-tools';

export const FadeableText = styled.div<{ faded?: boolean }>`
	${ifProp(
		'faded',
		css`
			color: ${palette('gray4')};
		`,
	)}
`;
