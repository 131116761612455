import { AxiosResponse } from 'axios';
import { DateTime } from 'luxon';
import useSWR from 'swr';

import { useApi } from '@/hooks/api';
import { SegmentSelectState, segmentsToJSON } from '@/utils/segments';

import { ApiResponse, RefetchOption } from '../types';
import { ReportingAggregationLevel } from './utils';

export interface ReportSessionStats {
	total_sessions: number;
	longest_streak: number;
	mindful_days: number;
	mindful_minutes: number;
	total_shared_meditations: number;
	average_session_length: number;
}

export interface ReportPortraits {
	power_users: number | null;
	casual_users: number | null;
}

export interface ReportPortraitsModular {
	partner_id: string;
	start_date?: string;
	end_date?: string;
	aggregation_level?: ReportingAggregationLevel;
	updated_at?: string;
	is_mocked?: boolean;
	session_stats: ReportSessionStats;
	user_portraits: ReportPortraits;
}

export function usePortraits({
	partnerId,
	segmentSelection,
	startDate = DateTime.local().minus({ weeks: 12 }).toJSDate(),
	endDate,
}: {
	partnerId: string;
	startDate?: Date;
	endDate?: Date;
	segmentSelection?: SegmentSelectState;
}): ApiResponse<ReportPortraitsModular> & RefetchOption {
	const apiRequest = useApi();

	const [segmentFilter, swrString] = segmentsToJSON(segmentSelection, startDate, endDate);

	const endpoint = `b2b/partners/${partnerId}/reporting/session-stats`;

	const { data, error, mutate } = useSWR<AxiosResponse | undefined>(
		`${endpoint}${swrString}`,
		async () => {
			if (!endDate) {
				return undefined;
			}
			const response = await apiRequest({ endpoint, method: 'POST', body: segmentFilter });
			return response;
		},
		{ errorRetryCount: 0 },
	);
	if (!endDate) {
		return {
			data: undefined,
			error,
			loading: true,
			refetch: () => {},
		};
	}
	const apiReport = data?.data.report as ReportPortraitsModular | undefined;
	const refetch = (): Promise<AxiosResponse | undefined> => mutate(undefined, true);
	return {
		data: apiReport,
		error,
		loading: !data && !error,
		refetch,
	};
}
