import { FC } from 'react';
import { useParams } from 'react-router-dom';

import InitialPageAnalytics from '@/components/analytics/InitialPageAnalytics';
import { useIntegrationType } from '@/hooks/api/useIntegrationType';
import { usePartner } from '@/hooks/api/usePartner';
import { IntegrationType } from '@/types/store/reducers';

import GetSetupEligibilityFileOrAccessCodes from './GetSetupEligibilityFileOrAccessCodes';
import GetSetupSSO from './GetSetupSSO';

export default function GetSetup(): ReturnType<FC> {
	const { partnerId } = useParams();
	const { data: partner } = usePartner(partnerId);
	const { data: integrationType } = useIntegrationType(partner?.id);
	if (!partner || !integrationType) {
		return null;
	}
	if (integrationType === IntegrationType.SSO) {
		return (
			<>
				<InitialPageAnalytics />
				<GetSetupSSO />
			</>
		);
	}
	return (
		<>
			<InitialPageAnalytics />
			<GetSetupEligibilityFileOrAccessCodes />
		</>
	);
}
