import { useDispatch } from 'react-redux';
import useSWR, { mutate } from 'swr';

import { useApi } from '@/hooks/api';
import { setBannerMessage } from '@/store/actions';
import { ActionPlanItem, ActionPlanResponse, GroupedItemState } from '@/types/actionPlan';

import { ApiResponse } from './types';

export enum ActionPlanErrors {
	UnableToFetchActionPlans = 'unable_to_fetch_partner_action_plans',
}

function sortIncompleteActionItems(responseObj: GroupedItemState): GroupedItemState {
	responseObj.incomplete.sort((a, b) => a.sort - b.sort);
	return responseObj;
}

function updateResponseObject(item: ActionPlanItem, responseObj: GroupedItemState): void {
	if (item.state === 'skipped' && responseObj.skipped) {
		responseObj.skipped.push(item);
	} else if (item.state === 'completed') {
		responseObj.completed.push(item);
	} else {
		responseObj.incomplete.push(item);
	}
}

export function useActionPlans(partnerId: string): ApiResponse<ActionPlanResponse> {
	const apiRequest = useApi();
	const dispatch = useDispatch();
	const baseEndpoint = `b2b/partners/${partnerId}/milestones/action_plans`;

	const { data, error } = useSWR(baseEndpoint, async endpoint => {
		if (!partnerId) {
			return;
		}
		try {
			const response = await apiRequest({
				endpoint,
				method: 'GET',
			});
			if (!response.data) {
				throw new Error(ActionPlanErrors.UnableToFetchActionPlans);
			}

			return response.data;
		} catch (responseError) {
			dispatch(
				setBannerMessage({
					message: `Oops! We were unable to obtain your action plan at this time.`,
					isError: true,
					flash: true,
				}),
			);

			throw responseError;
		}
	});

	if (!data) {
		return {
			data: undefined,
			loading: !error,
			error,
		};
	}

	// Can't 'skip' Get Setup items
	const getSetupStatuses: GroupedItemState = {
		incomplete: [],
		completed: [],
	};
	const actionPlanStatuses: GroupedItemState = {
		incomplete: [],
		skipped: [],
		completed: [],
	};

	const actionPlanItems = data?.action_plans || [];

	actionPlanItems.forEach((item: ActionPlanItem) => {
		if (item.category === 'GET_SET_UP') {
			updateResponseObject(item, getSetupStatuses);
		} else {
			updateResponseObject(item, actionPlanStatuses);
		}
	});

	const sortedGetSetupStatuses = sortIncompleteActionItems(getSetupStatuses);
	const sortedActionPlanStatuses = sortIncompleteActionItems(actionPlanStatuses);

	return {
		data: {
			getSetupStatuses: sortedGetSetupStatuses,
			actionPlanStatuses: sortedActionPlanStatuses,
			getSetupComplete: !getSetupStatuses.incomplete.length,
			action_plans: actionPlanItems,
		},
		error: undefined,
		loading: false,
	};
}

export function clearActionPlansCache(partnerId: string): Promise<undefined> {
	return mutate(`b2b/partners/${partnerId}/milestones/action_plans`, undefined, true);
}
