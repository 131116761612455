import styled from 'styled-components';

import { fontSize, FontSizes, fontWeight, FontWeights, units } from '@calm-web/design-system';

export const RadioButtonWrapper = styled.div`
	display: block;
	width: 100%;
`;

export const IndentedParagraph = styled.div`
	margin-left: ${units(4)};
	margin-bottom: ${units(3)};
`;

export const UserIdentifierLabel = styled.label`
	${fontSize(FontSizes.sm)}
	${fontWeight(FontWeights.Medium)};
	margin-bottom: ${units(1)};
`;
