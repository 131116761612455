import { useFeatureFlags } from './api/useFeatureFlags';

const B2B_CUSTOM_LAUNCH_ANNOUNCEMENT = 'b2b-custom-launch-announcement';

export type ShouldShowCustomLaunchEmail = boolean | string | undefined;
interface FlagLoadingState {
	loading: boolean;
}

type Response = [ShouldShowCustomLaunchEmail, FlagLoadingState];

export const useShouldShowCustomLaunchEmail = (): Response => {
	const {
		data: flagValues,
		error: flagError,
		loading: flagLoading,
	} = useFeatureFlags(B2B_CUSTOM_LAUNCH_ANNOUNCEMENT);
	const flagsLoaded = !flagLoading && !flagError && flagValues;
	const customLaunchAnnouncementFF =
		flagValues && (flagValues[B2B_CUSTOM_LAUNCH_ANNOUNCEMENT] as boolean | undefined);
	const shouldShowCustomLaunchEmail = flagsLoaded && customLaunchAnnouncementFF;

	return [shouldShowCustomLaunchEmail, { loading: flagLoading }];
};
