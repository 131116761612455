import { defineMessages } from 'react-intl';

export default defineMessages({
	title: {
		id: 'users.eligibility.logs.table.title',
		defaultMessage: '{numberOfLogs} Logs',
		description: 'The title of the table',
	},
	dateHeader: {
		id: 'users.eligibility.logs.table.dateHeader',
		defaultMessage: 'Date of upload',
		description: 'The title of the date column in the table',
	},
	statusHeader: {
		id: 'users.eligibility.logs.table.statusHeader',
		defaultMessage: 'Status',
		description: 'The title of the status column in the table',
	},
	fileNameHeader: {
		id: 'users.eligibility.logs.table.fileNameHeader',
		defaultMessage: 'File Name',
		description: 'The title of the file name column in the table',
	},
	rowsHeader: {
		id: 'users.eligibility.logs.table.rowsHeader',
		defaultMessage: 'Rows',
		description: 'The title of the rows column in the table',
	},
	descriptionHeader: {
		id: 'users.eligibility.logs.table.descriptionHeader',
		defaultMessage: 'Description',
		description: 'The title of the description column in the table',
	},
	actionHeader: {
		id: 'users.eligibility.logs.table.actionHeader',
		defaultMessage: 'Action',
		description: 'The title of the actioncolumn in the table',
	},
	processingMessage: {
		id: 'users.eligibility.logs.table.processingMessage',
		defaultMessage: 'Processing...',
		description: 'The message displayed when the logs are being processed',
	},
	successfulUploadMessage: {
		id: 'users.eligibility.logs.table.successfulUploadMessage',
		defaultMessage: 'Successful upload',
		description: 'The message displayed when the logs uploaded successfully',
	},
	partialSuccessMessage: {
		id: 'users.eligibility.logs.table.partialSuccessMessage',
		defaultMessage: 'Partially successful upload',
		description: 'The message displayed when the logs uploaded partially successfully',
	},
	detailsLink: {
		id: 'users.eligibility.logs.table.detailsLink',
		defaultMessage: 'Details',
		description: 'The link to get to the log details page',
	},
	errorDescription: {
		id: 'users.eligibility.logs.details.errorDescription',
		defaultMessage: '<bold>{errorName}</bold>: {errorMessage}',
		description: 'header for the description',
	},
});
