import { defineMessages } from 'react-intl';

export default defineMessages({
	tooltipTitle: {
		id: 'reporting.hourlyUsage.tooltipTitle',
		defaultMessage: '{starttime} - {endtime} employee’s local time.',
		description: 'Tooltip explaining what data range you are hovering over',
	},
	tableHeader: {
		id: 'reporting.hourlyUsage.tableHeader',
		defaultMessage: 'Here’s how your employees are using Calm throughout the day',
		description: "Header text introducing a chart showing hourly usage of Calm by the company's employees",
	},
	tableSubtitleDynamic: {
		id: 'reporting.hourlyUsage.tableSubtitleDynamic',
		defaultMessage: 'Sessions over {timeframe} by content type and time of day in employee’s local time.',
		description: "Explainer text introducing a chart showing hourly usage of Calm by the company's employees",
	},
	tableInfoTooltip: {
		id: 'reporting.hourlyUsage.tableInfoTooltip',
		defaultMessage:
			'If an employee in New York uses Calm at 8am eastern, then an employee uses Calm at 8am Pacific, we count 2 sessions in the 8am column.',
		description: "Info tooltip explaining a chart showing hourly usage of Calm by the company's employees",
	},
});
