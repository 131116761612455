import Image from 'next/legacy/image';
import { FC, useEffect } from 'react';

import { Card, Text, FormSelect, FormInput, Button } from '@calm-web/design-system';
import { stringFromModelValue } from '@calm-web/use-form';

import CellTitle from '@/components/ui/CellTitle';
import { EapBenefit } from '@/hooks/api/useEap';
import {
	ThemeOptions,
	getThemeUrl,
	themeSelectOptions,
	useDeleteEap,
	useEapForm,
	useSubmitEapForm,
} from '@/hooks/forms/useEapForm';
import { Partner } from '@/types/store/reducers';
import { srcLoader } from '@/utils/ui/imageLoader';

import StickySaveButton from '../Account/StickySaveButton';
import { Wrapper, FieldContainer, ImageWrapper, StyledLoader, Row } from './styles';

interface Props {
	partner: Partner;
	imageUrl: string;
	setImageUrl: (themUrl: string) => void;
	revalidateBenefits: () => void;
	isShowingPartnerSave: boolean;
	eapData?: EapBenefit[];
}

const EapPromotion: FC<Props> = ({
	partner,
	setImageUrl,
	imageUrl,
	eapData,
	revalidateBenefits,
	isShowingPartnerSave,
}) => {
	const [submitEapForm, { loading }] = useSubmitEapForm();
	const { formProps, isEapFormDirty, hasTouchedEapForm } = useEapForm(eapData);
	const [deleteEap] = useDeleteEap(partner);

	const hasSavedEap = !!eapData?.length;

	const onSubmit = async (): Promise<void> => {
		await submitEapForm(formProps, partner, eapData);
		revalidateBenefits();
	};

	useEffect(() => {
		const themeUrl = getThemeUrl(formProps?.model?.theme as ThemeOptions);
		setImageUrl(themeUrl);
	}, [formProps?.model?.theme, setImageUrl]);

	const removeEap = async (): Promise<void> => {
		const eapId = stringFromModelValue(formProps.model.id);
		await deleteEap(eapId);
		revalidateBenefits();
	};

	return (
		<section>
			<Card>
				<Row>
					<CellTitle>Promote your EAP</CellTitle>
					{hasSavedEap && (
						<Button hideShadow textColor="errorRed" backgroundColor="transparent" onPress={removeEap}>
							Remove EAP Promotion
						</Button>
					)}
				</Row>
				<Wrapper>
					<ImageWrapper>
						<StyledLoader color="gray5" />
						<Image src={imageUrl} alt="EAP image theme" width={240} height={160} loader={srcLoader} />
					</ImageWrapper>
					<FieldContainer>
						<Text el="span" color="gray7">
							Promote your employee benefits in the For Work section within the Calm app.
						</Text>
						<FormSelect
							{...formProps.bindWithErrorProps('theme', 'select', true)}
							aria-label="Theme"
							placeholder="Theme"
							options={themeSelectOptions}
							data-testid="eap-theme-select"
						/>
						<FormInput
							{...formProps.bindWithErrorProps('url', 'text', true)}
							label="URL"
							data-testid="eap-benefit-url"
						/>
					</FieldContainer>
				</Wrapper>
			</Card>
			<StickySaveButton
				isFormDirty={isEapFormDirty}
				hasTouchedForm={hasTouchedEapForm}
				isLoading={loading}
				onPress={onSubmit}
				isDisabled={!formProps.validation.isValid}
				accountForSecondButton={isShowingPartnerSave}
			>
				Save EAP Configuration
			</StickySaveButton>
		</section>
	);
};

export default EapPromotion;
