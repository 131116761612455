import styled from 'styled-components';

import { units } from '@calm-web/design-system';

export const SubmitWrapper = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	margin-bottom: ${units(3)};
`;
