import { ReactElement } from 'react';

import { Card, RadioGroup } from '@calm-web/design-system';
import { BindWithErrorProps } from '@calm-web/use-form';

import CellTitle from '@/components/ui/CellTitle';
import { usePermissions } from '@/hooks/auth/usePermissions';

interface Props {
	salesTrialProps: ReturnType<BindWithErrorProps<string>>;
	isEdit: boolean;
}

export default function DemoStatus({ salesTrialProps, isEdit }: Props): ReactElement | null {
	const [hasValidPermissions, actions] = usePermissions();
	const requiredPermissions = isEdit ? [actions.UPDATE] : [actions.CREATE];
	const shouldShowSlaesTrialToggle = hasValidPermissions('is_sales_trial', requiredPermissions);
	if (!shouldShowSlaesTrialToggle) {
		return null;
	}

	const options = [
		{
			value: true.toString(),
			id: 'SalesTrial',
			label: 'IS Sales Trial Partner',
		},
		{
			value: false.toString(),
			id: 'notSalesTrial',
			label: 'IS NOT Sales Trial Partner',
		},
	];
	return (
		<Card>
			<CellTitle showAdminFlag>Sales Trial</CellTitle>
			<RadioGroup
				{...salesTrialProps}
				options={options}
				asCards
				aria-label="Is this partner a sales trial partner?"
				display="flex"
			/>
		</Card>
	);
}
