import { rgba } from 'polished';
import styled, { css } from 'styled-components';
import { palette } from 'styled-tools';

import { fontWeight, FontWeights, fontSize, FontSizes, minWidth, units } from '@calm-web/design-system';

export const StepItemButton = styled.button<{ active: boolean; visited?: boolean; disabled: boolean }>`
	background: ${palette('gray1')};
	color: ${palette('gray6')};
	width: 100%;
	text-align: center;
	${fontWeight(FontWeights.Medium)};
	${fontSize(FontSizes.sm)};
	padding: ${units(1)};
	border: none;
	border-right: 1px solid ${palette('gray2')};
	cursor: ${p => (p.disabled ? 'not-allowed' : 'pointer')};

	${p =>
		p.visited &&
		css`
			color: ${palette('blue3')};
			background: ${rgba(palette('blue3')(p), 0.16)};
		`};
	${p =>
		p.active &&
		css`
			color: ${palette('white')};
			background: ${palette('blue3')};
		`};

	${minWidth('tablet')} {
		padding: ${units(2)};
		${fontSize(FontSizes.base)};
	}
`;

export const StepNav = styled.nav`
	display: flex;
	width: 100%;
	margin-bottom: ${units(2)};
	> :first-child {
		border-top-left-radius: ${units(2)};
		border-bottom-left-radius: ${units(2)};
	}
	> :last-child {
		border-top-right-radius: ${units(2)};
		border-bottom-right-radius: ${units(2)};
	}
`;
