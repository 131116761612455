import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { Loader, Text, FontSizes, FontWeights } from '@calm-web/design-system';

import { useBaseReport } from '@/hooks/api/reporting/useBaseReport';
import { aggregationLevelToTimeUnit } from '@/hooks/api/reporting/utils';
import { useDefinedPartner } from '@/hooks/api/usePartner';
import friendlyPercentage from '@/utils/friendlyPercentage';

import EmptyStateBlock from '../EmptyStateBlock';
import StatsTile from './StatsTile';
import messages from './messages';
import { ClockIcon, Row } from './styles';

const Stats: FC = () => {
	const partner = useDefinedPartner();
	const { formatMessage } = useIntl();
	const { data: signupsReport, loading: signupsLoading } = useBaseReport({ partnerId: partner.id });
	const [deltaLabel, setDeltaLabel] = useState<string | undefined>();

	const {
		activity_report,
		aggregation_level,
		sign_up_count,
		sign_up_count_at_start,
		sign_up_start_to_end_change_percentage,
	} = signupsReport || {};
	const { signups } = activity_report || {};

	useEffect(() => {
		if (signups && aggregation_level) {
			setDeltaLabel(`${signups.length} ${aggregationLevelToTimeUnit[aggregation_level]} change`);
		}
	}, [
		signups,
		aggregation_level,
		sign_up_count,
		sign_up_count_at_start,
		sign_up_start_to_end_change_percentage,
	]);

	if (signupsLoading) {
		return <Loader color="gray5" />;
	}

	if (!signupsReport) {
		return (
			<>
				<Text el="h2" size={FontSizes.lg} weight={FontWeights.Medium}>
					{formatMessage(messages.sectionHeading)}
				</Text>
				<EmptyStateBlock
					Icon={ClockIcon}
					primaryText={formatMessage(messages.heading)}
					secondaryText={formatMessage(messages.subheading)}
				/>
			</>
		);
	}

	return (
		<>
			<Text el="h2" size={FontSizes.lg} weight={FontWeights.Medium}>
				{formatMessage(messages.sectionHeading)}
			</Text>
			<Row>
				<StatsTile
					href="reporting#signup"
					title="Sign-ups"
					stat={signupsReport.sign_up_count?.toLocaleString() || '--'}
					deltaChange={signupsReport.sign_up_start_to_end_change_actual}
					deltaLabel={deltaLabel}
				/>
				<StatsTile
					href="reporting#signupRate"
					title="Total sign-up rate"
					stat={`${friendlyPercentage(signupsReport.sign_up_rate) || '--'}%`}
					deltaChange={signupsReport.sign_up_start_to_end_change_percentage}
					deltaLabel={deltaLabel}
					showDeltaAsPercentage
				/>
				<StatsTile
					href="reporting#engagementRate"
					title="Total engagement rate"
					stat={`${friendlyPercentage(signupsReport.engage_rate) || '--'}%`}
					deltaChange={signupsReport.engaged_start_to_end_change_percentage}
					deltaLabel={deltaLabel}
					showDeltaAsPercentage
				/>
				<StatsTile
					href="reporting#activelyEngaged"
					title="Engaged users"
					stat={signupsReport.engaged_count?.toLocaleString() || '--'}
					deltaChange={signupsReport.engaged_start_to_end_change_actual}
					deltaLabel={deltaLabel}
				/>
			</Row>
		</>
	);
};

export default Stats;
