import { ReactElement, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { ResultsCount } from '@/components/pages/Reporting/SegmentationFilter';
import { PathwaysContext } from '@/components/providers/PathwaysContextProvider';
import { usePathwaysCohortDetails } from '@/hooks/api/usePathways';
import { SegmentSelectState } from '@/utils/segments';

import { SegmentListContainer } from '../../../Reporting/SegmentationFilter/styles';
import SegmentList from './SegmentList';
import messages from './messages';
import { Wrapper, Tab, TabHeader, SegmentContainer, SegmentDescription, ResultsStyled } from './styles';

export interface SelectionObject {
	segment1: Set<string> | undefined;
	segment2: Set<string> | undefined;
	segment3: Set<string> | undefined;
}

function Audience({ canSeeCohorts }: { canSeeCohorts?: boolean }): ReactElement | null {
	const { formatMessage } = useIntl();
	const {
		partnerId,
		segment1Cohorts,
		segment2Cohorts,
		segment3Cohorts,
		setHasNoCohortsSelected,
		setCohortCount,
	} = useContext(PathwaysContext);

	const [isDebouncing, setIsDebouncing] = useState<boolean>(false);
	const [debouncedSelection, setDebouncedSelection] = useState<SegmentSelectState>();
	const [selection, updateSelection] = useState<SelectionObject>({
		segment1: segment1Cohorts
			? segment1Cohorts.length > 0
				? new Set(segment1Cohorts)
				: new Set()
			: undefined,
		segment2: segment2Cohorts
			? segment2Cohorts.length > 0
				? new Set(segment2Cohorts)
				: new Set()
			: undefined,
		segment3: segment3Cohorts
			? segment3Cohorts.length > 0
				? new Set(segment3Cohorts)
				: new Set()
			: undefined,
	});

	// Update total user count on change
	useEffect(() => {
		setIsDebouncing(true);
		const debounce = setTimeout(() => {
			setDebouncedSelection({
				segment1: selection.segment1 ? new Set(selection.segment1) : undefined,
				segment2: selection.segment2 ? new Set(selection.segment2) : undefined,
				segment3: selection.segment3 ? new Set(selection.segment3) : undefined,
			});
			setIsDebouncing(false);
		}, 500);
		return () => clearTimeout(debounce);
	}, [selection]);

	const cohortDetails = usePathwaysCohortDetails({ partnerId, segmentSelection: debouncedSelection });

	useEffect(() => {
		const size = cohortDetails?.data?.size ?? 0;
		setCohortCount(size);

		const isEmpty =
			selection?.segment1?.size === 0 && selection?.segment2?.size === 0 && selection?.segment3?.size === 0;
		const noCohortsSelected = !cohortDetails?.data?.size || isEmpty;
		setHasNoCohortsSelected(noCohortsSelected);
	}, [
		cohortDetails,
		selection?.segment1?.size,
		selection?.segment2?.size,
		selection?.segment3?.size,
		setCohortCount,
		setHasNoCohortsSelected,
	]);

	if (!canSeeCohorts) return null;

	return (
		<Wrapper>
			<Tab isActive>
				<TabHeader>{formatMessage(messages.segmentHeader)}</TabHeader>
			</Tab>
			<SegmentContainer>
				<SegmentDescription>{formatMessage(messages.segmentDescription)}</SegmentDescription>
				<SegmentListContainer>
					<SegmentList selection={selection} updateSelection={updateSelection} />
				</SegmentListContainer>
				<ResultsStyled>
					<ResultsCount
						baseReport={{
							data: { unique_users_in_segment: cohortDetails.data?.size },
							loading: cohortDetails.loading,
							refetch: cohortDetails.refetch,
						}}
						isDebouncing={isDebouncing}
					/>
				</ResultsStyled>
			</SegmentContainer>
		</Wrapper>
	);
}

export default Audience;
