import { useEffect, useCallback, useRef, useState, RefObject } from 'react';

import { useAnalytics } from '@/hooks/analytics/useAnalytics';

const BASELINE_SECONDS = 2; // minimum time to spend before logging

export function useHoverTracking({
	loggingPrefix,
	containerRef,
	targetRefs,
}: {
	loggingPrefix: string;
	containerRef: RefObject<HTMLDivElement>;
	targetRefs: { name: string; ref: RefObject<HTMLDivElement> }[];
}): void {
	const { logEvent } = useAnalytics();

	const parentElement = containerRef?.current;

	const [initializedListeners, setInitializedListeners] = useState<{ [key: string]: boolean }>({});
	const parentEnterTime = useRef<Date | null>(null);
	const childEnterTime = useRef<{ [key: string]: Date }>({});
	// A value in milliseconds
	const targetElementTimeSpent = useRef<{ [key: string]: number }>({});

	// Returns the number of milliseconds between the 2 times
	function getTimeDiff(laterDate: Date, earlierDate: Date): number {
		return laterDate.getTime() - earlierDate.getTime();
	}

	// Round to the nearest 10th (e.g. 2.5 seconds)
	function getSecondsRounded(timeInMilliseconds: number): number {
		return parseFloat((timeInMilliseconds / 1000).toFixed(1));
	}

	function clearCurrentState(): void {
		targetElementTimeSpent.current = {};
		childEnterTime.current = {};
		parentEnterTime.current = null;
	}

	// Outer in
	const onEnterParent = useCallback(() => {
		parentEnterTime.current = new Date();
	}, []);
	// Inner in
	const onEnterChild = useCallback(
		(name: string) => {
			childEnterTime.current[name] = new Date();
		},
		[childEnterTime],
	);

	// Inner out
	const onLeaveChild = useCallback(
		(name: string) => {
			if (childEnterTime.current[name]) {
				targetElementTimeSpent.current[name] =
					targetElementTimeSpent.current[name] ?? 0 + getTimeDiff(new Date(), childEnterTime.current[name]);
			}
		},
		[childEnterTime, targetElementTimeSpent],
	);

	// Outer out
	const onLeaveParent = useCallback(() => {
		if (parentEnterTime.current && Object.keys(targetElementTimeSpent.current).length) {
			const timeSpentInSeconds = getSecondsRounded(getTimeDiff(new Date(), parentEnterTime.current));
			if (timeSpentInSeconds > BASELINE_SECONDS) {
				Object.keys(targetElementTimeSpent.current).forEach(targetName => {
					const targetTimeSpentSeconds = getSecondsRounded(targetElementTimeSpent.current[targetName]);
					logEvent(`${loggingPrefix} : ${targetName} : Hover Time`, {
						timeInSeconds: targetTimeSpentSeconds,
					});
				});
			}
		}
		clearCurrentState();
	}, [logEvent, loggingPrefix, parentEnterTime, targetElementTimeSpent]);

	useEffect(() => {
		if (parentElement) {
			removeEventListener('mouseenter', onEnterParent);
			removeEventListener('mouseleave', onLeaveParent);
			parentElement.addEventListener('mouseenter', onEnterParent);
			parentElement.addEventListener('mouseleave', onLeaveParent);
			setInitializedListeners(listeners => ({
				...listeners,
				parent: true,
			}));
		}
	}, [parentElement, onEnterParent, onLeaveParent]);

	const validRefs = targetRefs.filter(target => target.ref.current !== null);

	useEffect(() => {
		// mouse enter event first, mouse leave event second
		const listeners: Array<[EventListener, EventListener]> = [];
		if (validRefs.length) {
			targetRefs.forEach(target => {
				if (!initializedListeners[target.name]) {
					const mouseEnterListener = (): void => onEnterChild(target.name);
					const mouseLeaveListener = (): void => onLeaveChild(target.name);
					target.ref.current?.addEventListener('mouseenter', mouseEnterListener);
					target.ref.current?.addEventListener('mouseleave', mouseLeaveListener);
					listeners.push([mouseEnterListener, mouseLeaveListener]);
					setInitializedListeners({
						...initializedListeners,
						[target.name]: true,
					});
				}
			});
		}
	}, [validRefs.length, targetRefs, onEnterChild, onLeaveChild, initializedListeners]);
}
