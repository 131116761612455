import { useDispatch } from 'react-redux';
import useSWR, { mutate } from 'swr';

import { setBannerMessage } from '@/store/actions';
import { BillingHistoryI } from '@/types/billingHistory';

import { ApiResponse } from './types';
import { useApi } from './useApi';

export function useBillingHistory(partnerId: string): ApiResponse<BillingHistoryI[]> {
	const apiRequest = useApi();
	const dispatch = useDispatch();

	const { data, isLoading, error } = useSWR(
		`b2b/partners/${partnerId}/billing_history`,
		async endpoint => {
			try {
				const response = await apiRequest({ endpoint });
				return response.data.billing_history;
			} catch (responseError) {
				dispatch(
					setBannerMessage({
						message: 'Failed to retrieve stripe billing history',
						isError: true,
						flash: true,
					}),
				);
				throw responseError;
			}
		},
		{ errorRetryCount: 0 },
	);

	return {
		data,
		error,
		loading: isLoading,
	};
}

export function updateBillingHistoryCache(partnerId: string): Promise<boolean | undefined> {
	return mutate(`b2b/partners/${partnerId}/billing_history`);
}
