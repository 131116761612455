/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

export default defineMessages({
	cancelButton: {
		id: 'integrationDetails.cancelButton',
		defaultMessage: 'Cancel',
		description: 'Text for button canceling integration change',
	},
	eligibilityFileLabel: {
		id: 'integrationDetails.eligibilityFile.label',
		defaultMessage: 'Eligibility file configuration',
		description: 'Label for eligibility file option in integration type',
	},
	eligibilityFileSubtitle: {
		id: 'integrationDetails.eligibilityFile.subtitle',
		defaultMessage:
			'Eligible employees will authenticate by entering a unique ID supplied in a .csv or .xlsx file.',
		description: 'Subtitle for eligibility file option in integration type',
	},
	uniqueIdTitle: {
		id: 'integrationDetails.eligibilityFile.uniqueIdTitle',
		defaultMessage: 'Unique ID type',
		description: 'Title for unique id type selection',
	},
	accessCodesLabel: {
		id: 'integrationDetails.accessCodesLabel.label',
		defaultMessage: 'Access code configuration',
		description: 'Label for access codes option in integration type',
	},
	accessCodesSubtitle: {
		id: 'integrationDetails.accessCodesLabel.subtitle',
		defaultMessage:
			'<b>Calm-created access codes</b> is an automated method and doesn’t require configuration.',
		description: 'Subtitle for access codes option in integration type',
	},
	accessCodesDescription: {
		id: 'integrationDetails.accessCodesLabel.description',
		defaultMessage:
			'Access codes will be generated for every contracted covered life: if the customer contracts 150 covered lives, 150 access codes will be created and listed on the access codes page.',
		description: 'Description of the access codes in integration type',
	},
	groupCodesLabel: {
		id: 'integrationDetails.groupCodes.label',
		defaultMessage: 'Group code',
		description: 'Label for group codes option in integration type',
	},
	groupCodesSubtitle: {
		id: 'integrationDetails.groupCodes.subtitle',
		defaultMessage:
			'Create one unique redemption code for your covered lives to redeem via \u201cLink Subscription\u201d located in the Calm app\u2019s settings. When the covered lives limit is reached, no more users can be added.',
		description: 'Subtitle for group code option in integration type',
	},
	subscriptionAccessModelSubtitle: {
		id: 'integrationDetails.subscriptionAccessModelLabel.subtitle',
		defaultMessage:
			'Choose how the partner would like to provide access to their covered lives. Changing the access model may reset your integration settings below.',
		description: 'Subtitle for subscription access model',
	},
	ssoLabel: {
		id: 'integrationDetails.sso.label',
		defaultMessage: 'SSO configuration',
		description: 'Label for SSO option in integration type',
	},
	ssoSubtitle: {
		id: 'integrationDetails.sso.subtitle',
		defaultMessage: 'Eligible employees will authenticate with their SSO provider.',
		description: 'Subtitle for SSO option in integration type',
	},
	subscriptionAccessTitle: {
		id: 'integrationDetails.subscriptionAccessTitle',
		defaultMessage: 'Subscription access model',
		description: 'Cell title for subscription access model',
	},
	integrationTypeTitle: {
		id: 'integrationDetails.integrationTypeTitle',
		defaultMessage: 'Integration method',
		description: 'Cell title for integration method',
	},
	ssoConfigurationTitle: {
		id: 'integrationDetails.ssoConfigurationTitle',
		defaultMessage: 'SSO configuration',
		description: 'Cell title for integration type when SSO is fully set up',
	},
	userIdTitle: {
		id: 'integrationDetails.userIdTitle',
		defaultMessage: 'User Identifier',
		description: 'Cell title for user identifier',
	},
	accessModelC4BContractDurationSelection: {
		id: 'integrationDetails.accessModelContractDurationSelection',
		defaultMessage: 'Access premium for the contract duration',
		description: 'Selection label for contract duration subscription access model',
	},
	accessModelCHContractDurationSelection: {
		id: 'integrationDetails.accessModelContractDurationSelection',
		defaultMessage: 'Access Calm Health for the contract duration',
		description: 'Selection label for contract duration subscription access model',
	},
	accessModelC4BFixedDurationSelection: {
		id: 'integrationDetails.accessModelFixedDurationSelection',
		defaultMessage: 'Sign up for premium during the contract',
		description: 'Selection label for fixed duration subscription access model',
	},
	accessModelCHFixedDurationSelection: {
		id: 'integrationDetails.accessModelFixedDurationSelection',
		defaultMessage: 'Sign up for Calm Health during the contract',
		description: 'Selection label for fixed duration subscription access model',
	},
	customAccessModelDescription: {
		id: 'integrationDetails.customAccessModelDescription',
		defaultMessage:
			'Covered lives will have access for as long as the partner is billed. Access to premium subscriptions will be removed on the contract end date set above.',
		description: 'Description for custom duration subscription access model',
	},
	fixedAccessModelDescription: {
		id: 'integrationDetails.fixedAccessModelDescription',
		defaultMessage:
			'Eligible employees will be able to sign up for a fixed-length subscription for as long as the partner is billed.',
		description: 'Description for fixed duration subscription access model',
	},
	renewalLabel: {
		id: 'integrationDetails.renewalLabel',
		defaultMessage: 'Renewal',
		description: 'Label for renewal',
	},
	renewalDescription: {
		id: 'integrationDetails.renewalDescription',
		defaultMessage: 'Allow subscriptions to renew as long as the partner is billed.',
		description: 'Description for renewal',
	},
	autoRenewalLabel: {
		id: 'integrationDetails.autoRenewalLabel',
		defaultMessage:
			'Calm will automatically renew subscriptions when covered lives’ subscription periods end',
		description: 'Label for auto renewal subscription access model',
	},
	renewableLabel: {
		id: 'integrationDetails.renewableLabel',
		defaultMessage:
			'Eligible employees will be able to sign up for a fixed-length premium subscription for as long as the partner is billed.',
		description: 'Label for renewable (manual) renewal subscription access model',
	},
	renewUrlsHeader: {
		id: 'accountManagerTools.renewUrlsHeader',
		defaultMessage: 'Covered lives renewal links (request these from the partner)',
		description: 'Group label for renewal urls',
	},
	webRenewUrlLabel: {
		id: 'accountManagerTools.webRenewUrlLabel',
		defaultMessage: 'Web',
		description: 'Label web renew url input',
	},
	webRenewUrlPlaceholder: {
		id: 'accountManagerTools.webRenewUrlPlaceholder',
		defaultMessage: 'Web renew url',
		description: 'Placeholder for web renew url input',
	},
	iosRenewUrlLabel: {
		id: 'accountManagerTools.iosRenewUrlLabel',
		defaultMessage: 'iOS',
		description: 'Label iOS renew url input',
	},
	iosRenewUrlPlaceholder: {
		id: 'accountManagerTools.iosRenewUrlPlaceholder',
		defaultMessage: 'iOS renew url',
		description: 'Placeholder for ios renew url input',
	},
	androidRenewUrlLabel: {
		id: 'accountManagerTools.androidRenewUrlLabel',
		defaultMessage: 'Android',
		description: 'Label android renew url input',
	},
	androidRenewUrlPlaceholder: {
		id: 'accountManagerTools.androidRenewUrlPlaceholder',
		defaultMessage: 'Android renew url',
		description: 'Placeholder for android renew url input',
	},
});
