import { ReactElement } from 'react';

import { Card } from '@calm-web/design-system';
import { stringFromModelValue } from '@calm-web/use-form';

import Subtitle from '@/components/ui/CellSubtitle';
import CellTitle from '@/components/ui/CellTitle';
import DatePicker from '@/components/ui/DatePicker';
import IncreaseCoverage from '@/components/ui/IncreaseCoverage';
import { usePermissions } from '@/hooks/auth';
import type { EditPartnerFormProps } from '@/hooks/forms/usePartnerForm';
import { Partner } from '@/types/store/reducers';
import { isSelfServePlan } from '@/utils/SkuUtils';
import { dateFromIso8601DateForDatePicker, iso8601Date } from '@/utils/helpers';

import { DateColumn, FormInputStyled, InvalidIcon, TwoColumnWrapper } from './styles';

interface Props {
	formProps: EditPartnerFormProps;
	isEdit?: boolean;
	partner?: Partner;
}

export default function PlanDetails({ formProps, isEdit, partner }: Props): ReactElement {
	const contractStartDate = dateFromIso8601DateForDatePicker(
		stringFromModelValue(formProps.model.contractStartDate),
	);
	const contractEndDate = dateFromIso8601DateForDatePicker(
		stringFromModelValue(formProps.model.contractEndDate),
	);
	const [hasValidPermissions, actions] = usePermissions();
	const requiredPermissions = isEdit ? [actions.UPDATE] : [actions.CREATE];

	const handleDateChange =
		(name: 'contractStartDate' | 'contractEndDate'): ((d: Date | null) => void) =>
		(d: Date | null) => {
			if (!d) {
				return formProps.setProperty(name, '');
			}
			return formProps.setProperty(name, iso8601Date(d));
		};

	const errors = formProps.validation.fields.contractEndDate?.errors ?? [];
	const hasTouched = formProps.dirtyState.contractEndDate?.hasTouched ?? false;
	const showError = Boolean(hasTouched && errors.length);

	const disableContractStartField =
		!hasValidPermissions('contract_starts_at', requiredPermissions) ||
		(contractStartDate < new Date() && isEdit);

	const isSelfServe = partner && isSelfServePlan(partner?.vouched_plan_sku);
	const disableContractExpiresField =
		!hasValidPermissions('contract_expires_at', requiredPermissions) || isSelfServe;

	return (
		<>
			<Card>
				<CellTitle>Number of contract covered lives</CellTitle>
				<TwoColumnWrapper>
					<FormInputStyled
						{...formProps.bindWithErrorProps('numCoveredLives', 'number')}
						label="Contract covered lives"
						disabled={!hasValidPermissions('contract_covered_lives', requiredPermissions)}
						data-testid="covered-lives-input"
						min="1"
					/>
					{isSelfServe && (
						<IncreaseCoverage partnerId={partner.id} coveredLives={partner.contract_covered_lives} />
					)}
				</TwoColumnWrapper>
			</Card>
			<Card>
				<TwoColumnWrapper>
					<DateColumn>
						<div>
							<CellTitle id="start-date-label">Start date</CellTitle>
							<Subtitle>
								This is the date Calm will start billing this partner and eligibility files will become
								active.
							</Subtitle>
						</div>
						<DatePicker
							startDate={contractStartDate}
							onChange={handleDateChange('contractStartDate')}
							disabled={disableContractStartField}
							dataTestId="contract-starts-at-picker"
							labelledBy="start-date-label"
						/>
					</DateColumn>
					<DateColumn>
						<div>
							<CellTitle id="renewal-date-label">Next renewal date</CellTitle>
							<Subtitle>This is the date the contract will next renew.</Subtitle>
						</div>
						<DatePicker
							startDate={contractEndDate}
							onChange={handleDateChange('contractEndDate')}
							disabled={disableContractExpiresField}
							dataTestId="contract-expires-at-picker"
							labelledBy="renewal-date-label"
						>
							{showError && <InvalidIcon className="validation-icon" />}
						</DatePicker>
					</DateColumn>
				</TwoColumnWrapper>
			</Card>
		</>
	);
}
