import useForm, { FormProps } from '@calm-web/use-form';

import { SurveyConfig } from '@/types/health';

type FieldNames = 'all_questions_required' | 'show_screening_results' | 'show_self_harm_question';

export type EditSurveyConfigFormProps = FormProps<FieldNames>;

export const useHealthSurveyConfigForm = (
	surveyConfigs?: SurveyConfig[],
): { formProps: EditSurveyConfigFormProps; hasChangedAny: boolean; hasTouchedAny: boolean } => {
	const healthSurvey = surveyConfigs?.find(config => config.survey_type === 'mental_health');
	const formProps: EditSurveyConfigFormProps = useForm(`healthSurveyConfigForm`, {
		initialModel: {
			all_questions_required: healthSurvey?.all_questions_required ? ['all_questions_required'] : [],
			show_screening_results:
				healthSurvey?.show_screening_results === false ? [] : ['show_screening_results'],
			show_self_harm_question: healthSurvey?.survey_id === 'mental_health' ? ['show_self_harm_question'] : [],
		},
	});

	const hasChangedAny = !!Object.values(formProps.dirtyState).some(value => value?.hasChanged);
	const hasTouchedAny = !!Object.values(formProps.dirtyState).some(value => value?.hasTouched);

	return { formProps, hasChangedAny, hasTouchedAny };
};
