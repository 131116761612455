import qs from 'qs';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import useSWR, { mutate } from 'swr';

import { useApi } from '@/hooks/api';
import { useUser } from '@/hooks/store';
import { setBannerMessage } from '@/store/actions';
import { Partner } from '@/types/store/reducers';
import { isCalmError } from '@/utils/apiRequest/errors';
import { calmLogger } from '@/utils/calmLogger';

import { ApiResponse } from './types';

const PAGE_SIZE = 50;

interface GetPartnersResponse {
	partners: Partner[];
	meta: {
		pagination: {
			total: number;
		};
	};
}

const buildPartnersSwrEndpointKey = ({
	isLoggedIn,
	filters,
	query,
	searchParents,
	pageSize,
	page,
}: {
	isLoggedIn: boolean;
	filters?: Record<string, string | Record<string, string>>;
	query?: string;
	searchParents?: boolean;
	pageSize: number;
	page: number;
}): string | null => {
	if (!isLoggedIn) {
		return null;
	}
	const filter = {
		...(filters ?? {}),
		...(query ? { name: { like: query, searchParents: searchParents } } : {}),
	};
	const hasFilters = Object.keys(filter).length > 0;
	const queryParams = {
		'page[offset]': pageSize * page,
		'page[limit]': pageSize,
		...(hasFilters ? { filter } : {}),
	};
	const queryString = qs.stringify(queryParams);
	return `b2b/partners?${queryString}`;
};

export const mutatePartnersResult = async ({
	filters,
	query,
	searchParents,
	pageSize,
	page,
}: {
	filters?: Record<string, string | Record<string, string>>;
	query?: string;
	searchParents?: boolean;
	pageSize: number;
	page: number;
}): Promise<void> => {
	const endpointKey = buildPartnersSwrEndpointKey({
		isLoggedIn: true,
		page,
		query,
		filters,
		searchParents,
		pageSize,
	});

	await mutate(endpointKey);
};

export default function usePartners({
	page,
	query,
	filters,
	searchParents,
	pageSize = PAGE_SIZE,
}: {
	page: number;
	query: string;
	filters?: Record<string, string | Record<string, string>>;
	searchParents?: boolean;
	pageSize?: number;
}): ApiResponse<GetPartnersResponse> {
	const apiRequest = useApi();
	const { user } = useUser();
	const dispatch = useDispatch();

	const isLoggedIn = Boolean(user);
	const endpoint = useMemo(() => {
		return buildPartnersSwrEndpointKey({
			isLoggedIn,
			page,
			query,
			filters,
			searchParents,
			pageSize,
		});
	}, [isLoggedIn, query, filters, page, pageSize, searchParents]);

	const { data, isLoading, error } = useSWR<GetPartnersResponse>(endpoint, async endpoint => {
		try {
			const response = await apiRequest({ endpoint });
			return response.data as GetPartnersResponse;
		} catch (err) {
			calmLogger.error('Error when fetching partners', {}, err);
			dispatch(
				setBannerMessage({
					message: `Error: ${(isCalmError(err) && err?.data?.error?.message) || ''}`,
					flash: true,
					isError: true,
				}),
			);
			throw err;
		}
	});

	return { data, loading: isLoading, error };
}
