import { defineMessages } from 'react-intl';

export default defineMessages({
	title: {
		id: 'dependentsDetails.title',
		defaultMessage: 'Dependents Support',
		description: 'Title for Dependents Details Section',
	},
	subtitle: {
		id: 'dependentsDetails.subtitle',
		defaultMessage: 'Enable Dependents for this partner',
		description: 'Subtitle for Dependents Details Section',
	},
	cancel: {
		id: 'dependentsDetails.modal.button.cancel',
		defaultMessage: 'Cancel',
		description: 'A cancellation button for the modal.',
	},
	confirm: {
		id: 'dependentsDetails.modal.button.cancel',
		defaultMessage: 'Confirm',
		description: 'A confirmation button for the modal.',
	},
	confirmUpdateTitle: {
		id: 'dependentsDetails.modal.title',
		defaultMessage: 'Are You Sure?',
		description: 'A title for the modal.',
	},
	dependentDecreaseWarning: {
		id: 'dependentsDetails.modal.warning',
		defaultMessage:
			'Decreasing the number of dependents for the partner will immediately expire all dependents access and require them to redeem again if the feature is re-enabled.',
		description: 'A title for the modal.',
	},
	noteAboutSaving: {
		id: 'dependentsDetails.modal.noteAboutSaving',
		defaultMessage: 'Note: Changes will not be saved until you click "Confirm" then "Save Configuration".',
		description: 'A note about how saving occurs.',
	},
});
