import { ReactElement } from 'react';

import { Button, Modal, PaletteColor } from '@calm-web/design-system';

import { CenterAlign, MarginSecondaryButton, SmallText } from './styles';

export default function ConfirmModal({
	title,
	confirmText,
	confirmTextBackgroundColor = 'errorRed',
	cancelText = 'Cancel',
	description,
	isOpen,
	onConfirm,
	onCancel,
}: {
	title: string;
	confirmText: string;
	confirmTextBackgroundColor?: PaletteColor;
	cancelText?: string;
	description: string | ReactElement;
	isOpen: boolean;
	onConfirm: () => void;
	onCancel: () => void;
}): ReactElement {
	if (!isOpen) {
		return <></>;
	}

	return (
		<Modal
			closeModal={onCancel}
			title={title}
			footer={
				<>
					<MarginSecondaryButton onPress={onCancel}>{cancelText}</MarginSecondaryButton>
					<Button onPress={onConfirm} backgroundColor={confirmTextBackgroundColor} textColor="white">
						{confirmText}
					</Button>
				</>
			}
			isOpen={isOpen}
		>
			<CenterAlign>
				<SmallText>{description}</SmallText>
			</CenterAlign>
		</Modal>
	);
}
