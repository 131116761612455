import { DateTime } from 'luxon';
import Image from 'next/legacy/image';
import { ReactElement, useContext, useState, useEffect, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { ThemeProvider } from 'styled-components';

import { D2CTheme } from '@calm-web/design-system';

import CalmLogoSquare from '@/../public/_n/images/calm-logo-square.png';
import PathwayEmailBanner from '@/../public/_n/images/pathway-email-banner-image.png';
import { PathwaysContext } from '@/components/providers/PathwaysContextProvider';
import { DayAbbreviation, convertDayAbbreviationToFull } from '@/utils/helpers';
import { srcLoader } from '@/utils/ui/imageLoader';

import messages from './messages';
import {
	LogoContainer,
	Title,
	PreviewWrapper,
	Header,
	Description,
	DescriptionBox,
	KeyInfoHeader,
	ListContainer,
	ListItem,
	NotificationSupport,
	SupportLink,
	ButtonWrapper,
	OptInButton,
} from './styles';

function AnnouncementEmailPreview(): ReactElement {
	const { formatMessage } = useIntl();
	const { partnerName, selectedPathway, startDate, endDate, serverTimeZone } = useContext(PathwaysContext);
	const [pathwayDuration, setPathwayDuration] = useState<number | undefined>();

	useEffect(() => {
		if (!startDate || !endDate) {
			return;
		}
		const msPerDay = 1000 * 60 * 60 * 24;
		// Existing end date is start of the day (midnight) which means the milliseconds wont account for the full last day
		const endingDate = DateTime.fromJSDate(endDate).plus({ days: 1 }).toJSDate();
		const millisecondDifference = Math.abs(
			DateTime.fromJSDate(startDate).toMillis() - DateTime.fromJSDate(endingDate).toMillis(),
		);
		const dayDifference = Math.round(millisecondDifference / msPerDay);
		setPathwayDuration(dayDifference);
	}, [startDate, endDate]);

	return (
		<PreviewWrapper>
			<LogoContainer>
				<Image height={50} width={50} src={CalmLogoSquare} loader={srcLoader} alt="" />
				<Title>
					{formatMessage(messages.LogoText, {
						pathwayTitle: selectedPathway?.title,
					})}
				</Title>
			</LogoContainer>
			<Image src={PathwayEmailBanner} loader={srcLoader} alt="" />
			<Header>
				{formatMessage(messages.emailHeader, {
					partnerName: partnerName.charAt(0).toUpperCase() + partnerName.slice(1),
					pathwayTitle: selectedPathway?.title,
				})}
			</Header>
			<Description>{formatMessage(messages.emailDescription)}</Description>
			<DescriptionBox>
				<KeyInfoHeader>
					{formatMessage(messages.descriptionBoxHeader, {
						pathwayTitle: selectedPathway?.title,
					})}
				</KeyInfoHeader>
				<ListContainer>
					<ListItem>{selectedPathway?.subtitle}</ListItem>
					<ListItem>
						{startDate && endDate
							? formatMessage(messages.keyItem2, {
									bold: (...chunks: ReactNode[]) => <strong>{chunks}</strong>,
									startDate: startDate.toLocaleDateString(undefined, {
										month: 'numeric',
										day: 'numeric',
										year: '2-digit',
										timeZone: serverTimeZone,
									}),
									endDate: endDate.toLocaleDateString(undefined, {
										month: 'numeric',
										day: 'numeric',
										year: '2-digit',
										timeZone: serverTimeZone,
									}),
							  })
							: null}
					</ListItem>
					<ListItem>
						{formatMessage(messages.keyItem3, {
							bold: (chunks: ReactNode[]) => <strong>{chunks}</strong>,
							pathwayDuration: `${pathwayDuration} days`,
							pathwaySchedule: convertDayAbbreviationToFull(
								(selectedPathway?.item_schedule || []) as DayAbbreviation[],
							),
						})}
					</ListItem>
					<ListItem>
						{formatMessage(messages.keyItem4, {
							applestore: (...chunks: ReactNode[]) => (
								<SupportLink
									href="https://links.calm.com/u/click?_t=b215951a136f423a9cbb19359fcccb0a&_m=1RaXWK7aJUKu5ZZXBh0uKu64PEbnfZtL&_e=zNLwlu4hf0vIQ7EG-94XF40o070TQo2EnFhrTMv63lFzm6dIjtZ-jQMG9QZfCA-KRtroDPg4Hyer9CxQN16tRnkBs_sxPrQvuxlvGoz7rBsH6l5QnJ-Jj97Bq0wHGV_DLI7H8_afxd_WNxaMzHoXnxDSkq2f7ihPKNK72N_mN-nzCwJdh8_fgYyyOgD2x5WKbjXjYWDQEpd5hOKufEtTXKjXTfARdpiYhW5ejUU6PIVoqA8wOI1emkkEQvwx7YaRFjucUnJ9lYRvYtoW9mGzoWBWcnbHKjkgRiwsDlpR4FWWTRj89XDBPyotVLkJEXaUubUJfXA2Q9B6sKeyn9WlpET8Ky3jsPvVGu0ROK6o9yw%3D"
									target="_blank"
									rel="noopener noreferrer"
								>
									{chunks}
								</SupportLink>
							),
							googleplay: (...chunks: ReactNode[]) => (
								<SupportLink
									href="https://links.calm.com/u/click?_t=b215951a136f423a9cbb19359fcccb0a&_m=1RaXWK7aJUKu5ZZXBh0uKu64PEbnfZtL&_e=zNLwlu4hf0vIQ7EG-94XF2TmNML3qNgKr3g21UHyaIXGbI3-xT_5m4Z774EeajUrgfj3pGjtc92WEw3xJMNNO6sgkzb73dg-XpdLpEWWCQinNWgZp75WqPgwIqgp5Rc4JDcwzK_YPuuBHJwo0K_7HSh8-3R9jw4Aub0K2zIJp1Ryy79k3y0hkzrEf47EJqrHLdywODIM_2ahZrpTUW6IljUQous-4wD2uAm3ecSvoBuWlz9n3lW7_0n5WEEk1FQsc3lW6kJ09ZkuJbjmAs7Vsv-jSLD1DgRoFoIjDI5JOISpRwIvphlQt3qyVPjYj4tfwfuhgYztxSdIG6uMEbOQxDP0-SE6sO6T5yvDZZPFotc%3D"
									target="_blank"
									rel="noopener noreferrer"
								>
									{chunks}
								</SupportLink>
							),
						})}
					</ListItem>
				</ListContainer>
			</DescriptionBox>
			<ButtonWrapper>
				<ThemeProvider theme={D2CTheme}>
					<OptInButton
						href="https://www.calm.com/?utm_campaign=pathways_reminder_"
						openInNewTab
						backgroundColor="gradientCalmBrand"
					>
						{formatMessage(messages.optInButton)}
					</OptInButton>
				</ThemeProvider>
			</ButtonWrapper>
			<NotificationSupport>
				{formatMessage(messages.notificationSupportText, {
					supportlink: (...chunks: ReactNode[]) => (
						<SupportLink
							href="https://links.calm.com/u/click?_t=b215951a136f423a9cbb19359fcccb0a&_m=1RaXWK7aJUKu5ZZXBh0uKu64PEbnfZtL&_e=zNLwlu4hf0vIQ7EG-94XF1JuYUFqJGxPrZdvmQxjVNFAjHNyWQAGHnXWUTSK27AX3WNbLr98meyhnqNVC_j-9TLm6DBeDbjd-I2S6IlEYO_ab8zt79y-GnTPbDY5_zaXY98YX7TCreXIb2i-egob1oTmnySUbiOVyC7Kf09WC5mE_8xrdeAt_Wj_mEms3868hvxc9_5cDMFfBXZs6-kKWq4TNAx3SmTh25KmMndH0KQplXrsk9Gy8vkH0PrQ48-8IZdpP6tuCuZ3Wai0He8kkbjV4kvtGurjzh_O9jprHc9THZ-gAS_Fub_RkqkP22YU-PKk1K5JUsHphftaSKVIkRw7O2bmZNmWiR2edIp9DfM%3D"
							target="_blank"
							rel="noopener noreferrer"
						>
							{chunks}
						</SupportLink>
					),
				})}
			</NotificationSupport>
		</PreviewWrapper>
	);
}

export default AnnouncementEmailPreview;
