import styled, { css } from 'styled-components';
import { ifProp, palette, theme } from 'styled-tools';

// TODO: derive these constants & styles from design-system
const HORIZONTAL_PADDING = 12;
const ICON_WIDTH = 26;
const LEFT_PADDING_WITH_ICON = HORIZONTAL_PADDING * 2 + ICON_WIDTH;
const InputBaseStyles = css`
	border-radius: ${theme('inputs.borderRadius')};
	border: 1px solid ${palette('gray6')};
	box-sizing: border-box;
	font-size: 1rem;
	height: ${theme('inputs.height')}px;
	outline: none;
	padding: 20px ${HORIZONTAL_PADDING}px 4px ${ifProp('hasIcon', `${LEFT_PADDING_WITH_ICON}px`)};
	width: 100%;
	color: ${palette('gray8')};
	:focused {
		border-color: ${palette('blue2')};
	}
	:read-only,
	:disabled {
		background: ${palette('transparent')};
		border: none;
		padding-left: ${ifProp('hasIcon', `${LEFT_PADDING_WITH_ICON}px`, '0px')};
	}
`;

export const DATEPICKER_CLASSNAME = 'react-datepicker__input-container';

export const Wrapper = styled.div<{ width: string; showCalendarIcon?: boolean }>`
	position: relative;

	svg {
		position: absolute;
		left: ${HORIZONTAL_PADDING}px;
		top: 50%;
		transform: translateY(-50%);
		width: ${ICON_WIDTH}px;
		z-index: 1;
		height: auto;
		cursor: pointer;
	}

	.${DATEPICKER_CLASSNAME} {
		input {
			${InputBaseStyles}
			width: ${props => props.width || 'auto'};
			padding: ${p =>
				p.showCalendarIcon
					? `0 ${HORIZONTAL_PADDING}px 0 ${LEFT_PADDING_WITH_ICON}px!important`
					: `0 ${HORIZONTAL_PADDING}px 0 10px`};
		}
	}
`;
