import { FC, MouseEventHandler, ReactElement, useEffect, useLayoutEffect, useMemo, useState } from 'react';

import { Feedback } from '@/hooks/api/useFeedback';
import useMeasureOnResize from '@/hooks/layout/useMeasureOnResize';
import { useDynamicImport } from '@/hooks/useDynamicImport';

import getWordsSplitByBolded from './boldWords';
import { Container, StyledFeedbackItem, StyledFeedbackList } from './styles';

interface FeedbackItemProps {
	feedback: Feedback;
	className: string;
	width: number;
	onClick?: MouseEventHandler<HTMLButtonElement>;
}

const MAX_TEXT_LENGTH = 210;
const GUTTER_WIDTH = 16;

function FeedbackItem({ feedback, className, width, onClick }: FeedbackItemProps): ReactElement {
	const textToShow = feedback.response_text.trim().replace(/[\s]+/g, ' ').slice(0, MAX_TEXT_LENGTH);
	const ellipsis = textToShow.length >= MAX_TEXT_LENGTH ? '…' : '';
	const piecesWithBolding = useMemo(() => getWordsSplitByBolded(textToShow), [textToShow]);
	return (
		<StyledFeedbackItem className={className} width={width} onClick={onClick}>
			&ldquo;
			{piecesWithBolding.map(({ piece, bold }) => (bold ? <b key={piece}>{piece}</b> : piece))}
			{ellipsis}&rdquo;
		</StyledFeedbackItem>
	);
}

export default function HighlightedFeedback({
	isLoading,
	feedbackList,
	onClickFeedbackItem,
}: {
	isLoading?: boolean;
	feedbackList: Feedback[];
	onClickFeedbackItem?: (feedback: Feedback) => void;
}): ReturnType<FC> {
	// Masonry-Layout accesses window at import time, so we have to dynamically import it once window is defined
	const Masonry = useDynamicImport(typeof window !== 'undefined' ? import('masonry-layout') : undefined);
	const { measuredWidth, ref: containerRef } = useMeasureOnResize<HTMLDivElement>(732);
	// We have to useState<any> because we don't have the Masonry type until we import Masonry
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [masonry, setMasonry] = useState<any>();
	const [columnWidth, setColumnWidth] = useState(233);

	const maxColumns = Math.min(feedbackList.length, 3);

	useLayoutEffect(() => {
		let cleanup: undefined | (() => void);
		if (Masonry) {
			const masonryInstance = new Masonry('.masonry-grid', {
				itemSelector: '.grid-item',
				gutter: GUTTER_WIDTH,
				transitionDuration: 0,
				resize: false,
				initLayout: false,
				fitWidth: true,
			});
			setMasonry(masonryInstance);
			cleanup = () => {
				if (masonryInstance.destroy) {
					masonryInstance.destroy();
				}
			};
		}
		return () => {
			if (cleanup) {
				cleanup();
			}
			setMasonry(undefined);
		};
	}, [Masonry, feedbackList]);
	useEffect(() => {
		// We have to compute the column width ourselves in JS rather than CSS
		// because Masonry gets upset if we want to both fitWidth and have a percentage-based column width
		setColumnWidth(() => {
			const renderOneColumn = maxColumns < 2 || measuredWidth < 416;
			if (renderOneColumn) {
				return measuredWidth;
			}
			const renderTwoColumns = maxColumns === 2 || measuredWidth < 672;
			if (renderTwoColumns) {
				return (measuredWidth - GUTTER_WIDTH) / 2;
			}
			return (measuredWidth - GUTTER_WIDTH * 2) / 3;
		});
	}, [maxColumns, measuredWidth]);
	useLayoutEffect(() => {
		if (masonry?.layout) {
			masonry.layout();
		}
		// Sadly, it seems like Masonry needs two render passes to settle on a good layout sometimes
		const timer = setTimeout(() => {
			if (masonry?.layout) {
				masonry.layout();
			}
		}, 200);
		return () => {
			clearTimeout(timer);
		};
	}, [columnWidth, masonry]);

	if (!feedbackList.length) return null;

	return (
		<Container ref={containerRef}>
			<StyledFeedbackList className="masonry-grid" maxColumns={maxColumns}>
				{feedbackList.map(feedback => (
					<FeedbackItem
						feedback={feedback}
						key={feedback.id}
						className="grid-item"
						width={columnWidth}
						onClick={onClickFeedbackItem ? () => onClickFeedbackItem(feedback) : undefined}
					/>
				))}
			</StyledFeedbackList>
		</Container>
	);
}
