// Third Party
import styled from 'styled-components';

import { Text, fontSize, FontWeights, FontSizes, maxWidth, units } from '@calm-web/design-system';

export const Container = styled.div`
	padding-top: ${units(2)};
	margin-left: ${units(0.5)};
	display: grid;
	grid-gap: ${units(5)};
	grid-template-columns: 50% 1fr 1fr;

	${maxWidth('desktop')} {
		display: flex;
		flex-wrap: wrap;
	}
`;

export const Header = styled(Text).attrs({
	size: FontSizes.base,
	weight: FontWeights.Medium,
})`
	display: block;
`;

export const Description = styled(Text).attrs({
	size: FontSizes.sm,
})``;

export const ScheduleContainer = styled.div``;

export const ScheduleDate = styled.div`
	display: flex;
	align-items: center;
	padding-top: ${units(1)};
`;

export const UpcomingDates = styled.div``;

export const PastDates = styled.div``;

export const PastDateList = styled.div``;

export const UpcomingDate = styled.div``;

export const DateHeader = styled(Header)`
	padding-bottom: ${units(1)};
`;

export const DateRow = styled.div`
	${fontSize(FontSizes.sm)};
	padding: ${units(1, 0)};
`;
