import { AxiosResponse } from 'axios';
import { DateTime } from 'luxon';
import useSWR from 'swr';

import { useApi } from '@/hooks/api';
import { SegmentSelectState, segmentsToJSON } from '@/utils/segments';

import { ApiResponse, RefetchOption } from '../types';
import { ReportingAggregationLevel } from './utils';

export interface MoodValue {
	mood_id: string;
	mood_count: number;
	gradient?: string;
	percentage?: number;
	emoji?: JSX.Element;
}

export interface ReportContent {
	starting_ts: string;
	anxiety?: number;
	focus?: number;
	gratitude?: number;
	relationship?: number;
	self_esteem?: number;
	stress?: number;
	shortDate?: string;
}

export interface ReportContentModular {
	partner_id: string;
	start_date?: string;
	end_date?: string;
	aggregation_level: ReportingAggregationLevel;
	updated_at?: string;
	is_mocked?: boolean;
	content: ReportContent[];
}

export function useContentReport({
	partnerId,
	segmentSelection,
	startDate = DateTime.local().minus({ weeks: 12 }).toJSDate(),
	endDate,
}: {
	partnerId: string;
	startDate?: Date;
	endDate?: Date;
	segmentSelection?: SegmentSelectState;
}): ApiResponse<ReportContentModular> & RefetchOption {
	const apiRequest = useApi();

	const [segmentFilter, swrString] = segmentsToJSON(segmentSelection, startDate, endDate);

	const endpoint = `b2b/partners/${partnerId}/reporting/content`;

	const { data, error, mutate } = useSWR<AxiosResponse | undefined>(
		`${endpoint}?${swrString}`,
		async () => {
			if (!endDate) {
				return undefined;
			}
			const response = await apiRequest({ endpoint, method: 'POST', body: segmentFilter });
			return response;
		},
		{ errorRetryCount: 0 },
	);
	if (!endDate) {
		return {
			data: undefined,
			error,
			loading: true,
			refetch: () => {},
		};
	}
	const apiReport = data?.data.report as ReportContentModular | undefined;

	const refetch = (): Promise<AxiosResponse | undefined> => mutate(undefined, true);
	return {
		data: apiReport,
		error,
		loading: !data && !error,
		refetch,
	};
}
