import styled from 'styled-components';
import { palette } from 'styled-tools';

import { fontSize, FontSizes, fontWeight, FontWeights, units } from '@calm-web/design-system';

export const NotificationContent = styled.div`
	background-color: ${palette('gray1')};
	border-radius: ${units(1)};
	padding: ${units(2)};
`;

export const PreviewWrapper = styled.div``;

export const NotificationHeader = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: ${units(2)};
`;

export const LogoWrapper = styled.div`
	display: flex;
	align-items: center;
`;

export const NotificationTime = styled.div`
	${fontSize(FontSizes.sm)};
	${fontWeight(FontWeights.Medium)};
	color: ${palette('gray6')};
`;

export const PreviewTitle = styled.div`
	${fontWeight(FontWeights.Demi)};
	margin-bottom: ${units(1)};
`;

export const PreviewDescription = styled.div`
	line-height: 22px;
`;

export const CalmLogoText = styled.div`
	color: ${palette('gray6')};
	margin-left: ${units(1)};
	${fontSize(FontSizes.sm)};
	${fontWeight(FontWeights.Medium)};
`;
