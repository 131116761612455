import { defineMessages } from 'react-intl';

export default defineMessages({
	uploadedOnText: {
		id: 'users.eligibility.logs.details.uploadedOn',
		defaultMessage: 'uploaded on {date}',
		description: 'text for the uploaded on date',
	},
	processedOnText: {
		id: 'users.eligibility.logs.details.processedOn',
		defaultMessage: 'processed on {date}',
		description: 'text for the processed on date',
	},
	rowsHeader: {
		id: 'users.eligibility.logs.details.rows',
		defaultMessage: 'Rows',
		description: 'header for the rows',
	},
	changesHeader: {
		id: 'users.eligibility.logs.details.changes',
		defaultMessage: 'Changes',
		description: 'header for the changes',
	},
	errorsHeader: {
		id: 'users.eligibility.logs.details.errors',
		defaultMessage: 'Errors',
		description: 'header for the errors',
	},
	numberOfErrorRowsHeader: {
		id: 'users.eligibility.logs.details.numberOfErrorRows',
		defaultMessage: '# of rows',
		description: 'header for the number of error rows',
	},
	descriptionHeader: {
		id: 'users.eligibility.logs.details.description',
		defaultMessage: 'Description',
		description: 'header for the description',
	},
});
