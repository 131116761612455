import styled, { css } from 'styled-components';
import { ifProp, palette } from 'styled-tools';

import {
	ButtonSizes,
	SecondaryButton,
	lineHeight,
	FontSizes,
	fontSize,
	fontWeight,
	FontWeights,
	units,
} from '@calm-web/design-system';

import { Subtitle } from '@/components/ui/CellTitle';

export const Label = styled.div`
	${fontWeight(FontWeights.Medium)};
`;

export const OptionBody = styled.div<{ disabled: boolean }>`
	${ifProp('disabled', 'opacity: 0.5')};
`;

export const Description = styled.div<{ topMargin: boolean }>`
	${fontSize(FontSizes.sm)};
	color: ${palette('gray5')};
	${ifProp(
		'topMargin',
		css`
			margin-top: ${units(1)};
		`,
	)};
`;

export const IntegrationTypeSelectionDescription = styled(Subtitle)`
	${fontSize(FontSizes.sm)}
	color: ${palette('gray8')};
	margin-bottom: ${units(2)};
`;

export const IntegrationTypeDescriptionButton = styled(SecondaryButton).attrs({ size: ButtonSizes.Sm })`
	&& {
		margin-left: ${units(1)};
	}
`;

export const IntegrationTypeOption = styled.div`
	display: flex;
	flex-direction: column;

	${Label} {
		margin-bottom: ${units(1)};
	}

	select {
		margin-top: ${units(1)};
	}

	${OptionBody} {
		margin-left: ${units(6)};
	}
`;

export const StyledHr = styled.hr<{
	marginTop?: number;
	marginBottom?: number;
}>`
	width: 100%;
	margin: ${props => units(props.marginTop ?? 2, 0, props.marginBottom ?? 2)};
`;

export const RenewUrlsContainer = styled.div``;

export const SubscriptionAccessModelContainer = styled.div`
	${Label} {
		${fontSize(FontSizes.sm)}
		${lineHeight(FontSizes.sm)};
	}
	select {
		margin-bottom: ${units(1)};
	}

	${RenewUrlsContainer} {
		width: 100%;
	}
`;

export const FixedAccessModelContainer = styled.div`
	margin-top: ${units(1)};
	margin-left: ${units(3)};
`;

export const RenewalSelection = styled.div`
	margin: ${units(1, 0)};
`;

export const RenewalTypeSelection = styled.div`
	margin-left: ${units(3)};
`;

export const WithSideLabel = styled.div`
	display: flex;
	align-items: center;

	& > :first-child {
		width: ${units(8)};
		text-align: right;
		margin-right: ${units(1)};
	}

	& > :last-child {
		flex-grow: 1;
	}
`;
