/* eslint-disable no-redeclare */
import { useState } from 'react';
import useSWR, { mutate } from 'swr';

import { useApi } from '@/hooks/api';
import { Partial } from '@/types/partial';
import { CalmError, getCalmErrorOrError } from '@/utils/apiRequest/errors';

import { ApiResponse } from './types';

export interface SSOConfig {
	entity_id: string;
	sso_url: string;
	signature_cert: string;
	audience_uri: string;
	assertion_consumer_service_url: string;
	has_completed_saml_form: boolean;
	segments?: {
		segment_1_name?: string;
		segment_2_name?: string;
		segment_3_name?: string;
		segment_1_display_name?: string;
		segment_2_display_name?: string;
		segment_3_display_name?: string;
	};
}

export interface SSOConfigParams {
	entityId: string;
	ssoUrl: string;
	certificate: string;
	certificateFile: File;
	webRenewUrl: string;
	segments: {
		segment_1_name?: string;
		segment_2_name?: string;
		segment_3_name?: string;
		segment_1_display_name?: string;
		segment_2_display_name?: string;
		segment_3_display_name?: string;
	};
}

export function useUpdateSaml(
	partnerId: string,
): [
	(args: Partial<SSOConfigParams>) => Promise<void>,
	{ loading: boolean; error: CalmError | Error | undefined },
] {
	const apiRequest = useApi();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<CalmError | Error | undefined>(undefined);

	async function updateSaml({
		entityId,
		ssoUrl,
		certificate,
		certificateFile,
		webRenewUrl,
		segments,
	}: Partial<SSOConfigParams>): Promise<void> {
		const endpoint = `b2b/partners/${partnerId}/saml`;
		if (loading) return;
		setLoading(true);
		setError(undefined);

		const body = {
			entity_id: entityId,
			sso_url: ssoUrl,
			signature_cert: certificate,
			web_renew_url: webRenewUrl,
		};
		const formData = new FormData();
		if (certificateFile) {
			formData.append('data', certificateFile);
		}
		if (segments) {
			Object.entries(segments).forEach(([key, value]) => {
				// Still want to send empty string
				if (key && value !== undefined) {
					formData.append(`segments[${key}]`, value);
				}
			});
		}
		Object.entries(body).forEach(([key, value]) => {
			if (key && value) {
				formData.append(key, value);
			}
		});

		try {
			await apiRequest({
				endpoint,
				method: 'PATCH',
				body: formData,
			});
		} catch (err) {
			setError(getCalmErrorOrError(err));
			throw err;
		} finally {
			setLoading(false);
		}
	}

	return [updateSaml, { loading, error }];
}

export function useOptionalSaml(partnerId: undefined): undefined;
export function useOptionalSaml(partnerId: string): ApiResponse<SSOConfig>;
export function useOptionalSaml(partnerId?: string): ApiResponse<SSOConfig> | undefined;
export function useOptionalSaml(partnerId?: string): ApiResponse<SSOConfig> | undefined {
	const apiRequest = useApi();

	const { data, error } = useSWR(
		`b2b/partners/${partnerId}/saml`,
		async endpoint => {
			if (!partnerId) {
				return null;
			}
			const response = await apiRequest({ endpoint });
			return response;
		},
		{ errorRetryCount: 2 },
	);
	if (!partnerId) {
		return undefined;
	}

	const saml = data?.data?.partner as SSOConfig;
	return { data: saml, error, loading: !data && !error };
}

export function useSaml(partnerId: string): ApiResponse<SSOConfig> {
	return useOptionalSaml(partnerId);
}

export function clearPartnerSamlCache(partnerId: string): Promise<undefined> {
	return mutate(`b2b/partners/${partnerId}/saml`, undefined, true);
}

export function useDownloadSamlMetadata(
	partnerId: string,
): [() => Promise<void>, { loading: boolean; error: CalmError | Error | undefined }] {
	const apiRequest = useApi();
	const [error, setError] = useState<CalmError | Error | undefined>(undefined);
	const [loading, setLoading] = useState<boolean>(false);

	async function download(): Promise<void> {
		try {
			setLoading(true);
			const response = await apiRequest({
				endpoint: `b2b/partners/${partnerId}/sso/metadata`,
				customHeaders: {
					Accept: 'application/xml',
					'Content-Type': 'application/json',
				},
			});

			const encoded = encodeURIComponent(response.data);
			const element = document.createElement('a');
			element.setAttribute('href', `data:text/plain;charset=utf-8,${encoded}`);
			element.setAttribute('download', `calm_${partnerId}_metadata.xml`);

			element.style.display = 'none';
			document.body.appendChild(element);
			element.click();

			document.body.removeChild(element);
		} catch (err) {
			setError(getCalmErrorOrError(err));
			throw err;
		} finally {
			setLoading(false);
		}
	}
	return [download, { error, loading }];
}
