import { defineMessages } from 'react-intl';

export default defineMessages({
	segmentHeader: {
		id: 'pathways.audience.segment.header',
		defaultMessage: 'Filter by segments',
		description: 'Segment cohort header',
	},
	segmentDescription: {
		id: 'pathways.audience.segment.description',
		defaultMessage: 'Choose which individuals can join the Pathway by segment',
		description: 'Segment cohort description',
	},
	segmentDetailsError: {
		id: 'pathways.segmentation.segmentDetailsError',
		defaultMessage: "Sorry, we couldn't load your segment list.",
		description: 'Message warning users that there was an error when trying to get their segment list.',
	},
	selectAll: {
		id: 'pathways.segmentation.selectAll',
		defaultMessage: 'Select all',
		description: 'Button text for selecting all values in a segment',
	},
	selectNone: {
		id: 'pathways.segmentation.selectNone',
		defaultMessage: 'None',
		description: 'Button text for selecting none for values in a segment',
	},
});
