/* eslint-disable @next/next/no-img-element */
import { ReactElement } from 'react';

import {
	CenteredContent,
	Content,
	ContentWrapper,
	Header,
	LandingImg,
	SamlImg,
	SectionBody,
	SectionHeader,
	SplitContent,
	Subtitle,
	Title,
	TroubleDescription,
	Updated,
} from '../styles';

export default function SsoIntegration(): ReactElement {
	return (
		<div>
			<Header>
				<img
					src="https://res.cloudinary.com/calm-com/image/upload/v1623175960/assets/images/logos/calm_square_logo.png"
					alt="Calm Logo"
				/>
				<Title>Partner SSO Integration</Title>
				<Updated>Last Updated: June 8, 2021</Updated>
			</Header>

			<ContentWrapper>
				<Content>
					<SectionHeader>Table of Contents</SectionHeader>
					<SectionBody>
						<ul>
							<li>
								<a href="#integration">Calm Partner SSO Integration</a>
							</li>
							<li>
								<a href="#how">How does the integration work?</a>
							</li>
							<li>
								<a href="#trouble">Troubleshooting</a>
							</li>
						</ul>
					</SectionBody>

					<SectionHeader id="integration">Calm Partner SSO Integration</SectionHeader>
					<SectionBody>
						<p>
							Partners can integrate with Calm through an IDP initiated flow to provision their users with a
							Calm subscription. The minimum requirements are a SAML 2.0 request containing a unique
							<code> SubjectNameId</code> to identify a user in the partner’s system (a “partner user”).
						</p>
						<p>
							Each partner user is able to activate a single Calm subscription. Once activated, users will
							have access to the Calm app without additional onboarding steps.
						</p>
						<p>
							Calm’s B2B Engineering Team will support engineering efforts during onboarding, testing and push
							to production.
						</p>
					</SectionBody>

					<SectionHeader id="how">How does the integration work?</SectionHeader>
					<SectionBody>
						<p>
							Calm’s B2B Engineering team will set up an IDP configuration and provide the Partner with the
							resulting metadata.xml file. This file will contain the{' '}
							<code>Assertion Consumer Service URL</code> and the <code>Audience URI</code> required to
							configure the SAML request. Calm will also provide the URL of our fsso application to use as the{' '}
							<code>Target</code> or <code>RelayState</code>.
						</p>
						<p>
							To complete the IDP configuration Calm will need the following specifics from the Partner (these
							are often included in the SAML request):
						</p>
						<ul>
							<li>
								<strong>IdP Issuer URI</strong> - the unique identifier of the IdP
							</li>
							<li>
								<strong>IdP Single Sign-On URL</strong> - login url to the Partner SSO system
							</li>
							<li>
								<strong>IdP Signature Certificate</strong> - the The SAML certificate
							</li>
						</ul>
						<p>
							When the configuration is complete and a SAML request is authenticated the Calm API will
							redirect the user to a Calm.com landing page where they are prompted to login to an existing
							Calm account or create a new Calm account with the email and password of choice.
						</p>
						<p>
							<CenteredContent>
								<LandingImg
									src="https://res.cloudinary.com/calm-com/image/upload/v1622070187/partner-portal/docs/landing.png"
									alt="Landing Page"
								/>
								<Subtitle>(Calm.com landing page, indicates successful configuration)</Subtitle>
							</CenteredContent>
						</p>
						<p>
							After login or signup, the user will have activated their Calm subscription and have access to
							Calm through our Mobile Apps or at Calm.com.
						</p>
					</SectionBody>

					<SectionHeader id="trouble">Troubleshooting</SectionHeader>
					<SectionBody>
						<p>
							Calm B2B Engineering has integrated with many partners using a variety of SSO Architectures
							(Azure, Shibboleth, Okta, Microsoft ADFS). We’ve encountered slight variations in each
							architecture which have surfaced some integration issues and possible resolutions.
						</p>
						<ul>
							<li>
								<p>Calm Okta prompting for Username / Password</p>
								<SplitContent>
									<img
										src="https://res.cloudinary.com/calm-com/image/upload/v1622071540/partner-portal/docs/login.png"
										alt="Okta Login"
										width="300px"
									/>
									<TroubleDescription>
										<p>
											After making a SAML request to Calm’s fsso Application, this screen indicates that we’re
											having an issue. This may be due to the
											<code> SubjectNameId</code> mapping.
										</p>
									</TroubleDescription>
								</SplitContent>
							</li>
							<li>
								<p>Calm Okta login successful, but requires tile click to go to landing page</p>
								<SplitContent>
									<img
										src="https://res.cloudinary.com/calm-com/image/upload/v1622071540/partner-portal/docs/okta.png"
										alt="Okta Home"
										width="300px"
									/>
									<TroubleDescription>
										<p>
											After making a SAML request to Calm’s fsso Application, this screen indicates that the{' '}
											<code>RelayState</code> or <code>Target</code> url is not specified in the SAML request.
										</p>
									</TroubleDescription>
								</SplitContent>
							</li>
							<li>
								<p>SAML Tracer</p>
								<p>
									To aid in debugging, install the{' '}
									<a href="https://chrome.google.com/webstore/detail/saml-tracer/mpdajninpobndbfcldcmbpnnbhibjmch?hl=en">
										saml-tracer
									</a>{' '}
									chrome extension.
								</p>

								<p>
									Verify Calm’s SSO URL is set up correctly by locating the SAML response of the POST request
									to the AssertionConsumerService Location provided by Calm through metadata.xml
								</p>
								<SamlImg
									src="https://res.cloudinary.com/calm-com/image/upload/v1622071541/partner-portal/docs/saml-tracer1.png"
									alt="Landing Page"
								/>
								<p>
									Make sure NameId is set up correctly and matches the unique user identifier in your system
								</p>
								<SamlImg
									src="https://res.cloudinary.com/calm-com/image/upload/v1622071542/partner-portal/docs/saml-tracer2.png"
									alt="Landing Page"
								/>
								<p>
									Make sure no irrelevant attributes are set through SAML response. For attributes sent to
									Calm, please use the short name (<strong>attrname-format: basic</strong> instead of uri for
									example) as in the screenshot.
								</p>
								<SamlImg
									src="https://res.cloudinary.com/calm-com/image/upload/v1622071541/partner-portal/docs/saml-tracer3.png"
									alt="Landing Page"
								/>
								<p>
									If the Okta logs are showing success but users land on Calm’s Okta page instead of the Calm
									sign up page, please double check that the relayState URL/embed link is set up correctly. (
									<a href="https://docs.google.com/document/d/1zE5_iE1gC3BfVXrwLwZSNKvxEI3mBuU1lGfJZ1ZJ78o/edit#bookmark=id.fknymztnrd2t">
										more info on relayState URL
									</a>
									)
								</p>

								<p>
									You can double check the response of the POST request to Calm’s IdP’s Assertion Consumer
									Service URL. It should match the relayState URL/embed link of the Calm fsso app. See below.
								</p>
								<SamlImg
									src="https://res.cloudinary.com/calm-com/image/upload/v1622071541/partner-portal/docs/saml-tracer4.png"
									alt="Landing Page"
									width="800px"
								/>
							</li>
							<li>
								<p>Issue: Not Found Error</p>
								<img
									src="https://res.cloudinary.com/calm-com/image/upload/v1623178107/partner-portal/docs/not_found_error.png"
									alt="Not Found Error"
									width="800px"
								/>
								<p>
									If you encounter this error after navigating to the app through your sso. Please double
									check your Single Sign on URL and Audience URI/Entity ID.
									<ul>
										<li>The Single Sign on URL should have the domain calm-forbusiness.okta.com</li>
										<li>The Audience URI/Entity ID should have the domain okta.com.</li>
									</ul>
								</p>
							</li>
							<li>
								<p>Issue: Calm Subscribe Error Page</p>
								<img
									src="https://res.cloudinary.com/calm-com/image/upload/v1623175891/partner-portal/docs/b2b_error.png"
									alt="Not Found Error"
									width="800px"
								/>
								<p>
									Please check with your Calm account manager to verify that:
									<ul>
										<li>The correct SAML Certificate was properly uploaded</li>
										<li>The correct PartnerID is being passed</li>
									</ul>
								</p>
							</li>
						</ul>
					</SectionBody>
					<p>
						If none of the above apply and you’re still experiencing an integration issue, please reach out to
						your Calm Account Manager.
					</p>
				</Content>
			</ContentWrapper>
		</div>
	);
}
