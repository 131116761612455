import { defineMessages } from 'react-intl';

export default defineMessages({
	adminTitle: {
		id: 'account.adminTitle',
		defaultMessage: 'Calm Admin',
		description: 'A title above a section where the Calm admin can add contract and partner details',
	},
	additionalLanguagesTitle: {
		id: 'account.healthConfig.additionalLanguagesTitle',
		defaultMessage: 'Additional Languages',
		description: 'A title above the additional languages section',
	},
	additionalLanguagesSubtitle: {
		id: 'account.healthConfig.additionalLanguagesSubtitle',
		defaultMessage:
			'You can add additional languages to be supported by the Calm Health app. Translations are provided by a third-party service and translated content can take up to 1 week to be updated.',
		description: 'A subtitle above the additional languages section',
	},
	additionalLanguageButton: {
		id: 'account.healthConfig.additionalLanguageButton',
		defaultMessage: 'Add language',
		description: 'Button text for adding an additional language',
	},
});
