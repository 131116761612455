import { useCallback, useMemo, useState } from 'react';

import { Card, FontSizes, IconButton, Text, TextElements } from '@calm-web/design-system';
import { CircleMinus } from '@calm-web/icons';
import { stringFromModelValue } from '@calm-web/use-form';

import CellTitle from '@/components/ui/CellTitle';
import ConfirmModal from '@/components/ui/ConfirmModal';
import PartnerSearch from '@/components/ui/PartnerSearch';
import { useOptionalPartner } from '@/hooks/api/usePartner';
import { useNumChildren } from '@/hooks/api/usePartnerRelations';
import { EditPartnerFormProps } from '@/hooks/forms/usePartnerForm';
import { Partner } from '@/types/store/reducers';

import { ParentDetailContainer, ParentDetailText } from './styles';

function ParentDetails({
	parent,
	onPressRemove,
	hasParentIdChanged,
}: {
	parent: Exclude<Partner['parent'], undefined>;
	onPressRemove: () => void;
	hasParentIdChanged: boolean;
}): JSX.Element {
	const { numChildren, loading } = useNumChildren(parent?.id);

	return (
		<ParentDetailContainer>
			<ParentDetailText>{parent.name}</ParentDetailText>
			<ParentDetailText color="gray6">
				{loading ? '' : `${numChildren} ${numChildren === 1 ? 'child' : 'children'}`}
				{hasParentIdChanged && ', 1 child pending'}
			</ParentDetailText>
			<ParentDetailText />
			<IconButton Icon={CircleMinus} color="errorRed" aria-label="Remove Parent" onPress={onPressRemove} />
		</ParentDetailContainer>
	);
}

export default function ParentSelector({
	partner,
	formProps,
}: {
	partner?: Partner;
	formProps: EditPartnerFormProps;
}): JSX.Element {
	const selectedParentId = stringFromModelValue(formProps.model.parentId) || undefined;
	const optionalParentResponse = useOptionalPartner(selectedParentId);
	const parent = useMemo(() => optionalParentResponse?.data, [optionalParentResponse]);
	const { numChildren } = useNumChildren(partner?.id);
	const [pendingIdChange, setPendingIdChange] = useState<string | undefined>(undefined);

	const hasParentIdChanged = !!formProps.dirtyState.parentId?.hasChanged;

	const onSelectPartner = useCallback((partnerId: string) => {
		setPendingIdChange(partnerId);
	}, []);
	const onPressRemove = useCallback(() => {
		setPendingIdChange('');
	}, []);

	const onConfirm = useCallback(() => {
		if (typeof pendingIdChange !== 'string') {
			return;
		}
		formProps.setProperty('parentId', pendingIdChange);
		setPendingIdChange(undefined);
	}, [formProps, pendingIdChange]);

	const onCancel = useCallback(() => {
		setPendingIdChange(undefined);
	}, []);

	return (
		<Card>
			<CellTitle showAdminFlag>Parent Designation</CellTitle>
			{parent ? (
				<ParentDetails
					parent={parent}
					onPressRemove={onPressRemove}
					hasParentIdChanged={hasParentIdChanged}
				/>
			) : (
				<>
					<Text el={TextElements.P} size={FontSizes.sm}>
						Choose a partner to be this partner&rsquo;s parent
					</Text>
					<PartnerSearch
						disabled={Boolean(numChildren)}
						onSelectPartner={onSelectPartner}
						additionalFilters={{
							['parent.id']: 'null',
							...(partner ? { id: { ['!=']: partner.id, product_sku: partner.product_sku } } : {}),
						}}
						showNumChildren
					/>
				</>
			)}
			{numChildren ? <Text color="gray6">This partner is already a parent.</Text> : null}
			{typeof pendingIdChange === 'undefined' ? null : pendingIdChange ? (
				<ConfirmModal
					title="Add parent-child relationship?"
					confirmText="Yes, add child to parent"
					description="Adding the child partner will affect the parent’s reporting."
					onConfirm={onConfirm}
					onCancel={onCancel}
					isOpen={true}
				/>
			) : (
				<ConfirmModal
					title="Remove parent-child relationship?"
					confirmText="Yes, remove child"
					description="Removing the child partner will affect the parent’s reporting."
					onConfirm={onConfirm}
					onCancel={onCancel}
					isOpen={true}
				/>
			)}
		</Card>
	);
}
