import { useFeatureFlags } from './api/useFeatureFlags';

const B2B_LAUNCH_ANNOUNCEMENT_EMAIL_SIGNUP_METHODS = 'b2b-launch-email-show-sign-up-methods';

export const useShouldShowLaunchEmailSignupMethods = (): boolean | string | undefined => {
	const {
		data: flagValues,
		error: flagError,
		loading: flagLoading,
	} = useFeatureFlags(B2B_LAUNCH_ANNOUNCEMENT_EMAIL_SIGNUP_METHODS);
	const flagsLoaded = !flagLoading && !flagError && flagValues;
	const launchAnnouncementSignupMethodsFF =
		flagValues && (flagValues[B2B_LAUNCH_ANNOUNCEMENT_EMAIL_SIGNUP_METHODS] as boolean | undefined);
	const shouldShowLaunchEmailSignUpMethods = flagsLoaded && launchAnnouncementSignupMethodsFF;
	return shouldShowLaunchEmailSignUpMethods;
};
