import { Dispatch, ReactElement, SetStateAction } from 'react';

import { Button, FontWeights, Modal, Text } from '@calm-web/design-system';

import { ButtonWrapper, ModalContent, ModalText } from '../../PartnerChurn/styles';

interface Props {
	modalIsOpen: boolean;
	openModal: Dispatch<SetStateAction<boolean>>;
	onDelete: () => Promise<void>;
}

function DeleteHealthSponsorshipModal({ modalIsOpen, openModal, onDelete }: Props): ReactElement {
	return (
		<Modal
			isOpen={modalIsOpen}
			closeModal={() => openModal(false)}
			canClose={false}
			aria-label={'Are you sure you want to delete this sponsor group?'}
		>
			<ModalContent>
				<Text weight={FontWeights.Medium}>Are you sure you want to delete this sponsor group?</Text>
				<ModalText>
					Once deleted, you will not be able to recover this sponsor group. Ensure there are no users
					currently in this sponsor group.
				</ModalText>
				<ButtonWrapper>
					<Button backgroundColor="gray2" onPress={() => openModal(false)} type="button">
						Cancel
					</Button>
					<Button backgroundColor="errorRed" onPress={onDelete} type="button">
						DELETE
					</Button>
				</ButtonWrapper>
			</ModalContent>
		</Modal>
	);
}

export default DeleteHealthSponsorshipModal;
