import { useDispatch } from 'react-redux';
import useSWR, { mutate } from 'swr';

import { useApi } from '@/hooks/api';
import { setBannerMessage } from '@/store/actions';

import { ApiResponse } from './types';

export interface Segments {
	segment_1_name?: string;
	segment_2_name?: string;
	segment_3_name?: string;
}

export default function useSegment(partnerId: string): ApiResponse<Segments> {
	const apiRequest = useApi();
	const dispatch = useDispatch();

	const { data, error } = useSWR(`b2b/partners/${partnerId}/segments`, async endpoint => {
		try {
			const response = await apiRequest({ endpoint });
			if (!response.data) {
				throw new Error('Not able to fetch segments');
			}
			return response;
		} catch (responseError) {
			// TODO not sure what we want to do with this error. Is it significant to show to the user?
			dispatch(
				setBannerMessage({
					message: 'Unable to load segment information.',
					isError: true,
					flash: true,
				}),
			);
			throw responseError;
		}
	});

	const segments = data?.data?.segments as Segments;
	return { data: segments, error, loading: !data && !error };
}

export function clearSegmentCache(partnerId: string): Promise<undefined> {
	return mutate(`b2b/partners/${partnerId}/segments`, undefined, true);
}
