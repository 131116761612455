import { FC, ReactNode } from 'react';

import { Text, FontWeights } from '@calm-web/design-system';

import { StyledCard, CardContent } from './styles';

interface Props {
	Icon: FC;
	primaryText: string;
	secondaryText?: string | ReactNode;
}

const EmptyStateBlock: FC<Props> = ({ Icon, primaryText, secondaryText }) => {
	return (
		<StyledCard>
			<CardContent>
				<Icon />
				<Text el="span" weight={FontWeights.Medium}>
					{primaryText}
				</Text>
				<Text el="span">{secondaryText}</Text>
			</CardContent>
		</StyledCard>
	);
};

export default EmptyStateBlock;
