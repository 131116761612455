import styled from 'styled-components';

import { units } from '@calm-web/design-system';

export const SampleContentCardsContainer = styled.div`
	display: grid;
	grid-auto-flow: column;
	justify-content: start;
	grid-gap: ${units(3)};
	margin-top: ${units(2)};
`;
