import styled, { css } from 'styled-components';
import { palette } from 'styled-tools';

import { FontSizes, fontWeight, FontWeights, Text } from '@calm-web/design-system';

import WarningIcon from 'icons/warning-filled.svg';

const IconCss = css`
	margin-right: 8px;
	width: 16px;
	height: auto;
`;

export const Warning = styled(WarningIcon)`
	${IconCss};
`;

export const WarningContainer = styled.div`
	display: flex;
	justify-content: center;
	margin: 1.33rem 0;
	${fontWeight(FontWeights.Medium)};
`;

export const CreditMessage = styled(Text).attrs({
	size: FontSizes.sm,
	color: 'gray5',
	lineHeight: 1.5,
})``;

export const WebLink = styled.a`
	color: ${palette('blue3')};
	text-decoration: none;
`;
