/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

export default defineMessages({
	title: {
		id: 'emailPreferences.title',
		defaultMessage: 'User Email Preferences',
		description: 'Title or the email preferences section',
	},
	description: {
		id: 'emailPreferences.description',
		defaultMessage: 'Calm will have permission to send the following e-mail types to your covered lives.',
		description: 'Description message for email preferences section',
	},
	marketing: {
		id: 'emailPreferences.marketing',
		defaultMessage: 'Marketing emails',
		description: 'Label for marketing checkbox',
	},
	upsell: {
		id: 'emailPreferences.upsell',
		defaultMessage: 'Upsell emails',
		description: 'Label for upsell checkbox',
	},
	downloadReminder: {
		id: 'emailPreferences.downloadReminder',
		defaultMessage: '"Download the app" reminder emails',
		description: 'Label for "Download the app" reminders',
	},
	benefitReminders: {
		id: 'emailPreferences.benefitReminders',
		defaultMessage: 'Benefit Invite & Reminders',
		description: 'Label for Benefit Invite & Reminders checkbox',
	},
	benefitRemindersDescription: {
		id: 'emailPreferences.benefitReminders',
		defaultMessage: "We'll email your covered lives' work emails on how to get started & sign up.",
		description: 'Section description for Benefit Invite & Reminders checkbox',
	},
	surveyTitle: {
		id: 'emailPreferences.surveyTitle',
		defaultMessage: 'How do my employees like their Calm Benefit?',
		description: 'Section title for survey checkbox',
	},
	surveyDescription: {
		id: 'emailPreferences.surveyDescription',
		defaultMessage:
			'We can periodically ask your active covered lives for anonymous feedback on their Calm benefit and share this with you.',
		description: 'Section description for survey checkbox',
	},
	survey: {
		id: 'emailPreferences.survey',
		defaultMessage: 'Feedback Surveys',
		description: 'Label for survey checkbox',
	},
	surveyDetail1: {
		id: 'emailPreferences.surveyDetail1',
		defaultMessage: 'All feedback will remain anonymous.',
		description: 'Bullet point explaining that feedback is anonymous',
	},
	surveyDetail2: {
		id: 'emailPreferences.surveyDetail2',
		defaultMessage: 'Surveys will be sent 3 months after your coverage began, then once a year.',
		description: 'Bullet point explaining when the survey will be sent.',
	},
	surveyDetail3: {
		id: 'emailPreferences.surveyDetail3',
		defaultMessage:
			'Surveys will be sent to all employees actively using Calm. We’ll use the e-mail addresses connected to their Calm accounts, not their work e-mail address.',
		description: 'Bullet point explaining who the survey will be sent to',
	},
	surveyDetail4: {
		id: 'emailPreferences.surveyDetail4',
		defaultMessage: 'Feedback will be surfaced in your portal.',
		description: 'Bullet point explaining where the survey results will be shown',
	},
	showMore: {
		id: 'emailPreferences.showMore',
		defaultMessage: 'Show more',
		description: 'Text indicating that additional content will be displayed if the button is clicked',
	},
	showLess: {
		id: 'emailPreferences.showLess',
		defaultMessage: 'Show less',
		description: 'Text indicating that content will be hidden if the button is clicked',
	},
	benefitDetail1: {
		id: 'emailPreferences.benefitDetail1',
		defaultMessage:
			'After the box is checked, a benefit invite email will be sent to all employees who have been newly added to the eligibility file. If an employee does not sign up after receiving the first Benefit Invite email, they will receive two more reminder emails, sent after 30 and 60 days.',
		description: 'Bullet point explaining the benefit emails and reminders',
	},
});
