import { ImageLoader, ImageLoaderProps } from 'next/legacy/image';

import { calmLogger } from '@/utils/calmLogger';

export const formatResizedUrl = (imageUrl: string, width: number, height?: number): string => {
	// Width and height values can be based off of screen size (and thus could be a non-integer)
	const constantWidth = Math.floor(width);
	const constantHeight = height ? Math.floor(height) : height;

	const sizerPath = constantHeight ? `${constantWidth}x${constantHeight}` : `${constantWidth}`;
	const urlId = imageUrl.replace('https://assets.calm.com/', `https://assets.calm.com/${sizerPath}/`);
	return urlId;
};

const calmLoader = ({ src, width }: ImageLoaderProps): string | undefined => {
	let parsedSrc;
	try {
		parsedSrc = new URL(src);
	} catch (err) {
		calmLogger.error(
			`Failed to parse src "${src}" on \`next/image\`, if using relative image it must start with a leading slash "/" or be an absolute URL (http:// or https://)`,
			{},
			err,
		);
		return undefined;
	}
	if (!['assets.calm.com', 'assets-prod-usw.calm.com'].includes(parsedSrc.hostname)) {
		return undefined;
	}
	const pathnamePieces = parsedSrc.pathname.split('/');
	if (pathnamePieces.length === 3) {
		const transformPath = pathnamePieces[1];
		const srcWidthString = transformPath.split('x')[0];
		try {
			// the calm assets service will upsize images,
			// so we have to take the Math.min ourselves client-side
			const srcWidth = parseInt(srcWidthString, 10);
			pathnamePieces[1] = `${Math.min(srcWidth, width)}`;
		} catch (err) {
			calmLogger.warn(`Attempted to transform an unrecognized assets.calm.com url: ${src}`);
		}
	} else {
		pathnamePieces.splice(1, 0, `${width}`);
	}
	parsedSrc.pathname = pathnamePieces.join('/');
	return parsedSrc.toString();
};

export const loader: ImageLoader = ({ src, width: _width, quality }) => {
	const width = Math.floor(_width);

	if (src.includes('assets.calm.com') || src.includes('assets-prod-usw.calm.com')) {
		const calmUrl = calmLoader({ src, width, quality });
		if (calmUrl) {
			return calmUrl;
		}
	}

	return src;
};
