import styled from 'styled-components';
import { palette, theme } from 'styled-tools';

import { fontSize, FontSizes, fontWeight, FontWeights, units } from '@calm-web/design-system';

import CircleCheckIcon from 'icons/check.svg';

export const Checkmark = styled(CircleCheckIcon)`
	width: ${units(4)};
	min-width: ${units(4)};
	height: ${units(4)};
	path {
		fill: ${palette('buttonLime')};
	}
`;

// Used by the GetSetup page components
export const StyledChecklist = styled.div`
	max-width: ${theme('layout.mainMaxWidth')};
	background-color: ${palette('white')};
	border: 1px solid ${palette('gray1')};
	border-radius: 10px;
`;

export const StyledChecklistItem = styled.div<{ $stackActions?: boolean }>`
	border-bottom: 1px solid ${palette('gray1')};
	display: flex;
	flex-direction: ${p => (p.$stackActions ? 'column' : 'row')};
	padding: ${units(2)};
	gap: ${units(1)};
`;

export const InnerWrapper = styled.div`
	display: flex;
`;

export const Content = styled.div`
	margin: ${units(0, 0, 2, 2)};
	max-width: ${units(65)};
	${fontWeight(FontWeights.Medium)};
	color: ${palette('gray8')};
`;

export const Title = styled.div`
	${fontSize(FontSizes.base)};
	${fontWeight(FontWeights.Demi)};
	margin-bottom: ${units(2)};
`;

export const Body = styled.div`
	${fontSize(FontSizes.sm)};

	> div {
		:not(:last-child) {
			margin-bottom: ${units(2)};
		}
	}
`;

export const Action = styled.div`
	flex-grow: 1;
	display: flex;
	align-self: flex-end;
	justify-content: flex-end;
`;
