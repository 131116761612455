/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

export default defineMessages({
	transitionButton: {
		id: 'partnerSku.transitionButton',
		defaultMessage: 'Transition to Managed Partner',
		description: 'A button to initiate partner transition.',
	},
	transitionModalTitle: {
		id: 'partnerSku.transitionModalTitle',
		defaultMessage: 'Transition this partner from self-server to a managed partner?',
		description: 'A title for the partner transition modal.',
	},
	transitionGeneralError: {
		id: 'partnerSku.transitionGeneralError',
		defaultMessage: 'Oops! Failed to transition partner because an error occurred.',
		description: 'The Error message if transition to managed partner fails.',
	},
	transitionRequiresReactivationError: {
		id: 'partnerSku.transitionRequiresReactivationError',
		defaultMessage:
			'Oops! This subscription is already canceled. Please reactivate the subscription then retry transitioning.',
		description:
			'The Error message if transition to managed partner fails due to a deactivated Subscription.',
	},
	cancel: {
		id: 'partnerSku.transitionModal.button.cancel',
		defaultMessage: 'Cancel',
		description: 'A cancellation button for the partner transition modal.',
	},
	confirm: {
		id: 'partnerSku.transitionModal.button.confirm',
		defaultMessage: 'Confirm',
		description: 'A confirmation button for the partner transition modal.',
	},
});
