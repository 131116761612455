import { Banner } from '@calm-web/design-system';

import { useActivateAchPaymentMethodLifeCycleManagement } from '@/hooks/api/useActivateAchPaymentMethodLifeCycleManagement';

const AchPendingBanner: React.FC = () => {
	const {
		ui: {
			banner: { error, pendingCopy },
		},
	} = useActivateAchPaymentMethodLifeCycleManagement();

	if (!error && !pendingCopy) {
		return null;
	}

	return (
		<Banner
			backgroundColor={error ? 'error' : 'bannerBg'}
			textColor={error ? 'white' : 'bannerText'}
			fullPageWidth
			data-testid="ach-pending-banner"
		>
			{error ?? pendingCopy}
		</Banner>
	);
};

export default AchPendingBanner;
