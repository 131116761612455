import isEqual from 'lodash/isEqual';
import { useCallback, useMemo, useState } from 'react';

import useForm, { FormProps, validation } from '@calm-web/use-form';

import { HealthAssignmentRule, HealthAssignmentRuleAttribute } from '@/types/health';

type FieldNames = 'type' | 'value';

export type EditHealthAssignmentRuleAttributeFormProps = FormProps<FieldNames>;

const useHealthAssignmentRuleAttributeForm = (
	index: number,
	ruleAttribute?: HealthAssignmentRuleAttribute,
): {
	formProps: EditHealthAssignmentRuleAttributeFormProps;
	hasChangedAny: boolean;
	hasTouchedAny: boolean;
} => {
	const formProps = useForm(`healthAssignmentRuleAttributeForm-${index}`, {
		initialModel: {
			type: ruleAttribute?.type ?? 'segment',
			value: ruleAttribute?.value ?? '',
		},
		validation: {
			value: validation.validateOrFail([
				{
					rules: [validation.required],
					errorResult: 'Please enter the value to match',
				},
			]),
		},
	});

	const hasChangedAny = !!Object.values(formProps.dirtyState).some(value => value?.hasChanged);
	const hasTouchedAny = !!Object.values(formProps.dirtyState).some(value => value?.hasTouched);

	return { formProps, hasChangedAny, hasTouchedAny };
};

export type EditHealthAssignmentOperatorFormProps = FormProps<'operator'>;

export const useHealthAssignmentRuleForm = (
	assignmentRule?: HealthAssignmentRule,
	isDefault = false,
): {
	operatorFormProps: EditHealthAssignmentOperatorFormProps;
	assignmentRuleAttributeFormProps: EditHealthAssignmentRuleAttributeFormProps[];
	hasChangedAny: boolean;
	hasTouchedAny: boolean;
	addAssignmentRuleAttribute: () => void;
	removeAssignmentRuleAttribute: (index: number) => void;
} => {
	const operatorFormProps = useForm(`healthAssignmentRuleForm`, {
		initialModel: {
			operator: assignmentRule?.operator ?? 'and',
		},
	});

	/**
	 * It is annoying that hooks can't be called conditionally, so we have to set up all possible forms every time
	 * Technically this could be done dynamically by having each form component own the call to the individual hooks,
	 * but using useGlobalForm, then having this higher-level form handle pulling those states out of redux
	 */
	const [numAssignmentRuleAttributes, setNumAssignmentRuleAttributes] = useState(
		isDefault ? 0 : assignmentRule?.assignment_rule?.attributes?.length ?? 1,
	);
	const assignmentRuleAttributeForm0 = useHealthAssignmentRuleAttributeForm(
		0,
		assignmentRule?.assignment_rule?.attributes?.[0],
	);
	const assignmentRuleAttributeForm1 = useHealthAssignmentRuleAttributeForm(
		1,
		assignmentRule?.assignment_rule?.attributes?.[1],
	);
	const assignmentRuleAttributeForm2 = useHealthAssignmentRuleAttributeForm(
		2,
		assignmentRule?.assignment_rule?.attributes?.[2],
	);
	const assignmentRuleAttributeForm3 = useHealthAssignmentRuleAttributeForm(
		3,
		assignmentRule?.assignment_rule?.attributes?.[3],
	);
	const assignmentRuleAttributeForm4 = useHealthAssignmentRuleAttributeForm(
		4,
		assignmentRule?.assignment_rule?.attributes?.[4],
	);
	const [formOrder, setFormOrder] = useState([0, 1, 2, 3, 4]);
	const getFormNumber = useCallback(
		(index: number) =>
			[
				assignmentRuleAttributeForm0,
				assignmentRuleAttributeForm1,
				assignmentRuleAttributeForm2,
				assignmentRuleAttributeForm3,
				assignmentRuleAttributeForm4,
			][index],
		[
			assignmentRuleAttributeForm0,
			assignmentRuleAttributeForm1,
			assignmentRuleAttributeForm2,
			assignmentRuleAttributeForm3,
			assignmentRuleAttributeForm4,
		],
	);
	const assignmentRuleAttributeForms = useMemo(() => {
		return formOrder.map(getFormNumber).slice(0, numAssignmentRuleAttributes);
	}, [formOrder, getFormNumber, numAssignmentRuleAttributes]);
	const addAssignmentRuleAttribute = (): void => setNumAssignmentRuleAttributes(num => num + 1);
	const removeAssignmentRuleAttribute = useCallback(
		(index: number) => {
			// Move the index to the back of the array, decrement the numAssignmentRuleAttributes so that it gets excluded, and reset its state
			const eligibilityValidatorForm = getFormNumber(formOrder[index]);
			eligibilityValidatorForm.formProps.resetAllDirtyStates();
			eligibilityValidatorForm.formProps.setModel({
				type: 'segment',
				value: '',
			});
			setFormOrder(items => [...items.slice(0, index), ...items.slice(index + 1), items[index]]);
			setNumAssignmentRuleAttributes(num => num - 1);
		},
		[formOrder, getFormNumber],
	);
	const assignmentRuleAttributeFormProps = useMemo(
		() => assignmentRuleAttributeForms.map(form => form.formProps),
		[assignmentRuleAttributeForms],
	);

	const hasChangedFormOrder = !isEqual(
		formOrder.slice(0, numAssignmentRuleAttributes),
		[0, 1, 2, 3, 4].slice(0, assignmentRule?.assignment_rule?.attributes?.length ?? 0),
	);
	const hasChangedAny =
		!!Object.values(operatorFormProps.dirtyState).some(value => value?.hasChanged) ||
		assignmentRuleAttributeForms.some(props => props.hasChangedAny) ||
		hasChangedFormOrder;
	const hasTouchedAny =
		!!Object.values(operatorFormProps.dirtyState).some(value => value?.hasTouched) ||
		assignmentRuleAttributeForms.some(props => props.hasTouchedAny);

	return {
		operatorFormProps,
		assignmentRuleAttributeFormProps,
		hasChangedAny,
		hasTouchedAny,
		addAssignmentRuleAttribute,
		removeAssignmentRuleAttribute,
	};
};
