import { FocusScope } from '@react-aria/focus';
import { useOverlay, DismissButton } from '@react-aria/overlays';
import { useRef } from 'react';

import { Container } from './styles';
import { PopoverProps } from './types';

const Popover: React.FC<PopoverProps> = ({
	popoverRef,
	isOpen,
	onClose,
	shouldCloseOnBlur = true,
	children,
}) => {
	const ref = useRef(null);
	const $popoverRef = popoverRef ?? ref;
	// Handle events that should cause the popup to close,
	// e.g. blur, clicking outside, or pressing the escape key.
	const { overlayProps } = useOverlay(
		{
			isOpen,
			onClose,
			shouldCloseOnBlur,
			isDismissable: true,
		},
		$popoverRef,
	);

	// Add a hidden <DismissButton> component at the end of the popover
	// to allow screen reader users to dismiss the popup easily.
	return (
		<FocusScope restoreFocus>
			<Container {...overlayProps} ref={$popoverRef}>
				{children}
				<DismissButton onDismiss={onClose} />
			</Container>
		</FocusScope>
	);
};

export default Popover;
