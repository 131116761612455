import QRCode from 'qrcode';

import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import { Partner } from '@/types/store/reducers';
import { SKU } from '@/utils/SkuEnums';
import { SkuToConfig } from '@/utils/SkuToConfig';
import { getErrorMessage } from '@/utils/apiRequest/errors';
import { createB2BPartnerSubscribeUrlPreview } from '@/utils/url';

export default function useQrCode(partner?: Partner): string {
	const { logEvent } = useAnalytics();

	const ssoUrl = partner?.web_renew_url;
	const nonSsoUrl =
		partner?.redemption_url ??
		createB2BPartnerSubscribeUrlPreview({
			slug: partner?.slug,
			skuType: SkuToConfig[partner?.vouched_plan_sku ?? SKU.PREMIUM_CUSTOM].productSKUType,
		});

	const url = ssoUrl || nonSsoUrl;
	let qrCode = '';

	QRCode.toDataURL(url, (err: unknown, dataUrl: string) => {
		if (err) {
			logEvent('Partner QR Code Error', {
				message: getErrorMessage(err),
			});
		}
		qrCode = dataUrl;
	});

	return qrCode;
}
