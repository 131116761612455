import styled from 'styled-components';
import { palette } from 'styled-tools';

import { units } from '@calm-web/design-system';

import InfoOutline from 'icons/info-outline.svg';

export const PrimaryText = styled.span``;

export const InfoIcon = styled(InfoOutline)`
	path {
		fill: ${palette('blue3')};
	}
	width: ${units(2)};
	height: ${units(2)};
	position: relative;
	vertical-align: baseline;
	top: 2px;
	margin-left: ${units(0.5)};
`;
