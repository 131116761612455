import styled, { css } from 'styled-components';
import { palette, ifProp } from 'styled-tools';

import {
	FontSizes,
	fontSize,
	maxWidth,
	minWidth,
	Text,
	fontWeight,
	FontWeights,
	units,
} from '@calm-web/design-system';

import Clock from 'icons/clock.svg';
import EllipseFilled from 'icons/ellipse-filled.svg';
import Ellipse from 'icons/ellipse-green-outline.svg';
import EllipseGreen from 'icons/ellipse-green.svg';

export const PathwaysPlaceholder = styled.div`
	border: 1px solid ${palette('gray2')};
	padding: ${units(4)};
	border-radius: ${units(1)};
	text-align: center;
`;

export const PlaceholderIcon = styled(Clock)``;

export const PlaceholderHeading = styled.div`
	${fontWeight(FontWeights.Medium)};
`;

export const TooltipWrapper = styled.div`
	padding-top: ${units(2)};
`;

export const EditText = styled(Text)`
	display: none;
	cursor: pointer;
	color: ${palette('blue3')};

	&:hover {
		text-decoration: underline;
	}
`;

export const EditTextMobile = styled(Text)`
	color: ${palette('blue3')};
`;

export const TableWrapper = styled.div`
	width: 100%;
	background: ${palette('white')};
	border-radius: ${units(2)};

	tr {
		box-shadow: none;

		&:hover {
			box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
			border-radius: ${units(2)};
			td:last-child {
				opacity: 1;

				${EditText} {
					display: block;
				}
			}
		}
		&:last-child {
			border-bottom: none;
		}
	}

	thead {
		tr:hover {
			box-shadow: none;
			border-radius: 0px;
		}
	}
`;

// TODO: Change this so that it's valid styling
const BaseRowStyles = css`
	cursor: pointer;
	display: flex;
	text-align: left;
	align-items: center;
`;

export const TableColumn = styled.div<{ rightAlign?: boolean }>`
	${BaseRowStyles};
	${ifProp(
		'rightAlign',
		css`
			justify-content: right;
		`,
	)};
`;

export const ActiveColumn = styled.div`
	${BaseRowStyles};
	${minWidth('desktop')} {
		padding-left: ${units(2)};
	}
`;

export const UpcomingIcon = styled(Ellipse)``;

export const ActiveIcon = styled(EllipseGreen)``;

export const PastIcon = styled(EllipseFilled)``;

export const MobileDataColumn = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${units(2)};
`;

export const MobileWrapper = styled.div`
	display: grid;
	grid-template-columns: 1.5fr 1fr 1fr auto;
	gap: ${units(2)};
	padding: ${units(2)};

	${maxWidth('tablet')} {
		grid-template-columns: 2fr 1fr;
		grid-template-rows: auto 1fr;
	}

	${maxWidth('mobileLarge')} {
		grid-template-columns: 1fr;
		margin: 0;
		padding: ${units(2)};
	}
`;

export const MobileRow = styled.div`
	display: flex;
	gap: ${units(2)};
`;

export const MobileLabel = styled.span`
	${fontSize(FontSizes.sm)};
	color: ${palette('gray5')};
	text-transform: uppercase;
	display: block;
`;
export const MobilePathwayWrapper = styled.div`
	width: 100%;
	background: ${palette('white')};
	border-radius: ${units(2)};

	${MobileWrapper}:not(:last-child) {
		border-bottom: 1px solid ${palette('gray1')};
	}
`;
