import { FC } from 'react';

import { FontWeights, Text } from '@calm-web/design-system';

// Launch Email template for partners who don't use EF OR do not use 'email' as their unique identifier.
const NonEfOrEmailTemplate: FC<{ signupLink: string }> = ({ signupLink }) => {
	return (
		<>
			<Text el="span">We are excited to introduce Calm as our newest mental health benefit!</Text>
			<br />
			<br />
			<Text el="span">
				Whether you have 30 seconds or 30 minutes, Calm can help you reduce stress and anxiety, sleep better
				and live a happier, healthier life. Explore relaxing Sleep Stories, guided meditations, mindful
				movement, focus music, tailored content for children, masterclasses, and more.
			</Text>
			<br />
			<br />
			<Text el="span">
				Follow the instructions below to gain unlimited access to Calm&apos;s science-backed, diverse content
				library in their mobile app or at calm.com.
			</Text>
			<br />
			<br />
			<Text el="span" weight={FontWeights.Medium}>
				To redeem via mobile app:
			</Text>
			<ol>
				<li>
					<Text el="span">
						Visit this link: <a href={signupLink}>{signupLink}</a>.
					</Text>
				</li>
				<li>
					<Text el="span">Sign up with your personal email address (or log in to an existing account)</Text>
				</li>
				<li>
					<Text el="span">Validate your work email address or Employee ID</Text>
				</li>
			</ol>
			<br />
			<Text el="span" weight={FontWeights.Medium}>
				To redeem via website:
			</Text>
			<ol>
				<li>
					<Text el="span">
						Visit this link: <a href={signupLink}>{signupLink}</a>.
					</Text>
				</li>
				<li>
					<Text el="span">
						Sign up with your personal email address or log in to an existing Calm account
					</Text>
				</li>
				<li>
					<Text el="span">Validate your eligible email address or unique ID</Text>
				</li>
				<li>
					<Text el="span">Once complete, you can download the Calm app and log in to your new account</Text>
				</li>
			</ol>
			<br />
			<Text el="span">
				For further assistance, view this{' '}
				<a href="https://support.calm.com/hc/en-us/articles/360055261934-How-to-redeem-your-subscription-provided-by-your-employer">
					Help Center article
				</a>
				. For information on Calm&apos;s privacy practices, see their privacy policy at{' '}
				<a href="https://calm.com/privacy">calm.com/privacy</a>.
			</Text>
		</>
	);
};

export default NonEfOrEmailTemplate;
