import styled from 'styled-components';
import { palette, theme } from 'styled-tools';

import { FormInput, units } from '@calm-web/design-system';

export const AccountManagerToolsContainer = styled.div`
	width: 100%;
	border: 1px dashed ${palette('gray2')};
	padding: ${units(2)};
	border-radius: ${theme('layout.borderRadius')};
	display: flex;
	flex-wrap: wrap;
	margin-top: ${units(2)};
	width: 100%;

	> div {
		margin-bottom: ${units(-2)};
		width: 100%;
	}
`;

export const CopyWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
`;

export const ClientIdInput = styled(FormInput)`
	margin-right: ${units(1)};
`;
