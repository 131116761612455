import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import useForm, { FormProps, stringFromModelValue, validation } from '@calm-web/use-form';

import { setBannerMessage } from '@/store/actions';
import { HealthConfig } from '@/types/health';

export const useHealthReportingConfigForm = (
	healthConfig?: Pick<HealthConfig, 'data_feed_delivery_method' | 'technical_contact' | 'report_configs'>,
): {
	formProps: EditHealthReportingConfigFormProps;
	hasChangedAny: boolean;
	hasTouchedAny: boolean;
} => {
	const formProps: EditHealthReportingConfigFormProps = useForm('healthReportingConfigForm', {
		initialModel: {
			delivery: healthConfig?.data_feed_delivery_method ?? '',
			technical_contact: healthConfig?.technical_contact ?? '',
			registered_users:
				healthConfig?.report_configs.find(report => report.report_type === 'registered_users')?.frequency ??
				'never',
			logins:
				healthConfig?.report_configs.find(report => report.report_type === 'logins')?.frequency ?? 'never',
			checkins:
				healthConfig?.report_configs.find(report => report.report_type === 'checkins')?.frequency ?? 'never',
			clinical_programs:
				healthConfig?.report_configs.find(report => report.report_type === 'clinical_programs')?.frequency ??
				'never',
			health_sessions:
				healthConfig?.report_configs.find(report => report.report_type === 'health_sessions')?.frequency ??
				'never',
			referrals:
				healthConfig?.report_configs.find(report => report.report_type === 'referrals')?.frequency ?? 'never',
			survey_responses:
				healthConfig?.report_configs.find(report => report.report_type === 'survey_responses')?.frequency ??
				'never',
			conditions:
				healthConfig?.report_configs.find(report => report.report_type === 'conditions')?.frequency ??
				'never',
		},
		validation: {
			delivery: validation.validateOrFail([
				{
					rules: [validation.required],
					errorResult: 'Please select a delivery method.',
				},
			]),
			technical_contact: validation.validateOrFail([
				{
					rules: [validation.or(validation.empty, validation.email)],
					errorResult: 'Please enter a valid email address.',
				},
			]),
			registered_users: validation.validateOrFail([
				{
					rules: [validation.required],
					errorResult: 'Please select a frequency.',
				},
			]),
			logins: validation.validateOrFail([
				{
					rules: [validation.required],
					errorResult: 'Please select a frequency.',
				},
			]),
			checkins: validation.validateOrFail([
				{
					rules: [validation.required],
					errorResult: 'Please select a frequency.',
				},
			]),
			clinical_programs: validation.validateOrFail([
				{
					rules: [validation.required],
					errorResult: 'Please select a frequency.',
				},
			]),
			health_sessions: validation.validateOrFail([
				{
					rules: [validation.required],
					errorResult: 'Please select a frequency.',
				},
			]),
			referrals: validation.validateOrFail([
				{
					rules: [validation.required],
					errorResult: 'Please select a frequency.',
				},
			]),
			survey_responses: validation.validateOrFail([
				{
					rules: [validation.required],
					errorResult: 'Please select a frequency.',
				},
			]),
			conditions: validation.validateOrFail([
				{
					rules: [validation.required],
					errorResult: 'Please select a frequency.',
				},
			]),
		},
	});

	const hasChangedAny = !!Object.values(formProps.dirtyState).some(value => value?.hasChanged);
	const hasTouchedAny = !!Object.values(formProps.dirtyState).some(value => value?.hasTouched);

	return { formProps, hasChangedAny, hasTouchedAny };
};

const getErrorMessage = (props: EditHealthReportingConfigFormProps): string | undefined => {
	return stringFromModelValue(
		(Object.keys(props.validation.fields) as FieldNames[])
			.map(fieldName => stringFromModelValue(props.validation.fields[fieldName]?.errors))
			.filter(Boolean) as string[],
	);
};

export const useHealthReportingSubmitData = (
	healthReportingProps: EditHealthReportingConfigFormProps,
): {
	getHealthReportingSubmitData: () => Partial<HealthConfig>;
	showValidationErrors: () => boolean;
} => {
	const dispatch = useDispatch();

	const getFrequency = useCallback(
		(field: FieldNames): string | null => {
			const frequency = stringFromModelValue(healthReportingProps.model[field]);
			if (frequency === 'never') {
				return null;
			}
			return frequency || null;
		},
		[healthReportingProps],
	);

	const getHealthReportingSubmitData = useCallback((): Partial<HealthConfig> => {
		return {
			data_feed_delivery_method: stringFromModelValue(healthReportingProps.model.delivery) as
				| 'none'
				| 'sftp'
				| 'secure_portal',
			technical_contact: stringFromModelValue(healthReportingProps.model.technical_contact),
			report_configs: [
				{
					report_type: 'registered_users',
					frequency: getFrequency('registered_users'),
				},
				{
					report_type: 'logins',
					frequency: getFrequency('logins'),
				},
				{
					report_type: 'checkins',
					frequency: getFrequency('checkins'),
				},
				{
					report_type: 'clinical_programs',
					frequency: getFrequency('clinical_programs'),
				},
				{
					report_type: 'health_sessions',
					frequency: getFrequency('health_sessions'),
				},
				{
					report_type: 'referrals',
					frequency: getFrequency('referrals'),
				},
				{
					report_type: 'survey_responses',
					frequency: getFrequency('survey_responses'),
				},
				{
					report_type: 'conditions',
					frequency: getFrequency('conditions'),
				},
			],
		};
	}, [healthReportingProps, getFrequency]);

	const showValidationErrors = useCallback((): boolean => {
		if (healthReportingProps.validation.isValid) {
			return false;
		}
		const errorMessage =
			getErrorMessage(healthReportingProps) ?? 'Please check you have filled out all required fields';
		dispatch(
			setBannerMessage({
				message: `Error: ${errorMessage}`,
				flash: true,
				isError: true,
			}),
		);
		return true;
	}, [dispatch, healthReportingProps]);

	return { getHealthReportingSubmitData, showValidationErrors };
};

export type FieldNames =
	| 'delivery'
	| 'technical_contact'
	| 'registered_users'
	| 'logins'
	| 'checkins'
	| 'clinical_programs'
	| 'health_sessions'
	| 'referrals'
	| 'survey_responses'
	| 'conditions';

export type EditHealthReportingConfigFormProps = FormProps<FieldNames>;
