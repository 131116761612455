/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

export default defineMessages({
	firstUploadTitle: {
		id: 'users.upload.firstUploadTitle',
		defaultMessage: 'Upload your first eligibility file',
		description: 'Title for the file upload modal if this is their first upload',
	},
	title: {
		id: 'users.upload.title',
		defaultMessage: 'Upload a new eligibility file',
		description: 'Title for the file upload modal',
	},
	uploadSuccessBanner: {
		id: 'users.upload.uploadSuccessBanner',
		defaultMessage: 'Successfully uploaded eligibility file',
		description: 'Message for the file upload success',
	},
	uploadError: {
		id: 'users.upload.uploadError',
		defaultMessage: 'Failed to upload eligibility file',
		description: 'Message for the file upload error if there are no details',
	},
	signedUrlUploadError: {
		id: 'users.upload.signedUrlUploadError',
		defaultMessage: 'Unable to retrieve upload destination. Please try again.',
		description: 'Message for the file upload error if we could not get a signed URL',
	},
	fileTooLargeError: {
		id: 'users.upload.fileTooLargeError',
		defaultMessage: 'Uploaded file is too large. Max allowed size is {fileSizeLimit} MB',
		description: 'Message for the file upload error if the file is too large',
	},
	serverDefinedUploadError: {
		id: 'users.upload.serverDefinedUploadError',
		defaultMessage: 'Failed to upload eligibility file. {servermessage}',
		description: 'Message for the file upload error if the server provides its own error message',
	},
	invalidFormatError: {
		id: 'users.upload.invalidFormatError',
		defaultMessage: 'Failed to upload eligibility file. File was in an incorrect format',
		description: 'Message for the file upload error if file was in wrong format (not CSV or Excel)',
	},
	multipleColumnsError: {
		id: 'users.upload.multipleColumnsError',
		defaultMessage: 'Failed to upload eligibility file. Data in multiple different columns',
		description: 'Message for the file upload error if file had data in more than one column',
	},
	tooManyEntriesError: {
		id: 'users.upload.tooManyEntriesError',
		defaultMessage: 'Your eligibility file has more than the allowed number of entries.',
		description: 'Message for the file upload error if file was had too many entries',
	},
	exampleShow: {
		id: 'users.upload.exampleShow',
		defaultMessage: 'Need help? See an example spreadsheet.',
		description: 'Button to click to show an example file',
	},
	exampleEligibilityFileTitle: {
		id: 'users.upload.exampleEligibilityFileTitle',
		defaultMessage: 'Example eligibility file',
		description: 'Title for the modal when showing an example file',
	},
	exampleExplainer: {
		id: 'users.upload.exampleExplainer',
		defaultMessage: 'Populate column A with your team’s e-mail addresses. Leave all other columns blank.',
		description: 'Explainer text when showing an example file',
	},
	exampleCaption: {
		id: 'users.upload.exampleCaption',
		defaultMessage:
			'Company.com has 5 employees. Their HR administrator entered each of their 5 employees’ email addresses into column A of their eligibility file.',
		description: 'Caption for an image showing an example file',
	},
	exampleBack: {
		id: 'users.upload.exampleBack',
		defaultMessage: 'Back',
		description: 'Back button for when user is done viewing the example file',
	},
	pickFileFirstUpload: {
		id: 'users.upload.pickFileFirstUpload',
		defaultMessage: 'Pick a file to upload',
		description: 'Button text to choose a file to upload',
	},
	newFileLabel: {
		id: 'users.upload.newFileLabel',
		defaultMessage: 'New File:',
		description: 'Label shown next to the name of the file you will be uploading',
	},
	loading: {
		id: 'users.upload.loading',
		defaultMessage: 'Loading…',
		description: 'Loading message',
	},
	pickDifferentFile: {
		id: 'users.upload.pickDifferentFile',
		defaultMessage: 'Pick a different file',
		description: 'Button text they can click to change their file selection',
	},
	overwriteWarning: {
		id: 'users.upload.overwriteWarning',
		defaultMessage: 'Committing this file will:',
		description: 'Intro text for the warning explaining what effects your upload will have',
	},
	overwriteRemovalWarning: {
		id: 'users.upload.overwriteRemovalWarning',
		defaultMessage: '{deletedcount, plural, =1 {Remove # covered life} other {Remove # covered lives}}',
		description: 'Warning explaining that uploading the file will remove N covered lives',
	},
	overwriteAddWarning: {
		id: 'users.upload.overwriteAddWarning',
		defaultMessage: '{addedcount, plural, =1 {Add # covered life} other {Add # covered lives}}',
		description: 'Warning explaining that uploading the file will add N covered lives',
	},
	overwriteRemoveSegmentWarning: {
		id: 'users.upload.overwriteRemoveSegmentWarning',
		defaultMessage: '{deletedsegmentcount, plural, =1 {Remove # segment} other {Remove # segments}}',
		description: 'Warning explaining that uploading the file will remove N segments',
	},
	overwriteAddSegmentWarning: {
		id: 'users.upload.overwriteAddSegmentWarning',
		defaultMessage: '{addedsegmentcount, plural, =1 {Add # segment} other {Add # segments}}',
		description: 'Warning explaining that uploading the file will add N segments',
	},
	overwriteChangeSegmentValueWarning: {
		id: 'users.upload.overwriteChangeSegmentValueWarning',
		defaultMessage:
			"{changedsegmentvaluecount, plural, =1 {Update # user's segments} other {Update # users' segments}}",
		description: 'Warning explaining that uploading the file will update N users segment values',
	},
	overwriteNoChangesWarning: {
		id: 'users.upload.overwriteNoChangesWarning',
		defaultMessage: 'Do nothing: there are no differences',
		description: 'Warning explaining that you cannot upload the file because it would cause no changes',
	},
	uploadButton: {
		id: 'users.upload.uploadButton',
		defaultMessage: 'Commit changes',
		description: 'Button text to upload a file when you have uploaded a file before',
	},
	uploadButtonFirstUpload: {
		id: 'users.upload.uploadButtonFirstUpload',
		defaultMessage: 'Upload file',
		description: 'Button text to upload a file',
	},
	cancelButton: {
		id: 'users.upload.cancelButton',
		defaultMessage: 'cancel',
		description: 'Button text to choose not to upload a file and close the modal',
	},
	purchaseMoreButton: {
		id: 'users.upload.purchaseMoreButton',
		defaultMessage: 'Click here to upgrade your plan',
		description: 'Button text to purchase more covered lives',
	},
	helpDescription: {
		id: 'users.upload.helpDescription',
		defaultMessage: `We only accept eligibility files in .CSV format. Duplicate files are not tracked or processed.
		Refer to the <setupGuideLink>Eligibility File Setup Guide</setupGuideLink> and <defaultTemplateLink>Default Template</defaultTemplateLink> to set up your eligibility file for a successful upload and processing. 
		{linebreak}
		{linebreak}
		For further support, reach out to your CSM. Do not include files in email.
			`,
		description: 'Text describing where to get help with uploading a file',
	},
	segmentedDescription: {
		id: 'users.upload.segmentedDescription',
		defaultMessage: `<div>
  <div>Upload an Excel or .csv file with the following parameters:</div>
  <ul>
    <li>Column A: Include the email or unique ID of every person you want to make Calm available to.</li>
    <li>Cell A1: Include a column header (ie Email Address or Employee ID)</li>
  </ul>
</div>
`,
		description: 'Text describing how to structure the eligibility file for segmented reporting',
	},
	segmentedOptional: {
		id: 'users.upload.segmentedOptional',
		defaultMessage:
			'Then add additional columns to segment reporting. Each additional column must contain a unique header and these additional columns should not include any identifying information (ie. first name, last name, SSN)',
		description: 'Text explaining the optional use of additional columns for segmented reporting',
	},
	segmentedExampleDescription: {
		id: 'users.upload.segmentedExampleDescription',
		defaultMessage: `
    <b>Required: add your team’s unique IDs to column A</b>
    <ul>
      <li>Populate column A with your team’s {descriptor}, starting with row 2. <b>Use Row 1 to label your Unique ID </b>, for example "Email address" or "Employee number"</li>
    </ul>
    <b>Optional: add up to three additional segmented reporting columns</b>
    <ul>
      <li>Use columns B, C and D to add the employee in column A to a segment.</li>
      <li>Use row 1 as the column label for each segment.</li>
      <li>Segments containing less than 10 members will not display in reporting, for privacy reasons.</li>
    </ul>
`,
		description:
			'Description text for the segmented eligibility file example. Describes required and optional columns.',
	},
	segmentedAnnouncementTitle: {
		id: 'users.upload.segmentedAnnouncementTitle',
		defaultMessage: 'New! Segmented reporting ✨',
		description: 'Title for modal announcing segmented reporting to users.',
	},
	segmentedAnnouncementDescription: {
		id: 'users.upload.segmentedAnnouncementDescription',
		defaultMessage: `
      <div>Add additional columns to your eligibility file to segment reporting (for example by location or department).</div>
      <div><b>Important: To use this feature, you must include column headers in row 1 of your eligibility file.</b></div>
`,
		description:
			'Description of the segmented reporting feature, explaining the use of multiple columns and the requirement to use column headers.',
	},
	timeout: {
		id: 'users.upload.timeout',
		defaultMessage: `
We encountered an error processing this file! Maybe it is too large.
{linebreak}
{linebreak}
Our server may still have processed it successfully. Please try wait a few minutes and then reload the page. If errors persist, please reach out to your Customer Success Manager.
    `,
		description:
			'Description of the segmented reporting feature, explaining the use of multiple columns and the requirement to use column headers.',
	},

	passwordProtectedError: {
		id: 'users.upload.passwordProtected',
		defaultMessage: `File is password protected. Please remove this constraint and upload again.`,
		description:
			'Error message warning users that the file they gave us has a password protection and cannot be parsed',
	},
	unableToParseError: {
		id: 'users.upload.unableToParse',
		defaultMessage: `Parsing error. We are unable to handle this file. Please reach out to your Customer Success Manager for help`,
		description: 'Error message warning users that the file they uploaded is in a format we cannot handle.',
	},
});
