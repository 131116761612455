import { ReactElement } from 'react';

import Image from '@/components/image/Image';

interface ImageProps {
	src: string;
	compressHeight?: number;
	width?: number;
	quality?: number;
	blur?: number;
	className?: string;
	isFullScreen?: boolean;
	customFormat?: string;
	alt: string;
}

function CalmImage(props: ImageProps): ReactElement {
	const { src, compressHeight = 50, quality = 5, blur = 20, isFullScreen, alt } = props;

	const baseImage = src?.split('/upload/');
	const lqip = src?.includes('/upload/')
		? `${baseImage[0]}/upload/f_auto,c_scale,h_${compressHeight},e_blur:${blur},q_${quality}:420,dpr_auto/${baseImage[1]}`
		: '';

	return <Image {...props} enableResponsiveImage lqip={lqip} isFullScreen={isFullScreen} alt={alt} />;
}

export default CalmImage;
