import { defineMessages } from 'react-intl';

export default defineMessages({
	calculateConfirmText: {
		id: 'plan.calculateConfirmText',
		defaultMessage: 'Calculate your new monthly bill before confirming purchase.',
		description: 'Prompt users to calculated the new price for their covered lives',
	},
	numberOfCoveredLivesError: {
		id: 'plan.numCoveredLivesError',
		defaultMessage:
			'To buy more coverage, please enter a number of covered lives greater than the number you already have.',
		description:
			'Message to show users if they attempt to purchase below the current amount of covered lives.',
	},
	coverageUnchanged: {
		id: 'plan.coverageUnchanged',
		defaultMessage:
			'To update coverage, please enter a number of covered lives different than the current amount.',
		description: 'Message to show users if they attempt to purchase the same amount of covered lives.',
	},
	creditMessage: {
		id: 'plan.creditMessage',
		defaultMessage:
			"We've updated our pricing. Your new pro-rated total is less than your previous pro-rated total. We've credited your account. Any available credit balance will be applied to your next amount due. If you cancel or do not renew, this credit will be forfeited. Please <link>contact Calm Support</link> with any questions.",
		description: 'Message to show users if they will be credited for their new pro-rated total.',
	},
});
