/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

export default defineMessages({
	fromEfContractDuration: {
		id: 'integrationDetails.integrationChanger.modal.fromEfContractDuration',
		defaultMessage:
			'Employees listed on the eligibility file will no longer be able to sign up for Calm Premium.',
		description: 'Modal explaining the dangers of changing from eligibility file',
	},
	fromEfFixedDuration: {
		id: 'integrationDetails.integrationChanger.modal.fromEfFixedDuration',
		defaultMessage:
			'Employees listed on the eligibility file will no longer be able to sign up for or renew their Calm Premium subscriptions.',
		description: 'Modal explaining the dangers of changing from eligibility file',
	},
	fromAcContractDuration: {
		id: 'integrationDetails.integrationChanger.modal.fromAcContractDuration',
		defaultMessage: 'Access codes will no longer be valid for signing up for Calm Premium.',
		description: 'Modal explaining the dangers of changing from access codes',
	},
	fromAcFixedDuration: {
		id: 'integrationDetails.integrationChanger.modal.fromAcFixedDuration',
		defaultMessage: 'Access codes will no longer be valid for signing up or renewing Calm Premium.',
		description: 'Modal explaining the dangers of changing from access codes',
	},
	fromGcContractDuration: {
		id: 'integrationDetails.integrationChanger.modal.fromGcContractDuration',
		defaultMessage: 'Group codes will no longer be valid for signing up for Calm Premium.',
		description: 'Modal explaining the dangers of changing from group codes',
	},
	fromGcFixedDuration: {
		id: 'integrationDetails.integrationChanger.modal.fromGcFixedDuration',
		defaultMessage: 'Group codes will no longer be valid for signing up or renewing Calm Premium.',
		description: 'Modal explaining the dangers of changing from group codes',
	},
	toEf: {
		id: 'integrationDetails.integrationChanger.modal.toEf',
		defaultMessage:
			'Employees who haven’t yet signed up will use a unique ID provided in the eligibility file.',
		description: 'Modal explaining how employees will redeem after the switch to eligibility file',
	},
	toAc: {
		id: 'integrationDetails.integrationChanger.modal.toAc',
		defaultMessage: 'Employees will have to use a Calm-provided access code.',
		description: 'Modal explaining how employees will redeem after the switch to access codes',
	},
	toGc: {
		id: 'integrationDetails.integrationChanger.modal.toGc',
		defaultMessage: 'Employees will have to use the group code.',
		description: 'Modal explaining how employees will redeem after the switch to group codes',
	},
});
