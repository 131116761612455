/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

export default defineMessages({
	title: {
		id: 'partnerChurn.title',
		defaultMessage: 'Partner Churn',
		description: 'Title of the partner churn section',
	},
	churned: {
		id: 'partnerChurn.churned',
		defaultMessage: 'Churned',
		description: 'Label for churned checkbox',
	},
	description: {
		id: 'partnerChurn.description',
		defaultMessage: 'Check this box if the partner is no longer a customer with Calm.',
		description: 'Description message for partner churn section',
	},
	modalTitle: {
		id: 'partnerChurn.modal.title',
		defaultMessage: 'Partner Churn',
		description: 'Title text for modal',
	},
	modalChurnedQuestion: {
		id: 'partnerChurn.modal.churnedQuestion',
		defaultMessage: 'Are you sure this partner will no longer be a customer?',
		description: 'Modal header asking if partner should be marked as churned',
	},
	modalChurnedDescription: {
		id: 'partnerChurn.modal.unchurnedQuestion',
		defaultMessage:
			"Checking this box is irreversible. It will also trigger emails with DTC subscription offers to the partner's employees.",
		description: 'Modal warning for marking a partner as churned',
	},
	modalUnchurnedQuestion: {
		id: 'partnerChurn.modal.unchurnedQuestion',
		defaultMessage: 'Are you sure you want to do this?',
		description: 'Modal header asking if partner should be marked as unchurned',
	},
	modalUnchurnedDescription: {
		id: 'partnerChurn.modal.unchurnedQuestion',
		defaultMessage: 'You should only uncheck this box if it was a mistake.',
		description: 'Modal warning for marking a partner as unchurned',
	},
	modalCancelButton: {
		id: 'partnerChurn.modal.cancelButton',
		defaultMessage: 'Cancel',
		description: 'Modal cancel button',
	},
	modalYesButton: {
		id: 'partnerChurn.modal.yesButton',
		defaultMessage: 'Yes',
		description: 'Modal yes button',
	},
});
