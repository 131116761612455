/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

export default defineMessages({
	customDateRangeTitle: {
		id: 'reporting.timeframeFilter.customDateRange.title',
		defaultMessage: 'Custom Date Range',
		description: 'Title for custom date range picker',
	},
	startDateLabel: {
		id: 'reporting.timeframeFilter.customDateRange.startDate',
		defaultMessage: 'Start date',
		description: 'Label for start date picker',
	},
	endDateLabel: {
		id: 'reporting.timeframeFilter.customDateRange.endDate',
		defaultMessage: 'End date',
		description: 'Label for end date picker',
	},
	cancelButton: {
		id: 'reporting.timeframeFilter.customDateRange.cancel',
		defaultMessage: 'cancel',
		description: 'Button text for cancel button',
	},
	applyButton: {
		id: 'reporting.timeframeFilter.customDateRange.apply',
		defaultMessage: 'Apply',
		description: 'Button text for Apply button',
	},
});
