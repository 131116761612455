import { SET_USER, SetUserAction } from '@/types/store/actions';
import { User } from '@/types/store/reducers';

// import useAnalytics from '@/hooks/useAnalytics';

export function setUser(user: User): SetUserAction {
	return {
		type: SET_USER,
		payload: user,
	};
}
