import { rgba } from 'polished';
import { css } from 'styled-components';
import { palette } from 'styled-tools';

import { units } from '@calm-web/design-system';

export const boxShadow = css`
	box-shadow: ${(props): string => `${units(0, 1, 2)} ${rgba(palette('black')(props), 0.15)}`};
`;

export const boxShadowText = (percent = 15): string => `${units(0, 1, 3)} rgb(0 0 0 / ${percent}%)`;
