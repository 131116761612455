import { useWindowWidth } from '@react-hook/window-size';
import { Ref, forwardRef, ReactElement, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';

import { Text, FontSizes, FontWeights } from '@calm-web/design-system';

import EmptyStateBlock from '@/components/pages/Home/EmptyStateBlock';
import StatsTile from '@/components/pages/Home/Stats/StatsTile';
import { ReportingContext } from '@/components/providers/ReportingContextProvider';
import { aggregationLevelToTimeUnit } from '@/hooks/api/reporting/utils';
import { useDefinedPartner } from '@/hooks/api/usePartner';
import { isSelfServePlan } from '@/utils/SkuUtils';
import friendlyPercentage from '@/utils/friendlyPercentage';

import LoadingAnimation from '../LoadingAnimation';
import messages from './messages';
import { ClockIcon, Row } from './styles';

function Summary(_props: {}, ref?: Ref<HTMLDivElement>): ReactElement {
	const { formatMessage } = useIntl();
	const { search } = useLocation();
	const { signupsAndEngagementReport, moodReport } = useContext(ReportingContext);
	const windowWidth = useWindowWidth();
	const isFourByTwo = windowWidth > 1231; // size where the row doesn't fit 4 elems

	const partner = useDefinedPartner();
	const isSelfServe = isSelfServePlan(partner?.vouched_plan_sku);

	if (signupsAndEngagementReport?.loading || moodReport?.loading) {
		return (
			<>
				<Text el="h2" size={FontSizes.lg} weight={FontWeights.Medium}>
					{formatMessage(messages.sectionHeading)}
				</Text>
				<LoadingAnimation ref={ref} />
			</>
		);
	}

	if (!signupsAndEngagementReport?.data) {
		return <EmptyStateBlock Icon={ClockIcon} primaryText={formatMessage(messages.heading)} />;
	}

	const { activity_report } = signupsAndEngagementReport.data;
	const { signups } = activity_report || {};
	const { aggregation_level } = signupsAndEngagementReport.data;
	const deltaLabel = `${signups?.length} ${aggregationLevelToTimeUnit[aggregation_level]} change`;

	const activelyEngagedTile = (
		<StatsTile
			href={`reporting${search}#activelyEngaged`}
			title="Actively engaged"
			stat={signupsAndEngagementReport.data.engaged_count?.toLocaleString('en-US') || '--'}
			location="reporting"
			deltaChange={signupsAndEngagementReport.data.engaged_start_to_end_change_actual}
			deltaLabel={deltaLabel}
		/>
	);

	return (
		<div ref={ref}>
			<Text el="h2" size={FontSizes.lg} weight={FontWeights.Medium}>
				{formatMessage(messages.sectionHeading)}
			</Text>
			<Row>
				<StatsTile
					href={`reporting${search}#signup`}
					title="Signups"
					stat={signupsAndEngagementReport.data.sign_up_count?.toLocaleString('en-US') || '--'}
					location="reporting"
					deltaChange={signupsAndEngagementReport.data.sign_up_start_to_end_change_actual}
					deltaLabel={deltaLabel}
				/>
				<StatsTile
					href={`reporting${search}#signupRate`}
					title="Total sign-up rate"
					stat={`${friendlyPercentage(signupsAndEngagementReport.data.sign_up_rate) || '--'}%`}
					location="reporting"
					deltaChange={signupsAndEngagementReport.data.sign_up_start_to_end_change_percentage}
					deltaLabel={deltaLabel}
					showDeltaAsPercentage
				/>
				<StatsTile
					href={`reporting${search}#engagementRate`}
					title="Total engagement rate"
					stat={`${friendlyPercentage(signupsAndEngagementReport.data.engage_rate) || '--'}%`}
					location="reporting"
					deltaChange={signupsAndEngagementReport.data.engaged_start_to_end_change_percentage}
					deltaLabel={deltaLabel}
					showDeltaAsPercentage
				/>
				{isFourByTwo && activelyEngagedTile}
			</Row>
			<Row>
				{!isFourByTwo && activelyEngagedTile}
				{!isSelfServe && (
					<StatsTile
						href={`reporting${search}#sentiment`}
						title="Positive Sentiment"
						stat={
							moodReport?.data?.mood
								? `${moodReport.data.mood?.mood_percent_positive.toFixed(1) ?? 0}%`
								: '--'
						}
						location="reporting"
						deltaChange={
							moodReport?.data?.mood ? moodReport?.data?.mood.mood_percent_change / 100 : undefined
						}
						deltaLabel={deltaLabel}
						showDeltaAsPercentage
					/>
				)}
				{!isSelfServe && (
					<StatsTile
						href={`reporting${search}#mood`}
						title="Mood Check-ins"
						stat={moodReport?.data?.mood?.total_moods_collected.toLocaleString() ?? '--'}
						location="reporting"
					/>
				)}
			</Row>
		</div>
	);
}

export default forwardRef(Summary);
