import { ReactElement, useEffect, useMemo } from 'react';

import { Card, RadioGroup } from '@calm-web/design-system';
import { BindWithErrorProps } from '@calm-web/use-form';

import CellTitle from '@/components/ui/CellTitle';
import { usePermissions } from '@/hooks/auth/usePermissions';
import { EditHealthConfigFormProps } from '@/hooks/forms/useHealthConfigForm';

interface Props {
	salesTrialProps: ReturnType<BindWithErrorProps<string>>;
	isEdit: boolean;
	healthConfigFormProps: EditHealthConfigFormProps;
}

export default function HealthIntegrationType({
	salesTrialProps,
	isEdit,
	healthConfigFormProps,
}: Props): ReactElement | null {
	const [hasValidPermissions, actions] = usePermissions();
	const requiredPermissions = isEdit ? [actions.UPDATE] : [actions.CREATE];
	const shouldShowIntegrationType = hasValidPermissions('integration_type', requiredPermissions);
	useEffect(() => {
		if (salesTrialProps.value === 'true') {
			healthConfigFormProps.setProperty('integration_type', 'GROUP_CODE');
		}
	}, [salesTrialProps.value, healthConfigFormProps]);

	const options = useMemo(() => {
		return salesTrialProps.value === 'true'
			? [
					{
						value: 'GROUP_CODE',
						id: 'SponsorCode',
						label: 'Sponsor Code',
					},
			  ]
			: [
					{
						value: 'ELIGIBILITY_FILE',
						id: 'EligibilityFile',
						label: 'Eligibility File',
					},
					{
						value: 'GROUP_CODE',
						id: 'SponsorCode',
						label: 'Sponsor Code',
					},
			  ];
	}, [salesTrialProps.value]);
	return (
		<Card>
			<CellTitle showAdminFlag>Integration Type</CellTitle>
			<RadioGroup
				{...healthConfigFormProps.bindWithErrorProps('integration_type', 'radio')}
				options={options}
				asCards
				aria-label="What is the partner's integration type?"
				display="flex"
				disabled={!shouldShowIntegrationType}
			/>
		</Card>
	);
}
