import styled from 'styled-components';

import { fontWeight, FontWeights, units } from '@calm-web/design-system';

export const Wrapper = styled.div`
	display: flex;
	overflow-x: scroll;
	width: 100%;
	max-width: 1024px;

	a {
		border-bottom: 0;
		background-color: inherit;
		margin-right: ${units(2)};

		&:last-child {
			margin-right: 0;
		}
	}
`;

export const Header = styled.div<{ customMargin?: number }>`
	${fontWeight(FontWeights.Medium)};
	margin-bottom: ${p => (p.customMargin ? units(p.customMargin) : 0)};
`;
