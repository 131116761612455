import styled from 'styled-components';
import { palette } from 'styled-tools';

import { fontSize, FontSizes, Text, units } from '@calm-web/design-system';

export const ButtonWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`;

export const ModalText = styled(Text)`
	${fontSize(FontSizes.sm)};
	color: ${palette('gray8')};
	padding: ${units(2)} 0;
	width: 100%;
`;

export const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	padding: ${units(1)};
`;
