import { useFeatureFlags } from './api/useFeatureFlags';

export const ERROR_ELIGIBILITY_CONFIG = 'b2b-ch-error-eligibility-config';

export const useShouldShowErrorEligibilityConfig = (): boolean => {
	const {
		data: flagValues,
		error: flagError,
		loading: flagLoading,
	} = useFeatureFlags(ERROR_ELIGIBILITY_CONFIG);
	const flagsLoaded = !flagLoading && Boolean(!flagError) && Boolean(flagValues);
	const shouldShowErrorEligibilityConfig = flagsLoaded && flagValues?.[ERROR_ELIGIBILITY_CONFIG] === true;
	return shouldShowErrorEligibilityConfig;
};
