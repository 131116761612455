import styled from 'styled-components';
import { ifProp } from 'styled-tools';

import { PrimaryButton, units } from '@calm-web/design-system';

export const DetailsContainer = styled.div``;

export const PaymentDetailsContainer = styled.div`
	display: flex;
	margin-bottom: ${units(1)};
`;

export const Icon = styled.img`
	margin-left: ${units(2)};
`;

export const BillingAddressContainer = styled.div`
	display: flex;
	align-items: flex-start;
`;

export const ChangePaymentDetailsButton = styled(PrimaryButton)<{ shouldShowMarginLeft?: boolean }>`
	${ifProp('shouldShowMarginLeft', `margin-left: ${8 / 9}rem !important;`)}
`;
