import { useEffect, useState } from 'react';

export function useHasScrolled(): boolean {
	const [hasScrolled, setHasScrolled] = useState(false);
	const handleScroll = (): void => {
		const yPosition = window.scrollY;
		if (yPosition === 0) {
			setHasScrolled(false);
		} else if (!hasScrolled) {
			setHasScrolled(true);
		}
	};
	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return (): void => {
			// return a cleanup function to unregister our function since its gonna run multiple times
			window.removeEventListener('scroll', handleScroll);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return hasScrolled;
}
