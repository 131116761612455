import { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Redirect, useParams } from 'react-router-dom';

import { Loader } from '@calm-web/design-system';

import InitialPageAnalytics from '@/components/analytics/InitialPageAnalytics';
import SocialShareButtons from '@/components/ui/SocialShareButtons';
import StepIcon from '@/components/ui/StepIcon';
import { EnvConfig } from '@/env_config';
import useCanSeeReferral from '@/hooks/api/useCanSeeReferral';
import { useUser } from '@/hooks/store';

import messages from './messages';
import {
	Cell,
	CellRow,
	Container,
	GiftHeader,
	GiftIcon,
	GiftInput,
	GiftRow,
	HeaderLockup,
	HeaderLockupImage,
	HeaderLockupNumbers,
	HeaderLockupQuote,
	HeaderLockupText,
	HeaderRow,
	HeaderSubtitle,
	HeaderText,
	HeaderTitle,
	LoaderContainer,
	ShareRow,
	TermsAndConditions,
} from './styles';

function HowItWorks(): ReturnType<FC> {
	const { data: { canSeeReferral100 } = {}, loading, error } = useCanSeeReferral();

	const { formatMessage } = useIntl();

	if (loading || error) {
		return null;
	}

	return (
		<CellRow>
			<Cell>
				<StepIcon>1</StepIcon>
				<div>{formatMessage(messages.howItWorks1)}</div>
			</Cell>
			<Cell>
				<StepIcon>2</StepIcon>
				<div>{formatMessage(canSeeReferral100 ? messages.howItWorks2_100 : messages.howItWorks2)}</div>
			</Cell>
		</CellRow>
	);
}

export default function Reporting(): ReturnType<FC> {
	const { formatMessage } = useIntl();
	const { partnerId } = useParams();
	const { user } = useUser();
	const { data: { canSeeReferralV1, canSeeReferralV2, canSeeReferral100 } = {}, loading } =
		useCanSeeReferral();

	const referralUrl = `${EnvConfig.hrReferralForm}?referrer=${user?.sub}`;
	const giftValue = canSeeReferral100 ? '$100' : '$500';

	const loader = (
		<Container>
			<LoaderContainer>
				<Loader color="gray5" />
			</LoaderContainer>
		</Container>
	);

	if (loading) {
		return loader;
	}

	if (!canSeeReferralV1 && !canSeeReferralV2) {
		return <Redirect to={`/${partnerId}/home`} />;
	}

	return (
		<Container>
			<InitialPageAnalytics />
			<HeaderRow>
				<HeaderText>
					<HeaderTitle>{formatMessage(messages.referralTitle)}</HeaderTitle>
					<HeaderSubtitle>
						{formatMessage(canSeeReferral100 ? messages.referral100Subtitle : messages.referralSubtitle)}
					</HeaderSubtitle>
				</HeaderText>
				<HeaderLockup>
					<HeaderLockupImage
						src="https://res.cloudinary.com/calm-com/image/upload/v1625183826/partner-portal/hr_referral.png"
						alt="Calm Partners"
					/>
					<HeaderLockupText>
						<HeaderLockupNumbers>{formatMessage(messages.referralNumbers)}</HeaderLockupNumbers>
						<HeaderLockupQuote>{formatMessage(messages.referralQuote)}</HeaderLockupQuote>
					</HeaderLockupText>
				</HeaderLockup>
			</HeaderRow>
			<GiftRow>
				<GiftIcon />
				<GiftHeader>{formatMessage(messages.referralCopyLink)}</GiftHeader>
				<GiftInput id="hr-referral-url" type="text" value={referralUrl} readOnly />
				<ShareRow>
					<SocialShareButtons event={'Referral'} url={referralUrl} />
				</ShareRow>
			</GiftRow>
			<HowItWorks />
			<TermsAndConditions>
				{formatMessage(messages.finePrint, {
					giftValue,
					linebreak: <br />,
					amazonLink: (...chunks: ReactNode[]) => (
						<a href="https://www.amazon.com/gc-legal" target="_blank" rel="noopener noreferrer">
							{chunks}
						</a>
					),
					termsLink: (...chunks: ReactNode[]) => (
						<a
							href="https://calm-partner-portal-public-assets-prod.s3.amazonaws.com/Calm+-+Business+Referral+Program+-+Terms+and+Conditions+(Final+8.22.22).pdf"
							target="_blank"
							rel="noopener noreferrer"
						>
							{chunks}
						</a>
					),
				})}
			</TermsAndConditions>
		</Container>
	);
}
