import copy from 'copy-to-clipboard';
import QRCode from 'qrcode';
import { FC, useState, useEffect, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { Button, TextButton, FontSizes, Loader } from '@calm-web/design-system';

import InitialPageAnalytics from '@/components/analytics/InitialPageAnalytics';
import LaunchEmailModal from '@/components/pages/Home/LaunchAnnouncementModal/LaunchEmailModal';
import CellContainer from '@/components/ui/CellContainer';
import ChecklistItem from '@/components/ui/Checklist';
import { StyledChecklist } from '@/components/ui/Checklist/styles';
import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import { usePartner } from '@/hooks/api/usePartner';
import { usePartnerStats } from '@/hooks/api/usePartnerStats';
import { useShouldShowBenefitEmailUpdates } from '@/hooks/useShouldShowBenefitEmailUpdates';
import { useShouldShowCustomLaunchEmail } from '@/hooks/useShouldShowCustomLaunchEmail';
import { setBannerMessage } from '@/store/actions';
import CalmCookie from '@/utils/CalmCookie';
import { SKU } from '@/utils/SkuEnums';
import { SkuToConfig } from '@/utils/SkuToConfig';
import { getErrorMessage } from '@/utils/apiRequest/errors';
import { createB2BPartnerSubscribeUrlPreview } from '@/utils/url';

import genericMessages from '../messages';
import {
	DesignateNote,
	ButtonGroup,
	LinkWrapper,
	DownloadIcon,
	DownloadLink,
	SetupButton,
	SetupSubtitle,
	SmallTextButton,
	StepLink,
	StepTask,
} from '../styles';
import messages from './messages';

export default function GetSetup(): ReturnType<FC> {
	const history = useHistory();
	const dispatch = useDispatch();
	const { search } = useLocation();
	const { partnerId } = useParams();
	const { formatMessage } = useIntl();
	const { logEvent } = useAnalytics();
	const [qrDataImage, setQrDataImage] = useState('');
	const shouldShowBenefitEmailUpdates = useShouldShowBenefitEmailUpdates();
	const [shouldShowCustomLaunchEmail, { loading }] = useShouldShowCustomLaunchEmail();
	const { data: partner } = usePartner(partnerId);
	const { data: stats, loading: statsLoading } = usePartnerStats(partnerId);
	const uniqueIdIsEmail = partner?.user_id_descriptor === 'Email' || partner?.user_id_descriptor === 'email';
	const canSendEmails = partner?.email_config_can_send_benefit_reminders && uniqueIdIsEmail;

	const url =
		partner?.redemption_url ??
		createB2BPartnerSubscribeUrlPreview({
			slug: partner?.slug,
			skuType: SkuToConfig[partner?.vouched_plan_sku ?? SKU.PREMIUM_CUSTOM].productSKUType,
		});

	useEffect(() => {
		if (!url) return;
		QRCode.toDataURL(url, (err: unknown, dataUrl: string) => {
			if (err) {
				logEvent('Partner QR Code Error', {
					message: getErrorMessage(err),
				});
			}
			setQrDataImage(dataUrl);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function copySignupUrl(): void {
		logEvent('Get Set Up : Copy Email Template', { type: 'EF or Access Codes' });
		if (url) {
			copy(url, {
				format: 'text/plain',
				onCopy: () => {
					dispatch(
						setBannerMessage({
							message: 'Signup Link copied to clipboard',
							isError: false,
							flash: true,
						}),
					);
				},
			});
		}
	}

	const onQrCodeDownload = (): void => {
		logEvent('Get Set Up : Download QR Code', { type: 'EF or Access Codes' });
	};

	const onDesignateClick = (): void => {
		history.push({
			pathname: `/${partnerId}/users`,
			search,
		});
	};

	const onLearnClick = (): void => {
		logEvent('Get Set Up : Visit Resources Page Clicked', { type: 'EF or Access Codes' });
		CalmCookie.set('learn-complete', 'true');
		history.push({
			pathname: `/${partnerId}/resources`,
			search,
		});
	};

	const onBenefitsClick = (): void => {
		logEvent('Get Set Up : Visit Benefits & Reminders Clicked', { type: 'EF or Access Codes' });
		CalmCookie.set('benefits-link-clicked', 'true');
		history.push({
			pathname: `/${partnerId}/account`,
			search,
		});
	};

	if (!partner) {
		return null;
	}

	if (loading) {
		return (
			<CellContainer>
				<Loader color="gray5" />
			</CellContainer>
		);
	}

	return (
		<CellContainer>
			<InitialPageAnalytics />
			<SetupSubtitle>{formatMessage(genericMessages.subtitle)}</SetupSubtitle>
			<StyledChecklist>
				<ChecklistItem
					stepCount={1}
					isCompleted={(stats?.numCoveredLives || 0) > 0 && !statsLoading}
					title={formatMessage(messages.designateTitle)}
					action={
						<SetupButton onPress={onDesignateClick} backgroundColor="blue3">
							{formatMessage(messages.designateButton)}
						</SetupButton>
					}
				>
					<div>{formatMessage(messages.designateSubtitle)}</div>
					<DesignateNote>
						{shouldShowBenefitEmailUpdates
							? formatMessage(messages.designateNote, {
									benefitslink: (...chunks: ReactNode[]) => (
										<SmallTextButton onClick={onBenefitsClick}>{chunks}</SmallTextButton>
									),
							  })
							: formatMessage(messages.oldDesignateNote)}
					</DesignateNote>
				</ChecklistItem>
				{shouldShowCustomLaunchEmail ? (
					<ChecklistItem
						stepCount={2}
						isCompleted={CalmCookie.get('announce-complete') === 'true'}
						title={formatMessage(genericMessages.announceTitle)}
						stackActions
						action={
							<ButtonGroup>
								<Button backgroundColor="transparent" hideShadow textColor="blue3" onPress={copySignupUrl}>
									Copy Sign-Up Link
								</Button>
								<Button
									backgroundColor="transparent"
									hideShadow
									textColor="blue3"
									href={qrDataImage}
									download
									onPress={onQrCodeDownload}
								>
									Download QR Code
								</Button>
								<LaunchEmailModal
									hideSendOption={!canSendEmails}
									signupLink={url}
									partnerId={partnerId}
									announceCookieName="announce-complete"
								/>
							</ButtonGroup>
						}
					>
						<div>{formatMessage(messages.announceSubtitle)}</div>
					</ChecklistItem>
				) : (
					<ChecklistItem
						stepCount={2}
						isCompleted={CalmCookie.get('announce-complete') === 'true'}
						title={formatMessage(genericMessages.announceTitle)}
						action={
							<LaunchEmailModal
								showOldTemplate
								hideSendOption
								signupLink={url}
								partnerId={partnerId}
								announceCookieName="announce-complete"
							/>
						}
					>
						<div>To sign up for Calm, your team members will need to know 2 things:</div>
						<StepTask>
							<b>
								{formatMessage(genericMessages.announceLink, {
									partnerlink: (
										<LinkWrapper>
											<StepLink id="partner-signup-link" href={url}>
												{url}
											</StepLink>
											<TextButton onClick={copySignupUrl} size={FontSizes.sm} hideUnderline>
												copy
											</TextButton>
										</LinkWrapper>
									),
								})}
							</b>
							<div>Alternatively, you can distribute a QR code to direct them to your subscribe link:</div>
							{qrDataImage && (
								<DownloadLink href={qrDataImage} download onClick={onQrCodeDownload}>
									<DownloadIcon />
									Download QR code (.png)
								</DownloadLink>
							)}
						</StepTask>
						<StepTask>{formatMessage(messages.announceEligibility)}</StepTask>
					</ChecklistItem>
				)}
				<ChecklistItem
					stepCount={3}
					isCompleted={CalmCookie.get('learn-complete') === 'true'}
					title={formatMessage(genericMessages.learnTitle)}
					action={
						<SetupButton onPress={onLearnClick} backgroundColor="blue3">
							{formatMessage(genericMessages.learnButton)}
						</SetupButton>
					}
				>
					{formatMessage(genericMessages.learnSubtitle)}
				</ChecklistItem>
			</StyledChecklist>
		</CellContainer>
	);
}
