import { rgba } from 'polished';
import styled from 'styled-components';
import { palette } from 'styled-tools';

import { units } from '@calm-web/design-system';

export const CalloutContainer = styled.div`
	display: grid;
`;

export const CalloutBase = styled.div`
	background-color: ${(props): string => rgba(palette('black')(props), 0.02)};
	border: 1px solid ${palette('gray1')};
	border-radius: 4px;
	padding: ${units(2)};
`;
