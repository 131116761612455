/* eslint-disable @next/next/no-img-element */
import { ReactElement } from 'react';

import {
	Content,
	ContentWrapper,
	FaqAnswer,
	FaqQuestion,
	Header,
	SectionBody,
	SectionHeader,
	Title,
	Updated,
} from '../styles';

export default function AdminFaq(): ReactElement {
	return (
		<div>
			<Header>
				<img
					src="https://res.cloudinary.com/calm-com/image/upload/v1623175960/assets/images/logos/calm_square_logo.png"
					alt="Calm Logo"
				/>
				<Title>Calm Business Admin FAQ</Title>
				<Updated>Last Updated: November 12, 2021</Updated>
			</Header>

			<ContentWrapper>
				<Content>
					<SectionHeader>Table of Contents</SectionHeader>
					<SectionBody>
						<ul>
							<li>
								<a href="#accessing">Accessing Partner Portal</a>
							</li>
							<li>
								<a href="#getStarted">Getting Started with your Calm Business benefit</a>
							</li>
							<li>
								<a href="#managing">Managing Calm as a benefit</a>
							</li>
							<li>
								<a href="#security">Security and Privacy</a>
							</li>
							<li>
								<a href="#general">General Calm Questions</a>
							</li>
						</ul>
					</SectionBody>

					<SectionHeader id="accessing">Accessing Partner Portal</SectionHeader>
					<SectionBody>
						<FaqQuestion>Where do I access the Calm Partner Portal?</FaqQuestion>
						<FaqAnswer>
							The Calm Partner Portal is located at partner.calm.com and can be accessed by logging in with
							your Partner Portal credentials.
						</FaqAnswer>

						<FaqQuestion>Can I have more than one Administrator on the Partner Portal?</FaqQuestion>
						<FaqAnswer>
							Yes! You can have multiple Administrators on the Partner Portal. If you are interested in adding
							more Administrators, please reach out to Calm’s Support team for assistance. You can reach the
							team by going{' '}
							<a href="https://support.calm.com/hc/en-us?solvvyProvidedByEmployer" target="_new">
								here
							</a>{' '}
							and clicking on “Continue” in the pop-up box to access and fill out our contact form. If you run
							into any issues, please see{' '}
							<a href="https://support.calm.com/hc/en-us/articles/115002582888-Contact-Us" target="_new">
								this article
							</a>{' '}
							for more details on how to contact us.
							<p />
							Please note, Administrators will have the ability to manage who has access to the Calm benefit.
							Additionally, all Administrators will have access to the Plan &amp; Payment tab (if it appears)
							inside the Partner Portal.
						</FaqAnswer>

						<FaqQuestion>How do I reset my password for Partner Portal?</FaqQuestion>
						<FaqAnswer>
							Visit{' '}
							<a href="https://partner.calm.com" target="_new">
								partner.calm.com
							</a>
							, enter your email address, and where it says, “Forgot your Calm Admin password? Click Here,”
							select “Click Here.” An email will be sent to the email address on record to reset your
							password.
						</FaqAnswer>

						<FaqQuestion>
							Do I use the same Partner Portal credentials to sign up for my Calm subscription?
						</FaqQuestion>
						<FaqAnswer>
							When activating the Calm benefit for yourself, you will create a personal Calm account that is
							separate from your Partner Portal login. You may choose to use the same credentials in both
							places if you would like, or you can use a different email and password when creating your
							personal Calm account. Updating your password for Partner Portal will not affect the password on
							your personal Calm account, and vice versa.
						</FaqAnswer>

						<FaqQuestion>
							Can I use an email alias as an Administrator login to the Partner Portal?
						</FaqQuestion>
						<FaqAnswer>
							You may use an email alias to manage the Partner Portal. Please note, Administrators will have
							the ability to manage who has access to the Calm benefit. Additionally, all Administrators will
							have access to the Plan &amp; Payment tab (if it appears) inside the Partner Portal.
						</FaqAnswer>

						<FaqQuestion>Is Calm Partner Portal supported on any web browser?</FaqQuestion>
						<FaqAnswer>
							Partner Portal is optimized for Google Chrome, and will also work on most popular web browsers
							including Safari, Firefox, and Microsoft Edge. Partner Portal is not supported on Internet
							Explorer. Internet Explorer is no longer updated and maintained by Microsoft, so we can’t
							guarantee compatibility.
						</FaqAnswer>
					</SectionBody>

					<SectionHeader id="getStarted">Getting Started with your Calm Business benefit</SectionHeader>
					<SectionBody>
						<FaqQuestion>Can I use employees’ first and last name for my eligibility file?</FaqQuestion>
						<FaqAnswer>
							No. The eligibility file must contain a unique identifier. This will usually be the employee’s
							work email address, though in some cases it may be an employee ID number or other unique ID. The
							eligible user will use the unique identifier to unlock Calm Premium for free.
						</FaqAnswer>

						<FaqQuestion>Will emails be sent to employees when I upload the eligibility file?</FaqQuestion>
						<FaqAnswer>
							When an eligibility file is uploaded, eligible users will not be notified by Calm of their
							access to the Calm benefit. You will need to communicate their access to Calm — check out the
							‘Communicating This Benefit’ section of the Partner Portal Admin Guide for further information.
							The Partner Portal Admin Guide can be found on the Resources tab of the Partner Portal.
						</FaqAnswer>

						<FaqQuestion>Are there templates I can use to launch Calm to my employees?</FaqQuestion>
						<FaqAnswer>
							Yes, you can find an email template and other launch resources on the “Get set up“ tab of the
							Partner Portal. If you do not see this tab, you can also find these resources in the Partner
							Portal Admin Guide, which can be found on the Resources tab of the Partner Portal.
						</FaqAnswer>

						<FaqQuestion>
							Can the same unique identifier be used by multiple individuals to activate a subscription?
						</FaqQuestion>
						<FaqAnswer>
							No. Once an individual sets up their Calm account and enters their specific unique identifier
							(work email, employee ID, etc.), that unique identifier will no longer be active as a validation
							key.
						</FaqAnswer>

						<FaqQuestion>When can my employees access Calm as a benefit?</FaqQuestion>
						<FaqAnswer>
							Employees can unlock their Calm Premium subscription after your contract start date has occurred
							and an eligibility file has been uploaded into the Partner Portal.
							<p />
							If you see a Contract Details section under the Account tab in the Partner Portal, your
							“Contract Start date” will be listed there.
							<p />
							If you do not have a Contract Details section, please refer to your partnership agreement to
							review your services start date.
						</FaqAnswer>
					</SectionBody>

					<SectionHeader id="managing">Managing Calm as a benefit</SectionHeader>
					<SectionBody>
						<FaqQuestion>What reporting does Calm provide?</FaqQuestion>
						<FaqAnswer>
							As an Administrator of Calm Business, you have visibility into the percentage of your eligible
							population who have signed up for Calm, the percentage of those individuals who have been active
							in Calm, as well as an overview of the number of sessions, by type, that have been listened to
							by your employees. You may also segment your reporting by adding additional columns to your
							eligibility file.
							<p />
							Please note, data will be shared in aggregate, as we do not share identifying information per
							our{' '}
							<a href="https://www.calm.com/en/privacy-policy" target="_new">
								Privacy Policy
							</a>
							. Reporting data will only become available once 10 or more users have redeemed their Calm
							benefit and segmented reporting will only show data if there are at least 10 users within the
							segment.
						</FaqAnswer>

						<FaqQuestion>What is segmented reporting?</FaqQuestion>
						<FaqAnswer>
							Segmented reporting allows Administrators to add identifying variables to their eligibility file
							to segment reporting in Partner Portal. Partners may choose to segment their population based on
							geographic/regional location and/or department/employee classification. Please note, we
							anticipate segmented reporting to be available for partners utilizing SSO in Spring 2022.
						</FaqAnswer>

						<FaqQuestion>How do I set up segmented reporting in my eligibility file?</FaqQuestion>
						<FaqAnswer>
							1. Download the most recently uploaded eligibility file from the Partner Portal under the Users
							tab.
							<p />
							2. Add new identifying variables in subsequent columns with unique headers. Please note, the
							first column of your eligibility file should still be the employee’s work email address,
							employee ID number or other unique ID.
							<p />
							3. Click the Users tab within the Partner Portal and upload the new eligibility file. You will
							receive a notification that identifies the number of new employees added and the number of
							employees who were removed. Once you have confirmed these changes, click on Commit changes.
							<p />
							Please note, we do not encourage the use of zip codes as an identifying variable, due to the
							fact that a large volume of results would be returned in the segmented report, thus making it
							difficult to use.
						</FaqAnswer>

						<FaqQuestion>How can I use segmented reporting?</FaqQuestion>
						<FaqAnswer>
							Administrators can utilize segmented reporting to identify segments who have lower signup or
							engagement rates and promote Calm directly to them or to identify usage trends amongst certain
							populations.
							<p />
							Examples of identifying variables include:
							<ul>
								<li>Location: State, region of US, country</li>
								<li>University: Students vs faculty vs staff</li>
								<li>Retail: Corporate vs store employees</li>
								<li>Employee type: Full-time vs part-time vs contractor</li>
								<li>Team: Customer, Finance, Legal, etc</li>
								<li>Other: Gender, age</li>
							</ul>
							<p />
							Please note, Administrators can enter more than one word, such as ”city, state” as an
							identifying variable.
						</FaqAnswer>

						<FaqQuestion>Where can I view reporting?</FaqQuestion>
						<FaqAnswer>
							To view your population’s analytics, click the Reporting tab in the Partner Portal and scroll
							over the text to view additional data points.
							<p />
							Please note, segmented reports will be displayed on a 3 month rolling window.
						</FaqAnswer>

						<FaqQuestion>Is there an additional cost to use segmented reporting?</FaqQuestion>
						<FaqAnswer>No, there is no additional cost for segmented reporting.</FaqAnswer>

						<FaqQuestion>
							What can I do to increase the number of employees signing up and using Calm?
						</FaqQuestion>
						<FaqAnswer>
							The Partner Portal will always contain a library of resources that you can utilize to maintain
							and increase engagement with your employees.
							<p />
							In the Resources tab, you will find engagement tools including:
							<li>Engagement Strategies Document</li>
							<li>Calendar of upcoming Calm educational and training events</li>
							<li>Web links to the Calm Blog and Calm Business Blog</li>
						</FaqAnswer>

						<FaqQuestion>What do I do when someone leaves or joins the company?</FaqQuestion>
						<FaqAnswer>
							When someone leaves or joins your company, you will need to update the eligibility file with
							your most recent employee roster. To update your eligibility file, please see below.
							<p />
							1. Download the most recently uploaded eligibility file from the Partner Portal under the Users
							tab.
							<p />
							2. Remove the column that contains the timestamp.
							<p />
							3. Add any new eligible employees to the list and remove anyone who should no longer have access
							to the benefit (ie employees who have left the company).
							<p />
							4. Click the Users tab within Partner Portal and upload the new eligibility file. You will
							receive a notification that identifies the number of new employees added and the number of
							employees who were removed.
							<p />
							Users will be able to sign up for their Calm Premium account as soon as the file is uploaded, as
							long as the contract start date has passed. If you have mistakenly removed or added the wrong
							individuals, simply update the eligibility file and reupload the correct file.
						</FaqAnswer>

						<FaqQuestion>How do I add more covered lives?</FaqQuestion>
						<FaqAnswer>
							If you do not see a Plan & Payment tab, please reach out to your Calm Account Executive who you
							worked with to complete your partnership agreement.
							<p />
							If you see the Plan & Payment tab inside the Partner Portal, you can purchase additional covered
							lives there. Please note if you would like to increase the number of covered lives to more than
							100, please{' '}
							<a href="https://business.calm.com/book-a-demo/" target="_new">
								contact sales
							</a>{' '}
							to discuss.
						</FaqAnswer>

						<FaqQuestion>
							Can you provide me with a list of employees who have not yet signed up for their Calm
							subscription so that the Admin can send a reminder?
						</FaqQuestion>
						<FaqAnswer>
							Per our{' '}
							<a href="https://www.calm.com/en/privacy-policy" target="_new">
								Privacy Policy
							</a>
							, we aren’t able to share identifying information for those who have signed up for the Calm app.
							We recommend sending a general reminder to everyone, including employees who have already signed
							up to highlight what to check out in the Calm app and how to sign up!
						</FaqAnswer>

						<FaqQuestion>
							If an employee’s email address changes from what is in the original eligibility file, will they
							lose their Calm benefit? (gets married, new business line, etc.)
						</FaqQuestion>
						<FaqAnswer>
							If the employee’s old email address is replaced with a new email on the Eligibility File, the
							individual will need to reactivate their Calm benefit utilizing the new email address as their
							unique identifier. The employee will be able to reactivate the benefit on their existing Calm
							account and will not need to create a new account.
							<p />
							Please note, when an email address is removed or replaced on the eligibility file, the
							associated Calm benefit will lapse on the first day of the following month.
						</FaqAnswer>

						<FaqQuestion>
							What do I do if my employee signed up for Calm but they are prompted to pay in the Calm app or
							the Premium content is locked?
						</FaqQuestion>
						<FaqAnswer>
							Confirm your employee visited your company’s unique Calm sign-up link on a web browser and
							activated their Calm benefit there, using their unique identifier that is currently on your
							eligibility file. They will also need to be logged in to the Calm app with the same Calm account
							credentials they used when activating their benefit through your unique Calm sign-up link.
						</FaqAnswer>

						<FaqQuestion>
							What should I do if an employee receives an “Oops! Something went wrong” error message while
							trying to redeem their Calm benefit?
						</FaqQuestion>
						<FaqAnswer>
							Please ensure that the unique identifier they are using to activate their Calm benefit matches
							with what is included on your eligibility file. We recommend checking for any typos or extra
							spaces included at the beginning or end of the email address or employee ID on file.
							<p />
							If you have double checked with the employee that the unique identifier used matches exactly
							with the uploaded eligibility file, then please have the employee reach out directly for
							assistance. They can reach Calm’s Support team by going{' '}
							<a href="https://support.calm.com/hc/en-us?solvvyProvidedByEmployer" target="_new">
								here
							</a>{' '}
							and clicking on “Continue” in the pop-up box to access and fill out form. If they run into any
							issues, please see{' '}
							<a href="https://support.calm.com/hc/en-us/articles/115002582888-Contact-Us" target="_new">
								this article
							</a>{' '}
							for more details on how to contact us. Employees should include their unique identifier and
							their company’s unique Calm sign-up link, as well as a screenshot of their issue, when possible.
						</FaqAnswer>

						<FaqQuestion>
							My employee tried activating their Calm benefit, but they are receiving the “Already redeemed”
							error message. Now what?
						</FaqQuestion>
						<FaqAnswer>
							We recommend that your employee log in to their existing Calm account to see if their
							subscription is active. If their Premium content is still locked, please have your employee
							reach out directly for assistance. They can reach Calm’s Support team by going{' '}
							<a href="https://support.calm.com/hc/en-us?solvvyProvidedByEmployer" target="_new">
								here
							</a>{' '}
							and clicking on “Continue” in the pop-up box to access and fill out our contact form. If they
							run into any issues, please see{' '}
							<a href="https://support.calm.com/hc/en-us/articles/115002582888-Contact-Us" target="_new">
								this article
							</a>{' '}
							for more details on how to contact us. Employees should include their unique identifier and
							their company’s unique Calm sign-up link, as well as a screenshot of their issue, when possible.
						</FaqAnswer>

						<FaqQuestion>Where can employees download the Calm app?</FaqQuestion>
						<FaqAnswer>
							Employees can download the Calm app through one of the links below: <p />
							<li>
								Android:{' '}
								<a href="https://play.google.com/store/apps/details?id=com.calm.android" target="_new">
									https://play.google.com/store/apps/details?id=com.calm.android
								</a>
							</li>
							<li>
								iPhone:{' '}
								<a
									href="https://itunes.apple.com/us/app/calm-meditate-relax-guided/id571800810?mt=8"
									target="new"
								>
									https://itunes.apple.com/us/app/calm-meditate-relax-guided/id571800810?mt=8
								</a>
							</li>
							<li>
								iPad:{' '}
								<a href="https://itunes.apple.com/us/app/calm.com/id571800810?ls=1&mt=8" target="_new">
									https://itunes.apple.com/us/app/calm.com/id571800810?ls=1&mt=8
								</a>
							</li>
							<li>
								For more information,{' '}
								<a
									href="https://support.calm.com/hc/en-us/articles/115002474147-On-what-type-of-device-can-I-use-Calm-"
									target="_new"
								>
									see here
								</a>
								.
							</li>
						</FaqAnswer>

						<FaqQuestion>
							Can an employee activate the Calm benefit if they already have a paid subscription on their
							account?
						</FaqQuestion>
						<FaqAnswer>
							An employee can activate their Calm benefit on their existing Calm account as long as there is
							no renewing subscription currently active on their account. If they already have a renewing
							subscription, they can cancel auto-renewal on the subscription and then proceed with the
							activation of their Calm benefit. For step by step instructions, please view our Help Center
							article:{' '}
							<a
								href="https://support.calm.com/hc/en-us/articles/115002473607-How-do-I-turn-off-auto-renewal-or-cancel-my-subscription-"
								target="_new"
							>
								How do I turn off auto-renewal or cancel my subscription?
							</a>{' '}
							Please note that the benefit will apply on the Calm account as soon as it is activated, even if
							this overlaps with remaining time on a previous subscription that has been canceled.
						</FaqAnswer>

						<FaqQuestion>What do I do if an employee still needs help? </FaqQuestion>
						<FaqAnswer>
							Employees can visit our Help Center at{' '}
							<a href="https://support.calm.com/hc/en-us" target="_new">
								support.calm.com
							</a>{' '}
							to review our how-to articles. If they can’t find answers to their questions on the Help Center,
							please have them reach out for assistance with any technical issues. They can reach Calm’s
							Support team by going{' '}
							<a href="https://support.calm.com/hc/en-us?solvvyProvidedByEmployer" target="_new">
								here
							</a>{' '}
							and clicking on “Continue” in the pop-up box to access and fill out our contact form. If they
							run into any issues, please see{' '}
							<a href="https://support.calm.com/hc/en-us/articles/115002582888-Contact-Us" target="_new">
								this article
							</a>{' '}
							for more details on how to contact us. Employees should include their unique identifier and
							their company’s unique Calm sign-up link, as well as a screenshot of their issue, when possible.
							<p />
							IMPORTANT: Please have the employee reach out to Calm’s Support team directly, rather than
							having an Administrator reach out on their behalf. Calm’s Support team will not be able to share
							specific account information with anyone other than the individual Calm account owner who needs
							assistance.
						</FaqAnswer>
					</SectionBody>

					<SectionHeader id="security">Security & Privacy</SectionHeader>
					<SectionBody>
						<FaqQuestion>
							How are the email addresses of employees and users in general protected while at rest in your
							environment?
						</FaqQuestion>
						<FaqAnswer>They are encrypted at rest with AES-256 bit encryption.</FaqAnswer>

						<FaqQuestion>Is partner employee data sent to 3rd parties?</FaqQuestion>
						<FaqAnswer>
							Please note, we do not sell user information to third parties, but rather we use third party
							services to analyze user data and optimize their experience.
						</FaqAnswer>

						<FaqQuestion>
							Are there domains that our team should safelist to ensure that Calm’s emails get delivered to
							our population?
						</FaqQuestion>
						<FaqAnswer>
							Yes! Here are the domains/email addresses that we would recommend safelisting:
							<p />
							<li>Calm.com</li>
							<li>em.calm.com</li>
							<li>Breathe.calm.com</li>
							<li>support@calm.com</li>
							<li>feedback@calm.com</li>
							<li>support@calmhelp.zendesk.com</li>
							<p />
							For more information on Calm’s Privacy Policy, please see{' '}
							<a href="https://www.calm.com/en/privacy-policy" target="_new">
								here
							</a>
						</FaqAnswer>
					</SectionBody>

					<SectionHeader id="general">General Calm Questions</SectionHeader>
					<SectionBody>
						<FaqQuestion>Where is Calm available?</FaqQuestion>
						<FaqAnswer>
							<a href="https://www.calm.com" target="_new">
								The Calm website
							</a>{' '}
							is available everywhere you have a solid WiFi connection or data connection, and the Calm app is
							available for any device that can download apps from the Apple App Store or Google Play Store.
							<p />
							Please note that, as Google is currently not accessible in China, the Calm app is not available
							to download through the Google Play Store there. Calm is available for iOS devices through the
							Apple App Store and also through our website at www.calm.com for users in China.
						</FaqAnswer>

						<FaqQuestion>Which languages does Calm support?</FaqQuestion>
						<FaqAnswer>
							For an up-to-date list of countries Calm supports, see{' '}
							<a
								href="https://support.calm.com/hc/en-us/articles/360008697573-What-languages-are-available-on-Calm-"
								target="_new"
							>
								here
							</a>
							.
						</FaqAnswer>

						<FaqQuestion>
							What research/evidence can you share with us regarding the benefits of Calm for employees?
						</FaqQuestion>
						<FaqAnswer>
							For up-to-date information on Calm Science, see{' '}
							<a href="https://business.calm.com/resources/clinical-studies/" target="_new">
								here
							</a>
							.
						</FaqAnswer>
					</SectionBody>
				</Content>
			</ContentWrapper>
		</div>
	);
}
