import styled from 'styled-components';
import { palette } from 'styled-tools';

import { fontSize, FontSizes, fontWeight, FontWeights, units } from '@calm-web/design-system';

export const Header = styled.h3`
	display: block;
	width: 100%;
	${fontSize(FontSizes.lg)};
	${fontWeight(FontWeights.Regular)};
`;

export const PlaceholderContainer = styled.div`
	text-align: center;
	margin: ${units(3, 5)};
`;

export const PlaceholderIcon = styled.div``;

export const PlaceholderTitle = styled.div`
	${fontWeight(FontWeights.Medium)};
	margin-bottom: ${units(1)};
`;

export const PlaceholderMessage = styled.div``;

export const ResourceLink = styled.a`
	color: ${palette('blue3')};
`;
