import Image from 'next/image';
import styled from 'styled-components';

import {
	units,
	Text,
	FontWeights,
	TextElements,
	CloseButton as DsCloseButton,
	SecondaryButton,
} from '@calm-web/design-system';

export const PreviewModal = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #00000088;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
`;

export const ModalContent = styled.div`
	background-color: white;
	padding: ${units(2.5)};
	position: relative;
	display: flex;
	flex-direction: column;
`;

export const HeaderBar = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: ${units(2.5)};
	border-bottom: 1px solid #e0e0e0;
	padding-bottom: ${units(2.5)};
`;

export const ReferralLogo = styled(Image).attrs(props => ({
	width: 64,
	height: 64,
	src: props.src ?? '/_n/images/employerco-square.png',
	alt: 'Your recommendation logo',
}))`
	border-radius: ${units(1.5)};
	flex: 0 0 auto;
	margin-right: ${units(1.5)};
`;

export const ReferralTitle = styled(Text).attrs({ el: TextElements.Div, weight: FontWeights.Demi })`
	flex-grow: 1;
	line-height: 150%;
	margin-bottom: ${units(0.5)};
`;

export const CloseButton = styled(DsCloseButton)`
	align-self: flex-start;
	margin: ${units(-2.5, -2.5, 0, 0)};
`;

export const Description = styled(Text)`
	margin-bottom: ${units(2.5)};
	white-space: pre-line;
`;

export const LinkOutButton = styled(SecondaryButton).attrs(props => ({
	borderColor: props.Icon ? 'blue3' : 'transparent',
}))`
	border-radius: ${units(3)};
	margin-bottom: ${units(2)};
`;
