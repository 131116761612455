import styled from 'styled-components';
import { palette } from 'styled-tools';

import { FontSizes, TextButton, fontSize, units } from '@calm-web/design-system';

import Copy from 'icons/copy-outline.svg';
import RawFacebookIcon from 'icons/portal-facebook-square.svg';

export const CopyIcon = styled(Copy)`
	fill: ${palette('gray2')};
`;

export const ShareText = styled.span`
	margin-left: ${units(1)};
	${fontSize(FontSizes.sm)};
	/* Lower the text a tiny bit so it looks more vertically centered */
	position: relative;
	top: 1px;
`;

export const FacebookIcon = styled(RawFacebookIcon)`
	width: 20px;
	color: ${palette('facebookBlue')};
`;

export const ShareContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	a,
	button {
		display: flex;
		align-items: center;
	}
`;

export const ShareButtonIconContainer = styled.div`
	width: 24px;
	height: 24px;
	margin-right: ${units(1)};
	display: flex;
	align-items: center;
	justify-content: center;
	svg {
		max-width: 24px;
		max-height: 24px;
	}
`;

export const ShareButton = styled(TextButton).attrs({
	size: FontSizes.sm,
	textColor: 'text',
	hideUnderline: true,
})`
	margin-top: ${units(2)};
`;
