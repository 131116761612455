import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { Loader } from '@calm-web/design-system';

import { useTriggerPathwayAnnouncementEmail } from '@/hooks/api/useTriggerIterableSend';
import { validateString } from '@/utils/helpers';

import { Header } from '../styles';
import messages from './messages';
import { TestEmailSendForm, Container, TestEmailSendFormButton, TestEmailSendFormInput } from './styles';

const TestEmailSend: React.FC = () => {
	const { formatMessage } = useIntl();
	const [email, setEmail] = useState('');
	const [triggerPathwayAnnouncementEmail, { loading, apiResErrors, error, didSucceed }] =
		useTriggerPathwayAnnouncementEmail({ isTest: true });
	const [isError, setIsError] = useState<boolean | undefined>();
	const [errorMessage, setErrorMessage] = useState<string | undefined>();

	const invalidEmailErrorMessage = formatMessage(messages.triggerTestEmailInvalidEmailError);
	const generalTestEmailSendErrorMessage = formatMessage(messages.triggerTestEmailGeneralError);

	useEffect(() => {
		if (didSucceed) {
			setIsError(false);
		}
	}, [didSucceed]);

	useEffect(() => {
		if (apiResErrors || error) {
			const isInvalidEmailError =
				apiResErrors && apiResErrors.length > 0 && Boolean(apiResErrors[0].invalidFields?.email);
			if (isInvalidEmailError) {
				setErrorMessage(invalidEmailErrorMessage);
			} else {
				setErrorMessage(generalTestEmailSendErrorMessage);
			}
			setIsError(true);
		}
	}, [apiResErrors, error, invalidEmailErrorMessage, generalTestEmailSendErrorMessage]);

	return (
		<Container>
			<Header>{formatMessage(messages.testEmailTitle)}</Header>
			<TestEmailSendForm
				onSubmit={async e => {
					e.preventDefault();
					setIsError(undefined);
					setErrorMessage(undefined);
					if (!validateString('email', email)) {
						setErrorMessage(invalidEmailErrorMessage);
						setIsError(true);
						return;
					}
					await triggerPathwayAnnouncementEmail(email, formatMessage(messages.triggerTestEmailError));
				}}
			>
				<TestEmailSendFormInput
					value={email}
					name="email-address"
					onChange={e => {
						setIsError(undefined);
						setErrorMessage(undefined);
						setEmail(e.target.value);
					}}
					label="Email address"
					error={errorMessage}
					isValid={isError === undefined ? undefined : !isError}
				/>
				<TestEmailSendFormButton type="submit" backgroundColor="blue3" isDisabled={email.length === 0}>
					{loading ? <Loader /> : formatMessage(messages.testEmailButton)}
				</TestEmailSendFormButton>
			</TestEmailSendForm>
		</Container>
	);
};

export default TestEmailSend;
