import styled, { css } from 'styled-components';
import { palette, ifNotProp } from 'styled-tools';

import { Badge, Button, ButtonSizes, units } from '@calm-web/design-system';

export const LinkWrapper = styled(Button).attrs({
	backgroundColor: 'white',
	fullWidth: true,
	size: ButtonSizes.Sm,
	contentAlignment: 'left',
	hideShadow: true,
})<{ shouldHideBorder?: boolean }>`
	&& {
		padding-left: 0;
	}

	${ifNotProp(
		'shouldHideBorder',
		css`
			&:not(:last-child) {
				border-bottom: 1px solid ${palette('gray1')};
			}
		`,
	)}
`;

export const ComingSoonBadge = styled(Badge).attrs({
	backgroundColor: 'gradientLightGreen',
	children: 'Coming Soon!',
	textColor: 'gradientBreatheBalance',
})`
	margin-left: ${units(1)};
`;
