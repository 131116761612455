import styled from 'styled-components';

import { units } from '@calm-web/design-system';

import WarningSvg from 'icons/warning-filled.svg';

export const WarningIcon = styled(WarningSvg)`
	margin: ${units(0, 0, 3, 0)};
	width: ${units(6)};
	height: auto;
`;

export const CenteredContent = styled.div`
	text-align: center;
	margin-top: ${units(1)};

	p {
		margin-bottom: ${units(2)};
	}

	p:last-child {
		margin-bottom: ${units(0)};
	}
`;
