import { defineMessages } from 'react-intl';

export default defineMessages({
	hello: {
		id: 'users.signupReminder.email.hello',
		defaultMessage: 'Hello,',
		description: 'Link text for showing email sample',
	},
	intro: {
		id: 'users.signupReminder.email.intro',
		defaultMessage:
			'{partnerName} has partnered with Calm to offer you free, 24/7 access to meditations, Sleep Stories, music and more self-care resources. And it works—84% of Calm users report better overall mental health.',
		description: 'First line of text in the survey email',
	},
	description: {
		id: 'users.signupReminder.email.description',
		defaultMessage: 'Activate your account today and take care of your mental well-being anytime, anywhere.',
		description: 'A prompt asking the user to give positive feedback about their app usage',
	},
	getStarted: {
		id: 'users.signupReminder.email.getStarted',
		defaultMessage: 'Get Started',
		description: 'CTA button text in the survey email asking users for feedback',
	},
	startExploring: {
		id: 'users.signupReminder.email.startExploring',
		defaultMessage: 'Start Exploring',
		description: 'Text in survey email explaning that feedback will be anonymous',
	},
	kickstart: {
		id: 'users.signupReminder.email.kickstart',
		defaultMessage:
			'Kickstart your day, take a quick break, or fall asleep faster with some of our favorite narrators.',
		description: 'Text in survey email explaning that feedback will be anonymous',
	},
	thankYou: {
		id: 'users.signupReminder.email.thankYou',
		defaultMessage: 'Warmly,',
		description: 'Thank you message in survey email',
	},
	signature: {
		id: 'users.signupReminder.email.signature',
		defaultMessage: 'The Calm Team',
		description: 'Signature line in the survey email',
	},
	confirmationCopy: {
		id: 'users.signupReminder.modal.signature',
		defaultMessage:
			'Please confirm you wish to send this additional reminder email to those who have not yet redeemed.',
		description: 'Confirmation copy for sending the manual sign up reminder emails.',
	},
	exampleOfEmail: {
		id: 'users.signupReminder.modal.exampleOfEmail',
		defaultMessage: 'Example of the email that will be sent.',
		description: 'Label on collapsable button to show an example of the reminder email.',
	},
	sendReminderCta: {
		id: 'users.signupReminder.modal.sendReminderCta',
		defaultMessage: 'Send reminder',
		description: 'Button copy to send the reminder emails.',
	},
	cancel: {
		id: 'users.signupReminder.modal.cancel',
		defaultMessage: 'Cancel',
		description: 'Cancel button copy.',
	},
});
