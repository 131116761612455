import { defineMessages } from 'react-intl';

export default defineMessages({
	title: {
		id: 'reporting.highlightedFeedback.title',
		defaultMessage: 'Here’s what your members have been saying about Calm as a benefit:',
		description: 'title message to show above highlighted feedback',
	},
	subtitle: {
		id: 'reporting.highlightedFeedback.subtitle',
		defaultMessage: 'Learn how Calm asks your members for feedback',
		description: 'Message linking to explanation of how Calm asks members for feedback',
	},
	modalTitle: {
		id: 'reporting.highlightedFeedback.modalTitle',
		defaultMessage: 'How Calm asks for feedback',
		description: 'Title for modal explaining how employees are asked for feedback',
	},
	modalText: {
		id: 'reporting.highlightedFeedback.modalText',
		defaultMessage:
			// eslint-disable-next-line max-len
			'Calm periodically asks your active covered lives for anonymous feedback about their Calm benefit, so you can use that feedback when you’re deciding to renew your plan.<ul><li>All feedback will remain anonymous.</li><li>Surveys will be sent 3 months after your coverage began, then once a year.</li><li>Surveys will be sent to all employees actively using Calm. We’ll use the e-mail addresses connected to their Calm accounts, not their work e-mail address.</li></ul>',
		description: 'Explaining how employees are asked for feedback',
	},
	seeAllFeedback: {
		id: 'reporting.highlightedFeedback.seeAllFeedback',
		defaultMessage: 'See all feedback',
		description: 'Button to show all feedback from employees',
	},
	emptyState: {
		id: 'reporting.highlightedFeedback.emptyState',
		defaultMessage: 'We don’t have any employee quotes just yet.',
		description: 'Message for when there is not yet any employee feedback',
	},
});
