import copy from 'copy-to-clipboard';
import { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { EmailShareButton, FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';

import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import { setBannerMessage } from '@/store/actions';

import GmailIcon from 'icons/gmail.svg';
import LinkedinIcon from 'icons/linkedin.svg';
import EmailIcon from 'icons/mail-filled.svg';
import TwitterIcon from 'icons/twitter.svg';

import messages from './messages';
import {
	CopyIcon,
	FacebookIcon,
	ShareButton,
	ShareButtonIconContainer,
	ShareContainer,
	ShareText,
} from './styles';

interface SocialShareButtonsProps {
	url: string;
	event: string;
	showCopy?: boolean;
	showLinkedin?: boolean;
	showTwitter?: boolean;
	showEmail?: boolean;
	showGmail?: boolean;
	showFacebook?: boolean;
	afterCopy?: () => void;
}

const NOOP = (): void => {};

function SocialShareButtons({
	url,
	event,
	showCopy = true,
	showLinkedin = true,
	showTwitter = true,
	showEmail = true,
	showGmail = true,
	showFacebook = true,
	afterCopy = NOOP,
}: SocialShareButtonsProps): ReactElement {
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();
	const { logEvent } = useAnalytics();

	function onShareClick(buttonType: string): () => void {
		return () => {
			logEvent(`${event} : Social Share Button : Clicked`, {
				buttonType,
				url,
			});
		};
	}

	function copyReferralUrl(): void {
		copy(url, {
			format: 'text/plain',
			onCopy: () => {
				logEvent(`${event} : Copy URL : Clicked`, {
					url,
				});
				dispatch(
					setBannerMessage({
						message: `${event} url copied to clipboard`,
						isError: false,
						flash: true,
					}),
				);
			},
		});
		afterCopy();
	}

	return (
		<ShareContainer>
			{showCopy && (
				<ShareButton className="react-share__ShareButton" onClick={copyReferralUrl}>
					<ShareButtonIconContainer>
						<CopyIcon />
					</ShareButtonIconContainer>
					<ShareText>{formatMessage(messages.shareIconsCopyLinkText)}</ShareText>
				</ShareButton>
			)}
			{showLinkedin && (
				<LinkedinShareButton url={url} beforeOnClick={onShareClick('Linkedin')}>
					<ShareButtonIconContainer>
						<LinkedinIcon />
					</ShareButtonIconContainer>
					<ShareText>LinkedIn</ShareText>
				</LinkedinShareButton>
			)}
			{showTwitter && (
				<TwitterShareButton url={url} beforeOnClick={onShareClick('Twitter')}>
					<ShareButtonIconContainer>
						<TwitterIcon />
					</ShareButtonIconContainer>
					<ShareText>Twitter</ShareText>
				</TwitterShareButton>
			)}
			{showEmail && (
				<EmailShareButton url={url} beforeOnClick={onShareClick('Email')}>
					<ShareButtonIconContainer>
						<EmailIcon />
					</ShareButtonIconContainer>
					<ShareText>Email</ShareText>
				</EmailShareButton>
			)}
			{showGmail && (
				<ShareButton
					as="a"
					target="_blank"
					rel="noopener noreferrer"
					href={`https://mail.google.com/mail/?view=cm&body=${url}`}
					className="react-share__ShareButton"
					onClick={onShareClick('Gmail')}
				>
					<ShareButtonIconContainer>
						<GmailIcon />
					</ShareButtonIconContainer>
					<ShareText>Gmail</ShareText>
				</ShareButton>
			)}
			{showFacebook && (
				<FacebookShareButton url={url} beforeOnClick={onShareClick('Facebook')}>
					<ShareButtonIconContainer>
						<FacebookIcon />
					</ShareButtonIconContainer>
					<ShareText>Facebook</ShareText>
				</FacebookShareButton>
			)}
		</ShareContainer>
	);
}

export default SocialShareButtons;
