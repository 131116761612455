import { useDispatch } from 'react-redux';
import useSWR from 'swr';

import { useApi } from '@/hooks/api';
import { setBannerMessage } from '@/store/actions';

import { ApiResponse } from './types';

export interface Invoice {
	pricePerCoveredLife: number;
	currency: string;
	totalCost: number;
	prorationAmount: number;
	subtotal: number;
	tax: number;
}

export function useInvoice(partnerId: string, coveredLives: number): ApiResponse<Invoice> {
	const apiRequest = useApi();
	const dispatch = useDispatch();

	const { data, error } = useSWR(
		`b2b/partners/${partnerId}/invoice?quantity=${coveredLives}`,
		async endpoint => {
			if (coveredLives === 0) {
				return {
					price: 0,
					amount_due: 0,
					currency: 'usd',
				};
			}
			try {
				const response = await apiRequest({ endpoint });
				if (!response.data) {
					throw new Error('Not able to fetch invoice details');
				}
				return response.data;
			} catch (responseError) {
				dispatch(
					setBannerMessage({
						message: `Failed to retrieve invoice details`,
						isError: true,
						flash: true,
					}),
				);
				throw responseError;
			}
		},
	);

	if (error) {
		return { loading: false, error };
	}

	if (!data) {
		return { loading: true, error: undefined };
	}

	const { price, currency, amount_due, tax_amount, subtotal } = data;
	const pricePerCoveredLife = price / coveredLives;
	const prorationAmount = amount_due;

	return {
		data: {
			pricePerCoveredLife,
			currency,
			subtotal: subtotal,
			tax: tax_amount,
			totalCost: price,
			prorationAmount,
		},
		error: undefined,
		loading: false,
	};
}
