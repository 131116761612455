import { FC, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { Card, Button, Modal } from '@calm-web/design-system';

import CellTitle from '@/components/ui/CellTitle';
import { updatePartnerCacheById, useTransitionSelfServePartner } from '@/hooks/api/usePartner';
import { setBannerMessage } from '@/store/actions';
import { SKU } from '@/utils/SkuEnums';
import { getSkuDisplayName, isSelfServePlan } from '@/utils/SkuUtils';
import { CalmError, isCalmError } from '@/utils/apiRequest/errors';

import messages from './messages';
import { CancelButton, ErrorMessage, TableTitle } from './styles';

function getTransitionErrorMsg(formatMessage: IntlShape['formatMessage'], error: CalmError): string {
	const { code } = error.data.error;
	const requiresReactivation = code === 'b2b_subscription_already_canceled';

	return requiresReactivation ? formatMessage(messages.transitionRequiresReactivationError) : code;
}

function PartnerSKUDisplayName({
	partnerId,
	vouchedPlanSku,
}: {
	partnerId: string;
	vouchedPlanSku: SKU | undefined;
}): ReturnType<FC> {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();
	const [transition, { loading: isTransitioning, error: transitionError }] =
		useTransitionSelfServePartner(partnerId);
	const transitionErrorMessage =
		transitionError && isCalmError(transitionError)
			? getTransitionErrorMsg(formatMessage, transitionError)
			: String(transitionError?.message ?? transitionError);
	const [showTransitionModal, setShowTransitionModal] = useState(false);
	const skuDisplayName = getSkuDisplayName(vouchedPlanSku);
	const onModalDismiss = (): void => {
		setShowTransitionModal(false);
	};

	const handleTransitionClick = (): void => {
		setShowTransitionModal(true);
	};

	const submitTransition = async (): Promise<void> => {
		try {
			await transition();
		} catch (err) {
			dispatch(
				setBannerMessage({
					message: formatMessage(messages.transitionGeneralError),
					isError: true,
					flash: true,
				}),
			);
			return;
		}

		setShowTransitionModal(false);
		await updatePartnerCacheById(partnerId);
		dispatch(
			setBannerMessage({
				message: 'Successfully transitioned partner',
				isError: false,
				flash: true,
			}),
		);
	};

	return (
		<Card>
			<TableTitle>
				<CellTitle showAdminFlag>Partner SKU</CellTitle>

				{isSelfServePlan(vouchedPlanSku) && (
					<Button backgroundColor="blue3" onPress={handleTransitionClick} type="button">
						{formatMessage(messages.transitionButton)}
					</Button>
				)}
			</TableTitle>
			{skuDisplayName}
			<Modal
				isOpen={showTransitionModal}
				closeModal={onModalDismiss}
				title={formatMessage(messages.transitionModalTitle)}
				footer={
					<>
						<CancelButton onPress={onModalDismiss}>{formatMessage(messages.cancel)}</CancelButton>
						<Button
							backgroundColor="blue3"
							onPress={submitTransition}
							isLoading={isTransitioning}
							type="button"
						>
							{formatMessage(messages.confirm)}
						</Button>
					</>
				}
			>
				<div>
					<ErrorMessage>{transitionErrorMessage}</ErrorMessage>
				</div>
			</Modal>
		</Card>
	);
}

export default PartnerSKUDisplayName;
