import { defineMessages } from 'react-intl';

export default defineMessages({
	instructions: {
		id: 'reporting.pinnedFeedbackTable.instructions',
		defaultMessage:
			// eslint-disable-next-line max-len
			'Pin quotes you want the partner admin to see. We’ll automatically rotate them so the partner admin sees new quotes every time they return to their reporting page.',
		description: 'instructions for why you would pin feedback',
	},
	unsavedChanges: {
		id: 'reporting.pinnedFeedbackTable.unsavedChanges',
		defaultMessage: 'Are you sure? Changes you made may not be saved.',
		description: 'Warning when leaving the page with unsaved changes',
	},
});
