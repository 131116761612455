import * as Popper from '@popperjs/core';
import { useId } from '@react-aria/utils';
import { PropsWithChildren, ReactElement, useMemo, useRef } from 'react';
import DP, { ReactDatePicker } from 'react-datepicker';

import Calendar from 'icons/calendar-outline.svg';

import { Wrapper } from './styles';

export interface Props {
	startDate?: Date | undefined | null;
	rangeStartDate?: Date | null;
	rangeEndDate?: Date | null;
	minDate?: Date | undefined | null;
	maxDate?: Date | undefined | null;
	minTime?: Date | undefined;
	maxTime?: Date | undefined;
	onChange: ((d: Date) => void) | ((dates: [Date | null, Date | null]) => void);
	disabled?: boolean;
	dataTestId?: string;
	disableKeyboardNavigation?: boolean;
	width?: string;
	onOpen?: () => void;
	onClose?: () => void;
	labelledBy: string;
	inline?: boolean;
	selectsRange?: boolean;
	monthsShown?: number;
	showCalendarIcon?: boolean;
	showTimeSelect?: boolean;
	showTimeSelectOnly?: boolean;
	timeIntervals?: number;
	timeCaption?: string;
	dateFormat?: string;
	filterDate?: (d: Date) => boolean;
	placement?: Popper.Placement | undefined;
}

export default function DatePicker({
	startDate,
	rangeStartDate,
	rangeEndDate,
	minDate,
	maxDate,
	minTime,
	maxTime,
	onChange,
	disabled,
	dataTestId,
	children,
	width,
	onOpen,
	onClose,
	labelledBy,
	inline,
	selectsRange,
	monthsShown,
	showCalendarIcon = true,
	disableKeyboardNavigation = false,
	showTimeSelect,
	showTimeSelectOnly,
	timeIntervals,
	timeCaption,
	dateFormat,
	filterDate,
	placement,
}: PropsWithChildren<Props>): ReactElement {
	const dpRef = useRef<ReactDatePicker<never, boolean>>(null);
	const datePickerId = useId();
	function onClickIcon(): void {
		dpRef.current?.setOpen(true);
	}

	function isInvalidDate(date: Date | undefined | null): boolean {
		return date !== null && date !== undefined && isNaN(date.getTime());
	}

	const selected = useMemo(() => {
		if (isInvalidDate(startDate)) {
			return;
		}
		return startDate ?? rangeStartDate;
	}, [startDate, rangeStartDate]);

	return (
		<Wrapper
			id={datePickerId}
			data-testid={dataTestId}
			width={width || 'auto'}
			showCalendarIcon={showCalendarIcon}
		>
			{showCalendarIcon && <Calendar onClick={onClickIcon} />}
			<DP
				popperPlacement={placement}
				selected={selected}
				focusSelectedMonth
				startDate={rangeStartDate}
				endDate={rangeEndDate}
				onChange={onChange}
				disabled={disabled}
				ref={dpRef}
				minDate={minDate}
				maxDate={maxDate}
				minTime={minTime}
				maxTime={maxTime}
				onCalendarOpen={onOpen}
				onCalendarClose={onClose}
				ariaLabelledBy={labelledBy}
				inline={inline}
				selectsRange={selectsRange}
				monthsShown={monthsShown}
				showTimeSelect={showTimeSelect}
				showTimeSelectOnly={showTimeSelectOnly}
				timeIntervals={timeIntervals}
				timeCaption={timeCaption}
				dateFormat={dateFormat}
				filterDate={filterDate}
				disabledKeyboardNavigation={disableKeyboardNavigation}
			/>
			{children}
		</Wrapper>
	);
}
