import { Dispatch } from 'redux';

import { setBannerMessage } from '@/store/actions';

export async function copySubscribeUrl({
	subscribeUrl,
	dispatch,
	navigator,
	document,
}: {
	subscribeUrl: string;
	dispatch: Dispatch;
	navigator: Navigator;
	document: Document;
}): Promise<void> {
	if (!navigator.clipboard) {
		const textArea = document.createElement('textarea');
		textArea.value = subscribeUrl;
		// Avoid scrolling to bottom
		textArea.style.top = '0';
		textArea.style.left = '0';
		textArea.style.position = 'fixed';
		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();
		document.execCommand('copy');
		document.body.removeChild(textArea);
	} else {
		await navigator.clipboard.writeText(subscribeUrl);
	}
	dispatch(
		setBannerMessage({
			message: 'Subscribe url copied to clipboard',
			isError: false,
			flash: true,
		}),
	);
}
