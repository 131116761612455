import { Container, InfoIconContainer, InfoIcon, FilterButtonTooltip } from './styles';
import { FilterButtonProps } from './types';

const FilterButton: React.FC<FilterButtonProps> = ({
	children,
	Icon,
	tooltipText,
	tooltipArrowTranslation,
	...props
}) => {
	const button = (
		<Container
			{...props}
			backgroundColor="transparent"
			textColor="buttonSecondaryText"
			hideShadow
			Icon={Icon}
		>
			{children}
			{tooltipText && (
				<InfoIconContainer data-html2canvas-ignore="true">
					<InfoIcon />
				</InfoIconContainer>
			)}
		</Container>
	);

	if (tooltipText) {
		return (
			<FilterButtonTooltip
				offset={[-9, -8]}
				placement="top-end"
				content={tooltipText}
				hideOnClick={false}
				maxWidth={220}
				arrowTranslation={tooltipArrowTranslation}
			>
				{button}
			</FilterButtonTooltip>
		);
	}

	return button;
};

export default FilterButton;
