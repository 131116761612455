import { defineMessages } from 'react-intl';

export default defineMessages({
	emailConfigHeading: {
		id: 'pathways.schedule.emailConfig.heading',
		defaultMessage: 'Emails',
		description: 'Heading for email config input',
	},
	emailConfigDescription: {
		id: 'pathways.schedule.emailConfig.description',
		defaultMessage: 'Choose whether Pathway announcement and reminder emails should be sent from Calm.',
		description: 'Description for email config input',
	},
	announcementHeading: {
		id: 'pathways.schedule.announcement.heading',
		defaultMessage: 'Schedule Announcement',
		description: 'Heading for announcement input',
	},
	announcementDescription: {
		id: 'pathways.schedule.announcement.description',
		defaultMessage:
			'This is the date when individuals will receive an announcement (mobile, email) to join the pathway. Must be at least one week before the start date.',
		description: 'Description for announcement input',
	},
	announcementDescriptionSettingsOff: {
		id: 'pathways.schedule.announcement.description.settingsOff',
		defaultMessage:
			'No emails will be sent by Calm. You can refer to the sample below as reference for your own communications about the Pathway.',
		description: 'Description for announcement when settings are off',
	},
	announcementLink: {
		id: 'pathways.schedule.announcement.link',
		defaultMessage: 'Announcement',
		description: 'Link name for announcement template',
	},
	reminderHeading: {
		id: 'pathways.schedule.reminder.heading',
		defaultMessage: 'Schedule Reminder',
		description: 'Heading for reminder input',
	},
	reminderDescription: {
		id: 'pathways.schedule.reminder.description',
		defaultMessage:
			'This is the date when individuals will receive a reminder (mobile, email) to join the pathway. Must be after announcement and before start date.',
		description: 'Description for reminder input',
	},
	reminderDescriptionSettingsOff: {
		id: 'pathways.schedule.reminder.description.settingsOff',
		defaultMessage:
			'No emails will be sent by Calm. You can refer to the sample below as reference for your own communications about the Pathway.',
		description: 'Description for reminder when settings are off',
	},
	reminderLink: {
		id: 'pathways.schedule.reminder.link',
		defaultMessage: 'Reminder',
		description: 'Link name for reminder template',
	},
	bannerText: {
		id: 'pathways.schedule.banner.text',
		defaultMessage:
			'<underlinedtext>Benefits Invites and Reminders</underlinedtext> are currently turned OFF, only those who have registered for Calm will receive the email. This setting must be turned ON in order to deliver to all employees (registered and not registered for Calm, will receive the email).',
		description: 'Banner text when email send is turned off',
	},
	scheduleHeading: {
		id: 'pathways.schedule.heading',
		defaultMessage: 'Choose the Pathway’s start date (2 week duration)',
		description: 'Heading on the schedule page',
	},
	scheduleDescription: {
		id: 'pathways.schedule.description',
		defaultMessage:
			'This is when the content programming will start and occur for your members. Pathways can only begin on Mondays.',
		description: 'Description on the schedule page',
	},
	closeButtonText: {
		id: 'pathways.schedule.closeButton.text',
		defaultMessage: 'Close',
		description: 'Text for close button',
	},
	weekendWarning: {
		id: 'pathways.schedule.weekend.warning',
		defaultMessage: 'This will be delivered during the weekend.',
		description: 'Warning text when partner schedules an email over the weekend',
	},
});
