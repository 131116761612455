import { ComponentProps, FC } from 'react';
import { Link } from 'react-router-dom';

import { CtaButton } from './styles';

type Props = ComponentProps<typeof CtaButton> & { to: ComponentProps<typeof Link>['to'] };

const CalloutCta: FC<Props> = ({ children, to, ...props }) => {
	return (
		<Link to={to}>
			<CtaButton {...props}>{children}</CtaButton>
		</Link>
	);
};

export default CalloutCta;
