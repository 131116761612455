import { defineMessages } from 'react-intl';

export default defineMessages({
	lightLogoTitle: {
		id: 'health.branding.lightModeTitle',
		defaultMessage: 'Light Mode',
		description: 'A title above a section where the user can add a light mode logo for the Calm Health app',
	},
	lightLogoSubtitle: {
		id: 'health.branding.lightModeSubtitle',
		defaultMessage: 'This logo will appear to the user as their sponsor group.',
		description: 'The subtitle below the light mode title',
	},
	darkLogoTitle: {
		id: 'health.branding.darkModeTitle',
		defaultMessage: 'Dark Mode',
		description: 'A title above a section where the user can add a dark mode logo for the Calm Health app',
	},
	darkLogoSubtitle: {
		id: 'health.branding.darkModeSubtitle',
		defaultMessage:
			'This logo will appear to the user as their sponsor group if the user has dark mode enabled.',
		description: 'The subtitle below the dark mode title',
	},
	squareLogoTitle: {
		id: 'health.branding.squareLogoTitle',
		defaultMessage: 'Square Logo',
		description: 'A title above a section where the user can add a square logo for the Calm Health app',
	},
	squareLogoSubtitle: {
		id: 'health.branding.squareLogoSubtitle',
		defaultMessage: 'This logo will appear to the user as their sponsor group in smaller card elements.',
		description: 'The subtitle below the square logo title',
	},
	squareLogoRequirements: {
		id: 'health.branding.squareLogoRequirements',
		defaultMessage: 'Must be square at 1:1 aspect ratio',
		description: 'A requirement for the square logo ratio',
	},
	rectangularLogoRequirements: {
		id: 'health.branding.rectangularLogoRequirements',
		defaultMessage: 'Must be a rectangular image wider than 1:1 aspect ratio',
		description: 'A requirement for the rectangular logo ratio',
	},
	squareLogoBackgroundRequirements: {
		id: 'health.branding.squareLogoBackgroundRequirements',
		defaultMessage: 'Includes a colored non-transparent background and is legible at small sizes',
		description: 'A requirement for the square logo background',
	},
	lightLogoBackgroundRequirements: {
		id: 'health.branding.lightLogoBackgroundRequirements',
		defaultMessage: 'Includes a transparent background and is legible on a light background',
		description: 'A requirement for the light logo background',
	},
	darkLogoBackgroundRequirements: {
		id: 'health.branding.darkLogoBackgroundRequirements',
		defaultMessage: 'Includes a transparent background and is legible on a dark background',
		description: 'A requirement for the dark logo background',
	},
});
