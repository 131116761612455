import { rgba } from 'polished';
import styled from 'styled-components';
import { palette } from 'styled-tools';

import {
	FontSizes,
	PrimaryButton,
	SecondaryButton,
	maxWidth,
	fontSize,
	fontWeight,
	FontWeights,
	units,
	fontFamily,
	minWidth,
} from '@calm-web/design-system';

const ContainerStyles = styled.div`
	background-color: ${palette('white')};
	border-radius: ${units(2)};
	padding: ${units(3, 4)};
`;

export const Wrapper = styled.div`
	border-radius: ${units(2)};
	margin-top: ${units(3)};
	${minWidth('desktop')} {
		margin-bottom: ${units(13)};
	}
`;

export const EmailWrapper = styled(ContainerStyles)`
	display: flex;
	grid-gap: ${units(5)};

	${maxWidth('tablet')} {
		flex-wrap: wrap;
		justify-content: center;
	}
`;

export const EmailConfigContainer = styled.div`
	width: ${units(48)};
`;

export const RadioContainer = styled.div`
	padding-top: ${units(2)};
`;

export const AnnouncementContainer = styled.div`
	width: ${units(50)};
`;

export const ReminderContainer = styled.div`
	width: ${units(50)};
`;

export const WeekendWarning = styled.div`
	padding-top: ${units(2)};
	color: ${palette('heartRed')};
	${fontWeight(FontWeights.Medium)};
`;

export const Header = styled.div`
	${fontWeight(FontWeights.Medium)};
	${fontSize(FontSizes.lg)};
	margin-bottom: ${units(2)};
`;

export const EmailPickerWrapper = styled.div`
	.react-datepicker,
	.react-datepicker__input-container input {
		${fontFamily};
	}
	.react-datepicker {
		display: flex;
	}

	.react-datepicker-popper {
		z-index: 2;
	}
`;

export const ScheduleContainer = styled(ContainerStyles)`
	margin-bottom: ${units(2)};
	padding-bottom: ${units(2)};
	${maxWidth('tablet')} {
		padding: ${units(3, 2)};
	}
`;

export const Description = styled.div``;

export const ResourceLink = styled(SecondaryButton)`
	margin-top: ${units(1)};
	text-decoration: none;
	&& {
		padding-left: 0;
	}
`;

export const CloseButton = styled(PrimaryButton)``;

// Label for accessiblity
export const Label = styled.div`
	visibility: hidden;
	${fontSize(FontSizes.sm)};
`;

/* Custom styles for react-datepicker classes */
export const DatePickerWrapper = styled.div`
	.react-datepicker {
		${fontFamily};
		border: 0;
	}

	.react-datepicker__header {
		border: 0;
		background-color: ${palette('white')};
	}

	.react-datepicker__navigation--previous {
		border-right-color: ${palette('blue3')};
	}

	.react-datepicker__navigation--next {
		border-left-color: ${palette('blue3')};
	}

	.react-datepicker__current-month {
		color: ${palette('gray6')};
	}

	.react-datepicker__day {
		width: 50px;
		margin: 0;
	}

	.react-datepicker__day-name {
		width: 50px;
		margin: 0;
	}

	.react-datepicker__day--keyboard-selected {
		background-color: transparent;
		color: ${palette('black')};
	}

	.react-datepicker__day--in-range {
		background-color: ${(props): string => rgba(palette('blue3')(props), 0.2)};
		color: ${palette('black')};
		border-radius: 0;

		&:nth-of-type(even) {
			border: 1px solid ${palette('blue3')};
		}
	}

	.react-datepicker__day--outside-month {
		border: 0 !important;
	}

	.react-datepicker__day--in-selecting-range {
		background-color: ${(props): string => rgba(palette('blue3')(props), 0.2)};
		color: ${palette('black')};
		border-radius: 0;
	}

	.react-datepicker__day--range-start {
		border-top-left-radius: ${units(2)};
		border-bottom-left-radius: ${units(2)};
	}

	.react-datepicker__day--range-end {
		border-top-right-radius: ${units(2)};
		border-bottom-right-radius: ${units(2)};
	}

	.react-datepicker__week {
		> * {
			&:first-child {
				border-top-left-radius: ${units(2)};
				border-bottom-left-radius: ${units(2)};
			}
			&:last-child {
				border-top-right-radius: ${units(2)};
				border-bottom-right-radius: ${units(2)};
			}
		}
	}

	${maxWidth('mobileLarge')} {
		.react-datepicker__day-name,
		.react-datepicker__day {
			width: 35px;
		}
	}
`;
