import { defineMessages } from 'react-intl';

export default defineMessages({
	areYouSure: {
		id: 'plan.planModal.areYouSure',
		defaultMessage: 'Are you sure you want to cancel your plan?',
		description: 'Title for the cancel plan modal',
	},
	confirmText: {
		id: 'plan.planModal.confirmText',
		defaultMessage:
			'Your plan will not renew at the end of the month. Your team will have access to their Calm Premium subscriptions until {expirationdate}.',
		description: 'Confirmation text for the cancel plan modal',
	},
	cancelPlan: {
		id: 'plan.planModal.cancelPlan',
		defaultMessage: 'OK, cancel my plan now',
		description: 'Cancel plan confirm text for cancel plan modal button',
	},
	planRemovalError: {
		id: 'plan.planModal.planRemovalError',
		defaultMessage: "We're sorry, there was an error removing your plan.",
		description: 'Error message for when plan removal fails for unknown reasons',
	},
	hasCanceledText: {
		id: 'plan.planModal.hasCanceledText',
		defaultMessage:
			// eslint-disable-next-line max-len
			'Your plan has been cancelled and will not renew on {expirationdate}. Your team will have access to their Calm Premium subscriptions until {expirationdate}.',
		description: 'Text confirming their plan is canceled',
	},
	done: {
		id: 'plan.planModal.closeModal',
		defaultMessage: 'Done',
		description: 'Indicates the user is done, and the modal can be closed',
	},
	loading: {
		id: 'plan.planModal.loading',
		defaultMessage: 'Canceling plan…',
		description: 'Indicates the cancelation request in progress',
	},
});
