import { defineMessages } from 'react-intl';

export default defineMessages({
	brandingAndBenefitsTitle: {
		id: 'account.brandingAndBenefitsTitle',
		defaultMessage: 'Branding & Benefits',
		description: 'A title above a section where the user can add partner branding and benefits info.',
	},
	brandingTitle: {
		id: 'account.brandingTitle',
		defaultMessage: 'Branding',
		description: 'A title above a section where the user can add partner branding info.',
	},
});
