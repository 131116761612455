import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ifProp, palette } from 'styled-tools';

import { units, FontSizes, Text, FontWeights, Card } from '@calm-web/design-system';

import success from 'icons/circle-check.svg';
import failure from 'icons/circle-x.svg';
import clock from 'icons/clock.svg';

export const TableColumn = styled.div<{ isMobile?: boolean }>`
	${ifProp(
		'isMobile',
		css`
			padding-top: ${units(0.5)};
		`,
	)};
`;

export const TableWrapper = styled(Card).attrs({ noPadding: true })<{
	isOnlyOnePage: boolean;
	isMobile?: boolean;
}>`
	padding-top: ${units(3)};
	margin-top: 0px;
	overflow-x: auto;

	${ifProp(
		'isOnlyOnePage',
		css`
			padding-bottom: ${units(3)};
		`,
	)};

	${ifProp(
		'isMobile',
		css`
			overflow-wrap: anywhere;
		`,
	)};
`;

export const Title = styled(Text).attrs({
	size: FontSizes.lg,
})``;

export const BoldText = styled(Text).attrs({
	weight: FontWeights.Demi,
})``;

export const TitleWrapper = styled.div`
	margin: 0 0 ${units(2)} ${units(2)};
`;

export const FailureIcon = styled(failure)`
	width: 22px;
	height: 22px;
	path {
		fill: ${palette('errorRed')};
	}
`;
export const SuccessIcon = styled(success)`
	width: 22px;
	height: 22px;
	path {
		fill: ${palette('successGreen')};
	}
`;

export const PartialIcon = styled(success)`
	width: 22px;
	height: 22px;
	path {
		fill: ${palette('gold')};
	}
`;

export const PendingIcon = styled(clock)`
	width: 20px;
	height: 20px;
	path {
		fill: ${palette('gray3')};
		fill-opacity: 1;
	}
`;

export const StyledLink = styled(Link)`
	text-decoration: none;
	color: ${palette('blue3')};
`;

export const DividerContainer = styled.div`
	width: 10px;
	padding: ${units(2)} 0;
`;
