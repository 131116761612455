import { rgba } from 'polished';
import styled, { css } from 'styled-components';
import { ifProp, palette } from 'styled-tools';

import { ButtonSizes, minWidth, Button, Tooltip, units } from '@calm-web/design-system';

import InfoOutline from 'icons/info-outline.svg';

const paddingLeftRight = units(1);

export const Container = styled(Button).attrs({ size: ButtonSizes.Sm })`
	position: relative;
	padding-left: ${paddingLeftRight};
	padding-right: ${paddingLeftRight};
	margin-left: ${units(0.8)};
	display: flex;
	justify-content: center;
	align-items: center;

	&:hover,
	&:focus {
		background: ${(props): string => rgba(palette('blue3')(props), 0.16)};
	}

	div {
		${minWidth('desktop')} {
			width: 24px;
			height: 24px;

			svg {
				width: 24px;
				height: 24px;
			}
		}
	}
`;

export const InfoIconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: ${units(1.25)};
`;

export const InfoIcon = styled(InfoOutline)`
	width: ${units(2.5)};
	height: ${units(2.5)};
	position: relative;

	path {
		fill: ${palette('gray7')};
	}
`;

export const FilterButtonTooltip = styled(Tooltip)<{
	offset: number[];
	arrowTranslation: number | undefined;
}>`
	${ifProp(
		'arrowTranslation',
		({ arrowTranslation }) =>
			css`
				&[data-placement^='top-end'] > .tippy-arrow {
					transform: translate(${arrowTranslation}px) !important;
				}
			`,
	)}
`;
