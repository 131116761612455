import { defineMessages } from 'react-intl';

export default defineMessages({
	lastModalTitle: {
		id: 'emailPreferences.manualSurvey.lastModal.title',
		defaultMessage: 'Last request sent less than a month ago',
		description: 'Header for the last request sent less than a month ago modal',
	},
	nextModalTitle: {
		id: 'mailPreferences.manualSurvey.nextModal.title',
		defaultMessage: 'Next request less than a month away',
		description: 'Header for the next request less than a month away modal',
	},
	modalCancel: {
		id: 'mailPreferences.manualSurvey.modal.cancel',
		defaultMessage: 'No, cancel',
		description: 'Modal text for cancelling manual date change',
	},
	modalProceed: {
		id: 'mailPreferences.manualSurvey.modal.proceed',
		defaultMessage: 'Yes, proceed anyway',
		description: 'Modal text for proceeding with manual date change',
	},
	lastModalParagraph1: {
		id: 'mailPreferences.manualSurvey.lastModal.paragraph1',
		defaultMessage: 'Your last request was on {newDate}.',
		description: 'Modal text defining the last request sent date',
	},
	lastModalParagraph2: {
		id: 'mailPreferences.manualSurvey.lastModal.paragraph2',
		defaultMessage: 'Are you sure you want to send a feedback request?',
		description: 'Modal text confirming the action',
	},
	nextModalParagraph1: {
		id: 'mailPreferences.manualSurvey.nextModal.paragraph1',
		defaultMessage: "We'll be sending your employees a feedback request on {newDate}.",
		description: 'Modal text defining the next request send date',
	},
	nextModalParagraph2: {
		id: 'mailPreferences.manualSurvey.nextModal.paragraph2',
		defaultMessage: 'Are you sure you still want to proceed?',
		description: 'Modal text confirming the action',
	},
});
