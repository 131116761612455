import { defineMessages } from 'react-intl';

export default defineMessages({
	title: {
		id: 'users.eligibilitiyExceeded.error.title',
		defaultMessage: 'Exceeded maximum covered lives',
		description: 'Eligibility error title',
	},
	confirmation: {
		id: 'users.eligibilitiyExceeded.error.confirmation',
		defaultMessage: 'I understand',
		description: 'Confirmation button text',
	},
	details: {
		id: 'users.eligibilitiyExceeded.error.details',
		defaultMessage:
			'You uploaded an eligibility file containing {eligibilityFileCoveredLives} covered lives which is over your current allocated coverage of {contractCoveredLives} covered lives. Looks like you’ve done a great job of spreading the Calm magic to {partnerName}!',
		description: 'Eligibility error details',
	},
	ask: {
		id: 'users.eligibilitiyExceeded.error.ask',
		defaultMessage: `A member of your Calm Business account team will be reaching out to make sure that your current agreement is fully meeting your organization's ongoing needs.`,
		description: 'Action step for resolution of eligibility exceeded error',
	},
});
