import { defineMessages } from 'react-intl';

export default defineMessages({
	businessAdminFAQ: {
		id: 'resources.businessAdminFAQ',
		defaultMessage: 'Calm Business Admin FAQ',
		description: 'Link text for downloading business admin frequently asked questions doc',
	},
	businessEmployeeFAQ: {
		id: 'resources.businessEmployeeFAQ',
		defaultMessage: 'Calm Business Employee FAQ',
		description: 'Link text for downloading business employee frequently asked questions doc',
	},
});
