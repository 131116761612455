import { FormEvent, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { Text, Card, FontWeights, FontSizes, Loader } from '@calm-web/design-system';

import CellTitle from '@/components/ui/CellTitle';
import useHealthConfig from '@/hooks/api/useHealthConfig';
import { usePermissions } from '@/hooks/auth';
import { useHealthConfigLogosForm, useSubmitHealthConfigLogos } from '@/hooks/forms/useHealthConfigLogosForm';
import { HealthConfig } from '@/types/health';
import { Partner } from '@/types/store/reducers';

import StickySaveButton from '../../StickySaveButton';
import HealthSponsorshipPreview from '../../TabRouter/sections/HealthConfig/Previews/HealthSponsorshipPreview';
import InAppClientSupportPreview from '../../TabRouter/sections/HealthConfig/Previews/InAppClientSupportPreview';
import UnifiedLogoUploader from '../LogoUploader/UnifiedLogoUploader';
import messages from './messages';
import { ConfigWrapper, LogoWrapper, Wrapper } from './styles';

const MAX_IMAGE_SIZE_MB = 5;
const SUPPORT_PNG_LOGOS = false;

const CalmHealthBrandingInner = ({
	partnerId,
	healthConfig,
}: {
	partnerId: string;
	healthConfig: HealthConfig;
}): React.ReactElement => {
	const { formatMessage } = useIntl();
	const [hasValidPermissions, actions] = usePermissions();
	const { formProps, hasChangedAny, hasTouchedAny } = useHealthConfigLogosForm(healthConfig);
	const { submitHealthConfigLogos, showValidationErrors, loading } = useSubmitHealthConfigLogos(formProps);
	const [preview, setPreview] = useState('light');
	const showDarkPreview = preview === 'dark';

	const onSubmit = useCallback(
		async (e: FormEvent): Promise<void> => {
			e.preventDefault();
			if (showValidationErrors()) return;
			try {
				await submitHealthConfigLogos(partnerId);
			} catch (error) {
				//...
			}
		},
		[partnerId, showValidationErrors, submitHealthConfigLogos],
	);

	return (
		<form onSubmit={onSubmit}>
			<Wrapper>
				<ConfigWrapper>
					<Card>
						<CellTitle>Branding</CellTitle>
						<LogoWrapper onMouseEnter={() => setPreview('light')}>
							<Text el="label" weight={FontWeights.Medium}>
								{formatMessage(messages.lightLogoTitle)}
							</Text>
							<Text el="label" weight={FontWeights.Medium} size={FontSizes.sm}>
								{formatMessage(messages.lightLogoSubtitle)}
							</Text>
							<UnifiedLogoUploader
								formProps={formProps}
								disabled={!hasValidPermissions('light_logo_url', [actions.UPDATE])}
								fileFieldName="lightLogoFile"
								urlFieldName="lightLogoUrl"
								analyticsEvent="Partner : Logo Upload : Light : Selected"
								hideDelete
								maxImageSizeMB={MAX_IMAGE_SIZE_MB}
								supportPNG={SUPPORT_PNG_LOGOS}
								additionalRequirements={[
									formatMessage(messages.rectangularLogoRequirements),
									formatMessage(messages.lightLogoBackgroundRequirements),
								]}
							/>
						</LogoWrapper>
						<LogoWrapper onMouseEnter={() => setPreview('dark')}>
							<Text el="label" weight={FontWeights.Medium}>
								{formatMessage(messages.darkLogoTitle)}
							</Text>
							<Text el="label" weight={FontWeights.Medium} size={FontSizes.sm}>
								{formatMessage(messages.darkLogoSubtitle)}
							</Text>
							<UnifiedLogoUploader
								formProps={formProps}
								disabled={!hasValidPermissions('dark_logo_url', [actions.UPDATE])}
								fileFieldName="darkLogoFile"
								urlFieldName="darkLogoUrl"
								analyticsEvent="Partner : Logo Upload : Dark : Selected"
								hideDelete
								maxImageSizeMB={MAX_IMAGE_SIZE_MB}
								supportPNG={SUPPORT_PNG_LOGOS}
								additionalRequirements={[
									formatMessage(messages.rectangularLogoRequirements),
									formatMessage(messages.darkLogoBackgroundRequirements),
								]}
								isDarkMode={true}
							/>
						</LogoWrapper>
						<LogoWrapper onMouseEnter={() => setPreview('square')}>
							<Text el="label" weight={FontWeights.Medium}>
								{formatMessage(messages.squareLogoTitle)}
							</Text>
							<Text el="label" weight={FontWeights.Medium} size={FontSizes.sm}>
								{formatMessage(messages.squareLogoSubtitle)}
							</Text>
							<UnifiedLogoUploader
								formProps={formProps}
								disabled={!hasValidPermissions('square_logo_url', [actions.UPDATE])}
								fileFieldName="squareLogoFile"
								urlFieldName="squareLogoUrl"
								analyticsEvent="Partner : Logo Upload : Square : Selected"
								hideDelete
								maxImageSizeMB={MAX_IMAGE_SIZE_MB}
								supportPNG={SUPPORT_PNG_LOGOS}
								additionalRequirements={[
									formatMessage(messages.squareLogoRequirements),
									formatMessage(messages.squareLogoBackgroundRequirements),
								]}
							/>
						</LogoWrapper>
					</Card>
				</ConfigWrapper>
				{preview === 'square' ? (
					<HealthSponsorshipPreview
						name="Default"
						partnerId={partnerId}
						squareLogoFile={formProps.model.squareLogoFile}
					/>
				) : (
					<InAppClientSupportPreview
						healthConfig={healthConfig}
						logoFormProps={formProps}
						isDarkMode={showDarkPreview}
					/>
				)}
			</Wrapper>
			<StickySaveButton
				isFormDirty={hasChangedAny}
				hasTouchedForm={hasTouchedAny}
				isLoading={loading}
				dataTestId="create-edit-partner-save-btn"
			>
				Save Configuration
			</StickySaveButton>
		</form>
	);
};

const CalmHealthBranding = ({ partner }: { partner: Partner }): React.ReactElement => {
	const { data: healthConfig, loading } = useHealthConfig({ partnerId: partner.id });
	if (healthConfig) {
		return <CalmHealthBrandingInner healthConfig={healthConfig} partnerId={partner.id} />;
	}
	if (loading) {
		return <Loader />;
	}
	return <Text>Sorry, there was an issue loading your configuration</Text>;
};
export default CalmHealthBranding;
